import { useState, Fragment, useRef, useEffect } from "react";
import {showLoading, hideLoading } from 'react-redux-loading-bar';
import { useDispatch } from "react-redux";

import "bootstrap/dist/css/bootstrap.min.css";
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCircleCheck,
    faDownload, faPaperPlane,
    faTimesCircle
} from "@fortawesome/free-solid-svg-icons";
import { v4 as uuid4 } from 'uuid';
import Papa from 'papaparse';

import MatchedItem from "../components/matched-item";
import { getAuthToken } from "../util/token";
import { select } from "d3";

function PDFParser () {

    // get auth token
    // const token = getAuthToken();
    // const userName = localStorage.getItem('username');
    const dispatch = useDispatch();

    const fileUpload = useRef(null);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [filteredUploaded, setfilteredUploaded] = useState([]);
    const [billingItems, setBillingItems] = useState([]);

    const [selectKwList, setSelectKwList] = useState([]);

    const handleFile = (file) => {
        const reader = new FileReader(file);
        reader.readAsDataURL(file); // Example: Read file content as base64 data
        reader.onload = () => {
            // Handle file content here, e.g., send it to a server
            const fileContent = reader.result;
            console.log('file content: ', fileContent);
        };
    };

    const handleDrop = (event) => {
        event.preventDefault();
        console.log(event.dataTransfer.items[0].getAsFile());
        const file = event.dataTransfer.files[0];
        const formData = new FormData();
        formData.append('file', file);
    
    //     fetch(`${process.env.REACT_APP_HOST_IP}/api/upload/image`, {
    //         method: 'POST',
    //         headers: {
    //           'Authorization': 'Bearer ' + token,
    //           'X-User': userName
    //         },
    //         body: formData
    //       })
    //       .then(response => response.json())
    //       .then(data => {
    //         console.log('Image uploaded successfully:', data.imageUrl);
    //       })
    //       .catch(error => {
    //         console.error('Error uploading image:', error);
    //       });
      };
    const handleDragOver = (event) => {
     event.preventDefault();
     event.stopPropagation();
    };
    const fileUploadHandler = () => {
        const input_file = Array.from(document.getElementById('formFile').files);
        console.log(input_file);
        if (input_file.length === 0){
            return
        };
        let acceptedTypes = ['image/jpeg', 'image/png', 'application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];

        let d = [];
        let f = [];
        input_file.map((file, idx) => {
            if (acceptedTypes.includes(file.type) && file.size < 10*1024*1024){
                // console.log(idx);
                d.push(file.name);
                f.push(file);
            }
        });
        setUploadedFiles(m => [...m, ...f]);
        console.log('uploaded file objects: ', f);
        setfilteredUploaded(m => [...m, ...d]);
        
    };

    const cancelHandler = (fileName) => {
        setUploadedFiles(d => d.filter(item => item.name !== fileName));
        setfilteredUploaded(d => d.filter(item => item !== fileName));
        return
    };
    const filterHandler = (item) => {
        let l = uploadedFiles.filter(i => {
            return (
                (item !== '' ? i.name.search(new RegExp(`^${item}`, 'i')) !== -1 : true)
            )
        });
        let d = l.map(item => item.name);
        setfilteredUploaded(d);
    };

    const startParsingHandler = () => {
        if (uploadedFiles.length === 0 || selectKwList.length === 0) return;
        dispatch(showLoading());
        const formData = new FormData();
        uploadedFiles.map(file => {
            console.log(file.name)
            if (selectKwList.includes(file.name)){

                formData.append('file', file);
            }
        });
        console.log(formData);
        fetch(`${process.env.REACT_APP_HOST_IP}/api/invoice-parser-v1`, {
            method: 'POST',
            // headers: {
            //     'Authorization': 'Bearer ' + token,
            //     'X-User': userName,
            // },
            body: formData
        })
        .then(response => {
            dispatch(hideLoading());
            return response.json()
        })
        .then(data => {
            console.log('Image uploaded successfully:', data);
            setBillingItems(data);
        })
        .catch(error => {
            console.error('Error uploading image:', error);
        });
    };

    const downloadReportHandler = () => {
        if(billingItems.length === 0) return;
        let d = []
        billingItems.map(item => {
            return item.items.map(i => {
                let VAT = i.vat;
                if (i.vat === null) VAT = 0;
                let QTY = i.quantity;
                if (i.quantity === null) QTY = 1;
                let unitPrice = VAT + i.unit_price;
                if (unitPrice === 0 && i.amount !== null) unitPrice = i.amount;
                let amount = i.amount;
                if (i.amount === null) amount = QTY*unitPrice;
                let total = item.items.reduce((acc, k) => {
                    if (k.amount !== null) {
                        acc += k.amount;
                    } else {
                        let VAT = k.vat;
                        if (k.vat === null) VAT = 0;
                        let QTY = k.quantity;
                        if (k.quantity === null) QTY = 1;
                        let unitPrice = VAT + k.unit_price;
                        acc += unitPrice*QTY;
                    }
                    return acc
                }, 0);
                return d.push({
                    ContactName: item.provider_name, // required
                    EmailAddress: '',
                    POAddressLine1: '',
                    POAddressLine2: '',
                    POAddressLine3: '',
                    POAddressLine4: '',
                    POCity: '',
                    PORegion: '',
                    POPostalCode: '',
                    POCountry: '',
                    SAAddressLine1: '',
                    SAAddressLine2: '',
                    SAAddressLine3: '',
                    SAAddressLine4: '',
                    SACity: '',
                    SARegion: '',
                    SAPostalCode: '',
                    SACountry: '',
                    InvoiceNumber: item.invoice_id, // required
                    Reference: '',
                    InvoiceDate: item.invoice_date, // required
                    DueDate: item.due_date, // required
                    PlannedDate: '',
                    Total: total, //required
                    TaxTotal: '',
                    InvoiceAmountPaid: '',
                    InvoiceAmountDue: '',
                    InventoryItemCode: '',
                    Description: i.item_description, //required
                    Quantity: QTY, // required
                    UnitAmount: unitPrice, //required
                    Discount: '',
                    LineAmount: amount, // required
                    AccountCode: '',
                    TaxType: '',
                    TaxAmount: '',
                    TrackingName1: '',
                    TrackingOption1: '',
                    TrackingName2: '',
                    TrackingOption2: '',
                    Currency: i.currency_code, // required
                    Type: '',
                    Sent: '',
                    Status: '',
                    // 'File Name': item.invoice_file_name,
                    // 'Client Name': item.client_name,
                    // 'Provider Name': item.provider_name,
                    // 'Invoice Id': item.reference_id,
                    // 'Issued Date': item.invoice_date,
                    // 'Due Date': item.due_date,
                    // ...i,
                })
            })
        })
        // console.log(d);
        const csv = Papa.unparse({
            // "fields": ['URL', "Keyword", 'Volume', 'KD', 'CPC'],
            "data": d
        });
        const blob = new Blob([csv]);

        const url = window.URL.createObjectURL(blob, {type: 'text/plain' });
        // console.log(blob);
        const link = document.createElement("a");
        link.download = 'billing.csv';
        link.href = url;
        link.click();
    };

    const changeSelectedKwHandler = (kw, type) => {
        if (kw === undefined || kw === null) {
            if (type === 'select'){
                setSelectKwList(filteredUploaded);
            } else if (type === 'deselect'){
                setSelectKwList([]);
            } 
        } else {
            const item_included = selectKwList.includes(kw)
            if (item_included){
                const new_list = selectKwList.filter(item => !(item === kw));
                setSelectKwList(new_list);
            }else {
                setSelectKwList(c => [...c, kw]);
            }
            
        }
        console.log(selectKwList);
    };

    return (
        <div className="d-flex flex-column h-100">
            <h1 className="text-center my-4">Invoice Parser</h1>
            <div className='d-flex my-4'>
                <div className='d-flex col-md-6'>
                    <div className={`d-flex flex-column m-4 w-100`} style={{height: '300px'}}
                        // onDrop={handleDrop}
                        // onDragOver={handleDragOver}
                        >
                        <div className='d-flex flex-column align-items-center justify-content-center bg-light h-100 w-100' style={{border: '2px dashed #dee2e6'}}>
                            {/* <h5>Drag and drop images here</h5> */}
                            <p>Supported files: .JPG, .PNG, .PDF, .DOCX</p>
                            <span>Each file size must be less than 10MB.</span>
                            <h5>--- or ---</h5>
                            <input class="form-control d-none" id="formFile" type="file" multiple accept=".jpg,.png,.pdf, .docx" placeholder="" ref={fileUpload} onChange={e => {
                            if (e.target.files.length > 0) {
                                fileUploadHandler();
                            }
                            }}/>
                            <button type="button" class="btn btn-dark" onClick={e => {fileUpload.current.value = null; fileUpload.current.click()}}>Select from your files</button>
                        </div>
                    </div>
                </div>
                <div className='d-flex col-md-6 flex-column'>
                    <div className='d-flex pb-3 text-nowrap align-items-center'>
                        <h5 className='me-auto'>Uploaded Files:</h5>
                        <input type="text" class="w-50 form-control" placeholder="Search file name..."
                            onChange = {e => filterHandler(e.target.value)}
                        />
                    </div>
                    <div className='d-flex flex-column overflow-auto mb-2' style={{maxHeight: '250px'}}>
                        <ul class="d-flex list-group list-group-flush w-100">
                            {
                                filteredUploaded.map(item => (
                                    <li class="d-flex list-group-item align-items-center justify-content-between">
                                        <div className='d-flex me-2 w-50' role='button' onClick={e => {changeSelectedKwHandler(item)}}>
                                            <input class="form-check-input row-checkbox me-2" style={{minWidth: '14px'}} type="checkbox" checked={selectKwList.includes(item)}/>
                                            <div className='me-2 text-wrap text-break'>{item}</div>
                                        </div>
                                        {
                                            billingItems.map(item => item.invoice_file_name).includes(item) &&
                                            <FontAwesomeIcon className='me-2' icon={faCircleCheck} style={{color: 'green'}} />
                                        }
                                        <button type="button" class="btn" onClick={e => cancelHandler(item)}>
                                            <FontAwesomeIcon icon={faTimesCircle} style={{color: 'grey'}} />
                                        </button>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                    <div className='d-flex align-items-center mt-auto'>
                        <strong className='me-auto'>Total: {filteredUploaded.length} file(s)</strong>
                        <div className='d-flex'>
                            <a href="#" className='me-2' onClick={e => changeSelectedKwHandler(null, 'select')}>Select all</a>
                            <span className='me-2'>|</span>
                            <a href="#" className='me-2' onClick={e => changeSelectedKwHandler(null, 'deselect')}>De-select all</a>
                            <span className='me-2'>|</span>
                            <a href="#" className='me-4' onClick={e => {e.preventDefault(); setUploadedFiles([]); setSelectKwList([]); setfilteredUploaded([])}}>Remove all</a>
                        </div>
                        <button type="button" disabled={selectKwList.length === 0} class="btn btn-primary" onClick={startParsingHandler}>Start parsing</button>
                    </div>
                </div>
            </div>
            <div className='d-flex my-4 flex-column'>
                <div className='d-flex'>
                    <button class="btn btn-primary" type="button" aria-expanded="false" onClick={downloadReportHandler}>
                        <FontAwesomeIcon icon={faDownload} className="pe-2"/>
                        Download
                    </button>
                </div>
                <div className='d-flex overflow-auto my-2' style={{maxHeight: '500px'}}>
                    <table class="table" style={{'table-layout':'fixed', 'word-wrap': 'break-word'}}>
                        <thead className='sticky-top table-primary'>
                            <tr>
                                <th scope="col" style={{'width':'5%'}}>#</th>
                                <th scope="col" style={{'width':'10%'}}>File Name</th>
                                <th scope="col" style={{'width':'10%'}}>Provider Name</th>
                                <th scope="col" style={{'width':'5%'}}>Ref Id</th>
                                <th scope="col" style={{'width':'5%'}}>Issued</th>
                                <th scope="col" style={{'width':'5%'}}>Due</th>
                                <th scope="col" style={{'width':'5%'}}>Item Date</th>
                                <th scope="col" style={{'width':'25%'}}>Item Description</th>
                                <th scope="col" style={{'width':'5%'}}>$</th>
                                <th scope="col" style={{'width':'5%'}}>Qty</th>
                                <th scope="col" style={{'width':'5%'}}>Unit</th>
                                <th scope="col" style={{'width':'5%'}}>VAT</th>
                                <th scope="col" style={{'width':'5%'}}>Amount</th>
                            </tr>
                        </thead>
                            <tbody>
                                {
                                    billingItems.map(item => (
                                        item.items.map((d, idx) => (
                                            <tr>
                                                <th scope="row">{idx+1}</th>
                                                <td>{item.invoice_file_name}</td>
                                                <td>{item.provider_name}</td>
                                                <td>{item.reference_id}</td>
                                                <td>{item.invoice_date}</td>
                                                <td>{item.due_date}</td>
                                                <td>{d.date}</td>
                                                <td>{d.item_description}</td>
                                                <td>{d.currency_code}</td>
                                                <td>{d.quantity}</td>
                                                <td>{d.unit_price}</td>
                                                <td>{d.vat}</td>
                                                <td>{d.amount}</td>
                                            </tr>
                                        ))
                                    ))           
                                }
                            </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
};

export default PDFParser;
