import { createBrowserRouter, RouterProvider } from "react-router-dom";

import Outline from './pages/outline';
import OutlineEditor from './pages/outline_editor';
import CheckPlagiarism from './pages/plagia';
import Cluster from './pages/kw_cluster';
import RankTracking from "./pages/rank_tracker";
import ManageRankTracking from "./pages/manage_rank_track";
import KPIReport from "./pages/kpi_monthly_report";
import KwResearch from './pages/kw_research';
import OnpageDetail from "./pages/onpage_detail";
import Onpage from './pages/onpage';
import Demo from './pages/demo';
import QABot from "./pages/qa_bot";
import SerpVolatility from "./pages/serp_volatility";
import KWSuggestion from "./pages/kw_suggestion";
import RankedKws from "./pages/ranked_kws";
import Login, {action as authAction} from './pages/login';
import Backlink from "./pages/backlink";
import ContentGap from "./pages/content_gap";
import { tokenLoader, checkAuth } from './util/token';
import {action as logoutAction} from './pages/logout';
import RootPage from "./pages/root_page";
import ErrorPage from "./pages/error_page";
import UserProfile from "./pages/profile";
import ManageTasks from "./pages/task";
import ContentAudit from "./pages/content_audit";
import MailChimp from "./pages/mailchimp";
import Traffic from "./pages/traffic";
import TawkTo from "./pages/tawkto";
import Accounting from "./pages/accounting_report";
import PDFParser from "./pages/pdf_parser";
import DataAnnotate from "./pages/data_annotate";



const router = createBrowserRouter([
  {path: '/',
  element: <RootPage />,
  errorElement: <ErrorPage />,
  id: 'root',
  loader: tokenLoader,
  children:[
    {index: true, element: <OutlineEditor />, loader: checkAuth},
    {path: 'check-duplicate', element: <CheckPlagiarism />, loader: checkAuth},
    {path: 'keyword-cluster', element: <Cluster />, loader: checkAuth},
    {path: 'rank-tracking', element: <RankTracking />, loader: checkAuth},
    {path: 'kpi-report', element: <KPIReport />},
    {path: 'manage-rank-tracking', element: <ManageRankTracking />, loader: checkAuth},
    {path: 'qa-bot', element: <QABot />},
    {path: 'serp-volatility', element: <SerpVolatility />, loader: checkAuth},
    {path: 'logout', action: logoutAction},
    {path: 'user-profile', element: <UserProfile />, loader: checkAuth},
    {path: 'ranked-keywords', element: <RankedKws />, loader: checkAuth},
    {path:'manage-tasks', element: <ManageTasks />, loader: checkAuth},
    {path:'keyword-research', element: <KwResearch />, loader: checkAuth},
    {path:'technical-audit', element: <Onpage />, loader: checkAuth},
    {path:'technical-audit/detail', element: <OnpageDetail />, loader: checkAuth},
    {path:'keyword-suggestion', element: <KWSuggestion />, loader: checkAuth},
    {path:'backlink-audit', element: <Backlink />, loader: checkAuth},
    {path:'keyword-gap', element: <ContentGap />, loader: checkAuth},
    {path:'content-audit', element: <ContentAudit />, loader: checkAuth},
    {path:'mailchimp-report', element: <MailChimp />, loader: checkAuth},
    {path:'traffic-analytic', element: <Traffic />, loader: checkAuth},
    {path:'tawkto-message', element: <TawkTo />},
    {path:'data-annotate', element: <DataAnnotate />, loader: checkAuth},
    {path:'accounting-report', element: <Accounting />, loader: checkAuth},
    {path: 'invoice-parser', element: <PDFParser />},
    {path:'demo', element: <Demo />}
  ]
  },
  {path: '/login',
    element: <Login />,
    action: authAction
    }
]);

function App() {
  return <RouterProvider router={router} />
}

export default App;
