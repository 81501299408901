import Menu from "../components/menu";
import Head from "../components/loading-bar";
import { Outlet, useLoaderData, useSubmit, NavLink } from "react-router-dom";
import { useEffect, useState, useMemo } from "react";
import { getTokenDuration } from "../util/token";
import { Collapse } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp, faArrowUp, faChartLine, faFile, faGlobe, faHome, faLink, faListCheck, faSearch } from "@fortawesome/free-solid-svg-icons";
import { faMailchimp } from '@fortawesome/free-brands-svg-icons';

function RootPage() {

    const token = useLoaderData();
    const submit = useSubmit();
    const [open, setOpen] = useState([]);
    useEffect(() => {
        if (!token){
            return;
        }
    
        if(token==='EXPIRED'){
            submit(null, {action: '/logout', method: 'post'});
        }
        const tokenDuration = getTokenDuration();
        console.log(tokenDuration);

        setTimeout(()=>{
            submit(null, {action: '/logout', method: 'post'});
        }, 1*60*60*1000);
    },[token]);
    
    return (
        <>
            <div className='d-flex vh-100'>
                <div className='d-flex col-md-2 bg-dark flex-column p-3'>
                    <a class="navbar-brand" href="#">
                        <img src="logo-gleads-white.png" alt="" width="105" height="48" class="d-inline-block align-text-top" />
                    </a>
                    <hr className='bg-white' />
                    <ul class="nav nav-pills flex-column mb-auto">
                        {/* <li class="nav-item">
                            <a href="#" className="nav-link text-white">
                                <FontAwesomeIcon icon={faHome} inverse className='me-2'/>
                                Home
                            </a>
                        </li> */}
                        <li>
                            <a href="#collapse1" class="nav-link text-white align-self-center"
                                onClick={(e) => {e.preventDefault();
                                    if (!open.includes("collapse1")){
                                        setOpen(c => [...c, 'collapse1']);
                                    } else{
                                        let d = open.filter(item => item !== 'collapse1');
                                        setOpen(d);
                                    }
                                }}
                            >
                                <FontAwesomeIcon icon={faSearch} inverse className='me-2'/>
                                Keyword Research
                                <FontAwesomeIcon icon={open.includes("collapse1") ? faAngleUp : faAngleDown} inverse className='ms-2'/>
                            </a>
                            {
                                useMemo(() =>
                                <Collapse in={open.includes('collapse1') ? true : false}>
                                    <ul class="nav nav-pills flex-column collapse ms-2" id='collapse1'>
                                        <li className='nav-item'>
                                            <NavLink to="/keyword-research" className="nav-link text-white">
                                                Keyword Planner
                                            </NavLink>
                                        </li>
                                        <li className='nav-item'>
                                            <NavLink to="/keyword-suggestion" className="nav-link text-white">
                                                Keyword Suggestion
                                            </NavLink>
                                        </li>
                                        <li className='nav-item'>
                                            <NavLink to="/keyword-cluster" className="nav-link text-white">
                                                Keyword Cluster
                                            </NavLink>
                                        </li>
                                        <li className='nav-item'>
                                            <NavLink to="/ranked-keywords" className="nav-link text-white">
                                                Competitors Audit
                                            </NavLink>
                                        </li>
                                        <li className='nav-item'>
                                            <NavLink to="/keyword-gap" className="nav-link text-white">
                                                Keyword Gap
                                            </NavLink>
                                        </li>
                                    </ul>
                                </Collapse>
                                ,[open])
                            }
                        </li>
                        <li>
                            <a href="#" class="nav-link text-white"
                                onClick={(e) => {e.preventDefault();
                                    if (!open.includes("collapse2")){
                                        setOpen(c => [...c, 'collapse2']);
                                    } else{
                                        let d = open.filter(item => item !== 'collapse2');
                                        setOpen(d);
                                    }
                                }}
                            >
                            <FontAwesomeIcon icon={faFile} inverse className='me-2'/>
                            Content
                            <FontAwesomeIcon icon={open.includes("collapse2") ? faAngleUp : faAngleDown} inverse className='ms-2'/>
                            </a>
                            { useMemo(() =>
                            <Collapse in={open.includes('collapse2') ? true : false}>
                                <ul class="nav nav-pills flex-column collapse ms-2" id='collapse2'>
                                    <li class="nav-item">
                                        <NavLink to="/" className="nav-link text-white">
                                            Outline
                                        </NavLink>
                                    </li>
                                    <li class="nav-item">
                                        <NavLink to="/check-duplicate" className="nav-link text-white">
                                            Check Duplicate
                                        </NavLink>
                                    </li>
                                    <li class="nav-item">
                                        <NavLink to="/content-audit" className="nav-link text-white">
                                            Content Audit
                                        </NavLink>
                                    </li>
                                </ul>
                            </Collapse>, [open])}
                        </li>
                        <li>
                            <NavLink to="/backlink-audit" className="nav-link text-white">
                                <FontAwesomeIcon icon={faLink} inverse className='me-2'/>
                                Backlink Audit
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/technical-audit" className="nav-link text-white">
                                <FontAwesomeIcon icon={faListCheck} inverse className='me-2'/>
                                Technical Audit
                            </NavLink>
                        </li>
                        <li>
                            <a href="#" class="nav-link text-white"
                                onClick={(e) => {e.preventDefault();
                                    if (!open.includes("collapse3")){
                                        setOpen(c => [...c, 'collapse3']);
                                    } else{
                                        let d = open.filter(item => item !== 'collapse3');
                                        setOpen(d);
                                    }
                                }}
                            >
                            <FontAwesomeIcon icon={faChartLine} inverse className='me-2'/>
                            Rank Tracking
                            <FontAwesomeIcon icon={open.includes("collapse3") ? faAngleUp : faAngleDown} inverse className='ms-2'/>
                            </a>
                            { useMemo(() =>
                            <Collapse in={open.includes('collapse3') ? true : false}>
                                <ul class="nav nav-pills flex-column collapse ms-2" id='collapse3'>
                                    <li class="nav-item">
                                        <NavLink to="/rank-tracking" className="nav-link text-white">
                                            Rank Tracker
                                        </NavLink>
                                    </li>
                                    <li class="nav-item">
                                        <NavLink to="/serp-volatility" className="nav-link text-white">
                                            SERP Volatility
                                        </NavLink>
                                    </li>
                                    <li class="nav-item">
                                        <NavLink to="/manage-rank-tracking" className="nav-link text-white">
                                            Manage Tracker
                                        </NavLink>
                                    </li> 
                                </ul>
                            </Collapse>, [open])}
                        </li>
                        <li>
                            <NavLink to="/mailchimp-report" className="nav-link text-white">
                                <FontAwesomeIcon icon={faMailchimp} inverse className='me-2' style={{color: 'white'}}/>
                                Mailchimp Report
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/traffic-analytic" className="nav-link text-white">
                                <FontAwesomeIcon icon={faGlobe} inverse className='me-2' style={{color: 'white'}}/>
                                Traffic Analytic
                            </NavLink>
                        </li>
                    </ul>
                </div>
                <div className='d-flex col-md-10 flex-column'>
                    <Head />
                    <Menu className='sticky-top'/>
                    <div className="overflow-auto h-100">
                        <div className='container'>
                            <Outlet />
                        </div>
                    </div>
                </div>
            </div>
            
        </>
    )
};

export default RootPage;
