import { useState, useRef, useEffect, useMemo } from 'react';
import {showLoading, hideLoading } from 'react-redux-loading-bar';
import { useDispatch } from "react-redux";

import { OverlayTrigger, Popover, ProgressBar } from 'react-bootstrap';
import { AuditGroup } from "../components/cart-item";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DatePicker from "../components/datepick";

import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap/dist/js/bootstrap.min.js";
import * as bootstrap from 'bootstrap/dist/js/bootstrap';
import { v4 as uuid4 } from 'uuid';

import {
    ResponsiveContainer,
    Tooltip,
    PieChart, Pie, Cell,
    AreaChart,
    CartesianGrid,
    XAxis,
    YAxis,
    Legend,
    Area,
    BarChart,
    Bar,
    Line,
    ComposedChart
  } from "recharts";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash, faDownload, faRefresh, faFilter, faMinus, faArrowUp, faArrowDown, faSort, faUpRightFromSquare, faCheckCircle, faTimesCircle, faAngleUp, faAngleDown, faGlobe, faLanguage, faPlus,
  faSquareCheck,
  faArrowCircleLeft,
  faEye,
  faFlag,
  faArrowUpRightFromSquare,
  faFolder,
  faBullseye,
  faCircle,
  faLineChart
} from "@fortawesome/free-solid-svg-icons";
import { getAuthToken } from '../util/token';
import { useSearchParams } from "react-router-dom";
import Papa from 'papaparse';
import moment from "moment";
import * as d3 from "d3";



function Traffic() {

    const dispatch = useDispatch();
    //auth token
    const token = getAuthToken();
    const userName = localStorage.getItem('username');
    // console.log('username: ', userName);

    const [filterArr, setFilterArr] = useState([{
        id: uuid4(),heading: 'Actions_EntryPageUrl', operation: 'contains', value: ''
      }]);
    
    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
    
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [sort,setSort] = useState('');
    const [startDate, setStartDate] = useState((new Date()).getTime());
    const [endDate, setEndDate] = useState((new Date()).getTime());
    const [startDateComp, setStartDateComp] = useState((new Date()).getTime() - 86400*1000);
    const [endDateComp, setEndDateComp] = useState((new Date()).getTime() - 86400*1000);

    // metrics
    const [totalUsers, setTotalUsers] = useState('');
    const [avgBounce, setAvgBounce] = useState('');
    const [avgTOS, setAvgTOS] = useState('');
    const [totalConv, setTotalConv] = useState('');
    const [convRate, setConvRate] = useState('');
    const [convType, setConvType] = useState([]);
    const [mode, setMode] = useState('conv');

    // comparison periods metrics
    const [prevTotalUsers, setPrevTotalUsers] = useState('');
    const [prevAvgBounce, setPrevAvgBounce] = useState('');
    const [prevAvgTOS, setPrevAvgTOS] = useState('');
    const [prevTotalConv, setPrevTotalConv] = useState('');
    const [prevConvRate, setPrevConvRate] = useState('');

    //component chart
    const [timeSeriesData, setTimeSeriesData] = useState([]);
    const [freqChart, setFreqChart] = useState([]);
    const [displayFreq, setDisplayFreq] = useState('Daily');

    const [convertedUsers, setConvertedUsers] = useState([]);
    const [displayMode, setDisplayMode] = useState('converted_user_list');
    const [convertedUserHistory, setConvertedUserHistory] = useState([]);

    const [engagedMetric, setEngagedMetric] = useState('bounce_rate');
    const [conversionMetric, setConversionMetric] = useState('total_conv');
    const [engagedMinVisits, setEngagedMinVisits] = useState('');
    const [topEngaged, setTopEngaged] = useState(10);
    const [convertedMinVisits, setConvertedMinVisits] = useState('');
    const [topConverted, setTopConverted] = useState(10);
    const [rankingProgress, setRankingProgress] = useState([]);
    const [activeURL, setActiveURL] = useState('');
    const [rankingDetail, setRankingDetail] = useState([]);
    const [referrerPerf, setReferrerPerf] = useState([]);
    const [referrerType, setReferrerType] = useState([]);
    const [referrerName, setReferrerName] = useState([]);
    const [referrerMetric, setReferrerMetric] = useState('nb_uniq_visitors');
    const [referrerChannel, setReferrerChanel] = useState('Search Engines');
    const [topCategory, setTopCategory] = useState('');

    const [comparisonMode, setComparisonMode] = useState(true);
    const [comparedData, setComparedData] = useState([]);
    const [ kwRankProgress, setKwRankProgress ] = useState([]);


    const updateFilterHandler = (filterID, type, value) => {
        const newArr = filterArr.filter(item => {
            if(item.id === filterID){
                const d = item;
                item[type] = value;
                return d
            }else {
                return item
            }
        });
        setFilterArr(newArr);
        // console.log(filterArr);
    };
    const deleteFilterHandler = (filterID) => {
    let arr = []
    if (filterArr.length === 1){
        arr = [{
        id: uuid4(),heading: 'Actions_EntryPageUrl', operation: 'contains', value: ''
    }]
    } else {
        arr = filterArr.filter(item => item.id !== filterID)
    }
    setFilterArr(arr);
    // console.log(filterArr);
    };
    const incFilterHandler = (filterID) => {
    if(filterArr.length === 3 ) return;
    setFilterArr( c => {
        const d = [...c];
        d.push({id: filterID ,heading: 'Actions_EntryPageUrl', operation: 'contains', value: ''});
        return d
    });
    // console.log(filterArr);
    };
    const applyFilterHandler = () => {
    //filterArr[{id, heading, operation, value}]
        console.log(filterArr);
        console.log('re render');
        let arr = [];
        data.map(item => {
            let count = 0;
            filterArr.map(condition => {
                if (condition.operation === 'contains'){
                    count += eval(`"${item[condition.heading]}".toLowerCase().includes("${condition.value}".toLowerCase())`)
                }else if (condition.operation === 'does not contain'){
                    count += eval(`!"${item[condition.heading]}".toLowerCase().includes("${condition.value}".toLowerCase())`)
                }else if (condition.operation === 'is'){
                    // console.log(`"${item[condition.heading]}" === "${condition.value}"`);
                    count += eval(`"${item[condition.heading]}" === "${condition.value}"`)
                }else if (condition.operation === 'is not'){
                    count += eval(`"${item[condition.heading]}" !== "${condition.value}"`)
                }else if(condition.operation === 'greater than') {
                    count += eval(`${item[condition.heading]} > ${condition.value}`)
                }else if (condition.operation === 'greater than or equal'){
                    count += eval(`${item[condition.heading]} >= ${condition.value}`)
                }else if (condition.operation === 'less than'){
                    count += eval(`${item[condition.heading]} < ${condition.value}`)
                }else if (condition.operation === 'less than or equal'){
                    count += eval(`${item[condition.heading]} <= ${condition.value}`)
                }
            })
            if (count === filterArr.length){
                arr.push(item)
            }
        });
        setFilteredData(arr);
        console.log(arr)
    };

    const urlFilter =  (
        <Popover id="popover-positioned-top" title="Popover bottom" style={{"max-width":"500px", 'border': 'none'}}>
            <div className='d-flex flex-column p-4 bg-white shadow'>
                <p>Filter URLs</p>
                {
                    filterArr.map(item => {
                        // console.log(item);
                        return (
                            <div className='d-flex align-items-center' key={item}>
                                where
                                <select class="form-select form-select-sm" aria-label="Default select example" className='ms-3 me-2' style={{maxWidth: '100px'}}
                                    onChange={e => updateFilterHandler(item.id, 'heading', e.target.value)}
                                >
                                  {/* <Form.Select aria-label="Default select example"> */}
                                  <option value='Actions_EntryPageUrl' selected={item['heading'] === 'Actions_EntryPageUrl'}>Entry Page</option>
                                  <option value='category' selected={item['heading'] === 'category'}>Category</option>
                                  <option value='users' selected={item['heading'] === 'users'}>Users</option>
                                  <option value='nb_visits' selected={item['heading'] === 'nb_visits'}>Sessions</option>
                                  <option value='avg_time_on_page' selected={item['heading'] === 'avg_time_on_page'}>Avg. time on page</option>
                                  <option value='bounce_rate' selected={item['heading'] === 'bounce_rate'}>Bounce rate</option>
                                  <option value='goal_2_conversion' selected={item['heading'] === 'goal_2_conversion'}>order success</option>
                                  <option value='goal_3_conversion' selected={item['heading'] === 'goal_3_conversion'}>contact form</option>
                                  <option value='goal_4_conversion' selected={item['heading'] === 'goal_4_conversion'}>live chat</option>
                                  <option value='total_conv' selected={item['heading'] === 'total_conv'}>Total conversion</option>
                                  <option value='conv_rate' selected={item['heading'] === 'conv_rate'}>Conversion rate</option>
                                  {/* </Form.Select> */}
                                </select>
                                <select class="form-select form-select-sm" aria-label="Default select example" className='me-2'
                                    onChange={e => updateFilterHandler(item.id, 'operation', e.target.value)}
                                >
                                    <option value='contains' selected={item.operation === '' ? true : item.operation === 'contains' ? true : false}>contains</option>
                                    <option value="does not contain" selected={item.operation === 'does not contain'}>does not contain</option>
                                    <option value="is" selected={item.operation === 'is'}>is</option>
                                    <option value="is not" selected={item.operation === 'is not'}>is not</option>
                                    <option value="greater than" selected={item.operation === 'greater than'}>greater than</option>
                                    <option value="greater than or equal" selected={item.operation === 'greater than or equal'}>greater than or equal</option>
                                    <option value="less than" selected={item.operation === 'less than'}>less than</option>
                                    <option value="less than or equal" selected={item.operation === 'less than or equal'}>less than or equal</option>
                                </select>
    
                                <input type="text" className="form-control me-2" style={{width: '120px'}} placeholder="Enter a value"
                                    defaultValue={item.value}
                                    onChange={e => updateFilterHandler(item.id, 'value', e.target.value)}
                                />
                                <button className='btn'
                                    onClick={e => deleteFilterHandler(item.id)}
                                ><FontAwesomeIcon icon={faTrash} /></button>
                            </div>
                        )
                    })
                }
                <div className='d-flex mt-4'>
                    <button type="button" class={`btn btn-light me-auto ${filterArr.length === 3 && 'disabled'}`} onClick={e => incFilterHandler(uuid4())}>+ Add condition</button>
                    <button type="button" class="btn btn-primary" onClick={applyFilterHandler}>Apply</button>
                </div>
                
            </div>
        </Popover>
    );

    const sortHandler = (data, mode) => {
        if (sort !== `${mode}_inc` && sort !== `${mode}_dec`){
            setSort(`${mode}_inc`);
            data.sort((a,b) => (b[mode] > a[mode]) ? -1 : (b[mode] < a[mode]) ? 1 : 0)
        } else if(sort === `${mode}_dec`){
            setSort(`${mode}_inc`);
            data.sort((a,b) => (b[mode] > a[mode]) ? -1 : (b[mode] < a[mode]) ? 1 : 0)
        } else if(sort === `${mode}_inc`){
            setSort(`${mode}_dec`);
            data.sort((a,b) => (b[mode] > a[mode]) ? 1 : (b[mode] < a[mode]) ? -1 : 0)
        };
    };

    const applyDateRangeHandler = (start, end, startcomp, endcomp, domain, comparisonMode) => {
        setComparisonMode(comparisonMode);
        console.log(comparisonMode);
        let unix_start_time = (new Date(start)).getTime();
        let unix_end_time = (new Date(end)).getTime();

        let unix_start_comp = (new Date(startcomp)).getTime();
        let unix_end_comp = (new Date(endcomp)).getTime();

        if (startcomp === '' || endcomp === ''){
            unix_start_comp = '';
            unix_end_comp = '';
          } else if (!comparisonMode){
            unix_start_comp = '';
            unix_end_comp = '';
          };
        console.log(unix_start_comp, unix_end_comp)
        setStartDateComp( c => unix_start_comp);
        setEndDateComp( c => unix_end_comp);

        setStartDate(c => unix_start_time);
        setEndDate(c => unix_end_time);
        document.getElementById("startEnd").value = `${start} - ${end}`;
        if(isNaN(unix_start_time) && isNaN(unix_end_time)) {
            unix_start_time = '';
            unix_end_time = '';
        }else if(isNaN(unix_end_time)){
        unix_end_time = '';
        } else if(isNaN(unix_start_time)){
        unix_start_time = '';
        };

        const input = {
            start: unix_start_time,
            end: unix_end_time,
            startComp: unix_start_comp,
            endComp: unix_end_comp,
            site: 'bbcincorp.com',
            report_id: 2
        };
        console.log(input);
        dispatch(showLoading());
        fetch(`${process.env.REACT_APP_HOST_IP}/api/performance-by-pages`, {
            method: 'POST',
            body: JSON.stringify(input),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
                'X-User': userName
            },
        }).then(
                response => {
                    dispatch(hideLoading());
                    return response.json()
                }
        ).then (
            (data) =>{
                console.log(data);
                let d = {};
                let p = {}
                let timeseries = {};
                data['current'].map(item => {
                    // aggregate by page
                    if (!Object.keys(d).includes(item['Actions_EntryPageUrl'])){
                        d[item['Actions_EntryPageUrl']] = {
                                        ...item,
                                        users: 1,
                                        date: item['date']*1000,
                                        CoreHome_VisitorId: []
                                    };
                        if ((item['goal_2_conversion'] + item['goal_3_conversion'] + item['goal_4_conversion']) > 0){
                            d[item['Actions_EntryPageUrl']]['CoreHome_VisitorId'] = [`${item['CoreHome_VisitorId']}<<>>${item['date']}`]
                        }
                    } else {
                        d[item['Actions_EntryPageUrl']]['bounce_count'] += item['bounce_count'];
                        d[item['Actions_EntryPageUrl']]['nb_visits'] += item['nb_visits'];
                        d[item['Actions_EntryPageUrl']]['users'] += 1;
                        d[item['Actions_EntryPageUrl']]['sum_corehome_visittotaltime'] += item['sum_corehome_visittotaltime'];
                        if ((item['goal_2_conversion'] + item['goal_3_conversion'] + item['goal_4_conversion']) > 0){
                            d[item['Actions_EntryPageUrl']]['CoreHome_VisitorId'].push(`${item['CoreHome_VisitorId']}<<>>${item['date']}`)
                        }
                        //unique conversion
                        if (item['goal_2_conversion'] > 0){
                            d[item['Actions_EntryPageUrl']]['goal_2_conversion'] += 1
                        } else if (item['goal_3_conversion'] > 0){
                            d[item['Actions_EntryPageUrl']]['goal_3_conversion'] += 1
                        } else if (item['goal_4_conversion'] > 0){
                            d[item['Actions_EntryPageUrl']]['goal_4_conversion'] += 1
                        }
                    };
                    //aggregate by date
                    if (!Object.keys(timeseries).includes(item['date'].toString())){
                        timeseries[item['date']] = {
                                        ...item,
                                        users: 1
                                    };
                    } else {
                        timeseries[item['date']]['bounce_count'] += item['bounce_count'];
                        timeseries[item['date']]['nb_visits'] += item['nb_visits'];
                        timeseries[item['date']]['users'] += 1;
                        timeseries[item['date']]['sum_corehome_visittotaltime'] += item['sum_corehome_visittotaltime'];
                        //unique conversion
                        if (item['goal_2_conversion'] > 0){
                            timeseries[item['date']]['goal_2_conversion'] += 1
                        } else if (item['goal_3_conversion'] > 0){
                            timeseries[item['date']]['goal_3_conversion'] += 1
                        } else if (item['goal_4_conversion'] > 0){
                            timeseries[item['date']]['goal_4_conversion'] += 1
                        }
                    };
                });

                if (data['previous'].length > 0){
                    data['previous'].map(item => {
                        // aggregate by page
                        if (!Object.keys(p).includes(item['Actions_EntryPageUrl'])){
                            p[item['Actions_EntryPageUrl']] = {
                                            ...item,
                                            users: 1,
                                            date: item['date']*1000,
                                            CoreHome_VisitorId: []
                                        };
                            if ((item['goal_2_conversion'] + item['goal_3_conversion'] + item['goal_4_conversion']) > 0){
                                p[item['Actions_EntryPageUrl']]['CoreHome_VisitorId'] = [`${item['CoreHome_VisitorId']}<<>>${item['date']}`]
                            }
                        } else {
                            p[item['Actions_EntryPageUrl']]['bounce_count'] += item['bounce_count'];
                            p[item['Actions_EntryPageUrl']]['nb_visits'] += item['nb_visits'];
                            p[item['Actions_EntryPageUrl']]['users'] += 1;
                            p[item['Actions_EntryPageUrl']]['sum_corehome_visittotaltime'] += item['sum_corehome_visittotaltime'];
                            if ((item['goal_2_conversion'] + item['goal_3_conversion'] + item['goal_4_conversion']) > 0){
                                p[item['Actions_EntryPageUrl']]['CoreHome_VisitorId'].push(`${item['CoreHome_VisitorId']}<<>>${item['date']}`)
                            }
                            //unique conversion
                            if (item['goal_2_conversion'] > 0){
                                p[item['Actions_EntryPageUrl']]['goal_2_conversion'] += 1
                            } else if (item['goal_3_conversion'] > 0){
                                p[item['Actions_EntryPageUrl']]['goal_3_conversion'] += 1
                            } else if (item['goal_4_conversion'] > 0){
                                p[item['Actions_EntryPageUrl']]['goal_4_conversion'] += 1
                            }
                        };
                    });
                    // setComparedDataObject(p);
                    // console.log('compared data object :', p);
                    
                    let p_result = [];
                    Object.entries(p).map(([k, v]) => {
                        let m = {
                            ...v,
                            bounce_rate: v['bounce_count']*100/v['nb_visits'],
                            avg_time_on_page: v['sum_corehome_visittotaltime']/v['nb_visits'],
                            conv_rate: (v['goal_2_conversion'] + v['goal_3_conversion'] + v['goal_4_conversion'])*100/v['users'],
                            total_conv: v['goal_2_conversion'] + v['goal_3_conversion'] + v['goal_4_conversion']
                        };
                        p_result.push(m);
                        p[k] = m;
                    });
                    setComparedData(p_result);
                    // console.log(p_result);
                }
                
                // console.log(p);
                let result = [];
                let t_result = [];
                Object.entries(d).map(([k, v]) => {
                    let n = {
                        ...v,
                        bounce_rate: v['bounce_count']*100/v['nb_visits'],
                        avg_time_on_page: v['sum_corehome_visittotaltime']/v['nb_visits'],
                        conv_rate: (v['goal_2_conversion'] + v['goal_3_conversion'] + v['goal_4_conversion'])*100/v['users'],
                        total_conv: v['goal_2_conversion'] + v['goal_3_conversion'] + v['goal_4_conversion']
                    };
                    if (Object.keys(p).includes(k)){
                        let keys = Object.keys(p[k]);
                        keys.includes('users') ? n['users_comp'] = (n['users'] - p[k]['users']) : n['users_comp'] = '';
                        keys.includes('nb_visits') ? n['nb_visits_comp'] = (n['nb_visits'] - p[k]['nb_visits']) : n['nb_visits_comp'] = '';
                        keys.includes('avg_time_on_page') ? n['avg_time_on_page_comp'] = (n['avg_time_on_page'] - p[k]['avg_time_on_page']) : n['avg_time_on_page_comp'] = '';
                        keys.includes('bounce_rate') ? n['bounce_rate_comp'] = (n['bounce_rate'] - p[k]['bounce_rate']) : n['bounce_rate_comp'] = '';
                        keys.includes('goal_2_conversion') ? n['goal_2_conversion_comp'] = (n['goal_2_conversion'] - p[k]['goal_2_conversion']) : n['goal_2_conversion_comp'] = '';
                        keys.includes('goal_3_conversion') ? n['goal_3_conversion_comp'] = (n['goal_3_conversion'] - p[k]['goal_3_conversion']) : n['goal_3_conversion_comp'] = '';
                        keys.includes('goal_4_conversion') ? n['goal_4_conversion_comp'] = (n['goal_4_conversion'] - p[k]['goal_4_conversion']) : n['goal_4_conversion_comp'] = '';
                        keys.includes('total_conv') ? n['total_conv_comp'] = (n['total_conv'] - p[k]['total_conv']) : n['total_conv_comp'] = '';
                        keys.includes('conv_rate') ? n['conv_rate_comp'] = (n['conv_rate'] - p[k]['conv_rate']) : n['conv_rate_comp'] = '';
                    } else {
                        n['users_comp'] = '';
                        n['nb_visits_comp'] = '';
                        n['avg_time_on_page_comp'] = '';
                        n['bounce_rate_comp'] = '';
                        n['goal_2_conversion_comp'] = '';
                        n['goal_3_conversion_comp'] = '';
                        n['goal_4_conversion_comp'] = '';
                        n['total_conv_comp'] = '';
                        n['conv_rate_comp'] = '';
                    }
                    
                    result.push(n);
                });
                Object.values(timeseries).map(item => {
                    t_result.push({
                        ...item,
                        date: item['date']*1000,
                        bounce_rate: item['bounce_count']*100/item['nb_visits'],
                        avg_time_on_page: item['sum_corehome_visittotaltime']/item['nb_visits'],
                        conv_rate: (item['goal_2_conversion'] + item['goal_3_conversion'] + item['goal_4_conversion'])*100/item['users'],
                        total_conv: item['goal_2_conversion'] + item['goal_3_conversion'] + item['goal_4_conversion']
                    })
                });
                console.log(result);
                setData(result);
                setFilteredData(result);
                setTimeSeriesData(t_result);
            })
    };

    useEffect(() => {
        if (filteredData.length > 0) {
            let total_user = 0;
            let total_conv = 0;
            let avg_bounce = [];
            let avg_tos = [];
            // compared metrics
            let prev_total_user = 0;
            let prev_total_conv = 0;
            let prev_avg_bounce = [];
            let prev_avg_tos = [];

            let conv_type = {};
            if (mode === 'conv'){
                conv_type = {
                    order_success: 0,
                    contact_form: 0,
                    live_chat: 0
                };
            }
            
            filteredData.map(item => {
                total_user += item['users'];
                total_conv += item['total_conv'];
                avg_bounce.push(item['bounce_rate']);
                avg_tos.push(item['avg_time_on_page']);
                if (mode === 'conv'){
                    conv_type['order_success'] += item['goal_2_conversion'];
                    conv_type['contact_form'] += item['goal_3_conversion'];
                    conv_type['live_chat'] += item['goal_4_conversion'];
                } else if (mode === 'category'){
                    if (!Object.keys(conv_type).includes(item['category'])){
                        conv_type[item['category']] = item['total_conv']
                    }else {
                        conv_type[item['category']] += item['total_conv']
                    }
                }  
            });
            if (comparedData.length > 0){
                comparedData.map(item => {
                    prev_total_user += item['users'];
                    prev_total_conv += item['total_conv'];
                    prev_avg_bounce.push(item['bounce_rate']);
                    prev_avg_tos.push(item['avg_time_on_page']);
                });
                //compared metrics
                setPrevTotalUsers(prev_total_user);
                setPrevTotalConv(prev_total_conv);
                setPrevConvRate(prev_total_conv*100/prev_total_user);
                setPrevAvgBounce(prev_avg_bounce.reduce((a, c) => a+c, 0)/prev_avg_bounce.length);
                setPrevAvgTOS(prev_avg_tos.reduce((a, c) => a + c, 0)/prev_avg_tos.length);
            }
            setTotalUsers(total_user);
            setTotalConv(total_conv);
            setConvRate(total_conv*100/total_user);
            setAvgBounce(avg_bounce.reduce((a, c) => a+c, 0)/avg_bounce.length);
            setAvgTOS(avg_tos.reduce((a, c) => a + c, 0)/avg_tos.length);
            setConvType(Object.entries(conv_type).map(([k, v]) => {return {name: k, value: v}}));
            // console.log((Object.entries(conv_type).map(([k, v]) => {return {name: k, value: v}})));
        }
    }, [filteredData, mode])

    const datePicker = (
        <Popover id="popover-positioned-top" title="Popover bottom" style={{"max-width":"1000px", 'border': 'none'}}>
            <DatePicker 
                applyDateRangeHandler = {applyDateRangeHandler}
                start = {startDate}
                end = {endDate}
                startComp = {startDateComp}
                endComp = {endDateComp}
                comparisonMode = {comparisonMode}
                hideControl='filter'
            />
        </Popover>
      );

    const downloadReportHandler = () => {
        if(filteredData.length === 0) return;
        let d = filteredData.map(item => {
            return {
                ...item,
                date: new Date(item['date']).toLocaleDateString()
            }
        })
        const csv = Papa.unparse({
            // "fields": ['URL', "Keyword", 'Volume', 'KD', 'CPC'],
            "data": d
        });
        const blob = new Blob([csv]);

        const url = window.URL.createObjectURL(blob, {type: 'text/plain' });
        // console.log(blob);
        const link = document.createElement("a");
        link.download = 'traffic_data.csv';
        link.href = url;
        link.click();
    };

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            // console.log(payload, label);
            return (
            <div className="custom-tooltip p-2">
                {
                   label ?
                    <p className="label">{`Date: ${moment(parseInt(label)).format('DD-MM-YYYY')}`}</p> :
                    <p className="label">{payload[0]['payload']['Referrers_ReferrerName'] || 'Others'}</p>
                }
                <ul className='ps-0' style={{listStyleType: 'none'}}>
                    {
                        payload.map(item => {
                            if (item.dataKey === 'bounce_rate' || item.dataKey === 'conv_rate'){
                                return <li className="">{`${item.dataKey}: ${item.value.toFixed(2)}%`}</li>
                            }
                            return <li className="">{`${item.dataKey}: ${item.value.toLocaleString('en-us')}`}</li>
                        })
                    }
                </ul>
            </div>
            );
            }
    };

    const changeDisplayFreqHandler = (freq) => {
        const end = new Date();
        const endUnix = end.getTime()
        const startUnix = new Date(end.getFullYear(), end.getMonth() - 12, 1).getTime();
        setDisplayFreq(freq);
        if (freq === 'Weekly') {
            const msInWeek = 1000*60*60*24*7;
            const curYear = new Date().getFullYear();
            const first_week = Math.ceil((startUnix - new Date(curYear, 0, 1).getTime())/msInWeek);
            const total_week = Math.round(Math.abs(startUnix - endUnix)/msInWeek);
            let weekly_detail = [];
            for (let i = 0 ; i <=total_week;  i++){
              // token
              let week_object = {
                // ...t,
                date: new Date(curYear, 0, 1).getTime() + (first_week + i)*msInWeek
              };
              timeSeriesData.filter(item => (
                (Math.ceil( (item.date - new Date(curYear, 0, 1).getTime()) /msInWeek ) >= (first_week + i)) && 
                (Math.ceil( (item.date - new Date(curYear, 0, 1).getTime()) /msInWeek) < (first_week + i+1)) )).map((item, index) => {
                Object.keys(item).map(it => {
                  if (it !== 'date'){
                    if (!Object.keys(week_object).includes(it)) {
                        week_object[it] = [item[it]];
                    } else {
                        // console.log(item[it]);
                        week_object[it].push(item[it]);
                    };
                  }
                });
              });
              weekly_detail.push(week_object);
            }
            
            weekly_detail = weekly_detail.filter(item => Object.keys(item).length > 1);
            // console.log(weekly_detail);
            weekly_detail.map(item => {
                Object.keys(item).map(it => {
                  if (['bounce_count', 'goal_2_conversion', 'goal_3_conversion', 'goal_4_conversion', 'nb_visits', 'nb_visits_converted', 'sum_corehome_visittotaltime', 'total_conv', 'users'].includes(it)){
                    if (item[it].length > 0) item[it] = item[it].reduce((a,b) => a + b, 0);
                  } else if (['avg_time_on_page', 'bounce_rate', 'conv_rate'].includes(it)){
                    if (item[it].length > 0) item[it] = (item[it].reduce((a,b) => a + b, 0))/item[it].length;
                  } else if(['Actions_EntryPageUrl', 'category', 'level', 'report_id', 'site']){
                    if (item[it].length > 0) item[it] = item[it][0]
                  }
                })
            });
            // console.log(weekly_detail);
            setFreqChart(weekly_detail);
        } else if(freq === 'Monthly') {
            const startMonth = new Date(startUnix).getMonth();
            const startYear = new Date(startUnix).getFullYear();
            const endMonth = new Date(endUnix).getMonth();
            const endYear = new Date(endUnix).getFullYear();
            const totalMonth = endMonth - startMonth + 12*(endYear - startYear);
            let monthly_detail = [];
            for (let i = 0 ; i <=totalMonth;  i++){
              //token
              let monthly_object = {
                // ...t,
                date: new Date(startYear, startMonth + i, 1).getTime()
              };
              timeSeriesData.filter(item => (
                (item.date >= new Date(startYear, startMonth + i, 1).getTime()) && 
                (item.date < new Date(startYear, startMonth + i + 1, 1).getTime()) )).map((item, index) => {
                Object.keys(item).map(it => {
                  if (it !== 'date'){
                    if (!Object.keys(monthly_object).includes(it)) {
                      monthly_object[it] = [item[it]];
                    } else {
                      monthly_object[it].push(item[it]);
                    };
                  }
                });
              });
              monthly_detail.push(monthly_object);
            };
            monthly_detail = monthly_detail.filter(item => Object.keys(item).length > 1);
            // console.log(monthly_detail);
            monthly_detail.map(item => {
                Object.keys(item).map(it => {
                  if (['bounce_count', 'goal_2_conversion', 'goal_3_conversion', 'goal_4_conversion', 'nb_visits', 'nb_visits_converted', 'sum_corehome_visittotaltime', 'total_conv', 'users'].includes(it)){
                    if (item[it].length > 0) item[it] = item[it].reduce((a,b) => a + b, 0);
                  } else if (['avg_time_on_page', 'bounce_rate', 'conv_rate'].includes(it)){
                    if (item[it].length > 0) item[it] = (item[it].reduce((a,b) => a + b, 0))/item[it].length;
                  } else if(['Actions_EntryPageUrl', 'category', 'level', 'report_id', 'site']){
                    if (item[it].length > 0) item[it] = item[it][0]
                  }
                })
            });
            // console.log(monthly_detail);
            setFreqChart(monthly_detail);
        }
    };

    const fetchConvertedUserHandler = (ids, url) => {
        // console.log(ids);
        setActiveURL(url);
        setDisplayMode('converted_user_list');
        setConvertedUserHistory([]);
        setConvertedUsers([]);
        const input_1 = {
            ids: ids
        };
        // console.log(input_1);
        const input_2 = {
            'start': new Date().getTime() - 86400*1000*90,
            'end': new Date().getTime(),
            // 'start': 1701363600000,
            // 'end': 1704042000000,
            'keyword': '',
            'url': 'https://' + url.replace(/\?.*$/,'')
        };
        // console.log(input_2);
        const input_3 = {
            'start': startDate,
            'end': endDate,
            'url': url.replace(/\?.*$/,'')
        };
        // console.log(input_3);
        dispatch(showLoading());
        Promise.all([
            fetch(`${process.env.REACT_APP_HOST_IP}/api/traffic/ranking-detail`,{
            method: 'POST',
            body: JSON.stringify(input_2),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
                'X-User': userName
                }
        }).then(
                response => {
                    return response.json()
                }
            ).then (
                (data) =>{
                    data['progress'].sort((a,b) => a.date - b.date);
                    // console.log('kw rank: ', data);
                    console.log('ranking detail :', data['detail']);
                    setRankingProgress(data['progress']);
                    setRankingDetail(data['detail']);
                }
            ),
        fetch(`${process.env.REACT_APP_HOST_IP}/api/converted-users`, {
            method: 'POST',
            body: JSON.stringify(input_1),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
                'X-User': userName
            },
        }).then(
                response => {
                    return response.json()
                }
        ).then (
            (data) =>{
                // console.log(data);
                if (data.status_code === 500){
                    const m = new bootstrap.Modal(document.getElementById('ModalAlert'));
                    m.toggle();
                    return
                }
                setConvertedUsers(data);
                const m = new bootstrap.Modal(document.getElementById('ModalConvertedUsers'));
                m.toggle();
            }
        ),
        fetch(`${process.env.REACT_APP_HOST_IP}/api/traffic/referrer-performance`,{
            method: 'POST',
            body: JSON.stringify(input_3),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
                'X-User': userName
                }
        }).then(
                response => {
                    return response.json()
                }
            ).then (
                (data) =>{
                    // console.log('referrer performance: ', data);
                    setReferrerPerf(data);
                    let type = {};
                    let name = {};
                    let pattern = new RegExp(/^goal_.*/);
                    let pattern_form_conv = new RegExp(/^goal_(17|18|23|25|27|29|3|30|31|33|34|35|36|37|39|40|42|43|46|47|48|65|32|49|5|60|64).*/);
                    data.map(item => {
                        // arrange referrerType
                        if ( !Object.keys(type).includes(item['Referrers_ReferrerType'])){
                                type[item['Referrers_ReferrerType']] = {
                                    Referrers_ReferrerType: item['Referrers_ReferrerType'],
                                    sum_corehome_visittotaltime: item['sum_corehome_visittotaltime'],
                                    bounce_count: item['bounce_count'],
                                    goal_2_conversion: item['goal_2_conversion'],
                                    goal_4_conversion: item['goal_4_conversion'],
                                    total_conv: item['goal_2_conversion'] + item['goal_4_conversion'],
                                    nb_uniq_visitors: item['nb_uniq_visitors'],
                                    nb_visits: item['nb_visits']
                                };
                                let mkt_lead = 0;
                                let form_conv = 0;
                                Object.entries(item).map(([k, v]) => {
                                    if (pattern_form_conv.test(k)){
                                        form_conv += v;
                                    }
                                    if (pattern.test(k) && k !== 'goal_2_conversion' && k !== 'goal_3_conversion' && k !== 'goal_4_conversion'){
                                        mkt_lead += v
                                    }
                                });
                                type[item['Referrers_ReferrerType']]['mkt_lead'] = mkt_lead;
                                type[item['Referrers_ReferrerType']]['form_conv'] = form_conv;
                                type[item['Referrers_ReferrerType']]['total_conv'] += form_conv;
                            }  else {
                                type[item['Referrers_ReferrerType']]['sum_corehome_visittotaltime'] += item['sum_corehome_visittotaltime'];
                                type[item['Referrers_ReferrerType']]['bounce_count'] += item['bounce_count'];
                                type[item['Referrers_ReferrerType']]['goal_2_conversion'] += item['goal_2_conversion'];
                                type[item['Referrers_ReferrerType']]['goal_4_conversion'] += item['goal_4_conversion'];
                                type[item['Referrers_ReferrerType']]['nb_uniq_visitors'] += item['nb_uniq_visitors'];
                                type[item['Referrers_ReferrerType']]['nb_visits'] += item['nb_visits'];
                                type[item['Referrers_ReferrerType']]['total_conv'] += item['goal_2_conversion'] + item['goal_4_conversion'];

                                let mkt_lead = 0;
                                let form_conv = 0;
                                Object.entries(item).map(([k, v]) => {
                                    if (pattern_form_conv.test(k)){
                                        form_conv += v;
                                    }
                                    if (pattern.test(k) && k !== 'goal_2_conversion' && k !== 'goal_3_conversion' && k !== 'goal_4_conversion'){
                                        mkt_lead += v
                                    }
                                });
                                type[item['Referrers_ReferrerType']]['mkt_lead'] += mkt_lead;
                                type[item['Referrers_ReferrerType']]['form_conv'] += form_conv;
                                type[item['Referrers_ReferrerType']]['total_conv'] += form_conv;
                            }
                            // arrange referrerName
                            if ( !Object.keys(name).includes(item['Referrers_ReferrerName'])){
                                name[item['Referrers_ReferrerName']] = {
                                    Referrers_ReferrerType: item['Referrers_ReferrerType'],
                                    Referrers_ReferrerName: item['Referrers_ReferrerName'],
                                    sum_corehome_visittotaltime: item['sum_corehome_visittotaltime'],
                                    bounce_count: item['bounce_count'],
                                    goal_2_conversion: item['goal_2_conversion'],
                                    goal_4_conversion: item['goal_4_conversion'],
                                    total_conv: item['goal_2_conversion'] + item['goal_4_conversion'],
                                    nb_uniq_visitors: item['nb_uniq_visitors'],
                                    nb_visits: item['nb_visits'],
                                };
                                let mkt_lead = 0;
                                let form_conv = 0;
                                Object.entries(item).map(([k, v]) => {
                                    if (pattern_form_conv.test(k)){
                                        form_conv += v;
                                    };
                                    if (pattern.test(k) && k !== 'goal_2_conversion' && k !== 'goal_3_conversion' && k !== 'goal_4_conversion'){
                                        mkt_lead += v
                                    }
                                });
                                name[item['Referrers_ReferrerName']]['mkt_lead'] = mkt_lead;
                                name[item['Referrers_ReferrerName']]['form_conv'] = form_conv;
                                name[item['Referrers_ReferrerName']]['total_conv'] += form_conv;
                            }  else {
                                name[item['Referrers_ReferrerName']]['sum_corehome_visittotaltime'] += item['sum_corehome_visittotaltime'];
                                name[item['Referrers_ReferrerName']]['bounce_count'] += item['bounce_count'];
                                name[item['Referrers_ReferrerName']]['goal_2_conversion'] += item['goal_2_conversion'];
                                name[item['Referrers_ReferrerName']]['goal_4_conversion'] += item['goal_4_conversion'];
                                name[item['Referrers_ReferrerName']]['nb_uniq_visitors'] += item['nb_uniq_visitors'];
                                name[item['Referrers_ReferrerName']]['nb_visits'] += item['nb_visits'];
                                name[item['Referrers_ReferrerName']]['total_conv'] += item['goal_2_conversion'] + item['goal_4_conversion'];

                                let mkt_lead = 0;
                                let form_conv = 0;
                                Object.entries(item).map(([k, v]) => {
                                    if (pattern_form_conv.test(k)){
                                        form_conv += v;
                                    };
                                    if (pattern.test(k) && k !== 'goal_2_conversion' && k !== 'goal_3_conversion' && k !== 'goal_4_conversion'){
                                        mkt_lead += v
                                    }
                                });
                                name[item['Referrers_ReferrerName']]['mkt_lead'] += mkt_lead;
                                name[item['Referrers_ReferrerName']]['form_conv'] += form_conv;
                                name[item['Referrers_ReferrerName']]['total_conv'] += form_conv;
                            }
                    });
                    let maxTypeMetric = Math.max(...Object.entries(type).map(([k, v]) => v[referrerMetric]));
                    let maxNameMetric = Math.max(...Object.entries(name).map(([k, v]) => v[referrerMetric]));
                    // console.log(Object.entries(name).map(([k, v]) => {return {...v,
                    //                     bounce_rate: v['bounce_count']/v['nb_visits'],
                    //                     pct: v[referrerMetric]/maxNameMetric
                    //                 }}));
                    // console.log(Object.entries(type).map(([k, v]) => {return {...v,
                    //     bounce_rate: v['bounce_count']/v['nb_visits'],
                    //     pct: v[referrerMetric]/maxTypeMetric
                    // }}));
                    setReferrerType(Object.entries(type).map(([k, v]) => {return {...v,
                                                                                bounce_rate: v['bounce_count']/v['nb_visits'],
                                                                                pct: v[referrerMetric]/maxTypeMetric
                                                                            }}));
                    setReferrerName(Object.entries(name).map(([k, v]) => {return {...v,
                                                                                bounce_rate: v['bounce_count']/v['nb_visits'],
                                                                                avg_tos: v.sum_corehome_visittotaltime/v.nb_visits,
                                                                                pct: v[referrerMetric]/maxNameMetric
                                                                            }}));
                    // console.log(raw);
                }
            )
        ]).then(
                () => dispatch(hideLoading())
            )
    };
    
    const fetchUserActivityHandler = (visitor_id) => {
        setDisplayMode("converted_user_detail");
        convertedUsers.map(item => {
            if (item.visitor_id === visitor_id){
                setConvertedUserHistory([{
                    ...item
                }])
            }
        })
    };
    const countGoal = (activity_list) => {
        let count = 0;
        activity_list.map(item => {
            if(item.type === 'goal'){
                count += 1
            }
        });
        return count
    };

    const convertSecondsToHoursMinutes = (s) => {
        if (s < 3600){
            let minutes = Math.floor(s/60);
            let seconds = Math.floor(s%60);
            return minutes + 'm ' + seconds + 's'
        } else {
            let hours = Math.floor(s/3600);
            let minutes = Math.floor((s%3600)/60);
            return hours + 'h ' + minutes + 'm'
        }
    };

    const fetchRankingHandler = () => {
        setDisplayMode('ranking');
        const input = {
            // 'start': startDate,
            // 'end': endDate,
            'start': 1701363600000,
            'end': 1704042000000,
            'keyword': '',
            'url': 'https://' + activeURL.replace(/\?.*$/,'')
        };
        // console.log(input);
        dispatch(showLoading());
        fetch(`${process.env.REACT_APP_HOST_IP}/api/traffic/ranking-detail`,{
          method: 'POST',
          body: JSON.stringify(input),
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + token,
              'X-User': userName
            }
      }).then(
            response => {
                return response.json()
            }
        ).then (
            (data) =>{
                data['progress'].sort((a,b) => a.date - b.date);
                dispatch(showLoading());
                // console.log('kw rank: ', data);
                setRankingProgress(data['progress']);
            }
        )
    };

    const getAccConvHandler = (idx) => {
        let d = Array.from(filteredData).sort((a,b) => (conversionMetric.search(/_inc/g) !== -1 ? 1 : -1)*(a[conversionMetric.replace(/_inc|_dec/g,'')] - b[conversionMetric.replace(/_inc|_dec/g,'')]))
                                        .filter((item, idx) => ((convertedMinVisits !== '' ? item.nb_visits >= parseInt(convertedMinVisits) : true) && (topCategory === '' ? true : topCategory === item.category)))
                                        .filter((item, idx) => (idx <= topConverted -1));
        let conv = 0;
        let convSum = 0;
        let convPct = 0;
        d.map((item, index) => {
            if (index <= idx){
                conv += item['total_conv']
            };
        });
        filteredData.map(item => {
            convSum += item['total_conv'];
        })
        convPct = conv/convSum;
        return [conv, convPct]
    };

    const popoverChart = (
        <Popover id="popover-positioned-top" title="Popover bottom" style={{"width":"300px", 'height': '180px'}} className="d-flex">
          <ResponsiveContainer width="100%" height="110%">
              <AreaChart
              data={kwRankProgress}
              margin={{
                  top: 10,
                  right: 10,
                  left: -30,
                  bottom: 10,
              }}
              >
                <defs>
                  <linearGradient id="color1" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
                    <stop offset="95%" stopColor="#82ca9d" stopOpacity={0}/>
                  </linearGradient>
                </defs>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                    dataKey="date"
                    name = "date"
                    tickFormatter = {(unixTime) => moment(unixTime).format('YY-MM-DD')}
                    // {...getXAxisArgsForTimeBasedGraph(data.map((item) => item.date))}
    
                />
                <YAxis 
                    domain={[0, 'auto']}
                    reversed
                />
                <Tooltip 
                    content={<CustomTooltip />}
                    wrapperStyle={{ backgroundColor: '#f8f9fa', padding: '2px' }}
                />
                <Area type="monotone" isAnimationActive={false} dataKey="rank" stroke="#82ca9d" fillOpacity={1} fill="url(#color1)"/>
              </AreaChart>
          </ResponsiveContainer>
        </Popover>
        );
    
    const KwRankHandler = (kw, url) => {
        // let start = Math.floor(new Date(startDate.current.value).getTime());
        // let end = Math.floor(new Date(endDate.current.value).getTime())
        let start = new Date().getTime() - 86400*1000*90;
        let end = new Date().getTime();
    
        const input = {
            'start': start,
            'end': end,
            'keyword': kw,
            'url': 'https://' + url
        }
        // console.log(input)
        fetch(`${process.env.REACT_APP_HOST_IP}/api/keyword-rank`,{
            method: 'POST',
            body: JSON.stringify(input),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
                'X-User': userName
                }
        }).then(
                response => {
                    return response.json()
                }
            ).then (
                (data) =>{
                data.sort((a,b) => a.date - b.date);
                console.log('kw rank: ', data);
                setKwRankProgress(data);
                }
            )
        };

    return (
        <div className="d-flex flex-column">
            <h1 className="text-center mt-4 mb-5">Traffic Analytic</h1>
            <div className='d-flex my-4'>
                <div class="input-group ps-3 w-auto ms-auto">
                    <span class="input-group-text" id="basic-addon1">Date range</span>
                    <OverlayTrigger trigger={["click"]} placement="bottom" overlay={datePicker} rootClose container={this} containerPadding={20}>
                        <input type="text" className="form-control" placeholder="from - to" id="startEnd"/>
                    </OverlayTrigger>
                </div>
            </div>
            <div className='d-flex my-4'>
                <div class={`d-flex flex-column col border shadow-sm rounded mx-3 py-2 px-3`}>
                    <b>Total users</b>
                    <h2 className='align-self-center my-3'>
                        {totalUsers === '' ? '-' : totalUsers.toLocaleString('en-us')}
                    </h2>
                    <span style={{minHeight: '21px'}}>
                        {comparedData.length > 0 && comparisonMode && (totalUsers - prevTotalUsers) > 0 ?
                        <FontAwesomeIcon icon={faArrowUp} style={{color:'green'}} className={'me-2'}/> :
                        comparedData.length > 0 && comparisonMode && (totalUsers - prevTotalUsers) < 0 ?
                        <FontAwesomeIcon icon={faArrowDown} style={{color:'red'}} className={'me-2'}/> :
                        comparedData.length > 0 && comparisonMode ?
                        <FontAwesomeIcon icon={faMinus} style={{color:'orange'}} className={'me-2'}/>:
                        null
                        }
                        {comparedData.length > 0 && comparisonMode && Math.abs((totalUsers - prevTotalUsers)).toLocaleString('en-us')}
                    </span>
                </div>
                <div class={`d-flex flex-column col border shadow-sm rounded mx-3 py-2 px-3`}>
                    <b>Avg. bounce rate</b>
                    <h2 className='align-self-center my-3'>
                        {avgBounce === '' ? '-' : `${avgBounce.toFixed(1)}%`}
                    </h2>
                    <span style={{minHeight: '21px'}}>
                        {comparedData.length > 0 && comparisonMode && (avgBounce - prevAvgBounce) < 0 ?
                        <FontAwesomeIcon icon={faArrowUp} style={{color:'green'}} className={'me-2'}/> :
                        comparedData.length > 0 && comparisonMode && (avgBounce - prevAvgBounce) > 0 ?
                        <FontAwesomeIcon icon={faArrowDown} style={{color:'red'}} className={'me-2'}/> :
                        comparedData.length > 0 && comparisonMode ?
                        <FontAwesomeIcon icon={faMinus} style={{color:'orange'}} className={'me-2'}/>:
                        null
                        }
                        {comparedData.length > 0 && comparisonMode && `${Math.abs((avgBounce - prevAvgBounce)).toFixed(2)}%`}
                    </span>
                </div>
                <div class={`d-flex flex-column col border shadow-sm rounded mx-3 py-2 px-3`}>
                    <b>Avg. time on site</b>
                    <h2 className='align-self-center my-3'>
                    {avgTOS === '' ? '-' : `${convertSecondsToHoursMinutes(parseInt(avgTOS))}`}
                    </h2>
                    <span style={{minHeight: '21px'}}>
                        {comparedData.length > 0 && comparisonMode && (avgTOS - prevAvgTOS) > 0 ?
                        <FontAwesomeIcon icon={faArrowUp} style={{color:'green'}} className={'me-2'}/> :
                        comparedData.length > 0 && comparisonMode && (avgTOS - prevAvgTOS) < 0 ?
                        <FontAwesomeIcon icon={faArrowDown} style={{color:'red'}} className={'me-2'}/> :
                        comparedData.length > 0 && comparisonMode ?
                        <FontAwesomeIcon icon={faMinus} style={{color:'orange'}} className={'me-2'}/>:
                        null
                        }
                        {comparedData.length > 0 && comparisonMode && convertSecondsToHoursMinutes(avgTOS - prevAvgTOS)}
                    </span>
                </div>
                <div class={`d-flex flex-column col border shadow-sm rounded mx-3 py-2 px-3`}>
                    <b>Total conv.</b>
                    <h2 className='align-self-center my-3'>
                        {totalConv === '' ? '-' : totalConv}
                    </h2>
                    <span style={{minHeight: '21px'}}>
                        {comparedData.length > 0 && comparisonMode && (totalConv - prevTotalConv) > 0 ?
                        <FontAwesomeIcon icon={faArrowUp} style={{color:'green'}} className={'me-2'}/> :
                        comparedData.length > 0 && comparisonMode && (totalConv - prevTotalConv) < 0 ?
                        <FontAwesomeIcon icon={faArrowDown} style={{color:'red'}} className={'me-2'}/> :
                        comparedData.length > 0 && comparisonMode ?
                        <FontAwesomeIcon icon={faMinus} style={{color:'orange'}} className={'me-2'}/>:
                        null
                        }
                        {comparedData.length > 0 && comparisonMode && Math.abs((totalConv - prevTotalConv)).toLocaleString('en-us')}
                    </span>
                </div>
                <div class={`d-flex flex-column col border shadow-sm rounded mx-3 py-2 px-3`}>
                    <b>Conv. rate </b>
                    <h2 className='align-self-center my-3'>
                    {convRate === '' ? '-' : `${convRate.toFixed(2)}%`}
                    </h2>
                    <span style={{minHeight: '21px'}}>
                        {comparedData.length > 0 && comparisonMode && (convRate - prevConvRate) > 0 ?
                        <FontAwesomeIcon icon={faArrowUp} style={{color:'green'}} className={'me-2'}/> :
                        comparedData.length > 0 && comparisonMode && (convRate - prevConvRate) < 0 ?
                        <FontAwesomeIcon icon={faArrowDown} style={{color:'red'}} className={'me-2'}/> :
                        comparedData.length > 0 && comparisonMode ?
                        <FontAwesomeIcon icon={faMinus} style={{color:'orange'}} className={'me-2'}/>:
                        null
                        }
                        {comparedData.length > 0 && comparisonMode && `${Math.abs((convRate - prevConvRate)).toFixed(2)}%`}
                    </span>
                </div>
                <div class={`d-flex flex-column col border shadow-sm rounded mx-3 py-2 px-3`}>
                    <div className='d-flex'>
                        <b>Conv. Type</b>
                        <select class="form-select form-select-sm" aria-label="Default select example" style={{width: '90px'}}
                        onChange={e => setMode(e.target.value)}
                        >
                            <option selected value='conv'>conv</option>
                            <option value="category">category</option>
                        </select>
                    </div>
                    <div className='d-flex align-items-center w-100' style={{height: '80px'}}>
                        {   convType.length > 0 &&
                            <ResponsiveContainer width="100%" height="100%">
                            <PieChart
                                margin={{
                                    top: 0,
                                    right: 10,
                                    left: 10,
                                    bottom: -90,
                                }}
                            >
                            <Pie
                                dataKey="value"
                                startAngle={180}
                                endAngle={0}
                                data={convType}
                                cx="50%"
                                cy="50%"
                                innerRadius={40}
                                outerRadius={80}
                                paddingAngle={2}
                                fill="#8884d8"
                            >
                                {convType.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                ))}
                            </Pie>
                            <Tooltip />
                            </PieChart>
                        </ResponsiveContainer>}
                    </div>
                </div>
            </div>
            <hr />
            <div className='d-flex my-4'>
                <div className='d-flex flex-column col-md-6 pe-3'>
                    <div className='d-flex align-items-center'>
                        <h5 className='text-nowrap me-2'>Best/Worst Engaged Pages</h5>
                        <select class="form-select me-auto w-auto" aria-label="Default select example"
                            onChange = {e => setTopEngaged(e.target.value)}
                        >
                            <option value='10'>Top 10</option>
                            <option value="20">Top 20</option>
                            <option value="30">Top 30</option>
                        </select>
                        <input type="text" class="form-control w-auto" placeholder="Specify min visits" onChange={e => setEngagedMinVisits(e.target.value)}/>
                    </div>
                    <div className='d-flex overflow-auto my-2' style={{maxHeight: '500px'}}>
                        <table class="table" style={{'table-layout':'fixed', 'word-wrap': 'break-word'}}>
                            <thead className='sticky-top table-primary'>
                                <tr>
                                    <th scope="col" style={{'width':'5%'}}>#</th>
                                    <th scope="col" style={{'width':'45%'}}>Pages</th>
                                    <th scope="col" style={{'width':'10%'}}>Visits</th>
                                    <th scope="col" style={{'width':'20%'}}>
                                        <button class="btn px-0" type="button" aria-expanded="false" 
                                            onClick={e => {engagedMetric !== 'bounce_rate_inc' ? setEngagedMetric('bounce_rate_inc') : setEngagedMetric('bounce_rate_dec')}}
                                        >
                                            <span className='me-2 fw-bold'>Bounce rate</span>
                                            <FontAwesomeIcon icon={faAngleUp} style={{'color':`${engagedMetric === 'bounce_rate_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                            <FontAwesomeIcon icon={faAngleDown} style={{'color':`${engagedMetric === 'bounce_rate_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                                        </button>    
                                    </th>
                                    <th scope="col" style={{'width':'20%'}}>
                                        <button class="btn px-0" type="button" aria-expanded="false" 
                                                onClick={e => {engagedMetric !== 'avg_time_on_page_inc' ? setEngagedMetric('avg_time_on_page_inc') : setEngagedMetric('avg_time_on_page_dec')}}
                                            >
                                            <span className='me-2 fw-bold'>Avg. TOS</span>
                                            <FontAwesomeIcon icon={faAngleUp} style={{'color':`${engagedMetric === 'avg_time_on_page_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                            <FontAwesomeIcon icon={faAngleDown} style={{'color':`${engagedMetric === 'avg_time_on_page_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                                        </button>  
                                    </th>
                                </tr>
                            </thead>
                                <tbody>
                                    {
                                        Array.from(filteredData).sort((a,b) => (engagedMetric.search(/_inc/g) !== -1 ? 1 : -1)*(a[engagedMetric.replace(/_inc|_dec/g,'')] - b[engagedMetric.replace(/_inc|_dec/g,'')]))
                                        .filter((item, idx) => (engagedMinVisits !== '' ? item.nb_visits >= parseInt(engagedMinVisits) : true))
                                        .filter((item, idx) => (idx <= topEngaged -1))
                                        .map((item, idx) => (
                                                    <tr>
                                                        <th scope="row">{idx+1}</th>
                                                        <td>{item.Actions_EntryPageUrl.replace('bbcincorp.com', '')}</td>
                                                        <td>{item.nb_visits}</td>
                                                        <td>{`${item.bounce_rate.toFixed(1)}%`}</td>
                                                        <td>{convertSecondsToHoursMinutes(item.avg_time_on_page)}</td>
                                                    </tr>
                                        ))
                                    }
                                </tbody>
                        </table>
                    </div>
                    
                </div>
                <div className='d-flex flex-column col-md-6 ps-3'>
                    <div className='d-flex align-items-center'>
                        <h5 className='text-nowrap me-2'>Best/Worst Converted Pages</h5>
                        <select class="form-select me-2 w-auto" aria-label="Default select example"
                            onChange = {e => setTopConverted(e.target.value)}
                        >
                            <option value="10">Top 10</option>
                            <option value="20">Top 20</option>
                            <option value="30">Top 30</option>
                        </select>
                        <select class="form-select me-auto w-auto" aria-label="Default select example"
                            onChange = {e => setTopCategory(e.target.value)}
                        >
                            <option value="">Category</option>
                            <option value="Blog">Blog</option>
                            <option value="Service">Service</option>
                            <option value="Anns">Anns</option>
                            <option value="About us">About us</option>
                            <option value="X">X</option>
                        </select>
                        <input type="text" class="form-control w-auto" placeholder="Specify min visits" onChange={e => setConvertedMinVisits(e.target.value)}/>
                    </div>
                    <div className='d-flex overflow-auto my-2' style={{maxHeight: '500px'}}>
                        <table class="table" style={{'table-layout':'fixed', 'word-wrap': 'break-word'}}>
                            <thead className='sticky-top table-primary'>
                                <tr>
                                    <th scope="col" style={{'width':'5%'}}>#</th>
                                    <th scope="col" style={{'width':'45%'}}>Pages</th>
                                    <th scope="col" style={{'width':'10%'}}>Visits</th>
                                    <th scope="col" style={{'width':'15%'}}>
                                        <button class="btn px-0" type="button" aria-expanded="false" 
                                                onClick={e => {conversionMetric !== 'total_conv_inc' ? setConversionMetric('total_conv_inc') : setConversionMetric('total_conv_dec')}}
                                            >
                                            <span className='me-2 fw-bold'>Conv</span>
                                            <FontAwesomeIcon icon={faAngleUp} style={{'color':`${engagedMetric === 'total_conv_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                            <FontAwesomeIcon icon={faAngleDown} style={{'color':`${engagedMetric === 'total_conv_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                                        </button> 
                                    </th>
                                    <th scope="col" style={{'width':'10%'}}>Acc. Conv</th>
                                    <th scope="col" style={{'width':'10%'}}>Acc. Conv pct</th>
                                    <th scope="col" style={{'width':'15%'}}>
                                        <button class="btn px-0" type="button" aria-expanded="false" 
                                            onClick={e => {conversionMetric !== 'conv_rate_inc' ? setConversionMetric('conv_rate_inc') : setConversionMetric('conv_rate_dec')}}
                                        >
                                            <span className='me-2 fw-bold'>Con. rate</span>
                                            <FontAwesomeIcon icon={faAngleUp} style={{'color':`${engagedMetric === 'conv_rate_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                            <FontAwesomeIcon icon={faAngleDown} style={{'color':`${engagedMetric === 'conv_rate_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                                        </button> 
                                    </th>
                                </tr>
                            </thead>
                                <tbody>
                                {
                                        Array.from(filteredData).sort((a,b) => (conversionMetric.search(/_inc/g) !== -1 ? 1 : -1)*(a[conversionMetric.replace(/_inc|_dec/g,'')] - b[conversionMetric.replace(/_inc|_dec/g,'')]))
                                        .filter((item, idx) => ((convertedMinVisits !== '' ? item.nb_visits >= parseInt(convertedMinVisits) : true) && (topCategory === '' ? true : topCategory === item.category)))
                                        .filter((item, idx) => (idx <= (topConverted -1)))
                                        .map((item, idx) => (
                                                    <tr>
                                                        <th scope="row">{idx+1}</th>
                                                        <td>{item.Actions_EntryPageUrl.replace('bbcincorp.com', '')}</td>
                                                        <td>{item.nb_visits}</td>
                                                        <td>{item.total_conv}</td>
                                                        <td>{getAccConvHandler(idx)[0]}</td>
                                                        <td>{`${(getAccConvHandler(idx)[1]*100).toFixed(1)}%`}</td>
                                                        <td>{`${item.conv_rate.toFixed(2)}%`}</td>
                                                    </tr>
                                                )
                                            )
                                    }
                                </tbody>
                        </table>
                    </div>
                    
                </div>
            </div>
            <hr />
            <div className='d-flex flex-column my-4 w-100' style={{height: '350px'}}>
                <div className='d-flex ms-auto mb-4'>
                    <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
                        <input type="radio" class="btn-check" name="btnradio" id="btnradio1" value='Daily' autocomplete="off" onChange={e => changeDisplayFreqHandler(e.target.value)}/>
                        <label class="btn btn-outline-primary" for="btnradio1">Daily</label>

                        <input type="radio" class="btn-check" name="btnradio" value='Weekly' id="btnradio2" autocomplete="off" onChange={e => changeDisplayFreqHandler(e.target.value)}/>
                        <label class="btn btn-outline-primary" for="btnradio2">Weekly</label>

                        <input type="radio" class="btn-check" name="btnradio" value='Monthly' id="btnradio3" autocomplete="off" onChange={e => changeDisplayFreqHandler(e.target.value)}/>
                        <label class="btn btn-outline-primary" for="btnradio3">Monthly</label>
                    </div>
                </div>
                <ResponsiveContainer width='100%' height='100%'>
                    <ComposedChart
                    // width={1000}
                    height={300}
                    data={displayFreq === 'Daily' ? timeSeriesData : freqChart}
                    margin={{
                        top: 5,
                        right: 10,
                        left: 10,
                        bottom: 5,
                    }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                            dataKey="date"
                            name = "date"
                            tickFormatter = {(unixTime) => moment(parseInt(unixTime)).format('D-M')}

                        />
                        <YAxis 
                            yAxisId="left"
                            domain={[0, 'auto']}
                        />
                        <YAxis 
                            yAxisId="right"
                            orientation="right"
                            domain={[0, 100]}
                        />
                        <Tooltip 
                            content={<CustomTooltip />}
                            wrapperStyle={{ backgroundColor: '#f8f9fa', padding: '5px' }}
                        />
                        <Legend legendType='none'/>
                        <Area yAxisId="left" type="monotone" dataKey="users" name="users" stroke="#8884d8" fill="#8884d8"/>
                        <Bar yAxisId="left" type="monotone" dataKey="total_conv" stroke="#00C49F" fill="#00C49F"/>
                        <Line yAxisId="right" type="monotone" dataKey="bounce_rate" stroke="#FFBB28" strokeWidth={1.5}/>
                        <Line yAxisId="right" type="monotone" dataKey="conv_rate" stroke="#FF8042" strokeWidth={1.5}/>
                    </ComposedChart>
                </ResponsiveContainer>
            </div>
            <hr />
            <div className='d-flex my-3'>
                <div className='d-flex me-auto'>
                    <OverlayTrigger trigger="click" placement="bottom" rootClose overlay={urlFilter} container={this} containerPadding={20}>
                        <button type="button" class="btn btn-light">
                            <FontAwesomeIcon className='me-2' icon={faFilter} />
                            Filter
                        </button>
                    </OverlayTrigger>
                </div>
                <button class="btn btn-primary ms-4" type="button" aria-expanded="false" onClick={downloadReportHandler}>
                    <FontAwesomeIcon icon={faDownload} className="pe-2"/>
                    Download
                </button>
            </div>
            {/* Modal Bounce*/}
            <div class="modal fade" id="ModalConvertedUsers" tabindex="-1" role="dialog" aria-labelledby="ModalCenterTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
                    <div class="modal-content">
                    <div className="modal-header">
                        <h5 class="modal-title me-auto" id="ModalLongTitle">Entry Page Analytics</h5>
                    </div>
                    <div class="modal-body" style={{height: '700px'}}>
                        <ul class="nav nav-tabs mb-2">
                            <li class="nav-item">
                                <a class={`nav-link ${['converted_user_list', 'converted_user_detail'].includes(displayMode) && 'active'}`} href="#"
                                    onClick={e => setDisplayMode('converted_user_list')}
                                >
                                    Converted users</a>
                            </li>
                            <li class="nav-item">
                                <a class={`nav-link ${displayMode === 'ranking' && 'active'}`} href="#"
                                    onClick={e => setDisplayMode('ranking')}
                                >Ranking progress</a>
                            </li>
                            <li class="nav-item">
                                <a class={`nav-link ${displayMode === 'referrer' && 'active'}`} href="#"
                                    onClick={e => setDisplayMode('referrer')}
                                >Referrer</a>
                            </li>
                        </ul>
                        { displayMode === 'converted_user_list' ?
                            <div class="d-flex flex-column mb-4"  style={{'max-height':'500px', 'overflow':'auto'}}>
                                {
                                    convertedUsers.length === 0 ?
                                    <h4 className='d-flex align-self-center text-muted my-5'>No converted users in selected period</h4> :
                                    <table class="table table-hover">
                                    <thead className="table-primary sticky-top">
                                        <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">
                                            <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler(convertedUsers, 'visitor_id')}>
                                                <span className='me-2 fw-bold' style={{fontSize: '0.9rem'}}>Visitor Id</span>
                                                <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'visitor_id_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                                <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'visitor_id_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                                            </button>
                                        </th>
                                        <th scope="col">
                                            <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler(convertedUsers, 'date')}>
                                                <span className='me-2 fw-bold' style={{fontSize: '0.9rem'}}>Date</span>
                                                <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'date_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                                <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'date_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                                            </button>
                                        </th>
                                        <th scope="col">
                                            <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler(convertedUsers, 'country')}>
                                                <span className='me-2 fw-bold' style={{fontSize: '0.9rem'}}>Country</span>
                                                <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'country_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                                <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'country_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                                            </button>
                                        </th>
                                        <th scope="col">
                                            <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler(convertedUsers, 'visitor_type')}>
                                                <span className='me-2 fw-bold' style={{fontSize: '0.9rem'}}>Visitor type</span>
                                                <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'visitor_type_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                                <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'visitor_type_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                                            </button>
                                        </th>
                                        <th scope="col">
                                            <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler(convertedUsers, 'referrer')}>
                                                <span className='me-2 fw-bold' style={{fontSize: '0.9rem'}}>Referrer</span>
                                                <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'referrer_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                                <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'referrer_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                                            </button>
                                        </th>
                                        <th scope="col">
                                            <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler(convertedUsers, 'conversion_type')}>
                                                <span className='me-2 fw-bold' style={{fontSize: '0.9rem'}}>Type</span>
                                                <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'conversion_type_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                                <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'conversion_type_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                                            </button>
                                        </th>
                                        <th scope="col">
                                            <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler(convertedUsers, 'url')}>
                                                <span className='me-2 fw-bold' style={{fontSize: '0.9rem'}}>Entry URL</span>
                                                <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'url_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                                <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'url_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                                            </button>
                                        </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            convertedUsers.map((item, idx) => (
                                                <tr role="button" onClick={e => fetchUserActivityHandler(item.visitor_id)}>
                                                    <th scope="row">{idx+1}</th>
                                                    <td>{item.visitor_id}</td>
                                                    <td>{`${new Date(item.date*1000).getDate()}/${new Date(item.date*1000).getMonth()+1}/${new Date(item.date*1000).getFullYear()}`}</td>
                                                    <td>{item.country}</td>
                                                    <td>{item.visitor_type}</td>
                                                    <td>{item.referrer}</td>
                                                    <td>{item.conversion_type}</td>
                                                    <td>{item.url}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                                }
                            </div> : displayMode === 'converted_user_detail' ?
                            <div class="d-flex" style={{'height':'500px'}}>
                                <div className='d-flex flex-column col-md-3'>
                                    <h5 className='sticky-top p-2 bg-light'>Contact Info</h5>
                                    <div className='d-flex flex-column'>
                                        <h6>Summary</h6>
                                        <p>
                                            {`Spent a total of ${convertSecondsToHoursMinutes(convertedUserHistory[0]['totalVisitDuration'])} on the website, and performed ${convertedUserHistory[0]['totalActions']} actions (${convertedUserHistory[0]['totalActions']} Pageviews, ${convertedUserHistory[0]['totalOutlinks']} Outlinks, ${convertedUserHistory[0]['totalDownloads']} Downloads) in ${convertedUserHistory[0]['totalVisits']} visits.`}
                                        </p>
                                        <p>
                                            {`Converted ${convertedUserHistory[0]['totalGoalConversions']} goals (${Object.entries(convertedUserHistory[0]['totalConversionsByGoal']).map(([k, v]) => `${v} ${k},`)})`}
                                        </p>
                                    </div>
                                    <div className='d-flex flex-column'>
                                        <h6>First Visit</h6>
                                        <p>
                                            {`${convertedUserHistory[0]['firstVisit']['prettyDate']} from ${convertedUserHistory[0]['firstVisit']['referralSummary']}`}
                                        </p>
                                    </div>
                                    <div className='d-flex flex-column'>
                                        <h6>Last Visit</h6>
                                        <p>
                                            {`${convertedUserHistory[0]['lastVisit']['prettyDate']} from ${convertedUserHistory[0]['lastVisit']['referralSummary']}`}
                                        </p>
                                    </div>
                                    <div className='d-flex flex-column'>
                                        <h6>Location</h6>
                                        <p>
                                            {`${convertedUserHistory[0]['countries'][0]['prettyName']}`}
                                        </p>
                                    </div>
                                </div>
                                <div className='d-flex flex-column col-md-9' style={{'height':'500px', 'overflow':'auto'}}>
                                    <h5 className='sticky-top p-2 bg-light'>Activity History</h5>
                                    {
                                        convertedUserHistory[0]['activity_list'].map((item, idx) => (
                                            <div className='d-flex flex-column'>
                                                <div className='d-flex align-items-center bg-secondary text-white p-2 px-3'>
                                                    <h5 className='d-flex flex-fill align-self-center'>
                                                        Visit {convertedUserHistory[0]['activity_list'].length - idx}
                                                        {
                                                            countGoal(item.actionDetails) > 0 &&
                                                            <div className='ms-3'>
                                                                <FontAwesomeIcon icon={faFlag} style={{color: 'white'}} className='me-2'/>
                                                                <span class="badge text-black bg-white">{countGoal(item.actionDetails)}</span>
                                                            </div>
                                                        }
                                                    </h5>
                                                    <span className='ms-2 flex-fill '>{`${item.actions} actions in ${convertSecondsToHoursMinutes(item.visitDuration)}`}</span>
                                                    <span className='ms-auto'>{`${item.serverDatePrettyFirstAction} ${item.serverTimePrettyFirstAction}`}</span>
                                                </div>
                                                <div className='d-flex'>
                                                    <span>{`${item.deviceType} - `}</span>
                                                    <span>{`${item.browser} - ${item.operatingSystem} - ${item.resolution}`}</span>
                                                </div>
                                                <div className='d-flex'>
                                                    <span>{`Referrer: ${item.referrerTypeName} - IP: ${item.visitIp} - Location: ${item.country}`}</span>
                                                </div>
                                                <ul className='list-group list-group-flush'>
                                                    {   item.actionDetails.length > 0 &&
                                                        item.actionDetails.map((session, index) => (
                                                            session.type === 'action' ?
                                                            <li className='list-group-item border-0 pb-0'>
                                                                <small>{`Page load time: ${session.pageLoadTime} - Time spent: ${session.timeSpentPretty}`}</small>
                                                                <div className='d-flex align-items-center'>
                                                                    <FontAwesomeIcon icon={faFolder} className='me-2'/>
                                                                    <p class="my-2">{`${session.title}`}</p>
                                                                </div>
                                                                <div className='d-flex flex-column border border-0 border-start border-black'>
                                                                    <a href={session.subtitle} target="_blank" class="ms-3 my-2">{`${session.subtitle.replace("https://bbcincorp.com", "")}`}</a>
                                                                </div>
                                                            </li> : session.type === 'goal' ?
                                                            <li className='list-group-item p-0 d-flex flex-column ms-3 border border-0 border-start border-black'>
                                                                <div className='d-flex align-items-center'>
                                                                    <FontAwesomeIcon icon={faFlag} className='me-2 ms-3'/>
                                                                    <p class="my-2">{`${session.subtitle.replace("https://bbcincorp.com", "")}`}</p>
                                                                </div>
                                                                {   index < (item.actionDetails.length - 1) &&
                                                                    <span className='ms-3 border border-0 border-start border-black' style={{height: '20px'}}></span>
                                                                }
                                                            </li> : session.type === 'outlink' ?
                                                            <li className='list-group-item p-0 d-flex flex-column ms-3 border border-0 border-start border-black'>
                                                                <div className='d-flex align-items-center'>
                                                                    <FontAwesomeIcon icon={faArrowUpRightFromSquare} className='me-2 ms-3'/>
                                                                    <p class="my-2">{`${session.subtitle}`}</p>
                                                                </div>
                                                                {
                                                                    index < (item.actionDetails.length - 1) &&
                                                                    <span className='ms-3 border border-0 border-start border-black' style={{height: '20px'}}></span>
                                                                }
                                                            </li> : session.type === 'event' ?
                                                            <li className='list-group-item p-0 d-flex flex-column ms-3 border border-0 border-start border-black'>
                                                                <div className='d-flex align-items-center'>
                                                                    <FontAwesomeIcon icon={faBullseye} className='me-2 ms-3'/>
                                                                    <p class="my-2">{`${session['subtitle']} - ${session['eventName']}`}</p>
                                                                </div>
                                                                {
                                                                    index < (item.actionDetails.length - 1) &&
                                                                    <span className='ms-3 border border-0 border-start border-black' style={{height: '20px'}}></span>
                                                                }
                                                            </li> : null
                                                        ))
                                                    }
                                                 </ul>
                                            </div>    
                                        ))
                                    }
                                    
                                </div>
                            </div> : displayMode === 'ranking' ?
                            <div className='d-flex flex-column w-100 overflow-auto' style={{maxHeight: '700px'}}>
                                <h6 className='my-2 align-self-end'>
                                    Last 90-day ranking progress
                                </h6>
                                <div className='d-flex w-100' style={{height: '200px'}}>
                                    <ResponsiveContainer width="100%" height="100%">
                                        <AreaChart
                                        width={500}
                                        height={300}
                                        data={rankingProgress}
                                        margin={{
                                            top: 5,
                                            right: 30,
                                            left: 20,
                                            bottom: 5,
                                        }}
                                        >
                                        <defs>
                                            <linearGradient id="color1" x1="0" y1="0" x2="0" y2="1">
                                                <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                                                <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
                                            </linearGradient>
                                        </defs>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis
                                            dataKey="date"
                                            name = "date"
                                            tickFormatter = {(unixTime) => moment(parseInt(unixTime)).format('DD-MM-YY')}

                                        />
                                        <YAxis 
                                            domain={[0, 'auto']}
                                            reversed
                                        />
                                        <Tooltip 
                                            content={<CustomTooltip />}
                                            wrapperStyle={{ backgroundColor: '#f8f9fa', padding: '2px' }}
                                        />
                                        <Legend legendType='none'/>
                                        <Area type="monotone" dataKey="rank" stroke="#8884d8" fillOpacity={1} fill="url(#color1)"/>
                                        </AreaChart>
                                    </ResponsiveContainer>
                                </div>
                                <div className='d-flex overflow-auto my-2' style={{maxHeight: '400px'}}>
                                    <table class="table" style={{'table-layout':'fixed', 'word-wrap': 'break-word'}}>
                                        <thead className='sticky-top table-primary'>
                                            <tr>
                                                <th scope="col" style={{'width':'5%'}}>#</th>
                                                <th scope="col" style={{'width':'30%'}}>
                                                    <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler(rankingDetail, 'Keyword')}>
                                                        <span className='me-2 fw-bold' style={{fontSize: '0.9rem'}}>Mapped keyword</span>
                                                        <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'Keyword_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                                        <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'Keyword_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                                                    </button>
                                                </th>
                                                <th scope="col" style={{'width':'10%'}}>
                                                    <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler(rankingDetail, 'rank')}>
                                                        <span className='me-2 fw-bold' style={{fontSize: '0.9rem'}}>Avg. Rank</span>
                                                        <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'rank_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                                        <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'rank_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                                                    </button>
                                                </th>
                                                <th scope="col" style={{'width':'10%'}}>
                                                    <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler(rankingDetail, 'best')}>
                                                        <span className='me-2 fw-bold' style={{fontSize: '0.9rem'}}>Best</span>
                                                        <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'best_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                                        <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'best_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                                                    </button>
                                                </th>
                                                <th scope="col" style={{'width':'10%'}}>
                                                    <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler(rankingDetail, 'first')}>
                                                        <span className='me-2 fw-bold' style={{fontSize: '0.9rem'}}>First</span>
                                                        <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'first_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                                        <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'first_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                                                    </button>
                                                </th>
                                                <th scope="col" style={{'width':'10%'}}>
                                                    <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler(rankingDetail, 'last')}>
                                                        <span className='me-2 fw-bold' style={{fontSize: '0.9rem'}}>Last</span>
                                                        <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'last_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                                        <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'last_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                                                    </button>
                                                </th>
                                                <th scope="col" style={{'width':'15'}}>
                                                    <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler(rankingDetail, 'volume')}>
                                                        <span className='me-2 fw-bold' style={{fontSize: '0.9rem'}}>Volume</span>
                                                        <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'volume_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                                        <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'volume_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                                                    </button>
                                                </th>
                                                <th scope="col" style={{'width':'10%'}}>
                                                    Action
                                                </th>
                                            </tr>
                                        </thead>
                                            <tbody>
                                            {
                                                    rankingDetail.map((item, idx) => (
                                                                <tr>
                                                                    <th scope="row">{idx+1}</th>
                                                                    <td>{item.Keyword}</td>
                                                                    <td>{item.rank === '' ? '-' : item.rank.toFixed(1)}</td>
                                                                    <td>{item.best}</td>
                                                                    <td>{item.first}</td>
                                                                    <td>{item.last}</td>
                                                                    <td>{item.volume.toLocaleString('en-us')}</td>
                                                                    <td>
                                                                        <OverlayTrigger trigger="focus" placement="top" overlay={popoverChart}>
                                                                            <button class="btn me-auto" type="button" aria-expanded="false" onClick={e => KwRankHandler(item.Keyword,activeURL)}>
                                                                                <FontAwesomeIcon icon={faLineChart} />
                                                                            </button>
                                                                        </OverlayTrigger>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        )
                                                }
                                            </tbody>
                                    </table>
                                </div>
                            </div> : displayMode === 'referrer' ?
                            <div className='d-flex flex-column w-100 overflow-auto' style={{maxHeight: '650px'}}>
                                <div className='d-flex mb-4'>
                                    <div className='d-flex col-md-6 pe-4'>
                                        <div class="d-flex flex-column col border shadow-sm rounded mx-3 py-2 px-3 w-100">
                                            <b>Referrer types</b>
                                            <hr />
                                            <div style={{height: '150px'}}>
                                            {
                                                referrerType.map(item => 
                                                    <div className={`d-flex align-items-center p-2 metric-hover ${referrerChannel === item.Referrers_ReferrerType && 'bg-light'}`}  role="button" onClick={e => setReferrerChanel(item.Referrers_ReferrerType)}>
                                                        <div className='m-0 me-2' style={{width: '70px'}}>{item.Referrers_ReferrerType}</div>
                                                        <div className='' style={{width: '400px'}}>
                                                            <ProgressBar now={item.pct*100} />
                                                        </div>
                                                        <div className='mx-2 text-end' style={{width: '40px'}}>{item[referrerMetric]}</div>
                                                        {/* <div className='text-end' style={{width: '40px'}}>{`${item.pct.toFixed(2)} %`}</div> */}
                                                    </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className='d-flex col-md-6'>
                                        <div class="d-flex flex-column col border shadow-sm rounded mx-3 py-2 px-3" style={{height: '280px'}}>
                                            <b>Referrer names</b>
                                            <hr />
                                            <div className='d-flex h-100'>
                                                <div className='d-flex col-md-6'>
                                                    <ResponsiveContainer width="100%" height="80%">
                                                        <PieChart
                                                        width={500}
                                                        height={300}
                                                        margin={{
                                                            top: 5,
                                                            right: 5,
                                                            left: 5,
                                                            bottom: 5,
                                                        }}
                                                        >
                                                        <CartesianGrid strokeDasharray="3 3" />
                                                        <Tooltip 
                                                            content={<CustomTooltip />}
                                                            wrapperStyle={{ backgroundColor: '#f8f9fa', padding: '2px' }}
                                                        />
                                                            <Pie
                                                            dataKey={referrerMetric}
                                                            //   startAngle={180}
                                                            // endAngle={0}
                                                            data={referrerName.filter(item => item.Referrers_ReferrerType === referrerChannel).sort((a,b) => b[referrerMetric] - a[referrerMetric]).slice(0,10)}
                                                            cx="50%"
                                                            cy="50%"
                                                            innerRadius={40}
                                                            outerRadius={80}
                                                            paddingAngle={5}
                                                            fill="#8884d8"
                                                        >
                                                            {referrerName.filter(item => item.Referrers_ReferrerType === referrerChannel).sort((a,b) => b[referrerMetric] - a[referrerMetric]).slice(0,10).map((entry, index) => (
                                                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                                            ))}
                                                        </Pie>
                                                        </PieChart>
                                                    </ResponsiveContainer>
                                                </div>
                                                <div className='d-flex flex-column col-md-6 align-items-center'>
                                                    <h5>{referrerChannel}</h5>
                                                    <ul class="list-group list-group-flush">
                                                        {   referrerName.filter(item => item.Referrers_ReferrerType === referrerChannel).length > 0 &&
                                                            referrerName.filter(item => item.Referrers_ReferrerType === referrerChannel).sort((a,b) => b[referrerMetric] - a[referrerMetric]).slice(0,5).map((item, index) => (
                                                                <li class="list-group-item border-0">
                                                                    <FontAwesomeIcon icon={faCircle} style={{color: COLORS[index]}} size='sm' className='me-2'/>
                                                                    {item.Referrers_ReferrerName || 'Others'}
                                                                </li>
                                                            ))
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>                                        
                                    </div>
                                </div>
                                <div className='d-flex mb-4 overflow-auto' style={{maxHeight: '350px'}}>
                                    <table class="table" style={{'table-layout':'fixed', 'word-wrap': 'break-word'}}>
                                        <thead className='sticky-top table-primary'>
                                            <tr>
                                                <th scope="col" style={{'width':'5%'}}>#</th>
                                                <th scope="col" style={{'width':'10%'}}>
                                                    <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler(referrerName, 'Referrers_ReferrerType')}>
                                                        <span className='me-2 fw-bold' style={{fontSize: '0.9rem'}}>Referrer Type</span>
                                                        <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'Referrers_ReferrerType_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                                        <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'Referrers_ReferrerType_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                                                    </button>
                                                </th>
                                                <th scope="col" style={{'width':'10%'}}>
                                                    <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler(referrerName, 'Referrers_ReferrerName')}>
                                                        <span className='me-2 fw-bold' style={{fontSize: '0.9rem'}}>Referrer Name</span>
                                                        <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'Referrers_ReferrerName_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                                        <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'Referrers_ReferrerName_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                                                    </button>
                                                </th>
                                                <th scope="col" style={{'width':'5%'}}>
                                                    <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler(referrerName, 'nb_visits')}>
                                                        <span className='me-2 fw-bold' style={{fontSize: '0.9rem'}}>Visitors</span>
                                                        <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'nb_visits_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                                        <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'nb_visits_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                                                    </button>
                                                </th>
                                                <th scope="col" style={{'width':'5%'}}>
                                                    <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler(referrerName, 'bounce_rate')}>
                                                        <span className='me-2 fw-bold' style={{fontSize: '0.9rem'}}>Bounce rate</span>
                                                        <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'bounce_rate_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                                        <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'bounce_rate_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                                                    </button>
                                                </th>
                                                <th scope="col" style={{'width':'5%'}}>
                                                    <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler(referrerName, 'avg_tos')}>
                                                        <span className='me-2 fw-bold' style={{fontSize: '0.9rem'}}>Avg. TOS</span>
                                                        <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'avg_tos_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                                        <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'avg_tos_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                                                    </button>
                                                </th>
                                                <th scope="col" style={{'width':'5%'}}>
                                                    <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler(referrerName, 'goal_2_conversion')}>
                                                        <span className='me-2 fw-bold' style={{fontSize: '0.9rem'}}>Order success</span>
                                                        <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'goal_2_conversion_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                                        <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'goal_2_conversion_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                                                    </button>
                                                </th>
                                                <th scope="col" style={{'width':'5%'}}>
                                                    <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler(referrerName, 'goal_4_conversion')}>
                                                        <span className='me-2 fw-bold' style={{fontSize: '0.9rem'}}>Live chat</span>
                                                        <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'goal_4_conversion_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                                        <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'goal_4_conversion_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                                                    </button>
                                                </th>
                                                <th scope="col" style={{'width':'5%'}}>
                                                    <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler(referrerName, 'form_conv')}>
                                                        <span className='me-2 fw-bold' style={{fontSize: '0.9rem'}}>Form submit</span>
                                                        <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'form_conv_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                                        <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'form_conv_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                                                    </button>
                                                </th>
                                                <th scope="col" style={{'width':'5%'}}>
                                                    <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler(referrerName, 'total_conv')}>
                                                        <span className='me-2 fw-bold' style={{fontSize: '0.9rem'}}>Total conv</span>
                                                        <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'total_conv_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                                        <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'total_conv_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                                                    </button>
                                                </th>
                                                <th scope="col" style={{'width':'5%'}}>
                                                    <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler(referrerName, 'mkt_lead')}>
                                                        <span className='me-2 fw-bold' style={{fontSize: '0.9rem'}}>Other MKT lead</span>
                                                        <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'mkt_lead_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                                        <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'mkt_lead_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                                                    </button>
                                                </th>
                                            </tr>
                                        </thead>
                                            <tbody>
                                            {
                                                    referrerName.map((item, idx) => (
                                                                <tr>
                                                                    <th scope="row">{idx+1}</th>
                                                                    <td>{item.Referrers_ReferrerType}</td>
                                                                    <td>{item.Referrers_ReferrerName}</td>
                                                                    <td>{item.nb_visits}</td>
                                                                    <td>{`${(item.bounce_rate*100).toFixed(1)}%`}</td>
                                                                    <td>{convertSecondsToHoursMinutes(item.avg_tos)}</td>
                                                                    <td>{item.goal_2_conversion === 0 ? '-' : item.goal_2_conversion}</td>
                                                                    <td>{item.goal_4_conversion === 0 ? '-' : item.goal_4_conversion}</td>
                                                                    <td>{item.form_conv === 0 ? '-' : item.form_conv}</td>
                                                                    <td>{item.total_conv === 0 ? '-' : item.total_conv}</td>
                                                                    <td>{item.mkt_lead === 0 ? '-' : item.mkt_lead}</td>
                                                                </tr>
                                                            )
                                                        )
                                                }
                                            </tbody>
                                    </table>
                                </div>
                            </div> : null
                        }
                    </div>
                    <div class="modal-footer">
                        {
                            displayMode === 'converted_user_detail' &&
                            <button type="button" className="btn btn-primary align-middle" 
                                onClick={e => {setDisplayMode('converted_user_list'); setConvertedUserHistory([])}}
                            >
                                <FontAwesomeIcon icon={faArrowCircleLeft} size='1x' className='me-2'/>
                                Go Back
                            </button>
                        }
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    </div>
                    </div>
                </div>
            </div>
            {/* Modal Bounce*/}
            {/* Modal Alert*/}
            <div class="modal fade" id="ModalAlert" tabindex="-1" role="dialog" aria-labelledby="ModalCenterTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                    <div className="modal-header">
                        <h5 class="modal-title me-auto" id="ModalLongTitle">Resources Exhausted</h5>
                    </div>
                    <div class="modal-body" style={{'max-height':'500px', 'overflow':'auto'}}>
                        <p>Too many requests. Please try again in 1 minute.</p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" >Close</button>
                    </div>
                    </div>
                </div>
            </div>
            {/* Modal Alert*/}
            <div className='d-flex mb-4'>
                <div className='d-flex overflow-auto mb-4 w-100' style={{"height":"500px"}}>
                    <table class="table" style={{'table-layout': 'fixed'}}>
                        <thead className='table-primary sticky-top'>
                            <tr>
                                <th scope="col" style={{'width':'5%'}}>#</th>
                                <th scope="col" style={{'width':'22%'}}>
                                    <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler(filteredData, 'Actions_EntryPageUrl')}>
                                        <span className='me-2 fw-bold'>Entry Page</span>
                                        <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'Actions_EntryPageUrl_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                        <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'Actions_EntryPageUrl_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                                    </button>
                                </th>
                                <th scope="col" style={{'width':'5%'}}>
                                    <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler(filteredData, 'category')}>
                                        <span className='me-2 fw-bold'>Category</span>
                                        <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'category_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                        <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'category_inc_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                                    </button>
                                </th>
                                <th scope="col" style={{'width':'5%'}}>
                                    <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler(filteredData, 'users')}>
                                            <span className='me-2 fw-bold'>Users</span>
                                            <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'users_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                            <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'users_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                                    </button>
                                </th>
                                <th scope="col" style={{'width':'5%'}}>
                                    <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler(filteredData, 'nb_visits')}>
                                        <span className='me-2 fw-bold'>Sessions</span>
                                        <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'nb_visits_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                        <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'nb_visits_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                                    </button>
                                </th>
                                <th scope="col" style={{'width':'10%'}}>
                                    <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler(filteredData, 'avg_time_on_page')}>
                                            <span className='me-2 fw-bold'>Avg. time on page</span>
                                            <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'avg_time_on_page_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                            <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'avg_time_on_page_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                                    </button>
                                </th>
                                <th scope="col" style={{'width':'6%'}}>
                                    <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler(filteredData, 'bounce_rate')}>
                                            <span className='me-2 fw-bold'>Bounce rate</span>
                                            <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'bounce_rate_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                            <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'bounce_rate_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                                    </button>
                                </th>
                                <th scope="col" style={{'width':'5%'}}>
                                    <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler(filteredData, 'goal_2_conversion')}>
                                            <span className='me-2 fw-bold'>Goal - order success</span>
                                            <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'goal_2_conversion_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                            <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'goal_2_conversion_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                                    </button>
                                </th>
                                <th scope="col" style={{'width':'5%'}}>
                                    <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler(filteredData, 'goal_3_conversion')}>
                                        <span className='me-2 fw-bold'>Goal - contact form</span>
                                        <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'goal_3_conversion_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                        <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'goal_3_conversion_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                                    </button>
                                </th>
                                <th scope="col" style={{'width':'5%'}}>
                                    <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler(filteredData, 'goal_4_conversion')}>
                                            <span className='me-2 fw-bold'>Goal - live chat</span>
                                            <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'goal_4_conversion_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                            <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'goal_4_conversion_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                                    </button>
                                </th>
                                <th scope="col" style={{'width':'5%'}}>
                                    <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler(filteredData, 'total_conv')}>
                                            <span className='me-2 fw-bold'>Total conversion</span>
                                            <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'total_conv_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                            <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'total_conv_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                                    </button>
                                </th>
                                <th scope="col" style={{'width':'5%'}}>
                                    <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler(filteredData, 'conv_rate')}>
                                            <span className='me-2 fw-bold'>Conversion rate</span>
                                            <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'conv_rate_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                            <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'conv_rate_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                                    </button>
                                </th>
                                <th scope="col" style={{'width':'5%'}}>
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                filteredData.map((item, idx) => {
                                    return (
                                        <tr>
                                            <th scope="row">{idx+1}</th>
                                            <td>{item.Actions_EntryPageUrl.replace('bbcincorp.com', '')}</td>
                                            <td>{item.category}</td>
                                            <td>
                                                {item.users}
                                                {
                                                    comparedData.length > 0 && comparisonMode &&
                                                    <p className='m-0 h-100'>
                                                        {comparedData.length > 0 && item.users_comp !== '' && item.users_comp > 0 ?
                                                        <FontAwesomeIcon icon={faArrowUp} style={{color:'green'}} className={'me-2'}/> :
                                                        comparedData.length > 0 && item.users_comp !== '' && item.users_comp < 0 ?
                                                        <FontAwesomeIcon icon={faArrowDown} style={{color:'red'}} className={'me-2'}/> :
                                                        comparedData.length > 0 && item.users_comp !== '' ?
                                                        <FontAwesomeIcon icon={faMinus} style={{color:'orange'}} className={'me-2'}/>:
                                                        null
                                                        }
                                                        {comparedData.length > 0 && item.users_comp !== '' && item.users_comp !== 0 ? 
                                                        Math.abs(item.users_comp).toLocaleString('en-us') : null}
                                                    </p>
                                                }
                                            </td>
                                            <td>
                                                {item.nb_visits}
                                                {
                                                    comparedData.length > 0 && comparisonMode &&
                                                    <p className='m-0 h-100'>
                                                        {comparedData.length > 0 && item.nb_visits_comp !== '' && item.nb_visits_comp > 0 ?
                                                        <FontAwesomeIcon icon={faArrowUp} style={{color:'green'}} className={'me-2'}/> :
                                                        comparedData.length > 0 && item.nb_visits_comp !== '' && item.nb_visits_comp < 0 ?
                                                        <FontAwesomeIcon icon={faArrowDown} style={{color:'red'}} className={'me-2'}/> :
                                                        comparedData.length > 0 && item.nb_visits_comp !== '' ?
                                                        <FontAwesomeIcon icon={faMinus} style={{color:'orange'}} className={'me-2'}/>:
                                                        null
                                                        }
                                                        {comparedData.length > 0 && item.nb_visits_comp !== '' && item.nb_visits_comp !== 0 ?
                                                        Math.abs(item.nb_visits_comp).toLocaleString('en-us') : null}
                                                    </p>
                                                }
                                            </td>
                                            <td>
                                                {`${convertSecondsToHoursMinutes(parseInt(item.avg_time_on_page))}`}
                                                {
                                                    comparedData.length > 0 && comparisonMode &&
                                                    <p className='m-0 h-100'>
                                                        {comparedData.length > 0 && item.avg_time_on_page_comp !== '' && item.avg_time_on_page_comp > 0 ?
                                                        <FontAwesomeIcon icon={faArrowUp} style={{color:'green'}} className={'me-2'}/> :
                                                        comparedData.length > 0 && item.avg_time_on_page_comp !== '' && item.avg_time_on_page_comp < 0 ?
                                                        <FontAwesomeIcon icon={faArrowDown} style={{color:'red'}} className={'me-2'}/> :
                                                        comparedData.length > 0 && item.avg_time_on_page_comp !== '' ?
                                                        <FontAwesomeIcon icon={faMinus} style={{color:'orange'}} className={'me-2'}/>:
                                                        null
                                                        }
                                                        {
                                                            comparedData.length > 0 && item.avg_time_on_page_comp !== '' && item.avg_time_on_page_comp !== 0 ?
                                                            convertSecondsToHoursMinutes(Math.abs(item.avg_time_on_page_comp)) : null
                                                        }
                                                    </p>
                                                }
                                            </td>
                                            <td>
                                                {`${item.bounce_rate.toFixed(1)}%`}
                                                {
                                                    comparedData.length > 0 && comparisonMode &&
                                                    <p className='m-0 h-100'>
                                                        {comparedData.length > 0 && item.bounce_rate_comp !== '' && item.bounce_rate_comp < 0 ?
                                                        <FontAwesomeIcon icon={faArrowUp} style={{color:'red'}} className={'me-2'}/> :
                                                        comparedData.length > 0 && item.bounce_rate_comp !== '' && item.bounce_rate_comp > 0 ?
                                                        <FontAwesomeIcon icon={faArrowDown} style={{color:'green'}} className={'me-2'}/> :
                                                        comparedData.length > 0 && item.bounce_rate_comp !== '' ?
                                                        <FontAwesomeIcon icon={faMinus} style={{color:'orange'}} className={'me-2'}/>:
                                                        null
                                                        }
                                                        {
                                                            comparedData.length > 0 && item.bounce_rate_comp !== '' && item.bounce_rate_comp !== 0 ?
                                                            `${Math.abs(item.bounce_rate_comp).toFixed(2)}%` : null
                                                        }
                                                    </p>
                                                }
                                            </td>
                                            <td>
                                                {item.goal_2_conversion === 0 ? '-' : item.goal_2_conversion}
                                                {
                                                    comparedData.length > 0 && comparisonMode &&
                                                    <p className='m-0 h-100'>
                                                        {comparedData.length > 0 && item.goal_2_conversion_comp !== '' && item.goal_2_conversion_comp > 0 ?
                                                        <FontAwesomeIcon icon={faArrowUp} style={{color:'green'}} className={'me-2'}/> :
                                                        comparedData.length > 0 && item.goal_2_conversion_comp !== '' && item.goal_2_conversion_comp < 0 ?
                                                        <FontAwesomeIcon icon={faArrowDown} style={{color:'red'}} className={'me-2'}/> :
                                                        comparedData.length > 0 && item.goal_2_conversion_comp !== '' ?
                                                        <FontAwesomeIcon icon={faMinus} style={{color:'orange'}} className={'me-2'}/>:
                                                        null
                                                        }
                                                        {comparedData.length > 0 && item.goal_2_conversion_comp !== '' && item.goal_2_conversion_comp !== 0 ?
                                                        Math.abs(item.goal_2_conversion_comp).toLocaleString('en-us') : null}
                                                    </p>
                                                }
                                            </td>
                                            <td>
                                                {item.goal_3_conversion === 0 ? '-' : item.goal_3_conversion}
                                                {
                                                    comparedData.length > 0 && comparisonMode &&
                                                    <p className='m-0 h-100'>
                                                        {comparedData.length > 0 && item.goal_3_conversion_comp !== '' && item.goal_3_conversion_comp > 0 ?
                                                        <FontAwesomeIcon icon={faArrowUp} style={{color:'green'}} className={'me-2'}/> :
                                                        comparedData.length > 0 && item.goal_3_conversion_comp !== '' && item.goal_3_conversion_comp < 0 ?
                                                        <FontAwesomeIcon icon={faArrowDown} style={{color:'red'}} className={'me-2'}/> :
                                                        comparedData.length > 0 && item.goal_3_conversion_comp !== '' ?
                                                        <FontAwesomeIcon icon={faMinus} style={{color:'orange'}} className={'me-2'}/>:
                                                        null
                                                        }
                                                        {comparedData.length > 0 && item.goal_3_conversion_comp !== '' && item.goal_3_conversion_comp !== 0 ? 
                                                        Math.abs(item.goal_3_conversion_comp).toLocaleString('en-us') : null}
                                                    </p>
                                                }
                                            </td>
                                            <td>
                                                {item.goal_4_conversion === 0 ? '-' : item.goal_4_conversion}
                                                {
                                                    comparedData.length > 0 && comparisonMode &&
                                                    <p className='m-0 h-100'>
                                                        {comparedData.length > 0 && item.goal_4_conversion_comp !== '' && item.goal_4_conversion_comp > 0 ?
                                                        <FontAwesomeIcon icon={faArrowUp} style={{color:'green'}} className={'me-2'}/> :
                                                        comparedData.length > 0 && item.goal_4_conversion_comp !== '' && item.goal_4_conversion_comp < 0 ?
                                                        <FontAwesomeIcon icon={faArrowDown} style={{color:'red'}} className={'me-2'}/> :
                                                        comparedData.length > 0 && item.goal_4_conversion_comp !== '' ?
                                                        <FontAwesomeIcon icon={faMinus} style={{color:'orange'}} className={'me-2'}/>:
                                                        null
                                                        }
                                                        {comparedData.length > 0 && item.goal_4_conversion_comp !== '' && item.goal_4_conversion_comp !== 0 ?
                                                        Math.abs(item.goal_4_conversion_comp).toLocaleString('en-us') : null}
                                                    </p>
                                                }
                                            </td>
                                            <td>
                                                {item.total_conv === 0 ? '-' : item.total_conv}
                                                {
                                                    comparedData.length > 0 && comparisonMode &&
                                                    <p className='m-0 h-100'>
                                                        {comparedData.length > 0 && item.total_conv_comp !== '' && item.total_conv_comp > 0 ?
                                                        <FontAwesomeIcon icon={faArrowUp} style={{color:'green'}} className={'me-2'}/> :
                                                        comparedData.length > 0 && item.total_conv_comp !== '' && item.total_conv_comp < 0 ?
                                                        <FontAwesomeIcon icon={faArrowDown} style={{color:'red'}} className={'me-2'}/> :
                                                        comparedData.length > 0 && item.total_conv_comp !== '' ?
                                                        <FontAwesomeIcon icon={faMinus} style={{color:'orange'}} className={'me-2'}/>:
                                                        null
                                                        }
                                                        {comparedData.length > 0 && item.total_conv_comp !== '' && item.total_conv_comp !== 0 ?
                                                        Math.abs(item.total_conv_comp).toLocaleString('en-us') : null
                                                        } 
                                                    </p>
                                                }
                                            </td>
                                            <td>
                                                {item.conv_rate === 0 ? '-' : `${item.conv_rate.toFixed(2)}%`}
                                                {
                                                    comparedData.length > 0 && comparisonMode &&
                                                    <p className='m-0 h-100'>
                                                        {comparedData.length > 0 && item.conv_rate_comp !== '' && item.conv_rate_comp > 0 ?
                                                        <FontAwesomeIcon icon={faArrowUp} style={{color:'green'}} className={'me-2'}/> :
                                                        comparedData.length > 0 && item.conv_rate_comp !== '' && item.conv_rate_comp < 0 ?
                                                        <FontAwesomeIcon icon={faArrowDown} style={{color:'red'}} className={'me-2'}/> :
                                                        comparedData.length > 0 && item.conv_rate_comp !== '' ?
                                                        <FontAwesomeIcon icon={faMinus} style={{color:'orange'}} className={'me-2'}/>:
                                                        null
                                                        }
                                                        {
                                                            comparedData.length > 0 && item.conv_rate_comp !== '' && item.conv_rate_comp !== 0 ?
                                                            `${item.conv_rate_comp.toFixed(2)}%` : null
                                                        }
                                                    </p>
                                                }
                                            </td>
                                            <td>
                                                <button type="button" className={`btn btn-light p-0`}
                                                    onClick={e => fetchConvertedUserHandler(item['CoreHome_VisitorId'], item.Actions_EntryPageUrl)}
                                                >
                                                    <FontAwesomeIcon icon={faEye} />
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
};

export default Traffic;