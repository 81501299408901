

function MatchedItem(props){
    // console.log(props.item.matched_percent > 0.3)
    if (parseFloat(props.item.matched_percent) > 0.3) {
        return (
            <a id={`${props.item._id}`} class={`list-group-item list-group-item-action mb-3 ${props.item._id === props.highlight && 'bg-warning'}`} style={{"word-break":"break-word"}} aria-current="true">
                <div class="d-flex w-100 justify-content-between mb-2">
                    <h5 class="mb-1">
                        {props.item.title}
                        <span class="badge bg-danger rounded-pill ms-2">{parseFloat(props.item.matched_percent*100).toFixed(0)}%</span>
                    </h5>
                    
                </div>
                <p class="mb-1">{props.item.matched_text}</p>
                <a href={props.item.url} target="_blank"><small>{props.item.url}</small></a>
            </a>
        )
    }
};

export default MatchedItem;