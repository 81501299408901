import React, { useState, useEffect } from 'react';
import { getAuthToken } from '../util/token';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Accordion from 'react-bootstrap/Accordion';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretRight, faAngleUp, faAngleDown, faFilter, faTrash } from '@fortawesome/free-solid-svg-icons';

import { v4 as uuid4 } from 'uuid';

const data = {
  "url_detail": {
    "URL": "https://bbcincorp.sg/vi",
    "Content Type": "text/html",
    "Status Code": 200,
    "Indexability": "Indexable",
    "Size": 0,
    "Title 1": "Nhà Cung Cấp Dịch Vụ Doanh Nghiệp Uy Tín Tại Singapore",
    "Title 1 Length": 54,
    "Meta Description 1": "BBCIncorp Pte. Ltd. giúp chủ doanh nghiệp dễ dàng thành lập công ty ở Singapore. Hỗ trợ mở tài khoản ngân hàng, kế toán và kiểm toán, visa, ...",
    "Meta Description 1 Length": 143,
    "H1 1": "",
    "H1 1 Length": 0,
    "H2 1": "  Dịch Vụ Đáng Tin Cậy cho doanh nghiệp của bạn",
    "H2 1 Length": 47,
    "Meta robots": "follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large",
    "Canonical link": "https://bbcincorp.sg/vi",
    "Word Count": "678",
    "Sentence Count": "10",
    "Avg Words per Sentence": 68,
    "Flesch Score": "",
    "Crawl Depth": 0,
    "Response Time": 0.19,
    "Inlinks": 355,
    "Unique Inlinks": 121,
    "Outlinks": 106,
    "Unique Outlinks": 80,
    "External Outlinks": 12,
    "Unique External Outlinks": 10
  },
  "inlinks": [
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/tuyen-dung-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/tuyen-dung-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang Chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/tuyen-dung-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/thi-thuc",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/thi-thuc",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang Chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/thi-thuc",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/ke-toan-va-kiem-toan",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/ke-toan-va-kiem-toan",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang Chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/ke-toan-va-kiem-toan",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang Chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/tai-khoan-thanh-toan-truc-tuyen",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/tai-khoan-thanh-toan-truc-tuyen",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang Chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/div/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/tai-khoan-thanh-toan-truc-tuyen",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/thu-ky-cong-ty",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/thu-ky-cong-ty",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang Chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/div/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/thu-ky-cong-ty",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/ngan-hang",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/ngan-hang",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang Chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/ngan-hang",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/ke-toan-va-thue",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/ke-toan-va-thue",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang Chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/div/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/ke-toan-va-thue",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/thi-thuc-viec-lam",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/thi-thuc-viec-lam",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang Chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/div/main/div/div/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/thi-thuc-viec-lam",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/thue",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/thue",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang Chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/thue",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/mo-tai-khoan-ngan-hang",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/mo-tai-khoan-ngan-hang",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang Chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/div/main/div/div/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/mo-tai-khoan-ngan-hang",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/dich-vu",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/dich-vu",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang Chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/div/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/dich-vu",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/thanh-lap-cong-ty-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/thanh-lap-cong-ty-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang Chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/div/main/div/div/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/thanh-lap-cong-ty-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/kiem-tra-ma-ssic",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/kiem-tra-ma-ssic",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang Chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/kiem-tra-ma-ssic",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/cong-cu-kiem-tra-ten-doanh-nghiep",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/cong-cu-kiem-tra-ten-doanh-nghiep",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang Chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/cong-cu-kiem-tra-ten-doanh-nghiep",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/cong-cu-goi-y-ten-doanh-nghiep",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/cong-cu-goi-y-ten-doanh-nghiep",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang Chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/div/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/cong-cu-goi-y-ten-doanh-nghiep",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/thanh-lap-cong-ty",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/thanh-lap-cong-ty",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang Chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/thanh-lap-cong-ty",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/lien-he",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/lien-he",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang Chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/lien-he",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/ve-chung-toi",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/ve-chung-toi",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang Chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/div/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/ve-chung-toi",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/cong-thanh-toan-truc-tuyen",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/cong-thanh-toan-truc-tuyen",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang Chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/div/main/div/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/cong-thanh-toan-truc-tuyen",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/thoa-thuan-su-dung-dich-vu",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/thoa-thuan-su-dung-dich-vu",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/div/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/thoa-thuan-su-dung-dich-vu",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "www.bbcincorp.sg/vi",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/div/div/div[3]/div/div[2]/p/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/thoa-thuan-su-dung-dich-vu",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "www.bbcincorp.sg/vi",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/div/div/div[3]/div[7]/div[2]/p/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/thoa-thuan-su-dung-dich-vu",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/huong-dan-thanh-toan",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/huong-dan-thanh-toan",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang Chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/div/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/huong-dan-thanh-toan",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "www.bbcincorp.sg",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/div/div/div/div/div/p/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/huong-dan-thanh-toan",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "bbcincorp.sg",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/div/div/div[3]/div[2]/div[4]/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/huong-dan-thanh-toan",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/cac-quoc-gia-va-vung-lanh-tho-bi-han-che",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/cac-quoc-gia-va-vung-lanh-tho-bi-han-che",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang Chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/div/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/cac-quoc-gia-va-vung-lanh-tho-bi-han-che",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/chinh-sach-bao-mat",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/chinh-sach-bao-mat",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang Chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/div/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/chinh-sach-bao-mat",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "www.bbcincorp.sg",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/div/div/div/div/div/p/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/chinh-sach-bao-mat",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "www.bbcincorp.sg",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/div/div/div[3]/div[3]/div[5]/p/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/chinh-sach-bao-mat",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/chinh-sach-hoan-tra",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/chinh-sach-hoan-tra",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang Chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/div/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/chinh-sach-hoan-tra",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/dieu-khoan-dich-vu",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/dieu-khoan-dich-vu",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang Chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/div/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/dieu-khoan-dich-vu",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/tin-tuc",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/tin-tuc",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang Chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/tin-tuc",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/chinh-sach-ho-tro-cho-nha-tuyen-dung-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/chinh-sach-ho-tro-cho-nha-tuyen-dung-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/chinh-sach-ho-tro-cho-nha-tuyen-dung-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/huong-dan-tuyen-dung-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/huong-dan-tuyen-dung-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/huong-dan-tuyen-dung-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/huong-dan-ve-quy-phong-xa-trung-uong-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/huong-dan-ve-quy-phong-xa-trung-uong-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/huong-dan-ve-quy-phong-xa-trung-uong-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/van-hoa-lam-viec-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/van-hoa-lam-viec-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/van-hoa-lam-viec-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/luat-tuyen-dung-va-lao-dong-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/luat-tuyen-dung-va-lao-dong-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/luat-tuyen-dung-va-lao-dong-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/loi-ich-khi-tro-thanh-cong-dan-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/loi-ich-khi-tro-thanh-cong-dan-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/loi-ich-khi-tro-thanh-cong-dan-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/thi-thuc/thuong-tru-nhan-va-cong-dan-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/thi-thuc/thuong-tru-nhan-va-cong-dan-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/quyen-loi-khi-tro-thanh-thuong-tru-nhan-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/quyen-loi-khi-tro-thanh-thuong-tru-nhan-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/quyen-loi-khi-tro-thanh-thuong-tru-nhan-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/thi-thuc-cho-doanh-nhan-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/thi-thuc-cho-doanh-nhan-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/thi-thuc-cho-doanh-nhan-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/dang-ky-quyen-cong-dan-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/dang-ky-quyen-cong-dan-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/dang-ky-quyen-cong-dan-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/giay-phep-lao-dong-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/giay-phep-lao-dong-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/giay-phep-lao-dong-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/tin-tuc/chinh-sach-moi-cho-doanh-nghiep-tai-singapore-mua-covid-19",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/tin-tuc/chinh-sach-moi-cho-doanh-nghiep-tai-singapore-mua-covid-19",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/tin-tuc/chinh-sach-moi-cho-doanh-nghiep-tai-singapore-mua-covid-19",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/tin-tuc/chuong-trinh-ho-tro-moi-tu-chinh-phu-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/tin-tuc/chuong-trinh-ho-tro-moi-tu-chinh-phu-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/tin-tuc/chuong-trinh-ho-tro-moi-tu-chinh-phu-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/tin-tuc/cac-thay-doi-ve-thi-thuc-tai-singapore-trong-nam-2020",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/tin-tuc/cac-thay-doi-ve-thi-thuc-tai-singapore-trong-nam-2020",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/tin-tuc/cac-thay-doi-ve-thi-thuc-tai-singapore-trong-nam-2020",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/tin-tuc/tong-quan-ve-ngan-sach-fortitude-budget-cho-doanh-nghiep",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/tin-tuc/tong-quan-ve-ngan-sach-fortitude-budget-cho-doanh-nghiep",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/tin-tuc/tong-quan-ve-ngan-sach-fortitude-budget-cho-doanh-nghiep",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/tin-tuc/them-ngan-sach-ho-tro-cho-doanh-nghiep",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/tin-tuc/them-ngan-sach-ho-tro-cho-doanh-nghiep",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/tin-tuc/them-ngan-sach-ho-tro-cho-doanh-nghiep",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/tin-tuc/nop-giay-dang-ky-thong-tin-tin-chu-doanh-nghiep-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/tin-tuc/nop-giay-dang-ky-thong-tin-tin-chu-doanh-nghiep-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/tin-tuc/nop-giay-dang-ky-thong-tin-tin-chu-doanh-nghiep-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/tin-tuc/gia-han-thoi-gian-cho-cac-yeu-cau-hang-nam-tu-acra-va-iras",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/tin-tuc/gia-han-thoi-gian-cho-cac-yeu-cau-hang-nam-tu-acra-va-iras",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/tin-tuc/gia-han-thoi-gian-cho-cac-yeu-cau-hang-nam-tu-acra-va-iras",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/tin-tuc/quy-dinh-moi-ve-gst-tai-singapore-2020",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/tin-tuc/quy-dinh-moi-ve-gst-tai-singapore-2020",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/tin-tuc/quy-dinh-moi-ve-gst-tai-singapore-2020",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/tin-tuc/thong-bao-cong-ty-co-phan-tai-viet-nam-cua-bbcincorp",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/tin-tuc/thong-bao-cong-ty-co-phan-tai-viet-nam-cua-bbcincorp",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/tin-tuc/thong-bao-cong-ty-co-phan-tai-viet-nam-cua-bbcincorp",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/tin-tuc/bbcincorp-tham-gia-su-kien-sccv-tai-tp-hcm",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/tin-tuc/bbcincorp-tham-gia-su-kien-sccv-tai-tp-hcm",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/tin-tuc/bbcincorp-tham-gia-su-kien-sccv-tai-tp-hcm",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/tin-tuc/ngan-sach-singapore-2020",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/tin-tuc/ngan-sach-singapore-2020",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/tin-tuc/ngan-sach-singapore-2020",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/tin-tuc/hoi-thao-truc-tuyen-thanh-lap-cong-ty-singapore-cho-doanh-nghiep-viet-nam",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/tin-tuc/hoi-thao-truc-tuyen-thanh-lap-cong-ty-singapore-cho-doanh-nghiep-viet-nam",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/tin-tuc/hoi-thao-truc-tuyen-thanh-lap-cong-ty-singapore-cho-doanh-nghiep-viet-nam",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/tin-tuc/ky-niem-le-quoc-khanh-singapore-lan-thu-58-tai-tp-hcm",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/tin-tuc/ky-niem-le-quoc-khanh-singapore-lan-thu-58-tai-tp-hcm",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/tin-tuc/ky-niem-le-quoc-khanh-singapore-lan-thu-58-tai-tp-hcm",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/tac-gia/bbcincorp-content-team",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/tac-gia/bbcincorp-content-team",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/cong-ty-khong-hoat-dong-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/cong-ty-khong-hoat-dong-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/cong-ty-khong-hoat-dong-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/thanh-lap-cong-ty/dong-cua-cong-ty-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/thanh-lap-cong-ty/dong-cua-cong-ty-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/huy-dang-ky-kinh-doanh-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/huy-dang-ky-kinh-doanh-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/huy-dang-ky-kinh-doanh-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/giai-the-cong-ty-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/giai-the-cong-ty-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/giai-the-cong-ty-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/yeu-cau-bao-cao-hang-nam-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/yeu-cau-bao-cao-hang-nam-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/yeu-cau-bao-cao-hang-nam-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/huong-dan-cong-bo-co-tuc-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/huong-dan-cong-bo-co-tuc-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/huong-dan-cong-bo-co-tuc-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/yeu-cau-phap-ly-thuong-nien-voi-cong-ty-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/yeu-cau-phap-ly-thuong-nien-voi-cong-ty-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/yeu-cau-phap-ly-thuong-nien-voi-cong-ty-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/chuyen-nhuong-co-phan-trong-cong-ty-trach-nhiem-huu-han-tu-nhan-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/chuyen-nhuong-co-phan-trong-cong-ty-trach-nhiem-huu-han-tu-nhan-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/chuyen-nhuong-co-phan-trong-cong-ty-trach-nhiem-huu-han-tu-nhan-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/thanh-lap-cong-ty/dieu-hanh-cong-ty-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/thanh-lap-cong-ty/dieu-hanh-cong-ty-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/huong-dan-ve-corppass-cho-doanh-nghiep-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/huong-dan-ve-corppass-cho-doanh-nghiep-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/huong-dan-ve-corppass-cho-doanh-nghiep-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/tai-sao-mo-cong-ty-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/tai-sao-mo-cong-ty-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/tai-sao-mo-cong-ty-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/phat-trien-kinh-doanh-tai-singapore-cho-nguoi-nuoc-ngoai",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/phat-trien-kinh-doanh-tai-singapore-cho-nguoi-nuoc-ngoai",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/phat-trien-kinh-doanh-tai-singapore-cho-nguoi-nuoc-ngoai",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/cong-ty-thuong-mai-xuat-nhap-khau-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/cong-ty-thuong-mai-xuat-nhap-khau-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/cong-ty-thuong-mai-xuat-nhap-khau-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/thu-ky-cong-ty-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/thu-ky-cong-ty-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/thu-ky-cong-ty-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/6-co-quan-chinh-phu-singapore-ma-ban-can-biet",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/6-co-quan-chinh-phu-singapore-ma-ban-can-biet",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/6-co-quan-chinh-phu-singapore-ma-ban-can-biet",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/quy-trinh-thanh-lap-cong-ty-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/quy-trinh-thanh-lap-cong-ty-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/quy-trinh-thanh-lap-cong-ty-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/cam-nang-kinh-doanh-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/cam-nang-kinh-doanh-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/singapore-vs-hong-kong",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/singapore-vs-hong-kong",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/singapore-vs-hong-kong",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/giam-doc-chi-dinh-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/giam-doc-chi-dinh-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/giam-doc-chi-dinh-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/tong-quan-ve-trademark-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/tong-quan-ve-trademark-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/tong-quan-ve-trademark-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/cac-loai-bao-hiem-ma-doanh-nghiep-can-co-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/cac-loai-bao-hiem-ma-doanh-nghiep-can-co-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/cac-loai-bao-hiem-ma-doanh-nghiep-can-co-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/cach-chon-van-phong-ao-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/cach-chon-van-phong-ao-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/cach-chon-van-phong-ao-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/huong-dan-tuan-thu-luat-bao-ve-thong-tin-ca-nhan-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/huong-dan-tuan-thu-luat-bao-ve-thong-tin-ca-nhan-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/huong-dan-tuan-thu-luat-bao-ve-thong-tin-ca-nhan-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/chuyen-doi-quoc-gia-dang-ky-kinh-doanh-den-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/chuyen-doi-quoc-gia-dang-ky-kinh-doanh-den-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/chuyen-doi-quoc-gia-dang-ky-kinh-doanh-den-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/dang-ky-giay-phep-kinh-doanh-voi-mot-so-linh-vuc-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/dang-ky-giay-phep-kinh-doanh-voi-mot-so-linh-vuc-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/dang-ky-giay-phep-kinh-doanh-voi-mot-so-linh-vuc-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/thanh-lap-cong-ty/thanh-lap-cong-ty-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/thanh-lap-cong-ty/thanh-lap-cong-ty-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/cong-ty-holding-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/cong-ty-holding-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/cong-ty-holding-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/thanh-lap-cong-ty/cac-van-de-khac-khi-kinh-doanh-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/thanh-lap-cong-ty/cac-van-de-khac-khi-kinh-doanh-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/cong-ty-tu-nhan-mien-tru-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/cong-ty-tu-nhan-mien-tru-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/cong-ty-tu-nhan-mien-tru-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/cac-loai-hinh-cong-ty-nuoc-ngoai-o-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/cac-loai-hinh-cong-ty-nuoc-ngoai-o-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/cac-loai-hinh-cong-ty-nuoc-ngoai-o-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/doanh-nghiep-tu-nhan-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/doanh-nghiep-tu-nhan-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/doanh-nghiep-tu-nhan-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/cong-ty-hop-danh-trach-nhiem-huu-han-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/cong-ty-hop-danh-trach-nhiem-huu-han-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/cong-ty-hop-danh-trach-nhiem-huu-han-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/cac-loai-hinh-cong-ty-pho-bien-nhat-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/cac-loai-hinh-cong-ty-pho-bien-nhat-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/cac-loai-hinh-cong-ty-pho-bien-nhat-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/so-sanh-ba-loai-hinh-cong-ty-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/so-sanh-ba-loai-hinh-cong-ty-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/so-sanh-ba-loai-hinh-cong-ty-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/cong-ty-trach-nhiem-huu-han-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/cong-ty-trach-nhiem-huu-han-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/cong-ty-trach-nhiem-huu-han-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/thanh-lap-cong-ty/cac-loai-hinh-kinh-doanh-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/thanh-lap-cong-ty/cac-loai-hinh-kinh-doanh-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/hiep-dinh-tranh-danh-thue-2-lan-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/hiep-dinh-tranh-danh-thue-2-lan-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/hiep-dinh-tranh-danh-thue-2-lan-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/hiep-dinh-tranh-danh-thue-2-lan-giua-singapore-va-viet-nam",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/hiep-dinh-tranh-danh-thue-2-lan-giua-singapore-va-viet-nam",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/hiep-dinh-tranh-danh-thue-2-lan-giua-singapore-va-viet-nam",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/chuong-trinh-ho-tro-startup-cong-nghe-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/chuong-trinh-ho-tro-startup-cong-nghe-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/chuong-trinh-ho-tro-startup-cong-nghe-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/thue/uu-dai-thue-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/thue/uu-dai-thue-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/mien-thue-cho-thu-nhap-nuoc-ngoai-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/mien-thue-cho-thu-nhap-nuoc-ngoai-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/mien-thue-cho-thu-nhap-nuoc-ngoai-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/cach-thuc-giam-thue-thu-nhap-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/cach-thuc-giam-thue-thu-nhap-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/cach-thuc-giam-thue-thu-nhap-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/uu-dai-thue-cho-mot-so-linh-vuc-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/uu-dai-thue-cho-mot-so-linh-vuc-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/uu-dai-thue-cho-mot-so-linh-vuc-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/thue-nha-thau-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/thue-nha-thau-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/thue-nha-thau-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/thue-thu-nhap-doanh-nghiep-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/thue-thu-nhap-doanh-nghiep-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/thue-thu-nhap-doanh-nghiep-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/thue-thu-nhap-ca-nhan-o-singapore-cho-nguoi-nuoc-ngoai",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/thue-thu-nhap-ca-nhan-o-singapore-cho-nguoi-nuoc-ngoai",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/thue-thu-nhap-ca-nhan-o-singapore-cho-nguoi-nuoc-ngoai",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/thue-hang-hoa-dich-vu-gst-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/thue-hang-hoa-dich-vu-gst-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/thue-hang-hoa-dich-vu-gst-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/thue-truoc-ba-bat-dong-san-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/thue-truoc-ba-bat-dong-san-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/thue-truoc-ba-bat-dong-san-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/thue/cac-loai-thue-pho-bien-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/thue/cac-loai-thue-pho-bien-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/mo-tai-khoan-ngan-hang-doanh-nghiep-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/mo-tai-khoan-ngan-hang-doanh-nghiep-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/mo-tai-khoan-ngan-hang-doanh-nghiep-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/thue-bat-dong-san-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/thue-bat-dong-san-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/thue-bat-dong-san-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/6-cau-hoi-pho-bien-nhat-ve-co-tuc-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/6-cau-hoi-pho-bien-nhat-ve-co-tuc-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/6-cau-hoi-pho-bien-nhat-ve-co-tuc-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/thue-thu-nhap-cua-cong-ty-holding-dau-tu-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/thue-thu-nhap-cua-cong-ty-holding-dau-tu-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/thue-thu-nhap-cua-cong-ty-holding-dau-tu-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/tong-quan-ve-he-thong-thue-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/tong-quan-ve-he-thong-thue-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/tong-quan-ve-he-thong-thue-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/xbrl-ngon-ngu-bao-cao-cho-doanh-nghiep-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/xbrl-ngon-ngu-bao-cao-cho-doanh-nghiep-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/xbrl-ngon-ngu-bao-cao-cho-doanh-nghiep-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/mien-tru-kiem-toan-cho-doanh-nghiep-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/mien-tru-kiem-toan-cho-doanh-nghiep-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/mien-tru-kiem-toan-cho-doanh-nghiep-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/gioi-thieu-ve-chuan-muc-ke-toan-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/gioi-thieu-ve-chuan-muc-ke-toan-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/gioi-thieu-ve-chuan-muc-ke-toan-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/thi-thuc-lam-viec-employment-pass-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/thi-thuc-lam-viec-employment-pass-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/thi-thuc-lam-viec-employment-pass-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/thi-thuc-cho-nguoi-phu-thuoc-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/thi-thuc-cho-nguoi-phu-thuoc-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/thi-thuc-cho-nguoi-phu-thuoc-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/singapore-tech-pass",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/singapore-tech-pass",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/singapore-tech-pass",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/thi-thuc/giay-phep-lao-dong-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/thi-thuc/giay-phep-lao-dong-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/tong-quan-ve-dang-ky-thuong-tru-nhan-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/tong-quan-ve-dang-ky-thuong-tru-nhan-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/tong-quan-ve-dang-ky-thuong-tru-nhan-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/cach-chon-ngan-hang-singapore-danh-cho-nguoi-nuoc-ngoai",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/cach-chon-ngan-hang-singapore-danh-cho-nguoi-nuoc-ngoai",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/cach-chon-ngan-hang-singapore-danh-cho-nguoi-nuoc-ngoai",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/top-6-ngan-hang-tot-nhat-cho-nguoi-nuoc-ngoai-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/top-6-ngan-hang-tot-nhat-cho-nguoi-nuoc-ngoai-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/top-6-ngan-hang-tot-nhat-cho-nguoi-nuoc-ngoai-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/gia-tang-co-hoi-mo-tai-khoan-ngan-hang-doanh-nghiep-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/gia-tang-co-hoi-mo-tai-khoan-ngan-hang-doanh-nghiep-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/gia-tang-co-hoi-mo-tai-khoan-ngan-hang-doanh-nghiep-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/cach-chon-nam-tai-chinh-cho-doanh-nghiep-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/cach-chon-nam-tai-chinh-cho-doanh-nghiep-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "Trang chủ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/section/div[3]/div/ul/li/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/bai-viet/cach-chon-nam-tai-chinh-cho-doanh-nghiep-tai-singapore",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/case-study/cosugames",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/case-study/cosugames",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/case-study/beincom",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi/case-study/beincom",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    }
  ],
  "outlinks": [
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/assets/images/common/logo-bbc.png",
      "Anchor text": "",
      "Alt text": "logo-bbc",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a/figure/div/picture/img",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/assets/images/common/logo-bbc-black.png",
      "Anchor text": "",
      "Alt text": "logo-bbc-black",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a/figure/div[2]/picture/img",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/assets/images/layout/layout-call-phone.png",
      "Anchor text": "",
      "Alt text": "call-telephone",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/div/a/div/picture/img",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/assets/images/layout/layout-report.png",
      "Anchor text": "",
      "Alt text": "report",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div[2]/div/ul/li/div[2]/div/div/div/div/div[3]/picture/img",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/assets/images/layout/layout-auditing.png",
      "Anchor text": "",
      "Alt text": "auditing",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div[2]/div/ul/li/div[2]/div/div/div[2]/div/div[3]/picture/img",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/assets/images/layout/layout-business-solution.svg",
      "Anchor text": "",
      "Alt text": "business-solution",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div[2]/div/ul/li/div[2]/div/div/div[3]/div/div[3]/img",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/assets/images/layout/layout-guide.svg",
      "Anchor text": "",
      "Alt text": "guide",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div[2]/div/ul/li[2]/div[2]/div/div/div/div/div[3]/img",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/assets/images/layout/layout-news.svg",
      "Anchor text": "",
      "Alt text": "news",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div[2]/div/ul/li[2]/div[2]/div/div/div[2]/div/div[3]/img",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/assets/images/layout/layout-tool.svg",
      "Anchor text": "",
      "Alt text": "tool",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div[2]/div/ul/li[2]/div[2]/div/div/div[3]/div/div[3]/img",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/assets/images/banner/banner-homepage.png",
      "Anchor text": "",
      "Alt text": "banner image",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/div/section/div/div/picture/img",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/assets/images/homepage/service1-thanh-lap-cong-ty.png",
      "Anchor text": "",
      "Alt text": "thanh-lap-cong-ty",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/div/section[2]/div[2]/div[2]/div/picture/img",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/assets/images/homepage/service2-ke-toan-va-thue.png",
      "Anchor text": "",
      "Alt text": "ke-toan-va-thue",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/div/section[2]/div[3]/div[2]/div/picture/img",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/assets/images/homepage/service3-thu-ky-cong-ty.png",
      "Anchor text": "",
      "Alt text": "thanh-lap-cong-ty",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/div/section[2]/div[4]/div[2]/div/picture/img",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/assets/images/homepage/gia-tri-ma-chung-toi-mang-lai-human.png",
      "Anchor text": "",
      "Alt text": "placeholder",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/div/section[3]/div/div/div/div/picture/img",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/assets/icons/dollar.svg",
      "Anchor text": "",
      "Alt text": "placeholder",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/div/section[3]/div/div/div[2]/div/div/div/div/div/img",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/assets/icons/flash.svg",
      "Anchor text": "",
      "Alt text": "placeholder",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/div/section[3]/div/div/div[2]/div/div/div[2]/div/div/img",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/assets/icons/hand-human.svg",
      "Anchor text": "",
      "Alt text": "placeholder",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/div/section[3]/div/div/div[2]/div/div/div[3]/div/div/img",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/assets/icons/services.svg",
      "Anchor text": "",
      "Alt text": "placeholder",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/div/section[3]/div/div/div[2]/div/div/div[4]/div/div/img",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/assets/images/homepage/gia-tri-ma-chung-toi-mang-lai-bg.png",
      "Anchor text": "",
      "Alt text": "placeholder",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/div/section[3]/div[2]/picture/img",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/assets/images/homepage/happy-young-asian-businesswoman-working-with-laptop-computer-office.png",
      "Anchor text": "",
      "Alt text": "placeholder",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/div/section[4]/div/div/div/div/div/div/div/div[2]/div/picture/img",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/assets/images/homepage/group-asian-business-people-posing-white-background.png",
      "Anchor text": "",
      "Alt text": "placeholder",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/div/section[4]/div/div/div/div/div/div[2]/div/div[2]/div/picture/img",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/assets/images/homepage/cafef-logo.png",
      "Anchor text": "",
      "Alt text": "placeholder",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/div/div/div/div/div/div/div/img",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/assets/images/homepage/dan-tri-logo.png",
      "Anchor text": "",
      "Alt text": "placeholder",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/div/div/div/div/div/div/div[2]/img",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/assets/images/homepage/vnexpress-logo.png",
      "Anchor text": "",
      "Alt text": "placeholder",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/div/div/div/div/div/div/div[3]/img",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/assets/images/layout/layout-logo-footer.png",
      "Anchor text": "",
      "Alt text": "logo-footer",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a/div/picture/img",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/assets/images/layout/layout-buiding.png",
      "Anchor text": "",
      "Alt text": "building",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div[2]/ul/li/a/div/picture/img",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/assets/images/layout/layout-email.png",
      "Anchor text": "",
      "Alt text": "building",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div[2]/ul/li[2]/a/div/picture/img",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/assets/images/layout/layout-call.png",
      "Anchor text": "",
      "Alt text": "building",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div[2]/ul/li[3]/a/div/picture/img",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/assets/images/layout/layout-buiding.png",
      "Anchor text": "",
      "Alt text": "building",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div[2]/ul[2]/li/a/div/picture/img",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/assets/images/layout/layout-call.png",
      "Anchor text": "",
      "Alt text": "building",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div[2]/ul[2]/li[2]/a/div/picture/img",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/assets/images/layout/layout-dcma.png",
      "Anchor text": "",
      "Alt text": "img-dcma",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div[2]/div/div/a/div/picture/img",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/assets/images/layout/layout-stripe.png",
      "Anchor text": "",
      "Alt text": "stripe",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div[2]/div/div[2]/div/picture/img",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/assets/images/layout/layout-visa.png",
      "Anchor text": "",
      "Alt text": "visa",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div[2]/div/div[2]/div[2]/picture/img",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/assets/images/layout/layout-mastercard.png",
      "Anchor text": "",
      "Alt text": "mastercard",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div[2]/div/div[3]/div/picture/img",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/assets/images/layout/layout-american.png",
      "Anchor text": "",
      "Alt text": "american",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div[2]/div/div[3]/div[2]/picture/img",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/assets/images/layout/layout-mastro.png",
      "Anchor text": "",
      "Alt text": "mastro",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div[2]/div/div[4]/div/picture/img",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/assets/images/layout/layout-dcma.png",
      "Anchor text": "",
      "Alt text": "img-dcma",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div[5]/div[3]/div/div/picture/img",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/assets/images/layout/layout-stripe.png",
      "Anchor text": "",
      "Alt text": "stripe",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div[5]/div[3]/div[2]/div/picture/img",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/assets/images/layout/layout-visa.png",
      "Anchor text": "",
      "Alt text": "visa",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div[5]/div[3]/div[2]/div[2]/picture/img",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/assets/images/layout/layout-mastercard.png",
      "Anchor text": "",
      "Alt text": "mastercard",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div[5]/div[3]/div[2]/div[3]/picture/img",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/assets/images/layout/layout-american.png",
      "Anchor text": "",
      "Alt text": "american",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div[5]/div[3]/div[2]/div[4]/picture/img",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/assets/images/layout/layout-mastro.png",
      "Anchor text": "",
      "Alt text": "mastro",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div[5]/div[3]/div[2]/div[5]/picture/img",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/_next/static/css/2d5339368b8359f9.css",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/head/link",
      "Link position": ""
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/_next/static/css/7ebac14d71729b28.css",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/head/link",
      "Link position": ""
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/_next/static/chunks/polyfills-c67a75d1b6f99dc8.js",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/head/script",
      "Link position": ""
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/_next/static/chunks/webpack-01db7ca11dc59b72.js",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/head/script",
      "Link position": ""
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/_next/static/chunks/framework-8e69f8bb355563b7.js",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/head/script",
      "Link position": ""
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/_next/static/chunks/main-66adfdbb42943ca0.js",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/head/script",
      "Link position": ""
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/_next/static/chunks/pages/_app-2cbc80dfd0b83bca.js",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/head/script",
      "Link position": ""
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/_next/static/chunks/2580-9a4e5f30cd0934eb.js",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/head/script",
      "Link position": ""
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/_next/static/chunks/7453-8cfaca8e3d4df7ac.js",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/head/script",
      "Link position": ""
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/_next/static/chunks/2807-7243375e3b71f7b0.js",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/head/script",
      "Link position": ""
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/_next/static/chunks/7536-e8558f430891afac.js",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/head/script",
      "Link position": ""
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/_next/static/chunks/1216-1c66bff6adc5f7b8.js",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/head/script",
      "Link position": ""
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/_next/static/chunks/4505-20ffd1c2fbb7690f.js",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/head/script",
      "Link position": ""
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/_next/static/chunks/3892-eab26011f6a6ae71.js",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/head/script",
      "Link position": ""
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/_next/static/chunks/pages/vi-dddef4ff7e0c224f.js",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/head/script",
      "Link position": ""
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/_next/static/9wUAsWJKFRYUMY2_BCbvs/_buildManifest.js",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/head/script",
      "Link position": ""
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/_next/static/9wUAsWJKFRYUMY2_BCbvs/_ssgManifest.js",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/head/script",
      "Link position": ""
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "tel:18006338",
      "Anchor text": "18006338",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/vi/dich-vu",
      "Anchor text": "Dịch Vụ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div[2]/div/ul/li/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/vi/thanh-lap-cong-ty-tai-singapore",
      "Anchor text": "Thành Lập Công Ty Tại Singapore",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div[2]/div/ul/li/div[2]/div/div/div/div/div[2]/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/vi/thi-thuc-viec-lam",
      "Anchor text": "Thị Thực Việc Làm",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div[2]/div/ul/li/div[2]/div/div/div/div/div[2]/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/vi/mo-tai-khoan-ngan-hang",
      "Anchor text": "Mở Tài Khoản Ngân Hàng",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div[2]/div/ul/li/div[2]/div/div/div/div/div[2]/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/vi/ke-toan-va-thue",
      "Anchor text": "Kế Toán và Thuế",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div[2]/div/ul/li/div[2]/div/div/div[2]/div/div[2]/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/vi/thu-ky-cong-ty",
      "Anchor text": "Thư Ký Công Ty",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div[2]/div/ul/li/div[2]/div/div/div[2]/div/div[2]/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/vi/tai-khoan-thanh-toan-truc-tuyen",
      "Anchor text": "Tài Khoản Thanh Toán Trực Tuyến",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div[2]/div/ul/li/div[2]/div/div/div[3]/div/div[2]/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/vi/bai-viet",
      "Anchor text": "Bài Viết Hướng Dẫn",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div[2]/div/ul/li[2]/div[2]/div/div/div/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/vi/bai-viet/thanh-lap-cong-ty",
      "Anchor text": "Thành Lập Công Ty",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div[2]/div/ul/li[2]/div[2]/div/div/div/div/div[2]/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/vi/bai-viet/ngan-hang",
      "Anchor text": "Ngân Hàng",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div[2]/div/ul/li[2]/div[2]/div/div/div/div/div[2]/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/vi/bai-viet/ke-toan-va-kiem-toan",
      "Anchor text": "Kế Toán và Kiểm Toán",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div[2]/div/ul/li[2]/div[2]/div/div/div/div/div[2]/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/vi/bai-viet/thue",
      "Anchor text": "Thuế",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div[2]/div/ul/li[2]/div[2]/div/div/div/div/div[2]/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/vi/bai-viet/thi-thuc",
      "Anchor text": "Thị Thực",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div[2]/div/ul/li[2]/div[2]/div/div/div/div/div[2]/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/vi/bai-viet/tuyen-dung-tai-singapore",
      "Anchor text": "Tuyển Dụng",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div[2]/div/ul/li[2]/div[2]/div/div/div/div/div[2]/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/vi/tin-tuc",
      "Anchor text": "Tin Tức",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div[2]/div/ul/li[2]/div[2]/div/div/div[2]/div/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/vi/cong-cu-goi-y-ten-doanh-nghiep",
      "Anchor text": "Công Cụ Gợi Ý Tên Doanh Nghiệp",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div[2]/div/ul/li[2]/div[2]/div/div/div[3]/div/div[2]/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/vi/cong-cu-kiem-tra-ten-doanh-nghiep",
      "Anchor text": "Công Cụ Kiểm Tra Tên Doanh Nghiệp Dự Kiến",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div[2]/div/ul/li[2]/div[2]/div/div/div[3]/div/div[2]/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/vi/kiem-tra-ma-ssic",
      "Anchor text": "Kiểm Tra Mã SSIC",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div[2]/div/ul/li[2]/div[2]/div/div/div[3]/div/div[2]/div/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/vi/ve-chung-toi",
      "Anchor text": "Về Chúng Tôi",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div[2]/div/ul/li[3]/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/vi/lien-he",
      "Anchor text": "Liên Hệ",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div[2]/div/ul/li[4]/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "tel:18006338",
      "Anchor text": "1800 6338",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div[2]/a",
      "Link position": "Navigation"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/vi/thanh-lap-cong-ty-tai-singapore",
      "Anchor text": "Liên hệ ngay",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/div/section[4]/div/div/div/div/div/div/div/div[2]/div[2]/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/vi/thanh-lap-cong-ty-tai-singapore",
      "Anchor text": "Liên hệ ngay",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/div/section[4]/div/div/div/div/div/div[2]/div/div[2]/div[2]/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/vi/thanh-lap-cong-ty-tai-singapore",
      "Anchor text": "Xem thêm các gói khác",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/div/section[4]/div/div[2]/a",
      "Link position": "Content"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://www.facebook.com/bbcincorpvn",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://twitter.com/bbcincorpsgvn",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://www.youtube.com/channel/UCBckTm3hg_KZaa-he7yA5bQ",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://www.linkedin.com/showcase/bbcincorp-pte-ltd",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://goo.gl/maps/28jm5nMZMeCMYasC9",
      "Anchor text": "9 Raffles Place, #29-05 Republic Plaza, Singapore (048619)",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div[2]/ul/li/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "mailto:service@bbcincorp.sg",
      "Anchor text": "service@bbcincorp.sg",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div[2]/ul/li[2]/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "tel:+6560118200",
      "Anchor text": "(+65) 6011 8200",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div[2]/ul/li[3]/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://goo.gl/maps/CSpMzzZsq5ncvJLT9",
      "Anchor text": "Số 39-41 Đường D4, Khu Him Lam, Phường Tân Hưng, Quận 7, Tp. Hồ Chí Minh",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div[2]/ul[2]/li/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "tel:18006338",
      "Anchor text": "Hotline: 18006338",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div[2]/ul[2]/li[2]/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "//www.dmca.com/Protection/Status.aspx?id=e07f8571-6859-4c17-800e-fc1d9f9ed6b6&refurl=https://bbcincorp.sg/vi",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div[2]/div/div/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/vi/thanh-lap-cong-ty-tai-singapore",
      "Anchor text": "Thành Lập Công Ty Tại Singapore",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div[3]/div/div[2]/ul/li/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/vi/mo-tai-khoan-ngan-hang",
      "Anchor text": "Mở Tài Khoản Ngân Hàng Tại Singapore",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div[3]/div/div[2]/ul/li[2]/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/vi/thu-ky-cong-ty",
      "Anchor text": "Dịch Vụ Thư Ký Công Ty",
      "Alt text": "",
      "Follow": false,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div[3]/div/div[2]/ul/li[3]/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/vi/thi-thuc-viec-lam",
      "Anchor text": "Thị Thực/Visa",
      "Alt text": "",
      "Follow": false,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div[3]/div/div[2]/ul/li[4]/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/vi/ke-toan-va-thue",
      "Anchor text": "Kế Toán và Thuế",
      "Alt text": "",
      "Follow": false,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div[3]/div/div[2]/ul/li[5]/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/vi/tai-khoan-thanh-toan-truc-tuyen",
      "Anchor text": "Mở Tài Khoản Thanh Toán Trực Tuyến",
      "Alt text": "",
      "Follow": false,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div[3]/div/div[2]/ul/li[6]/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/vi/bai-viet/thanh-lap-cong-ty",
      "Anchor text": "Thành Lập Công Ty",
      "Alt text": "",
      "Follow": false,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div[4]/div/div[2]/ul/li/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/vi/bai-viet/ngan-hang",
      "Anchor text": "Ngân Hàng",
      "Alt text": "",
      "Follow": false,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div[4]/div/div[2]/ul/li[2]/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/vi/bai-viet/ke-toan-va-kiem-toan",
      "Anchor text": "Kế Toán và Kiểm Toán",
      "Alt text": "",
      "Follow": false,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div[4]/div/div[2]/ul/li[3]/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/vi/bai-viet/thue",
      "Anchor text": "Thuế",
      "Alt text": "",
      "Follow": false,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div[4]/div/div[2]/ul/li[4]/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/vi/bai-viet",
      "Anchor text": "Bài viết khác",
      "Alt text": "",
      "Follow": false,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div[4]/div/div[2]/ul/li[5]/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/vi/cong-cu-goi-y-ten-doanh-nghiep",
      "Anchor text": "Công Cụ Gợi Ý Tên Doanh Nghiệp",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div[4]/div[2]/div[2]/ul/li/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/vi/cong-cu-kiem-tra-ten-doanh-nghiep",
      "Anchor text": "Công Cụ Kiểm Tra Tên Doanh Nghiệp Dự Kiến",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div[4]/div[2]/div[2]/ul/li[2]/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/vi/kiem-tra-ma-ssic",
      "Anchor text": "Kiểm Tra Mã SSIC",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div[4]/div[2]/div[2]/ul/li[3]/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/vi/dieu-khoan-dich-vu",
      "Anchor text": "Điều Khoản Dịch Vụ",
      "Alt text": "",
      "Follow": false,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div[5]/div/div[2]/ul/li/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/vi/chinh-sach-hoan-tra",
      "Anchor text": "Chính Sách Hoàn Trả",
      "Alt text": "",
      "Follow": false,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div[5]/div/div[2]/ul/li[2]/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/vi/chinh-sach-bao-mat",
      "Anchor text": "Chính Sách Bảo Mật",
      "Alt text": "",
      "Follow": false,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div[5]/div/div[2]/ul/li[3]/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/vi/cac-quoc-gia-va-vung-lanh-tho-bi-han-che",
      "Anchor text": "Các Quốc Gia Và Vùng Lãnh Thổ Bị Hạn Chế",
      "Alt text": "",
      "Follow": false,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div[5]/div/div[2]/ul/li[4]/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/vi/huong-dan-thanh-toan",
      "Anchor text": "Hướng Dẫn Thanh Toán",
      "Alt text": "",
      "Follow": false,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div[5]/div/div[2]/ul/li[5]/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/vi/thoa-thuan-su-dung-dich-vu",
      "Anchor text": "Thỏa Thuận Sử Dụng Dịch Vụ",
      "Alt text": "",
      "Follow": false,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div[5]/div/div[2]/ul/li[6]/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/vi/cong-thanh-toan-truc-tuyen",
      "Anchor text": "Cổng Thanh Toán Trực Tuyến",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div[5]/div[2]/div[2]/ul/li/a",
      "Link position": "Footer"
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.com/sg",
      "Anchor text": "Global website",
      "Alt text": "",
      "Follow": false,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div[2]/div/div/a",
      "Link position": "Footer"
    }
  ],
  "image_details": [
    {
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/assets/images/common/logo-bbc.png",
      "Alt text": "logo-bbc",
      "Real Dimensions (WxH)": "",
      "Dimensions in Attributes (WxH)": "130x36",
      "Display Dimensions (WxH)": "",
      "Potential Savings": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a/figure/div/picture/img",
      "Link position": "Navigation"
    },
    {
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/assets/images/common/logo-bbc-black.png",
      "Alt text": "logo-bbc-black",
      "Real Dimensions (WxH)": "",
      "Dimensions in Attributes (WxH)": "130x36",
      "Display Dimensions (WxH)": "",
      "Potential Savings": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/a/figure/div[2]/picture/img",
      "Link position": "Navigation"
    },
    {
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/assets/images/layout/layout-call-phone.png",
      "Alt text": "call-telephone",
      "Real Dimensions (WxH)": "",
      "Dimensions in Attributes (WxH)": "30x30",
      "Display Dimensions (WxH)": "",
      "Potential Savings": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div/div/a/div/picture/img",
      "Link position": "Navigation"
    },
    {
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/assets/images/layout/layout-report.png",
      "Alt text": "report",
      "Real Dimensions (WxH)": "",
      "Dimensions in Attributes (WxH)": "200x155",
      "Display Dimensions (WxH)": "",
      "Potential Savings": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div[2]/div/ul/li/div[2]/div/div/div/div/div[3]/picture/img",
      "Link position": "Navigation"
    },
    {
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/assets/images/layout/layout-auditing.png",
      "Alt text": "auditing",
      "Real Dimensions (WxH)": "",
      "Dimensions in Attributes (WxH)": "184x170",
      "Display Dimensions (WxH)": "",
      "Potential Savings": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div[2]/div/ul/li/div[2]/div/div/div[2]/div/div[3]/picture/img",
      "Link position": "Navigation"
    },
    {
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/assets/images/layout/layout-business-solution.svg",
      "Alt text": "business-solution",
      "Real Dimensions (WxH)": "",
      "Dimensions in Attributes (WxH)": "150x150",
      "Display Dimensions (WxH)": "",
      "Potential Savings": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div[2]/div/ul/li/div[2]/div/div/div[3]/div/div[3]/img",
      "Link position": "Navigation"
    },
    {
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/assets/images/layout/layout-guide.svg",
      "Alt text": "guide",
      "Real Dimensions (WxH)": "",
      "Dimensions in Attributes (WxH)": "150x150",
      "Display Dimensions (WxH)": "",
      "Potential Savings": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div[2]/div/ul/li[2]/div[2]/div/div/div/div/div[3]/img",
      "Link position": "Navigation"
    },
    {
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/assets/images/layout/layout-news.svg",
      "Alt text": "news",
      "Real Dimensions (WxH)": "",
      "Dimensions in Attributes (WxH)": "150x150",
      "Display Dimensions (WxH)": "",
      "Potential Savings": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div[2]/div/ul/li[2]/div[2]/div/div/div[2]/div/div[3]/img",
      "Link position": "Navigation"
    },
    {
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/assets/images/layout/layout-tool.svg",
      "Alt text": "tool",
      "Real Dimensions (WxH)": "",
      "Dimensions in Attributes (WxH)": "150x150",
      "Display Dimensions (WxH)": "",
      "Potential Savings": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/header/nav/div/div/div[2]/div/ul/li[2]/div[2]/div/div/div[3]/div/div[3]/img",
      "Link position": "Navigation"
    },
    {
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/assets/images/banner/banner-homepage.png",
      "Alt text": "banner image",
      "Real Dimensions (WxH)": "",
      "Dimensions in Attributes (WxH)": "1920x576",
      "Display Dimensions (WxH)": "",
      "Potential Savings": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/div/section/div/div/picture/img",
      "Link position": "Content"
    },
    {
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/assets/images/homepage/service1-thanh-lap-cong-ty.png",
      "Alt text": "thanh-lap-cong-ty",
      "Real Dimensions (WxH)": "",
      "Dimensions in Attributes (WxH)": "445x445",
      "Display Dimensions (WxH)": "",
      "Potential Savings": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/div/section[2]/div[2]/div[2]/div/picture/img",
      "Link position": "Content"
    },
    {
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/assets/images/homepage/service2-ke-toan-va-thue.png",
      "Alt text": "ke-toan-va-thue",
      "Real Dimensions (WxH)": "",
      "Dimensions in Attributes (WxH)": "445x445",
      "Display Dimensions (WxH)": "",
      "Potential Savings": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/div/section[2]/div[3]/div[2]/div/picture/img",
      "Link position": "Content"
    },
    {
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/assets/images/homepage/service3-thu-ky-cong-ty.png",
      "Alt text": "thanh-lap-cong-ty",
      "Real Dimensions (WxH)": "",
      "Dimensions in Attributes (WxH)": "445x445",
      "Display Dimensions (WxH)": "",
      "Potential Savings": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/div/section[2]/div[4]/div[2]/div/picture/img",
      "Link position": "Content"
    },
    {
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/assets/images/homepage/gia-tri-ma-chung-toi-mang-lai-human.png",
      "Alt text": "placeholder",
      "Real Dimensions (WxH)": "",
      "Dimensions in Attributes (WxH)": "440x459",
      "Display Dimensions (WxH)": "",
      "Potential Savings": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/div/section[3]/div/div/div/div/picture/img",
      "Link position": "Content"
    },
    {
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/assets/icons/dollar.svg",
      "Alt text": "placeholder",
      "Real Dimensions (WxH)": "",
      "Dimensions in Attributes (WxH)": "NonexNone",
      "Display Dimensions (WxH)": "",
      "Potential Savings": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/div/section[3]/div/div/div[2]/div/div/div/div/div/img",
      "Link position": "Content"
    },
    {
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/assets/icons/flash.svg",
      "Alt text": "placeholder",
      "Real Dimensions (WxH)": "",
      "Dimensions in Attributes (WxH)": "NonexNone",
      "Display Dimensions (WxH)": "",
      "Potential Savings": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/div/section[3]/div/div/div[2]/div/div/div[2]/div/div/img",
      "Link position": "Content"
    },
    {
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/assets/icons/hand-human.svg",
      "Alt text": "placeholder",
      "Real Dimensions (WxH)": "",
      "Dimensions in Attributes (WxH)": "NonexNone",
      "Display Dimensions (WxH)": "",
      "Potential Savings": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/div/section[3]/div/div/div[2]/div/div/div[3]/div/div/img",
      "Link position": "Content"
    },
    {
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/assets/icons/services.svg",
      "Alt text": "placeholder",
      "Real Dimensions (WxH)": "",
      "Dimensions in Attributes (WxH)": "NonexNone",
      "Display Dimensions (WxH)": "",
      "Potential Savings": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/div/section[3]/div/div/div[2]/div/div/div[4]/div/div/img",
      "Link position": "Content"
    },
    {
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/assets/images/homepage/gia-tri-ma-chung-toi-mang-lai-bg.png",
      "Alt text": "placeholder",
      "Real Dimensions (WxH)": "",
      "Dimensions in Attributes (WxH)": "1040x777",
      "Display Dimensions (WxH)": "",
      "Potential Savings": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/div/section[3]/div[2]/picture/img",
      "Link position": "Content"
    },
    {
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/assets/images/homepage/happy-young-asian-businesswoman-working-with-laptop-computer-office.png",
      "Alt text": "placeholder",
      "Real Dimensions (WxH)": "",
      "Dimensions in Attributes (WxH)": "377x230",
      "Display Dimensions (WxH)": "",
      "Potential Savings": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/div/section[4]/div/div/div/div/div/div/div/div[2]/div/picture/img",
      "Link position": "Content"
    },
    {
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/assets/images/homepage/group-asian-business-people-posing-white-background.png",
      "Alt text": "placeholder",
      "Real Dimensions (WxH)": "",
      "Dimensions in Attributes (WxH)": "377x230",
      "Display Dimensions (WxH)": "",
      "Potential Savings": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/div/section[4]/div/div/div/div/div/div[2]/div/div[2]/div/picture/img",
      "Link position": "Content"
    },
    {
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/assets/images/homepage/cafef-logo.png",
      "Alt text": "placeholder",
      "Real Dimensions (WxH)": "",
      "Dimensions in Attributes (WxH)": "NonexNone",
      "Display Dimensions (WxH)": "",
      "Potential Savings": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/div/div/div/div/div/div/div/img",
      "Link position": "Content"
    },
    {
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/assets/images/homepage/dan-tri-logo.png",
      "Alt text": "placeholder",
      "Real Dimensions (WxH)": "",
      "Dimensions in Attributes (WxH)": "NonexNone",
      "Display Dimensions (WxH)": "",
      "Potential Savings": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/div/div/div/div/div/div/div[2]/img",
      "Link position": "Content"
    },
    {
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/assets/images/homepage/vnexpress-logo.png",
      "Alt text": "placeholder",
      "Real Dimensions (WxH)": "",
      "Dimensions in Attributes (WxH)": "NonexNone",
      "Display Dimensions (WxH)": "",
      "Potential Savings": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/main/div/div/div/div/div/div/div[3]/img",
      "Link position": "Content"
    },
    {
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/assets/images/layout/layout-logo-footer.png",
      "Alt text": "logo-footer",
      "Real Dimensions (WxH)": "",
      "Dimensions in Attributes (WxH)": "56x56",
      "Display Dimensions (WxH)": "",
      "Potential Savings": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div/div/a/div/picture/img",
      "Link position": "Footer"
    },
    {
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/assets/images/layout/layout-buiding.png",
      "Alt text": "building",
      "Real Dimensions (WxH)": "",
      "Dimensions in Attributes (WxH)": "63x93",
      "Display Dimensions (WxH)": "",
      "Potential Savings": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div[2]/ul/li/a/div/picture/img",
      "Link position": "Footer"
    },
    {
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/assets/images/layout/layout-email.png",
      "Alt text": "building",
      "Real Dimensions (WxH)": "",
      "Dimensions in Attributes (WxH)": "133x91",
      "Display Dimensions (WxH)": "",
      "Potential Savings": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div[2]/ul/li[2]/a/div/picture/img",
      "Link position": "Footer"
    },
    {
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/assets/images/layout/layout-call.png",
      "Alt text": "building",
      "Real Dimensions (WxH)": "",
      "Dimensions in Attributes (WxH)": "126x130",
      "Display Dimensions (WxH)": "",
      "Potential Savings": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div[2]/ul/li[3]/a/div/picture/img",
      "Link position": "Footer"
    },
    {
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/assets/images/layout/layout-buiding.png",
      "Alt text": "building",
      "Real Dimensions (WxH)": "",
      "Dimensions in Attributes (WxH)": "63x93",
      "Display Dimensions (WxH)": "",
      "Potential Savings": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div[2]/ul[2]/li/a/div/picture/img",
      "Link position": "Footer"
    },
    {
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/assets/images/layout/layout-call.png",
      "Alt text": "building",
      "Real Dimensions (WxH)": "",
      "Dimensions in Attributes (WxH)": "126x130",
      "Display Dimensions (WxH)": "",
      "Potential Savings": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div[2]/ul[2]/li[2]/a/div/picture/img",
      "Link position": "Footer"
    },
    {
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/assets/images/layout/layout-dcma.png",
      "Alt text": "img-dcma",
      "Real Dimensions (WxH)": "",
      "Dimensions in Attributes (WxH)": "162x81",
      "Display Dimensions (WxH)": "",
      "Potential Savings": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div[2]/div/div/a/div/picture/img",
      "Link position": "Footer"
    },
    {
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/assets/images/layout/layout-stripe.png",
      "Alt text": "stripe",
      "Real Dimensions (WxH)": "",
      "Dimensions in Attributes (WxH)": "58x40",
      "Display Dimensions (WxH)": "",
      "Potential Savings": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div[2]/div/div[2]/div/picture/img",
      "Link position": "Footer"
    },
    {
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/assets/images/layout/layout-visa.png",
      "Alt text": "visa",
      "Real Dimensions (WxH)": "",
      "Dimensions in Attributes (WxH)": "57x39",
      "Display Dimensions (WxH)": "",
      "Potential Savings": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div[2]/div/div[2]/div[2]/picture/img",
      "Link position": "Footer"
    },
    {
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/assets/images/layout/layout-mastercard.png",
      "Alt text": "mastercard",
      "Real Dimensions (WxH)": "",
      "Dimensions in Attributes (WxH)": "57x39",
      "Display Dimensions (WxH)": "",
      "Potential Savings": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div[2]/div/div[3]/div/picture/img",
      "Link position": "Footer"
    },
    {
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/assets/images/layout/layout-american.png",
      "Alt text": "american",
      "Real Dimensions (WxH)": "",
      "Dimensions in Attributes (WxH)": "64x39",
      "Display Dimensions (WxH)": "",
      "Potential Savings": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div[2]/div/div[3]/div[2]/picture/img",
      "Link position": "Footer"
    },
    {
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/assets/images/layout/layout-mastro.png",
      "Alt text": "mastro",
      "Real Dimensions (WxH)": "",
      "Dimensions in Attributes (WxH)": "66x39",
      "Display Dimensions (WxH)": "",
      "Potential Savings": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div[2]/div/div[4]/div/picture/img",
      "Link position": "Footer"
    },
    {
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/assets/images/layout/layout-dcma.png",
      "Alt text": "img-dcma",
      "Real Dimensions (WxH)": "",
      "Dimensions in Attributes (WxH)": "162x81",
      "Display Dimensions (WxH)": "",
      "Potential Savings": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div[5]/div[3]/div/div/picture/img",
      "Link position": "Footer"
    },
    {
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/assets/images/layout/layout-stripe.png",
      "Alt text": "stripe",
      "Real Dimensions (WxH)": "",
      "Dimensions in Attributes (WxH)": "58x40",
      "Display Dimensions (WxH)": "",
      "Potential Savings": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div[5]/div[3]/div[2]/div/picture/img",
      "Link position": "Footer"
    },
    {
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/assets/images/layout/layout-visa.png",
      "Alt text": "visa",
      "Real Dimensions (WxH)": "",
      "Dimensions in Attributes (WxH)": "57x39",
      "Display Dimensions (WxH)": "",
      "Potential Savings": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div[5]/div[3]/div[2]/div[2]/picture/img",
      "Link position": "Footer"
    },
    {
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/assets/images/layout/layout-mastercard.png",
      "Alt text": "mastercard",
      "Real Dimensions (WxH)": "",
      "Dimensions in Attributes (WxH)": "57x39",
      "Display Dimensions (WxH)": "",
      "Potential Savings": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div[5]/div[3]/div[2]/div[3]/picture/img",
      "Link position": "Footer"
    },
    {
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/assets/images/layout/layout-american.png",
      "Alt text": "american",
      "Real Dimensions (WxH)": "",
      "Dimensions in Attributes (WxH)": "64x39",
      "Display Dimensions (WxH)": "",
      "Potential Savings": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div[5]/div[3]/div[2]/div[4]/picture/img",
      "Link position": "Footer"
    },
    {
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/assets/images/layout/layout-mastro.png",
      "Alt text": "mastro",
      "Real Dimensions (WxH)": "",
      "Dimensions in Attributes (WxH)": "66x39",
      "Display Dimensions (WxH)": "",
      "Potential Savings": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/body/div[3]/div/footer/div/div/div[5]/div[3]/div[2]/div[5]/picture/img",
      "Link position": "Footer"
    }
  ],
  "resources": [
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/_next/static/css/2d5339368b8359f9.css",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/head/link",
      "Link position": ""
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/_next/static/css/7ebac14d71729b28.css",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/head/link",
      "Link position": ""
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/_next/static/chunks/polyfills-c67a75d1b6f99dc8.js",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/head/script",
      "Link position": ""
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/_next/static/chunks/webpack-01db7ca11dc59b72.js",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/head/script",
      "Link position": ""
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/_next/static/chunks/framework-8e69f8bb355563b7.js",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/head/script",
      "Link position": ""
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/_next/static/chunks/main-66adfdbb42943ca0.js",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/head/script",
      "Link position": ""
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/_next/static/chunks/pages/_app-2cbc80dfd0b83bca.js",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/head/script",
      "Link position": ""
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/_next/static/chunks/2580-9a4e5f30cd0934eb.js",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/head/script",
      "Link position": ""
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/_next/static/chunks/7453-8cfaca8e3d4df7ac.js",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/head/script",
      "Link position": ""
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/_next/static/chunks/2807-7243375e3b71f7b0.js",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/head/script",
      "Link position": ""
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/_next/static/chunks/7536-e8558f430891afac.js",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/head/script",
      "Link position": ""
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/_next/static/chunks/1216-1c66bff6adc5f7b8.js",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/head/script",
      "Link position": ""
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/_next/static/chunks/4505-20ffd1c2fbb7690f.js",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/head/script",
      "Link position": ""
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/_next/static/chunks/3892-eab26011f6a6ae71.js",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/head/script",
      "Link position": ""
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/_next/static/chunks/pages/vi-dddef4ff7e0c224f.js",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/head/script",
      "Link position": ""
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/_next/static/9wUAsWJKFRYUMY2_BCbvs/_buildManifest.js",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/head/script",
      "Link position": ""
    },
    {
      "Type": "Hyperlink",
      "From": "https://bbcincorp.sg/vi",
      "To": "https://bbcincorp.sg/_next/static/9wUAsWJKFRYUMY2_BCbvs/_ssgManifest.js",
      "Anchor text": "",
      "Alt text": "",
      "Follow": true,
      "Target": "",
      "Rel": "",
      "Status code": "",
      "Path type": "Relative",
      "Link path": "/html/head/script",
      "Link position": ""
    }
  ],
  "structured_data": {
    "microdata": [],
    "json-ld": [
        {
            "@context": "https://schema.org",
            "@graph": [
                {
                    "@type": [
                        "Organization",
                        "Person"
                    ],
                    "@id": "https://gleads.vn/vi/#person",
                    "name": "Gleads",
                    "url": "https://gleads.vn/vi"
                },
                {
                    "@type": "WebSite",
                    "@id": "https://gleads.vn/vi/#website",
                    "url": "https://gleads.vn/vi",
                    "name": "Gleads",
                    "publisher": {
                        "@id": "https://gleads.vn/vi/#person"
                    },
                    "inLanguage": "vi"
                },
                {
                    "@type": "BreadcrumbList",
                    "@id": "https://gleads.vn/vi/dich-vu-quang-cao-google#breadcrumb",
                    "itemListElement": [
                        {
                            "@type": "ListItem",
                            "position": "1",
                            "item": {
                                "@id": "https://gleads.vn/vi",
                                "name": "Home"
                            }
                        },
                        {
                            "@type": "ListItem",
                            "position": "2",
                            "item": {
                                "@id": "https://gleads.vn/vi/dich-vu-quang-cao-google",
                                "name": "Dịch vụ quảng cáo Google Ads tăng Chuyển Đổi cho doanh nghiệp"
                            }
                        }
                    ]
                },
                {
                    "@type": "WebPage",
                    "@id": "https://gleads.vn/vi/dich-vu-quang-cao-google#webpage",
                    "url": "https://gleads.vn/vi/dich-vu-quang-cao-google",
                    "name": "Dịch vụ quảng cáo Google Ads tăng Chuyển Đổi cho doanh nghiệp | Gleads",
                    "datePublished": "2022-08-12T04:44:58+07:00",
                    "dateModified": "2023-01-17T11:19:35+07:00",
                    "isPartOf": {
                        "@id": "https://gleads.vn/vi/#website"
                    },
                    "inLanguage": "vi",
                    "breadcrumb": {
                        "@id": "https://gleads.vn/vi/dich-vu-quang-cao-google#breadcrumb"
                    }
                },
                {
                    "@type": "Person",
                    "@id": "https://gleads.vn/vi/author/long-legleads-vn",
                    "name": "Lê Trần Đăng Long",
                    "url": "https://gleads.vn/vi/author/long-legleads-vn",
                    "image": {
                        "@type": "ImageObject",
                        "@id": "https://secure.gravatar.com/avatar/73f091377c37b0cfe6ed9515cc45b019?s=96&amp;d=mm&amp;r=g",
                        "url": "https://secure.gravatar.com/avatar/73f091377c37b0cfe6ed9515cc45b019?s=96&amp;d=mm&amp;r=g",
                        "caption": "Lê Trần Đăng Long",
                        "inLanguage": "vi"
                    }
                },
                {
                    "@type": "Article",
                    "headline": "Dịch vụ quảng cáo Google Ads tăng Chuyển Đổi cho doanh nghiệp | Gleads",
                    "keywords": "Dịch vụ quảng cáo google",
                    "datePublished": "2022-08-12T04:44:58+07:00",
                    "dateModified": "2023-01-17T11:19:35+07:00",
                    "author": {
                        "@id": "https://gleads.vn/vi/author/long-legleads-vn",
                        "name": "Lê Trần Đăng Long"
                    },
                    "publisher": {
                        "@id": "https://gleads.vn/vi/#person"
                    },
                    "description": "Dịch vụ quảng cáo Google Ads của Gleads giúp tăng chuyển đổi cho doanh nghiệp nhờ vào những kỹ thuật nhấm mục tiêu chuyên sâu, chính xác cao.",
                    "name": "Dịch vụ quảng cáo Google Ads tăng Chuyển Đổi cho doanh nghiệp | Gleads",
                    "@id": "https://gleads.vn/vi/dich-vu-quang-cao-google#richSnippet",
                    "isPartOf": {
                        "@id": "https://gleads.vn/vi/dich-vu-quang-cao-google#webpage"
                    },
                    "inLanguage": "vi",
                    "mainEntityOfPage": {
                        "@id": "https://gleads.vn/vi/dich-vu-quang-cao-google#webpage"
                    }
                }
            ]
        }
    ],
    "rdfa": [
        {
            "@id": "",
            "https://ogp.me/ns#description": [
                {
                    "@value": "Dịch vụ quảng cáo Google Ads của Gleads giúp tăng chuyển đổi cho doanh nghiệp nhờ vào những kỹ thuật nhấm mục tiêu chuyên sâu, chính xác cao."
                }
            ],
            "https://ogp.me/ns#locale": [
                {
                    "@value": "vi_VN"
                }
            ],
            "https://ogp.me/ns#site_name": [
                {
                    "@value": "Gleads"
                }
            ],
            "https://ogp.me/ns#title": [
                {
                    "@value": "Dịch vụ quảng cáo Google Ads tăng Chuyển Đổi cho doanh nghiệp | Gleads"
                }
            ],
            "https://ogp.me/ns#type": [
                {
                    "@value": "article"
                }
            ],
            "https://ogp.me/ns#updated_time": [
                {
                    "@value": "2023-01-17T11:19:35+07:00"
                }
            ],
            "https://ogp.me/ns#url": [
                {
                    "@value": "https://gleads.vn/vi/dich-vu-quang-cao-google"
                }
            ],
            "https://ogp.me/ns/article#modified_time": [
                {
                    "@value": "2023-01-17T11:19:35+07:00"
                }
            ],
            "https://ogp.me/ns/article#published_time": [
                {
                    "@value": "2022-08-12T04:44:58+07:00"
                }
            ]
        }
    ]
}
}

const App = () => {
  const [loading, setLoading] = useState(false);
  const token = getAuthToken();
  const [sort, setSort] = useState('');
  const [open, setOpen] = useState([]);
  const [ activeTab, setActiveTab] = useState('');

  const [filterArr, setFilterArr] = useState([{
      id: uuid4(),heading: 'URL', operation: 'contains', value: ''
  }]);
  const [filteredIssueURL, setFilteredIssueURL] = useState( data || []);

  const renderNestedObject = (tier, data) => {
    // console.log(data);
    const d = Object.entries(data).map(([key, value]) => 
              typeof value === 'object' && !Array.isArray(value) && typeof value !== null ?
                <>
                  <tr className={`${tier === -1 && key === '@type' && 'table-light'}`}>
                    <td colspan="2"
                      onClick={(e) => {e.preventDefault();
                        console.log(open);
                        if (!open.includes(key)){
                            setOpen(c => [...c, key]);
                        } else{
                            let d = open.filter(item => item !== key);
                            setOpen(d);
                        }  
                    }}
                    role='button'
                    style={{paddingLeft: `${(tier+1)*20}px`}}
                    >
                      <FontAwesomeIcon icon={open.includes(key) ? faCaretDown : faCaretRight} className='me-2' />
                      {key}
                    </td>
                  </tr>
                  {/* <Collapse in={open.includes(key) ? true : false}> */}
                    <>
                    {open.includes(key) &&
                    renderNestedObject(tier + 1, value)}
                    </>
                  {/* </Collapse> */}
                </> : Array.isArray(value) ?
                <>
                {
                  value.map(v => 
                    typeof v === 'object' && !Array.isArray(v) && typeof v !== null ?
                    <>
                    <tr className={`${tier === -1 && key === '@type' && 'table-light'}`}>
                      <td colspan="2"
                        onClick={(e) => {e.preventDefault();
                          if (!open.includes(key)){
                              setOpen(c => [...c, key]);
                          } else{
                              let d = open.filter(item => item !== key);
                              setOpen(d);
                          }  
                      }}
                      role='button'
                      style={{paddingLeft: `${(tier+1)*20}px`}}
                      >
                        <FontAwesomeIcon icon={open.includes(key) ? faCaretDown : faCaretRight} className='me-2' />
                        {key}</td>
                    </tr>
                    {/* <Collapse in={open.includes(key) ? true : false}> */}
                      {open.includes(key) &&
                      renderNestedObject(tier + 1, v)}
                    {/* </Collapse> */}
                    </> :
                    <tr className={`${tier === -1 && key === '@type' && 'table-light'}`}>
                      <td style={{paddingLeft: `${(tier+1)*20}px`}}>{key}</td>
                      <td>{v}</td>
                    </tr>
                    )
                }
                </> :
                <tr className={`${tier === -1 && key === '@type' && 'table-light'}`}>
                  <td style={{paddingLeft: `${(tier+1)*20}px`}}>{key}</td>
                  <td>{value}</td>
                </tr>
            )
    // console.log(d)
    return d
  };
  const renderArr = (tier, data) => {
    // console.log(data);
    const d = data.map(item => 
          <>
          {renderNestedObject(tier, item)}
          </>
      );
    // console.log(d);
    return d
  }

  const renderElem = (tier, data) => {
    if (typeof data === 'object' && !Array.isArray(data) && typeof data !== null){
      return (
        <>
          {Object.entries(data).map(([key, value]) => 
          <>
            <tr className={`${tier === -1 && key === '@type' && 'table-light'}`}>
              <td colspan="2">{key}</td>
            </tr>
            {renderNestedObject(tier, value)}
          </>
          )}
        </>
      )
    } else if (Array.isArray(data)){
      // console.log(data);
      return renderArr(tier, data)
    }
  };

  const sortHandler = (tab, heading) => {
    // console.log(issueURL);
    if (sort !== `${heading}_inc` && sort !== `${heading}_dec`){
    setSort(`${heading}_inc`);
    filteredIssueURL[tab].sort((a,b) => (b[heading] > a[heading]) ? -1 : (b[heading] < a[heading]) ? 1 : 0);
    } else if(sort === `${heading}_dec`){
    setSort('keyword_inc');
    filteredIssueURL[tab].sort((a,b) => (b[heading] > a[heading]) ? -1 : (b[heading] < a[heading]) ? 1 : 0);
    } else if(sort === `${heading}_inc`){
    setSort(`${heading}_dec`);
    filteredIssueURL[tab].sort((a,b) => (b[heading] > a[heading]) ? 1 : (b[heading] < a[heading]) ? -1 : 0);
    };
  };

  const incFilterHandler = (filterID) => {
    if(filterArr.length === 3 ) return;
    setFilterArr( c => {
        const d = [...c];
        d.push({id: filterID ,heading: 'URL', operation: 'contains', value: ''});
        return d
    });
    console.log(filterArr);
  };
  const updateFilterHandler = (filterID, type, value) => {
      const newArr = filterArr.filter(item => {
          if(item.id === filterID){
              const d = item;
              item[type] = value;
              return d
          }else {
              return item
          }
      });
      setFilterArr(newArr);
      // console.log(filterArr);
  };
  const applyFilterHandler = () => {
    //filterArr[{id, heading, operation, value}]
    console.log('re render');
    const arr = data[activeTab].filter(item => {
        let count = 0;
        filterArr.map(condition => {
            if (condition.operation === 'contains'){
                count += eval(`"${item[condition.heading]}".includes("${condition.value}")`)
            }else if (condition.operation === 'does not contain'){
                count += eval(`!"${item[condition.heading]}".includes("${condition.value}")`)
            }else if (condition.operation === 'is'){
                // console.log(`"${item[condition.heading]}" === "${condition.value}"`);
                count += eval(`"${item[condition.heading]}" === "${condition.value}"`)
            }else if (condition.operation === 'is not'){
                console.log(`"${item[condition.heading]}" !== "${condition.value}"`);
                count += eval(`"${item[condition.heading]}" !== "${condition.value}"`)
            }else if(condition.operation === 'greater than') {
                count += eval(`"${item[condition.heading]}" > "${condition.value}"`)
            }else if (condition.operation === 'greater than or equal'){
                count += eval(`"${item[condition.heading]}" >= "${condition.value}"`)
            }else if (condition.operation === 'less than'){
                count += eval(`"${item[condition.heading]}" < "${condition.value}"`)
            }else if (condition.operation === 'less than or equal'){
                count += eval(`"${item[condition.heading]}" <= "${condition.value}"`)
            } 
        })
        return count === filterArr.length
    });
    setFilteredIssueURL(c => {
      const d = {...c};
      d[activeTab] = arr;
      return d
      });
    console.log(filteredIssueURL)
  };
  const deleteFilterHandler = (filterID) => {
    let arr = []
    if (filterArr.length === 1){
      arr = [{
        id: uuid4(),heading: 'URL', operation: 'contains', value: ''
    }]
    } else {
      arr = filterArr.filter(item => item.id !== filterID)
    }
    setFilterArr(arr);
    console.log(filterArr);
  }

  const urlFilter = (
      <Popover id="popover-positioned-top" title="Popover bottom" style={{"max-width":"500px", 'border': 'none'}}>
          <div className='d-flex flex-column p-4 bg-white shadow'>
              <p>Filter URLs</p>
              {
                  filterArr.map(item => {
                      // console.log(item);
                      return (
                          <div className='d-flex align-items-center' key={item}>
                              where
                              <select class="form-select form-select-sm" aria-label="Default select example" className='ms-3 me-2' style={{maxWidth: '100px'}}
                                  onChange={e => updateFilterHandler(item.id, 'heading', e.target.value)}
                              >
                                  <option value='URL' selected={item.heading === '' ? true : item.heading === 'URL' ? true : false}>Select Option</option>
                                  { !['url_detail', 'structured_data', ''].includes(activeTab) &&
                                      Object.keys(data[activeTab][0]).map(heading =>{
                                          // console.log(data[activeTab]);
                                          if (heading !== 'URL') {
                                              return (<option value={heading} selected={item['heading'] === heading}>{heading}</option>)
                                          } 
                                      })
                                  }
                              </select>
                              <select class="form-select form-select-sm" aria-label="Default select example" className='me-2'
                                  onChange={e => updateFilterHandler(item.id, 'operation', e.target.value)}
                              >
                                  <option value='contains' selected={item.operation === '' ? true : item.operation === 'contains' ? true : false}>contains</option>
                                  <option value="does not contain" selected={item.operation === 'does not contain'}>does not contain</option>
                                  <option value="is" selected={item.operation === 'is'}>is</option>
                                  <option value="is not" selected={item.operation === 'is not'}>is not</option>
                                  <option value="greater than" selected={item.operation === 'greater than'}>greater than</option>
                                  <option value="greater than or equal" selected={item.operation === 'greater than or equal'}>greater than or equal</option>
                                  <option value="less than" selected={item.operation === 'less than'}>less than</option>
                                  <option value="less than or equal" selected={item.operation === 'less than or equal'}>less than or equal</option>
                              </select>

                              <input type="text" className="form-control me-2" style={{width: '120px'}} placeholder="Enter a value" aria-label="Enter a value" aria-describedby="basic-addon2" 
                                  defaultValue={item.value}
                                  onChange={e => updateFilterHandler(item.id, 'value', e.target.value)}
                              />
                              <button className='btn'
                                  onClick={e => {return}}
                              ><FontAwesomeIcon icon={faTrash} onClick={e => deleteFilterHandler(item.id)}/></button>
                          </div>
                      )
                  })
              }
              <div className='d-flex mt-4'>
                  <button type="button" class={`btn btn-light me-auto ${filterArr.length === 3 && 'disabled'}`} onClick={e => incFilterHandler(uuid4())}>+ Add condition</button>
                  <button type="button" class="btn btn-primary" onClick={applyFilterHandler}>Apply</button>
              </div>
              
          </div>
      </Popover>
  );

  function handleSelect(tab){
    setActiveTab(tab);
    console.log(tab)
  }

  return (
    <div>
      <div className='d-flex mb-4 mt-2'>
          <OverlayTrigger trigger="click" placement="bottom" rootClose overlay={urlFilter} container={this} containerPadding={20}>
              <button type="button" class="btn btn-light">
                  <FontAwesomeIcon className='me-2' icon={faFilter} />
                  Filter URLs
              </button>
          </OverlayTrigger>
      </div>
      <div className='w-100'>
        <Tabs
          defaultActiveKey="url_detail"
          id="fill-tab-example"
          className="mb-3"
          fill
          onSelect={e => handleSelect(e)}
        >
          {
            Object.keys(filteredIssueURL).map(tab => {
              // console.log(tab);
              return (
                <Tab eventKey={tab} title={tab} className='w-100 overflow-auto'>
                  {
                    tab === 'url_detail' ? 
                    <table class="table">
                      <thead>
                        <tr className='table-secondary'>
                          <th scope="col">Name</th>
                          <th scope="col">Value</th>
                        </tr>
                      </thead>
                      <tbody>
                          {
                            Object.keys(filteredIssueURL[tab]).map(heading => {
                              return (
                                <tr>
                                  <td>{heading}</td>
                                  <td>{filteredIssueURL[tab][heading]}</td>
                                </tr>
                              )
                            })
                          }
                      </tbody>
                    </table> : tab === 'structured_data' ?
                      <table class="table">
                        <thead>
                        </thead>
                        <tbody>
                            {
                              Object.keys(filteredIssueURL[tab]).map(type => 
                                 type === 'json-ld' ?
                                  <>
                                  {Object.entries(filteredIssueURL[tab][type][0]).map(([heading, value]) => {
                                    // console.log(data[tab][type][0]);
                                    if(heading !== '@context'){
                                        return (
                                          <Accordion defaultActiveKey={Array.from({length: 20}, (v, i) => i)}>
                                                  {
                                                    value.map(schema_type => 
                                                          <Accordion.Item eventKey={schema_type['@type']} >
                                                              <Accordion.Header><h5>{schema_type['@type']}</h5></Accordion.Header>
                                                              <Accordion.Body>
                                                                <>
                                                                  <tr className='table-secondary'>
                                                                    <th scope="col" className='w-50'>Name</th>
                                                                    <th scope="col">Value</th>
                                                                  </tr>
                                                                  {renderElem(-1, value)}
                                                                </>
                                                              </Accordion.Body>
                                                          </Accordion.Item>
                                                      )
                                                } 
                                        </Accordion>
                                        )
                                      // if (!Array.isArray(value) && typeof value !== 'object'){
                                      //   return (
                                      //     <tr>
                                      //       <td>{heading}</td>
                                      //       <td>{value}</td>
                                      //     </tr>
                                      //   )
                                      // } else {
                                      //   return renderElem(-1, value)
                                      // }
                                    }
                                  })}
                                  </> :
                                  null
                              )
                            }
                        </tbody>
                      </table> :
                      <table class="table">
                        <thead>
                          <tr className='table-secondary'>
                            {
                              Object.keys(filteredIssueURL[tab][0]).map(heading => {
                                return (
                                  <td scope="col">
                                    <button class="btn" type="button" aria-expanded="false" onClick={event => sortHandler(tab, heading)}>
                                        <span className='me-2 fw-bold'>{heading}</span>
                                        <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === `${heading}_inc` ? '#000' : '#6c757d'}`}}/>
                                        <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === `${heading}_dec` ? '#000' : '#6c757d'}`}}/>
                                    </button>
                                  </td>
                                )
                              })
                            }
                          </tr>
                        </thead>
                        <tbody>
                            {
                              filteredIssueURL[tab].map(row => {
                                return (
                                  <tr>
                                    {
                                      Object.keys(row).map(heading => {
                                        return (
                                          <td>{row[heading]}</td>
                                        )
                                      })
                                    }
                                  </tr>
                                )
                              })
                            }
                        </tbody>
                      </table>
                  }
                </Tab>
              )
            })
          }
        </Tabs>
      </div>
    </div>
  );
};

export default App;