import { useState, Fragment, useRef, useEffect } from "react";
import {showLoading, hideLoading } from 'react-redux-loading-bar';
import { useDispatch, useSelector } from "react-redux";

import "bootstrap/dist/css/bootstrap.min.css";
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faDownload,
} from "@fortawesome/free-solid-svg-icons";

import { Form, redirect, useActionData, useNavigation, useNavigate, useLocation, useSearchParams } from "react-router-dom";

function Login() {

    const location = useLocation();
    const data = useActionData();
    const navigation = useNavigation();
    const navigate = useNavigate();


    const username = useRef('');
    const password = useRef('');
    const [validation, setValidation] = useState(true);
    const [searchParams, setSearchParams] = useSearchParams();
    const [registered, setRegistered] = useState(true);

    
    const isSubmitting = navigation.state === 'submitting';
    const loginGoogleHandler = () => {
        window.location.href = `https://accounts.google.com/o/oauth2/auth?response_type=code&client_id=${process.env.REACT_APP_GOOGLE_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_GOOGLE_REDIRECT_URI}&scope=openid%20profile%20email&access_type=offline`
    };

    useEffect(() => {
        if (searchParams.get('code')){
            console.log(searchParams.get('code'));
            let c = searchParams.get('code');
            fetch(`${process.env.REACT_APP_HOST_IP}/api/callback?code=${c}`,{
            }).then(
                response => {
                    if (response.status === 401){
                    return null
                    }
                    return response.json();
                }
            ).then (
                data =>{ 
                    if (data !== null){
                        const token = data.token;
                        console.log(data);
                        localStorage.setItem('token',token);
                        localStorage.setItem('username', data.username);

                        const expiration = new Date();
                        expiration.setHours(expiration.getHours() + 1);
                        localStorage.setItem('expiration', expiration.toISOString());
                        window.location.href = `${process.env.REACT_APP_HOST_IP}/traffic-analytic`
                    } else {
                        setRegistered(false);
                        navigate('/login');
                    }
                    
                })
            }
    }, [location])



    return (
        <div className="d-flex flex-column h-100">
            <div className='w-50 align-self-center p-5 shadow' style={{marginTop:'150px', maxWidth: '500px'}}>
                <img src="logo-gleads.svg" alt="" width="105" height="48" class="d-inline-block align-text-top" />
                <h1 className="mt-5 mb-4">Welcome back!</h1>
                {
                    data && data.status_code === 401 &&
                    <h5 className='mb-2' style={{'color':'red'}}>Incorrect username or password!!!</h5>
                }
                {
                    !registered &&
                    <h5 className='mb-2' style={{'color':'red'}}>Contact Admin to register your account!!!</h5>
                }
                <div>
                    <Form method='post' className='needs-validation' id='login-form'
                    >
                        <div class="mb-3">
                            <label for="username" class="form-label">Username</label>
                            <input type="text" class="form-control" id="username" name='username' aria-describedby="emailHelp" required ref={username}/>
                            <div className="invalid-feedback">
                                Please provide a valid username.
                            </div>
                        </div>
                        <div class="mb-3">
                            <label for="password" class="form-label">Password</label>
                            <input type="password" class="form-control" id="password" name='password' required ref={password}/>
                            <div class="invalid-feedback">
                                Please provide a valid password.
                            </div>
                        </div>
                        <button type="submit" class="btn btn-primary w-100 rounded-3" disabled={isSubmitting}>Submit</button>
                    </Form>
                    <button class="gsi-material-button mt-3 w-100 align-items-center" onClick={loginGoogleHandler}>
                        <div class="gsi-material-button-state"></div>
                        <div class="gsi-material-button-content-wrapper">
                            <div class="gsi-material-button-icon">
                            <svg version="1.1" xmlns="https://www.w3.org/2000/svg" viewBox="0 0 48 48" style={{display: 'block'}}>
                                <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
                                <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
                                <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
                                <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
                                <path fill="none" d="M0 0h48v48H0z"></path>
                            </svg>
                            </div>
                            <span class="gsi-material-button-contents">Sign in with Google</span>
                            <span style={{display: 'none'}}>Sign in with Google</span>
                        </div>
                    </button> 
                </div>
            </div>
        </div>
    )
};

export default Login;

export async function action({request}) {
    const data = await request.formData();
    const response = await fetch(`${process.env.REACT_APP_HOST_IP}/api/token`, {
            method: 'POST',
            body: data,
        })
    
    const respBody = await response.json();
    if (respBody.status_code === 401){
        return respBody
    };

    const token = respBody.token;
    console.log(respBody);
    localStorage.setItem('token',token);
    localStorage.setItem('username', respBody.username);

    const expiration = new Date();
    expiration.setHours(expiration.getHours() + 1);
    localStorage.setItem('expiration', expiration.toISOString());
    return redirect('/')
}