import { useState, Fragment, useRef, useEffect } from "react";
import {showLoading, hideLoading } from 'react-redux-loading-bar';
import { useDispatch } from "react-redux";
import { OverlayTrigger, Popover } from 'react-bootstrap';

import "bootstrap/dist/css/bootstrap.min.css";
// import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import * as bootstrap from 'bootstrap/dist/js/bootstrap';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCircle,
    faDownload, faEye, faHeadset, faPaperPlane
} from "@fortawesome/free-solid-svg-icons";

import {
    ResponsiveContainer,
    Tooltip,
    PieChart, Pie, Cell,
    AreaChart,
    CartesianGrid,
    XAxis,
    YAxis,
    Legend,
    Area,
    BarChart,
    Bar,
    Line,
    ComposedChart
  } from "recharts";

import { getAuthToken } from "../util/token";
import DatePicker from "../components/datepick";
import RadarChart from '../components/radar_chart';
import moment from "moment";

const listGrowth = [
    {
        "date": "1709226000000",
        "sent": 106
    },
    {
        "date": "1711904400000",
        "sent": 144
    },
    {
        "date": "1714496400000",
        "sent": 127
    },
    {
        "date": "1717174800000",
        "sent": 89
    }
];

const TawkTo = () => {
    const token = getAuthToken();
    const userName = localStorage.getItem('username');

    const dispatch = useDispatch();
    const [agentList, setAgentList] = useState([
        'Alex Pei',
        'Alva Ng',
        'Amber',
        'Cecilia Ng',
        'Emma',
        'Evelyn Miller',
        'Ginny',
        'Grace Chen',
        'Jane Huang',
        'Jennifer Joan',
        'Kuntal Sahu',
        'Laura Huang',
        'Linda',
        'Lucy Lee',
        'Milly Ng',
        'Naomi Ng',
        'Rachel',
        'Sky',
        'Stella Wo',
        'Suri Allen',
        'Tiana Fan',
        
    ]);
    const [filteredAgentList, setFilteredAgentList] = useState([
        'Alex Pei',
        'Alva Ng',
        'Amber',
        'Cecilia Ng',
        'Emma',
        'Evelyn Miller',
        'Ginny',
        'Grace Chen',
        'Jane Huang',
        'Jennifer Joan',
        'Kuntal Sahu',
        'Laura Huang',
        'Linda',
        'Lucy Lee',
        'Milly Ng',
        'Naomi Ng',
        'Rachel',
        'Sky',
        'Stella Wo',
        'Suri Allen',
        'Tiana Fan',
    ]);
    const [criteriaList, setCriteriaList] = useState([
        {name: 'Helpfulness', value: 1},
        {name: 'Friendly & polite', value: 2},
        {name: 'Clear & detailed', value: 3},
        {name: 'Sentiment', value: 4},
        {name: 'Next-step action', value: 5},
        {name: 'Conversation intent', value: 6},
    ]);
    const [criterion, setCriterion] = useState('');
    const [conversationType, setConversationType] = useState('Informational');
    const [agentName, setAgentName] = useState('Agent Name');
    const [startDate, setStartDate] = useState((new Date()).getTime());
    const [endDate, setEndDate] = useState((new Date()).getTime());
    const [operation, setOperation] = useState('');
    const operationValue = useRef('');
    const [tawkChat, setTawkChat] = useState([]);
    const [conv, setConv] = useState([]);
    const [evalData, setEvalData] = useState([]);
    const [consultingQuality, setConsultingQuality] = useState([]);
    const [overallConsultingQuality, setOverallConsultingQuality] = useState([]);

    // Statistical metrics
    const [totalChat, setTotalChat] = useState('');
    const [totalTimeSpend, setTotalTimeSpend] = useState('');
    const [avgResponseTime, setAvgResponseTime] = useState('');
    const [nextStepRate, setNextStepRate] = useState('');

    // chart
    const [totalChatSeries, setTotalChatSeries] = useState([]);
    const [totalTimeSpendSeries, setTotalTimeSpendSeries] = useState([]);
    const [avgResponseTimeSeries, setAvgResponseTimeSeries] = useState([]);
    const [nextStepRateSeries, setNextStepRateSeries] = useState([]);
    const [intentHistory, setIntentHistory] = useState([]);
    const[ intentPerformance, setIntentPerformance] = useState([]);

    const filterAgentHandler = (val) => {
        const search = agentList.filter(item => {
            return (
                (val !== '' ? item.search(new RegExp(`^${val}`, 'i')) !== -1 : true)
            )
        });
        setFilteredAgentList(search);
    };

    const applyDateRangeHandler = (start, end) => {
        let unix_start_time = (new Date(start)).getTime();
        let unix_end_time = (new Date(end)).getTime() + 86400*1000;

        setStartDate(c => unix_start_time);
        setEndDate(c => unix_end_time);
        document.getElementById("startEnd").value = `${start} - ${end}`;
        if(isNaN(unix_start_time) && isNaN(unix_end_time)) {
            unix_start_time = '';
            unix_end_time = '';
        }else if(isNaN(unix_end_time)){
        unix_end_time = '';
        } else if(isNaN(unix_start_time)){
        unix_start_time = '';
        };
    };
    const datePicker = (
        <Popover id="popover-positioned-top" title="Popover bottom" style={{"max-width":"1000px", 'border': 'none'}}>
            <DatePicker 
                applyDateRangeHandler = {applyDateRangeHandler}
                start = {startDate}
                end = {endDate}
                hideControl=''
            />
        </Popover>
    );

    const convertSecondsToHoursMinutes = (s) => {
        if (s < 3600){
            let minutes = Math.floor(s/60);
            let seconds = Math.floor(s%60);
            return minutes + 'm ' + seconds + 's'
        } else {
            let hours = Math.floor(s/3600);
            let minutes = Math.floor((s%3600)/60);
            return hours + 'h ' + minutes + 'm'
        }
    };

    const applyFilterHandler = () => {
        let input = {
            start_date: startDate,
            end_date: endDate,
            agent_name: agentName,
            criteria: criterion
        };
        if (['1','2','3','4'].includes(criterion)){
            input['operation_type'] = operation;
            input['operation_value'] = operationValue.current.value
        } else if (criterion === '6'){
            input['conversation_type'] = conversationType
        }
        console.log(input);
        dispatch(showLoading());
        fetch(`${process.env.REACT_APP_HOST_IP}/api/tawkto-message`, {
            method: 'POST',
            body: JSON.stringify(input),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
                'X-User': userName
            },
        }).then(
                response => {
                    dispatch(hideLoading());
                    return response.json()
                }
        ).then (
            (data) =>{
                console.log(data);
                setTawkChat(data['data']);
                setTotalChat(data['total_chat']);
                setTotalTimeSpend(convertSecondsToHoursMinutes(data['total_time_spent']));
                setAvgResponseTime(convertSecondsToHoursMinutes(data['avg_response_time']));
                setNextStepRate(`${(data['next_action_rate']*100).toFixed(2)}%`);
                setOverallConsultingQuality(data['overall_consulting_quality']);
                // intent history
                let h = Object.entries(data['intent']).map(([k, v]) => {
                    return {
                        date: parseInt(k),
                        ...v
                    }
                });
                h.sort((a,b) => a.date - b.date);
                setIntentHistory(h);
                console.log('intent history: ', h);
                // intent performance
                let p = Object.entries(data['intent_performance']).map(([k, v]) => {
                    return {
                        'intent': k,
                        ...v
                    }
                });
                setIntentPerformance(p);
                console.log('intent performance', p);
                let d = Object.entries(data['total_chat_series']).map(([k, v]) => {
                    return {
                        date: k,
                        'Total chats': v
                    }
                })
                d.sort((a,b) => a.date - b.date);
                setTotalChatSeries(d);
                d = Object.entries(data['total_time_spent_series']).map(([k, v]) => {
                    return {
                        date: k,
                        'Time spent': v
                    }
                })
                d.sort((a,b) => a.date - b.date);
                setTotalTimeSpendSeries(d);
                d = Object.entries(data['avg_response_time_series']).map(([k, v]) => {
                    return {
                        date: k,
                        'Response time': v
                    }
                })
                d.sort((a,b) => a.date - b.date);
                setAvgResponseTimeSeries(d);
                d = Object.entries(data['next_action_rate_series']).map(([k, v]) => {
                    return {
                        date: k,
                        'Next-step rate': v
                    }
                })
                d.sort((a,b) => a.date - b.date);
                setNextStepRateSeries(d);
            }
        )
    };

    const chatBubble = (item, index) => {

        let k = 'Client';
        if( item['sender']['t'] === 's') {
            k = 'System'
        } else if (item['sender']['t'] === 'a'){
            k = item['sender']['n']
        }

        const v = item['msg'];
        // console.log(v);
        return (
            <>
                <div key={index} className={`${k !== 'Client' && 'align-self-start border border-2'} ${k==='Client' && 'align-self-end bg-primary'} mt-3 p-3`} style={{'border-radius': '20px', 'max-width': '75%', 'min-width': '15%'}}>
                    <div className={`${k==='Client' && 'text-white'}`} style={{wordBreak: 'break-word'}}>
                        {v}
                    </div>
                </div>
                <span className={`${k==='Client' ? 'align-self-end' : 'align-self-start'} badge text-secondary`}>{`${k !== 'Client' ? `${k} - ` : ''}${new Date(item['time']).toLocaleTimeString()}`}</span>
            </>
        )
    }

    const openChatDetailHandler = (item) => {
        const d = item['evaluation'].filter(it => ['1','2','3','4'].includes(it.criteria_id.toString()));
        setConsultingQuality(d);
        setEvalData(item['evaluation']);
        setConv(item['conversation']);
        const m = new bootstrap.Modal(document.getElementById('ModalChatDetail'), {focus: false});
        m.toggle();
    };

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            // console.log(payload, label)
            return (
            <div className="custom-tooltip p-2">
                <p className="label">{`Date: ${moment(parseInt(label)).format('D-M-YY')}`}</p>
                <ul className='ps-0' style={{listStyleType: 'none'}}>
                    {
                        payload.map(item => {
                            if (item.dataKey === 'open rate' || item.dataKey === 'click rate'){
                                return <li className="">{`${item.dataKey}: ${item.value}%`}</li>
                            }
                            return <li className="">{`${item.dataKey}: ${item.dataKey === 'Total chats' ? item.value.toLocaleString('en-us') :
                                item.dataKey === 'Time spent' || item.dataKey === 'Response time' ? convertSecondsToHoursMinutes(item.value) :
                                `${parseInt(item.value*100)}%`
                            }`}</li>
                        })
                    }
                </ul>
            </div>
            );
            }
    };

    const toPercent = (decimal, fixed = 0) => `${(decimal * 100).toFixed(fixed)}%`;
    const getPercent = (value, total) => {
        const ratio = total > 0 ? value / total : 0;

        return toPercent(ratio, 0);
    };

    const renderTooltipContent = (o) => {
        const { payload = [], label } = o;
        if (payload !== null){
            const total = payload.reduce(
            (result, entry) => result + entry.value,
            0
            );
        
            return (
            <div className="customized-tooltip-content">
                <p className="total">{`${moment(label).format('D-M-YY')} (Total: ${total})`}</p>
                <ul className="list">
                {payload.map((entry, index) => {
                    if (entry.value > 0){
                        return (
                            <li key={`item-${index}`} style={{ color: entry.color }}>
                                {`${entry.name}: ${entry.value}(${getPercent(entry.value, total)})`}
                            </li>
                        )
                    }
                    })}
                </ul>
            </div>
            );
        }
    };

    const checkIntentHandler = (arr) => {
        let intent = '';
        arr.map(item => {
            console.log(item.criteria_id.toString() == '6' && new RegExp('Informational').test(item.grade.toString()))
            if (item.criteria_id.toString() === '6' && new RegExp('Informational').test(item.grade.toString())){
                intent = 'Informational';
            } else if (item.criteria_id.toString() === '6' && new RegExp('Commvercial').test(item.grade.toString())){
                intent = 'Commvercial'
            } else if (item.criteria_id.toString() === '6' && new RegExp(/Transactional/).test(item.grade.toString())){
                intent = 'Transactional'
            } else if (item.criteria_id.toString() === '6' && new RegExp(/Support/).test(item.grade.toString())){
                intent = 'Support'
            } else if (item.criteria_id.toString() === '6' && new RegExp(/Account/).test(item.grade.toString())){
                intent = 'Account mgmt'
            } else if (item.criteria_id.toString() === '6' && new RegExp(/Feedback/).test(item.grade.toString())){
                intent = 'Feedback'
            } else if (item.criteria_id.toString() === '6') {
                intent = 'Other'
            }
        });
        return intent;
    };

    const checkNextStepHandler = (arr) => {
        let intent = '';
        arr.map(item => {
            if (item.criteria_id.toString() === '5' && new RegExp('yes').test(item.grade.toString())){
                intent = 'yes';
            }
        });
        return intent;
    }

    return (
        <div className="d-flex flex-column">
            <h1 className="text-center mt-4 mb-5">Tawkto Evaluation</h1>
            <div className='d-flex my-4'>
                <div class="input-group ps-3 w-auto me-3">
                    <span class="input-group-text" id="basic-addon1">Date range</span>
                    <OverlayTrigger trigger={["click"]} placement="bottom" overlay={datePicker} rootClose container={this} containerPadding={20}>
                        <input type="text" className="form-control" placeholder="from - to" id="startEnd"/>
                    </OverlayTrigger>
                </div>
                <div class="dropdown me-3 align-self-center">
                    <button class="btn btn-secondary dropdown-toggle" type="button" id="location" data-bs-toggle="dropdown" aria-expanded="false">
                        <FontAwesomeIcon icon={faHeadset} className='me-2' />
                        {agentName}
                    </button>
                    <ul class="dropdown-menu p-2" aria-labelledby="dropdownMenuButton1" style={{maxHeight:'300px', 'overflow': 'auto', width: '250px', 'z-index': '9999'}}>
                        <input type="text" class="form-control sticky-top" placeholder="Search country..." aria-label="Search country..." aria-describedby="basic-addon1"
                        onChange = {e => filterAgentHandler(e.target.value)}
                        />
                        {filteredAgentList.map((item, index) => {
                            return (
                                <li key={index} onClick={e => setAgentName(item)}><a class="dropdown-item" href="#">{item}</a></li>
                            )
                        })}
                    </ul>
                </div>
                <div className='d-flex me-2'>
                    <select class="form-select" onChange={e => setCriterion(e.target.value)}>
                        <option selected value=''>Criteria</option>
                        {
                            criteriaList.map((item, index) => 
                                <option value={item.value}>{item.name}</option>
                            )
                        }
                    </select>
                </div>
                {
                    ['1','2','3','4'].includes(criterion) ?
                    <>
                        <div className='d-flex me-2'>
                            <select class="form-select" onChange={e => setOperation(e.target.value)} >
                                <option selected value={`>=`}>{`>=`}</option>
                                <option value={`<=`}>{`<=`}</option>
                                <option value={`=`}>{`=`}</option>
                            </select>
                        </div>
                        <div className='d-flex'>
                            <input type="text" className="form-control me-2" placeholder="value" ref={operationValue}/>
                        </div>
                    </> :
                    criterion === '6' ?
                    <div className='d-flex me-2'>
                        <select class="form-select" onChange={e => setConversationType(e.target.value)}>
                            <option selected>Informational</option>
                            <option >Commercial</option>
                            <option >Transactional</option>
                            <option >Support</option>
                            <option >Account management</option>
                            <option >Feedback</option>
                            <option >Other</option>
                        </select>
                    </div> : null
                }
                <button type="button" class="btn btn-primary" onClick={applyFilterHandler}>
                    Apply filter
                </button>
            </div>
            <div className='d-flex my-4 align-items-center'>
                <div className='d-flex flex-column col-md-6'>
                    <div className='d-flex my-3'>
                        <div class={`d-flex flex-column col border shadow-sm rounded mx-3 py-2 px-3`}>
                            <b>Total Chat</b>
                            <div className='d-flex'>
                                <div className='d-flex col-md-4 justify-content-center'>
                                    <h4 className='align-self-center my-3'>
                                        {totalChat}
                                    </h4>
                                </div>
                                <div className='d-flex col-md-8' style={{maxHeight: '100px'}}>
                                    <ResponsiveContainer width='100%' height='100%'>
                                        <AreaChart
                                        width={500}
                                        height={300}
                                        data={totalChatSeries}
                                        syncId="syncChart"
                                        syncMethod={(tick, datum) => {
                                            let d = tick.filter(item => item.value === datum.activeLabel);
                                            if (d.length > 0 && Object.keys(d[0]).includes('index')){
                                                return d[0].index
                                            }
                                        }}
                                        margin={{
                                            top: 5,
                                            right: 10,
                                            left: 10,
                                            bottom: 5,
                                        }}
                                        >
                                            <defs>
                                                <linearGradient id="color1" x1="0" y1="0" x2="0" y2="1">
                                                    <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                                                    <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
                                                </linearGradient>
                                            </defs>
                                            {/* <CartesianGrid strokeDasharray="3 3" /> */}
                                            <XAxis
                                                dataKey="date"
                                                name = "date"
                                                hide= 'true'
                                                tickFormatter = {(unixTime) => moment(parseInt(unixTime)).format('M-YY')}

                                            />
                                            <YAxis 
                                                domain={[0, 'auto']}
                                                hide= 'true'
                                            />
                                            <Tooltip 
                                                content={<CustomTooltip />}
                                                wrapperStyle={{ backgroundColor: '#f8f9fa', padding: '2px' }}
                                                offset={20}
                                                allowEscapeViewBox={{x:true}}
                                            />
                                            {/* <Legend legendType='none'/> */}
                                            <Area type="monotone" dataKey="Total chats" name="list growth" stroke="#8884d8" fillOpacity={1} fill="url(#color1)"/>
                                        </AreaChart>
                                    </ResponsiveContainer>
                                </div>
                            </div>
                            <span style={{minHeight: '21px'}}></span>
                        </div>
                        <div class={`d-flex flex-column col border shadow-sm rounded mx-3 py-2 px-3`}>
                            <b>Total Time Spent</b>
                            <div className='d-flex'>
                                <div className='d-flex col-md-4 justify-content-center'>
                                    <h4 className='align-self-center my-3'>
                                        {totalTimeSpend}
                                    </h4>
                                </div>
                                <div className='d-flex col-md-8' style={{maxHeight: '100px'}}>
                                    <ResponsiveContainer width='100%' height='100%'>
                                        <AreaChart
                                        width={500}
                                        height={300}
                                        data={totalTimeSpendSeries}
                                        syncId="syncChart"
                                        syncMethod={(tick, datum) => {
                                            let d = tick.filter(item => item.value === datum.activeLabel);
                                            if (d.length > 0 && Object.keys(d[0]).includes('index')){
                                                return d[0].index
                                            }
                                        }}
                                        margin={{
                                            top: 5,
                                            right: 10,
                                            left: 10,
                                            bottom: 5,
                                        }}
                                        >
                                            <defs>
                                                <linearGradient id="color1" x1="0" y1="0" x2="0" y2="1">
                                                    <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                                                    <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
                                                </linearGradient>
                                            </defs>
                                            {/* <CartesianGrid strokeDasharray="3 3" /> */}
                                            <XAxis
                                                dataKey="date"
                                                name = "date"
                                                hide= 'true'
                                                tickFormatter = {(unixTime) => moment(parseInt(unixTime)).format('M-YY')}

                                            />
                                            <YAxis 
                                                domain={[0, 'auto']}
                                                hide= 'true'
                                            />
                                            <Tooltip 
                                                content={<CustomTooltip />}
                                                wrapperStyle={{ backgroundColor: '#f8f9fa', padding: '2px' }}
                                                offset={20}
                                                allowEscapeViewBox={{x:true}}
                                            />
                                            {/* <Legend legendType='none'/> */}
                                            <Area type="monotone" dataKey="Time spent" name="list growth" stroke="#8884d8" fillOpacity={1} fill="url(#color1)"/>
                                        </AreaChart>
                                    </ResponsiveContainer>
                                </div>
                            </div>
                            <span style={{minHeight: '21px'}}></span>
                        </div>
                    </div>
                    <div className='d-flex'>
                        <div class={`d-flex flex-column col border shadow-sm rounded mx-3 py-2 px-3`}>
                            <b>Avg. Response Time</b>
                            <div className='d-flex'>
                                <div className='d-flex col-md-4 justify-content-center'>
                                    <h4 className='align-self-center my-3'>
                                        {avgResponseTime}
                                    </h4>
                                </div>
                                <div className='d-flex col-md-8' style={{maxHeight: '100px'}}>
                                    <ResponsiveContainer width='100%' height='100%'>
                                        <AreaChart
                                        width={500}
                                        height={300}
                                        data={avgResponseTimeSeries}
                                        syncId="syncChart"
                                        syncMethod={(tick, datum) => {
                                            let d = tick.filter(item => item.value === datum.activeLabel);
                                            if (d.length > 0 && Object.keys(d[0]).includes('index')){
                                                return d[0].index
                                            }
                                        }}
                                        margin={{
                                            top: 5,
                                            right: 10,
                                            left: 10,
                                            bottom: 5,
                                        }}
                                        >
                                            <defs>
                                                <linearGradient id="color1" x1="0" y1="0" x2="0" y2="1">
                                                    <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                                                    <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
                                                </linearGradient>
                                            </defs>
                                            {/* <CartesianGrid strokeDasharray="3 3" /> */}
                                            <XAxis
                                                dataKey="date"
                                                name = "date"
                                                hide= 'true'
                                                tickFormatter = {(unixTime) => moment(parseInt(unixTime)).format('M-YY')}

                                            />
                                            <YAxis 
                                                domain={[0, 'auto']}
                                                hide= 'true'
                                            />
                                            <Tooltip 
                                                content={<CustomTooltip />}
                                                wrapperStyle={{ backgroundColor: '#f8f9fa', padding: '2px' }}
                                                offset={20}
                                                allowEscapeViewBox={{x:true}}
                                            />
                                            {/* <Legend legendType='none'/> */}
                                            <Area type="monotone" dataKey="Response time" name="list growth" stroke="#8884d8" fillOpacity={1} fill="url(#color1)"/>
                                        </AreaChart>
                                    </ResponsiveContainer>
                                </div>
                            </div>
                            <span style={{minHeight: '21px'}}></span>
                        </div>
                        <div class={`d-flex flex-column col border shadow-sm rounded mx-3 py-2 px-3`}>
                            <b>Next-action Rate</b>
                            <div className='d-flex'>
                                <div className='d-flex col-md-4 justify-content-center'>
                                    <h4 className='align-self-center my-3'>
                                        {nextStepRate}
                                    </h4>
                                </div>
                                <div className='d-flex col-md-8' style={{maxHeight: '100px'}}>
                                    <ResponsiveContainer width='100%' height='100%'>
                                        <AreaChart
                                        width={500}
                                        height={300}
                                        data={nextStepRateSeries}
                                        syncId="syncChart"
                                        syncMethod={(tick, datum) => {
                                            let d = tick.filter(item => item.value === datum.activeLabel);
                                            if (d.length > 0 && Object.keys(d[0]).includes('index')){
                                                return d[0].index
                                            }
                                        }}
                                        margin={{
                                            top: 5,
                                            right: 10,
                                            left: 10,
                                            bottom: 5,
                                        }}
                                        >
                                            <defs>
                                                <linearGradient id="color1" x1="0" y1="0" x2="0" y2="1">
                                                    <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                                                    <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
                                                </linearGradient>
                                            </defs>
                                            {/* <CartesianGrid strokeDasharray="3 3" /> */}
                                            <XAxis
                                                dataKey="date"
                                                name = "date"
                                                hide= 'true'
                                                tickFormatter = {(unixTime) => moment(parseInt(unixTime)).format('M-YY')}

                                            />
                                            <YAxis 
                                                domain={[0, 'auto']}
                                                hide= 'true'
                                            />
                                            <Tooltip 
                                                content={<CustomTooltip />}
                                                wrapperStyle={{ backgroundColor: '#f8f9fa', padding: '2px' }}
                                                offset={20}
                                                allowEscapeViewBox={{x:true}}
                                            />
                                            {/* <Legend legendType='none'/> */}
                                            <Area type="monotone" dataKey="Next-step rate" name="list growth" stroke="#8884d8" fillOpacity={1} fill="url(#color1)"/>
                                        </AreaChart>
                                    </ResponsiveContainer>
                                </div>
                            </div>
                            <span style={{minHeight: '21px'}}></span>
                        </div>
                    </div>
                </div>
                <div className='d-flex flex-column col-md-6 align-items-center'>
                    <h5 className='ms-auto'>Avg. Consulting Quality</h5>
                    <div classNa='d-flex'>
                    {
                        overallConsultingQuality.length > 0 &&
                        <RadarChart 
                            data={overallConsultingQuality}
                        />
                    }
                    </div>
                </div>
            </div>
            <div className='d-flex my-4'>
                <div className="d-flex flex-column col-md-6" style={{'height': '400px'}}>
                    <h5 className="mb-4">Chat Intent</h5>
                    <ResponsiveContainer width="100%" height="80%">
                        <BarChart
                        width={500}
                        height={300}
                        data={intentHistory}
                        // stackOffset="expand"
                        // syncId="syncChart"
                        // syncMethod={(tick, datum) => {
                        //     let d = tick.filter(item => item.value === datum.activeLabel);
                        //     if (d.length > 0 && Object.keys(d[0]).includes('index')){
                        //         return d[0].index
                        //     }
                        // }}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                        >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                            dataKey="date"
                            name = "date"
                            tickFormatter = {(unixTime) => moment(unixTime).format('YY-MM-D')}

                        />
                        <YAxis 
                            // tickFormatter={(decimal) => `${(decimal * 100).toFixed(0)}%`}
                            domain={[0, 'auto']}
                        />
                        <Tooltip 
                            content={renderTooltipContent}
                            wrapperStyle={{ backgroundColor: '#f8f9fa', padding: '2px' }}
                        />
                        <Legend />
                        
                        <Bar type="monotone" dataKey="Informational" stackId="1" stroke="#8884d8" fill="#8884d8" dot={{ stroke: '#8884d8', strokeWidth: 1, r: 2,strokeDasharray:''}}/>
                        <Bar type="monotone" dataKey="Commercial" stackId="1" stroke="#82ca9d" fill="#82ca9d" dot={{ stroke: '#82ca9d', strokeWidth: 1, r: 2,strokeDasharray:''}}/>
                        <Bar type="monotone" dataKey="Transactional" stackId="1" stroke="#ffc658" fill="#ffc658" dot={{ stroke: '#ffc658', strokeWidth: 1, r: 2,strokeDasharray:''}}/>
                        <Bar type="monotone" dataKey="Support" stackId="1" stroke="#d4d884" fill="#d4d884" dot={{ stroke: '#d4d884', strokeWidth: 1, r: 2,strokeDasharray:''}}/>
                        <Bar type="monotone" dataKey="Account management" stackId="1" stroke="#d88884" fill="#d88884" dot={{ stroke: '#d88884', strokeWidth: 1, r: 2,strokeDasharray:''}}/>
                        <Bar type="monotone" dataKey="Feedback" stackId="1" stroke="#c6504a" fill="#c6504a" dot={{ stroke: '#c6504a', strokeWidth: 1, r: 2,strokeDasharray:''}}/>
                        <Bar type="monotone" dataKey="Other" stackId="1" stroke="#c6504a" fill="#c6504a" dot={{ stroke: '#c6504a', strokeWidth: 1, r: 2,strokeDasharray:''}}/>      
                        </BarChart>
                    </ResponsiveContainer>
                    
                </div>
                <div className='d-flex flex-column col-md-6'>
                    <h5 className="mb-4">Evaluation by Chat Intent</h5>
                    <div className='d-flex overflow-auto my-2' style={{maxHeight: '300px'}}>
                        <table class="table" style={{'table-layout':'fixed', 'word-wrap': 'break-word'}}>
                            <thead className='sticky-top table-primary'>
                                <tr>
                                    <th scope="col" style={{'width':'5%'}}>#</th>
                                    <th scope="col" style={{'width':'18%'}}>Intent</th>
                                    <th scope="col" style={{'width':'18%'}}>Helpfulness</th>
                                    <th scope="col" style={{'width':'18%'}}>Friendly</th>
                                    <th scope="col" style={{'width':'18%'}}>Clear</th>
                                    <th scope="col" style={{'width':'18%'}}>Sentiment</th>
                                </tr>
                            </thead>
                                <tbody>
                                    {
                                        intentPerformance.map((item, idx) => {
                                            return (
                                                <tr>
                                                    <th scope="col">{idx+1}</th>
                                                    <td scope="col">
                                                        <>
                                                            <FontAwesomeIcon icon={faCircle} color={`${item['intent'] === 'Informational' ? '#8884d8' :
                                                                                                        item['intent'] === 'Commercial' ? '#82ca9d' :
                                                                                                        item['intent'] === 'Transactional' ? '#ffc658' :
                                                                                                        item['intent'] === 'Support' ? '#d4d884' :
                                                                                                        item['intent'] === 'Account management' ? '#d88884' :
                                                                                                        item['intent'] === 'Feedback' ? '#c6504a' :
                                                                                                        item['intent'] === 'Other' ? '#c6504a' : null
                                                            }`} size='sm' className='me-2'/>
                                                            {item['intent']}
                                                        </>
                                                    </td>
                                                    <td scope="col" style={{backgroundColor: `${parseInt(item['1']) === 5 ? "#31a354"  :
                                                                                                parseInt(item['1']) === 4 ? "#78c679"  :
                                                                                                parseInt(item['1']) === 3 ? "#fecc5c"  :
                                                                                                parseInt(item['1']) === 2 ? "#fd8d3c"  :
                                                                                                parseInt(item['1']) === 1 ? "#f03b20"  : 
                                                                                                parseInt(item['1']) === 0 ? "#666666"  : null
                                                    }`}}>
                                                        {item['1'] === 0 ? '' : item['1'].toFixed(1)}
                                                    </td>
                                                    <td scope="col" style={{backgroundColor: `${parseInt(item['2']) === 5 ? "#31a354"  :
                                                                                                parseInt(item['2']) === 4 ? "#78c679"  :
                                                                                                parseInt(item['2']) === 3 ? "#fecc5c"  :
                                                                                                parseInt(item['2']) === 2 ? "#fd8d3c"  :
                                                                                                parseInt(item['2']) === 1 ? "#f03b20"  :
                                                                                                parseInt(item['2']) === 0 ? "#666666"  : null
                                                    }`}}>
                                                        {item['2'] === 0 ? '' : item['2'].toFixed(1)}
                                                    </td>
                                                    <td scope="col" style={{backgroundColor: `${parseInt(item['3']) === 5 ? "#31a354"  :
                                                                                                parseInt(item['3']) === 4 ? "#78c679"  :
                                                                                                parseInt(item['3']) === 3 ? "#fecc5c"  :
                                                                                                parseInt(item['3']) === 2 ? "#fd8d3c"  :
                                                                                                parseInt(item['3']) === 1 ? "#f03b20"  :
                                                                                                parseInt(item['3']) === 0 ? "#666666"  : null
                                                    }`}}>
                                                        {item['3'] === 0 ? '' : item['3'].toFixed(1)}
                                                    </td>
                                                    <td scope="col" style={{backgroundColor: `${parseInt(item['4']) === 5 ? "#31a354"  :
                                                                                                parseInt(item['4']) === 4 ? "#78c679"  :
                                                                                                parseInt(item['4']) === 3 ? "#fecc5c"  :
                                                                                                parseInt(item['4']) === 2 ? "#fd8d3c"  :
                                                                                                parseInt(item['4']) === 1 ? "#f03b20"  :
                                                                                                parseInt(item['4']) === 0 ? "#666666"  : null
                                                    }`}}>
                                                        {item['4'] === 0 ? '' : item['4'].toFixed(1)}
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className='d-flex my-4'>
                {/* Modal Bounce*/}
                <div class="modal fade" id="ModalChatDetail" tabindex="-1" role="dialog" aria-labelledby="ModalCenterTitle" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
                        <div class="modal-content">
                            <div className="modal-header">
                                <h5 class="modal-title me-2" id="ModalLongTitle">Message Content & Evaluation</h5>
                            </div>
                            <div class="d-flex flex-row modal-body">
                                <div className='d-flex col-md-6'>
                                    <div className="d-flex flex-column mb-3 pe-3" style={{'height':'500px', 'overflow': 'auto'}}>
                                        {conv.map((item, index) => 
                                            chatBubble(item, index)
                                        )}
                                    </div>
                                </div>
                                <div className='d-flex col-md-6 flex-column'>
                                    <div className='d-flex mb-4 align-self-center'>
                                        {
                                            consultingQuality.length > 0 &&
                                            <RadarChart 
                                                data={consultingQuality}
                                            />
                                        }
                                    </div>
                                    <div className='d-flex mb-4' style={{'height':'200px', 'overflow': 'auto'}}>
                                        <ul list-group list-group-flush>
                                            {
                                                consultingQuality.map((item, idx) => {
                                                    let criteria_name = '';
                                                    if (item.criteria_id.toString() === '1'){
                                                        criteria_name = 'Helpfulness'
                                                    } else if (item.criteria_id.toString() === '2'){
                                                        criteria_name = 'Friendly'
                                                    } else if (item.criteria_id.toString() === '3'){
                                                        criteria_name = 'Clear'
                                                    } else if (item.criteria_id.toString() === '4'){
                                                        criteria_name = 'Sentiment'
                                                    };
                                                    let colorCode = '#31a354'
                                                    if (item.grade === 4){
                                                        colorCode = '#78c679'
                                                    } else if (item.grade === 3){
                                                        colorCode = '#fecc5c'
                                                    } else if (item.grade === 2) {
                                                        colorCode = '#fd8d3c'
                                                    } else if (item.grade === 1){
                                                        colorCode = '#f03b20'
                                                    }
                                                     return <li className='list-group-item border-0'>
                                                                <span className='badge' style={{backgroundColor: colorCode}}>
                                                                    {`${criteria_name}: ${item.grade}`}
                                                                </span>
                                                                <br />
                                                                <span>
                                                                    {`${item.explaination}`}
                                                                </span>
                                                            </li>
                                                })
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Modal Bounce*/}
                <div className='d-flex overflow-auto my-2' style={{maxHeight: '500px'}}>
                    <table class="table" style={{'table-layout':'fixed', 'word-wrap': 'break-word'}}>
                        <thead className='sticky-top table-primary'>
                            <tr>
                                <th scope="col" style={{'width':'5%'}}>#</th>
                                <th scope="col" style={{'width':'18%'}}>Conversation Id</th>
                                <th scope="col" style={{'width':'18%'}}>Agent name</th>
                                <th scope="col" style={{'width':'18%'}}>Start</th>
                                <th scope="col" style={{'width':'18%'}}>Duration</th>
                                <th scope="col" style={{'width':'18%'}}>Message Count</th>
                                <th scope="col" style={{'width':'18%'}}>Intent</th>
                                <th scope="col" style={{'width':'18%'}}>Next-step</th>
                                <th scope="col" style={{'width':'5%'}}>Action</th>
                            </tr>
                        </thead>
                            <tbody>
                                {
                                    tawkChat.map((item, idx) => {
                                        return (
                                            <tr>
                                                <th scope="col">{idx+1}</th>
                                                <td scope="col">{item['_id']}</td>
                                                <td scope="col">{agentName}</td>
                                                <td scope="col">{new Date(item.start_time).toLocaleDateString()}</td>
                                                <td scope="col">{convertSecondsToHoursMinutes(item.chat_duration)}</td>
                                                <td scope="col">{item.message_count}</td>
                                                <td scope="col">{checkIntentHandler(item.evaluation)}</td>
                                                <td scope="col">{checkNextStepHandler(item.evaluation)}</td>
                                                <td scope="col" >
                                                    <button type="button" class="btn btn-light" onClick={e => openChatDetailHandler(item)}>
                                                        <FontAwesomeIcon icon={faEye} />
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
};

export default  TawkTo;