import { faBarChart, faLineChart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as d3 from "d3";
import { useEffect, useRef } from "react";
import FontAwesome from "react-fontawesome";

// const data = {
//     "children": [
//       {
//         "name": "Nhóm 1",
//         "children": [
//           {
//             "group": "1",
//             "name": "Nhóm 1.1",
//             "url": "/vi/blog/website-ban-hoa",
//             "display_name": "Thiết kế website bán hoa để tiếp cận khách hàng tiềm năng",
//             "keywords": ["website bán hoa<<>>210<<>>14<<>>16"],
//             "kw_count": 1,
//             "value": 210
//           }
//         ]
//       },
//       {
//         "name": "Nhóm 2",
//         "children": [
//           {
//             "group": "2",
//             "name": "Nhóm 2.1",
//             "url": "/vi/thiet-ke-website",
//             "display_name": "Dịch vụ thiết kế Website Trọn Gói Uy Tín Nhất Việt Nam - Gleads",
//             "keywords": [
//               "thiêt ke web<<>>49500<<>>100<<>>59",
//               "dich vụ thiết kế website<<>>3600<<>>100<<>>33",
//               "thiet ke web di an<<>>2400<<>>10<<>>38",
//               "dịch vụ thiết kế web chuẩn seo<<>>1900<<>>100<<>>83",
//               "thiết kế website trọn gói<<>>1900<<>>100<<>>33",
//               "dịch vụ website trọn gói<<>>1000<<>>100<<>>26",
//               "thiết kế web cao cấp<<>>720<<>>100<<>>57",
//               "thiết kế web doanh nghiệp<<>>590<<>>100<<>>40",
//               "dịch vụ làm web<<>>480<<>>100<<>>59",
//               "dịch vụ làm website<<>>480<<>>100<<>>31",
//               "dịch vụ thiết kế web uy tín<<>>390<<>>100<<>>40",
//               "dịch vụ thiết kế website uy tín<<>>390<<>>75<<>>57",
//               "thiet ke web tron goi<<>>260<<>>100<<>>22",
//               "thiet ke website tron goi<<>>260<<>>97<<>>18",
//               "thiết kế website uy tín<<>>260<<>>100<<>>78",
//               "website trọn gói<<>>210<<>>100<<>>58"
//             ],
//             "kw_count": 16,
//             "value": 64340
//           },
//           {
//             "group": "2",
//             "name": "Nhóm 2.2",
//             "url": "/vi/blog/thiet-ke-website-doc-truyen",
//             "display_name": "Thiết kế website đọc truyện tranh, tiểu thuyết, ngôn tình - Gleads",
//             "keywords": [
//               "web đọc truyện tranh<<>>1600<<>>62<<>>69",
//               "đọc tiểu thuyết<<>>1300<<>>77<<>>99",
//               "web truyện online<<>>1000<<>>14<<>>90",
//               "đọc tiểu thuyết ngôn tình<<>>1000<<>>100<<>>71",
//               "đọc truyện tranh hay<<>>720<<>>39<<>>100",
//               "web truyện chữ<<>>390<<>>100<<>>86",
//               "trang web đọc truyện<<>>320<<>>11<<>>39",
//               "web đọc tiểu thuyết<<>>320<<>>27<<>>53",
//               "truyen com tieu thuyet<<>>210<<>>26<<>>56",
//               "truyện tranh tiểu thuyết<<>>210<<>>10<<>>79",
//               "truyện tranh đọc<<>>210<<>>54<<>>67",
//               "web truyen tranh<<>>170<<>>11<<>>46",
//               "đọc truyện tranh ngôn tình online<<>>170<<>>38<<>>57"
//             ],
//             "kw_count": 13,
//             "value": 7620
//           },
//           {
//             "group": "2",
//             "name": "Nhóm 2.3",
//             "url": "/vi/thiet-ke-website-wordpress",
//             "display_name": "Thiết kế website wordpress code theo quy trình riêng của khách",
//             "keywords": [
//               "dịch vụ thiết kế web wordpress<<>>1000<<>>54<<>>9",
//               "thiết kế web wordpress<<>>880<<>>23<<>>22",
//               "thiết kế web wordpress gia re<<>>590<<>>50<<>>28",
//               "website wordpress<<>>480<<>>66<<>>26",
//               "thiết kế website bằng wordpress<<>>260<<>>18<<>>33",
//               "web wordpress<<>>260<<>>46<<>>86"
//             ],
//             "kw_count": 6,
//             "value": 3470
//           },
//           {
//             "group": "2",
//             "name": "Nhóm 2.4",
//             "url": "/vi/blog/thiet-ke-website-thuong-mai-dien-tu",
//             "display_name": "Thiết kế website thương mại điện tử với nhiều module chuyên ...",
//             "keywords": [
//               "thiết kế web thương mại điện tử<<>>480<<>>14<<>>39",
//               "thiết kế website thương mại điện tử<<>>480<<>>13<<>>63"
//             ],
//             "kw_count": 2,
//             "value": 960
//           },
//           {
//             "group": "2",
//             "name": "Nhóm 2.5",
//             "url": "/vi/blog/thiet-ke-website-thoi-trang",
//             "display_name": "Thiết kế website thời trang quần áo bán lẻ, bán sỉ - Gleads",
//             "keywords": ["web bán quần áo<<>>480<<>>13<<>>68"],
//             "kw_count": 1,
//             "value": 480
//           },
//           {
//             "group": "2",
//             "name": "Nhóm 2.6",
//             "url": "/vi/blog/thiet-ke-website-tin-tuc",
//             "display_name": "Thiết kế website tin tức, báo điện tử với CMS chuyên sâu",
//             "keywords": [
//               "thiết kế web blog<<>>320<<>>13<<>>80",
//               "web tin tức<<>>260<<>>11<<>>54",
//               "website tin tức<<>>260<<>>11<<>>44",
//               "thiết kế website tin tức<<>>170<<>>13<<>>16",
//               "trang web tin tức<<>>70<<>>13<<>>42"
//             ],
//             "kw_count": 5,
//             "value": 1080
//           },
//           {
//             "group": "2",
//             "name": "Nhóm 2.7",
//             "url": "/vi",
//             "display_name": "Gleads - Go to Leads Digital Marketing Agency",
//             "keywords": ["gleads<<>>260<<>>13<<>>1"],
//             "kw_count": 1,
//             "value": 260
//           },
//           {
//             "group": "2",
//             "name": "Nhóm 2.8",
//             "url": "/vi/thiet-ke-catalogue",
//             "display_name": "Thiết kế, in catalogue đẹp, chuyên nghiệp - Gleads",
//             "keywords": ["thiết kế catalog online<<>>260<<>>6<<>>65"],
//             "kw_count": 1,
//             "value": 260
//           },
//           {
//             "group": "2",
//             "name": "Nhóm 2.9",
//             "url": "/vi/blog/thiet-ke-website-van-phong-pham",
//             "display_name": "Thiết kế website văn phòng phẩm, bán sách, bán tài liệu",
//             "keywords": [
//               "thiết kế cửa hàng văn phòng phẩm nhỏ<<>>260<<>>18<<>>95"
//             ],
//             "kw_count": 1,
//             "value": 260
//           },
//           {
//             "group": "2",
//             "name": "Nhóm 2.10",
//             "url": "/vi/blog/thiet-ke-website-mang-xa-hoi",
//             "display_name": "Thiết kế website mạng xã hội xây dựng thành viên bền vững",
//             "keywords": ["website nào sau đây là mạng xã hội<<>>260<<>>14<<>>97"],
//             "kw_count": 1,
//             "value": 260
//           },
//           {
//             "group": "2",
//             "name": "Nhóm 2.11",
//             "url": "/vi/blog/quan-tri-website",
//             "display_name": "Quản trị website là gì? Hướng dẫn cách quản trị web hiệu quả",
//             "keywords": ["hệ thống quản trị website<<>>210<<>>10<<>>16"],
//             "kw_count": 1,
//             "value": 210
//           },
//           {
//             "group": "2",
//             "name": "Nhóm 2.12",
//             "url": "/vi/blog/thiet-ke-website-doanh-nghiep",
//             "display_name": "Thiết kế website doanh nghiệp giúp tối ưu hóa quy trình | Gleads",
//             "keywords": ["thiết kế website cho doanh nghiệp<<>>210<<>>100<<>>56"],
//             "kw_count": 1,
//             "value": 210
//           },
//           {
//             "group": "2",
//             "name": "Nhóm 2.13",
//             "url": "/vi/blog/thiet-ke-website-affiliate",
//             "display_name": "Thiết kế website Affiliate để bắt kịp xu thế trong 10 nắm tới",
//             "keywords": ["website affiliate<<>>210<<>>15<<>>60"],
//             "kw_count": 1,
//             "value": 210
//           },
//           {
//             "group": "2",
//             "name": "Nhóm 2.14",
//             "url": "/vi/blog/thiet-ke-website-khach-san",
//             "display_name": "Thiết Kế Website Khách Sạn, Resort Nâng Tầm đằng Cấp",
//             "keywords": ["thiết kế website khách sạn<<>>170<<>>21<<>>43"],
//             "kw_count": 1,
//             "value": 170
//           },
//           {
//             "group": "2",
//             "name": "Nhóm 2.15",
//             "url": "/vi/blog/thiet-ke-website",
//             "display_name": "Thiết Kế Website - Gleads",
//             "keywords": [
//               "tuyệt mỹ bạch liên hoa online dạy học wordpress<<>>140<<>>14<<>>30"
//             ],
//             "kw_count": 1,
//             "value": 140
//           },
//           {
//             "group": "2",
//             "name": "Nhóm 2.16",
//             "url": "/vi/blog/thiet-ke-website-giai-tri",
//             "display_name": "Thiết Kế Website Giải Trí Tối ưu Tải Nhanh Video, Hình ảnh",
//             "keywords": ["web giai tri<<>>50<<>>13<<>>31"],
//             "kw_count": 1,
//             "value": 50
//           }
//         ]
//       },
//       {
//         "name": "Nhóm 3",
//         "children": [
//           {
//             "group": "3",
//             "name": "Nhóm 3.1",
//             "url": "/vi/blog/cach-dat-quang-cao-tren-youtube",
//             "display_name": "Cách đặt quảng cáo trên Youtube để bật kiếm tiền - Gleads",
//             "keywords": [
//               "kiem tien youtube<<>>4400<<>>35<<>>68",
//               "cách kiếm tiền trên youtube<<>>1600<<>>41<<>>66",
//               "kiếm tiền từ youtube<<>>1000<<>>41<<>>75",
//               "quảng cáo youtube<<>>1000<<>>29<<>>55",
//               "điều kiện bật kiếm tiền youtube<<>>1000<<>>30<<>>33",
//               "bật kiếm tiền youtube<<>>880<<>>30<<>>25",
//               "cách bật kiếm tiền trên youtube<<>>720<<>>34<<>>35",
//               "cách bật kiếm tiền youtube<<>>720<<>>34<<>>23",
//               "kiếm tiền trên youtube<<>>720<<>>34<<>>40",
//               "cách kiếm tiền từ youtube<<>>590<<>>35<<>>78",
//               "bật kiếm tiền trên youtube<<>>390<<>>34<<>>34",
//               "điều kiện bật kiếm tiền youtube 2023<<>>210<<>>25<<>>60"
//             ],
//             "kw_count": 12,
//             "value": 13230
//           },
//           {
//             "group": "3",
//             "name": "Nhóm 3.2",
//             "url": "/vi/blog/chi-phi-quang-cao-tren-youtube",
//             "display_name": "Chi phí quảng cáo trên Youtube gồm có những khoản nào?",
//             "keywords": [
//               "ads youtube<<>>1600<<>>25<<>>34",
//               "chạy quảng cáo youtube<<>>480<<>>35<<>>22",
//               "quảng cáo thuốc trên youtube<<>>320<<>>23<<>>94",
//               "quảng cáo trên youtube<<>>320<<>>35<<>>26",
//               "chi phí quảng cáo<<>>260<<>>9<<>>67",
//               "quảng cáo trên google giá bao nhiêu<<>>50<<>>15<<>>79"
//             ],
//             "kw_count": 6,
//             "value": 3030
//           },
//           {
//             "group": "3",
//             "name": "Nhóm 3.3",
//             "url": "/vi/blog/quang-cao-discovery-youtube",
//             "display_name": "Quảng cáo Discovery Youtube là gì? Khi nào nên sử dụng?",
//             "keywords": [
//               "discover la gì<<>>1600<<>>11<<>>34",
//               "discovery là gì<<>>1600<<>>11<<>>25",
//               "discovery la gì<<>>110<<>>10<<>>22"
//             ],
//             "kw_count": 3,
//             "value": 3310
//           },
//           {
//             "group": "3",
//             "name": "Nhóm 3.4",
//             "url": "/vi/blog/quang-cao-game-tren-youtube",
//             "display_name": "Quảng cáo game trên Youtube giúp tăng lượt tải với ... - Gleads",
//             "keywords": ["quảng cáo game<<>>260<<>>13<<>>61"],
//             "kw_count": 1,
//             "value": 260
//           },
//           {
//             "group": "3",
//             "name": "Nhóm 3.5",
//             "url": "/vi/blog/quang-cao-youtube-trueview",
//             "display_name": "Quảng cáo trueview là gì? Tại sao nó được dùng phổ biến?",
//             "keywords": ["youtube trueview là gì<<>>50<<>>7<<>>1"],
//             "kw_count": 1,
//             "value": 50
//           },
//           {
//             "group": "3",
//             "name": "Nhóm 3.6",
//             "url": "/vi/blog/cac-loai-quang-cao-tren-youtube",
//             "display_name": "Các loại quảng cáo trên Youtube, có những hình thưc nào?",
//             "keywords": [
//               "các hình thức quảng cáo trên youtube<<>>30<<>>10<<>>45",
//               "các hình thức quảng cáo youtube<<>>30<<>>10<<>>27",
//               "quảng cáo youtube masthead<<>>10<<>>9<<>>40"
//             ],
//             "kw_count": 3,
//             "value": 70
//           },
//           {
//             "group": "3",
//             "name": "Nhóm 3.7",
//             "url": "/vi/blog/mua-quang-cao-youtube",
//             "display_name": "Mua quảng cáo Youtube ở đâu uy tín, chất lượng - Gleads",
//             "keywords": ["quảng cáo gdn trên youtube<<>>10<<>>7<<>>96"],
//             "kw_count": 1,
//             "value": 10
//           },
//           {
//             "group": "3",
//             "name": "Nhóm 3.8",
//             "url": "/vi/blog/chay-quang-cao-tren-messenger",
//             "display_name": "Chạy quảng cáo trên messenger chiến lượt remarketing hoàn ...",
//             "keywords": ["quảng cáo trên facebook messenger<<>>10<<>>15<<>>13"],
//             "kw_count": 1,
//             "value": 10
//           }
//         ]
//       },
//       {
//         "name": "Nhóm 4",
//         "children": [
//           {
//             "group": "4",
//             "name": "Nhóm 4.1",
//             "url": "/vi/blog/google-mcc-ads",
//             "display_name": "MCC là gì? Hướng dẫn tạo tài khoản Google MCC Ads từ A-Z",
//             "keywords": [
//               "ad mcc<<>>6600<<>>23<<>>18",
//               "ads mcc<<>>6600<<>>23<<>>19",
//               "mcc ads<<>>6600<<>>10<<>>22",
//               "tạo mcc<<>>2400<<>>23<<>>14",
//               "google mcc<<>>1000<<>>19<<>>19",
//               "mcc google ads<<>>880<<>>19<<>>22",
//               "google ads mcc<<>>480<<>>19<<>>19",
//               "mcc adwords<<>>480<<>>19<<>>17",
//               "tạo tài khoản mcc<<>>480<<>>19<<>>44"
//             ],
//             "kw_count": 9,
//             "value": 25520
//           },
//           {
//             "group": "4",
//             "name": "Nhóm 4.2",
//             "url": "/vi/blog/quang-cao-google-shopping",
//             "display_name": "Quảng cáo Google Shopping, kênh dành cho doanh nghiệp ...",
//             "keywords": [
//               "google shopping<<>>1300<<>>25<<>>76",
//               "quảng cáo google shopping<<>>170<<>>15<<>>48",
//               "quảng cáo google shopping là gì<<>>50<<>>14<<>>20",
//               "cách tạo google shopping<<>>40<<>>13<<>>86",
//               "google shopping ads là gì<<>>30<<>>10<<>>28",
//               "hướng dẫn chạy quảng cáo google shopping<<>>10<<>>23<<>>77",
//               "điều kiện chạy google shopping<<>>10<<>>10<<>>35"
//             ],
//             "kw_count": 7,
//             "value": 1610
//           },
//           {
//             "group": "4",
//             "name": "Nhóm 4.3",
//             "url": "/vi/blog/google-ads",
//             "display_name": "Google Ads | Gleads",
//             "keywords": [
//               "quảng cáo google map<<>>1000<<>>9<<>>55",
//               "cách chạy quảng cáo google map<<>>390<<>>9<<>>43",
//               "hướng dẫn quảng cáo google ads<<>>90<<>>30<<>>98",
//               "tạo mcc google ads<<>>90<<>>23<<>>54"
//             ],
//             "kw_count": 4,
//             "value": 1570
//           },
//           {
//             "group": "4",
//             "name": "Nhóm 4.4",
//             "url": "/vi/blog/quang-cao-google-gia-re",
//             "display_name": "Quảng cáo Google giá rẻ dành cho doanh nghiệp vừa và nhỏ",
//             "keywords": [
//               "quang cao google gia re<<>>590<<>>46<<>>20",
//               "quảng cáo google giá rẻ nhất<<>>590<<>>41<<>>17",
//               "quảng cáo google giá rẻ<<>>390<<>>13<<>>26",
//               "giá quảng cáo google<<>>110<<>>10<<>>35",
//               "giá quảng cáo google ads<<>>50<<>>15<<>>34",
//               "giá quảng cáo trên google<<>>30<<>>15<<>>38"
//             ],
//             "kw_count": 6,
//             "value": 1760
//           },
//           {
//             "group": "4",
//             "name": "Nhóm 4.5",
//             "url": "/vi/blog/thue-chay-quang-cao-google",
//             "display_name": "Thuê chạy quảng cáo Google và những điều cần lưu ý - Gleads",
//             "keywords": [
//               "thuê chạy quảng cáo google<<>>590<<>>10<<>>32",
//               "thuê quảng cáo google<<>>90<<>>10<<>>35",
//               "thuê chạy adword<<>>30<<>>14<<>>16",
//               "kinh nghiệm chạy quảng cáo google<<>>20<<>>10<<>>85",
//               "cho thuê tài khoản adwords<<>>10<<>>7<<>>86",
//               "thuê chạy quảng cáo google adwords<<>>10<<>>10<<>>30"
//             ],
//             "kw_count": 6,
//             "value": 750
//           },
//           {
//             "group": "4",
//             "name": "Nhóm 4.6",
//             "url": "/vi/blog/bang-gia-chay-quang-cao-google-ads",
//             "display_name": "Bảng báo giá chạy quảng cáo Google Ads gia tăng chỉ số ROI",
//             "keywords": [
//               "bảng giá chạy quảng cáo google ads<<>>480<<>>10<<>>23",
//               "chi phí chạy google ads<<>>70<<>>15<<>>55",
//               "chi phí chạy quảng cáo trên google<<>>50<<>>15<<>>68",
//               "chi phí chạy google adwords<<>>40<<>>15<<>>46",
//               "mẫu kế hoạch chạy quảng cáo google adwords<<>>10<<>>9<<>>78"
//             ],
//             "kw_count": 5,
//             "value": 650
//           },
//           {
//             "group": "4",
//             "name": "Nhóm 4.7",
//             "url": "/vi/blog/chay-quang-cao-fanpage",
//             "display_name": "Dịch Vụ Chạy Quảng Cáo Fanpage Target Chính Xác đối ...",
//             "keywords": [
//               "chạy target<<>>170<<>>14<<>>95",
//               "quảng cáo fanpage<<>>140<<>>18<<>>51"
//             ],
//             "kw_count": 2,
//             "value": 310
//           }
//         ]
//       },
//       {
//         "name": "Nhóm 5",
//         "children": [
//           {
//             "group": "5",
//             "name": "Nhóm 5.1",
//             "url": "/vi/blog/thiet-ke-giao-dien-web",
//             "display_name": "Thiết kế giao diện Web đẹp, đạt chuẩn UX/UI 2024 | Gleads",
//             "keywords": ["thiết kế giao diện web<<>>480<<>>18<<>>60"],
//             "kw_count": 1,
//             "value": 480
//           },
//           {
//             "group": "5",
//             "name": "Nhóm 5.2",
//             "url": "/vi/blog/thiet-ke-giao-dien-wordpress",
//             "display_name": "Thiết kế giao diện Wordpress đạt chuẩn W3C - Gleads",
//             "keywords": [
//               "giao diện wordpress<<>>260<<>>58<<>>81",
//               "plugin thiết kế giao diện wordpress<<>>10<<>>9<<>>10"
//             ],
//             "kw_count": 2,
//             "value": 270
//           }
//         ]
//       },
//       {
//         "name": "Nhóm 6",
//         "children": [
//           {
//             "group": "6",
//             "name": "Nhóm 6.1",
//             "url": "/vi/blog/quang-cao-video-tren-facebook",
//             "display_name": "Quảng cáo Video trên Facebook, khi nào nên dùng? - Gleads",
//             "keywords": [
//               "quảng cáo cho be<<>>1600<<>>23<<>>66",
//               "kích thước video reel facebook<<>>480<<>>11<<>>83",
//               "thư viện quảng cáo fb<<>>480<<>>18<<>>86",
//               "video quảng cáo<<>>480<<>>17<<>>65"
//             ],
//             "kw_count": 4,
//             "value": 3040
//           },
//           {
//             "group": "6",
//             "name": "Nhóm 6.2",
//             "url": "/vi/blog/bang-gia-chay-quang-cao-tren-facebook",
//             "display_name": "Bảng báo giá chạy quảng cáo trên Facebook mới nhất - Gleads",
//             "keywords": [
//               "bảng giá dịch vụ facebook<<>>880<<>>31<<>>91",
//               "giá chạy quảng cáo facebook<<>>720<<>>9<<>>21",
//               "bảng giá chạy quảng cáo facebook<<>>320<<>>13<<>>23",
//               "bảng giá quảng cáo facebook<<>>70<<>>13<<>>42"
//             ],
//             "kw_count": 4,
//             "value": 1990
//           },
//           {
//             "group": "6",
//             "name": "Nhóm 6.3",
//             "url": "/vi/blog/chay-quang-cao-livestream-tren-facebook",
//             "display_name": "Chạy quảng cáo Livestream trên Facebook cho nhiều ngành ...",
//             "keywords": [
//               "chạy quảng cáo livestream<<>>320<<>>23<<>>37",
//               "quảng cáo livestream facebook<<>>260<<>>23<<>>38",
//               "cách chạy quảng cáo livestream page thường<<>>110<<>>6<<>>29",
//               "cách chạy quảng cáo live stream<<>>70<<>>23<<>>33",
//               "cách chạy quảng cáo livestream<<>>70<<>>23<<>>31",
//               "cách chạy quảng cáo cho livestream<<>>40<<>>23<<>>33"
//             ],
//             "kw_count": 6,
//             "value": 870
//           },
//           {
//             "group": "6",
//             "name": "Nhóm 6.4",
//             "url": "/vi/blog/quang-cao-san-pham-tren-facebook",
//             "display_name": "Quảng cáo sản phẩm trên Facebook và 14 hình thức phổ biến",
//             "keywords": ["các hình thức quảng cáo facebook<<>>50<<>>11<<>>71"],
//             "kw_count": 1,
//             "value": 50
//           }
//         ]
//       },
//       {
//         "name": "Nhóm 7",
//         "children": [
//           {
//             "group": "7",
//             "name": "Nhóm 7.1",
//             "url": "/vi/dich-vu-seo/seo-tong-the",
//             "display_name": "Dịch vụ SEO tổng thể Website trọn gói - Gleads",
//             "keywords": [
//               "dich vu seo<<>>74000<<>>93<<>>48",
//               "dich vụ seo<<>>60500<<>>88<<>>28",
//               "dichvuseo<<>>60500<<>>86<<>>97",
//               "dịch vu seo<<>>60500<<>>88<<>>33",
//               "dịch vụ seo<<>>60500<<>>88<<>>77",
//               "dịch vụ seo tổng the<<>>12100<<>>88<<>>24",
//               "dịch vụ seo tổng thể<<>>12100<<>>88<<>>26",
//               "dịch vụ seo traffic<<>>1900<<>>10<<>>25",
//               "seo tổng the<<>>1900<<>>27<<>>28",
//               "seo tổng thể<<>>1900<<>>17<<>>46",
//               "dịch vụ seo chuyên nghiệp<<>>1600<<>>69<<>>98",
//               "dich vu seo tong the<<>>880<<>>88<<>>10",
//               "dịch vụ seo trọn gói<<>>880<<>>45<<>>5",
//               "dịch vụ seo tổng thể website<<>>720<<>>92<<>>14",
//               "dịch vụ seo chất lượng<<>>390<<>>88<<>>24",
//               "dịch vụ seo hiệu quả<<>>320<<>>77<<>>69",
//               "bảng báo giá seo tổng thể<<>>260<<>>61<<>>73",
//               "dịch vụ seo google<<>>260<<>>88<<>>30",
//               "dịch vụ seo tổng thể website chuyên nghiệp<<>>170<<>>86<<>>21",
//               "seo web tổng thể<<>>90<<>>38<<>>48",
//               "gói seo<<>>50<<>>59<<>>23"
//             ],
//             "kw_count": 21,
//             "value": 351520
//           },
//           {
//             "group": "7",
//             "name": "Nhóm 7.2",
//             "url": "/vi/dich-vu-seo/seo-tu-khoa",
//             "display_name": "Dịch Vụ SEO Từ Khóa Chất Lượng Và Uy Tín Tại Gleads",
//             "keywords": [
//               "dịch vụ seo từ khóa<<>>8100<<>>46<<>>64",
//               "dịch vụ seo từ khóa google<<>>1600<<>>85<<>>97"
//             ],
//             "kw_count": 2,
//             "value": 9700
//           },
//           {
//             "group": "7",
//             "name": "Nhóm 7.3",
//             "url": "/vi/dich-vu-seo/seo-hcm",
//             "display_name": "Dịch vụ SEO TP.HCM - Gleads",
//             "keywords": [
//               "dịch vụ seo tphcm<<>>6600<<>>47<<>>50",
//               "dịch vụ seo hcm<<>>720<<>>51<<>>75",
//               "dịch vụ seo web tphcm<<>>480<<>>51<<>>90",
//               "dịch vụ seo giá rẻ tphcm<<>>320<<>>46<<>>96"
//             ],
//             "kw_count": 4,
//             "value": 8120
//           },
//           {
//             "group": "7",
//             "name": "Nhóm 7.4",
//             "url": "/vi/blog/seo-offpage-la-gi",
//             "display_name": "SEO Offpage là gì? Chiến lược SEO Offpage hiệu quả - Gleads",
//             "keywords": [
//               "cách seo offpage<<>>3600<<>>11<<>>64",
//               "seo offpage là gì<<>>1300<<>>19<<>>35",
//               "offpage là gì<<>>1000<<>>19<<>>27",
//               "cách làm seo offpage<<>>390<<>>10<<>>69",
//               "outreach là gì<<>>390<<>>11<<>>42",
//               "seo offpage<<>>390<<>>19<<>>66"
//             ],
//             "kw_count": 6,
//             "value": 7070
//           },
//           {
//             "group": "7",
//             "name": "Nhóm 7.5",
//             "url": "/vi/dich-vu-seo/seo-audit",
//             "display_name": "Dịch vụ Audit SEO tối ưu hóa giao diện, tăng tốc độ Website",
//             "keywords": ["seo audit là gì<<>>480<<>>10<<>>72"],
//             "kw_count": 1,
//             "value": 480
//           },
//           {
//             "group": "7",
//             "name": "Nhóm 7.6",
//             "url": "/vi/blog/seo",
//             "display_name": "SEO | Gleads",
//             "keywords": ["dịch vụ tạo google suggest<<>>260<<>>9<<>>51"],
//             "kw_count": 1,
//             "value": 260
//           }
//         ]
//       },
//       {
//         "name": "Nhóm 8",
//         "children": [
//           {
//             "group": "8",
//             "name": "Nhóm 8.1",
//             "url": "/vi/blog/top-mau-catalogue-dep-nhat",
//             "display_name": "Top 100+ Mẫu Catalogue Đẹp Và Sang Trọng - Gleads",
//             "keywords": [
//               "thiết kế catalog<<>>18100<<>>35<<>>96",
//               "catalog sản phẩm<<>>1900<<>>6<<>>7",
//               "catalogue san pham<<>>1900<<>>9<<>>7",
//               "mẫu catalogue<<>>880<<>>6<<>>7",
//               "bìa catalogue đẹp<<>>390<<>>6<<>>52",
//               "catalogue đẹp<<>>320<<>>6<<>>5",
//               "catalog mỹ phẩm<<>>260<<>>6<<>>62",
//               "catalogue thời trang<<>>260<<>>6<<>>67",
//               "thiết kế catalogue sản phẩm<<>>210<<>>11<<>>8"
//             ],
//             "kw_count": 9,
//             "value": 24220
//           },
//           {
//             "group": "8",
//             "name": "Nhóm 8.2",
//             "url": "/vi/blog/top-mau-thiet-ke-logo-spa-sang-trong",
//             "display_name": "Top 300 mẫu thiết kế logo spa sang trọng nhất - Gleads",
//             "keywords": [
//               "logo spa<<>>8100<<>>15<<>>7",
//               "mẫu logo đẹp<<>>1600<<>>15<<>>46",
//               "logo spa đẹp<<>>1300<<>>15<<>>7",
//               "mẫu logo chữ đẹp<<>>1300<<>>10<<>>67",
//               "logo for spa<<>>880<<>>19<<>>26",
//               "logo spa sang chảnh<<>>880<<>>7<<>>4",
//               "spa logo<<>>880<<>>18<<>>44",
//               "vector logo cô gái spa<<>>590<<>>11<<>>52",
//               "logo spa hình cô gái<<>>390<<>>11<<>>9",
//               "logo spa png<<>>390<<>>13<<>>34",
//               "logo cô gái<<>>320<<>>13<<>>87",
//               "logo làm đẹp<<>>320<<>>7<<>>11",
//               "logo mẫu<<>>320<<>>15<<>>71",
//               "chữ spa đẹp<<>>260<<>>5<<>>38",
//               "hình ảnh mẫu spa<<>>210<<>>23<<>>71"
//             ],
//             "kw_count": 15,
//             "value": 17740
//           },
//           {
//             "group": "8",
//             "name": "Nhóm 8.3",
//             "url": "/vi/blog/top-mau-thiet-ke-logo-my-pham-dep-nhat",
//             "display_name": "Top 30 mẫu thiết kế logo mỹ phẩm đẹp nhất năm ...",
//             "keywords": [
//               "logo mỹ phẩm<<>>1900<<>>7<<>>2",
//               "tạo logo theo tên mình<<>>1900<<>>33<<>>92",
//               "mẫu logo<<>>880<<>>15<<>>91",
//               "thiết kế logo đẹp<<>>880<<>>27<<>>32",
//               "ảnh logo đẹp<<>>590<<>>21<<>>81",
//               "hình nền mỹ phẩm đẹp<<>>480<<>>17<<>>39",
//               "thiết kế logo tên<<>>480<<>>41<<>>86",
//               "các mẫu logo đẹp<<>>390<<>>13<<>>62",
//               "hình ảnh mỹ phẩm<<>>390<<>>18<<>>41",
//               "logo dễ thương<<>>390<<>>11<<>>73",
//               "logo tròn dễ thương<<>>390<<>>13<<>>61",
//               "mẫu logo hình tròn đẹp<<>>390<<>>13<<>>76",
//               "những hình logo đẹp nhất<<>>390<<>>15<<>>44",
//               "ảnh mỹ phẩm đẹp<<>>390<<>>18<<>>63",
//               "hình ảnh logo đẹp<<>>320<<>>18<<>>34",
//               "hình ảnh shop mỹ phẩm đẹp<<>>320<<>>23<<>>83",
//               "logo cá nhân<<>>320<<>>38<<>>89",
//               "logo mỹ phẩm đẹp<<>>320<<>>6<<>>1",
//               "cosmetics logo mỹ phẩm<<>>260<<>>7<<>>90",
//               "logo đẹp nhất<<>>260<<>>15<<>>29",
//               "mẫu logo đẹp sáng tạo<<>>260<<>>15<<>>88",
//               "tạo logo shop<<>>210<<>>39<<>>57",
//               "tạo logo team 3d<<>>210<<>>23<<>>71",
//               "làm logo tên<<>>140<<>>33<<>>84"
//             ],
//             "kw_count": 24,
//             "value": 12460
//           },
//           {
//             "group": "8",
//             "name": "Nhóm 8.4",
//             "url": "/vi/blog/top-mau-thiet-ke-brochure-du-lich",
//             "display_name": "Top 10 mẫu thiết kế brochure du lịch đẹp và xin xò",
//             "keywords": [
//               "brochure du lịch<<>>720<<>>10<<>>8",
//               "thiet ke brochure<<>>720<<>>5<<>>85",
//               "thiết kế brochure<<>>720<<>>5<<>>35",
//               "mẫu brochure<<>>260<<>>15<<>>42"
//             ],
//             "kw_count": 4,
//             "value": 2420
//           },
//           {
//             "group": "8",
//             "name": "Nhóm 8.5",
//             "url": "/vi/blog/thiet-ke-website-du-lich",
//             "display_name": "Thiết kế website du lịch đẹp, giá rẻ thu hút du khách - Gleads",
//             "keywords": [
//               "web du lịch<<>>720<<>>11<<>>90",
//               "thiết kế website du lich<<>>590<<>>67<<>>47",
//               "thiết kế website du lịch<<>>590<<>>67<<>>47"
//             ],
//             "kw_count": 3,
//             "value": 1900
//           }
//         ]
//       },
//       {
//         "name": "Nhóm 9",
//         "children": [
//           {
//             "group": "9",
//             "name": "Nhóm 9.1",
//             "url": "/vi/blog/cach-doi-don-vi-tien-te-trong-quang-cao-facebook",
//             "display_name": "Cách đổi đơn vị tiền tệ trong quảng cáo facebook cho bạn mới",
//             "keywords": [
//               "đổi đơn vị tiền tệ<<>>880<<>>29<<>>92",
//               "đơn vị tiền<<>>390<<>>25<<>>78",
//               "tạo tài khoản quảng cáo facebook<<>>210<<>>29<<>>86",
//               "chạy quảng cáo facebook ở nước ngoài<<>>90<<>>9<<>>38",
//               "chạy quảng cáo thị trường nước ngoài<<>>70<<>>9<<>>36",
//               "thanh toán tiền quảng cáo facebook<<>>50<<>>22<<>>76"
//             ],
//             "kw_count": 6,
//             "value": 1690
//           },
//           {
//             "group": "9",
//             "name": "Nhóm 9.2",
//             "url": "/vi/blog/cach-viet-content-facebook",
//             "display_name": "15 cách viết content Facebook thu hút tương tác, tăng like 2022",
//             "keywords": [
//               "content facebook hay<<>>590<<>>14<<>>85",
//               "cách xóa like ảo trên bài viết facebook<<>>260<<>>11<<>>73",
//               "viết content facebook<<>>260<<>>15<<>>80"
//             ],
//             "kw_count": 3,
//             "value": 1110
//           }
//         ]
//       },
//       {
//         "name": "Nhóm 10",
//         "children": [
//           {
//             "group": "10",
//             "name": "Nhóm 10.1",
//             "url": "/vi/blog/huong-dan-tao-website-ban-hang-bang-wordpress",
//             "display_name": "Hướng dẫn tạo website bán hàng bằng Wordpress với 10 bước",
//             "keywords": [
//               "tạo website bán hàng<<>>880<<>>21<<>>70",
//               "cách tạo website bán hàng<<>>480<<>>11<<>>71",
//               "tạo web bằng wordpress<<>>390<<>>19<<>>49",
//               "tạo website bằng wordpress<<>>390<<>>19<<>>47",
//               "mẫu website bán hàng wordpress<<>>260<<>>23<<>>44",
//               "cách làm website bán hàng<<>>170<<>>21<<>>59",
//               "wordpress cơ bản<<>>110<<>>23<<>>84",
//               "giao diện web bán hàng wordpress<<>>10<<>>10<<>>52"
//             ],
//             "kw_count": 8,
//             "value": 2690
//           },
//           {
//             "group": "10",
//             "name": "Nhóm 10.2",
//             "url": "/vi/blog/thiet-ke-website-ban-hang",
//             "display_name": "Thiết kế website bán hàng có API kho, vận chuyển, CMR, ERP",
//             "keywords": ["ghn api<<>>320<<>>11<<>>77"],
//             "kw_count": 1,
//             "value": 320
//           },
//           {
//             "group": "10",
//             "name": "Nhóm 10.3",
//             "url": "/vi/blog/thiet-ke-website-ban-dien-thoai",
//             "display_name": "Thiết kế website bán điện thoại với nhiều module ưu Việt",
//             "keywords": [
//               "web bán điện thoại<<>>320<<>>98<<>>44",
//               "website bán điện thoại<<>>320<<>>11<<>>30"
//             ],
//             "kw_count": 2,
//             "value": 640
//           },
//           {
//             "group": "10",
//             "name": "Nhóm 10.4",
//             "url": "/vi/blog/web-nha-hang",
//             "display_name": "Thiết kế website nhà hàng ẩm thực sang trọng - Gleads",
//             "keywords": [
//               "web nhà hàng<<>>260<<>>11<<>>78",
//               "web ẩm thực<<>>50<<>>10<<>>96",
//               "website ẩm thực<<>>50<<>>11<<>>82"
//             ],
//             "kw_count": 3,
//             "value": 360
//           }
//         ]
//       },
//       {
//         "name": "Nhóm 11",
//         "children": [
//           {
//             "group": "11",
//             "name": "Nhóm 11.1",
//             "url": "/vi/tuyen-dung/manual-tester",
//             "display_name": "Manual Tester | Gleads",
//             "keywords": ["manual tester<<>>480<<>>10<<>>86"],
//             "kw_count": 1,
//             "value": 480
//           },
//           {
//             "group": "11",
//             "name": "Nhóm 11.2",
//             "url": "/vi/tuyen-dung/senior-ui-ux-designer",
//             "display_name": "Senior UX/UI Designer - Gleads",
//             "keywords": ["tuyển ui/ux designer<<>>210<<>>13<<>>93"],
//             "kw_count": 1,
//             "value": 210
//           }
//         ]
//       },
//       {
//         "name": "Nhóm 12",
//         "children": [
//           {
//             "group": "12",
//             "name": "Nhóm 12.1",
//             "url": "/vi/blog/gia-thiet-ke-profile-cong-ty-ho-so-nang-luc",
//             "display_name": "Báo giá thiết kế profile công ty - hồ sơ năng lực ...",
//             "keywords": [
//               "thiết kế profile<<>>27100<<>>26<<>>23",
//               "thiết kế profile công ty<<>>590<<>>15<<>>34",
//               "báo giá thiết kế hồ sơ năng lực<<>>390<<>>7<<>>2",
//               "thiết kế profile doanh nghiệp<<>>260<<>>63<<>>35",
//               "bảng giá thiết kế ảnh<<>>10<<>>14<<>>65"
//             ],
//             "kw_count": 5,
//             "value": 28350
//           },
//           {
//             "group": "12",
//             "name": "Nhóm 12.2",
//             "url": "/vi/blog/50-mau-profile-cong-ty",
//             "display_name": "50+ mẫu profile công ty đẹp, chuyên nghiệp - Gleads",
//             "keywords": [
//               "profile công ty<<>>2900<<>>6<<>>45",
//               "mẫu profile cá nhân<<>>1000<<>>6<<>>88",
//               "mẫu profile công ty<<>>590<<>>9<<>>15",
//               "cách làm profile<<>>260<<>>10<<>>65"
//             ],
//             "kw_count": 4,
//             "value": 4750
//           }
//         ]
//       },
//       {
//         "name": "Nhóm 13",
//         "children": [
//           {
//             "group": "13",
//             "name": "Nhóm 13.1",
//             "url": "/vi/thiet-ke-logo",
//             "display_name": "Dịch vụ thiết kế logo chuyên nghiệp nâng tầm thương hiệu",
//             "keywords": [
//               "dịch vụ thiết kế logo<<>>14800<<>>59<<>>37",
//               "tự thiết kế logo<<>>1300<<>>51<<>>98",
//               "thiết kế logo thương hiệu<<>>1000<<>>61<<>>91",
//               "logo dịch<<>>880<<>>77<<>>96",
//               "logo cơ khí<<>>590<<>>10<<>>93",
//               "nhận thiết kế logo<<>>480<<>>59<<>>76",
//               "ý tưởng thiết kế logo<<>>210<<>>10<<>>78"
//             ],
//             "kw_count": 7,
//             "value": 19260
//           },
//           {
//             "group": "13",
//             "name": "Nhóm 13.2",
//             "url": "/vi/blog/thiet-ke-logo-bong-chuyen",
//             "display_name": "Thiết kế logo bóng chuyền theo yêu cầu - Gleads",
//             "keywords": [
//               "logo bóng chuyền<<>>1600<<>>13<<>>9",
//               "lô gô bóng chuyền<<>>1600<<>>13<<>>8",
//               "tạo logo đội bóng<<>>590<<>>15<<>>70",
//               "thiết kế logo đội bóng<<>>320<<>>17<<>>83"
//             ],
//             "kw_count": 4,
//             "value": 4110
//           },
//           {
//             "group": "13",
//             "name": "Nhóm 13.3",
//             "url": "/vi/blog/thiet-ke-logo-banh",
//             "display_name": "Cách thiết kế logo bánh mang 'hương vị' ngọt ...",
//             "keywords": [
//               "logo bánh mì<<>>1300<<>>9<<>>22",
//               "logo tiệm bánh<<>>880<<>>10<<>>24",
//               "logo bánh<<>>480<<>>10<<>>9"
//             ],
//             "kw_count": 3,
//             "value": 2660
//           },
//           {
//             "group": "13",
//             "name": "Nhóm 13.4",
//             "url": "/vi/blog/thiet-ke-logo-am-nhac",
//             "display_name": "Thiết kế logo âm nhạc - Gleads",
//             "keywords": [
//               "logo âm nhạc<<>>880<<>>13<<>>11",
//               "logo nhạc<<>>390<<>>13<<>>24",
//               "logo âm nhạc đẹp<<>>320<<>>11<<>>33",
//               "logo nốt nhạc<<>>260<<>>13<<>>28",
//               "tạo logo âm nhạc<<>>260<<>>13<<>>9"
//             ],
//             "kw_count": 5,
//             "value": 2110
//           },
//           {
//             "group": "13",
//             "name": "Nhóm 13.5",
//             "url": "/vi/blog/thiet-ke-logo-benh-vien",
//             "display_name": "Thiết kế logo bệnh viện chuyên nghiệp - Gleads",
//             "keywords": ["logo bệnh viện<<>>720<<>>7<<>>24"],
//             "kw_count": 1,
//             "value": 720
//           },
//           {
//             "group": "13",
//             "name": "Nhóm 13.6",
//             "url": "/vi/blog/thiet-ke-logo-barber",
//             "display_name": "Thiết kế logo barber, tiệm cắt tóc - Gleads",
//             "keywords": [
//               "hình barber chất<<>>320<<>>7<<>>54",
//               "logo cắt tóc<<>>260<<>>13<<>>32"
//             ],
//             "kw_count": 2,
//             "value": 580
//           }
//         ]
//       },
//       {
//         "name": "Nhóm 14",
//         "children": [
//           {
//             "group": "14",
//             "name": "Nhóm 14.1",
//             "url": "/vi/blog/thiet-ke-web-gia-re",
//             "display_name": "Thiết kế web giá rẻ cần tập trung vào những gì? - Gleads",
//             "keywords": [
//               "thiết kế web giá rẻ<<>>2400<<>>100<<>>83",
//               "thiet ke web gia re<<>>320<<>>100<<>>82"
//             ],
//             "kw_count": 2,
//             "value": 2720
//           },
//           {
//             "group": "14",
//             "name": "Nhóm 14.2",
//             "url": "/vi/blog/thiet-ke-web-noi-that",
//             "display_name": "Thiết kế web Nội Thất Kiến Trúc có API kho, quản lý sản xuất",
//             "keywords": [
//               "thiết kế web nội thất<<>>880<<>>19<<>>92",
//               "web nội thất<<>>480<<>>19<<>>98"
//             ],
//             "kw_count": 2,
//             "value": 1360
//           },
//           {
//             "group": "14",
//             "name": "Nhóm 14.3",
//             "url": "/vi/blog/thiet-ke-web-chuan-seo",
//             "display_name": "Thiết kế web chuẩn SEO với 16 tiêu chí quan trọng - Gleads",
//             "keywords": ["web chuan seo<<>>480<<>>33<<>>77"],
//             "kw_count": 1,
//             "value": 480
//           },
//           {
//             "group": "14",
//             "name": "Nhóm 14.4",
//             "url": "/vi/blog/thiet-ke-web-xo-so",
//             "display_name": "Thiết kế web xổ số có module trực tiếp cho người dò - Gleads",
//             "keywords": ["web xổ số<<>>390<<>>100<<>>44"],
//             "kw_count": 1,
//             "value": 390
//           },
//           {
//             "group": "14",
//             "name": "Nhóm 14.5",
//             "url": "/vi/blog/thiet-ke-web-quang-cao",
//             "display_name": "Thiết kế web quảng cáo thì có những tiêu chuẩn nào? - Gleads",
//             "keywords": ["quảng cáo web<<>>320<<>>13<<>>78"],
//             "kw_count": 1,
//             "value": 320
//           },
//           {
//             "group": "14",
//             "name": "Nhóm 14.6",
//             "url": "/vi/blog/thiet-ke-web-dep",
//             "display_name": "Thiết kế web đẹp giúp giữ chân người dung ở lại ...",
//             "keywords": ["web đẹp<<>>260<<>>92<<>>74"],
//             "kw_count": 1,
//             "value": 260
//           },
//           {
//             "group": "14",
//             "name": "Nhóm 14.7",
//             "url": "/vi/blog/thiet-ke-web-phong-thuy",
//             "display_name": "Thiết kế web phong thủy mang đậm chất tính ngưỡng - Gleads",
//             "keywords": ["web phong thủy<<>>140<<>>13<<>>19"],
//             "kw_count": 1,
//             "value": 140
//           }
//         ]
//       },
//       {
//         "name": "Nhóm 15",
//         "children": [
//           {
//             "group": "15",
//             "name": "Nhóm 15.1",
//             "url": "/vi/dich-vu-content-chuan-seo",
//             "display_name": "Dịch vụ viết bài SEO, Content chuẩn SEO chất lượng - Gleads",
//             "keywords": [
//               "dịch vụ viết bài chuẩn seo<<>>3600<<>>11<<>>28",
//               "dịch vụ viết bài<<>>590<<>>63<<>>26",
//               "dịch vụ viết content<<>>590<<>>63<<>>84",
//               "viết content chuẩn seo<<>>590<<>>33<<>>80",
//               "content chuẩn seo<<>>390<<>>29<<>>64",
//               "dịch vụ content marketing<<>>390<<>>10<<>>93",
//               "dịch vụ viết bài seo<<>>390<<>>11<<>>19",
//               "dịch vụ viết bài chuẩn seo giá rẻ<<>>90<<>>30<<>>36"
//             ],
//             "kw_count": 8,
//             "value": 6630
//           },
//           {
//             "group": "15",
//             "name": "Nhóm 15.2",
//             "url": "/vi/blog/dich-vu-seo-google-maps",
//             "display_name": "Dịch vụ SEO Google Maps lên top bền vững, thu hút khách hàng",
//             "keywords": [
//               "dịch vụ seo google map<<>>1000<<>>23<<>>61",
//               "dịch vụ seo google maps<<>>1000<<>>25<<>>39",
//               "dịch vụ seo map<<>>590<<>>25<<>>55",
//               "seo maps<<>>480<<>>13<<>>69",
//               "dịch vụ seo local<<>>320<<>>34<<>>38",
//               "seo maps google<<>>320<<>>13<<>>62"
//             ],
//             "kw_count": 6,
//             "value": 3710
//           },
//           {
//             "group": "15",
//             "name": "Nhóm 15.3",
//             "url": "/vi/blog/dich-vu-seo-youtube",
//             "display_name": "Dịch vụ SEO Youtube – Đẩy video lên TOP - Gleads",
//             "keywords": ["dịch vụ seo youtube<<>>320<<>>9<<>>12"],
//             "kw_count": 1,
//             "value": 320
//           },
//           {
//             "group": "15",
//             "name": "Nhóm 15.4",
//             "url": "/vi/blog/bao-gia-dich-vu-seo",
//             "display_name": "Báo giá dịch vụ SEO, SEO từ khóa, SEO tổng thể 2022 - Gleads",
//             "keywords": ["báo giá seo từ khóa<<>>260<<>>62<<>>41"],
//             "kw_count": 1,
//             "value": 260
//           },
//           {
//             "group": "15",
//             "name": "Nhóm 15.5",
//             "url": "/vi/dich-vu-quang-cao-youtube",
//             "display_name": "Dịch vụ quảng cáo Youtube hiệu quả, tiết kiệm ngân sách",
//             "keywords": [
//               "dịch vụ quảng cáo youtube<<>>260<<>>33<<>>6",
//               "quảng cáo youtube trọn gói<<>>20<<>>7<<>>5"
//             ],
//             "kw_count": 2,
//             "value": 280
//           }
//         ]
//       },
//       {
//         "name": "Nhóm 16",
//         "children": [
//           {
//             "group": "16",
//             "name": "Nhóm 16.1",
//             "url": "/vi/blog/cong-ty-thiet-ke-web",
//             "display_name": "Công ty thiết kế web hàng đầu tại Việt Nam - Gleads",
//             "keywords": ["công ty thiết kế web<<>>2900<<>>79<<>>71"],
//             "kw_count": 1,
//             "value": 2900
//           },
//           {
//             "group": "16",
//             "name": "Nhóm 16.2",
//             "url": "/vi/blog/thiet-ke-logo-cong-ty-xay-dung",
//             "display_name": "Thiết kế logo công ty xây dựng - Gleads",
//             "keywords": [
//               "logo công ty xây dựng<<>>880<<>>11<<>>28",
//               "logo xây dựng<<>>880<<>>11<<>>29",
//               "thiết kế logo công ty xây dựng<<>>880<<>>13<<>>29"
//             ],
//             "kw_count": 3,
//             "value": 2640
//           },
//           {
//             "group": "16",
//             "name": "Nhóm 16.3",
//             "url": "/vi/blog/cong-ty-quang-cao-google",
//             "display_name": "Công ty quảng cáo Google Uy Tín, Chuyên Nghiệp - Gleads",
//             "keywords": [
//               "cách chạy quảng cáo google miễn phí<<>>320<<>>25<<>>88",
//               "công ty quảng cáo google<<>>320<<>>10<<>>18",
//               "công ty chạy quảng cáo google<<>>110<<>>10<<>>17",
//               "chạy quảng cáo miễn phí trên google<<>>70<<>>19<<>>80",
//               "công ty chạy google adwords<<>>30<<>>11<<>>54",
//               "đơn vị chạy quảng cáo google<<>>20<<>>10<<>>69"
//             ],
//             "kw_count": 6,
//             "value": 870
//           }
//         ]
//       },
//       {
//         "name": "Nhóm 17",
//         "children": [
//           {
//             "group": "17",
//             "name": "Nhóm 17.1",
//             "url": "/vi/dich-vu-quang-cao-google",
//             "display_name": "Dịch vụ quảng cáo Google - Gleads",
//             "keywords": [
//               "quảng cáo google<<>>5400<<>>25<<>>34",
//               "chạy quảng cáo google<<>>2900<<>>15<<>>73",
//               "dịch vụ quảng cáo google<<>>1900<<>>61<<>>10",
//               "dịch vụ google ads<<>>480<<>>62<<>>15",
//               "quảng cáo google ads<<>>480<<>>30<<>>60",
//               "dịch vụ quảng cáo<<>>390<<>>58<<>>38",
//               "dịch vụ quảng cáo google ads<<>>320<<>>62<<>>10",
//               "dịch vụ quảng cáo google trọn gói<<>>70<<>>57<<>>13",
//               "quảng cáo google trọn gói<<>>70<<>>57<<>>12",
//               "quảng cáo trên google miễn phí<<>>50<<>>9<<>>57",
//               "tối ưu quảng cáo google adwords<<>>50<<>>11<<>>90",
//               "quảng cáo google adwords trọn gói<<>>30<<>>54<<>>52",
//               "dịch vụ chạy google adwords<<>>20<<>>55<<>>14",
//               "dịch vụ chạy quảng cáo google adwords<<>>20<<>>62<<>>15",
//               "quang cao google tron goi<<>>20<<>>54<<>>11",
//               "báo cáo quảng cáo google<<>>10<<>>22<<>>85"
//             ],
//             "kw_count": 16,
//             "value": 12210
//           },
//           {
//             "group": "17",
//             "name": "Nhóm 17.2",
//             "url": "/vi/dich-vu-cham-soc-website",
//             "display_name": "Dịch vụ chăm sóc website chuyên nghiệp [Báo Giá Mới] | Gleads",
//             "keywords": [
//               "dịch vụ chăm sóc web<<>>1300<<>>23<<>>59",
//               "dịch vụ chăm sóc website<<>>1300<<>>18<<>>60",
//               "cham soc website<<>>390<<>>14<<>>72",
//               "chăm sóc web<<>>390<<>>13<<>>72",
//               "chăm sóc website<<>>390<<>>18<<>>73"
//             ],
//             "kw_count": 5,
//             "value": 3770
//           },
//           {
//             "group": "17",
//             "name": "Nhóm 17.3",
//             "url": "/vi/blog/content-quang-cao",
//             "display_name": "Cách viết content quảng cáo - 22 mẫu quảng cáo áp dụng đa ...",
//             "keywords": [
//               "bài viết quảng cáo mẫu<<>>880<<>>11<<>>38",
//               "content mẫu<<>>480<<>>9<<>>14",
//               "mẫu quảng cáo<<>>480<<>>23<<>>23",
//               "mẫu bài viết quảng cáo mỹ phẩm<<>>390<<>>10<<>>82",
//               "mẫu content hay<<>>390<<>>9<<>>17",
//               "mẫu content feedback khách hàng<<>>320<<>>9<<>>42",
//               "bài content mẫu<<>>260<<>>10<<>>33",
//               "công thức sss<<>>260<<>>9<<>>34",
//               "mẫu quảng cáo sản phẩm<<>>260<<>>13<<>>37",
//               "mẫu viết content<<>>260<<>>10<<>>32",
//               "quang cao hay<<>>260<<>>11<<>>58",
//               "quảng cáo hay<<>>260<<>>11<<>>59",
//               "viết quang cáo<<>>170<<>>23<<>>17"
//             ],
//             "kw_count": 13,
//             "value": 4670
//           },
//           {
//             "group": "17",
//             "name": "Nhóm 17.4",
//             "url": "/vi/dich-vu-quang-cao-facebook",
//             "display_name": "Dịch vụ quảng cáo Facebook uy tín, hiệu quả - Gleads",
//             "keywords": [
//               "dịch vụ chạy quảng cáo facebook<<>>720<<>>42<<>>7",
//               "dịch vụ quảng cáo facebook<<>>720<<>>29<<>>6"
//             ],
//             "kw_count": 2,
//             "value": 1440
//           },
//           {
//             "group": "17",
//             "name": "Nhóm 17.5",
//             "url": "/vi/blog/chi-phi-chay-quang-cao-facebook",
//             "display_name": "Chi phí chạy quảng cáo Facebook gồm có những khoản nào?",
//             "keywords": [
//               "bảng giá chạy quảng cáo trên facebook<<>>590<<>>9<<>>20",
//               "chi phí chạy quảng cáo facebook<<>>480<<>>9<<>>33",
//               "chi phí quảng cáo trên facebook<<>>320<<>>13<<>>38",
//               "chi phí quảng cáo facebook<<>>170<<>>17<<>>62",
//               "giá chạy quảng cáo trên facebook<<>>140<<>>13<<>>21",
//               "phí chạy quảng cáo facebook<<>>140<<>>13<<>>22",
//               "cách tính chi phí quảng cáo facebook<<>>90<<>>11<<>>58",
//               "chi phí quảng cáo trên facebook là bao nhiêu<<>>40<<>>13<<>>19"
//             ],
//             "kw_count": 8,
//             "value": 1970
//           },
//           {
//             "group": "17",
//             "name": "Nhóm 17.6",
//             "url": "/vi/dich-vu-email-marketing",
//             "display_name": "[Báo giá] Dịch vụ Email Marketing hiệu quả, chuyên sâu",
//             "keywords": ["mẫu email gửi báo giá khách hàng<<>>590<<>>11<<>>83"],
//             "kw_count": 1,
//             "value": 590
//           },
//           {
//             "group": "17",
//             "name": "Nhóm 17.7",
//             "url": "/vi/blog/quang-cao-gdn",
//             "display_name": "Quảng cáo GDN hiệu quả, chính xác cho doanh nghiệp - Gleads",
//             "keywords": ["quảng cáo gdn<<>>210<<>>11<<>>60"],
//             "kw_count": 1,
//             "value": 210
//           },
//           {
//             "group": "17",
//             "name": "Nhóm 17.8",
//             "url": "/vi/blog/quang-cao-tham-do-y-kien-facebook",
//             "display_name": "Quảng cáo thăm dò ý kiến Facebook, bước đi chiến lượt quan ...",
//             "keywords": ["tạo cuộc thăm dò trên messenger<<>>170<<>>21<<>>45"],
//             "kw_count": 1,
//             "value": 170
//           },
//           {
//             "group": "17",
//             "name": "Nhóm 17.9",
//             "url": "/vi/blog/thue-chay-quang-cao-facebook",
//             "display_name": "Thuê Chạy Quảng Cáo Facebook Với Chuyên Gia Trong Và ...",
//             "keywords": [
//               "cho thuê tài khoản quảng cáo facebook<<>>110<<>>7<<>>30",
//               "chạy thuê quảng cáo facebook chiết khấu<<>>20<<>>9<<>>37"
//             ],
//             "kw_count": 2,
//             "value": 130
//           }
//         ]
//       },
//       {
//         "name": "Nhóm 18",
//         "children": [
//           {
//             "group": "18",
//             "name": "Nhóm 18.1",
//             "url": "/vi/blog/bang-bao-gia-thiet-ke-landing-page",
//             "display_name": "Bảng báo giá thiết kế Landing Page cập nhật mới nhất - Gleads",
//             "keywords": [
//               "landing page<<>>33100<<>>35<<>>86",
//               "thiết kế landing page<<>>1600<<>>41<<>>19",
//               "dịch vụ thiết kế landing page<<>>590<<>>42<<>>23",
//               "landing page website<<>>390<<>>25<<>>79",
//               "làm landing page<<>>320<<>>15<<>>37",
//               "làm landingpage<<>>320<<>>15<<>>63",
//               "giá thiết kế landing page<<>>70<<>>11<<>>1",
//               "thiết kế landing page giá rẻ<<>>30<<>>13<<>>1"
//             ],
//             "kw_count": 8,
//             "value": 36420
//           },
//           {
//             "group": "18",
//             "name": "Nhóm 18.2",
//             "url": "/vi/blog/bang-gia-thiet-ke-website-tron-goi",
//             "display_name": "Bảng báo giá thiết kế website trọn gói chuyên sâu - Gleads",
//             "keywords": [
//               "giá thiết kế web<<>>880<<>>59<<>>22",
//               "giá thiết kế website<<>>880<<>>69<<>>38",
//               "dịch vụ thiết kế website trọn gói<<>>590<<>>97<<>>21",
//               "báo giá thiết kế website<<>>390<<>>59<<>>23",
//               "làm web trọn gói<<>>390<<>>100<<>>38",
//               "làm website trọn gói<<>>390<<>>100<<>>26",
//               "bảng giá thiết kế web<<>>260<<>>59<<>>32",
//               "bảng giá thiết kế website<<>>260<<>>61<<>>24",
//               "thiết kế website trọn gói giá rẻ<<>>260<<>>100<<>>90",
//               "thiết kế website cho doanh nghiệp trọn gói<<>>170<<>>59<<>>33",
//               "báo giá thiết kế website trọn gói<<>>140<<>>59<<>>22",
//               "giá thiết kế website trọn gói<<>>140<<>>82<<>>26",
//               "bảng giá thiết kế website trọn gói<<>>110<<>>59<<>>26"
//             ],
//             "kw_count": 13,
//             "value": 4860
//           },
//           {
//             "group": "18",
//             "name": "Nhóm 18.3",
//             "url": "/vi/thiet-ke-landing-page",
//             "display_name": "Dịch vụ thiết kế Landing Page chuẩn SEO tối đa lợi nhuận",
//             "keywords": ["landing page chuẩn seo<<>>30<<>>13<<>>44"],
//             "kw_count": 1,
//             "value": 30
//           }
//         ]
//       },
//       {
//         "name": "Nhóm 19",
//         "children": [
//           {
//             "group": "19",
//             "name": "Nhóm 19.1",
//             "url": "/vi/thiet-ke-bo-nhan-dien-thuong-hieu",
//             "display_name": "Thiết Kế Bộ Nhận Diện Thương Hiệu Chuyên Nghiệp Nhất",
//             "keywords": [
//               "bộ nhận diện thuong hieu<<>>4400<<>>29<<>>83",
//               "bộ nhận diện thương hiệu<<>>4400<<>>21<<>>88",
//               "thiết kế bộ nhận diện thương hiệu<<>>880<<>>46<<>>50",
//               "thiết kế nhận diện thương hiệu<<>>590<<>>35<<>>64",
//               "thiet ke thuong hieu<<>>480<<>>15<<>>58",
//               "thiết kế thương hiệu<<>>480<<>>15<<>>95"
//             ],
//             "kw_count": 6,
//             "value": 11230
//           },
//           {
//             "group": "19",
//             "name": "Nhóm 19.2",
//             "url": "/vi/thiet-ke-bo-sale-kit",
//             "display_name": "Thiết kế bộ sale kit cho phòng kinh doanh - Gleads",
//             "keywords": [
//               "sale kit<<>>590<<>>9<<>>96",
//               "sales kit<<>>390<<>>9<<>>93"
//             ],
//             "kw_count": 2,
//             "value": 980
//           }
//         ]
//       },
//       {
//         "name": "Nhóm 20",
//         "children": [
//           {
//             "group": "20",
//             "name": "Nhóm 20.1",
//             "url": "/vi/blog/thiet-ke-website-bat-dong-san",
//             "display_name": "Thiết kế website bất động sản, nhà đất chuyên sâu - Gleads",
//             "keywords": [
//               "thiết kế web bất động sản<<>>1600<<>>46<<>>78",
//               "thiết kế website bất động sản<<>>1600<<>>70<<>>77",
//               "thiet ke web bat dong san<<>>880<<>>69<<>>64",
//               "thiet ke website bat dong san<<>>880<<>>69<<>>61"
//             ],
//             "kw_count": 4,
//             "value": 4960
//           },
//           {
//             "group": "20",
//             "name": "Nhóm 20.2",
//             "url": "/vi/blog/thiet-ke-logo-bat-dong-san",
//             "display_name": "Thiết kế logo bất động sản chất lượng - Gleads",
//             "keywords": [
//               "logo bất đọng sản<<>>880<<>>11<<>>49",
//               "logo bđs<<>>260<<>>11<<>>54",
//               "logo barber chất<<>>210<<>>10<<>>44"
//             ],
//             "kw_count": 3,
//             "value": 1350
//           }
//         ]
//       }
//     ]
//   }

const BubleChart = (props) => {
    const ref = useRef();
    const data = props.data;
    useEffect(() => {
        const width = 1000;
        const height = 400;
        // console.log(props.data)
        const d = d3.hierarchy(data)
                  .sum((datum) => datum.value)
                  .sort((a, b) => a.value- b.value);
        // console.log(d)
        const pack = (m) => d3.pack().size([width - 100, height - 100]).padding(1)(m);
        function forceCluster() {
            const strength = 0.2;
            let nodes;
            
            function force(alpha) {
                const centroids = d3.rollup(nodes, centroid, (d) => d.data.group);
                const l = alpha * strength;
                for (const d of nodes) {
                const { x: cx, y: cy } = centroids.get(d.data.group);
                d.vx -= (d.x - cx) * l;
                d.vy -= (d.y - cy) * l;
                }
            }
            
            force.initialize = (_) => (nodes = _);
            
            return force;
            }
            
        function forceCollide() {
          const alpha = 0.4; // fixed for greater rigidity!
          const padding1 = 2; // separation between same-color nodes
          const padding2 = 6; // separation between different-color nodes
          let nodes;
          let maxRadius;
          
          function force() {
              const quadtree = d3.quadtree(
              nodes,
              (d) => d.x,
              (d) => d.y
              );
              for (const d of nodes) {
              const r = d.r + maxRadius;
              const nx1 = d.x - r,
                  ny1 = d.y - r;
              const nx2 = d.x + r,
                  ny2 = d.y + r;
              quadtree.visit((q, x1, y1, x2, y2) => {
                  if (!q.length)
                  do {
                      if (q.data !== d) {
                      const r =
                          d.r +
                          q.data.r +
                          (d.data.group === q.data.group ? padding1 : padding2);
                      let x = d.x - q.data.x,
                          y = d.y - q.data.y,
                          l = Math.hypot(x, y);
                      if (l < r) {
                          l = ((l - r) / l) * alpha;
                          (d.x -= x *= l);
                          (d.y -= y *= l);
                          (q.data.x += x);
                          (q.data.y += y);
                      }
                      }
                  } while ((q = q.next));
                  return x1 > nx2 || x2 < nx1 || y1 > ny2 || y2 < ny1;
              });
              }
        }
        
        force.initialize = (_) =>
            (maxRadius =
            d3.max((nodes = _), (d) => d.r) + Math.max(padding1, padding2));
        
        return force;
        }
        
        function centroid(nodes) {
          let x = 0;
          let y = 0;
          let z = 0;
          for (const d of nodes) {
              let k = d.r ** 2;
              x += d.x * k;
              y += d.y * k;
              z += k;
          }
          return { x: x / z, y: y / z };
        }
        
        const nodes = pack(d).leaves();
        // console.log(nodes)
        const simulation = d3
                .forceSimulation(nodes)
                //   .force("charge", d3.forceManyBody())
                //   .force("x", d3.forceX().x(d => d.x).strength(0.1))
                //   .force("y", d3.forceY(height / 2).strength(0.01))
                //   .force("cluster", forceCluster())
                .force("collide", forceCollide());
        // tooltip
        const tooltip = d3.select('#tooltip');

        // create svg
        const container = d3.select(ref.current)

        container.selectAll('svg').remove()

        const svg = container.append("svg")
                            .attr("width", width)
                            .attr("height", height)
                            .style('overflow', 'visible');
        
        // const zoom = d3.zoom().scaleExtent([1,10]).on('zoom', zoomed)
        const gContainer = svg.append('g')
                        .attr('width', width - 100)
                        .attr('height', height - 100)
                        // .attr('transform',`translate(50,50)`)
                        .attr('fill', '#f7f7f7')
                        .attr('pointer-events', 'all')
                        // .call(zoom)
                        
        
        // function zoomed() {
        //     gContainer.attr('transform', d3.zoomTransform(this));
        // }
        
        const colorScale = d3.scaleSequential(d3.interpolateSpectral)
            .domain([1, 20]);
        
        // scales
        const xScales = d3.scaleLog()
        //                 .domain(d3.extent(nodes, (d) => d.x))
        //                 .range([0, (width - 100)])
        //                 .clamp(true);
        
        // const yScales = d3.scaleLog()
        //                 .domain(d3.extent(nodes, (d) => d.y))
        //                 .range([0, (height - 100)])
        //                 .clamp(true);
        
        const rAccessor = (d) => d.r
        
        const rScales = d3.scaleLinear()
                        .domain(d3.extent(nodes, rAccessor))
                        .range([10, 60])
                        // .clamp(true);
        
        const node = gContainer
        //   .append("g")
            .selectAll("circle")
            .data(nodes)
            .join("circle")
            .attr("cx", (d) => d.x)
            .attr("cy", (d) => d.y)
            .attr('r', (d) => rScales(rAccessor(d)))
            .attr("fill", (d) => colorScale(d.data.group))
            .on("mouseenter", function(event, datum){
            // console.log(datum)
            const formatter = d3.format('.2f')
            d3.select(this)
                // .attr('fill', '#120078')
                .attr('r', datum.r*1.2)
            tooltip.style('display', 'block')
                .style('top', datum.y - 25 + 'px')
                .style('left', datum.x + datum.r*2 + 50 + 'px')
            tooltip.select('.group-name span')
                .text(datum.data.group)
            tooltip.select('.group-name .volume')
                .text(datum.parent.value.toLocaleString('en-us'))
            tooltip.select('.group-name .topic')
                .text(datum.parent.children.length.toLocaleString('en-us'))
            // tooltip.select('.topic-value span')
            //     .text(datum.value)
            tooltip.select('.title span')
                .text(datum.data.display_name)
            tooltip.select('.url span')
                .text(datum.data.url)
            tooltip.select('.topic-volume span')
                .text(datum.data.value.toLocaleString('en-us'))
            })
            .on('mouseleave', function(event, datum){
            d3.select(this)
                .attr('r', datum.r/1.2)
            tooltip.style('display','none')
            })
            .on("mousedown", function(event, datum){
            changeTopicHandler(datum)
            });
        
        // const rect = svg.append("rect")
        //   .attr('fill', 'none')
        //   .attr('width',  width)
        //   .attr('height',height)
        //   .attr('pointer-events', 'fill')
        //   .call(zoom);
        const changeTopicHandler = (data) => {
          props.changeActiveTopic(data)
        };
        node
            .transition()
            .delay((d, i) => Math.random() * 500)
            .duration(750)
            .attrTween("r", (d) => {
            const i = d3.interpolate(0, d.r);
            return (t) => (d.r = i(t));
            });
        
        const clusters = d3.scalePoint()
            .domain(nodes.map(n => parseInt(n.data.group)))
            .range([100, width - 100]);
        
        function ticked() {
            const k = this.alpha() * 0.3;
            // Define the fixed distance between clusters on the x-axis
            const clusterSpacing = 10;
        
            // Calculate the total width required by all clusters
            const totalClusterWidth = clusterSpacing * 19; // Assuming 20 clusters (0 to 19)
        
            // Calculate the available width for positioning clusters
            const availableWidth = width - totalClusterWidth - 100;
        
            // Calculate the starting x-position for the first cluster
            const initialX = 0;
            console.log(initialX)
            //move the nodes to their foci/cluster
            nodes.forEach(function(n, i) {
                // const targetX = initialX + clusters(parseInt(n.data.group)) + (clusterSpacing * parseInt(n.data.group));
        
                // Update the x and y coordinates of the node
                // n.x += (targetX - n.x) * k;
                n.x += (clusters(parseInt(n.data.group)) - n.x ) * k;
                n.y += ((height - 100)/2 - n.y) * k;
                });
            //update coordinates for the circle
            node.attr("cx", d => d.x)
                .attr("cy", d => d.y)
            };
        const xAxis = d3.axisBottom(clusters)
        const xAxisGroup = gContainer.append('g')
                                    .call(xAxis)
                                    .style('transform', `translateY(${height-100}px)`)
                                    .classed('axis', true)
        xAxisGroup.append('text')
                  .attr('x', (width - 100)/2)
                  .attr('y', 40)
                  .attr('fill', 'black')
                  .text('Nhóm')
        
        // simulation.on("tick", () => {
        //   node.attr("cx", (d) => d.x).attr("cy", (d) => d.y);
        // });
        simulation
                .nodes(nodes)
                .on("tick", ticked);
    }, [data]);
    return (
      <div class='canvas' ref={ref}>
        <h5 id='chart-title'>Topic Clusters Visualized</h5>
        <div id='tooltip'>
          <div class='group-name d-flex align-items-center'>
            Nhóm <span className='ms-2 me-auto'></span>
            <FontAwesomeIcon icon={faLineChart} className='mx-2'/> <span class='volume me-2'></span>
            Topics: <span class='topic ms-2'></span>
          </div>
          <div class='title'>
            <span style={{fontWeight: 'bold'}}></span>
          </div>
          <div class='url'>
            <span></span>
          </div>
          <div class='topic-volume'>
          <FontAwesomeIcon icon={faLineChart} className='mx-2'/> <span></span>
          </div>
        </div>
      </div>
    )
    
};

export default BubleChart;