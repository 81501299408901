import { useState, Fragment, useRef, useEffect } from "react";
import {showLoading, hideLoading } from 'react-redux-loading-bar';
import { useDispatch } from "react-redux";
import Accordion from 'react-bootstrap/Accordion';

import CartBoardEditor, { Question } from "../components/cart_editor";


import "bootstrap/dist/css/bootstrap.min.css";
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import * as bootstrap from 'bootstrap/dist/js/bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash, faDownload, faRefresh, faAngleDoubleLeft, faAngleDoubleRight, faSave, faCheckCircle, faTimesCircle, faUpRightFromSquare, faAngleUp, faAngleDown, faSearch, faCaretDown, faCaretRight, faLevelUp, faFilter, faCompressArrowsAlt, faCompressAlt, faAdd, faPlusCircle, faPencil, faMinus
} from "@fortawesome/free-solid-svg-icons";
import { faWordpress } from '@fortawesome/free-brands-svg-icons';

import { Editor } from "@tinymce/tinymce-react";
import { getAuthToken } from "../util/token";
import { useSearchParams } from "react-router-dom";
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { v4 as uuid4 } from 'uuid';


const ADD_HEADING = [];
const leg = [
  {
      "legislation": "Nghị định 01/2021/NĐ-CP",
      "title": "Nghị định 01/2021/NĐ-CP về đăng ký doanh nghiệp",
      "description": "01/2021/ND-CP,Nghị định 01 2021 Chính phủ,Nghị định 01 năm 2021 Chính phủ,Nghị định 01 Chính phủ ban hành 2021,NĐ 01 Chính phủ còn hiệu lực,NĐ 01 Chính phủ áp dụng 2021,NĐ 01 Chính phủ mới nhất,NĐ số 01 năm 2021 Chính phủ,Nghị định 01/2021/ND-CP ngày 04/01/2021,Nghị định 01 Chính phủ về Đăng ký doanh nghiệp mới 2021,NĐ 01 Chính phủ về Đăng ký",
      "link": "https://thuvienphapluat.vn/van-ban/Doanh-nghiep/Nghi-dinh-01-2021-ND-CP-dang-ky-doanh-nghiep-283247.aspx",
      "Ban hành": "04/01/2021",
      "Hiệu lực": "04/01/2021",
      "Tình trạng": "Còn hiệu lực",
      "Cập nhật": "11/01/2021",
      "domain": "ropresso.vn",
      "heading": "Thủ tục đăng ký kinh doanh cho quán cafe",
      "section": "Điều 87"
  },
  {
      "legislation": "Thông tư 01/2021/TT-BKHĐT",
      "title": "Thông tư 01/2021/TT-BKHĐT hướng dẫn về đăng ký doanh nghiệp do Bộ trưởng Bộ Kế hoạch và Đầu tư ban hành",
      "description": "01/2021/TT-BKHDT,Thông tư 01 2021 Bộ Kế hoạch và Đầu tư,Thông tư 01 năm 2021 Bộ Kế hoạch và Đầu tư,Thông tư 01 Bộ Kế hoạch và Đầu tư ban hành 2021,TT 01 Bộ Kế hoạch và Đầu tư còn hiệu lực,TT 01 Bộ Kế hoạch và Đầu tư áp dụng 2021,TT 01 Bộ Kế hoạch và Đầu tư mới nhất,TT số 01 năm 2021 Bộ Kế hoạch và Đầu tư,Thông tư 01/2021/TT-BKHDT ngày",
      "link": "https://thuvienphapluat.vn/van-ban/Doanh-nghiep/Thong-tu-01-2021-TT-BKHDT-huong-dan-dang-ky-doanh-nghiep-465911.aspx",
      "Ban hành": "16/03/2021",
      "Hiệu lực": "01/05/2021",
      "Tình trạng": "Còn hiệu lực",
      "Cập nhật": "23/03/2021",
      "domain": "ropresso.vn",
      "heading": "Thủ tục đăng ký kinh doanh cho quán cafe",
      "section": "Phụ lục III-1"
  },
  {
      "legislation": "Thông tư 85/2019/TT-BTC",
      "title": "Thông tư 85/2019/TT-BTC hướng dẫn về phí và lệ phí thuộc thẩm quyền quyết định của Hội đồng nhân dân tỉnh, thành phố trực thuộc Trung ương do Bộ trưởng Bộ Tài chính ban hành",
      "description": "85/2019/TT-BTC,Thông tư 85 2019 Bộ Tài chính,Thông tư 85 năm 2019 Bộ Tài chính,Thông tư 85 Bộ Tài chính ban hành 2019,TT 85 Bộ Tài chính còn hiệu lực,TT 85 Bộ Tài chính áp dụng 2019,TT 85 Bộ Tài chính mới nhất,TT số 85 năm 2019 Bộ Tài chính,Thông tư 85/2019/TT-BTC ngày 29/11/2019,Thông tư 85 Bộ Tài chính về Hội đồng nhân dân tỉnh 2019,TT 85 Bộ Tài",
      "link": "https://thuvienphapluat.vn/van-ban/Thue-Phi-Le-Phi/Thong-tu-85-2019-TT-BTC-huong-dan-phi-va-le-phi-tham-quyen-quyet-dinh-Hoi-dong-nhan-dan-tinh-431619.aspx",
      "Ban hành": "29/11/2019",
      "Hiệu lực": "13/01/2020",
      "Tình trạng": "Còn hiệu lực",
      "Cập nhật": "24/12/2019",
      "domain": "ropresso.vn",
      "heading": "Thủ tục đăng ký kinh doanh cho quán cafe",
      "section": ""
  },
  {
      "legislation": "Nghị định 15/2018/NĐ-CP",
      "title": "Nghị định 15/2018/NĐ-CP hướng dẫn Luật an toàn thực phẩm",
      "description": "15/2018/ND-CP,Nghị định 15 2018 Chính phủ,Nghị định 15 năm 2018 Chính phủ,Nghị định 15 Chính phủ ban hành 2018,NĐ 15 Chính phủ còn hiệu lực,NĐ 15 Chính phủ áp dụng 2018,NĐ 15 Chính phủ mới nhất,NĐ số 15 năm 2018 Chính phủ,Nghị định 15/2018/ND-CP ngày 02/02/2018,Nghị định 15 Chính phủ về An toàn thực phẩm 2018,NĐ 15 Chính phủ về An toàn thực phẩm",
      "link": "https://thuvienphapluat.vn/van-ban/The-thao-Y-te/Nghi-dinh-15-2018-ND-CP-huong-dan-Luat-an-toan-thuc-pham-341254.aspx",
      "Ban hành": "02/02/2018",
      "Hiệu lực": "02/02/2018",
      "Tình trạng": "Còn hiệu lực",
      "Cập nhật": "05/02/2018",
      "domain": "ropresso.vn",
      "heading": "Thủ tục đăng ký kinh doanh cho quán cafe",
      "section": "Điều 11, 12"
  },
  {
      "legislation": "Nghị định số 79/2014/NĐ-CP",
      "title": "Nghị định 79/2014/NĐ-CP hướng dẫn Luật Phòng cháy và chữa cháy và Luật Phòng cháy và chữa cháy sửa đổi",
      "description": "79/2014/ND-CP,Nghị định 79 2014 Chính phủ,Nghị định 79 năm 2014 Chính phủ,Nghị định 79 Chính phủ ban hành 2014,NĐ 79 Chính phủ còn hiệu lực,NĐ 79 Chính phủ áp dụng 2014,NĐ 79 Chính phủ mới nhất,NĐ số 79 năm 2014 Chính phủ,Nghị định 79/2014/ND-CP ngày 31/07/2014,Nghị định 79 Chính phủ về Hướng dẫn Luật phòng cháy và chữa cháy 2014,NĐ 79 Chính phủ",
      "link": "https://thuvienphapluat.vn/van-ban/Tai-nguyen-Moi-truong/Nghi-dinh-79-2014-ND-CP-huong-dan-Luat-Phong-chua-chay-va-Luat-Phong-hua-chay-sua-doi-241914.aspx",
      "Ban hành": "31/07/2014",
      "Hiệu lực": "15/09/2014",
      "Tình trạng": "Hết hiệu lực: 10/01/2021",
      "Cập nhật": "01/08/2014",
      "domain": "accgroup.vn",
      "heading": "10. Quán cà phê có cần giấy phép phòng cháy chữa cháy?",
      "section": "Phụ lục I"
  },
  {
      "legislation": "Nghị định số 79/2014/NĐ-CP",
      "title": "Nghị định 79/2014/NĐ-CP hướng dẫn Luật Phòng cháy và chữa cháy và Luật Phòng cháy và chữa cháy sửa đổi",
      "description": "79/2014/ND-CP,Nghị định 79 2014 Chính phủ,Nghị định 79 năm 2014 Chính phủ,Nghị định 79 Chính phủ ban hành 2014,NĐ 79 Chính phủ còn hiệu lực,NĐ 79 Chính phủ áp dụng 2014,NĐ 79 Chính phủ mới nhất,NĐ số 79 năm 2014 Chính phủ,Nghị định 79/2014/ND-CP ngày 31/07/2014,Nghị định 79 Chính phủ về Hướng dẫn Luật phòng cháy và chữa cháy 2014,NĐ 79 Chính phủ",
      "link": "https://thuvienphapluat.vn/van-ban/Tai-nguyen-Moi-truong/Nghi-dinh-79-2014-ND-CP-huong-dan-Luat-Phong-chua-chay-va-Luat-Phong-hua-chay-sua-doi-241914.aspx",
      "Ban hành": "31/07/2014",
      "Hiệu lực": "15/09/2014",
      "Tình trạng": "Hết hiệu lực: 10/01/2021",
      "Cập nhật": "01/08/2014",
      "domain": "accgroup.vn",
      "heading": "10. Quán cà phê có cần giấy phép phòng cháy chữa cháy?",
      "section": "Phụ lục I"
  },
  {
      "legislation": "Thông tư số 66/2014/TT-BCA",
      "title": "Thông tư 66/2014/TT-BCA hướng dẫn Nghị định 79/2014/NĐ-CP hướng dẫn Luật Phòng cháy và chữa cháy và Luật Phòng cháy và chữa cháy sửa đổi do Bộ Công an ban hành",
      "description": "66/2014/TT-BCA,Thông tư 66 2014 Bộ Công An,Thông tư 66 năm 2014 Bộ Công An,Thông tư 66 Bộ Công An ban hành 2014,TT 66 Bộ Công An còn hiệu lực,TT 66 Bộ Công An áp dụng 2014,TT 66 Bộ Công An mới nhất,TT số 66 năm 2014 Bộ Công An,Thông tư 66/2014/TT-BCA ngày 16/12/2014,Thông tư 66 Bộ Công An về Công tác phòng cháy và chữa cháy 2014,TT 66 Bộ Công An",
      "link": "https://thuvienphapluat.vn/van-ban/Van-hoa-Xa-hoi/Thong-tu-66-2014-TT-BCA-huong-dan-Nghi-dinh-79-2014-ND-CP-Phong-chay-va-chua-chay-263088.aspx",
      "Ban hành": "16/12/2014",
      "Hiệu lực": "06/02/2015",
      "Tình trạng": "Hết hiệu lực: 20/02/2021",
      "Cập nhật": "15/01/2015",
      "domain": "accgroup.vn",
      "heading": "10. Quán cà phê có cần giấy phép phòng cháy chữa cháy?",
      "section": "Khoản 5, Điều 10"
  },
  {
      "legislation": "Nghị định 124/2015/NĐ-CP",
      "title": "Nghị định 124/2015/NĐ-CP sửa đổi Nghị định 185/2013/NĐ-CP quy định xử phạt vi phạm hành chính trong hoạt động thương mại, sản xuất, buôn bán hàng giả, hàng cấm và bảo vệ quyền lợi người tiêu dùng",
      "description": "VIỆT NAM Độc lập - Tự do - Hạnh phúc --------------- Số: 124/2015/NĐ-CP Hà Nội, ngày 19 tháng 11 năm 2015 NGHỊ ĐỊNH SỬA ĐỔI, BỔ SUNG MỘT SỐ ĐIỀU CỦA NGHỊ ĐỊNH SỐ 185/2013/NĐ-CP NGÀY 15 THÁNG 11 NĂM 2013 CỦA CHÍNH PHỦ QUY ĐỊNH XỬ PHẠT VI PHẠM HÀNH CHÍNH TRONG HOẠT ĐỘNG",
      "link": "https://thuvienphapluat.vn/van-ban/Thuong-mai/Nghi-dinh-124-2015-ND-CP-sua-doi-185-2013-ND-CP-xu-phat-vi-pham-hanh-chinh-san-xuat-buon-ban-hang-gia-cam-295892.aspx",
      "Ban hành": "19/11/2015",
      "Hiệu lực": "05/01/2016",
      "Tình trạng": "Hết hiệu lực: 15/10/2020",
      "Cập nhật": "20/11/2015",
      "domain": "accgroup.vn",
      "heading": "11. Mở quán cafe không đăng ký kinh doanh có bị xử phạt không?",
      "section": "khoản 3 điều 6"
  },
  {
      "legislation": "Nghị định số 185/2013/NĐ-CP",
      "title": "Nghị định 185/2013/NĐ-CP quy định xử phạt vi phạm hành chính trong hoạt động thương mại, sản xuất, buôn bán hàng giả, hàng cấm và bảo vệ quyền lợi người tiêu dùng",
      "description": "185/2013/ND-CP,Nghị định 185 2013 Chính phủ,Nghị định 185 năm 2013 Chính phủ,Nghị định 185 Chính phủ ban hành 2013,NĐ 185 Chính phủ còn hiệu lực,NĐ 185 Chính phủ áp dụng 2013,NĐ 185 Chính phủ mới nhất,NĐ số 185 năm 2013 Chính phủ,Nghị định 185/2013/ND-CP ngày 15/11/2013 CHÍNH PHỦ ------- CỘNG HÒA XÃ HỘI",
      "link": "https://thuvienphapluat.vn/van-ban/Thuong-mai/Nghi-dinh-185-2013-ND-CP-xu-phat-hanh-chinh-hoat-dong-thuong-mai-san-xuat-bao-ve-nguoi-tieu-dung-213915.aspx",
      "Ban hành": "15/11/2013",
      "Hiệu lực": "01/01/2014",
      "Tình trạng": "Hết hiệu lực: 15/10/2020",
      "Cập nhật": "22/11/2013",
      "domain": "accgroup.vn",
      "heading": "11. Mở quán cafe không đăng ký kinh doanh có bị xử phạt không?",
      "section": "Khoản 2 Điều 6"
  }
];

const legal_notes = [
  {'topic_cat': 'abc', 'topic': 'quyết định thành lập công ty', 'legislation': 'Nghị định 01/2021/NĐ-CP', 'section': 'Phụ lục III-1', 'note': 'note content 1', 'heading': 'heading 1'},
  {'topic_cat': 'abc', 'topic': 'quyết định thành lập công ty', 'legislation': 'Nghị định 01/2021/NĐ-CP', 'section': 'Phụ lục III-1', 'note': 'note content 2', 'heading': 'heading 2'},
  {'topic_cat': 'abc', 'topic': 'quyết định thành lập công ty', 'legislation': 'Nghị định 01/2021/NĐ-CP', 'section': 'Phụ lục III-1', 'note': 'note content 3', 'heading': 'heading 3'},
]
  

function OutlineEditor() {

  const token = getAuthToken();
  const userName = localStorage.getItem('username');

  const [initHeadings, setInitHeadings] = useState([]);
  const [initQuestions, setInitQuestions] = useState([]);
  const [headings, setHeadings] = useState(ADD_HEADING);
  const [active, setActive] = useState(null);
  const [liIndex, setIndex] = useState(null);
  const [disabled, setDisabled] = useState([]); //gray out when move heading or content from left to right
  const [editable, setEditable] = useState(null);
  const [detail, setDetail] = useState(false);
  const [dateCrawled, setDateCrawled] = useState('');
  const [location, setLocation] = useState('Location');
  const [forumFilter, setForumFilter] = useState('All');
  const [tab, setTab] = useState('serp');
  const [isLoading, setIsLoading] = useState(false);
  const [activeJSRender, setActiveJSRender] = useState(0);
  const pageRef = useRef([]);
  const [keywords, setKeywords] = useState([])
  const [sort,setSort] = useState('');
  const [open, setOpen] = useState([]);
  const [stats, setStats] = useState([]);
  const [kwDensity, setKwDensity] = useState([]);
  const [enrichTop, setEnrichTop] = useState(1);
  const [filterArr, setFilterArr] = useState([{
    id: uuid4(),heading: 'heading', operation: 'contains', value: ''
  }]);
  const [currentNoteList, setCurrentNoteList] = useState([]);

  const [legislation, setLegislation] = useState([]);
  const regulation = useRef('');
  const section = useRef('');
  const notes = useRef('');
  const headingNote = useRef('');
  const category = useRef('');
  const searchNotesContent = useRef('');
  const [allCategories, setAllCategories] = useState([]);
  const [allTopics, setAllTopics] = useState([]);
  const [allRegulations, setAllRegulations] = useState([])
  const [allNotes, setAllNotes] = useState([]);
  const [refNotes, setRefNotes] = useState([]);
  const [filteredRefNotes, setFilteredRefNotes] = useState([]);
  const [images, setImages] = useState([]);
  const [imageMode, setImageMode] = useState('pixabay');
  const fileUpload = useRef(null);
  const pixaBayClose = useRef(null);

  //save wordpress
  const wTitle = useRef('');
  const [wCategory, setWCategory] = useState([]);
  const wordpressModal = useRef(null);
  const [totalWPCategory, setTotalWPCategory] = useState([]);
  const [wpSite, setWPSite] = useState('');

  // TinyMCE Editor
  const editorRef = useRef(null);
  const [initialValue, setInitialValue] = useState('');

  //activePage mover
  const [activePage, setActivePage] = useState(1);
  //Gen outline
  const [totalReferences, setTotalReferences] = useState([]);
  const [legalRefs, setLegalRefs] = useState([]);
  //translate heading
  const [outlineLang, setOutlineLang] = useState('');
  const [editorLang, setEditorLang] = useState('');

  //save popup
  const topic = useRef('');
  const domain = useRef('');
  const description = useRef('');
  const [importStat, setImportStat] = useState('');
  const [taskId, setTaskId] = useState('');
  const [autoSave, setAutoSave] = useState('');
  const [isContentChanged, setIsContentChanged] = useState(false);

  //wordpress
  const [wordpressAction, setWordpressAction] = useState('');

  
  const search_key = useRef();

  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();


  useEffect(() => {
    if (searchParams.get('id')){
      const input = {
        id: searchParams.get('id')
      };
     fetch(`${process.env.REACT_APP_HOST_IP}/api/manage-tasks`,{
          method: 'POST',
          body: JSON.stringify(input),
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + token,
              'X-User': userName
            }
      }).then(
          response => {
            if (response.status === 404){
              return null
            }
            return response.json();
          }
      ).then (
          data =>{
            if (data === null){
              return
            };
            // console.log(data);
            setKeywords(data[0]['keywords']);
            setInitialValue(data[0]['outline']);
            setCurrentNoteList(data[0]['note'] || []);
            topic.current.value = data[0]['topic'];
            domain.current.value = data[0]['domain'];
            description.current.value = data[0]['description'];
            category.current.value = data[0]['category'] || '';
    })
    };
    fetch(`${process.env.REACT_APP_HOST_IP}/api/wordpress-category`,{
        method: 'POST',
        body: JSON.stringify({
          site: 'hk'
        }),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
            'X-User': userName
          }
      }).then(
          response => {
            if (response.status === 500){
              return response.message
            }
            return response.json();
          }
      ).then (
          data =>{
            console.log(data);
            setWCategory(data);
          }) 
  },[]);


  function fetchHeadingHandler() {

      if (search_key.current.value.trim() === '') {
        return
      };
      setTotalReferences([]);
      setActivePage(1);
      setInitQuestions([]);

      dispatch(showLoading());
      let loc = 'VN-vi';
      if (location !== 'Location'){
        loc = `?location=${location}`;
        setOutlineLang(location.split('-')[1]);
        setEditorLang(location.split('-')[1]);
      };
      // console.log(loc, location)
      setIsLoading(current => !current);
      console.log('loading: ', isLoading);
      Promise.all([
        fetch(`${process.env.REACT_APP_HOST_IP}/api/outline/${search_key.current.value}${loc}`, {
          headers: {
            'Authorization': 'Bearer ' + token,
            'X-User': userName
          },
        }).then(
        response => {
          return response.json();
        }
      ).then((data) =>{
        setInitHeadings(data[0]['heading']);
        setInitQuestions(data[0]['questions']);
        setDateCrawled(data[0]['heading'][0].dateAdded);
        setIsLoading(current => !current);
        console.log(data[0]);
        let d = data[0]['heading'].filter(item => item['rank'] <= 10).map(item => {
          return {
            name: `Top ${item['rank']}`,
            rootDomain: item['root_domain'],
            value: parseInt(item['word_count'] !== null && item['word_count'].replace(',',''))
          }
        });
        // console.log(d);
        setStats(d);
        // console.log(data[0]['heading'][0]['schema']['json-ld'][0]);
      }
      ),
      // fetch(`${process.env.REACT_APP_HOST_IP}/api/questions/${search_key.current.value}${loc}`,{
      //   headers: {'Authorization': 'Bearer ' + token},
      // }).then(
      //   response => {
      //     return response.json();
      //   }
      // ).then((data2) =>{
      //   setInitQuestions(c => [...c, ...data2]);
      //   // console.log(data2);
      //   // console.log(data[0]);
      // }
      // )
      ]).then(() => {return dispatch(hideLoading())})
      
      console.log('not loading: ', isLoading);
  };

  function refreshHandler(e, kw) {

    console.log(kw)
    let loc = 'VN-vi'
    if (location !== 'Location'){
      loc = `?location=${location}`;}

    dispatch(showLoading());
    setInitHeadings([]);
    setInitQuestions([]);
    setDateCrawled('');
    
    fetch(`${process.env.REACT_APP_HOST_IP}/api/delete/${kw}`, {
      method: 'DELETE',
      headers: {
        'Authorization': 'Bearer ' + token,
        'X-User': userName
      },
    }).then (response => {
      Promise.all([
        // fetch(`${process.env.REACT_APP_HOST_IP}/api/questions/${kw}${loc}`,{
        //   headers: {'Authorization': 'Bearer ' + token},
        // }).then(
        //   response => {
        //     return response.json();
        //   }).then((data2) =>{
        //     setInitQuestions(c => [...c, ...data2]);
        //     // console.log(data2);
        //   }),
        fetch(`${process.env.REACT_APP_HOST_IP}/api/outline/${kw}${loc}`, {
          headers: {
            'Authorization': 'Bearer ' + token,
            'X-User': userName
          },
        }).then(
        response => {
          return response.json();
        }).then((data) =>{
        setInitHeadings(data[0]['heading']);
        setInitQuestions(data[0]['questions']);
        setDateCrawled(data[0]['heading'][0].dateAdded);
        let d = data[0]['heading'].filter(item => item['rank'] <= 10).map(item => {
          return {
            name: `Top ${item['rank']}`,
            rootDomain: item['root_domain'],
            value: parseInt(item['word_count'] !== null && item['word_count'].replace(',',''))
          }
        });
        // console.log(d);
        setStats(d);
      })
      ]).then(() => {return dispatch(hideLoading())}); 
  });
};



  const addItemHandler = (item) => {
    setHeadings((prevHeadings) => {
      return [...prevHeadings, item];
    });
    console.log('addHeadings: ',headings)
    setDisabled((prevdisabled) => {
      return [...prevdisabled, item.id];
    });
  };



  const exportFileHandler = (event, outline) => {
    
    const input = {
      outline: outline,
    };

    dispatch(showLoading());
    console.log(input);
    fetch(`${process.env.REACT_APP_HOST_IP}/api/download/outline-report`, {
      method: 'POST',
      body: JSON.stringify(input),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
        'X-User': userName
      }
    }).then(
      response => {
        dispatch(hideLoading());
        return response.blob();
      }).then(blob => {
        const url = window.URL.createObjectURL(blob);
        // console.log(blob);
        const link = document.createElement("a");
        link.download = 'outline.docx';
        link.href = url;
        link.click();
      }).catch(error => {
        console.error(error);
    });
  };

  const changeLocationHandler = (e, value) => {
    // console.log(e.target);
    setLocation(value);
    console.log(location);
  };

  const changeForumHandler = (e, value) => {
    // console.log(e.target);
    setForumFilter(value);
  };
  
  const showDetailHandler = () => {
    setDetail(!detail);
  };

  const setTabHandler = (e, tab_name) => {
    e.preventDefault();
    setTab(tab_name);
  };

  const updateItemHandler = (item) => {

    if (item === 'starting'){
      setActiveJSRender(current => current + 1);
    } else {
      // const newHeadings = initHeadings.map((it) => {
      //   if(it.url === item.url){
      //       return {...it, content: item.content};
      //     };
      //   return it
      //   });
      const h = initHeadings.map((it) => {
        if(it.url === item.url){
            return {...it,
              content: item.content,
              logo:item['logo'],
              word_count:item['word_count'],
              number_of_heading: item['number_of_heading']
            };
          };
        return it
        });
      setInitHeadings(h);
      let d = h.filter(item => item['rank'] <= 10).map(item => {
        return {
          name: `Top ${item['rank']}`,
          rootDomain: item['root_domain'],
          value: parseInt(item['word_count'] !== null && item['word_count'].replace(',',''))
        }
      });
      // console.log(d);
      setStats(d);
      setActiveJSRender(current => current - 1);
    }
  };

  const onDragStart = (event, heading) => {
    let content = event.target.innerHTML;
    // if (editorLang !== outlineLang){
    //   dispatch(showLoading());
    //   fetch(`${process.env.REACT_APP_HOST_IP}/api/llm/translate`, {
    //   method: 'POST',
    //   body: JSON.stringify({
    //     to: editorLang,
    //     content: event.target.innerHTML
    //   }),
    //   headers: {
    //     'Content-Type': 'application/json'
    //   }
    // }).then(
    //   response => {
    //     dispatch(hideLoading());
    //     return response.json()
    //   }).then( (data) => {
    //     content = data['response'];
    //   }
    //   ).catch(error => {
    //     console.error(error);
    // });
    // };
    event.dataTransfer.setData('text/html', `<${heading}>${content}</${heading}>`);
  };

  const log = () => {
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
    }
  };

  const insertContentHandler = (e, heading, body) => {
    if (editorLang === outlineLang){
      window.tinymce.activeEditor.execCommand('InsertHTML', false, `<${heading}>${body}</${heading}>`);
    } else {
      dispatch(showLoading());
      fetch(`${process.env.REACT_APP_HOST_IP}/api/llm/translate`, {
      method: 'POST',
      body: JSON.stringify({
        to: editorLang,
        content: body,
        date: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()).getTime()
      }),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
        'X-User': userName
      }
    }).then(
      response => {
        dispatch(hideLoading());
        return response.json()
      }).then( (data) => {
        window.tinymce.activeEditor.execCommand('InsertHTML', false, `<${heading}>${data['response']}</${heading}>`);
      }
      ).catch(error => {
        console.error(error);
    });
    };
    
  };

  const goToPage = (e, direction) => {
    e.preventDefault();
    if(direction === 'inc') {
      if (activePage - 1 < 1) return;
      // navigate(`#go-${activePage - 1}`);
      if (pageRef.current[activePage - 1]) pageRef.current[activePage - 1].scrollIntoView({behavior: 'smooth', block: 'start' });
      setActivePage(c => c - 1);
    }else {
      if (activePage + 1 > 20) return;
      // navigate(`#go-${activePage + 1}`);
      if (pageRef.current[activePage + 1]) pageRef.current[activePage + 1].scrollIntoView({behavior: 'smooth', block: 'start' });
      setActivePage(c => c + 1);
    }
  };

  const llmSummarizeHandler = (body) => {
    dispatch(showLoading());
    fetch(`${process.env.REACT_APP_HOST_IP}/api/llm/summarize`, {
      method: 'POST',
      body: JSON.stringify({
        from: outlineLang,
        to: editorLang,
        content: body,
        date: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()).getTime(),
      }),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
        'X-User': userName
      }
    }).then(
      response => {
        dispatch(hideLoading());
        return response.json()
      }).then( (data) => {
        console.log(data['response']);
        window.tinymce.activeEditor.execCommand('InsertHTML', false, data['response']);
      }
      ).catch(error => {
        console.error(error);
    });
  };


  const genOutlineHandler = () => {
    const outlines = totalReferences.map(item => {return initHeadings[item-1]});
    console.log(outlines);
    let m_outline = {};
    outlines.map((item, index) => {
      m_outline[`Outline ${index + 1}`] = [
        `<Title>${item['title'].trim()}</Title>`
      ]
      item['content'].map(heading => {
        if (heading['content_type'] !== 'P' ){
          // console.log(heading['content_type']);
          let d = `<${heading['content_type']}>${heading['content_body'].trim()}</${heading['content_type']}>`;
          m_outline[`Outline ${index + 1}`].push(d);
        }
      })
    })
    dispatch(showLoading());
    fetch(`${process.env.REACT_APP_HOST_IP}/api/llm/gen-outline`, {
      method: 'POST',
      body: JSON.stringify(m_outline),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
        'X-User': userName
      }
    }).then(
      response => {
        dispatch(hideLoading());
        if (response.status === 404 || response.status === 500){
          return null
        }
        return response.json()
      }).then( (data) => {
        // console.log(data);
        if (data === 'no token found'){
          const m = new bootstrap.Modal(document.getElementById('ModalAlert'));
          m.toggle();
          return
        }
        window.tinymce.activeEditor.execCommand('InsertHTML', false, data);
      }
      ).catch(error => {
        console.error(error);
    });
  };

  const saveOutlineHandler = () => {
    setImportStat('');
    const m = new bootstrap.Modal(document.getElementById('ModalImport'));
    m.toggle();
  };

  const handleSaveOutline = () => {
    if (topic.current.value === ''){
      saveOutlineHandler();
    }else{
      importOutline('save outside');
    }
  };

  const importOutline = (check) => {
    const input = {
      topic: topic.current.value,
      category: category.current.value,
      domain: domain.current.value,
      description: description.current.value,
      outline: editorRef.current.getContent(),
      date: new Date().getTime(),
      task: 'outline',
      note: currentNoteList,
      username: localStorage.getItem('username')
    }
    if (searchParams.get('id')){
      input['id'] = searchParams.get('id');
    } else if (taskId !== ''){
      input['id'] = taskId;
    };
    // console.log(input);
    fetch(`${process.env.REACT_APP_HOST_IP}/api/import-outline`,{
        method: 'POST',
        body: JSON.stringify(input),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
            'X-User': userName
          }
    }).then(
        response => {
            return response.json()
        }
    ).then (
        data =>{
          if(data.acknowledged === true){
            setImportStat(true);
            if(check === "save outside") setAutoSave(true);
            if (taskId === ''){
              setTaskId(data.inserted_id);
            }
          }else{
            setImportStat(false);
          }
          console.log(data);
        }
    );
  };

  const importWordpress = () => {
    let s = wpSite;
    if (wpSite === '') s = 'hk'
    const input = {
      title: wTitle.current.value,
      category: totalWPCategory,
      site: s,
      content: editorRef.current.getContent(),
    };
    console.log(input);
    fetch(`${process.env.REACT_APP_HOST_IP}/api/save-wordpress`,{
        method: 'POST',
        body: JSON.stringify(input),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
            'X-User': userName
          }
      }).then(
          response => {
            return response.json();
          }
      ).then (
          data =>{
            if (data.status_code === 500){
              console.log(data);
              return
            } else {
              console.log(data);
              setImportStat(true);
            }
            
          }) 
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (!searchParams.get('id') && taskId === '') return
      const contentValue = editorRef.current.getContent();
      const input = {
        outline: contentValue,
        topic: topic.current.value,
        domain: domain.current.value,
        description: description.current.value,
      };
      if (searchParams.get('id')){
        input['id'] = searchParams.get('id');
      } else if (taskId !== ''){
        input['id'] = taskId;
      }
      if(isContentChanged === true){
        // console.log('content changed');
        fetch(`${process.env.REACT_APP_HOST_IP}/api/import-outline`,{
          method: 'POST',
          body: JSON.stringify(input),
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + token,
              'X-User': userName
            }
        }).then(
            response => {
                return response.json()
            }
        ).then (
            data =>{
              if(data.acknowledged === true){
                setAutoSave(true);
                setIsContentChanged(false);
              }
              console.log(data);
            }
        );
      } else{
        // console.log('content not changed');
      }
      
    },5*60*1000);
    return () => {
      clearInterval(interval);
    }
  },[isContentChanged]);

  const sortHandler = (map, mode) => {
    if (map === 'mapped'){
      if (mode === 'kw'){
        if (sort !== 'keyword_inc' && sort !== 'keyword_dec'){
          setSort('keyword_inc');
          keywords.sort((a,b) => b.split('<<>>')[0].localeCompare(a.split('<<>>')[0]));
        } else if(sort === 'keyword_dec'){
          setSort('keyword_inc');
          keywords.sort((a,b) => b.split('<<>>')[0].localeCompare(a.split('<<>>')[0]));
        } else if(sort === 'keyword_inc'){
          setSort('keyword_dec');
          keywords.sort((a,b) => a.split('<<>>')[0].localeCompare(b.split('<<>>')[0]));
        };
      } else {
        if (sort !== 'volume_inc' && sort !== 'volume_dec'){
          setSort('volume_inc');
          keywords.sort((a,b) => (parseInt(b.split('<<>>')[1]) > parseInt(a.split('<<>>')[1])) ? -1 : (parseInt(b.split('<<>>')[1]) < parseInt(a.split('<<>>')[1])) ? 1 : 0);
        } else if(sort === 'volume_dec'){
          setSort('volume_inc');
          keywords.sort((a,b) => (parseInt(b.split('<<>>')[1]) > parseInt(a.split('<<>>')[1])) ? -1 : (parseInt(b.split('<<>>')[1]) < parseInt(a.split('<<>>')[1])) ? 1 : 0);
        } else if(sort === 'volume_inc'){
          setSort('volume_dec');
          keywords.sort((a,b) => (parseInt(a.split('<<>>')[1]) > parseInt(b.split('<<>>')[1])) ? -1 : (parseInt(a.split('<<>>')[1]) < parseInt(b.split('<<>>')[1])) ? 1 : 0);
        };
      }
    } else if (map === 'stats'){
      if (mode === 'kw'){
        if (sort !== `${mode}_inc` && sort !== `${mode}_dec`){
          setSort(`${mode}_inc`);
          kwDensity.sort((a,b) => a.keyword.localeCompare(b.keyword));
        } else if(sort === `${mode}_dec`){
          setSort(`${mode}_inc`);
          kwDensity.sort((a,b) => a.keyword.localeCompare(b.keyword));
        } else if(sort === `${mode}_inc`){
          setSort(`${mode}_dec`);
          kwDensity.sort((a,b) => b.keyword.localeCompare(a.keyword));
        };
      } else {
        if (sort !== `${mode}_inc` && sort !== `${mode}_dec`){
          setSort(`${mode}_inc`);
          kwDensity.sort((a,b) => a[mode] - b[mode]);
        } else if(sort === `${mode}_dec`){
          setSort(`${mode}_inc`);
          kwDensity.sort((a,b) => a[mode] - b[mode]);
        } else if(sort === `${mode}_inc`){
          setSort(`${mode}_dec`);
          kwDensity.sort((a,b) => b[mode] - a[mode]);
        };
      }
    } else {
      console.log(map, mode);
      let d = eval(map);
      console.log(d);
      if (sort !== `${mode}_inc` && sort !== `${mode}_dec`){
        setSort(`${mode}_inc`);
        d.sort((a,b) => b[mode].localeCompare(a[mode]));
      } else if(sort === `${mode}_dec`){
        setSort(`${mode}_inc`);
        d.sort((a,b) => b[mode].localeCompare(a[mode]));
      } else if(sort === `${mode}_inc`){
        setSort(`${mode}_dec`);
        d.sort((a,b) => a[mode].localeCompare(b[mode]));
      };
    }
    
  };

  const renderNestedObject = (tier, data) => {
    // console.log(data);
    const d = Object.entries(data).map(([key, value]) => 
              typeof value === 'object' && !Array.isArray(value) && typeof value !== null ?
                <>
                  <tr className={`${tier === -1 && key === '@type' && 'table-light'}`}>
                    <td colspan="2"
                      onClick={(e) => {e.preventDefault();
                        console.log(open);
                        if (!open.includes(key)){
                            setOpen(c => [...c, key]);
                        } else{
                            let d = open.filter(item => item !== key);
                            setOpen(d);
                        }  
                    }}
                    role='button'
                    style={{paddingLeft: `${(tier+1)*20}px`}}
                    >
                      <FontAwesomeIcon icon={open.includes(key) ? faCaretDown : faCaretRight} className='me-2' />
                      {key}
                    </td>
                  </tr>
                  {/* <Collapse in={open.includes(key) ? true : false}> */}
                    <>
                    {open.includes(key) &&
                    renderNestedObject(tier + 1, value)}
                    </>
                  {/* </Collapse> */}
                </> : Array.isArray(value) ?
                <>
                {
                  value.map(v => 
                    typeof v === 'object' && !Array.isArray(v) && typeof v !== null ?
                    <>
                    <tr className={`${tier === -1 && key === '@type' && 'table-light'}`}>
                      <td colspan="2"
                        onClick={(e) => {e.preventDefault();
                          if (!open.includes(key)){
                              setOpen(c => [...c, key]);
                          } else{
                              let d = open.filter(item => item !== key);
                              setOpen(d);
                          }  
                      }}
                      role='button'
                      style={{paddingLeft: `${(tier+1)*20}px`}}
                      >
                        <FontAwesomeIcon icon={open.includes(key) ? faCaretDown : faCaretRight} className='me-2' />
                        {key}</td>
                    </tr>
                    {/* <Collapse in={open.includes(key) ? true : false}> */}
                      {open.includes(key) &&
                      renderNestedObject(tier + 1, v)}
                    {/* </Collapse> */}
                    </> :
                    <tr className={`${tier === -1 && key === '@type' && 'table-light'}`}>
                      <td style={{paddingLeft: `${(tier+1)*20}px`}}>{key}</td>
                      <td className='text-break'>{v}</td>
                    </tr>
                    )
                }
                </> :
                <tr className={`${tier === -1 && key === '@type' && 'table-light'}`}>
                  <td style={{paddingLeft: `${(tier+1)*20}px`}}>{key}</td>
                  <td className='text-break'>{value}</td>
                </tr>
            )
    // console.log(d)
    return d
  };
  const renderArr = (tier, data) => {
    // console.log(data);
    const d = data.map(item => 
          <>
          {renderNestedObject(tier, item)}
          </>
      );
    // console.log(d);
    return d
  }

  const renderElem = (tier, data) => {
    if (typeof data === 'object' && !Array.isArray(data) && typeof data !== null){
      return renderNestedObject(tier, data)
    } else if (Array.isArray(data)){
      // console.log(data);
      return renderArr(tier, data)
    }
  };

  const kwDensityHandler = () => {
    if (keywords.length === 0) return;
    let d = keywords.map(item => item.split('<<>>')[0]);
    // console.log(d);
    let h = {};
    initHeadings.filter(item => item.rank <= 10).map(item => {
      let s = ''
      item.content.map(heading => {
        s = s + '\n' + heading['content_body']
      })
        h[[item.url]]= s
    });
    // console.log(h)
    const input = {
      keywords: d,
      content: h
    };
    console.log(input);
    fetch(`${process.env.REACT_APP_HOST_IP}/api/kw-density`,{
          method: 'POST',
          body: JSON.stringify(input),
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + token,
              'X-User': userName
            }
      }).then(
          response => {
            return response.json();
          }
      ).then (
          data =>{
            console.log(data);
            let h = [];
            Object.entries(data).map(([k, v]) => {
              let count = 0;
              let min = 0;
              let max = 0;
              Object.entries(v).map(([u, c]) => {
                if (c > 0){
                  count += 1;
                }
                if (c < min) min = c;
                if(c > max) max = c;
              });
              h.push({
                'keyword': k,
                'min': min,
                'max': max,
                'count': count
              })
            });
            // console.log(h);
            let check = kwDensity.map(item => item.keyword);
            let u = h.filter(item => !check.includes(item.keyword));
            setKwDensity(c => [...c, ...u]);
          })

    return
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="px-2 pt-2">
          <p>{`${payload[0].payload.rootDomain}`}</p>
          <p>{`word count : ${payload[0].value.toLocaleString('en-US')}`}</p>
        </div>
      );
    }
  
    return null;
  };

  const enrichKwHandler = () => {
    if (initHeadings.length === 0) return;
    // console.log(keywords);
    // console.log(initHeadings);
    dispatch(showLoading());
    const d = initHeadings.filter(item => item.rank === parseInt(enrichTop));
    // console.log(d)
    let text = ''
    d[0].content.map(item => {
      text += '\n' + item.content_body.trim()
    })
    // console.log(text)
    fetch(`${process.env.REACT_APP_HOST_IP}/api/llm/extract-kws`,{
          method: 'POST',
          body: JSON.stringify({
            'content': text
          }),
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + token,
              'X-User': userName
            }
      }).then(
          response => {
            if (response.status === 404 || response.status === 500){
              return null
            }
            return response.json();
          }
      ).then (
          data =>{
            // console.log(data);
            if (data === 'no token found'){
              const m = new bootstrap.Modal(document.getElementById('ModalAlert'));
              m.toggle();
              dispatch(hideLoading());
              return
            };
            let d = data.split(',');
            // console.log(d);
            // keyword density
            let h = {};
            initHeadings.filter(item => item.rank <= 10).map(item => {
              let s = ''
              if(item.content === null) return;
              item.content.map(heading => {
                s = s + '\n' + heading['content_body']
              })
                h[[item.url]]= s
            });
            // console.log(h)
            const input = {
              keywords: d,
              content: h
            };
            // console.log(input);
            fetch(`${process.env.REACT_APP_HOST_IP}/api/kw-density`,{
                  method: 'POST',
                  body: JSON.stringify(input),
                  headers: {
                      'Content-Type': 'application/json',
                      'Authorization': 'Bearer ' + token,
                      'X-User': userName
                    }
              }).then(
                  response => {
                    dispatch(hideLoading());
                    return response.json();
                  }
              ).then (
                  data =>{
                    // console.log(data);
                    let h = [];
                    Object.entries(data).map(([k, v]) => {
                      let count = 0;
                      let min = 0;
                      let max = 0;
                      Object.entries(v).map(([u, c]) => {
                        if (c > 0){
                          count += 1;
                        }
                        if (c < min) min = c;
                        if(c > max) max = c;
                      });
                      h.push({
                        'keyword': k,
                        'min': min,
                        'max': max,
                        'count': count
                      })
                    });
                    // console.log(h);
                    let check = kwDensity.map(item => item.keyword);
                    let u = h.filter(item => !check.includes(item.keyword));
                    setKwDensity(c => [...c, ...u]);
                  })
          })
    return
  };

  const updateFilterHandler = (filterID, type, value) => {
    const newArr = filterArr.filter(item => {
        if(item.id === filterID){
            const d = item;
            item[type] = value;
            return d
        }else {
            return item
        }
    });
    setFilterArr(newArr);
    // console.log(filterArr);
  };
  const deleteFilterHandler = (filterID) => {
    let arr = []
    if (filterArr.length === 1){
      arr = [{
        id: uuid4(),heading: 'URL', operation: 'contains', value: ''
    }]
    } else {
      arr = filterArr.filter(item => item.id !== filterID)
    }
    setFilterArr(arr);
    console.log(filterArr);
  };
  const incFilterHandler = (filterID) => {
    if(filterArr.length === 3 ) return;
    setFilterArr( c => {
        const d = [...c];
        d.push({id: filterID ,heading: 'URL', operation: 'contains', value: ''});
        return d
    });
    // console.log(filterArr);
  };
  const applyFilterHandler = () => {
    //filterArr[{id, heading, operation, value}]
    console.log('re render');
    console.log(filterArr)
    let arr = refNotes.filter(item => {
        let count = 0;
        filterArr.map(condition => {
            if (condition.operation === 'contains'){
                count += eval(`"${item[condition.heading]}".toLowerCase().includes("${condition.value}".toLowerCase())`)
            }else if (condition.operation === 'does not contain'){
                count += eval(`!"${item[condition.heading]}".toLowerCase().includes("${condition.value}".toLowerCase())`)
            }else if (condition.operation === 'is'){
                // console.log(`"${item[condition.heading]}" === "${condition.value}"`);
                count += eval(`"${item[condition.heading]}" === "${condition.value}"`)
            }else if (condition.operation === 'is not'){
                console.log(`"${item[condition.heading]}" !== "${condition.value}"`);
                count += eval(`"${item[condition.heading]}" !== "${condition.value}"`)
            }else if(condition.operation === 'greater than') {
                count += eval(`"${item[condition.heading]}" > "${condition.value}"`)
            }else if (condition.operation === 'greater than or equal'){
                count += eval(`"${item[condition.heading]}" >= "${condition.value}"`)
            }else if (condition.operation === 'less than'){
                count += eval(`"${item[condition.heading]}" < "${condition.value}"`)
            }else if (condition.operation === 'less than or equal'){
                count += eval(`"${item[condition.heading]}" <= "${condition.value}"`)
            } 
        })
        return count === filterArr.length
    });
    console.log(arr)
    setFilteredRefNotes(arr);
  };

  const urlFilter = (
    <Popover id="popover-positioned-top" title="Popover bottom" style={{"max-width":"500px", 'border': 'none'}}>
        <div className='d-flex flex-column p-4 bg-white shadow'>
            <p>Filter URLs</p>
            {
                filterArr.map(item => {
                    // console.log(item);
                    return (
                        <div className='d-flex align-items-center' key={item}>
                            where
                            <select class="form-select form-select-sm" aria-label="Default select example" className='ms-3 me-2' style={{maxWidth: '100px'}}
                                onChange={e => updateFilterHandler(item.id, 'heading', e.target.value)}
                            >
                              {/* <Form.Select aria-label="Default select example"> */}
                                <option value='heading' selected>heading</option>
                                <option value='category' >category</option>
                                <option value='topic' >topic</option>
                                <option value='legislation' >legislation</option>
                                <option value='section' >section</option>
                                <option value='note' >note</option>
                                {/* { currentNoteList.length > 0 &&
                                    Object.keys(currentNoteList[0]).map(heading =>{
                                        // console.log(data[activeTab]);
                                        if (!['topic_cat', 'topic'].includes(heading)) {
                                            return (<option value={heading} selected={item['heading'] === heading}>{heading}</option>)
                                        } 
                                    })
                                } */}
                              {/* </Form.Select> */}
                            </select>
                            <select class="form-select form-select-sm" aria-label="Default select example" className='me-2'
                                onChange={e => updateFilterHandler(item.id, 'operation', e.target.value)}
                            >
                                <option value='contains' selected={item.operation === '' ? true : item.operation === 'contains' ? true : false}>contains</option>
                                <option value="does not contain" selected={item.operation === 'does not contain'}>does not contain</option>
                                <option value="is" selected={item.operation === 'is'}>is</option>
                                <option value="is not" selected={item.operation === 'is not'}>is not</option>
                                <option value="greater than" selected={item.operation === 'greater than'}>greater than</option>
                                <option value="greater than or equal" selected={item.operation === 'greater than or equal'}>greater than or equal</option>
                                <option value="less than" selected={item.operation === 'less than'}>less than</option>
                                <option value="less than or equal" selected={item.operation === 'less than or equal'}>less than or equal</option>
                            </select>

                            <input type="text" className="form-control me-2" style={{width: '120px'}} placeholder="Enter a value"
                                defaultValue={item.value}
                                onChange={e => updateFilterHandler(item.id, 'value', e.target.value)}
                            />
                            <button className='btn'
                                onClick={e => deleteFilterHandler(item.id)}
                            ><FontAwesomeIcon icon={faTrash} /></button>
                        </div>
                    )
                })
            }
            <div className='d-flex mt-4'>
                <button type="button" class={`btn btn-light me-auto ${filterArr.length === 3 && 'disabled'}`} onClick={e => incFilterHandler(uuid4())}>+ Add condition</button>
                <button type="button" class="btn btn-primary" onClick={applyFilterHandler}>Apply</button>
            </div>
            
        </div>
    </Popover>
  );

  const extractLegislationHandler = () =>{
    if (search_key.current.value.trim() === '') {
      return
    };
    let loc = 'VN-vi';
        if (location !== 'Location'){
          loc = location;
        };
    const input = {
      keyword: search_key.current.value.trim(),
      location: loc,
      ref: legalRefs
    };
    console.log(input);
    dispatch(showLoading());
    fetch(`${process.env.REACT_APP_HOST_IP}/api/extract-legislations`,{
          method: 'POST',
          body: JSON.stringify(input),
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + token,
              'X-User': userName
            }
      }).then(
          response => {
            if (response.status === 404){
              return null
            };
            dispatch(hideLoading())
            return response.json();
          }
      ).then (
          data =>{
            console.log(data);
            setLegislation(data);
          })
  };
  const summarizeRegHandler = (domain, heading) => {
    console.log(heading);
    let pattern = new RegExp(heading);
    let content;
    initHeadings.map (item => {
      if (item.root_domain === domain){
        item.content.map((h, idx) => {
          let isMatch = pattern.test(h.content_body);
          if (isMatch){
            content = item.content[idx+1].content_body
          }
        })
      }
    });
    console.log(content);
    dispatch(showLoading());
    fetch(`${process.env.REACT_APP_HOST_IP}/api/summarize-legislation`,{
          method: 'POST',
          body: JSON.stringify({
            content: content
          }),
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + token,
              'X-User': userName
            }
      }).then(
          response => {
            if (response.status === 404){
              return null
            };
            dispatch(hideLoading());
            return response.json();
          }
      ).then (
          data =>{
            // console.log(data);
            window.tinymce.activeEditor.execCommand('InsertHTML', false, data['result']);
          })
  };
  const addNoteHandler = (reg, sec, head, note) => {
    console.log(reg, sec);
    const m = new bootstrap.Modal(document.getElementById('ModalAddNotes'));
    m.toggle();
    if (regulation.current) regulation.current.value = reg;
    if (section.current) section.current.value = sec;
    if (headingNote.current) headingNote.current.value = head;
    if (notes.current) notes.current.value = note;
  };

  const saveNoteHandler = (head, reg, sec, note) => {
    let l = '';
    let d = currentNoteList.filter(item => {
      if (item['heading'] !== head || item['legislation'] !== reg || item['section'] !== sec) {
        return true
      };
      console.log(item['link']);
      l = item['link'];
      return false
    });
    console.log(d)
    setCurrentNoteList([...d, {
      heading: headingNote.current.value,
      legislation: regulation.current.value,
      section: section.current.value,
      note: notes.current.value,
      link: l
    }])
  };

  const handleEditorInit = (editor) => {
    // editor.ui.registry.addMenuItem('customItem1', {
    //   text: 'Menu Item 1',
    //   onAction: function () {
    //     alert('Menu item 1 clicked');
    //   }
    // });

    // editor.ui.registry.addMenuItem('customItem2', {
    //   text: 'Menu Item 2',
    //   onAction: function () {
    //     alert('Menu item 2 clicked');
    //   },
    //   menu: [
    //     {
    //       text: 'Sub-menu item 1',
    //       onAction: function () {
    //         alert('Sub-menu item 1 clicked');
    //       }
    //     },
    //     {
    //       text: 'Sub-menu item 2',
    //       onAction: function () {
    //         alert('Sub-menu item 2 clicked');
    //       }
    //     }
    //   ]
    // });
    // Add the new menu item to the toolbar
    editor.ui.registry.addToggleButton('custommenu', {
          text: 'Pixabay',
          icon: 'gallery',
          onAction: function() {
            const pixa = new bootstrap.Modal(document.getElementById('ModalPixabay'));
            pixa.show();
            // setPixabayModal(true);
          }
    });

    editor.ui.registry.addContextMenu('customContextMenu', {
      update: function () {
        return [
          {
            type: 'menuitem',
            text: 'Custom Item 1',
            icon: 'permanent-pen',
            onAction: function () {
              
              // Get the selected content
              const selectedContent = editor.selection.getContent({format:"text"});
        
              // Log the highlighted content to the console
              console.log("Highlighted content:", selectedContent);

              editor.execCommand('mceInsertContent', false, 'Menu item 1 content');
            }
          }
        ];
      }
    });
  };

  const selectSearchNoteHandler = (heading, content) => {
    searchNotesContent.current.value = heading + '\n' + content
  };
  const addCurrentNoteHandler = (note) => {
    console.log(note);
    setCurrentNoteList(c => [...c, {
      heading: note['heading'],
      legislation: note['legislation'],
      section: note['section'],
      note: note['note'] || '',
      link: note['link'] || ''
    }]);
  };
  const deleteCurrentNoteHandler = (head, reg, sec) => {
    console.log('delete', head, reg, sec);
    let d = currentNoteList.filter(item => item['heading'] !== head || item['legislation'] !== reg || item['section'] !== sec);
    console.log(d)
    setCurrentNoteList(d);
  };

  const openSearchHandler = () => {
    fetch(`${process.env.REACT_APP_HOST_IP}/api/all-categories`,{
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + token,
              'X-User': userName
            }
      }).then(
          response => {
            if (response.status === 404){
              return null
            }
            return response.json();
          }
      ).then (
          data =>{
            console.log(data);
            setAllCategories(data);
          }
      );
    const m = new bootstrap.Modal(document.getElementById('ModalFactSearch'),  {focus: false});
    m.toggle();
    
  };

  const loadAllCategoricalTopics = (t) => {
    console.log(t);
    if (t === 'Choose...') return;
    fetch(`${process.env.REACT_APP_HOST_IP}/api/categorical-outlines`,{
          method: 'POST',
          body: JSON.stringify({
            category: t
          }),
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + token,
              'X-User': userName
            }
      }).then(
          response => {
            if (response.status === 404){
              return null
            }
            return response.json();
          }
      ).then (
          data =>{
            console.log(data);
            setAllNotes(data);
            setAllTopics(data.map(item => item['topic']))
          }
      );
  };
  const loadAllNotesHandler = (n) => {
    if (n === 'Choose...') return;
    let d = [];
    if (n === 'all'){
      allNotes.map(item => {
          let m = item['note'].map(i => {
            return {
              ...i,
              category: item['category'],
              topic: item['topic']
            }
          })
          d.push(...m)
      });
    } else {
      allNotes.map(item => {
        if (item['topic'] === n) {
          let m = item['note'].map(i => {
            return {
              ...i,
              category: item['category'],
              topic: item['topic']
            }
  
          })
          d.push(...m)
        }
      });
    };
    console.log(d);
    setRefNotes(d);
    setFilteredRefNotes(d);
  };
  const chunkArray = (array, size) => {
    return array.reduce((chunks, item, index) => {
      if (index % size === 0) {
        chunks.push([item]);
      } else {
        chunks[chunks.length - 1].push(item);
      }
      return chunks;
    }, []);
  };

  let timeoutId;
  const handleDelayedChange = (event) => {
    const value = event.target.value;
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      if (value !== ''){
        fetch(`${process.env.REACT_APP_HOST_IP}/api/pixabay-image`,{
          method: 'POST',
          body: JSON.stringify({
            query: value,
            page: 1
          }),
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + token,
              'X-User': userName
            }
        }).then(
            response => {
              if (response.status === 404){
                return null
              }
              return response.json();
            }
        ).then (
            data =>{
              console.log(data);
              setImages(data);
        })
      }
    }, 1000); // Set a new timeout for 1000ms
  };

  const insertImgHandler = (url) => {
    // Convert the image URL to a Blob
    let file_name = url.split('/').pop();
    console.log(url);
    fetch(url)
      .then(response => response.blob())
      .then(blob => {
        // Create a File object from the Blob
        const file = new File([blob], file_name, { type: blob.type });
        // console.log(file)
        const formData = new FormData();
        formData.append('file', file);
        // console.log(formData)

      fetch(`${process.env.REACT_APP_HOST_IP}/api/upload/image`, {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + token,
          'X-User': userName
        },
        body: formData
      })
      .then(response => response.json())
      .then(data => {
        console.log('Image uploaded successfully:', data.imageUrl);
        window.tinymce.activeEditor.execCommand('InsertHTML', false, `<img src="${process.env.REACT_APP_HOST_IP}/api${data.imageUrl}" class="img-fluid" style="width:100%; height: 100%" alt="..." />`);
        // You can do something with the image URL received from the server
      })
      .catch(error => {
        console.error('Error uploading image:', error);
      });
    })
  };

  const fileUploadHandler = () => {
    const input_file = document.getElementById('formFile').files
    console.log(input_file);
    if (input_file.length === 0){
        return
    };
    const file_size = input_file[0].size;
    const file = input_file[0];
    if (file_size < 10*1024*1024){
      const formData = new FormData();
      formData.append('file', file);
      fetch(`${process.env.REACT_APP_HOST_IP}/api/upload/image`, {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + token,
          'X-User': userName
        },
        body: formData
      })
      .then(response => response.json())
      .then(data => {
        console.log('Image uploaded successfully:', data.imageUrl);
        window.tinymce.activeEditor.execCommand('InsertHTML', false, `<img src="${process.env.REACT_APP_HOST_IP}/api${data.imageUrl}" class="img-fluid" style="width:100%; height: 100%" alt="..." />`);
        pixaBayClose.current.click();
      })
      .catch(error => {
        console.error('Error uploading image:', error);
      });
    } else {
      console.log('greater than 10MB');
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    const formData = new FormData();
    formData.append('file', file);

    fetch(`${process.env.REACT_APP_HOST_IP}/api/upload/image`, {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + token,
          'X-User': userName
        },
        body: formData
      })
      .then(response => response.json())
      .then(data => {
        console.log('Image uploaded successfully:', data.imageUrl);
        window.tinymce.activeEditor.execCommand('InsertHTML', false, `<img src="${process.env.REACT_APP_HOST_IP}/api${data.imageUrl}" class="img-fluid" style="width:100%; height: 100%" alt="..." />`);
        pixaBayClose.current.click();
      })
      .catch(error => {
        console.error('Error uploading image:', error);
      });
  };
  const handleDragOver = (event) => {
    event.preventDefault();
  };
  const openWordpressModal = () => {
    setImportStat('');
    // const m = new bootstrap.Modal(document.getElementById('ModalWordpress'));
    const m = new bootstrap.Modal(wordpressModal.current);
    m.toggle();
  };

  const getSiteCategoryHandler = (s) => {
    setTotalWPCategory([]);
    setWPSite(s);
    const d = document.getElementsByClassName('row-checkbox');
    for ( let i = 0; i < d.length; i++) d[i].checked = false;
    fetch(`${process.env.REACT_APP_HOST_IP}/api/wordpress-category`,{
        method: 'POST',
        body: JSON.stringify({
          site: s
        }),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
            'X-User': userName
          }
      }).then(
          response => {
            if (response.status === 500){
              return response.message
            }
            return response.json();
          }
      ).then (
          data =>{
            console.log(data);
            setWCategory(data);
          }) 
  }

  return (
      <div className="d-flex flex-column h-100">
        <h1 className="text-center my-4">Outline Builder</h1>
        {/* Modal Alert*/}
        <div class="modal fade" id="ModalAlert" tabindex="-1" role="dialog" aria-labelledby="ModalCenterTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                <div className="modal-header">
                    <h5 class="modal-title me-auto" id="ModalLongTitle">No Token Found</h5>
                </div>
                <div class="modal-body" style={{'max-height':'500px', 'overflow':'auto'}}>
                    <p>No token found. Please insert your Gemini token in profile setting.</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" >Close</button>
                </div>
                </div>
            </div>
        </div>
        {/* Modal Alert*/}
        <div className="d-flex flex-column flex-grow-1 overflow-hidden">
          <div class={`input-group ${dateCrawled !=='' &&  dateCrawled !== null ? 'mt-5' : 'my-5'}`} style={{'max-width': '66%'}}>
          <button class="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">{location}</button>
          <ul class="dropdown-menu">
            <li onClick={event => changeLocationHandler(event, 'US-en')}>
              <a class=" d-flex dropdown-item m-auto" href="#">
                <img src='us-flag.png' className="pe-2" style={{"height":"20px"}}/>
                <span>US-en</span>
              </a>
            </li>
            <li onClick={event => changeLocationHandler(event, 'SG-en')}>
              <a class=" d-flex dropdown-item m-auto" href="#">
                <img src='sg-flag.png' className="pe-2" style={{"height":"20px"}}/>
                <span>SG-en</span>
              </a>
            </li>
            <li onClick={event => changeLocationHandler(event, 'VN-vi')}>
              <a class=" d-flex dropdown-item m-auto" href="#">
                <img src='vn-flag.png' className="pe-2" style={{"height":"20px"}}/>
                <span>VN-vi</span>
              </a>
            </li>
          </ul>
            <input type="text" ref={search_key} class="form-control" placeholder="Type search keywords here..." aria-label="Type search keywords here..." aria-describedby="button-addon2" />
            <button class={`btn btn-outline-primary ${isLoading && 'disabled'}`} type="button" id="button-addon2" onClick={fetchHeadingHandler}>Start Searching</button>
          </div>
          {dateCrawled !== '' &&  dateCrawled !== null &&
          <div className='d-flex mb-5 mt-1'>
            <small className="text-muted">Last crawled: {dateCrawled}</small>
            <a href="#" className='ms-4' onClick = {event => refreshHandler(event, initHeadings[0].Keyword, location)}>
            <FontAwesomeIcon icon={faRefresh} />
            <small>Refresh</small></a>
          </div> }
          <div className="row flex-grow-1 overflow-hidden">
            <div className="d-flex flex-column vh-100 col-md-6">
                <div className="d-flex mb-2 pb-4">
                  {/* <h3 className="d-inline-block me-auto">SERP Outline</h3> */}
                  <div className="d-inline-block w-100">
                    <ul class="d-flex justify-content-start nav nav-tabs" id="myTab" role="tablist">
                      <li class="nav-item" role="presentation">
                        <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true" onClick={event => setTabHandler(event,'serp')}>SERP Outline</button>
                      </li>
                      <li class="nav-item" role="presentation">
                        <button class="nav-link" id="keyword-tab" data-bs-toggle="tab" data-bs-target="#statistics" type="button" role="tab" aria-controls="statistics" aria-selected="false" onClick={event => setTabHandler(event,'stats')}>Stats</button>
                      </li>
                      <li class="nav-item" role="presentation">
                        <button class="nav-link" id="schema-tab" data-bs-toggle="tab" data-bs-target="#schema" type="button" role="tab" aria-controls="schema" aria-selected="false" onClick={event => setTabHandler(event,'schema')}>Schema</button>
                      </li>
                      <li class="nav-item" role="presentation">
                        <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false" onClick={event => setTabHandler(event,'faqs')}>FAQs</button>
                      </li>
                      <li class="nav-item" role="presentation">
                        <button class="nav-link" id="keyword-tab" data-bs-toggle="tab" data-bs-target="#keyword" type="button" role="tab" aria-controls="keyword" aria-selected="false" onClick={event => setTabHandler(event,'keyword')}>Mapped Keywords</button>
                      </li>
                      <li class="nav-item" role="presentation">
                        <button class="nav-link" id="keyword-tab" data-bs-toggle="tab" data-bs-target="#keyword" type="button" role="tab" aria-controls="keyword" aria-selected="false" onClick={event => setTabHandler(event,'fact')}>Legal fact</button>
                      </li>
                    </ul>
                    <div class="d-flex flex-column tab-content mt-3">
                      {tab === 'serp' ?
                        <div class="d-flex" aria-labelledby="home-tab">
                          <div class="d-inline-block ms-4 form-check form-switch align-self-center" style={{'font-size': '16px'}}>
                            <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" onChange={showDetailHandler}/>
                            <label class="form-check-label" for="flexSwitchCheckDefault">Show detail</label>
                          </div>
                          <div className='align-self-center ms-3'>
                            <button className='btn btn-light' onClick={e => goToPage(e, 'inc')}><FontAwesomeIcon icon={faAngleDoubleLeft} /></button>
                            <span className='mx-2'> {activePage} </span>
                            <button className='btn btn-light' onClick={e => goToPage(e, 'dec')}><FontAwesomeIcon icon={faAngleDoubleRight} /></button>
                            
                          </div>
                          <div class="dropdown ms-auto">
                            <button class="btn btn-light dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
                              {`Ref. outline (${totalReferences.length})`}
                            </button>
                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1" style={{'max-height': '300px', 'overflow': 'auto'}}>
                              {
                                initHeadings.map((item, index) => {
                                  return (
                                    <li className='ms-2'>
                                      <div className=" form-check">
                                        <input className="form-check-input" type="checkbox" name={`exampleRadios${index}`} id={`exampleRadios${index}`} value={`Rank ${item.rank}`}
                                        {...(totalReferences.includes(item.rank) && {checked:true})}
                                        onChange={e => e.target.checked ? setTotalReferences( c => [...c, item.rank]) : setTotalReferences( c => c.filter(it => it !== item.rank))}
                                        />
                                        <label className="form-check-label" for={`exampleRadios${index}`}>
                                          {`Rank ${item.rank}`}
                                        </label>
                                      </div>
                                    </li>
                                  )
                                })
                              }
                            </ul>
                          </div>
                          <button className={`btn btn-primary mx-3 ${totalReferences.length === 0 && 'disabled'}`} onClick={genOutlineHandler}>
                            Gen Outline
                          </button>
                      </div> : tab === 'faqs' ?
                      <div class="d-flex" aria-labelledby="profile-tab">
                        <div class="dropdown ms-4">
                          <a class="btn btn-outline-secondary dropdown-toggle pe-4 ps-3" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                            {forumFilter}
                          </a>
                          <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                            <li><a class="dropdown-item" href="#" onClick={event => changeForumHandler(event, 'All')}>All</a></li>
                            <li><a class="dropdown-item" href="#" onClick={event => changeForumHandler(event, 'People Also Asked')}>People Also Asked</a></li>
                            <li><a class="dropdown-item" href="#" onClick={event => changeForumHandler(event, 'Quora')}>Quora</a></li>
                            <li><a class="dropdown-item" href="#" onClick={event => changeForumHandler(event, 'Reddit')}>Reddit</a></li>
                          </ul>
                        </div>
                      </div> : tab === 'schema' ?
                        <div class="d-flex flex-column overflow-auto w-100" aria-labelledby="schema-tab" style={{height: '600px'}}>
                          {
                            initHeadings.map(item => 
                              item.schema !== null && item['schema']['json-ld'].length > 0 &&
                              <div className='card mb-3'>
                                <div className='d-flex align-items-center card-header'>
                                    <span className='me-4'>Rank: {item.rank}</span>
                                    <span className='me-4'>Headings: {item.number_of_heading}</span>
                                    <span className='me-auto'>Word count: {item.word_count}</span>
                                </div>
                                <div className='card-body'>
                                  <div className='card-title mb-2'>
                                  <img className='me-2' src={item.logo} style={{'width': '20px'}} />
                                  <a href={item.url} target='_blank'>
                                    {item.root_domain}
                                  </a>
                                  </div>
                                  <div className='card-text'>
                                  {  
                                    Object.keys(item['schema']).map(type => 
                                      type === 'json-ld' ?
                                        <>
                                        <Accordion>
                                            { item['schema'][type].length === 1 && Object.keys(item['schema'][type][0]).includes('@graph') ?
                                            Object.entries(item['schema'][type][0]).map(([heading, value]) => {
                                              // console.log(item['schema'][type][0]);
                                              if(heading !== '@context'){
                                                // console.log(value);
                                                  return (
                                                    <Accordion>
                                                        { Array.isArray(value) && value.length === 1 && Object.keys(value[0]).includes('@context') ?
                                                          Object.entries(value).map(([heading, v]) => {
                                                            // console.log(value);
                                                            if(heading !== '@context'){
                                                                return (
                                                                  <Accordion>
                                                                    {
                                                                      v.map((schema_type, idx) => 
                                                                            <Accordion.Item eventKey={idx} >
                                                                                <Accordion.Header><h5>{schema_type['@type']}</h5></Accordion.Header>
                                                                                <Accordion.Body>
                                                                                  <>
                                                                                    <tr className='table-secondary'>
                                                                                      <th scope="col" className='w-50'>Name</th>
                                                                                      <th scope="col">Value</th>
                                                                                    </tr>
                                                                                    {renderElem(-1, schema_type)}
                                                                                  </>
                                                                                </Accordion.Body>
                                                                            </Accordion.Item>
                                                                        )
                                                                    } 
                                                                </Accordion>
                                                                  )
                                                            }}) : !Array.isArray(value) ?
                                                            <Accordion.Item eventKey={1} >
                                                              <Accordion.Header><h5>{value['@type']}</h5></Accordion.Header>
                                                              <Accordion.Body>
                                                                <>
                                                                  <tr className='table-secondary'>
                                                                    <th scope="col" className='w-50'>Name</th>
                                                                    <th scope="col">Value</th>
                                                                  </tr>
                                                                  {renderElem(-1, value)}
                                                                </>
                                                              </Accordion.Body>
                                                          </Accordion.Item> :
                                                          value.map((schema_type, idx) => 
                                                                <Accordion.Item eventKey={idx} >
                                                                    <Accordion.Header><h5>{schema_type['@type']}</h5></Accordion.Header>
                                                                    <Accordion.Body>
                                                                      <>
                                                                        <tr className='table-secondary'>
                                                                          <th scope="col" className='w-50'>Name</th>
                                                                          <th scope="col">Value</th>
                                                                        </tr>
                                                                        {renderElem(-1, schema_type)}
                                                                      </>
                                                                    </Accordion.Body>
                                                                </Accordion.Item>
                                                            )
                                                      } 
                                                  </Accordion>
                                                  )
                                              }
                                            }) :
                                            item['schema'][type].map((schema_type, idx) => 
                                                Object.keys(schema_type).includes('@graph') ?
                                                Object.entries(schema_type).map(([heading, v]) => {
                                                  // console.log(value);
                                                  if(heading === '@graph'){
                                                      // console.log(v)
                                                      if (Array.isArray(v)){
                                                        return (
                                                          <Accordion>
                                                            {
                                                              v.map((schema_type, idx) => 
                                                                    <Accordion.Item eventKey={idx} >
                                                                        <Accordion.Header><h5>{schema_type['@type']}</h5></Accordion.Header>
                                                                        <Accordion.Body>
                                                                          <>
                                                                            <tr className='table-secondary'>
                                                                              <th scope="col" className='w-50'>Name</th>
                                                                              <th scope="col">Value</th>
                                                                            </tr>
                                                                            {renderElem(-1, schema_type)}
                                                                          </>
                                                                        </Accordion.Body>
                                                                    </Accordion.Item>
                                                                )
                                                            } 
                                                        </Accordion>
                                                          )
                                                      } else {
                                                        return (
                                                          <Accordion>
                                                            <Accordion.Item eventKey={idx} >
                                                                <Accordion.Header><h5>{v['@type']}</h5></Accordion.Header>
                                                                <Accordion.Body>
                                                                  <>
                                                                    <tr className='table-secondary'>
                                                                      <th scope="col" className='w-50'>Name</th>
                                                                      <th scope="col">Value</th>
                                                                    </tr>
                                                                    {renderElem(-1, v)}
                                                                  </>
                                                                </Accordion.Body>
                                                            </Accordion.Item>
                                                        </Accordion>
                                                        )
                                                      }
                                                      
                                                  }}) :
                                                <Accordion.Item eventKey={idx} >
                                                    <Accordion.Header><h5>{schema_type['@type']}</h5></Accordion.Header>
                                                    <Accordion.Body>
                                                      <>
                                                        <tr className='table-secondary'>
                                                          <th scope="col" className='w-50'>Name</th>
                                                          <th scope="col">Value</th>
                                                        </tr>
                                                        {renderElem(-1, schema_type)}
                                                      </>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                              )
                                          } 
                                        </Accordion>
                                        </> :
                                        null
                                    )
                                  }
                                  </div>
                                </div>
                              </div>
                              )
                          }
                        </div> : tab === 'stats' ?
                        <div class="d-flex" aria-labelledby="stats">
                          <div className='d-flex flex-column w-100 overflow-auto' style={{height: '800px'}}>
                            <h5>Word count</h5>
                            <div style={{width: '100%', height: '400px'}}>
                              <ResponsiveContainer width="100%" height={400}>
                                <BarChart
                                data={stats}
                                // layout="vertical"
                                margin={{
                                    top: 30,
                                    right: 30,
                                    left: 20,
                                    bottom:-30,
                                }}
                                >
                                <CartesianGrid strokeDasharray="3 3" />
                                <YAxis
                                  type="number" 
                                  domain={[0, 'auto']}
                                />
                                <XAxis 
                                  dataKey="name"
                                  // type="category"
                                />
                                <Tooltip 
                                    wrapperStyle={{ backgroundColor: '#f8f9fa', padding: '2px' }}
                                    content={<CustomTooltip />}
                                />
                                <Legend formatter={() => 'word count'}/>
                                <Bar dataKey="value" fill="#8884d8" isAnimationActive={false} />
                                </BarChart>
                              </ResponsiveContainer>
                            </div>
                            <div className='my-5 d-flex flex-row align-items-center'>
                              <h5 className='me-3'>Keyword density</h5>
                              <a href='#' className='me-auto' onClick={e => {e.preventDefault(); kwDensityHandler()}}>
                                <FontAwesomeIcon icon={faRefresh} className='me-2'/>
                                Check keyword density
                              </a>
                              <select class="form-select w-auto me-2" aria-label="Default select example" onChange={e => setEnrichTop(e.target.value)}>
                                {
                                  Array.from(Array(10).keys()).map(item => 
                                    <option value={item + 1} selected={(item + 1) === parseInt(enrichTop)}>{`Top ${item+1}`}</option>
                                    )
                                }
                              </select>
                              <a href='#' className='me-2' onClick={enrichKwHandler}>
                                <FontAwesomeIcon icon={faLevelUp} className='me-2'/>
                                Enrich
                              </a>
                            </div>
                            <table class="table">
                              <thead className='sticky-top table-primary'>
                                <tr>
                                  <th scope="col">#</th>
                                  <th>
                                    <button class="btn" type="button" aria-expanded="false" onClick={event => sortHandler('stats', 'kw')}>
                                      <span className='me-2 fw-bold'>Keyword </span>
                                      <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'keyword_inc' ? '#000' : '#6c757d'}`}}/>
                                      <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'keyword_dec' ? '#000' : '#6c757d'}`}}/>
                                  </button>
                                  </th>
                                  <th>
                                    <button class="btn" type="button" aria-expanded="false" onClick={event => sortHandler('stats', 'min')}>
                                        <span className='me-2 fw-bold'>Min </span>
                                        <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'min_inc' ? '#000' : '#6c757d'}`}}/>
                                        <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'min_dec' ? '#000' : '#6c757d'}`}}/>
                                    </button>
                                  </th>
                                  <th>
                                    <button class="btn" type="button" aria-expanded="false" onClick={event => sortHandler('stats', 'max')}>
                                        <span className='me-2 fw-bold'>Max </span>
                                        <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'max_inc' ? '#000' : '#6c757d'}`}}/>
                                        <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'max_dec' ? '#000' : '#6c757d'}`}}/>
                                    </button>
                                  </th>
                                  <th>
                                    <button class="btn" type="button" aria-expanded="false" onClick={event => sortHandler('stats', 'count')}>
                                        <span className='me-2 fw-bold'>Appear on </span>
                                        <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'count_inc' ? '#000' : '#6c757d'}`}}/>
                                        <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'count_dec' ? '#000' : '#6c757d'}`}}/>
                                    </button>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  kwDensity.map((item, idx) => 
                                    <tr>
                                      <td>{idx+1}</td>
                                      <td>{item.keyword}</td>
                                      <td>{item.min}</td>
                                      <td>{item.max}</td>
                                      <td>{`${item.count}/10`}</td>
                                    </tr>
                                    )
                                }
                              </tbody>
                            </table>
                          </div>
                        </div> : tab === 'keyword' ?
                      <div class="d-flex flex-column" aria-labelledby="keyword-tab">
                        <div className='d-flex overflow-auto' style={{height: '600px'}}>
                          <table class="table">
                            <thead className='sticky-top table-primary'>
                              <tr>
                                <th scope="col">#</th>
                                <th scope="col">
                                  <button class="btn" type="button" aria-expanded="false" onClick={event => sortHandler('mapped', 'kw')}>
                                    <span className='me-2 fw-bold'>Keyword </span>
                                    <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'keyword_inc' ? '#000' : '#6c757d'}`}}/>
                                    <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'keyword_dec' ? '#000' : '#6c757d'}`}}/>
                                </button>
                                </th>
                                <th scope="col">
                                  <button class="btn" type="button" aria-expanded="false" onClick={event => sortHandler('mapped', 'volume')}>
                                    <span className='me-2 fw-bold'>Volume </span>
                                    <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'volume_inc' ? '#000' : '#6c757d'}`}}/>
                                    <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'volume_dec' ? '#000' : '#6c757d'}`}}/>
                                  </button>
                                </th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                keywords.map((kw, idx) => {
                                  return (
                                    <tr>
                                      <th scope="row">{idx+1}</th>
                                      <td>{kw.split('<<>>')[0]}</td>
                                      <td>{kw.split('<<>>')[1]}</td>
                                      <td className=''>
                                        <button class="btn p-0" type="button" onClick={e => {search_key.current.value = kw.split('<<>>')[0]}}>
                                          <FontAwesomeIcon icon={faSearch} size='sm' rotation={90}/>
                                        </button>
                                      </td>
                                    </tr>
                                  )
                                })
                              }
                            </tbody>
                          </table>
                        </div>
                      </div> :
                      <div class="d-flex flex-column" aria-labelledby="fact-tab">
                        <div className='d-flex'> 
                          <a href="#" class="me-auto mb-4" onClick={extractLegislationHandler}>
                            Extract & Validate Legislations
                          </a>
                          <div class="dropdown ms-auto" >
                            <button class="btn btn-light dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
                              {`Ref. sources (${legalRefs.length})`}
                            </button>
                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1" style={{'max-height': '300px', 'overflow': 'auto', zIndex: 9999}}>
                              {
                                initHeadings.map((item, index) => {
                                  if (index < 10){
                                    return (
                                      <li className='ms-2'>
                                        <div className=" form-check">
                                          <input className="form-check-input" type="checkbox" name={`exampleRadios${index}`} id={`exampleRadios${index}`} value={`Rank ${item.rank}`}
                                          {...(legalRefs.includes(item.rank) && {checked:true})}
                                          onChange={e => e.target.checked ? setLegalRefs( c => [...c, item.rank]) : setLegalRefs( c => c.filter(it => it !== item.rank))}
                                          />
                                          <label className="form-check-label" for={`exampleRadios${index}`}>
                                            {`${item.root_domain}`}
                                          </label>
                                        </div>
                                      </li>
                                    )
                                  }
                                  
                                })
                              }
                            </ul>
                          </div>
                        </div>
                        <div className='d-flex overflow-auto' style={{height: '300px'}}>
                          <table class="table">
                            <thead className='sticky-top table-primary'>
                              <tr>
                                <th scope="col">#</th>
                                <th scope="col">
                                  <button class="btn" type="button" aria-expanded="false" onClick={event => sortHandler('legislation', 'domain')}>
                                    <span className='me-2 fw-bold'>Domain </span>
                                    <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'domain_inc' ? '#000' : '#6c757d'}`}}/>
                                    <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'domain_dec' ? '#000' : '#6c757d'}`}}/>
                                </button>
                                </th>
                                <th scope="col">
                                  <button class="btn" type="button" aria-expanded="false" onClick={event => sortHandler('legislation', 'heading')}>
                                    <span className='me-2 fw-bold'>Heading</span>
                                    <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'heading_inc' ? '#000' : '#6c757d'}`}}/>
                                    <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'heading_dec' ? '#000' : '#6c757d'}`}}/>
                                  </button>
                                </th>
                                <th scope="col">
                                  <button class="btn" type="button" aria-expanded="false" onClick={event => sortHandler('legislation', 'legislation')}>
                                    <span className='me-2 fw-bold'>Legislation</span>
                                    <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'legislation_inc' ? '#000' : '#6c757d'}`}}/>
                                    <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'legislation_dec' ? '#000' : '#6c757d'}`}}/>
                                  </button>
                                </th>
                                <th scope="col">
                                  <button class="btn" type="button" aria-expanded="false" onClick={event => sortHandler('legislation', 'section')}>
                                    <span className='me-2 fw-bold'>Section</span>
                                    <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'section_inc' ? '#000' : '#6c757d'}`}}/>
                                    <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'section_dec' ? '#000' : '#6c757d'}`}}/>
                                  </button>
                                </th>
                                <th scope="col">
                                  <button class="btn" type="button" aria-expanded="false" onClick={event => sortHandler('legislation', 'Tình trạng')}>
                                    <span className='me-2 fw-bold'>Valid</span>
                                    <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'Tình trạng_inc' ? '#000' : '#6c757d'}`}}/>
                                    <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'Tình trạng_dec' ? '#000' : '#6c757d'}`}}/>
                                  </button>
                                </th>
                                <th scope="col"></th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                legislation.map((item, idx) => (
                                  <tr>
                                    <td scope="row">{idx+1}</td>
                                    <td>{item['domain']}</td>
                                    <td>{item['heading']}</td>
                                    <td><a href={item['link']} target="_blank">{item['legislation']}</a></td>
                                    <td>{item['section']}</td>
                                    <td>{item['Tình trạng']}</td>
                                    <td>
                                      <button className='btn d-flex mt-2' onClick={e => summarizeRegHandler(item['domain'], item['heading'])}>
                                        <FontAwesomeIcon icon={faCompressAlt} />
                                      </button>
                                      <button className='btn d-flex mt-2' onClick={e => addCurrentNoteHandler(item)}>
                                        <FontAwesomeIcon icon={faAdd} />
                                      </button>
                                    </td>
                                  </tr>
                                ))
                              }
                            </tbody>
                          </table>
                        </div>
                        {/* Modal Add Notes*/}
                        <div class="modal fade" id="ModalAddNotes" tabindex="-1" role="dialog" aria-labelledby="ModalCenterTitle" aria-hidden="true">
                          <div class="modal-dialog modal-dialog-centered" role="document">
                              <div class="modal-content">
                                <div className="modal-header">
                                    <h5 class="modal-title me-auto" id="ModalLongTitle">Add/Edit Notes</h5>
                                    <div className={`${[false,'',null].includes(importStat) ? 'd-none' : ''}`} id="success-noti">
                                      <FontAwesomeIcon icon={faCheckCircle} style={{'color':'green'}}/>
                                      <span className='ms-1'>Success</span>
                                    </div>
                                    <div className={`${[true,'',null].includes(importStat) ? 'd-none' : ''}`} id="failed-noti">
                                      <FontAwesomeIcon icon={faTimesCircle} style={{'color':'red'}}/>
                                      <span className='ms-1'>Failed. Pls Retry!</span>
                                    </div>
                                </div>
                                <div class="modal-body" style={{'max-height':'500px', 'overflow':'auto'}}>
                                  <div class="d-flex flex-column mb-4">
                                    <div class="input-group mb-3">
                                        <span class="input-group-text">Heading</span>
                                        <input type="text" class="form-control" placeholder="Topic" aria-label="Topic" aria-describedby="basic-addon1" ref={headingNote}/>
                                    </div>
                                    <div className='d-flex'>
                                      <div class="input-group mb-3 me-2">
                                          <span class="input-group-text">Regulation</span>
                                          <input type="text" class="form-control" placeholder="Domain" aria-label="Domain" aria-describedby="basic-addon1" ref={regulation}/>
                                      </div>
                                      <div class="input-group mb-3 w-50">
                                          <span class="input-group-text">Section</span>
                                          <input type="text" class="form-control" placeholder="Secion" ref={section}/>
                                      </div>
                                    </div>
                                    <div class="form-floating">
                                      <textarea class="form-control" placeholder="Leave a description" id="floatingTextarea2" style={{height: '100px'}} ref={notes || ''}/>
                                      <label for="floatingTextarea2">Note</label>
                                    </div>
                                  </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" onClick={e => setImportStat('')}>Close</button>
                                    <button type="button" className={`btn btn-primary`} id='import-button' onClick={e => saveNoteHandler(headingNote.current.value, regulation.current.value, section.current.value, notes.current.value)}>Save</button>
                                </div>
                              </div>
                          </div>
                        </div>
                      {/* Modal Add Notes*/}
                        <div className='d-flex my-4'>
                          <h5 className='my-2 text-nowrap me-3'>Note list</h5>
                          <a href='#' className='my-2' onClick={e => addNoteHandler('','','','')}>
                            <FontAwesomeIcon icon={faPlusCircle} />
                            Add note
                          </a>
                          <div className='d-flex ms-auto me-2'>
                            <button type="button" class="btn btn-light" onClick={e => openSearchHandler()}>
                              <FontAwesomeIcon className='me-2' icon={faSearch} />
                            </button>
                          </div>
                        </div>
                        <div className='d-flex overflow-auto' style={{height: '300px'}}>
                          <table class="table table-hover">
                            <thead className='sticky-top table-primary'>
                              <tr>
                                <th scope="col">#</th>
                                <th scope="col">
                                  <button class="btn" type="button" aria-expanded="false" onClick={event => sortHandler('currentNoteList', 'heading')}>
                                    <span className='me-2 fw-bold'>Heading</span>
                                    <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'heading_inc' ? '#000' : '#6c757d'}`}}/>
                                    <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'heading_dec' ? '#000' : '#6c757d'}`}}/>
                                  </button>
                                </th>
                                <th scope="col">
                                  <button class="btn" type="button" aria-expanded="false" onClick={event => sortHandler('currentNoteList', 'legislation')}>
                                    <span className='me-2 fw-bold'>Legislation</span>
                                    <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'legislation_inc' ? '#000' : '#6c757d'}`}}/>
                                    <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'legislation_dec' ? '#000' : '#6c757d'}`}}/>
                                  </button>
                                </th>
                                <th scope="col">
                                  <button class="btn" type="button" aria-expanded="false" onClick={event => sortHandler('currentNoteList', 'section')}>
                                    <span className='me-2 fw-bold'>Section</span>
                                    <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'section_inc' ? '#000' : '#6c757d'}`}}/>
                                    <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'section_dec' ? '#000' : '#6c757d'}`}}/>
                                  </button>
                                </th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                currentNoteList.map((item, idx) => (
                                  <tr>
                                    <td scope="row">{idx+1}</td>
                                    <td>{item['heading']}</td>
                                    <td>
                                      {
                                        item['link'] ?
                                        <a href={item['link']} target='_blank'>{item['legislation']}</a> :
                                        item['legislation']
                                      }
                                    </td>
                                    <td>{item['section']}</td>
                                    <td className=''>
                                      <button className='btn' onClick={e => addNoteHandler(item['legislation'], item['section'], item['heading'], item['note'])} >
                                        <FontAwesomeIcon icon={faPencil} />
                                      </button>
                                      <button className='btn' onClick={e => deleteCurrentNoteHandler(item['heading'], item['legislation'], item['section'])}>
                                        <FontAwesomeIcon icon={faMinus} />
                                      </button>
                                    </td>
                                  </tr>
                                ))
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>
                      }
                    </div>
                  </div>
                </div>
                {/* Modal Fact Search*/}
                <div class="modal fade" id="ModalFactSearch" tabindex="-1" role="dialog" aria-labelledby="ModalCenterTitle" aria-hidden="true">
                  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                      <div class="modal-content">
                        <div className="modal-header">
                            <h5 class="modal-title me-auto" id="ModalLongTitle">Fact-check references</h5>
                        </div>
                        <div class="modal-body" style={{'max-height':'500px', 'overflow':'auto'}}>
                          <div className='d-flex'>
                            <div class="input-group mb-3 me-2">
                              <label class="input-group-text" for="topicCategory">Topic Category</label>
                              <select class="form-select" id="topicCategory" onChange={e => loadAllCategoricalTopics(e.target.value)}>
                                <option selected>Choose...</option>
                                <option value='all'>All</option>
                                {
                                  allCategories.map(item => (
                                    <option value={item}>{item}</option>
                                  ))
                                }
                              </select>
                            </div>
                            <div class="input-group mb-3 me-2">
                              <label class="input-group-text" for="topic">Topic</label>
                              <select class="form-select" id="topic" onChange={e => loadAllNotesHandler(e.target.value)}>
                                <option selected>Choose...</option>
                                <option value='all'>All</option>
                                {
                                  allTopics.map(item => (
                                    <option value={item}>{item}</option>
                                  ))
                                }
                              </select>
                            </div>
                          </div>
                          <div class="d-flex mb-4 align-items-center">
                            
                          </div>
                          <div className='d-flex ms-auto mb-2'>
                            <OverlayTrigger trigger="click" placement="bottom" rootClose overlay={urlFilter} container={this} containerPadding={20}>
                                <button type="button" class="btn btn-light">
                                    <FontAwesomeIcon className='me-2' icon={faFilter} />
                                </button>
                            </OverlayTrigger>
                          </div>
                          <div className='d-flex' style={{height: '300px'}}>
                            <div className='d-flex col-md-8 me-2 overflow-auto'>
                              <table class="table table-hover">
                                <thead className='sticky-top table-primary'>
                                  <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">
                                      <button class="btn" type="button" aria-expanded="false" onClick={event => sortHandler('filteredRefNotes', 'category')}>
                                        <span className='me-2 fw-bold'>Category </span>
                                        <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'category_inc' ? '#000' : '#6c757d'}`}}/>
                                        <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'category_dec' ? '#000' : '#6c757d'}`}}/>
                                      </button>
                                    </th>
                                    <th scope="col">
                                      <button class="btn" type="button" aria-expanded="false" onClick={event => sortHandler('filteredRefNotes', 'topic')}>
                                        <span className='me-2 fw-bold'>Topic</span>
                                        <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'topic_inc' ? '#000' : '#6c757d'}`}}/>
                                        <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'topic_dec' ? '#000' : '#6c757d'}`}}/>
                                      </button>
                                    </th>
                                    <th scope="col">
                                      <button class="btn" type="button" aria-expanded="false" onClick={event => sortHandler('filteredRefNotes', 'legislation')}>
                                        <span className='me-2 fw-bold'>Legislation</span>
                                        <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'legislation_inc' ? '#000' : '#6c757d'}`}}/>
                                        <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'legislation_dec' ? '#000' : '#6c757d'}`}}/>
                                      </button>
                                    </th>
                                    <th scope="col">
                                      <button class="btn" type="button" aria-expanded="false" onClick={event => sortHandler('filteredRefNotes', 'section')}>
                                        <span className='me-2 fw-bold'>Section</span>
                                        <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'section_inc' ? '#000' : '#6c757d'}`}}/>
                                        <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'section_dec' ? '#000' : '#6c757d'}`}}/>
                                      </button>
                                    </th>
                                    <th scope="col"></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {
                                    filteredRefNotes.map((item, idx) => (
                                      <tr onClick={e => selectSearchNoteHandler(item['heading'], item['note'])}>
                                        <td scope="row">{idx+1}</td>
                                        <td>{item['category']}</td>
                                        <td>{item['topic']}</td>
                                        <td>
                                          {
                                            item['link'] ?
                                            <a href={item['link']} target='_blank'>{item['legislation']}</a> :
                                            item['legislation']
                                          }
                                        </td>
                                        <td>{item['section']}</td>
                                        <td>
                                          <button className='btn'
                                            onClick={e => addCurrentNoteHandler(item)}
                                          ><FontAwesomeIcon icon={faPlusCircle} /></button></td>
                                      </tr>
                                    ))
                                  }
                                </tbody>
                              </table>
                            </div>
                            <div className='d-flex col-md-4'>
                              <div class="form-floating w-100 pe-3">
                                <textarea class="form-control" placeholder="Notes" id="notes" style={{height: '300px'}} ref={searchNotesContent}>
                                </textarea>
                                <label for="motes">Notes</label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" onClick={e => setImportStat('')}>Close</button>
                        </div>
                      </div>
                  </div>
                </div>
                {/* Modal Fact Search*/}
                <div className="row row-cols-1 g-4 overflow-auto justify-content-center">
                  {initHeadings.length === 0 && !['keyword', 'schema', 'stats', 'fact'].includes(tab) && <h5 className="text-muted text-center fs-2" style={{"width": "90%", "line-height": "200px", "border": "1px dashed #adb5bd"}}>SERP Outline</h5>}
                  {tab === 'serp' ? initHeadings.map((item) => (
                    <CartBoardEditor
                      items={item}
                      disabled={disabled}
                      onDragStart={onDragStart}
                      onInsertContent={insertContentHandler}
                      onLLMSummarize={llmSummarizeHandler}
                      detail={detail}
                      onUpdateItem={updateItemHandler}
                      location={location}
                      activeJS = {activeJSRender}
                      ref={pageRef}
                    />))
                    : tab === 'faqs' ? initQuestions.map((item) => (
                    <Question
                    items={item}
                    disabled={disabled}
                    onDragStart={onDragStart}
                    onInsertContent={insertContentHandler}
                    forumFilter={forumFilter}
                    // detail={detail}
                  />
                  )) : null
                }
                </div>
            </div>
            <div className=" d-flex flex-column vh-100 col-md-6">
              <div className="d-flex flex-row mb-4">
                <h3 className="me-4">Editor</h3>
                <div className="dropdown me-2">
                  <button class="btn btn-light dropdown-toggle" type="button" id="outlinelang" data-bs-toggle="dropdown" aria-expanded="false">
                    {editorLang === '' ?
                    'Lang' :
                    editorLang}
                  </button>
                  <ul class="dropdown-menu" aria-labelledby="outlinelang" >
                    <li><a class="dropdown-item" href="#" onClick={e => setEditorLang('en')}>EN</a></li>
                    <li><a class="dropdown-item" href="#" onClick={e => setEditorLang('vi')}>VI</a></li>
                  </ul>
                </div>
                <button
                  type="button"
                  className='btn btn-primary me-auto'
                  onClick={(event) => exportFileHandler(event, editorRef.current.getContent())}
                >
                  <FontAwesomeIcon icon={faDownload} />
                </button>
                {autoSave === true &&
                  <div className={`d-flex me-2 align-items-center justify-content-end ms-2`} id="success-noti">
                    <FontAwesomeIcon icon={faCheckCircle} style={{'color':'green'}}/>
                    <span className='ms-1'>Saved</span>
                  </div>
                }
                {/* <div class="d-flex me-2"> */}
                    {/* <button class="btn btn-secondary" type="button" id="language" aria-expanded="false"
                      onClick={openWordpressModal}
                    >
                        <FontAwesomeIcon icon={faWordpress} className='me-2' />
                        Save
                    </button> */}
                    {/* <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        <li><a class="dropdown-item" href="#"
                        onClick={e => {e.preventDefault(); setWordpressAction('Create')}}
                        >Create</a></li>
                        <li><a class="dropdown-item" href="#"
                        onClick={e => {e.preventDefault(); setWordpressAction('Get')}}
                        >Get</a></li>
                        <li><a class="dropdown-item" href="#"
                        onClick={e => {e.preventDefault(); setWordpressAction('Update')}}
                        >Update</a></li>
                    </ul> */}
                {/* </div> */}
                {/* Modal Wordpress*/}
                <div class="modal fade" id="ModalWordpress" ref={wordpressModal} tabindex="-1" role="dialog" aria-labelledby="ModalCenterTitle" aria-hidden="true">
                  <div class="modal-dialog modal-dialog-centered" role="document">
                      <div class="modal-content">
                        <div className="modal-header">
                            <h5 class="modal-title me-auto">Save Wordpress</h5>
                            <div className={`${[false,'',null].includes(importStat) ? 'd-none' : ''}`} id="success-noti">
                              <FontAwesomeIcon icon={faCheckCircle} style={{'color':'green'}}/>
                              <span className='ms-1'>Success</span>
                            </div>
                            <div className={`${[true,'',null].includes(importStat) ? 'd-none' : ''}`} id="failed-noti">
                              <FontAwesomeIcon icon={faTimesCircle} style={{'color':'red'}}/>
                              <span className='ms-1'>Failed. Pls Retry!</span>
                            </div>
                        </div>
                        <div class="modal-body">
                          <div class="d-flex flex-column mb-4">
                            <div class="input-group mb-3">
                                <span class="input-group-text">Title</span>
                                <input type="text" class="form-control" placeholder="Title" aria-label="Title" aria-describedby="basic-addon1" ref={wTitle}/>
                            </div>
                            <div class="input-group mb-3">
                              <span class="input-group-text">Site</span>
                              <select class="form-select" id="site" onChange={e => getSiteCategoryHandler(e.target.value)}>
                                <option selected value='hk'>BBCIncorp HK</option>
                                <option value="sg">BBCIncorp SG</option>
                                <option value="offshore">BBCIncorp Offshore</option>
                                <option value="vn">BBCIncorp VN</option>
                              </select>
                            </div>
                            <div class="dropdown">
                              <button class="btn btn-light dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
                                {`Category (${totalWPCategory.length})`}
                              </button>
                              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1" style={{'max-height': '300px', 'overflow': 'auto'}}>
                                {
                                  wCategory.map((item, index) => {
                                    return (
                                      <li className='ms-2'>
                                        <div className="form-check">
                                          <input className="form-check-input row-checkbox" type="checkbox" name={`exampleRadios${index}`} id={`exampleRadios${index}`} value={`Rank ${item.id}`}
                                          {...(totalWPCategory.includes(item.rank) && {checked:true})}
                                          onChange={e => e.target.checked ? setTotalWPCategory( c => [...c, item.id]) : setTotalWPCategory( c => c.filter(it => it !== item.id))}
                                          />
                                          <label className="form-check-label" for={`exampleRadios${index}`}>
                                            <span>{item.name}</span>
                                          </label>
                                        </div>
                                      </li>
                                    )
                                  })
                                }
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" onClick={e => setImportStat('')}>Close</button>
                            <button type="button" className={`btn btn-primary`} onClick={importWordpress}>Save</button>
                        </div>
                      </div>
                  </div>
                </div>
                {/* Modal Wordpress*/}
                <div className="d-flex">
                  <button type="button" className="btn btn-primary" onClick={handleSaveOutline}>Save</button>
                  <button type="button" className="btn btn-primary" onClick={saveOutlineHandler}>
                  <FontAwesomeIcon icon={faUpRightFromSquare}/>
                  </button>
                </div>
              </div>
              {/* Modal Pixabay*/}
              <div class={`modal fade`} id="ModalPixabay" tabindex="-1" role="dialog" aria-labelledby="ModalCenterTitle" >
                  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                      <div class="modal-content">
                        <div className="modal-header">
                            <h5 class="modal-title me-auto" id="ModalLongTitle">Insert Image</h5>
                        </div>
                        <div class="modal-body">
                          <ul class="nav nav-tabs">
                            <li class="nav-item">
                              <a class={`nav-link ${imageMode === 'pixabay' && 'active'}`} aria-current="page" href="#" onClick={e => setImageMode('pixabay')}>Pixabay</a>
                            </li>
                            <li class="nav-item">
                              <a class={`nav-link ${imageMode === 'upload' && 'active'}`} href="#" onClick={e => setImageMode('upload')}>Upload</a>
                            </li>
                          </ul>
                          <div class={`input-group my-3 ${imageMode !== 'pixabay' && 'd-none'}`}>
                            <span class="input-group-text bg-white"><FontAwesomeIcon icon={faSearch} /></span>
                            <input type="text" class="form-control" placeholder="Search photos by keyword" 
                              onChange={handleDelayedChange}
                            />
                          </div>
                          <div className={`d-flex flex-column overflow-auto ${imageMode !== 'pixabay' && 'd-none'}`} style={{height: '450px'}}>
                            { Object.keys(images).length > 0 && images['hits'].length > 0 &&
                              chunkArray(images['hits'],4).map((item, idx) => 
                              <div className='row'>
                                {
                                  item.map(it => (
                                    <div className='col d-flex flex-column' onClick={e => insertImgHandler(it['largeImageURL'])}>
                                      <img src={`${it['previewURL']}`} class="img-thumbnail" alt="..." />
                                      <span>{it['tags']}</span>
                                    </div>
                                ))
                                }
                              </div>
                              )
                            }
                          </div>
                          <div className={`d-flex flex-column ${imageMode !== 'upload' && 'd-none'} m-4`} style={{height: '480px'}}
                            onDrop={handleDrop}
                            onDragOver={handleDragOver}
                          >
                            <div className='d-flex flex-column align-items-center justify-content-center bg-light h-100 w-100' style={{border: '2px dashed #dee2e6'}}>
                              <h5>Drag and drop an image here</h5>
                              <h5>--- or ---</h5>
                              <input class="form-control d-none" id="formFile" type="file" placeholder="" ref={fileUpload} onChange={e => {
                                if (e.target.files.length > 0) {
                                  fileUploadHandler();
                                }
                              }}/>
                              <button type="button" class="btn btn-dark" onClick={e => {fileUpload.current.value = null; fileUpload.current.click()}}>Select from your files</button>
                            </div>
                          </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" onClick={e => setImportStat('')} ref={pixaBayClose}>Close</button>
                        </div>
                      </div>
                  </div>
                </div>
              {/* Modal Pixabay*/}
              {/* Modal Import*/}
              <div class="modal fade" id="ModalImport" tabindex="-1" role="dialog" aria-labelledby="ModalCenterTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                      <div className="modal-header">
                          <h5 class="modal-title me-auto" id="ModalLongTitle">Save Outline</h5>
                          <div className={`${[false,'',null].includes(importStat) ? 'd-none' : ''}`} id="success-noti">
                            <FontAwesomeIcon icon={faCheckCircle} style={{'color':'green'}}/>
                            <span className='ms-1'>Success</span>
                          </div>
                          <div className={`${[true,'',null].includes(importStat) ? 'd-none' : ''}`} id="failed-noti">
                            <FontAwesomeIcon icon={faTimesCircle} style={{'color':'red'}}/>
                            <span className='ms-1'>Failed. Pls Retry!</span>
                          </div>
                      </div>
                      <div class="modal-body" style={{'max-height':'500px', 'overflow':'auto'}}>
                        <div class="d-flex flex-column mb-4">
                          <div class="input-group mb-3">
                              <span class="input-group-text">Topic</span>
                              <input type="text" class="form-control" placeholder="Topic" aria-label="Topic" aria-describedby="basic-addon1" ref={topic}/>
                          </div>
                          <div class="input-group mb-3">
                              <span class="input-group-text">Domain</span>
                              <input type="text" class="form-control" placeholder="Domain" aria-label="Domain" aria-describedby="basic-addon1" ref={domain}/>
                          </div>
                          <div class="input-group mb-3">
                              <span class="input-group-text">Category</span>
                              <input type="text" class="form-control" placeholder="Category" aria-label="Category" aria-describedby="basic-addon1" ref={category}/>
                          </div>
                          <div class="form-floating">
                            <textarea class="form-control" placeholder="Leave a description" id="floatingTextarea2" style={{height: '100px'}} ref={description}/>
                            <label for="floatingTextarea2">Description</label>
                          </div>
                        </div>
                      </div>
                      <div class="modal-footer">
                          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" onClick={e => setImportStat('')}>Close</button>
                          <button type="button" className={`btn btn-primary`} id='import-button' onClick={importOutline}>Save</button>
                      </div>
                    </div>
                </div>
              </div>
            {/* Modal Import*/}
              <div className="row g-4 overflow-auto h-100">
                <div className="col">
                  <div className="card h-100">
                    <div className="card-body">
                      <div className="card-text h-100">
                        <Editor
                          apiKey='7kbo05huwibwa05biyzkb856nnpxv1tvflf8o3a85ideeaz7'
                          onInit={(evt, editor) => (editorRef.current = editor)}
                          initialValue={initialValue}
                          onEditorChange={(newValue, editor) => {
                            setIsContentChanged(true);
                            setAutoSave(false)}}
                          init={{
                            height: "100%",
                            menubar: false,
                            plugins: [
                              "advlist",
                              "autolink",
                              "lists",
                              "link",
                              "image",
                              "charmap",
                              "anchor",
                              "searchreplace",
                              "visualblocks",
                              "code",
                              "fullscreen",
                              "insertdatetime",
                              "media",
                              "table",
                              "preview",
                              "help",
                              "wordcount",
                              'contextmenu'
                            ],
                            contextmenu: 'customContextMenu',
                            // setup: handleEditorInit,
                            toolbar:
                              "undo redo | blocks | " +
                              "bold italic forecolor custommenu| alignleft aligncenter " +
                              "alignright alignjustify | bullist numlist outdent indent | " +
                              "removeformat | help",
                            // language: 'vi',
                            content_style:
                              "body { font-family:Roboto,Arial,sans-serif; font-size:14px }",
                          }}
                          
                        />
                        {/* <button onClick={log}>Log editor content</button> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}

export default OutlineEditor;
