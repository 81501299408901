import { combineReducers, createStore } from 'redux';
import { loadingBarReducer } from 'react-redux-loading-bar';

// const user = (state={username: ''}, action) => {
//   if(action.type === 'update') {
//     return {
//       username: action.username
//     }
//   }
// };

const reducer = combineReducers({
  // app reducers
  // user: user,
  loadingBar: loadingBarReducer,
});

const store = createStore(
    reducer
  );

export default store;