import React, { useState, useEffect } from 'react';
import { getAuthToken } from '../util/token';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Accordion from 'react-bootstrap/Accordion';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretRight, faAngleUp, faAngleDown, faFilter, faTrash } from '@fortawesome/free-solid-svg-icons';

import { v4 as uuid4 } from 'uuid';


const AuditURL = (props) => {
  const [loading, setLoading] = useState(false);
  const token = getAuthToken();
  const [sort, setSort] = useState('');
  const [open, setOpen] = useState([]);
  const [ activeTab, setActiveTab] = useState('');
  const [data, setData] = useState(props.data || []);

  const [filterArr, setFilterArr] = useState([{
      id: uuid4(),heading: 'URL', operation: 'contains', value: ''
  }]);
  const [filteredIssueURL, setFilteredIssueURL] = useState( data || []);

  useEffect(() => {
    setData(props.data);
    setFilteredIssueURL(props.data);
    // console.log(data)
  },[props.data])

  const renderNestedObject = (tier, data) => {
    // console.log(data);
    const d = Object.entries(data).map(([key, value]) => 
              typeof value === 'object' && !Array.isArray(value) && typeof value !== null ?
                <>
                  <tr className={`${tier === -1 && key === '@type' && 'table-light'}`}>
                    <td colspan="2"
                      onClick={(e) => {e.preventDefault();
                        console.log(open);
                        if (!open.includes(key)){
                            setOpen(c => [...c, key]);
                        } else{
                            let d = open.filter(item => item !== key);
                            setOpen(d);
                        }  
                    }}
                    role='button'
                    style={{paddingLeft: `${(tier+1)*20}px`}}
                    >
                      <FontAwesomeIcon icon={open.includes(key) ? faCaretDown : faCaretRight} className='me-2' />
                      {key}
                    </td>
                  </tr>
                  {/* <Collapse in={open.includes(key) ? true : false}> */}
                    <>
                    {open.includes(key) &&
                    renderNestedObject(tier + 1, value)}
                    </>
                  {/* </Collapse> */}
                </> : Array.isArray(value) ?
                <>
                {
                  value.map(v => 
                    typeof v === 'object' && !Array.isArray(v) && typeof v !== null ?
                    <>
                    <tr className={`${tier === -1 && key === '@type' && 'table-light'}`}>
                      <td colspan="2"
                        onClick={(e) => {e.preventDefault();
                          if (!open.includes(key)){
                              setOpen(c => [...c, key]);
                          } else{
                              let d = open.filter(item => item !== key);
                              setOpen(d);
                          }  
                      }}
                      role='button'
                      style={{paddingLeft: `${(tier+1)*20}px`}}
                      >
                        <FontAwesomeIcon icon={open.includes(key) ? faCaretDown : faCaretRight} className='me-2' />
                        {key}</td>
                    </tr>
                    {/* <Collapse in={open.includes(key) ? true : false}> */}
                      {open.includes(key) &&
                      renderNestedObject(tier + 1, v)}
                    {/* </Collapse> */}
                    </> :
                    <tr className={`${tier === -1 && key === '@type' && 'table-light'}`}>
                      <td style={{paddingLeft: `${(tier+1)*20}px`}}>{key}</td>
                      <td>{v}</td>
                    </tr>
                    )
                }
                </> :
                <tr className={`${tier === -1 && key === '@type' && 'table-light'}`}>
                  <td style={{paddingLeft: `${(tier+1)*20}px`}}>{key}</td>
                  <td>{value}</td>
                </tr>
            )
    // console.log(d)
    return d
  };
  const renderArr = (tier, data) => {
    // console.log(data);
    const d = data.map(item => 
          <>
          {renderNestedObject(tier, item)}
          </>
      );
    // console.log(d);
    return d
  }

  const renderElem = (tier, data) => {
    if (typeof data === 'object' && !Array.isArray(data) && typeof data !== null){
      return renderNestedObject(tier, data)
      // return (
      //   <>
      //     {Object.entries(data).map(([key, value]) => 
      //     <>
      //       <tr className={`${tier === -1 && key === '@type' && 'table-light'}`}>
      //         <td colspan="2">{key}</td>
      //       </tr>
      //       {renderNestedObject(tier, value)}
      //     </>
      //     )}
      //   </>
      // )
    } else if (Array.isArray(data)){
      // console.log(data);
      return renderArr(tier, data)
    }
  };

  const sortHandler = (tab, heading) => {
    // console.log(issueURL);
    if (sort !== `${heading}_inc` && sort !== `${heading}_dec`){
    setSort(`${heading}_inc`);
    filteredIssueURL[tab].sort((a,b) => (b[heading] > a[heading]) ? -1 : (b[heading] < a[heading]) ? 1 : 0);
    } else if(sort === `${heading}_dec`){
    setSort('keyword_inc');
    filteredIssueURL[tab].sort((a,b) => (b[heading] > a[heading]) ? -1 : (b[heading] < a[heading]) ? 1 : 0);
    } else if(sort === `${heading}_inc`){
    setSort(`${heading}_dec`);
    filteredIssueURL[tab].sort((a,b) => (b[heading] > a[heading]) ? 1 : (b[heading] < a[heading]) ? -1 : 0);
    };
  };

  const incFilterHandler = (filterID) => {
    if(filterArr.length === 3 ) return;
    setFilterArr( c => {
        const d = [...c];
        d.push({id: filterID ,heading: 'URL', operation: 'contains', value: ''});
        return d
    });
    // console.log(filterArr);
  };
  const updateFilterHandler = (filterID, type, value) => {
      const newArr = filterArr.filter(item => {
          if(item.id === filterID){
              const d = item;
              item[type] = value;
              return d
          }else {
              return item
          }
      });
      setFilterArr(newArr);
      // console.log(filterArr);
  };
  const applyFilterHandler = () => {
    //filterArr[{id, heading, operation, value}]
    console.log('re render');
    let arr = data[activeTab].filter(item => {
        let count = 0;
        filterArr.map(condition => {
            if (condition.operation === 'contains'){
                count += eval(`"${item[condition.heading]}".toLowerCase().includes("${condition.value}".toLowerCase())`)
            }else if (condition.operation === 'does not contain'){
                count += eval(`!"${item[condition.heading]}".toLowerCase().includes("${condition.value}".toLowerCase())`)
            }else if (condition.operation === 'is'){
                // console.log(`"${item[condition.heading]}" === "${condition.value}"`);
                count += eval(`"${item[condition.heading]}" === "${condition.value}"`)
            }else if (condition.operation === 'is not'){
                console.log(`"${item[condition.heading]}" !== "${condition.value}"`);
                count += eval(`"${item[condition.heading]}" !== "${condition.value}"`)
            }else if(condition.operation === 'greater than') {
                count += eval(`"${item[condition.heading]}" > "${condition.value}"`)
            }else if (condition.operation === 'greater than or equal'){
                count += eval(`"${item[condition.heading]}" >= "${condition.value}"`)
            }else if (condition.operation === 'less than'){
                count += eval(`"${item[condition.heading]}" < "${condition.value}"`)
            }else if (condition.operation === 'less than or equal'){
                count += eval(`"${item[condition.heading]}" <= "${condition.value}"`)
            } 
        })
        return count === filterArr.length
    });
    if (arr.length === 0){
      let c = {};
      Object.keys(data[activeTab][0]).map(key => {c[[key]] = ''});
      arr.push(c);
    }
    // console.log(arr)
    setFilteredIssueURL(c => {
      const d = {...c};
      d[activeTab] = arr;
      // console.log(d);
      return d
      });
    // console.log(filteredIssueURL)
  };
  const deleteFilterHandler = (filterID) => {
    let arr = []
    if (filterArr.length === 1){
      arr = [{
        id: uuid4(),heading: 'URL', operation: 'contains', value: ''
    }]
    } else {
      arr = filterArr.filter(item => item.id !== filterID)
    }
    setFilterArr(arr);
    console.log(filterArr);
  }

  const urlFilter = (
      <Popover id="popover-positioned-top" title="Popover bottom" style={{"max-width":"500px", 'border': 'none'}}>
          <div className='d-flex flex-column p-4 bg-white shadow'>
              <p>Filter URLs</p>
              {
                  filterArr.map(item => {
                      // console.log(item);
                      return (
                          <div className='d-flex align-items-center' key={item}>
                              where
                              <select class="form-select form-select-sm" aria-label="Default select example" className='ms-3 me-2' style={{maxWidth: '100px'}}
                                  onChange={e => updateFilterHandler(item.id, 'heading', e.target.value)}
                              >
                                {/* <Form.Select aria-label="Default select example"> */}
                                  <option value='URL' selected={item.heading === '' ? true : item.heading === 'URL' ? true : false}>Select Option</option>
                                  { !['URL Detail', 'Structured Data', ''].includes(activeTab) &&
                                      Object.keys(data[activeTab][0]).map(heading =>{
                                          // console.log(data[activeTab]);
                                          if (heading !== 'URL') {
                                              return (<option value={heading} selected={item['heading'] === heading}>{heading}</option>)
                                          } 
                                      })
                                  }
                                {/* </Form.Select> */}
                              </select>
                              <select class="form-select form-select-sm" aria-label="Default select example" className='me-2'
                                  onChange={e => updateFilterHandler(item.id, 'operation', e.target.value)}
                              >
                                  <option value='contains' selected={item.operation === '' ? true : item.operation === 'contains' ? true : false}>contains</option>
                                  <option value="does not contain" selected={item.operation === 'does not contain'}>does not contain</option>
                                  <option value="is" selected={item.operation === 'is'}>is</option>
                                  <option value="is not" selected={item.operation === 'is not'}>is not</option>
                                  <option value="greater than" selected={item.operation === 'greater than'}>greater than</option>
                                  <option value="greater than or equal" selected={item.operation === 'greater than or equal'}>greater than or equal</option>
                                  <option value="less than" selected={item.operation === 'less than'}>less than</option>
                                  <option value="less than or equal" selected={item.operation === 'less than or equal'}>less than or equal</option>
                              </select>

                              <input type="text" className="form-control me-2" style={{width: '120px'}} placeholder="Enter a value" aria-label="Enter a value" aria-describedby="basic-addon2" 
                                  defaultValue={item.value}
                                  onChange={e => updateFilterHandler(item.id, 'value', e.target.value)}
                              />
                              <button className='btn'
                                  onClick={e => {return}}
                              ><FontAwesomeIcon icon={faTrash} onClick={e => deleteFilterHandler(item.id)}/></button>
                          </div>
                      )
                  })
              }
              <div className='d-flex mt-4'>
                  <button type="button" class={`btn btn-light me-auto ${filterArr.length === 3 && 'disabled'}`} onClick={e => incFilterHandler(uuid4())}>+ Add condition</button>
                  <button type="button" class="btn btn-primary" onClick={applyFilterHandler}>Apply</button>
              </div>
              
          </div>
      </Popover>
  );

  function handleSelect(tab){
    setActiveTab(tab);
    console.log(tab)
  }

  return (
    <div className='d-flex w-100 flex-column'>
      <div className='d-flex mb-4 mt-2 ms-auto'>
          <OverlayTrigger trigger="click" placement="bottom" rootClose overlay={urlFilter} container={this} containerPadding={20}>
              <button type="button" class="btn btn-light">
                  <FontAwesomeIcon className='me-2' icon={faFilter} />
                  Filter URLs
              </button>
          </OverlayTrigger>
      </div>
      <div className='w-100'>
        <Tabs
          defaultActiveKey="URL Detail"
          id="fill-tab-example"
          className="mb-3 sticky-top bg-light"
          fill
          onSelect={e => handleSelect(e)}
        >
          {
            Object.keys(filteredIssueURL).map(tab => {
              // console.log(tab);
              return (
                <Tab eventKey={tab} title={tab} className='w-100 overflow-auto'  style={{height: '500px'}}>
                  {
                    tab === 'URL Detail' ? 
                    <table class="table">
                      <thead className='sticky-top'>
                        <tr className='table-primary'>
                          <th scope="col">Name</th>
                          <th scope="col">Value</th>
                        </tr>
                      </thead>
                      <tbody>
                          {
                            Object.keys(filteredIssueURL[tab]).map(heading => {
                              return (
                                <tr>
                                  <td>{heading}</td>
                                  <td>{filteredIssueURL[tab][heading]}</td>
                                </tr>
                              )
                            })
                          }
                      </tbody>
                    </table> : tab === 'Structured Data' ?
                      <table class="table">
                        <thead>
                        </thead>
                        <tbody>
                            {
                              Object.keys(filteredIssueURL[tab]).map(type => 
                                 type === 'json-ld' ?
                                  <>
                                  {Object.entries(filteredIssueURL[tab][type][0]).map(([heading, value]) => {
                                    // console.log(data[tab][type][0]);
                                    if(heading !== '@context'){
                                        return (
                                          <Accordion>
                                                  {
                                                    value.map((schema_type, idx) => 
                                                          <Accordion.Item eventKey={idx} >
                                                              <Accordion.Header><h5>{schema_type['@type']}</h5></Accordion.Header>
                                                              <Accordion.Body>
                                                                <>
                                                                  <tr className='table-secondary'>
                                                                    <th scope="col" className='w-50'>Name</th>
                                                                    <th scope="col">Value</th>
                                                                  </tr>
                                                                  {renderElem(-1, schema_type)}
                                                                </>
                                                              </Accordion.Body>
                                                          </Accordion.Item>
                                                      )
                                                } 
                                        </Accordion>
                                        )
                                      // if (!Array.isArray(value) && typeof value !== 'object'){
                                      //   return (
                                      //     <tr>
                                      //       <td>{heading}</td>
                                      //       <td>{value}</td>
                                      //     </tr>
                                      //   )
                                      // } else {
                                      //   return renderElem(-1, value)
                                      // }
                                    }
                                  })}
                                  </> :
                                  null
                              )
                            }
                        </tbody>
                      </table> :
                      <table class="table">
                        <thead className='sticky-top'>
                          <tr className='table-primary'>
                            {
                              Object.keys(filteredIssueURL[tab][0]).map(heading => {
                                return (
                                  <td scope="col">
                                    <button class="btn" type="button" aria-expanded="false" onClick={event => sortHandler(tab, heading)}>
                                        <span className='me-2 fw-bold'>{heading}</span>
                                        <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === `${heading}_inc` ? '#000' : '#6c757d'}`}}/>
                                        <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === `${heading}_dec` ? '#000' : '#6c757d'}`}}/>
                                    </button>
                                  </td>
                                )
                              })
                            }
                          </tr>
                        </thead>
                        <tbody>
                            {
                              filteredIssueURL[tab].map(row => {
                                return (
                                  <tr>
                                    {
                                      Object.keys(row).map(heading => {
                                        return (
                                          <td>{row[heading]}</td>
                                        )
                                      })
                                    }
                                  </tr>
                                )
                              })
                            }
                        </tbody>
                      </table>
                  }
                </Tab>
              )
            })
          }
        </Tabs>
      </div>
    </div>
  );
};

export default AuditURL;