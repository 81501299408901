import { useState, Fragment, useRef, useEffect } from "react";
import {showLoading, hideLoading } from 'react-redux-loading-bar';
import { useDispatch } from "react-redux";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import LoadingBar from 'react-redux-loading-bar';
import CartBoard, { Question } from "../components/cart";
import { DelCartItem } from "../components/cart-item";
import Head from "../components/loading-bar";
import Menu from '../components/menu';

import "bootstrap/dist/css/bootstrap.min.css";
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUp,
  faArrowDown,
  faPlus,
  faMinus,
  faTrash, faDownload, faRefresh
} from "@fortawesome/free-solid-svg-icons";


const ADD_HEADING = [];

function Outline() {
  const userName = localStorage.getItem('username');

  const [initHeadings, setInitHeadings] = useState([]);
  const [initQuestions, setInitQuestions] = useState([])
  const [headings, setHeadings] = useState(ADD_HEADING);
  const [active, setActive] = useState(null);
  const [liIndex, setIndex] = useState(null);
  const [disabled, setDisabled] = useState([]); //gray out when move heading or content from left to right
  const [editable, setEditable] = useState(null);
  const [detail, setDetail] = useState(false);
  const [dateCrawled, setDateCrawled] = useState('');
  const [location, setLocation] = useState('Location');
  const [forumFilter, setForumFilter] = useState('All');
  const [tab, setTab] = useState('serp');
  const [isLoading, setIsLoading] = useState(false);
  const [activeJSRender, setActiveJSRender] = useState(0);

  
  const search_key = useRef();

  const dispatch = useDispatch();

  function fetchHeadingHandler() {

      if (search_key.current.value.trim() === '') {
        return
      };


      dispatch(showLoading());
      let loc = 'VN-vi';
      if (location !== 'Location'){
        loc = `?location=${location}`;};
      // console.log(loc, location)
      setIsLoading(current => !current);
      console.log('loading: ', isLoading);
      fetch(`${process.env.REACT_APP_HOST_IP}/api/outline/${search_key.current.value}${loc}`, {
        headers: {
          'X-User': userName
        }
      }).then(
        response => {
          dispatch(hideLoading());
          return response.json();
        }
      ).then((data) =>{
        setInitHeadings(data);
        setDateCrawled(data[0].dateAdded);
        setIsLoading(current => !current);
        // console.log(data);
        // console.log(data[0]);
      }
      );
      dispatch(showLoading());
      fetch(`${process.env.REACT_APP_HOST_IP}/api/questions/${search_key.current.value}${loc}`, {
        headers: {
          'X-User': userName
        }
      }).then(
        response => {
          dispatch(hideLoading());
          return response.json();
        }
      ).then((data2) =>{
        setInitQuestions(data2);
        // console.log(data2);
        // console.log(data[0]);
      }
      );
      console.log('not loading: ', isLoading);
  };

  function refreshHandler(e, kw) {

    console.log(kw)
    let loc = 'VN-vi'
    if (location !== 'Location'){
      loc = `?location=${location}`;}

    dispatch(showLoading());
    setInitHeadings([]);
    setInitQuestions([]);
    setDateCrawled('');
    fetch(`${process.env.REACT_APP_HOST_IP}/api/delete/${kw}`, { 
      method: 'DELETE',
      headers: {
        'X-User': userName
      }
     }).then (response => {

        fetch(`${process.env.REACT_APP_HOST_IP}/api/questions/${kw}${loc}`, {
          headers: {
            'X-User': userName
          }
        }).then(
          response => {
            return response.json();
          }).then((data2) =>{
            setInitQuestions( prevQuestions => data2);
          });
      
        fetch(`${process.env.REACT_APP_HOST_IP}/api/outline/${kw}${loc}`, {
          headers: {
            'X-User': userName
          }
        }).then(
        response => {
          dispatch(hideLoading());
          return response.json();
        }).then((data) =>{
        setInitHeadings(prevHeadings => data);
        setDateCrawled(prevDateCrawled => data[0].dateAdded)});
  });
};



  const addItemHandler = (item) => {
    setHeadings((prevHeadings) => {
      return [...prevHeadings, item];
    });
    console.log('addHeadings: ',headings)
    setDisabled((prevdisabled) => {
      return [...prevdisabled, item.id];
    });
  };

  const delItemHandler = (item) => {
    setHeadings(headings.filter((it) => it.id !== item.id));
    setDisabled(disabled.filter((it) => it !== item.id));
  };

  const copyItemHandler = (current, copy) => {
    const idx = headings.indexOf(current);
    // console.log(headings.indexOf(current));

    setHeadings((prevHeadings) => {
      return [
        ...prevHeadings.slice(0, idx+1),
        copy,
        ...prevHeadings.slice(idx+1)
      ];
    });
  };

  const reorderArray = (event, originalArray) => {
    const movedItem = originalArray.find(
      (item, index) => index === event.oldIndex
    );
    const remainingItems = originalArray.filter(
      (item, index) => index !== event.oldIndex
    );

    const reorderedItems = [
      ...remainingItems.slice(0, event.newIndex),
      movedItem,
      ...remainingItems.slice(event.newIndex),
    ];
    console.log(
      movedItem,
      remainingItems.slice(0, event.newIndex),
      remainingItems.slice(event.newIndex)
    );

    return reorderedItems;
  };

  function changeOrderHandler(oldIdx, newIdx) {
    setHeadings(prevHeadings => reorderArray({ oldIndex: oldIdx, newIndex: newIdx }, headings));
    console.log("old idx: ", oldIdx, "new idx: ", newIdx);
  }

  const onMoveUpHandler = () => {
    if (headings.length === 0){
      return;
    }else {
      changeOrderHandler(
        liIndex,
        liIndex > 0 ? liIndex - 1 : headings.length - 1
      );
      if (liIndex > 0) {
        setIndex(liIndex - 1);
      } else {
        setIndex(headings.length - 1);
      }
    };
  };

  const onMoveDownHandler = () => {
    if (headings.length === 0){
      return;
    }else {
      changeOrderHandler(
        liIndex,
        liIndex < headings.length - 1 ? liIndex + 1 : 0
      );
      if (liIndex < headings.length - 1) {
        setIndex(liIndex + 1);
      } else {
        setIndex(0);
      };
    };
  };

  const clickHandler = (e, item, index) => {
    // if (active === item.id) {
    //   setActive("Null");
    // } else if (editable === item.id ){
    //   setActive("Null");
    // } else {
    //   setActive(item.id);
    // }
    setActive(item.id);
    setIndex(index);
    setEditable(item.id);
  };

  const increaseClassHandler = (e) => {
    e.preventDefault();
    const newHeadings = headings.map((item) => {
      if(item.id === active){
        switch(item.content_type){
          case 'P':
            return {...item, content_type: 'H4'};
            break;
          case 'H4':
            return {...item, content_type: 'H3'};
            break;
          case 'H3':
            return {...item, content_type: 'H2'};
            break;
          case 'H2':
            return {...item, content_type: 'H1'};
            break;
        };
      };
      return item;
    });
    setHeadings(newHeadings);
  };

  const decreaseClassHandler = (e) => {
    e.preventDefault();
    const newHeadings = headings.map((item) => {
      if(item.id === active){
        switch(item.content_type){
          case 'H1':
            return {...item, content_type: 'H2'};
            break;
          case 'H2':
            return {...item, content_type: 'H3'};
            break;
          case 'H3':
            return {...item, content_type: 'H4'};
            break;
          case 'H4':
            return {...item, content_type: 'P'};
            break;
        };
      };
      return item;
    });
    setHeadings(newHeadings);
  };

  const addElemHandler = (event, type) => {
    let newItem = {}
    switch(type){
      case 'H1':
        newItem = {
          id: Math.random().toString(),
          content_type: 'H1',
          content_body: 'Edit content here!'
        };
        break;
      case 'H2':
        newItem = {
          id: Math.random().toString(),
          content_type: 'H2',
          content_body: 'Edit content here!'
        };
        break;
      case 'H3':
        newItem = {
          id: Math.random().toString(),
          content_type: 'H3',
          content_body: 'Edit content here!'
        };
        break;
      case 'H4':
        newItem = {
          id: Math.random().toString(),
          content_type: 'H4',
          content_body: 'Edit content here!'
        };
        break;
      case 'P':
        newItem = {
          id: Math.random().toString(),
          content_type: 'P',
          content_body: 'Edit content here!'
        };
        break;
    };
    setHeadings(prevHeadings => {
      return[...prevHeadings, newItem];
    });
  };

  const onDelAllHandler = () => {
    setHeadings([]);
    setDisabled([]);
  };

  const exportFileHandler = (event, outline) => {
    if(headings.length === 0){
      return;
    } else{
      dispatch(showLoading());
      // console.log(outline);
      fetch(`${process.env.REACT_APP_HOST_IP}/api/download/outline-report`, {
        method: 'POST',
        body: JSON.stringify(outline),
        headers: {
          'Content-Type': 'application/json',
          'X-User': userName
        }
      }).then(
        response => {
          dispatch(hideLoading());
          return response.blob();
        }).then(blob => {
          const url = window.URL.createObjectURL(blob);
          // console.log(blob);
          const link = document.createElement("a");
          link.download = 'outline.docx';
          link.href = url;
          link.click();
        }).catch(error => {
          console.error(error);
      });
    };
  };

  const changeLocationHandler = (e, value) => {
    // console.log(e.target);
    setLocation(value);
    console.log(location);
  };

  const changeForumHandler = (e, value) => {
    // console.log(e.target);
    setForumFilter(value);
  };

  const updateChangeHandler = (item) => {
    const newHeadings = headings.map((it) => {
      if(it.id === item.id){
          return {...it, content_body: item.content_body};
        };
        return it
      });
      setHeadings(prevHeadings => newHeadings);
    };
  
  const showDetailHandler = () => {
    setDetail(!detail);
  };

  const setTabHandler = (e, tab_name) => {
    e.preventDefault();
    setTab(tab_name);
  };

  const updateItemHandler = (item) => {

    if (item === 'starting'){
      setActiveJSRender(current => current + 1);
    } else {
      const newHeadings = initHeadings.map((it) => {
        if(it.url === item.url){
            return {...it, content: item.content};
          };
        return it
        });
      setInitHeadings(prevInitHeadings => newHeadings);
      setActiveJSRender(current => current - 1);
    }
  };

  const onDragEndHandler = (result) => {
    if (!result.destination || result.destination.droppableId.search(/^serp|quest.*/i) !== -1) return;
    const items = Array.from(headings);
    if (result.destination.droppableId === 'editor' && result.source.droppableId.search(/^serp|quest.*/i) !== -1){
      const sourceItem = (result.source.droppableId.search(/^serp-/i,'') !== -1) ? initHeadings.filter((item) => item._id === result.source.droppableId.replace(/^serp-/i,'')) : initQuestions.filter((item) => item._id === result.source.droppableId.replace(/^quest-/i,''));
      const addItem = result.source.droppableId.search(/^serp-/i,'') !== -1 ? sourceItem[0].content[result.source.index] : sourceItem[0].questions[result.source.index];
      console.log(addItem);
      if(result.source.droppableId.search(/^quest-/i,'') !== -1){
        const head = {
          id: addItem._id,
          content_type: 'P',
          content_body: addItem.title
        };
        console.log(head.id);
        setDisabled((prevdisabled) => {
          return [...prevdisabled, head.id];
        });
        items.splice(result.destination.index, 0, head);
      } else {
        items.splice(result.destination.index, 0, addItem);
        setDisabled((prevdisabled) => {
          return [...prevdisabled, addItem.id];
        });
      }
      setHeadings(items);
      return;
    }
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setHeadings(items);
  };

  return (
    <Fragment>
      <Head />
      <div className="container vh-100">
        <Menu />
        <div className="d-flex flex-column h-100">
          <h1 className="text-center my-4">Outline App</h1>
          <div className="d-flex flex-column flex-grow-1 overflow-hidden">
            <div class={`input-group ${dateCrawled !=='' &&  dateCrawled !== null ? 'mt-5' : 'my-5'}`} style={{'max-width': '66%'}}>
            <button class="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">{location}</button>
            <ul class="dropdown-menu">
              <li onClick={event => changeLocationHandler(event, 'US-en')}>
                <a class=" d-flex dropdown-item m-auto" href="#">
                  <img src='us-flag.png' className="pe-2" style={{"height":"20px"}}/>
                  <span>US-en</span>
                </a>
              </li>
              <li onClick={event => changeLocationHandler(event, 'SG-en')}>
                <a class=" d-flex dropdown-item m-auto" href="#">
                  <img src='sg-flag.png' className="pe-2" style={{"height":"20px"}}/>
                  <span>SG-en</span>
                </a>
              </li>
              <li onClick={event => changeLocationHandler(event, 'VN-vi')}>
                <a class=" d-flex dropdown-item m-auto" href="#">
                  <img src='vn-flag.png' className="pe-2" style={{"height":"20px"}}/>
                  <span>VN-vi</span>
                </a>
              </li>
            </ul>
              <input type="text" ref={search_key} class="form-control" placeholder="Type search keywords here..." aria-label="Type search keywords here..." aria-describedby="button-addon2" />
              <button class={`btn btn-outline-primary ${isLoading && 'disabled'}`} type="button" id="button-addon2" onClick={fetchHeadingHandler}>Start Searching</button>
            </div>
            {dateCrawled !== '' &&  dateCrawled !== null &&
            <div className='d-flex mb-5 mt-1'>
              <small className="text-muted">Last crawled: {dateCrawled}</small>
              <a href="#" className='ms-4' onClick = {event => refreshHandler(event, initHeadings[0].Keyword, location)}>
              <FontAwesomeIcon icon={faRefresh} />
              <small>Refresh</small></a>
            </div> }
            <div className="row flex-grow-1 overflow-hidden">
              <DragDropContext onDragEnd={onDragEndHandler}>
                <div className="d-flex flex-column h-100 col-md-6">
                    <div className="d-flex mb-2 pb-4">
                      {/* <h3 className="d-inline-block me-auto">SERP Outline</h3> */}
                      <div className="d-inline-block w-100">
                        <ul class="d-flex justify-content-start nav nav-tabs" id="myTab" role="tablist">
                          <li class="nav-item" role="presentation">
                            <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true" onClick={event => setTabHandler(event,'serp')}>SERP Outline</button>
                          </li>
                          <li class="nav-item" role="presentation">
                            <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false" onClick={event => setTabHandler(event,'faqs')}>FAQs</button>
                          </li>
                        </ul>
                        <div class="tab-content mt-3" style={{"height":"35px"}} id="myTabContent">
                          <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                              <div class="d-inline-block ms-4 form-check form-switch" style={{'font-size': '16px'}}>
                                <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" onChange={showDetailHandler}/>
                                <label class="form-check-label" for="flexSwitchCheckDefault">Show detail</label>
                              </div>
                          </div>
                          <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                          <div class="dropdown ms-4">
                            <a class="btn btn-outline-secondary dropdown-toggle pe-4 ps-3" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                              {forumFilter}
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                              <li><a class="dropdown-item" href="#" onClick={event => changeForumHandler(event, 'All')}>All</a></li>
                              <li><a class="dropdown-item" href="#" onClick={event => changeForumHandler(event, 'People Also Asked')}>People Also Asked</a></li>
                              <li><a class="dropdown-item" href="#" onClick={event => changeForumHandler(event, 'Quora')}>Quora</a></li>
                              <li><a class="dropdown-item" href="#" onClick={event => changeForumHandler(event, 'Reddit')}>Reddit</a></li>
                            </ul>
                          </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row row-cols-1 g-4 overflow-auto justify-content-center">
                      {initHeadings.length === 0 && <h5 className="text-muted text-center fs-2" style={{"width": "90%", "line-height": "200px", "border": "1px dashed #adb5bd"}}>SERP Outline</h5>}
                      {tab === 'serp' ? initHeadings.map((item) => (
                        <CartBoard
                          items={item}
                          disabled={disabled}
                          onAddItem={addItemHandler}
                          detail={detail}
                          onUpdateItem={updateItemHandler}
                          location={location}
                          activeJS = {activeJSRender}
                        />))
                        : initQuestions.map((item) => (
                        <Question
                        items={item}
                        disabled={disabled}
                        onAddItem={addItemHandler}
                        forumFilter={forumFilter}
                        // detail={detail}
                      />
                      ))}
                    </div>
                </div>
                <div className=" d-flex flex-column h-100 col-md-6">
                  <h3 className="mb-4">Editor</h3>
                  <div className="d-flex flex-row pb-4 mb-4">
                    <div class="dropdown">
                      <button
                        class="btn btn-secondary dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Add Elements
                      </button>
                      <ul class="dropdown-menu">
                        <li>
                          <button class="dropdown-item" href="#" onClick={(event) => addElemHandler(event, 'H1')}>
                            H1
                          </button>
                        </li>
                        <li>
                          <button class="dropdown-item" href="#" onClick={(event) => addElemHandler(event, 'H2')}>
                            H2
                          </button>
                        </li>
                        <li>
                          <button class="dropdown-item" href="#" onClick={(event) => addElemHandler(event, 'H3')}>
                            H3
                          </button>
                        </li>
                        <li>
                          <button class="dropdown-item" href="#" onClick={(event) => addElemHandler(event, 'H4')}>
                            H4
                          </button>
                        </li>
                        <li>
                          <button class="dropdown-item" href="#" onClick={(event) => addElemHandler(event, 'P')}>
                            Paragraph
                          </button>
                        </li>
                      </ul>
                    </div>
                    <button
                      type="button"
                      className="btn btn-light btn-sm  ms-auto"
                      onClick={event => increaseClassHandler(event)}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </button>
                    <button
                      type="button"
                      className="btn btn-light btn-sm ms-2"
                      onClick={event => decreaseClassHandler(event)}
                    >
                      <FontAwesomeIcon icon={faMinus} />
                    </button>
                    <button
                      type="button"
                      className="btn btn-light btn-sm ms-2"
                      onClick={onMoveUpHandler}
                    >
                      <FontAwesomeIcon icon={faArrowUp} />
                    </button>
                    <button
                      type="button"
                      className="btn btn-light btn-sm ms-2"
                      onClick={onMoveDownHandler}
                    >
                      <FontAwesomeIcon icon={faArrowDown} />
                    </button>
                    <button
                      type="button"
                      className={`btn btn-danger btn-sm ms-2 ${headings.length === 0 && 'disabled'}`}
                      onClick={onDelAllHandler}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                    <button
                      type="button"
                      className={`btn btn-primary btn-sm ms-2 ${headings.length === 0 && 'disabled'}`}
                      onClick={(event) => exportFileHandler(event, headings)}
                    >
                      <FontAwesomeIcon icon={faDownload} />
                    </button>
                  </div>
                  <div className="row g-4 overflow-auto">
                    <div className="col">
                      <div className="card">
                        <div className="card-body">
                          <div className="card-text">
                            <Droppable droppableId="editor">
                              {(provided) => (
                                  <ul className="list-group" {...provided.droppableProps} ref={provided.innerRef}>
                                    {headings.length === 0 && <h5 className="text-muted text-center fs-2" style={{"line-height": "200px", "border": "1px dashed #adb5bd"}}>Drag Content Here</h5>}
                                    {headings.map((item, index) => {
                                      return (
                                        <Draggable key={item.id} draggableId={item.id} index={index}>
                                          {(provided) => (
                                              <li
                                              className={`list-group-item list-group-item-action d-flex justify-content-between align-items-center w-auto ${
                                                item.content_type === "H2" && "ms-2"
                                              } ${item.content_type === "H3" && "ms-3"} ${item.content_type === "H4" && "ms-4"} ${item.content_type === "P" && "ms-5"} ${
                                                item.id === active && "active"
                                              }`}
                                              onClick={(event) =>
                                                clickHandler(event, item, index)
                                              }
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                            >
                                              <DelCartItem
                                                key={item.id}
                                                items={item}
                                                onDelItem={delItemHandler}
                                                onCopyItem={copyItemHandler}
                                                editable={editable}
                                                onUpdateChanges = {updateChangeHandler}
                                              />
                                              </li>
                                          )}
                                        </Draggable>
                                      )}
                                      )}
                                    {headings.length > 0 && provided.placeholder}
                                  </ul>
                              )}
                            </Droppable>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </DragDropContext>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Outline;
