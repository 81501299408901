import { useState, useRef, useEffect, useMemo } from 'react';
import {showLoading, hideLoading } from 'react-redux-loading-bar';
import { useDispatch } from "react-redux";

import NotiModal from '../components/modal';
import { AuditGroup } from "../components/cart-item";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap/dist/js/bootstrap.min.js";
import * as bootstrap from 'bootstrap/dist/js/bootstrap';

import {
    ResponsiveContainer,
    Tooltip,
    PieChart, Pie, Cell
  } from "recharts";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash, faDownload, faRefresh, faFilter, faMinus, faArrowUp, faArrowDown, faSort, faUpRightFromSquare, faCheckCircle, faTimesCircle, faAngleUp, faAngleDown, faGlobe, faLanguage, faPlus
} from "@fortawesome/free-solid-svg-icons";
import { getAuthToken } from '../util/token';
import { useSearchParams } from "react-router-dom";
import Papa from 'papaparse';
import BubleChart from '../components/buble_chart';



function RankedKws() {

    const [activeG, setActiveG] = useState('');
    const [activeDisplayName, setActiveDisplayName] = useState('');
    const [kwDetail, setKwDetail] = useState([]);
    const [kwGroup, setKwGroup] = useState([]);
    const [confirmKw, setConfirmKw] = useState([]);
    const [projectKw, setProjectKw] = useState([]);
    const [msg, setMsg] = useState('');
    const [mode, setMode] = useState('Type');
    const [filter, setFilter] = useState('');
    const [filterRange, setFilterRange] = useState(['','']);
    const [searchKey, setSearchKey] = useState('');
    const no_clusters = useRef();
    const range_from = useRef();
    const range_to = useRef();
    const [filePicked, setFilePicked] = useState(false);
    const [ nCluster, setNCluster] = useState(false);
    const [sortGroup, setSortGroup] = useState('');
    const [serpNoti, setSerpNoti] = useState('');
    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

    //auth token
    const token = getAuthToken();
    const userName = localStorage.getItem('username');

    //project info
    const topic = useRef('');
    const domain = useRef('');
    const description = useRef('');

    //save setup
    const [importStat, setImportStat] = useState('');
    const [ taskId, setTaskId] = useState('');
    const [autoSave, setAutoSave] = useState('');

    //table title sort
    const [sort, setSort] = useState('');

    const [searchParams, setSearchParams] = useSearchParams();
    const dispatch = useDispatch();

    // get volume
    const [location, setLocation] = useState('');
    const [language, setLanguage] = useState('');

    const [rankingDist, setRankingDist] = useState({});
    const [totalkeywords, setTotalKeywords] = useState(0);
    const [traffic, setTraffic] = useState(0);

    const [locationName, setLocationName] = useState('Global');
    const urlSeed = useRef('');
    const searchStr = useRef('');
    const [filteredList, setFilteredList] = useState([]);
    const [countryList, setCountryList] = useState([]);
    const [pendingTasks, setPendingTasks] = useState([]);
    const [bubleChartData, setBubleChartData] = useState({});
    const [data, setData] = useState({});
    const [collapseAll, setCollapseAll] = useState(false);


    useEffect(() => {
        // console.log(data);
        // setProjectKw(data['keywords']);
        // setKwDetail(data['keywords']);
        // setRankingDist(data['ranking_dist']);
        // setTotalKeywords(data['total_kws']);
        // setTraffic(data['estimated_traffic']);
        // if(data['group']) setKwGroup(data['group']);
        // if (searchParams.get('id')){
        //   const input = {
        //     id: searchParams.get('id')
        //   };
        //   console.log(input);
        //   fetch(`${process.env.REACT_APP_HOST_IP}/api/manage-tasks`,{
        //       method: 'POST',
        //       body: JSON.stringify(input),
        //       headers: {
        //           'Content-Type': 'application/json',
        //           'Authorization': 'Bearer ' + token
        //         }
        //   }).then(
        //       response => {
        //         if (response.status === 404){
        //           return null
        //         }
        //         return response.json();
        //       }
        //   ).then (
        //       data =>{
        //         if (data === null){
        //           return
        //         };
        //         console.log(data);
        //         setProjectKw(data[0]['keywords']);
        //         setKwDetail(data[0]['keywords']);
        //         topic.current.value = (data[0]['topic']);
        //         domain.current.value = (data[0]['domain']);
        //         description.current.value = (data[0]['description']);
        //         // console.log(data[0]['group']);
        //         if(data[0]['group']) setKwGroup(data[0]['group']);
        // })};
        Promise.all([
            fetch(`${process.env.REACT_APP_HOST_IP}/api/country-geo-target`,{
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                    'X-User': userName
                    }
            }
            ).then(
                response => response.json()
            ).then(
                data => {
                    setCountryList(data);
                    setFilteredList(data);
                }
            )
            ,
            fetch(`${process.env.REACT_APP_HOST_IP}/api/manage-tasks`,{
            method: 'POST',
            body: JSON.stringify({}),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
                'X-User': userName
                }
        }).then(
            response => {
                return response.json()
            }
        ).then (
            data =>{
                const task = data.filter(item => item.task === 'grouping');
                setPendingTasks(task);
                // console.log(task)
            })
        ])
      },[]);

    const tableSortHandler = (mode) => {
        if (mode === 'kw'){
            if (sort !== 'keyword_inc' && sort !== 'keyword_dec'){
              setSort('keyword_inc');
              if(typeof kwDetail[0] === 'object'){
                if (typeof kwDetail[0]['keywords'][0] === 'object'){
                    let new_arr = [];
                    new_arr = kwDetail.map((elem) => {
                        const child = elem.keywords.map((ele) => ({
                            group_name: ele.group_name,
                            display_name: ele.display_name,
                            keywords: [...ele.keywords.sort((a, b) => a.localeCompare(b))]
                        }));
                        return(
                            {group_name: elem.group_name,
                            keywords: child} // Sort keywords alphabetically
                        )
                    });
                    setKwDetail(new_arr);
                } else {
                    let new_arr = [];
                    new_arr = kwDetail.map((elem) => ({
                        group_name: elem.group_name,
                        display_name: elem.display_name,
                        keywords: [...elem.keywords.sort((a, b) => a.localeCompare(b))], // Sort keywords alphabetically
                    }));
                    setKwDetail(new_arr);
                };
              } else if (typeof kwDetail[0] === 'string'){
                kwDetail.sort((a,b) => (b > a) ? -1 : (b < a) ? 1 : 0);
              };
            } else if(sort === 'keyword_dec'){
                setSort('keyword_inc');
                if(typeof kwDetail[0] === 'object'){
                  if (typeof kwDetail[0]['keywords'][0] === 'object'){
                      let new_arr = [];
                      new_arr = kwDetail.map((elem) => {
                          const child = elem.keywords.map((ele) => ({
                              group_name: ele.group_name,
                              display_name: ele.display_name,
                              keywords: [...ele.keywords.sort((a, b) => a.localeCompare(b))]
                          }));
                          return(
                              {group_name: elem.group_name,
                              keywords: child} // Sort keywords alphabetically
                          )
                      });
                      setKwDetail(new_arr);
                  } else {
                      let new_arr = [];
                      new_arr = kwDetail.map((elem) => ({
                          group_name: elem.group_name,
                          display_name: elem.display_name,
                          keywords: [...elem.keywords.sort((a, b) => a.localeCompare(b))], // Sort keywords alphabetically
                      }));
                      setKwDetail(new_arr);
                  };
                } else if (typeof kwDetail[0] === 'string'){
                  kwDetail.sort((a,b) => (b > a) ? -1 : (b < a) ? 1 : 0);
                };
            } else if(sort === 'keyword_inc'){
              setSort('keyword_dec');
              if(typeof kwDetail[0] === 'object'){
                if (typeof kwDetail[0]['keywords'][0] === 'object'){
                    let new_arr = [];
                    new_arr = kwDetail.map((elem) => {
                        const child = elem.keywords.map((ele) => ({
                            group_name: ele.group_name,
                            display_name: ele.display_name,
                            keywords: [...ele.keywords.sort((a, b) => b.localeCompare(a))]
                        }));
                        return(
                            {group_name: elem.group_name,
                            keywords: child} // Sort keywords alphabetically
                        )
                    });
                    // console.log(kwDetail);
                    setKwDetail(new_arr);
                } else {
                    let new_arr = [];
                    new_arr = kwDetail.map((elem) => ({
                        group_name: elem.group_name,
                        display_name: elem.display_name,
                        keywords: [...elem.keywords.sort((a, b) => b.localeCompare(a))], // Sort keywords alphabetically
                    }));
                    setKwDetail(new_arr);
                };
              } else if (typeof kwDetail[0] === 'string'){
                kwDetail.sort((a,b) => (b > a) ? 1 : (b < a) ? -1 : 0);
              };
            };
        } else if (mode === 'volume'){
            if (sort !== 'volume_inc' && sort !== 'volume_dec'){
                setSort('volume_inc');
                if(typeof kwDetail[0] === 'object'){
                  if (typeof kwDetail[0]['keywords'][0] === 'object'){
                      let new_arr = [];
                      new_arr = kwDetail.map((elem) => {
                          const child = elem.keywords.map((ele) => ({
                              group_name: ele.group_name,
                              display_name: ele.display_name,
                              keywords: [...ele.keywords.sort((a, b) => {
                                let vol2 = parseInt(b.split('<<>>')[1]);
                                let vol1 = parseInt(a.split('<<>>')[1]);
                                return (vol2 > vol1) ? -1 : (vol2 < vol1) ? 1 : 0
                              })]
                          }));
                          return(
                              {group_name: elem.group_name,
                              keywords: child}
                          )
                      });
                      setKwDetail(new_arr);
                  } else {
                      let new_arr = [];
                      new_arr = kwDetail.map((elem) => ({
                          group_name: elem.group_name,
                          display_name: elem.display_name,
                          keywords: [...elem.keywords.sort((a, b) => {
                                let vol2 = parseInt(b.split('<<>>')[1]);
                                let vol1 = parseInt(a.split('<<>>')[1]);
                                return (vol2 > vol1) ? -1 : (vol2 < vol1) ? 1 : 0
                          })],
                      }));
                      setKwDetail(new_arr);
                  };
                } else if (typeof kwDetail[0] === 'string'){
                  kwDetail.sort((a,b) => {
                    let vol2 = parseInt(b.split('<<>>')[1]);
                    let vol1 = parseInt(a.split('<<>>')[1]);
                    return (vol2 > vol1) ? -1 : (vol2 < vol1) ? 1 : 0
                });
                };
              } else if(sort === 'volume_dec'){
                  setSort('volume_inc');
                  if(typeof kwDetail[0] === 'object'){
                    if (typeof kwDetail[0]['keywords'][0] === 'object'){
                        let new_arr = [];
                        new_arr = kwDetail.map((elem) => {
                            const child = elem.keywords.map((ele) => ({
                                group_name: ele.group_name,
                                display_name: ele.display_name,
                                keywords: [...ele.keywords.sort((a, b) => {
                                    let vol2 = parseInt(b.split('<<>>')[1]);
                                    let vol1 = parseInt(a.split('<<>>')[1]);
                                    return (vol2 > vol1) ? -1 : (vol2 < vol1) ? 1 : 0
                                })]
                            }));
                            return(
                                {group_name: elem.group_name,
                                keywords: child} // Sort keywords alphabetically
                            )
                        });
                        setKwDetail(new_arr);
                    } else {
                        let new_arr = [];
                        new_arr = kwDetail.map((elem) => ({
                            group_name: elem.group_name,
                            display_name: elem.display_name,
                            keywords: [...elem.keywords.sort((a, b) => {
                                let vol2 = parseInt(b.split('<<>>')[1]);
                                let vol1 = parseInt(a.split('<<>>')[1]);
                                return (vol2 > vol1) ? -1 : (vol2 < vol1) ? 1 : 0
                            })],
                        }));
                        setKwDetail(new_arr);
                    };
                  } else if (typeof kwDetail[0] === 'string'){
                    kwDetail.sort((a,b) => {
                        let vol2 = parseInt(b.split('<<>>')[1]);
                        let vol1 = parseInt(a.split('<<>>')[1]);
                        return (vol2 > vol1) ? -1 : (vol2 < vol1) ? 1 : 0
                    });
                  };
              } else if(sort === 'volume_inc'){
                setSort('volume_dec');
                if(typeof kwDetail[0] === 'object'){
                  if (typeof kwDetail[0]['keywords'][0] === 'object'){
                      let new_arr = [];
                      new_arr = kwDetail.map((elem) => {
                          const child = elem.keywords.map((ele) => ({
                              group_name: ele.group_name,
                              display_name: ele.display_name,
                              keywords: [...ele.keywords.sort((a, b) => {
                                let vol2 = parseInt(b.split('<<>>')[1]);
                                let vol1 = parseInt(a.split('<<>>')[1]);
                                return (vol2 > vol1) ? 1 : (vol2 < vol1) ? -1 : 0
                              })]
                          }));
                          return(
                              {group_name: elem.group_name,
                              keywords: child}
                          )
                      });
                      // console.log(kwDetail);
                      setKwDetail(new_arr);
                  } else {
                      let new_arr = [];
                      new_arr = kwDetail.map((elem) => ({
                          group_name: elem.group_name,
                          display_name: elem.display_name,
                          keywords: [...elem.keywords.sort((a, b) => {
                            let vol2 = parseInt(b.split('<<>>')[1]);
                            let vol1 = parseInt(a.split('<<>>')[1]);
                            return (vol2 > vol1) ? 1 : (vol2 < vol1) ? -1 : 0
                          })],
                      }));
                      setKwDetail(new_arr);
                  };
                } else if (typeof kwDetail[0] === 'string'){
                    kwDetail.sort((a,b) => {
                        let vol2 = parseInt(b.split('<<>>')[1]);
                        let vol1 = parseInt(a.split('<<>>')[1]);
                        return (vol2 > vol1) ? 1 : (vol2 < vol1) ? -1 : 0
                    });
                };
              };
        } else if (mode === 'kd'){
            if (sort !== 'kd_inc' && sort !== 'kd_dec'){
                setSort('kd_inc');
                if(typeof kwDetail[0] === 'object'){
                  if (typeof kwDetail[0]['keywords'][0] === 'object'){
                      let new_arr = [];
                      new_arr = kwDetail.map((elem) => {
                          const child = elem.keywords.map((ele) => ({
                              group_name: ele.group_name,
                              display_name: ele.display_name,
                              keywords: [...ele.keywords.sort((a, b) => {
                                let vol2 = parseInt(b.split('<<>>')[2]);
                                let vol1 = parseInt(a.split('<<>>')[2]);
                                return (vol2 > vol1) ? -1 : (vol2 < vol1) ? 1 : 0
                              })]
                          }));
                          return(
                              {group_name: elem.group_name,
                              keywords: child}
                          )
                      });
                      setKwDetail(new_arr);
                  } else {
                      let new_arr = [];
                      new_arr = kwDetail.map((elem) => ({
                          group_name: elem.group_name,
                          display_name: elem.display_name,
                          keywords: [...elem.keywords.sort((a, b) => {
                                let vol2 = parseInt(b.split('<<>>')[2]);
                                let vol1 = parseInt(a.split('<<>>')[2]);
                                return (vol2 > vol1) ? -1 : (vol2 < vol1) ? 1 : 0
                          })],
                      }));
                      setKwDetail(new_arr);
                  };
                } else if (typeof kwDetail[0] === 'string'){
                  kwDetail.sort((a,b) => {
                    let vol2 = parseInt(b.split('<<>>')[2]);
                    let vol1 = parseInt(a.split('<<>>')[2]);
                    return (vol2 > vol1) ? -1 : (vol2 < vol1) ? 1 : 0
                });
                };
              } else if(sort === 'kd_dec'){
                  setSort('kd_inc');
                  if(typeof kwDetail[0] === 'object'){
                    if (typeof kwDetail[0]['keywords'][0] === 'object'){
                        let new_arr = [];
                        new_arr = kwDetail.map((elem) => {
                            const child = elem.keywords.map((ele) => ({
                                group_name: ele.group_name,
                                display_name: ele.display_name,
                                keywords: [...ele.keywords.sort((a, b) => {
                                    let vol2 = parseInt(b.split('<<>>')[2]);
                                    let vol1 = parseInt(a.split('<<>>')[2]);
                                    return (vol2 > vol1) ? -1 : (vol2 < vol1) ? 1 : 0
                                })]
                            }));
                            return(
                                {group_name: elem.group_name,
                                keywords: child} // Sort keywords alphabetically
                            )
                        });
                        setKwDetail(new_arr);
                    } else {
                        let new_arr = [];
                        new_arr = kwDetail.map((elem) => ({
                            group_name: elem.group_name,
                            display_name: elem.display_name,
                            keywords: [...elem.keywords.sort((a, b) => {
                                let vol2 = parseInt(b.split('<<>>')[2]);
                                let vol1 = parseInt(a.split('<<>>')[2]);
                                return (vol2 > vol1) ? -1 : (vol2 < vol1) ? 1 : 0
                            })],
                        }));
                        setKwDetail(new_arr);
                    };
                  } else if (typeof kwDetail[0] === 'string'){
                    kwDetail.sort((a,b) => {
                        let vol2 = parseInt(b.split('<<>>')[2]);
                        let vol1 = parseInt(a.split('<<>>')[2]);
                        return (vol2 > vol1) ? -1 : (vol2 < vol1) ? 1 : 0
                    });
                  };
              } else if(sort === 'kd_inc'){
                setSort('kd_dec');
                if(typeof kwDetail[0] === 'object'){
                  if (typeof kwDetail[0]['keywords'][0] === 'object'){
                      let new_arr = [];
                      new_arr = kwDetail.map((elem) => {
                          const child = elem.keywords.map((ele) => ({
                              group_name: ele.group_name,
                              display_name: ele.display_name,
                              keywords: [...ele.keywords.sort((a, b) => {
                                let vol2 = parseInt(b.split('<<>>')[2]);
                                let vol1 = parseInt(a.split('<<>>')[2]);
                                return (vol2 > vol1) ? 1 : (vol2 < vol1) ? -1 : 0
                              })]
                          }));
                          return(
                              {group_name: elem.group_name,
                              keywords: child}
                          )
                      });
                      // console.log(kwDetail);
                      setKwDetail(new_arr);
                  } else {
                      let new_arr = [];
                      new_arr = kwDetail.map((elem) => ({
                          group_name: elem.group_name,
                          display_name: elem.display_name,
                          keywords: [...elem.keywords.sort((a, b) => {
                            let vol2 = parseInt(b.split('<<>>')[2]);
                            let vol1 = parseInt(a.split('<<>>')[2]);
                            return (vol2 > vol1) ? 1 : (vol2 < vol1) ? -1 : 0
                          })],
                      }));
                      setKwDetail(new_arr);
                  };
                } else if (typeof kwDetail[0] === 'string'){
                    kwDetail.sort((a,b) => {
                        let vol2 = parseInt(b.split('<<>>')[2]);
                        let vol1 = parseInt(a.split('<<>>')[2]);
                        return (vol2 > vol1) ? 1 : (vol2 < vol1) ? -1 : 0
                    });
                };
              };
        } else if (mode === 'rank'){
            if (sort !== 'rank_inc' && sort !== 'rank_dec'){
                setSort('rank_inc');
                if(typeof kwDetail[0] === 'object'){
                  if (typeof kwDetail[0]['keywords'][0] === 'object'){
                      let new_arr = [];
                      new_arr = kwDetail.map((elem) => {
                          const child = elem.keywords.map((ele) => ({
                              group_name: ele.group_name,
                              display_name: ele.display_name,
                              keywords: [...ele.keywords.sort((a, b) => {
                                let vol2 = parseInt(b.split('<<>>')[3]);
                                let vol1 = parseInt(a.split('<<>>')[3]);
                                return (vol2 > vol1) ? -1 : (vol2 < vol1) ? 1 : 0
                              })]
                          }));
                          return(
                              {group_name: elem.group_name,
                              keywords: child}
                          )
                      });
                      setKwDetail(new_arr);
                  } else {
                      let new_arr = [];
                      new_arr = kwDetail.map((elem) => ({
                          group_name: elem.group_name,
                          display_name: elem.display_name,
                          keywords: [...elem.keywords.sort((a, b) => {
                                let vol2 = parseInt(b.split('<<>>')[3]);
                                let vol1 = parseInt(a.split('<<>>')[3]);
                                return (vol2 > vol1) ? -1 : (vol2 < vol1) ? 1 : 0
                          })],
                      }));
                      setKwDetail(new_arr);
                  };
                } else if (typeof kwDetail[0] === 'string'){
                  kwDetail.sort((a,b) => {
                    let vol2 = parseInt(b.split('<<>>')[3]);
                    let vol1 = parseInt(a.split('<<>>')[3]);
                    return (vol2 > vol1) ? -1 : (vol2 < vol1) ? 1 : 0
                });
                };
              } else if(sort === 'rank_dec'){
                  setSort('rank_inc');
                  if(typeof kwDetail[0] === 'object'){
                    if (typeof kwDetail[0]['keywords'][0] === 'object'){
                        let new_arr = [];
                        new_arr = kwDetail.map((elem) => {
                            const child = elem.keywords.map((ele) => ({
                                group_name: ele.group_name,
                                display_name: ele.display_name,
                                keywords: [...ele.keywords.sort((a, b) => {
                                    let vol2 = parseInt(b.split('<<>>')[3]);
                                    let vol1 = parseInt(a.split('<<>>')[3]);
                                    return (vol2 > vol1) ? -1 : (vol2 < vol1) ? 1 : 0
                                })]
                            }));
                            return(
                                {group_name: elem.group_name,
                                keywords: child} // Sort keywords alphabetically
                            )
                        });
                        setKwDetail(new_arr);
                    } else {
                        let new_arr = [];
                        new_arr = kwDetail.map((elem) => ({
                            group_name: elem.group_name,
                            display_name: elem.display_name,
                            keywords: [...elem.keywords.sort((a, b) => {
                                let vol2 = parseInt(b.split('<<>>')[3]);
                                let vol1 = parseInt(a.split('<<>>')[3]);
                                return (vol2 > vol1) ? -1 : (vol2 < vol1) ? 1 : 0
                            })],
                        }));
                        setKwDetail(new_arr);
                    };
                  } else if (typeof kwDetail[0] === 'string'){
                    kwDetail.sort((a,b) => {
                        let vol2 = parseInt(b.split('<<>>')[3]);
                        let vol1 = parseInt(a.split('<<>>')[3]);
                        return (vol2 > vol1) ? -1 : (vol2 < vol1) ? 1 : 0
                    });
                  };
              } else if(sort === 'rank_inc'){
                setSort('rank_dec');
                if(typeof kwDetail[0] === 'object'){
                  if (typeof kwDetail[0]['keywords'][0] === 'object'){
                      let new_arr = [];
                      new_arr = kwDetail.map((elem) => {
                          const child = elem.keywords.map((ele) => ({
                              group_name: ele.group_name,
                              display_name: ele.display_name,
                              keywords: [...ele.keywords.sort((a, b) => {
                                let vol2 = parseInt(b.split('<<>>')[3]);
                                let vol1 = parseInt(a.split('<<>>')[3]);
                                return (vol2 > vol1) ? 1 : (vol2 < vol1) ? -1 : 0
                              })]
                          }));
                          return(
                              {group_name: elem.group_name,
                              keywords: child}
                          )
                      });
                      // console.log(kwDetail);
                      setKwDetail(new_arr);
                  } else {
                      let new_arr = [];
                      new_arr = kwDetail.map((elem) => ({
                          group_name: elem.group_name,
                          display_name: elem.display_name,
                          keywords: [...elem.keywords.sort((a, b) => {
                            let vol2 = parseInt(b.split('<<>>')[3]);
                            let vol1 = parseInt(a.split('<<>>')[3]);
                            return (vol2 > vol1) ? 1 : (vol2 < vol1) ? -1 : 0
                          })],
                      }));
                      setKwDetail(new_arr);
                  };
                } else if (typeof kwDetail[0] === 'string'){
                    kwDetail.sort((a,b) => {
                        let vol2 = parseInt(b.split('<<>>')[3]);
                        let vol1 = parseInt(a.split('<<>>')[3]);
                        return (vol2 > vol1) ? 1 : (vol2 < vol1) ? -1 : 0
                    });
                };
              };
        };
    };

    const selectGroupHandler = (item) => {
        // console.log(item);
        setKwDetail([item]);
        setActiveG(item.group_name);
        setActiveDisplayName(item.display_name);
        // console.log(kwDetail);
        };

    const handleFileUpload = (e) => {
        setSortGroup('');
        const modal = document.getElementById('ModalCenter');
        const m = new bootstrap.Modal(modal);
        // if (mode === 'SERP' && (parseInt(no_clusters.current.value) > 5 || parseInt(no_clusters.current.value) < 3)){
        //     setConfirmKw([]);
        //     setMsg('Similar results should lie within 3 and 5!');
        // }
        setConfirmKw([]);
        setMsg('');
        
        const input_file = document.getElementById('formFile').files
        if (input_file.length === 0){
            return
        };
        console.log(input_file);
        const file_size = input_file[0].size;
        const file = input_file[0];
        const data = new FormData();
        data.append("file", file);
        data.append('group',no_clusters.current.value);
        data.append('mode', mode);
        if (file_size < 50*1024*1024) {
            console.log('less than 50MB');
            Papa.parse(input_file[0], {
                header: false,
                // worker: true,
                skipEmptyLines: true,
                complete: function (results) {
                    console.log(results);
                    setConfirmKw(results.data.flat(1));
                },
              });
            // fetch(`${process.env.REACT_APP_HOST_IP}/api/uploadfile`, {
            //     method: 'POST',
            //     body: data,
            //     headers: {'Authorization': 'Bearer ' + token}
            // }).then(
            //     response => {
            //     dispatch(hideLoading());
            //     return response.json();
            // }).then(kw => {
            //     console.log(kw);
            //     if (Array.isArray(kw)){
            //         setConfirmKw(current => {return [...current, ...kw]});
            //     }else{
            //         setMsg(kw.message);
            //     };
            // })
        } else {
            console.log('greater than 50MB');
        };
        m.toggle();
    };
    const addKwHandler = (mode) => {
        if (mode === 'existing'){
            setProjectKw(c => [...new Set([...c,...confirmKw])]);
            setKwDetail(c => [...new Set([...c,...confirmKw])]);
        }else if (mode === 'new'){
            setTaskId('');
            topic.current.value = '';
            domain.current.value = '';
            description.current.value = '';
            setSearchParams('');
            setKwGroup([]);
            setProjectKw(confirmKw);
            setKwDetail(confirmKw);

        };
    };

    const changeLocationHandler = (e, value) => {
        e.preventDefault();
        setMode(value);
        // console.log(location);
      };

    const furtherSplitHandler = (item, num, mo) => {
        const input = {
            'kw_list': item.keywords,
            'num_clusters': num,
            'group_mode': mo,
            'parent': item.group_name,
            date: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()).getTime(),
        };
        console.log(input);
        dispatch(showLoading());

        fetch(`${process.env.REACT_APP_HOST_IP}/api/${mo === 'SERP' ? 'cluster-matrix-serp' : mo === 'Ada-2' ? 'cluster-ada' : 'cluster-kw'}`, {
                method: 'POST',
                body: JSON.stringify(input),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                    'X-User': userName
                  }
            }).then(
                response => {
                // dispatch(hideLoading());
                dispatch(hideLoading());
                return response.json();
            }).then(data =>{
                console.log(data);
                const new_group = kwGroup.map((it) => {
                    if (it.group_name === item.group_name){
                        return {...it, keywords: data};
                    };
                    return it;
                });
                console.log(new_group);
                setKwGroup(new_group)
            })
    };

    function getStyle({ provided, style, isDragging }) {
        // If you don't want any spacing between your items
        // then you could just return this.
        // I do a little bit of magic to have some nice visual space
        // between the row items
        const combined = {
            ...style,
            ...provided.draggableProps.style,
            backgroundColor: isDragging ? '#fff3cd' : '',
            maxWidth: isDragging ? '20%' : '100%',
        };
        return combined;
    };

    const delKwHandler = (pa, ch, item) => {
        console.log(pa,ch, item);
        if (pa === ch && pa === ''){
            setKwDetail(kwDetail.filter(kw => kw !== item));
            setProjectKw(projectKw.filter(kw => kw !== item));
        } else {
            if(ch === '' || pa === ch && pa.search(/\./i) === -1){
                if(!item){
                    setKwGroup(kwGroup.filter((it) => it.group_name !== pa));
                    setKwDetail([]);
                }else {
                    let newGroup = kwGroup.map((it) => {
                        if(it.group_name === pa){
                        it.keywords.splice(it.keywords.indexOf(item),1);
                        };
                        return it;
                    });
                    setKwGroup(newGroup);
                }
            } else if(pa.search(/\./i) !== -1){
                let newGroup = kwGroup.map((group) => {
                    if(group.group_name === ch){
                        if(!item){
                            console.log('case');
                            let gr = group.keywords.filter((sub_group) => sub_group.group_name !== pa);
                            setKwDetail([]);
                            return {
                                group_name: ch,
                                keywords: gr
                            };
                        }else {
                            group.keywords.map((sub_group) => {
                                if(sub_group.group_name === pa){
                                    sub_group.keywords.splice(sub_group.keywords.indexOf(item),1);
                                };
                                return sub_group;
                            });
                        }    
                    }; 
                    return group;
                });
                setKwGroup(newGroup);
            }else {
                let newGroup = kwGroup.map((group) => {
                    if(group.group_name === pa){
                        if(!item){
                            console.log('case');
                            let gr = group.keywords.filter((sub_group) => sub_group.group_name !== ch);
                            // setKwDetail([]);
                            return {
                                group_name: pa,
                                keywords: gr
                            };
                        } else {
                            group.keywords.map((sub_group) => {
                                if(sub_group.group_name === ch){
                                    sub_group.keywords.splice(sub_group.keywords.indexOf(item),1);
                                };
                                return sub_group;
                            });
                        }  
                    }; 
                    return group;
                });
                setKwGroup(newGroup);
                setKwDetail(newGroup.filter((group) => group.group_name === pa));
            };
        }
    };

    function Item({ provided, pa, ch, item, index, style, isDragging }) {
        // console.log(provided.draggableProps);
        return (
          <tr
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={getStyle({ provided, style, isDragging })}
            className={`item ${isDragging ? "is-dragging" : ""}`}
          >
              <th scope="row">{index + 1}</th>
              <td>{item.split('<<>>')[0]}</td>
              <td>{item.split('<<>>')[1]}</td>
              <td>{item.split('<<>>')[2]}</td>
              <td>{item.split('<<>>')[3]}</td>
              <td>
                <button
                    type="button"
                    className="btn btn-outline-danger btn-sm  me-1"
                    onClick={event => delKwHandler(pa,ch,item)}
                >
                    <FontAwesomeIcon icon={faMinus} />
                </button>
              </td>
          </tr>
        );
      };


    function CloneItem({ provided, item, index, style, isDragging }) {
    // console.log(provided.draggableProps);
        return (
            <tr
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={getStyle({ provided, style, isDragging })}
            className={`item ${isDragging ? "is-dragging" : ""}`}
            >
                {/* <th scope="row">{index + 1}</th> */}
                <td>{item.split('<<>>')[0]}</td>
                {/* <td>Otto</td> */}
                {/* <td>@mdo</td> */}
            </tr>
        );
    };
      
    const onDragEndHandler = (result) => {
        if (!result.destination) return;
        if (result.source.droppableId === result.destination.droppableId) return;
        const parent_group = result.source.droppableId.replace('kd-','').replace(/-.*$/i,'');
        const source_child_group = result.source.droppableId.replace('kd-','').replace(/^.*-/i,'');
        const dest_child_group = result.destination.droppableId.replace('kd-','').replace(/^.*-/i,'');
        const dest_parent_group = result.destination.droppableId.replace('kd-','').replace(/-.*$/i,'');
        console.log('source parent: ', parent_group, 'source child: ', source_child_group, 'dest child: ', dest_child_group, 'dest parent: ', dest_parent_group);
        // console.log(result);
        const changed_kwDetail = Array.from(kwGroup);
        if (dest_child_group === '' && source_child_group === ''){
            if (typeof(changed_kwDetail.filter((item) => item.group_name === dest_parent_group)[0].keywords[0]) === 'object') return;
            console.log('source parent: ',parent_group, 'source child: ',source_child_group, 'dest parent: ', dest_parent_group);
            let movedItem = changed_kwDetail.filter((item) => item.group_name === parent_group)[0].keywords.splice(result.source.index,1)[0];
            changed_kwDetail.filter((item) => item.group_name === dest_parent_group)[0].keywords.splice(1,0,movedItem);
            setKwGroup(changed_kwDetail);
            return;
        } else if (dest_child_group === '' && source_child_group !== ''){
            if (typeof(changed_kwDetail.filter((item) => item.group_name === dest_parent_group)[0].keywords[0]) === 'object') return;
            console.log(parent_group, source_child_group, dest_parent_group);
            let reorderedItem = changed_kwDetail.filter((item) => item.group_name === parent_group)[0].
                                                keywords.filter((item) => item.group_name === source_child_group)[0].
                                                keywords.splice(result.source.index, 1)[0];
            changed_kwDetail.filter((item) => item.group_name === dest_parent_group)[0]
                    .keywords.splice(1,0,reorderedItem);
            setKwGroup(changed_kwDetail);
            return;
        } else if (dest_child_group !== '' && source_child_group !== ''){
            console.log('True');
            let reorderedItem = changed_kwDetail.filter((item) => item.group_name === parent_group)[0].
                                                keywords.filter((item) => item.group_name === source_child_group)[0].
                                                keywords.splice(result.source.index, 1)[0];
            console.log(reorderedItem);
            changed_kwDetail.filter((item) => item.group_name === dest_parent_group)[0].
                    keywords.filter((item) => item.group_name === dest_child_group)[0].
                    keywords.splice(1,0,reorderedItem);
            setKwGroup(changed_kwDetail);
            return;
        } else if(dest_child_group !== '' && source_child_group === '') {
            let movedItem = changed_kwDetail.filter((item) => item.group_name === parent_group)[0].keywords.splice(result.source.index,1)[0];
            changed_kwDetail.filter((item) => item.group_name === dest_parent_group)[0].
                    keywords.filter((item) => item.group_name === dest_child_group)[0].
                    keywords.splice(1,0,movedItem);
            setKwGroup(changed_kwDetail);
            return;
        };
        // console.log(result);
        // const items = Array.from(kwDetail);
        console.log(parent_group, source_child_group, dest_child_group);
        const new_kwDetail = kwDetail.map((group) => {
            if (group.group_name === parent_group) {
                // console.log(group);
                let reorderedItem = group.keywords.filter((item) => item.group_name === source_child_group)[0].keywords.splice(result.source.index, 1)[0];
                group.keywords.filter((item) => item.group_name === dest_child_group)[0].keywords.splice(result.destination.index, 0, reorderedItem);
                // console.log(reorderedItem);
            return group;
        }});
        
        setKwDetail(new_kwDetail);
    };

    const filterHandler = (mode) => {
        setFilter(mode);
        if (mode === 'Keywords'){
            setFilterRange(['','']);
        }else if (mode === 'No. of Keywords'){
            setSearchKey('');
        }

    };

    const applyFilterHandler = (range, search, item) => {
        if (searchKey !== ''){
            if (typeof(item.keywords[0]) === 'object'){
                let kws = [];
                item.keywords.map((it) => {return(it.keywords)}).
                map((ite) => {ite.forEach((i) => {kws.push(i.split('<<>>')[0])})});
                // console.log(kws)
                return kws.includes(search);
            };
            return (
                item.keywords.map((it) => {return(it.keywords.split('<<>>')[0])}).includes(search)
            );
        }else if (range[0] !== '' || range[1] !== '' ){
            if (typeof(item.keywords[0]) === 'object'){
                let count = 0;
                item.keywords.map((it) => {
                    count += it.keywords.length;
                });
                console.log(count)
                return ((range[0] !== '' ? (count >= range[0]) : true)
                && (range[1] !== '' ? (count < range[1]) : true))
            }
            return ((range[0] !== '' ? (item.keywords.length >= range[0]) : true)
                    && (range[1] !== '' ? (item.keywords.length < range[1]) : true))
        };
        return true;
    };

    const saveClusterHandler = () => {
        setImportStat('');
        const m = new bootstrap.Modal(document.getElementById('ModalImport'));
        m.toggle();
      };

    const handleSaveCluster = () => {
        if (topic.current.value === ''){
            saveClusterHandler();
          }else{
            importCluster('save outside');
          }
    };

    const importCluster = (check) => {
        let kws = [];
        let d = []
        // console.log(kwDetail);
        //remove ranking information
        if (typeof kwDetail[0].keywords[0] !== 'string'){
            kwDetail[0].keywords.map(group => {
                const k = group.keywords.map( str => {
                    let lastIndex = str.lastIndexOf("<<>>");
                    if (lastIndex !== -1) {
                        str = str.substring(0, lastIndex); // Extract substring up to the last occurrence
                        return str
                    }
                    })
                kws.push(...k)
            });
            d = kwDetail.map(group => {
                let m = group.keywords.map(child => {
                    let k = child.keywords.map(str => {
                        let lastIndex = str.lastIndexOf("<<>>");
                        if (lastIndex !== -1) {
                            str = str.substring(0, lastIndex); // Extract substring up to the last occurrence
                            return str
                        }
                    })
                    let c = {
                        ...child,
                        keywords: k
                    }
                    return c
                });
                return {...group, keywords: m}
            });
            // console.log(d)
        } else {
            d = kwDetail.map( group => {
                let k = group.keywords.map(str => {
                        let lastIndex = str.lastIndexOf("<<>>");
                        if (lastIndex !== -1) {
                            str = str.substring(0, lastIndex); // Extract substring up to the last occurrence
                            return str
                        }})
                return {...group, keywords: k}
                })
            // console.log(d)
            kws = d[0].keywords
        }
        // console.log(kws)
        const input = {
          topic: topic.current.value,
          domain: domain.current.value,
          description: description.current.value,
          keywords: kws,
          group: d,
          date: new Date().getTime(),
          task: 'grouping_update',
          username: localStorage.getItem('username')
        }
        
        if (taskId !== ''){
            input['id'] = taskId;
        } else {
            input['task'] = 'grouping'
        }
        console.log(input)
        fetch(`${process.env.REACT_APP_HOST_IP}/api/import-outline`,{
            method: 'POST',
            body: JSON.stringify(input),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
                'X-User': userName
              }
        }).then(
            response => {
                return response.json()
            }
        ).then (
            data =>{
              if(data.acknowledged === true){
                setImportStat(true);
                if(check === "save outside") {
                    setAutoSave(true);
                    setTimeout(() => {
                        setAutoSave(false);
                    }, 3*1000)
                }
                if (taskId === ''){
                  setTaskId(data.inserted_id);
                }
              }else{
                setImportStat(false);
              }
              console.log(data);
            }
        );
      };

    const exportFileHandler = (event, datum) => {
        if(datum.length === 0){
          return;
        } else{
        //   dispatch(showLoading());
          console.log(datum);
          let d = [];
          data.group.map(item => {
            item.keywords.map(it => {
                it.keywords.map(keyword => {
                    d.push({
                        parent_group: item.group_name,
                        child_group: it.display_name,
                        url: it.url,
                        keyword: keyword.split('<<>>')[0],
                        volume: keyword.split('<<>>')[1],
                        kd: keyword.split('<<>>')[2],
                        rank: keyword.split('<<>>')[3],
                    })
                })
                
            })
          });
          const csv = Papa.unparse({
            // "fields": ['URL', "Keyword", 'Volume', 'KD', 'CPC'],
            "data": d
        });

          const blob = new Blob([csv]);

          const url = window.URL.createObjectURL(blob, {type: 'text/plain' });
          // console.log(blob);
          const link = document.createElement("a");
          link.download = 'kw_cluster.csv';
          link.href = url;
          link.click();

        //   fetch(`${process.env.REACT_APP_HOST_IP}/api/download/kw-cluster`, {
        //     method: 'POST',
        //     body: JSON.stringify(d),
        //     headers: {
        //       'Content-Type': 'application/json',
        //       'Authorization': 'Bearer ' + token
        //     }
        //   }).then(
        //     response => {
        //       dispatch(hideLoading());
        //       return response.blob();
        //     })
        //     .then(blob => {
        //       const url = window.URL.createObjectURL(blob);
        //       // console.log(blob);
        //       const link = document.createElement("a");
        //       link.download = 'kw_cluster.csv';
        //       link.href = url;
        //       link.click();
        //     }).catch(error => {
        //       console.error(error);
        //   });
        };
      };

    // const myModal = new window.bootstrap.Modal(document.getElementById('#ModalCenter'), {
    //     keyboard: false,
    //     backdrop: true
    // });

    // myModal.show();
    const sortHandler = (e) => {
        e.preventDefault();
        sortGroup === '' ? setSortGroup(true) : setSortGroup(current => !current);
        if (!kwGroup[0].kw_count){
            const newKwGroup = kwGroup.map((item =>{
                if(typeof(item.keywords[0]) === 'object'){
                    let kw_count = 0;
                    item.keywords.map((it) =>{
                        kw_count += it.keywords.length;
                    });
    
                    return {...item, kw_count: kw_count}
                }else {
                    return {...item, kw_count: item.keywords.length}
                }
            }));
            sortGroup === true ? newKwGroup.sort((a,b) => a.kw_count - b.kw_count) : newKwGroup.sort((a,b) => b.kw_count - a.kw_count);
            setKwGroup(newKwGroup);
            return
        }
        sortGroup === true ? kwGroup.sort((a,b) => a.kw_count - b.kw_count) : kwGroup.sort((a,b) => b.kw_count - a.kw_count);
    };

    const openOutlineModelHandler = () => {
        setImportStat('');
        const m = new bootstrap.Modal(document.getElementById('ModalOutline'));
        m.toggle();
    };

    const createOutlineHandler = () => {
        // let kws = kwDetail[0]['keywords'].map(item => {
        //     return item.split('<<>>')[0]
        // })
        const input = {
            topic: activeDisplayName,
            domain: domain.current.value,
            description: activeDisplayName,
            outline: '',
            keywords: kwDetail[0]['keywords'],
            date: new Date().getTime(),
            task: 'outline',
            username: localStorage.getItem('username')
          };
          console.log(input);
          fetch(`${process.env.REACT_APP_HOST_IP}/api/import-outline`,{
              method: 'POST',
              body: JSON.stringify(input),
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': 'Bearer ' + token,
                  'X-User': userName
                }
          }).then(
              response => {
                  return response.json()
              }
          ).then (
              data =>{
                if(data.acknowledged === true){
                  setImportStat(true);
                }else{
                  setImportStat(false);
                }
                console.log(data);
              }
          );
    };
    const locationFilterHandler = (val) => {

        const search = countryList.filter(item => {
            return (
                (val !== '' ? item.Name.search(new RegExp(`^${val}`, 'i')) !== -1 : true)
            )
        });
        setFilteredList(search);
    };
    const changeTopicHandler = (data) => {
        // console.log(data);
        // console.log(kwGroup);
        const m = kwGroup;
        const index = m.findIndex(item => item.group_name === `Nhóm ${data.data.group}`);
        if (index !== -1) {
            const object = m.splice(index, 1)[0];
            // console.log(object);
            m.unshift(object);
        };
        // console.log(m);
        setKwGroup(m);
        const d = data.data;
        d['group_name'] = d['name']
        setActiveG(data.data.name);
        setActiveDisplayName(data.data.display_name);
        setKwDetail([d]);
    };

    const moveGroupHandler = (origin_sub, target) => {
        console.log(origin_sub, target);
        const m = kwGroup;
        if (origin_sub !== '') {
            // Find the child group "childGroupName" in its current parent group
            let childGroup;
            let childGroupIndex;
            for (let i = 0; i < m.length; i++) {
                const parentGroup = m[i];
                const childIndex = parentGroup.keywords.findIndex(child => child.group_name === origin_sub);
                if (childIndex !== -1) {
                    childGroup = parentGroup.keywords.splice(childIndex, 1)[0];
                    childGroupIndex = i;
                    break;
                }
            }
            // Find the index of the parent group "newParentGroupName"
            const newParentIndex = kwGroup.findIndex(group => group.group_name === target);
            const oldParentIndex = kwGroup.findIndex(group => group.group_name === origin_sub.split('.')[0]);

            // If both parent and child groups are found
            if (newParentIndex !== -1 && childGroup) {
                // Add the child group to the children array of the new parent group
                m[newParentIndex].keywords.push(childGroup);
                if (m[oldParentIndex]['keywords'].length > 0){
                    setKwDetail([m[oldParentIndex]]);
                } else {
                    setKwDetail([m[newParentIndex]]);
                }   
            };
        } else{
            // console.log(kwDetail, kwGroup)
            // Find the index of the parent group "newParentGroupName"
            const newParentIndex = kwGroup.findIndex(group => group.group_name === target);
            const oldParentIndex = kwGroup.findIndex(group => group.group_name === kwDetail[0]['group_name']);
            // If parent group is found
            if (newParentIndex !== -1) {
                // Add the child group to the children array of the new parent group
                m[newParentIndex].keywords.push(...kwDetail[0]['keywords']);
                setKwDetail([m[newParentIndex]]);
            };
            // Remove moved parent group
            m.splice(oldParentIndex, 1)
            
        };
        let filtered_m = m.filter(group => group.keywords.length > 0);
        console.log(filtered_m)
        setKwGroup(filtered_m);

    };
    const refreshTaskHandler = () => {
        fetch(`${process.env.REACT_APP_HOST_IP}/api/manage-tasks`,{
            method: 'POST',
            body: JSON.stringify({}),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
                'X-User': userName
                }
        }).then(
            response => {
                return response.json()
            }
        ).then (
            data =>{
                const task = data.filter(item => item.task === 'grouping');
                setPendingTasks(task);
                console.log(task)
        })
    };
    const startAuditHandler = (refresh) => {
        if(urlSeed.current.value === '' || language === '') return;
        const input = {
            "target":urlSeed.current.value,
            "location_code": location,
            // "location_code": 2704,
            "language_code": language,
            'refresh': refresh
        };
        console.log(input);
        dispatch(showLoading());
        fetch(`${process.env.REACT_APP_HOST_IP}/api/url-audit`,{
            method: 'POST',
            body: JSON.stringify(input),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
                'X-User': userName
                }
        }).then(
            response => {
                dispatch(hideLoading());
                return response.json()
            }
        ).then (
            data =>{
                console.log(data);
                if (typeof data === 'string'){
                    const m = new bootstrap.Modal(document.getElementById('ModalAlert'));
                    m.toggle();
                    return;
                }
                setData(data);
                setProjectKw(data['keywords']);
                setKwDetail(data['keywords']);
                setRankingDist(data['ranking_dist']);
                setTotalKeywords(data['total_kws']);
                setTraffic(data['estimated_traffic']);
                if(data['group']) setKwGroup(data['group']);
                const d = data.group.map(group => {
                    const g = group.keywords.map(item => {
                        return {
                            ...item,
                            'name': item.group_name,
                            'group': group.group_name.split(' ')[1]
                        }
                    })
                    return {name: group.group_name, children: g}
                })
                // console.log(d);
                setBubleChartData({children: d});
        })
    };
    const collapseHandler = () => {
        setCollapseAll(c => !c);
    };
    const addGroupHandler = () => {
        let maxNum = Math.max(...kwGroup.map(item => parseInt(item.group_name.replace('Nhóm',''))));
        let m = [...kwGroup, {
                    group_name: `Nhóm ${maxNum+1}`,
                    keywords: []
                }];
        console.log(kwGroup)
        setKwGroup(m);
    };
    const deleteGroupHandler = (group_name) => {
        setKwGroup(c => {
            let m = c.filter(group => group.group_name !== group_name);
            return m;
        });
    };

    return (
        <DragDropContext onDragEnd={onDragEndHandler}>
            <NotiModal 
            kw_list = {confirmKw}
            msg = {msg}
            onGroupKw={addKwHandler}
            />
            {/* <NotiModal
            msg = 'Similar results should lie between 3-5'
            /> */}
            <div className="d-flex flex-column h-100">
                <h1 className="text-center my-4">Competitor Audit</h1>
                <div className="d-flex my-5 flex-column">
                    <div class="d-flex mb-3 w-100">
                        <div class="input-group me-3" style={{maxWidth: '75%'}}>
                            <span class="input-group-text" id="basic-addon1">Input URL</span>
                            <div className='d-flex align-content-start flex-fill border border-light border-2' >
                                {/* <FontAwesomeIcon icon={faSearch} className='fa-3 align-self-center me-2'/> */}
                                <input type="text" class="form-control" placeholder="URL" aria-describedby="button-addon2"
                                style={{border: 'none'}}
                                ref={urlSeed}
                                />                        
                            </div>
                            <button class="btn btn-primary" type="button" id="button-addon2"
                            onClick={e => startAuditHandler('')}
                            >Search</button>
                        </div>
                        <div class="dropdown me-3 align-self-center">
                            <button class="btn btn-secondary dropdown-toggle" type="button" id="location" data-bs-toggle="dropdown" aria-expanded="false">
                                <FontAwesomeIcon icon={faGlobe} className='me-2' />
                                {locationName}
                            </button>
                            <ul class="dropdown-menu p-2" aria-labelledby="dropdownMenuButton1" style={{maxHeight:'300px', 'overflow': 'auto', width: '250px'}}>
                                <input type="text" class="form-control" placeholder="Search country..." aria-label="Search country..." aria-describedby="basic-addon1"
                                onChange = {e => locationFilterHandler(e.target.value)}
                                ref={searchStr}
                                />
                                {filteredList.map((item, index) => {
                                    return (
                                        <li key={index} onClick={e => {setLocationName(item.Name); setLocation(item['Criteria ID'])}}><a class="dropdown-item" href="#">{item.Name}</a></li>
                                    )
                                })}
                            </ul>
                        </div>
                        <div class="dropdown align-self-center">
                            <button class="btn btn-secondary dropdown-toggle" type="button" id="language" data-bs-toggle="dropdown" aria-expanded="false">
                                <FontAwesomeIcon icon={faLanguage} className='me-2' />
                                {language === '' ? 'Language' : language === 'vi' ? 'VI' : language === 'en' ? 'EN' : language}
                            </button>
                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <li><a class="dropdown-item" href="#"
                                onClick={e => {e.preventDefault(); setLanguage('vi')}}
                                >VI</a></li>
                                <li><a class="dropdown-item" href="#"
                                onClick={e => {e.preventDefault(); setLanguage('en')}}
                                >EN</a></li>
                            </ul>
                        </div>
                    </div>
                    <span>Note: a domain or a subdomain should be specified without https:// and www.</span>
                    <span>a page should be specified with absolute URL (including https:// or https://)</span>
                    {
                        Object.keys(data).length > 0 && data['date_checked'] !== '' &&
                        <div className='d-flex align-self-end align-items-center'>
                            <a href='#' className='me-2' onClick={e => startAuditHandler('refresh')}>
                                <FontAwesomeIcon icon={faRefresh} className='me-2'/>
                                Refresh
                            </a>
                            <span className='d-flex'>{`Last checked: ${new Date(data['date_checked']).toLocaleDateString()}`}</span>
                        </div>
                    }
                </div>
                {/* Modal Import*/}
                    <div class="modal fade" id="ModalAlert" tabindex="-1" role="dialog" aria-labelledby="ModalCenterTitle" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered" role="document">
                            <div class="modal-content">
                            <div className="modal-header">
                                <h5 class="modal-title me-auto" id="ModalLongTitle">Resources Exhausted</h5>
                            </div>
                            <div class="modal-body" style={{'max-height':'500px', 'overflow':'auto'}}>
                                <p>URL audit exceeded 30 reports/day</p>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" >Close</button>
                            </div>
                            </div>
                        </div>
                    </div>
                {/* Modal Import*/}
                {serpNoti &&
                    <div className='mb-4' style={{color: 'red'}}>
                    Shared SERP results must lie within 3 - 5.
                </div>}
                {   Object.keys(data).length > 0 &&
                    <>
                        <div className="d-flex justify-content-center">
                            <div class="d-flex flex-column col border shadow-sm rounded mx-3 py-2 px-3">
                                <b>Total ranked keywords</b>
                                <h2 className='align-self-center my-3'>
                                {totalkeywords.toLocaleString('en-us')}
                                </h2>
                                <span style={{minHeight: '21px'}}>
                                
                                </span>
                            </div>
                            <div class="d-flex flex-column col border shadow-sm rounded mx-3 py-2 px-3">
                                <b>Total ranked URLs</b>
                                <h2 className='align-self-center my-3'>
                                {data['total_ranking_urls'].toLocaleString('en-us')}
                                </h2>
                                <span style={{minHeight: '21px'}}>
                                
                                </span>
                            </div>
                            <div class="d-flex flex-column col border shadow-sm rounded mx-3 py-2 px-3">
                                <b>Total groups</b>
                                <h2 className='align-self-center my-3'>
                                {kwGroup.filter((item) => applyFilterHandler(filterRange, searchKey, item)).length.toLocaleString('en-us')}
                                </h2>
                                <span style={{minHeight: '21px'}}>
                                
                                </span>
                            </div>
                            <div class="d-flex flex-column col border shadow-sm rounded mx-3 py-2 px-3">
                                <b>Estimated monthly traffic</b>
                                <h2 className='align-self-center my-3'>
                                {Math.round(traffic).toLocaleString('en-us')}
                                </h2>
                                <span style={{minHeight: '21px'}}>
                                
                                </span>
                            </div>
                            <div class="d-flex flex-column col border shadow-sm rounded mx-3 py-2 px-3">
                                <b className='me-auto'>Ranking distribution</b>
                                <div className='d-flex align-self-center' style={{width: '200px', height: '80px'}}>
                                    {   rankingDist.length > 0 &&
                                        <ResponsiveContainer width="100%" height="100%">
                                        <PieChart
                                            margin={{
                                                top: 0,
                                                right: 10,
                                                left: 10,
                                                bottom: -90,
                                            }}
                                        >
                                        <Pie
                                            dataKey="value"
                                            startAngle={180}
                                            endAngle={0}
                                            data={rankingDist}
                                            cx="50%"
                                            cy="50%"
                                            innerRadius={40}
                                            outerRadius={80}
                                            paddingAngle={2}
                                            fill="#8884d8"
                                        >
                                            {rankingDist.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                            ))}
                                        </Pie>
                                        <Tooltip />
                                        </PieChart>
                                    </ResponsiveContainer>}
                                </div>
                            </div>
                        </div>
                        <div className="d-flex my-5 justify-content-center shadow-sm">
                            {   
                                Object.keys(bubleChartData).length > 0 &&
                                <BubleChart 
                                    data={bubleChartData}
                                    changeActiveTopic={changeTopicHandler}
                                />
                            }
                        </div>
                        <div className="d-flex flex-column flex-grow-1 overflow-hidden">
                            <div className="row flex-grow-1 overflow-hidden">
                                <div className="d-flex flex-column vh-100 col-md-4">
                                    <h3 className="mb-2">Topic Group</h3>
                                    <div className="d-flex mb-2">
                                        <div class="dropdown">
                                            <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                <FontAwesomeIcon icon={faFilter} />
                                                <span className='ms-2'>Filter by</span>
                                            </button>
                                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                <li onClick={e => {e.preventDefault(); filterHandler('No. of Keywords')}}><a class="dropdown-item" href="#">No. of keywords</a></li>
                                                <li onClick={e => {e.preventDefault(); filterHandler('Keywords')}}><a class="dropdown-item" href="#">Keywords</a></li>
                                            </ul>
                                        </div>
                                        <div className="d-flex" style={{"width":"150px"}}>
                                            <div class={`input-group flex-nowrap ${filter !== 'No. of Keywords' && 'd-none'}`}>
                                                {/* <span class="input-group-text" id="addon-wrapping">No. of Keywords</span> */}
                                                <input type="text" class="form-control" placeholder="From" aria-label="From" aria-describedby="addon-wrapping" ref={range_from} onChange={(e) => setFilterRange(current => [e.target.value,current[1]])}/>
                                                <input type="text" class="form-control" placeholder="To" aria-label="To" aria-describedby="addon-wrapping" ref={range_to} onChange={(e) => setFilterRange(current => [current[0],e.target.value])}/>
                                            </div>
                                            <div class={`input-group flex-nowrap ${filter !== 'Keywords' && 'd-none'}`}>
                                                <input type="text" class="form-control" placeholder="Keyword Search..." aria-label="Keyword Search..." aria-describedby="addon-wrapping" onChange={(e) => setSearchKey(e.target.value)}/>
                                            </div>
                                        </div>
                                        <button
                                            type="button"
                                            className={`btn btn-light btn-sm ms-auto me-2`}
                                            onClick={addGroupHandler}
                                        >
                                            <FontAwesomeIcon icon={faPlus} />
                                            
                                        </button>
                                        {kwGroup.length > 0 &&
                                            <>
                                                <button
                                                    type="button"
                                                    className={`btn btn-light btn-sm me-2`}
                                                    onClick={collapseHandler}
                                                >
                                                    <FontAwesomeIcon icon={collapseAll ? faAngleDown : faMinus} />
                                                    
                                                </button>
                                            </>
                                        }
                                        {kwGroup.length > 0 &&
                                            <button
                                                type="button"
                                                className="btn btn-outline-primary btn-sm  mx-2"
                                                onClick={event => sortHandler(event)}
                                            >
                                                {sortGroup === '' ? <FontAwesomeIcon icon={faSort} /> : sortGroup === true ? <FontAwesomeIcon icon={faArrowDown} /> : <FontAwesomeIcon icon={faArrowUp} />}
                                                
                                            </button>
                                        }
                                    </div>
                                    
                                    <div className="col overflow-auto">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="card-text">
                                                    <ul class="list-group">
                                                        {kwGroup.length === 0 && <h5 className="text-muted text-center fs-2" style={{"line-height": "300px", "border": "1px dashed #adb5bd"}}>Kw Group</h5>}
                                                        {kwGroup.filter((item) => applyFilterHandler(filterRange, searchKey, item)
                                                        ).map((item, index) => {
                                                            return (
                                                            <Droppable droppableId={`${item.group_name}-`}>
                                                                {(provided, snapshot) => (
                                                                <>
                                                                    <AuditGroup
                                                                    key={index}
                                                                    item = {item}
                                                                    activeG = {activeG}
                                                                    onSelectGroup = {selectGroupHandler}
                                                                    onFurtherSplit = {furtherSplitHandler}
                                                                    provided={provided}
                                                                    isDraggingOver = {snapshot.isDraggingOver}
                                                                    collapseA={collapseAll}
                                                                    onDeleteGroup={deleteGroupHandler}
                                                                    />
                                                                    {/* {provided.placeholder} */}
                                                                </>
                                                                )}
                                                            
                                                            </Droppable>
                                                        )})}
                                                    </ul>  
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className=" d-flex flex-column vh-100 col-md-8 overflow-hidden">
                                    <div className='d-flex mb-2'>
                                        <h3 className="">Keyword Detail</h3>
                                        <a href="#" className="mx-4 align-self-center"
                                        onClick={e => {e.preventDefault(); setKwDetail(projectKw)}}
                                        >View all kws</a>
                                        {autoSave === true &&
                                        <div className={`d-flex me-2 align-items-center ms-2`} id="success-noti">
                                            <FontAwesomeIcon icon={faCheckCircle} style={{'color':'green'}}/>
                                            <span className='ms-1'>Saved</span>
                                        </div>
                                        }
                                        {/* <button type="button" className={`btn btn-primary ms-auto ${activeG === '' && 'd-none'}`} onClick={openOutlineModelHandler}>Create outline</button> */}
                                        {/* Modal Save Outline*/}
                                        <div class="modal fade" id="ModalOutline" tabindex="-1" role="dialog" aria-labelledby="ModalCenterTitle" aria-hidden="true">
                                                <div class="modal-dialog modal-dialog-centered" role="document">
                                                    <div class="modal-content">
                                                    <div className="modal-header">
                                                        <h5 class="modal-title me-auto" id="ModalLongTitle">Save Outline</h5>
                                                        <div className={`${[false,'',null].includes(importStat) ? 'd-none' : ''}`} id="success-noti">
                                                            <FontAwesomeIcon icon={faCheckCircle} style={{'color':'green'}}/>
                                                            <span className='ms-1'>Success</span>
                                                        </div>
                                                        <div className={`${[true,'',null].includes(importStat) ? 'd-none' : ''}`} id="failed-noti">
                                                            <FontAwesomeIcon icon={faTimesCircle} style={{'color':'red'}}/>
                                                            <span className='ms-1'>Failed. Pls Retry!</span>
                                                        </div>
                                                    </div>
                                                    <div class="modal-body" style={{'max-height':'500px', 'overflow':'auto'}}>
                                                        <div class="d-flex flex-column mb-4">
                                                            <div class="input-group mb-3">
                                                                <span class="input-group-text">Topic</span>
                                                                <input type="text" class="form-control" placeholder="Topic" aria-label="Topic" aria-describedby="basic-addon1" 
                                                                    defaultValue={activeDisplayName}
                                                                />
                                                            </div>
                                                            <div class="input-group mb-3">
                                                                <span class="input-group-text">Domain</span>
                                                                <input type="text" class="form-control" placeholder="Domain" aria-label="Domain" aria-describedby="basic-addon1" defaultValue={domain.current.value}/>
                                                            </div>
                                                            <div class="form-floating">
                                                                <textarea class="form-control" rows="2" placeholder="Leave a description" id="floatingTextarea2" style={{height: '100px'}} 
                                                                    defaultValue={activeDisplayName}
                                                                />
                                                                <label for="floatingTextarea2">Description</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="modal-footer">
                                                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" onClick={e => setImportStat('')}>Close</button>
                                                        <button type="button" className={`btn btn-primary`} id='import-button' onClick={createOutlineHandler}>Save</button>
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>
                                        {/* Modal Save Outline*/}
                                        {
                                            kwDetail.length > 0 && kwDetail[0].keywords && typeof kwDetail[0].keywords[0] !== 'string' &&
                                            <div className='d-flex'>
                                                <select class="form-select" onChange={e => moveGroupHandler('', e.target.value)}>
                                                    <option selected>Move to</option>
                                                    {
                                                        kwGroup.map(item => 
                                                            <option value={item.group_name}>{item.group_name}</option>
                                                            )
                                                    }
                                                </select>
                                            </div>
                                        }
                                        <button
                                        type="button"
                                        className={`btn btn-primary ${kwGroup.length === 0 && 'disabled'} ms-auto me-2`}
                                        onClick={(event) => exportFileHandler(event, kwGroup)}
                                        >
                                            <FontAwesomeIcon icon={faDownload} />
                                        </button>
                                        {
                                            kwDetail.length > 0 && kwDetail[0].keywords &&
                                            <div className={`btn-group`}>
                                                <button type="button" className="btn btn-primary" onClick={handleSaveCluster}>Save</button>
                                                <button type="button" className="btn btn-primary" onClick={saveClusterHandler}>
                                                    <FontAwesomeIcon icon={faUpRightFromSquare}/>
                                                </button>
                                            </div>
                                        }
                                    </div>
                                    {/* Modal Import*/}
                                    <div class="modal fade" id="ModalImport" tabindex="-1" role="dialog" aria-labelledby="ModalCenterTitle" aria-hidden="true">
                                            <div class="modal-dialog modal-dialog-centered" role="document">
                                                <div class="modal-content">
                                                <div className="modal-header">
                                                    <h5 class="modal-title me-auto" id="ModalLongTitle">Save Cluster</h5>
                                                    <div className={`${[false,'',null].includes(importStat) ? 'd-none' : ''}`} id="success-noti">
                                                        <FontAwesomeIcon icon={faCheckCircle} style={{'color':'green'}}/>
                                                        <span className='ms-1'>Success</span>
                                                    </div>
                                                    <div className={`${[true,'',null].includes(importStat) ? 'd-none' : ''}`} id="failed-noti">
                                                        <FontAwesomeIcon icon={faTimesCircle} style={{'color':'red'}}/>
                                                        <span className='ms-1'>Failed. Pls Retry!</span>
                                                    </div>
                                                </div>
                                                <div class="modal-body" style={{'max-height':'500px', 'overflow':'auto'}}>
                                                    <div class="d-flex flex-column mb-4">
                                                        <div class="input-group mb-3">
                                                            <span class="input-group-text">Add to Pending Task</span>
                                                            <div class="dropdown">
                                                                <button class="btn btn-secondary dropdown-toggle" type="button" id="taskID" data-bs-toggle="dropdown" aria-expanded="false">
                                                                Pending task
                                                                </button>
                                                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1" style={{maxHeight: '200px', overflow: 'auto'}}>
                                                                { pendingTasks.map((item, index) => {
                                                                        return (
                                                                            <li key={index}><a class="dropdown-item" href="#" onClick={e => {e.preventDefault();
                                                                                setTaskId(item._id);
                                                                                document.getElementById('taskID').innerHTML = item.topic;
                                                                                topic.current.value = item.topic;
                                                                                domain.current.value = item.domain;
                                                                                description.current.value = item.description;
                                                                            }}
                                                                            >{item.topic}</a></li>
                                                                        )
                                                                })
                                                                }
                                                                </ul>
                                                                <button type='button' className='btn ms-2' onClick = {refreshTaskHandler}>
                                                                    <FontAwesomeIcon icon={faRefresh} />
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div class="input-group mb-3">
                                                            <span class="input-group-text">Topic</span>
                                                            <input type="text" class="form-control" placeholder="Topic" aria-label="Topic" aria-describedby="basic-addon1" ref={topic}/>
                                                        </div>
                                                        <div class="input-group mb-3">
                                                            <span class="input-group-text">Domain</span>
                                                            <input type="text" class="form-control" placeholder="Domain" aria-label="Domain" aria-describedby="basic-addon1" ref={domain}/>
                                                        </div>
                                                        <div class="form-floating">
                                                            <textarea class="form-control" placeholder="Leave a description" id="floatingTextarea2" style={{height: '100px'}} ref={description}/>
                                                            <label for="floatingTextarea2">Description</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="modal-footer">
                                                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" onClick={e => setImportStat('')}>Close</button>
                                                    <button type="button" className={`btn btn-primary`} id='import-button' onClick={importCluster}>Save</button>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                    {/* Modal Import*/}
                                    <div className="d-flex flex-row pb-4 mb-4 overflow-auto">
                                        
                                            <table class="table table-striped">
                                                <thead className="sticky-top">
                                                    <tr className='table-primary'>
                                                        <th scope="col">#</th>
                                                        <th scope="col">
                                                            <button class="btn" type="button" aria-expanded="false" onClick={event => tableSortHandler('kw')}>
                                                                <span className='me-2 fw-bold'>Keyword </span>
                                                                <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'keyword_inc' ? '#000' : '#6c757d'}`}}/>
                                                                <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'keyword_dec' ? '#000' : '#6c757d'}`}}/>
                                                            </button>
                                                        </th>
                                                        <th scope="col">
                                                            <button class="btn" type="button" aria-expanded="false" onClick={event => tableSortHandler('volume')}>
                                                                <span className='me-2 fw-bold'>Volume </span>
                                                                <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'volume_inc' ? '#000' : '#6c757d'}`}}/>
                                                                <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'volume_dec' ? '#000' : '#6c757d'}`}}/>
                                                            </button>
                                                        </th>
                                                        <th scope="col">
                                                            <button class="btn" type="button" aria-expanded="false" onClick={event => tableSortHandler('kd')}>
                                                                <span className='me-2 fw-bold'>KD </span>
                                                                <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'kd_inc' ? '#000' : '#6c757d'}`}}/>
                                                                <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'kd_dec' ? '#000' : '#6c757d'}`}}/>
                                                            </button>
                                                        </th>
                                                        <th scope="col">
                                                            <button class="btn" type="button" aria-expanded="false" onClick={event => tableSortHandler('rank')}>
                                                                <span className='me-2 fw-bold'>Rank </span>
                                                                <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'rank_inc' ? '#000' : '#6c757d'}`}}/>
                                                                <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'rank_dec' ? '#000' : '#6c757d'}`}}/>
                                                            </button>
                                                        </th>
                                                        <th scope="col"></th>
                                                    </tr>
                                                    {kwDetail.length === 0 && 
                                                    <tr>
                                                        <th colspan="5" style={{'border': "None"}}>
                                                            <h5 className="text-muted text-center fs-2" style={{"line-height": "200px", "border": "1px dashed #adb5bd"}}>Group Detail</h5>
                                                        </th>
                                                    </tr>}
                                                </thead>
                                                {/* <tbody> */}
                                                    {typeof kwDetail[0] !== 'string' ?
                                                        kwDetail.map((group) => {
                                                            // console.log(group);
                                                            let sub_group_id = 0;
                                                            if (typeof(group.keywords[0]) !== 'object') {
                                                                let counter = 0;
                                                                let sorted_kws = group.keywords; //.sort() => eliminate sort()
                                                                sub_group_id += 1;
                                                                let group_id = '';
                                                                if(group.group_name.search(/\./i) === -1){
                                                                    group_id = `kd-${group.group_name}-`
                                                                } else {
                                                                    group_id = `kd-${group.group_name.replace(/\..*$/i,'')}-${group.group_name}`
                                                                }
                                                                return (
                                                                    <Droppable
                                                                        droppableId={group_id}
                                                                        mode="virtual"
                                                                        renderClone={(provided, snapshot, rubric) => (
                                                                            <CloneItem
                                                                            provided={provided}
                                                                            isDragging={snapshot.isDragging}
                                                                            item={group.keywords[rubric.source.index]}
                                                                            index={rubric.source.index}
                                                                            // style={{...provided.droppableProps}}
                                                                            />
                                                                            )}
                                                                    >
                                                                        {(provided) => (
                                                                            <tbody {...provided.droppableProps} ref={provided.innerRef}>
                                                                                <tr>
                                                                                    <td colspan="4" class="table-primary">{group.display_name || group.group_name}</td>
                                                                                    <td class='table-primary'>
                                                                                        <select class="form-select" onChange={e => moveGroupHandler(group.group_name, e.target.value)}>
                                                                                            <option selected>Move to</option>
                                                                                            {
                                                                                                kwGroup.map(item => 
                                                                                                    <option value={item.group_name}>{item.group_name}</option>
                                                                                                    )
                                                                                            }
                                                                                        </select>
                                                                                    </td>
                                                                                    <td class="table-primary">
                                                                                        <button
                                                                                            type="button"
                                                                                            className="btn btn-outline-danger btn-sm  me-1"
                                                                                            onClick={event => delKwHandler(group.group_name,group.group_name.replace(/\..*$/i,''))}
                                                                                        >
                                                                                            <FontAwesomeIcon icon={faMinus} />
                                                                                        </button>
                                                                                    </td>
                                                                                </tr>
                                                                                {sorted_kws.map((kw, index) => {
                                                                                    counter += 1;
                                                                                    return (
                                                                                        <Draggable key={kw} draggableId={kw} index={index} display='table'>
                                                                                            {(provided) => (
                                                                                                <Item
                                                                                                provided={provided}
                                                                                                index={index}
                                                                                                pa={group.group_name}
                                                                                                ch={group.group_name.replace(/\..*$/i,'')}
                                                                                                item={kw}
                                                                                                {...provided.draggableProps}
                                                                                                {...provided.dragHandleProps}
                                                                                                />
                                                                                            )}
                                                                                        </Draggable>
                                                                                    )
                                                                                    
                                                                                })}
                                                                            </tbody>)}
                                                                    </Droppable>
                                                                )
                                                            } else {
                                                                    return(
                                                                        <>
                                                                        {group.keywords.map((embedded_group) => {
                                                                            let counter = 0;
                                                                            let sort_embedded = embedded_group.keywords; //eleminate sort()
                                                                            sub_group_id += 1;
                                                                            return (
                                                                                <Droppable
                                                                                    droppableId={`kd-${group.group_name}-${embedded_group.group_name}`}
                                                                                    mode="virtual"
                                                                                    renderClone={(provided, snapshot, rubric) => (
                                                                                        <CloneItem
                                                                                        provided={provided}
                                                                                        isDragging={snapshot.isDragging}
                                                                                        item={embedded_group.keywords[rubric.source.index]}
                                                                                        index={rubric.source.index}
                                                                                        style={{...provided.droppableProps}}
                                                                                        />
                                                                                        )}
                                                                                >
                                                                                    {(provided) => (
                                                                                        <tbody {...provided.droppableProps} ref={provided.innerRef} >
                                                                                            
                                                                                            <tr>
                                                                                                <td colspan="4" class="table-primary" >{embedded_group.display_name || embedded_group.group_name}</td>
                                                                                                <td class='table-primary'>
                                                                                                    <select class="form-select" onChange={e => moveGroupHandler(embedded_group.group_name, e.target.value)}>
                                                                                                        <option selected>Move to</option>
                                                                                                        {
                                                                                                            kwGroup.map(item => 
                                                                                                                <option value={item.group_name}>{item.group_name}</option>
                                                                                                                )
                                                                                                        }
                                                                                                    </select>
                                                                                                </td>
                                                                                                <td class="table-primary">
                                                                                                    <button
                                                                                                        type="button"
                                                                                                        className="btn btn-outline-danger btn-sm  me-1"
                                                                                                        onClick={event => delKwHandler(group.group_name,embedded_group.group_name)}
                                                                                                    >
                                                                                                        <FontAwesomeIcon icon={faMinus} />
                                                                                                    </button>
                                                                                                </td>
                                                                                            </tr>
                                                                                            {provided.placeholder}
                                                                                            {sort_embedded.map((kw, index) => {
                                                                                                counter += 1;
                                                                                                return (
                                                                                                    <Draggable key={kw} draggableId={kw} index={index} display='table'>
                                                                                                        {(provided) => (
                                                                                                            <Item
                                                                                                            provided={provided}
                                                                                                            pa={group.group_name}
                                                                                                            ch={embedded_group.group_name}
                                                                                                            index={index}
                                                                                                            item={kw}
                                                                                                            {...provided.draggableProps}
                                                                                                            {...provided.dragHandleProps}
                                                                                                            style={provided.draggableProps.style}
                                                                                                            />
                                                                                                        )}   
                                                                                                    </Draggable>
                                                                                                )
                                                                                            })}
                                                                                            {provided.placeholder}
                                                                                        </tbody>
                                                                                    )}
                                                                                </Droppable>
                                                                            )})}
                                                                        </>
                                                                        )
                                                                };
                                                        }) :
                                                        <tbody>
                                                        {kwDetail.map((item, index) => {
                                                            return(
                                                                    <tr key={index}>
                                                                        <th scope="row">{index+1}</th>
                                                                        <td >{item.split('<<>>')[0]}</td>
                                                                        <td >{item.split('<<>>')[1] !== 'None' ? item.split('<<>>')[1] : 0}</td>
                                                                        <td >{item.split('<<>>')[2] !== 'None' ? item.split('<<>>')[2] : ''}</td>
                                                                        <td >{item.split('<<>>')[3] !== 'None' ? item.split('<<>>')[3] : 0}</td>
                                                                        <td >
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-outline-danger btn-sm  me-1"
                                                                                onClick={event => delKwHandler('','',item)}
                                                                            >
                                                                                <FontAwesomeIcon icon={faMinus} />
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                
                                                            )
                                                        })}
                                                        </tbody>
                                                    }
                                            </table>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>  
        </DragDropContext>
    )
};

export default RankedKws;