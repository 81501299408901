import { useState, useRef, Fragment, useEffect, useMemo } from 'react';
import Menu from "../components/menu";
import Papa from 'papaparse';

import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap/dist/js/bootstrap.min.js";
import * as bootstrap from 'bootstrap/dist/js/bootstrap'
import DatePicker from "../components/datepick";

import { OverlayTrigger, Popover } from 'react-bootstrap';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash, faDownload, faRefresh, faFilter, faMinus, faCalendar, faAngleUp, faAngleDown, faTimesCircle, faLineChart, faUpload, faGlobe, faPencil, faCheckCircle
} from "@fortawesome/free-solid-svg-icons";

import {showLoading, hideLoading } from 'react-redux-loading-bar';
import { useDispatch } from "react-redux";

import {
    CartesianGrid,
    Legend,
    Line,
    AreaChart,
    Area,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
  } from "recharts";
  import moment from "moment";
  import { scaleTime } from "d3-scale";
import { getAuthToken } from '../util/token';


function ManageRankTracking() {
    const [rankDetail, setRankDetail] = useState([]);
    const [filteredRank, setFilteredRank] = useState([]);
    const [filter, setFilter] = useState([]);
    const [filterRange, setFilterRange] = useState(['','']);
    const [sort,setSort] = useState('');
    const [showResult, setShowResult] = useState(10);
    const [pagination, setPagination] = useState([]);
    const [selectedPage, setSelectedPage] = useState(1);
    const [currentPageRange, setCurrentPageRange] = useState(1);

    const [ domain, setDomain] = useState([]);
    const [ country, setCountry ] = useState([]);
    const [ selectedDomain, setSelectedDomain] = useState('');
    const [ selectedCountry, setSelectedCountry] = useState('');
    const [ selectedCategory, setSelectedCategory] = useState('');
    const [searchKey, setSearchKey] = useState('');
    const [ searchURL, setSearchURL] = useState('');
    const [ activeCountry, setActiveCountry ] = useState("");
    const [ customFreq, setCustomFreq ] = useState(false);
    const [kwList, setKwList] = useState([]);
    const [selectKwList, setSelectKwList] = useState([]);

    //Modal states:
    const [mKey, setMKey] = useState('');
    const [mDomain, setMDomain] = useState('');
    const [mCountry, setMCountry] = useState('');
    const [mCategory, setMCategory] = useState('');
    const [mURL, setMURL] = useState('');
    const [cFreq, setCFreq] = useState();
    const [mFreq, setMFreq] = useState('');

    const [importStat, setImportStat] = useState('');
    const [ valArr, setValArr ] = useState([]);

    // Bulk Edit
    const multiFreq = useRef();
    const multiCat = useRef();
    const multiURL = useRef();
    const multiDays = useRef();


    //Manual keyword input
    const manualDomain = useRef();
    const manualKeywords = useRef();
    const [manualCountry, setManualCountry] = useState('');
    const [manualFreq, setManualFreq] = useState('');

    //Modal Import
    const modal = document.getElementById('ModalImport');

    //auth token
    const token = getAuthToken();
    const userName = localStorage.getItem('username');

    const keySearch = useRef();
    const urlSearch = useRef();
    const categorySearch = useRef();
    



    const dispatch = useDispatch();


    useEffect(() => {
      const input = {}
      fetch(`${process.env.REACT_APP_HOST_IP}/api/keyword-list`,{
          method: 'POST',
          body: JSON.stringify(input),
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + token,
              'X-User': userName
            }
      }).then(
          response => {
              // console.log(response.json())
              return response.json()
          }
      ).then (
          data =>{
            console.log(data);
              setRankDetail(data);
              setFilteredRank(data);
              let newPagination = [];
              for (let i = 1; i <= Math.ceil(data.length/showResult); i++){
                // console.log(i);
                newPagination.push(
                  <a class="page-link" href="#" onClick={(e) => {e.preventDefault();
                    setSelectedPage(i);
                    const el = document.querySelectorAll('li[class*=active]')[0];
                    el.className = 'page-item';
                    document.getElementById(`page-${i}`).className = 'page-item active';
                    }}>{i}
                  </a>
                )
              };
              setPagination(newPagination);
              const cat = [...new Set(data.map(item => item.Domain))];
              setDomain([...cat]);
              setSelectedDomain(data[0].Domain);
              const ct = [...new Set(data.map(item => item.Country))];
              console.log(cat, ct);
              setCountry([...ct]);
          })
    },[]);


    useEffect(() => {
      setCurrentPageRange(1);
      setSelectedPage(1);
      let newPagination = [];
      const filtered_range = rankDetail.filter((item, index) => applyFilterHandler(item));
      setFilteredRank(filtered_range);
      for (let i = 1; i <= Math.ceil(filtered_range.length/showResult); i++){
        newPagination.push(
          <a class="page-link" href="#" onClick={(e) => {e.preventDefault();
            setSelectedPage(i);
            const el = document.querySelectorAll('li[class*=active]')[0];
            el.className = 'page-item';
            document.getElementById(`page-${i}`).className = 'page-item active';
            }}>{i}
          </a>
        )
      };
      setPagination(newPagination);
    },[searchKey, searchURL, showResult, selectedDomain, selectedCountry, selectedCategory]);


    const filterHandler = (e, mode) => {
      e.preventDefault();
      setFilter(current => [...current, mode]);
    };

    const sortHandler = (mode) => {
      if (mode === 'kw'){
        if (sort !== 'keyword_inc' && sort !== 'keyword_dec'){
          setSort('keyword_inc');
          filteredRank.sort((a,b) => (b.Keyword > a.Keyword) ? -1 : (b.Keyword < a.Keyword) ? 1 : 0);
        } else if(sort === 'keyword_dec'){
          setSort('keyword_inc');
          filteredRank.sort((a,b) => (b.Keyword > a.Keyword) ? -1 : (b.Keyword < a.Keyword) ? 1 : 0);
        } else if(sort === 'keyword_inc'){
          setSort('keyword_dec');
          filteredRank.sort((a,b) => (b.Keyword > a.Keyword) ? 1 : (b.Keyword < a.Keyword) ? -1 : 0);
        };
      }};

  const clearFilterHandler = (e, mode) =>{
    const newFilter = filter.filter(item => item !== mode);
    setFilter(newFilter);
    if(mode === 'Domain'){
      setSelectedDomain('Domain');
    }else if (mode === 'Category'){
      setSelectedCategory('');
    }else if (mode === 'Keyword'){
      setSearchKey('');
    }else if (mode === 'URL'){
      setSearchURL('');
    } else if (mode === 'Country'){
      setSelectedCountry('Country');
    }
  };

  const changeShowResultHandler= (result) => {
    setCurrentPageRange(1);
    setSelectedPage(1);
    setShowResult(result);
  };



  const filterModeHandler = () => {
    // setFilterMode(current => !current);
    if(filter.includes('Category') === true) {
      const search_cat_mode = document.getElementById("cat-search-mode").innerHTML;
      let search_category = categorySearch.current.value;
      if (search_cat_mode === 'E') {
        search_category = `^${categorySearch.current.value}$`;
      }
      setSelectedCategory(c => search_category)
    };
    if(filter.includes('Keyword') === true) {
      const search_key_mode = document.getElementById("kw-search-mode").innerHTML;
      let search_key = keySearch.current.value;
      if (search_key_mode === 'E') {
        search_key = `^${keySearch.current.value}$`;
      }
      setSearchKey(c => search_key)
    };
    if(filter.includes('URL') === true) {
      const search_url_mode = document.getElementById("url-search-mode").innerHTML;
      let search_url = urlSearch.current.value;
      if (search_url_mode === 'E') {
        search_url = `^${search_url}$`;
      };
      console.log(search_url);
      setSearchURL(c => search_url)
    };
    // if(filter.includes('Country') === true) setSelectedCountry(country);
    // if(filter.includes('Domain') === true) setSelectedDomain(selectedCategory);
  };


  const applyFilterHandler = (item) => {
    console.log('re render');
    return(
      (selectedDomain !== '' && selectedDomain !== 'Domain' ? item.Domain === selectedDomain : true) &&
      (selectedCountry !== '' && selectedCountry !== 'Country' ? item.Country === selectedCountry : true) &&
      (selectedCategory !== '' ? item.Category.search(new RegExp(selectedCategory, 'i')) !== -1 : true) &&
      (searchURL !== '' ? item.URL.search(new RegExp(searchURL, 'i')) !== -1 : true) &&
      (searchKey !== '' ? item.Keyword.search(new RegExp(searchKey, 'i')) !== -1 : true)
    )
      // setRankDetail(cuurent => filteredArray);
  };

    const downloadRankReportHandler = (rank) => {
      if(rank.length === 0){
        return;
      } else{
        dispatch(showLoading());
        // console.log(outline);
        fetch(`${process.env.REACT_APP_HOST_IP}/api/download/ranking-report`, {
          method: 'POST',
          body: JSON.stringify(rank),
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
            'X-User': userName
          }
        }).then(
          response => {
            dispatch(hideLoading());
            return response.blob();
          }).then(blob => {
            const url = window.URL.createObjectURL(blob);
            // console.log(blob);
            const link = document.createElement("a");
            link.download = 'ranking_report.xlsx';
            link.href = url;
            link.click();
          }).catch(error => {
            console.error(error);
        });
      };
    };



    const importHandler = (e) => {
      e.preventDefault();
      setImportStat('');
      setValArr([]);
      setKwList([]);
      let date = (new Date()).getTime();
      let m = new bootstrap.Modal(modal);
      const kw_list = manualKeywords.current.value.trim().split('\n');
      console.log(kw_list);
      const input_file = document.getElementById('uploadfile').files;
      if (input_file.length === 0 || input_file[0].type !== 'text/csv') {
        if ( manualDomain.current.value === '') setValArr(c => [...c, 'blankDomain']);
        if ( manualFreq === '') setValArr(c => [...c, 'blankFreq']);
        if ( manualCountry === '') setValArr(c => [...c, 'blankCountry']);
        if (kw_list.filter(item => item !== '').length === 0) setValArr(c => [...c, 'blankKeywords']);
        console.log(valArr);
        if (manualDomain.current.value === '' ||
            manualFreq === '' ||
            manualCountry === '' ||
            kw_list.filter(item => item !== '').length === 0
          ) {
          console.log('not validated');
          console.log(valArr);
          return
        };

        const data = [];
        kw_list.map(item =>{
          let d = {}
          if (item !== '') {
            d.Keyword = item;
            d.Domain = manualDomain.current.value;
            d.Freq = manualFreq;
            d.Country = manualCountry;
            d.Category = '';
            d.URL = '';
            d.dateAdded = date;
            data.push(d);
          };
        });
        setKwList(c => data);
      } else {
        Papa.parse(input_file[0], {
          header: true,
          // worker: true,
          skipEmptyLines: true,
          complete: function (results) {
            const d = results.data.map(item => {
              return {
                ...item,
                dateAdded: new Date().getTime(),
              }
            })
            setKwList(c => d);
          },
        });
      };
      m.toggle();
    };
    const fetchMetrics = async (input) => {
      await fetch(`${process.env.REACT_APP_HOST_IP}/api/generate-historical-metrics`,{
          method: 'POST',
          body: JSON.stringify(input),
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + token,
              'X-User': userName
            }
      })
    };

    const importDB = () => {
      let type = 'import'
      if (document.getElementById('update').checked){
        type = 'update'
      }
      fetch(`${process.env.REACT_APP_HOST_IP}/api/import-kw`,{
          method: 'POST',
          body: JSON.stringify({
            type: type,
            data: kwList
          }),
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + token,
              'X-User': userName
            }
      }).then(
          response => {
              return response.json()
          }
      ).then (
          data =>{
            if (data.acknowledged === true) {
              setImportStat(true);
              setFilteredRank(data.new_arr);
              setRankDetail(data.new_arr);
              const cat = [...new Set(data.new_arr.map(item => item.Domain))];
              setDomain([...cat]);
            } else {
              setImportStat(false);
            };
          }
      );
      if (document.getElementById('volume').checked && kwList.length < 2000){
        let kws = kwList.map(item => item.Keyword);
        let lang = '1000';
        let reg = 'US';
        if (kwList[0]['Country'] === 'Vietnam'){
          lang = '1040';
          reg = 'VN';
        } else if (kwList[0]['Country'] === 'Singapore') {
          reg = 'SG';
        };
        const input = {
            location: reg,
            language: lang,
            keyword_seed: kws
        };
        console.log(input);
        fetchMetrics(input);
      };
      if (document.getElementById('checkBox1').checked){
        fetch(`${process.env.REACT_APP_HOST_IP}/api/check-keyword-rank`,{
          method: 'POST',
          body: JSON.stringify(kwList),
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + token,
              'X-User': userName
            }
      })
      // .then(
      //     response => {
      //         return response.json()
      //     }
      // ).then (
      //   data => {
      //     console.log(data);
      //   }
      // )
      };
    };

    const updateKwHandler = () => {
      let li = [];
      let freq = '';
      if (selectKwList.length > 0){
        freq = multiFreq.current.value;
        if (!['Daily', 'Weekly'].includes(freq)){
          freq = multiDays.current.value;
        };
        li = selectKwList.map((item) => {
          return {
            ...item,
            Freq: freq,
            Category: multiCat.current.value,
            URL: multiURL.current.value
          };
        });
      } else {
        if (['Daily', 'Weekly'].includes(mFreq)){
          freq = mFreq;
        }else {
          freq = cFreq
        };
        li = [{
          Keyword: mKey,
          Domain: mDomain,
          Country: mCountry,
          URL: mURL,
          Category: mCategory,
          Freq: freq
        }];
      };
      console.log(li);

      fetch(`${process.env.REACT_APP_HOST_IP}/api/keyword-update`,{
          method: 'PUT',
          body: JSON.stringify(li),
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + token,
              'X-User': userName
            }
      }).then(
          response => {
              return response.json()
          }
      ).then (
          data =>{
            if (data.acknowledged === true) {
              setImportStat(true);
              setFilteredRank(data.new_arr);
              setRankDetail(data.new_arr);
            } else {
              setImportStat(false);
            };
          }
      );
    };

    const deleteKwHandler = (key, domain, country, url, category) => {
      const d = document.getElementsByClassName('row-checkbox');
      for ( let i = 0; i < d.length; i++) d[i].checked = false;
      // console.log(selectKwList);
      let item = selectKwList;
      if (key === undefined){
        // console.log('undefined')
        item = {
          Keyword: key,
          Domain: domain,
          Country: country,
          URL: url,
          Category: category,
        };
      };
      // console.log(item)
      fetch(`${process.env.REACT_APP_HOST_IP}/api/keyword-delete`,{
          method: 'DELETE',
          body: JSON.stringify(item),
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + token,
              'X-User': userName
            }
      }).then(
          response => {
              return response.json()
          }
      ).then (
          data =>{
            if (data.acknowledged === true) {
              setImportStat(true);
              setRankDetail(data.new_arr);
              setFilteredRank(data.new_arr);
              const cat = [...new Set(data.new_arr.map(item => item.Domain))];
              setDomain([...cat]);
            } else {
              setImportStat(false);
            };
          }
      );
      setSelectKwList([]);
    };

    const changeSelectedKwHandler = (e, kw) => {
      if (e.target.checked) {
        if (kw === undefined) {
          const d = document.getElementsByClassName('row-checkbox');
          for ( let i = 0; i < d.length; i++) d[i].checked = true;
          setSelectKwList(filteredRank);
        } else {
          setSelectKwList(c => [...c, kw]);
        }
      } else {
        if (kw === undefined){
          const d = document.getElementsByClassName('row-checkbox');
          for ( let i = 0; i < d.length; i++) d[i].checked = false;
          setSelectKwList([]);
        } else {
          const new_list = selectKwList.filter(item => !(item.Keyword === kw.Keyword && item.Domain === kw.Domain && item.Country === kw.Country));
          setSelectKwList(new_list);
        }
      };
      // console.log(selectKwList, kw);
    };

    return (
        <div className="d-flex flex-column">
            <h1 className="text-center mt-4 mb-5">Manage Rank Tracking</h1>
            <div class="d-flex flex-column mb-4 w-50">
              <div class="mb-3 input-group">
                <span class="input-group-text" id="domain">*Domain</span>
                <input className={`form-control ${valArr.includes('blankDomain') && 'border border-danger'}`} type="text" placeholder="Domain" aria-label="Domain" aria-describedby="basic-addon1" ref={manualDomain}/>
              </div>
              <div class="mb-3 input-group">
                <span class="input-group-text">*Country</span>
                <select class={`form-select ${valArr.includes('blankCountry') && 'border border-danger'}`} id="country" onChange = {e => setManualCountry(c => e.target.value)}>
                  <option selected>Choose...</option>
                  <option value="US">US</option>
                  <option value="Singapore">Singapore</option>
                  <option value="Vietnam">Vietnam</option>
                </select>
              </div>
              <div className="d-flex mb-3">
                <div class="input-group">
                  <span class="input-group-text">Frequency</span>
                  <select class={`form-select ${valArr.includes('blankFreq') && 'border border-danger'}`} id="freq_insert"
                  onChange={e => {e.preventDefault();
                                  e.target.value === 'Custom' ? setCustomFreq(c => true) : setCustomFreq(c => false);
                                setManualFreq(e.target.value);
                                }}
                  >
                    <option selected>Choose...</option>
                    <option value="Daily">Daily</option>
                    <option value="Weekly">Weekly</option>
                    <option value="Custom">Custom</option>
                  </select>
                </div>
                {
                  customFreq &&
                  <div class="input-group ms-3">
                    <span class="input-group-text">Each per</span>
                    <input type="text" class="form-control" placeholder="Days" aria-label="Days" aria-describedby="basic-addon1" onChange={e => setManualFreq(e.target.value)}/>
                    <span class="input-group-text">days</span>
                </div>
                }
              </div>
              <div class="d-flex input-group mb-3">
                <span class="input-group-text">Keywords</span>
                <div class="flex-grow-1 form-floating">
                  <textarea class={`form-control ${valArr.includes('blankKeywords') && 'border border-danger'}`} style={{"max-height":"300px"}} aria-label="With textarea" id="floatingTextarea" ref={manualKeywords}></textarea>
                  <label for="floatingTextarea">Each keyword separated by new line</label>
                </div>
              </div>
              <div class="d-flex input-group mb-3">
                <input type="file" class="form-control" id="uploadfile"/>
              </div>
              <a
                href='/kw_import_template.csv'
                download = 'kw_import_template'
                rel='noreferrer'
                >Download template file</a>
              <button class="btn btn-primary ms-auto" type="button" aria-expanded="false"  onClick={event => importHandler(event)}>
                    <FontAwesomeIcon icon={faUpload} className="pe-2"/>
                    Import
              </button>
            </div>
            {/* Modal Import*/}
            <div class="modal fade" id="ModalImport" tabindex="-1" role="dialog" aria-labelledby="ModalCenterTitle" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div class="modal-content">
                          <div className="modal-header">
                              <h5 class="modal-title me-auto" id="ModalLongTitle">Keyword Import List</h5>
                              <div className={`${[false,'',null].includes(importStat) ? 'd-none' : ''}`} id="success-noti">
                                <FontAwesomeIcon icon={faCheckCircle} style={{'color':'green'}}/>
                                <span className='ms-1'>Success</span>
                              </div>
                              <div className={`${[true,'',null].includes(importStat) ? 'd-none' : ''}`} id="failed-noti">
                                <FontAwesomeIcon icon={faTimesCircle} style={{'color':'red'}}/>
                                <span className='ms-1'>Failed. Pls Retry!</span>
                              </div>
                          </div>
                          <div class="modal-body" style={{'max-height':'500px', 'overflow':'auto'}}>
                            <div class="d-flex mb-4">
                              <table class="table">
                                <thead>
                                  <tr>
                                    <th scope="col" style={{'width':'16%'}}>Keyword</th>
                                    <th scope="col" style={{'width':'16%'}}>Category</th>
                                    <th scope="col" style={{'width':'16%'}}>Domain</th>
                                    <th scope="col" style={{'width':'16%'}}>Country</th>
                                    <th scope="col" style={{'width':'16%'}}>Freq</th>
                                    <th scope="col" style={{'width':'20%'}}>URL</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {
                                    kwList.map((item, index) => { return (
                                      <tr key={index}>
                                        <td>{item.Keyword}</td>
                                        <td>{item.Category}</td>
                                        <td>{item.Domain}</td>
                                        <td>{item.Country}</td>
                                        <td>{item.Freq}</td>
                                        <td>{item.URL}</td>
                                      </tr>
                                    )})
                                  }
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div class="modal-footer">
                              <div className="form-check me-2">
                                <input class="form-check-input" id="checkBox1" type="checkbox" value="" />
                                <label class="form-check-label" for="checkBox1">Check Now</label>
                              </div>
                              <div className="form-check me-2">
                                <input class="form-check-input" id="update" type="checkbox" value="" />
                                <label class="form-check-label" for="update">Update Keywords</label>
                              </div>
                              <div className="form-check me-auto">
                                <input class="form-check-input" id="volume" type="checkbox" value="" />
                                <label class="form-check-label" for="volume">Get Volume</label>
                              </div>
                              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" onClick={e => setImportStat('')}>Close</button>
                              <button type="button" className={`btn btn-primary ${(importStat === true || kwList.length === 0) && 'disabled'}`} id='import-button' onClick={importDB}>Import</button>
                          </div>
                        </div>
                    </div>
                  </div>
            {/* Modal Import*/}
            <div className='my-5'>
              <div className="d-flex mb-2">
              <div class="dropdown me-3">
                  <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                      <FontAwesomeIcon icon={faGlobe} />
                      <span className='ms-2'>{selectedDomain}</span>
                  </button>
                  <ul class="dropdown-menu" style={{'z-index':'99'}} aria-labelledby="dropdownMenuButton1">
                      {domain.map((item, index) => {return (
                          <li key={index}><a class="dropdown-item" href="#" onClick={e => {e.preventDefault(); console.log(e.target.outerText);setSelectedDomain(item)}}>{item}</a></li>
                        )}
                        )}
                  </ul>
              </div>
              <div class="dropdown me-3">
                    <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                        <FontAwesomeIcon icon={faFilter} />
                        <span className='ms-2'>Filter by</span>
                    </button>
                    <ul class="dropdown-menu" style={{'z-index':'99'}} aria-labelledby="dropdownMenuButton1">
                        <li onClick={e => filterHandler(e, 'Country')}><a class="dropdown-item" href="#">Country</a></li>
                        <li onClick={e => filterHandler(e, 'Category')}><a class="dropdown-item" href="#">Category</a></li>
                        <li onClick={e => filterHandler(e, 'Keyword')}><a class="dropdown-item" href="#">Keyword</a></li>
                        <li onClick={e => filterHandler(e, 'URL')}><a class="dropdown-item" href="#">URL</a></li>
                    </ul>
                </div>
                <div className={`dropdown me-3 ${selectKwList.length === 0 && 'd-none'}`}>
                    <button class="btn btn-secondary" type="button" data-bs-toggle="modal" data-bs-target="#ModalMultiSelect" aria-expanded="false" onClick={e => setImportStat('')}>
                        <span className='ms-2'>Bulk Edit</span>
                    </button>
                </div>
                {/* Modal Update Multiple Items */}
                <div class="modal fade" id="ModalMultiSelect" tabindex="-1" role="dialog" aria-labelledby="ModalCenterTitle" aria-hidden="true">
                      <div class="modal-dialog modal-dialog-centered" role="document">
                          <div class="modal-content">
                          <div class="modal-header">
                              <h5 class="modal-title" id="ModalLongTitle">Keyword detail</h5>
                              <div className={`${[false,'',null].includes(importStat) ? 'd-none' : ''}`} id="success-noti">
                                <FontAwesomeIcon icon={faCheckCircle} style={{'color':'green'}}/>
                                <span className='ms-1'>Success</span>
                              </div>
                              <div className={`${[true,'',null].includes(importStat) ? 'd-none' : ''}`} id="failed-noti">
                                <FontAwesomeIcon icon={faTimesCircle} style={{'color':'red'}}/>
                                <span className='ms-1'>Failed. Pls Retry!</span>
                              </div>
                          </div>
                          <div class="modal-body" style={{'max-height':'500px', 'overflow':'auto'}}>
                            <div class="d-flex flex-column mb-4">
                              <div className="d-flex mb-3">
                                <div class="input-group me-3">
                                  <span class="input-group-text">Frequency</span>
                                  <select class="form-select" id="multiFreq" ref={multiFreq} onChange={e => {e.target.value === 'Custom' ? document.getElementById('custom-freq').style.display = 'flex' : document.getElementById('custom-freq').style.display = 'none'}}>
                                    <option value="Daily">Daily</option>
                                    <option value="Weekly">Weekly</option>
                                    <option value="Custom">Custom</option>
                                  </select>
                                </div>
                                  <div class="input-group" id="custom-freq" style={{'display': 'none'}}>
                                    <span class="input-group-text">Each per</span>
                                    <input type="text" class="form-control" placeholder="Days" aria-label="Days" aria-describedby="basic-addon1" ref={multiDays}/>
                                    <span class="input-group-text">days</span>
                                </div>
                              </div>
                              <div class="input-group mb-3">
                                <span class="input-group-text">Category</span>
                                <input type="text" class="form-control" placeholder="Category" aria-label="Category" aria-describedby="basic-addon1" ref={multiCat}/>
                              </div>
                              <div class="input-group mb-3">
                                <span class="input-group-text">URL</span>
                                <input type="text" class="form-control" placeholder="URL" aria-label="URL" aria-describedby="basic-addon1" ref={multiURL}/>
                            </div>
                            </div>
                          </div>
                          <div class="modal-footer">
                              <button type="button" className={`btn btn-danger me-auto ${importStat === true && 'disabled'}`} onClick={deleteKwHandler}>Delete</button>
                              <button type="button" class="btn btn-light" data-bs-dismiss="modal">Cancel</button>
                              <button type="button" className={`btn btn-primary ${importStat === true && 'disabled'}`} onClick={updateKwHandler}>Update</button>
                          </div>
                          </div>
                      </div>
                  </div>
                  {/* Modal Update Multiple Items */}
                <div className={`d-flex ${filter.length === 0 && 'd-none'}`}>
                    {/* <button class="btn btn-sm btn-close btn-outline-danger p-2 align-self-center me-2" type="button" aria-expanded="false" onClick={clearFilterHandler}/> */}
                    <div class={`input-group flex-nowrap me-2 ${filter.includes('Category') ===false && 'd-none'}`}>
                        <button class="btn btn-sm btn-outline-danger p-2 align-self-center" id="button-addon1" type="button" onClick={e => clearFilterHandler(e, 'Category')}>X</button>
                        <button class="btn btn-outline-secondary dropdown-toggle" type="button" id="cat-search-mode" data-bs-toggle="dropdown" aria-expanded="false">M</button>
                        <ul class="dropdown-menu">
                          <li><a class="dropdown-item" href="#" onClick={e => {e.preventDefault(); document.getElementById("cat-search-mode").innerHTML = "E"}}>E</a></li>
                          <li><a class="dropdown-item" href="#" onClick={e => {e.preventDefault(); document.getElementById("cat-search-mode").innerHTML = 'B'}}>B</a></li>
                        </ul>
                        <input type="text" class="form-control" placeholder="Category Search..." aria-label="Category Search..." aria-describedby="addon-wrapping" ref={categorySearch}/>
                    </div>
                    <div class={`input-group flex-nowrap me-2 ${filter.includes('Keyword') ===false && 'd-none'}`}>
                        <button class="btn btn-sm btn-outline-danger p-2 align-self-center" id="button-addon1" type="button" onClick={e => clearFilterHandler(e, 'Keyword')}>X</button>
                        <button class="btn btn-outline-secondary dropdown-toggle" type="button" id="kw-search-mode" data-bs-toggle="dropdown" aria-expanded="false">M</button>
                        <ul class="dropdown-menu">
                          <li><a class="dropdown-item" href="#" onClick={e => {e.preventDefault(); document.getElementById("kw-search-mode").innerHTML = "E"}}>E</a></li>
                          <li><a class="dropdown-item" href="#" onClick={e => {e.preventDefault(); document.getElementById("kw-search-mode").innerHTML = 'B'}}>B</a></li>
                        </ul>
                        <input type="text" class="form-control" placeholder="Keyword Search..." aria-label="Keyword Search..." aria-describedby="addon-wrapping" ref={keySearch}/>
                    </div>
                    <div class={`input-group flex-nowrap me-2 w-auto ${filter.includes('Country') ===false && 'd-none'}`}>
                        <button class="btn btn-sm btn-outline-danger p-2 align-self-center" id="button-addon1" type="button" onClick={e => clearFilterHandler(e, 'Country')}>X</button>
                        <button class="btn btn-light dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                            <span className='ms-2'>{`${selectedCountry === '' ? 'Country' : selectedCountry}`}</span>
                        </button>
                        <ul class="dropdown-menu" style={{'z-index':'99'}} aria-labelledby="dropdownMenuButton1">
                          {country.map((item, index) => {return (
                            <li key={index}><a class="dropdown-item" href="#" onClick={e => {e.preventDefault(); console.log(e.target.outerText);setSelectedCountry(item)}}>{item}</a></li>
                          )}
                          )}
                        </ul>
                    </div>
                    <div class={`input-group flex-nowrap ${filter.includes('URL')===false && 'd-none'}`}>
                        <button class="btn btn-sm btn-outline-danger p-2 align-self-center" id="button-addon1" type="button" onClick={e => clearFilterHandler(e, 'URL')}>X</button>
                        <button class="btn btn-outline-secondary dropdown-toggle" type="button" id="url-search-mode" data-bs-toggle="dropdown" aria-expanded="false">M</button>
                        <ul class="dropdown-menu">
                          <li><a class="dropdown-item" href="#" onClick={e => {e.preventDefault(); document.getElementById("url-search-mode").innerHTML = "E"}}>E</a></li>
                          <li><a class="dropdown-item" href="#" onClick={e => {e.preventDefault(); document.getElementById("url-search-mode").innerHTML = 'B'}}>B</a></li>
                        </ul>
                        <input type="text" class="form-control" placeholder="URL Search..." aria-label="URL Search..." aria-describedby="addon-wrapping" ref={urlSearch}/>
                    </div>
                    <button class="btn btn-primary ms-2" type="button" aria-expanded="false"
                    onClick={filterModeHandler}
                    >
                      Apply
                    </button>
                </div>
                <button class="btn btn-primary ms-auto" type="button" aria-expanded="false" onClick={event => downloadRankReportHandler(filteredRank)}>
                    <FontAwesomeIcon icon={faDownload} className="pe-2"/>
                    Download
                </button>
              </div>
              <div className="overflow-auto mb-3" style={{"height":"500px"}}>
                  <table class="table table-striped" style={{'table-layout':'fixed', 'word-wrap': 'break-word'}}>
                      <thead className="table-primary sticky-top" style={{'z-index':'9'}}>
                          <tr>
                          <th scope="col" style={{'width':'5%'}}>
                            <div>
                              <input class="form-check-input" type="checkbox" id='master-checkbox' aria-label="..." onChange={e => {changeSelectedKwHandler(e)}}/>
                            </div>
                          </th>
                            <th scope="col" style={{'width':'5%'}}>#</th>
                            <th scope="col" style={{'width':'20%'}}>
                              <button class="btn" type="button" aria-expanded="false" onClick={event => sortHandler('kw')}>
                                  <span className='me-2 fw-bold'>Keyword </span>
                                  <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'keyword_inc' ? '#000' : '#6c757d'}`}}/>
                                  <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'keyword_dec' ? '#000' : '#6c757d'}`}}/>
                              </button>
                            </th>
                            <th scope="col" style={{'width':'15%'}}>Category</th>
                            <th scope="col" style={{'width':'10%'}} >Domain</th>
                            <th scope="col" style={{'width':'10%'}}>Country</th>
                            <th scope="col" style={{'width':'5%'}}>Freq</th>
                            <th scope="col" style={{'width':'20%'}}>URL</th>
                            <th scope="col" style={{'width':'5%'}} >Action</th>
                          </tr>
                      </thead>
                      <tbody>
                          {useMemo(() =>
                            filteredRank.slice(showResult*(selectedPage-1),showResult*selectedPage).map((item, index) =>{
                              return (
                                  <tr key={index}>
                                    <th><div>
                                          <input class="form-check-input row-checkbox" type="checkbox" value="" aria-label="..." 
                                          onChange={e => {changeSelectedKwHandler(e, item)}}
                                          />
                                        </div>
                                    </th>
                                    <th scope="row">{(index+1)+(selectedPage-1)*showResult}</th>
                                    <td>{item.Keyword}</td>
                                    <td>{item.Category}</td>
                                    <td>{item.Domain}</td>
                                    <td>{item.Country}</td>
                                    <td>{item.Freq}</td>
                                    <td>{item.URL}</td>
                                    <td>
                                        <button class="btn btn-light" type="button" aria-expanded="false" data-bs-toggle="modal" data-bs-target="#ModalCenter" onClick={e => {
                                          e.preventDefault();
                                          setImportStat('');
                                          // setSelectKwList([]);
                                          // document.getElementById('master-checkbox').checked = false;
                                          // const d = document.getElementsByClassName('row-checkbox');
                                          // for ( let i = 0; i < d.length; i++) d[i].checked = false;
                                          setMKey(item.Keyword);
                                          setMDomain(item.Domain);
                                          setMCountry(item.Country);
                                          setMCategory(item.Category);
                                          setMURL(item.URL);
                                          if (['Daily', 'Weekly'].includes(item.Freq)) {
                                            setMFreq(item.Freq);
                                          } else {
                                            setMFreq('Custom');
                                            setCFreq(item.Freq);
                                          };
                                        }}>
                                          <FontAwesomeIcon icon={faPencil} />
                                        </button>
                                    </td>
                                  </tr>
                              )
                          }),[filteredRank, sort, selectedPage, showResult, selectKwList])
                          }
                      </tbody>
                  </table>
                  {/* Modal Popup*/}
                  <div class="modal fade" id="ModalCenter" tabindex="-1" role="dialog" aria-labelledby="ModalCenterTitle" aria-hidden="true">
                      <div class="modal-dialog modal-dialog-centered" role="document">
                          <div class="modal-content">
                          <div class="modal-header">
                              <h5 class="modal-title" id="ModalLongTitle">Keyword detail</h5>
                              <div className={`${[false,'',null].includes(importStat) ? 'd-none' : ''}`} id="success-noti">
                                <FontAwesomeIcon icon={faCheckCircle} style={{'color':'green'}}/>
                                <span className='ms-1'>Success</span>
                              </div>
                              <div className={`${[true,'',null].includes(importStat) ? 'd-none' : ''}`} id="failed-noti">
                                <FontAwesomeIcon icon={faTimesCircle} style={{'color':'red'}}/>
                                <span className='ms-1'>Failed. Pls Retry!</span>
                              </div>
                          </div>
                          <div class="modal-body" style={{'max-height':'500px', 'overflow':'auto'}}>
                            <div class="d-flex flex-column mb-4">
                              <div class="input-group mb-3">
                                <span class="input-group-text" id="domain">*Domain</span>
                                <input type="text" class="form-control" placeholder="Domain" aria-label="Domain" aria-describedby="basic-addon1" value={mDomain} />
                              </div>
                              <div class="input-group mb-3">
                                <span class="input-group-text">*Country</span>
                                <div class="dropdown">
                                  {/* <input type="text" className="dropdown-toggle form-control" id="country-search" data-bs-toggle="dropdown" placeholder="Search..." aria-label="Search..." aria-describedby="basic-addon1" ref={mCountry}/> */}
                                  <select class="form-select" id="country" value={mCountry}>
                                    <option value="US">US</option>
                                    <option value="Singapore">Singapore</option>
                                    <option value="Vietnam">Vietnam</option>
                                  </select>
                                </div>
                              </div>
                              <div class="input-group mb-3">
                                <span class="input-group-text">Keyword</span>
                                <input type="text" class="form-control" placeholder="Keyword" aria-label="Keyword" aria-describedby="basic-addon1" value={mKey} />
                              </div>
                              <div className="d-flex mb-3">
                                <div class="input-group me-3">
                                  <span class="input-group-text">Frequency</span>
                                  <select class="form-select" id="freq" value={mFreq} onChange={e => {setMFreq(e.target.value)}}>
                                    <option value="Daily">Daily</option>
                                    <option value="Weekly">Weekly</option>
                                    <option value="Custom">Custom</option>
                                  </select>
                                </div>
                                {
                                  mFreq !== '' && mFreq !== 'Daily' && mFreq !== 'Weekly' &&
                                  <div class="input-group">
                                    <span class="input-group-text">Each per</span>
                                    <input type="text" class="form-control" placeholder="Days" aria-label="Days" aria-describedby="basic-addon1" value={cFreq} onChange={e => {setCFreq(e.target.value)}}/>
                                    <span class="input-group-text">days</span>
                                </div>
                                }
                              </div>
                              <div class="input-group mb-3">
                                <span class="input-group-text">Category</span>
                                <input type="text" class="form-control" placeholder="Category" aria-label="Category" aria-describedby="basic-addon1" value={mCategory} onChange={e => {setMCategory(e.target.value)}}/>
                              </div>
                              <div class="input-group mb-3">
                                <span class="input-group-text">URL</span>
                                <input type="text" class="form-control" placeholder="URL" aria-label="URL" aria-describedby="basic-addon1" value={mURL} onChange={e => {setMURL(e.target.value)}}/>
                            </div>
                            
                            </div>
                          </div>
                          <div class="modal-footer">
                              <button type="button" className={`btn btn-danger me-auto ${importStat === true && 'disabled'}`} onClick={e => deleteKwHandler(mKey, mDomain, mCountry, mURL, mCategory)}>Delete</button>
                              <button type="button" class="btn btn-light" data-bs-dismiss="modal">Cancel</button>
                              <button type="button" className={`btn btn-primary ${importStat === true && 'disabled'}`} onClick={updateKwHandler}>Update</button>
                          </div>
                          </div>
                      </div>
                  </div>
                  {/* Modal Popup */}
                  <h4 className={`text-muted text-center ${rankDetail.length > 0 && 'd-none'}`}>No Records founded in selected period!</h4>
              </div>
              <div className="d-flex">
                <div class="input-group mb-3 me-3 ms-auto w-25">
                  <label class="input-group-text" for="inputGroupSelect01">Results per page</label>
                  <select class="form-select" id="inputGroupSelect01" onChange={e => changeShowResultHandler(e.target.value)}>
                    <option selected value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="500">500</option>
                  </select>
                </div>
                <nav aria-label="Page navigation example">
                  <ul class="pagination">
                    <li class={`page-item ${currentPageRange === 1 && 'disabled'}`}
                    onClick = {e => {
                      e.preventDefault();
                      setCurrentPageRange(current => {
                        if(current > 1){
                          return current -1
                        }
                        return current;
                        })}}
                    ><a class="page-link" href="#">Previous</a></li>
                    {pagination.map((item, index) => {return(
                      <li  key={index+1} id={`page-${index+1}`} className={`page-item ${index+1 === selectedPage && 'active'} ${(index+1 > 10*currentPageRange || index + 1 <= 10*(currentPageRange-1)) && 'd-none'}`}>
                        {item}
                      </li>
                      )})
                    }
                    <li
                    class={`page-item ${(pagination.length <= 10 || currentPageRange + 1 > Math.ceil(pagination.length/10)) && 'disabled'}`}
                    onClick = {e => {
                      e.preventDefault();
                      setCurrentPageRange(current => {
                        if(current + 1 <= Math.ceil(pagination.length/10)) {
                          return (current +1);
                        }
                        return current;
                      })}
                      }
                    ><a class="page-link" href="#">Next</a></li>
                  </ul>
                </nav>
                
              </div>
            </div>
        </div>
    )
};

export default ManageRankTracking;