import { Form, Link, NavLink, useRouteLoaderData } from 'react-router-dom';

const Menu = () => {
    const token = useRouteLoaderData('root');
    const username = localStorage.getItem('username');

    return (
    <header class="navbar navbar-expand-lg navbar-light bg-light shadow-sm" style={{height: '50px'}}>
        <div class="container-fluid">
            <div class="collapse navbar-collapse justify-content-end" id="navbarNav">
                <ul class="navbar-nav me-4">
                    {token &&
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <img src="gleads-logo.png" class="rounded-circle img-fluid" width="20" height="20" alt="..." />
                                <span className='ms-2'>{username}</span>
                            </a>
                        <ul className="dropdown-menu" style={{left: '-20px'}}>
                            <li ><NavLink className="dropdown-item" to="/user-profile">Profile</NavLink></li>
                            <li ><NavLink className="dropdown-item" to="/manage-tasks">Pending Tasks</NavLink></li>
                            <li >
                                <Form action='/logout' method="post">
                                    <button className="btn dropdown-item"><a>Log out</a></button>
                                </Form>
                            </li>
                        </ul>
                    </li>
                    }
                </ul>
            </div>
        </div>
    </header>
    )
};

export default Menu;