import { useState, useRef, Fragment, useEffect, useMemo } from 'react';

import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap/dist/js/bootstrap.min.js";
import * as bootstrap from 'bootstrap/dist/js/bootstrap';
import { OverlayTrigger, Popover } from 'react-bootstrap';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash, faDownload, faRefresh, faUpRightFromSquare, faTimesCircle, faLineChart, faEye, faCheck, faArrowUp, faArrowDown, faCircle, faGlobe, faSquareCheck, faLanguage, faCheckCircle, faCirclePlus, faSearch
} from "@fortawesome/free-solid-svg-icons";
import { faWordpress } from '@fortawesome/free-brands-svg-icons';

import {showLoading, hideLoading } from 'react-redux-loading-bar';
import Papa from 'papaparse';
import { v4 as uuid4 } from 'uuid';
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { getAuthToken } from '../util/token';
import RadarChart from '../components/radar_chart';
import { Editor } from "@tinymce/tinymce-react";


// const data = [
//     {
//     "criteria": "Descriptive language and imagery",
//     "grade": 4,
//     "explanation": "The text uses vivid imagery, such as 'warm golden glow' and 'gentle breeze rustling through the leaves,' to create a clear and evocative picture of the scene."
//     },
//     {
//     "criteria": "Sentence structure and variety",
//     "grade": 3,
//     "explanation": "The text uses a mix of sentence lengths and structures, but there could be more variety in sentence beginnings and types."
//     },
//     {
//     "criteria": "Emotional impact and engagement",
//     "grade": 4,
//     "explanation": "The text effectively conveys a sense of peace and serenity, using phrases like 'filled the heart with joy and contentment' to connect with the reader's emotions."
//     },
//     {
//     "criteria": "Grammar and punctuation",
//     "grade": 5,
//     "explanation": "The text appears to be free of grammatical and punctuation errors."
//     },
//     {
//     "criteria": "Clever wordplay",
//     "grade": 2,
//     "explanation": "The text does not utilize any particularly clever or unique wordplay."
//     },
//     {
//     "criteria": "A sense of professionalism combined with the feeling of friendliness and dedication",
//     "grade": 3,
//     "explanation": "While the text is well-written and grammatically correct, it does not necessarily convey a strong sense of professionalism or dedication. This criterion may be more relevant for evaluating business or academic writing."
//     }
// ]
const terms = [
    {term: 'thành lập công ty', lower:3, upper:5, current: 2, type: 'paragraph'},
    {term: 'thành lập công ty singapore', lower:1, upper:2, current: 3, type: 'heading'},
    {term: 'thành lập', lower:2, upper:4, current: 3, type: 'paragraph'},
    {term: 'thành lập việt nam', lower:0, upper:1, current: 1, type: 'heading'},
    {term: 'thành lập công ty hong kong', lower:3, upper:5, current: 0, type: 'paragraph'},
];
const result = {
    "term": [
        {
            "term": "ACRA",
            "lower": 0,
            "upper": 15,
            "type": [
                "paragraph"
            ]
        },
        {
            "term": "Annual General Meeting",
            "lower": 0,
            "upper": 1,
            "type": [
                "heading",
                "paragraph"
            ]
        },
        {
            "term": "Annual Return",
            "lower": 0,
            "upper": 1,
            "type": [
                "heading",
                "paragraph"
            ]
        },
        {
            "term": "Blockchain",
            "lower": 0,
            "upper": 6,
            "type": [
                "paragraph"
            ]
        },
        {
            "term": "Crypto",
            "lower": 0,
            "upper": 9,
            "type": [
                "paragraph"
            ]
        },
        {
            "term": "Crypto Wallet",
            "lower": 0,
            "upper": 2,
            "type": [
                "paragraph"
            ]
        },
        {
            "term": "E-commerce",
            "lower": 0,
            "upper": 5,
            "type": [
                "paragraph"
            ]
        },
        {
            "term": "ECI",
            "lower": 0,
            "upper": 3,
            "type": [
                "heading",
                "paragraph"
            ]
        },
        {
            "term": "Employment Pass",
            "lower": 0,
            "upper": 8,
            "type": [
                "paragraph"
            ]
        },
        {
            "term": "EntrePass",
            "lower": 0,
            "upper": 3,
            "type": [
                "paragraph"
            ]
        },
        {
            "term": "E-wallet",
            "lower": 0,
            "upper": 1,
            "type": [
                "paragraph"
            ]
        },
        {
            "term": "Fintech",
            "lower": 0,
            "upper": 6,
            "type": [
                "paragraph"
            ]
        },
        {
            "term": "Fintechbank",
            "lower": 0,
            "upper": 1,
            "type": [
                "paragraph"
            ]
        },
        {
            "term": "Global Links Asia",
            "lower": 0,
            "upper": 17,
            "type": [
                "heading",
                "paragraph"
            ]
        },
        {
            "term": "GST",
            "lower": 0,
            "upper": 8,
            "type": [
                "heading",
                "paragraph"
            ]
        },
        {
            "term": "IRAS",
            "lower": 0,
            "upper": 4,
            "type": [
                "paragraph"
            ]
        },
        {
            "term": "NFT",
            "lower": 0,
            "upper": 7,
            "type": [
                "paragraph"
            ]
        },
        {
            "term": "Paypal",
            "lower": 0,
            "upper": 7,
            "type": [
                "paragraph"
            ]
        },
        {
            "term": "Paypal Business",
            "lower": 0,
            "upper": 3,
            "type": [
                "paragraph"
            ]
        },
        {
            "term": "Paypal Business Pro",
            "lower": 0,
            "upper": 2,
            "type": [
                "paragraph"
            ]
        },
        {
            "term": "Permanent Resident",
            "lower": 0,
            "upper": 3,
            "type": [
                "paragraph"
            ]
        },
        {
            "term": "Quickbooks",
            "lower": 0,
            "upper": 3,
            "type": [
                "paragraph"
            ]
        },
        {
            "term": "Singapore",
            "lower": 0,
            "upper": 189,
            "type": [
                "heading",
                "paragraph"
            ]
        },
        {
            "term": "Singapore Standard Industrial Classification",
            "lower": 0,
            "upper": 1,
            "type": [
                "paragraph"
            ]
        },
        {
            "term": "Stripe",
            "lower": 0,
            "upper": 7,
            "type": [
                "paragraph"
            ]
        },
        {
            "term": "Tax",
            "lower": 0,
            "upper": 2,
            "type": [
                "heading",
                "paragraph"
            ]
        },
        {
            "term": "UOB",
            "lower": 0,
            "upper": 3,
            "type": [
                "paragraph"
            ]
        },
        {
            "term": "Work Permit",
            "lower": 0,
            "upper": 2,
            "type": [
                "paragraph"
            ]
        }
    ],
    "structure": [
        {
            "type": "words",
            "min": 12,
            "max": 7233
        },
        {
            "type": "headings",
            "min": 1,
            "max": 63
        },
        {
            "type": "paragraphs",
            "min": 9,
            "max": 132
        },
        {
            "type": "images",
            "min": 1,
            "max": 140
        }
    ]
}
  
const ContentAudit = () => {
    
    // get auth token
    const token = getAuthToken();
    const userName = localStorage.getItem('username');
    const dispatch = useDispatch();

    // tinymce editor
    const editorRef = useRef(null);
    const [initialValue, setInitialValue] = useState('');

    const [data, setData] = useState([]);
    const [tab, setTab] = useState('guideline');
    const [filteredTerms, setFilteredTerms] = useState([]);
    const [termType, setTermType] = useState('all');
    const searchInput = useRef('');
    const [terms, setTerms] = useState([]);
    const [selectedTerms, setSelectedTerms] = useState([]);
    const mainKeyword = useRef('');
    const [location, setLocation] = useState('Location');
    const [structure, setStructure] = useState([]);
    const url = useRef('');

    // pixabay
    const [imageMode, setImageMode] = useState('pixabay');
    const fileUpload = useRef(null);
    const pixaBayClose = useRef(null);
    const [images, setImages] = useState([]);
    const [importStat, setImportStat] = useState('');

    //save wordpress
    const wTitle = useRef('');
    const [wCategory, setWCategory] = useState([]);
    const wordpressModal = useRef(null);
    const [totalWPCategory, setTotalWPCategory] = useState([]);
    const [wpSite, setWPSite] = useState('');
    const [brokenMessage, setBrokenMessage] = useState('');

    // save portal
    const topic = useRef('');
    const domain = useRef('');
    const description = useRef('');
    const category = useRef('');
    const [taskId, setTaskId] = useState('');
    const [autoSave, setAutoSave] = useState('');

    const [searchParams, setSearchParams] = useSearchParams();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (searchParams.get('id')){
            const input = {
              id: searchParams.get('id')
            };
           fetch(`${process.env.REACT_APP_HOST_IP}/api/manage-tasks`,{
                method: 'POST',
                body: JSON.stringify(input),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                    'X-User': userName
                  }
            }).then(
                response => {
                  if (response.status === 404){
                    return null
                  }
                  return response.json();
                }
            ).then (
                data =>{
                  if (data === null){
                    return
                  };
                  // console.log(data);
                  setInitialValue(data[0]['outline']);
                  topic.current.value = data[0]['topic'];
                  domain.current.value = data[0]['domain'];
                  description.current.value = data[0]['description'];
                  category.current.value = data[0]['category'] || '';
          })
          };
    //     fetch(`${process.env.REACT_APP_HOST_IP}/api/wordpress-category`,{
    //     method: 'POST',
    //     body: JSON.stringify({
    //       site: 'hk'
    //     }),
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Authorization': 'Bearer ' + token
    //       }
    //   }).then(
    //       response => {
    //         if (response.status === 500){
    //           return response.message
    //         }
    //         return response.json();
    //       }
    //   ).then (
    //       data =>{
    //         console.log(data);
    //         setWCategory(data);
    //       }) 
    },[]);

    const updateMetricHandler = () => {
        let content = editorRef.current.getContent({format: 'text'});
        if (content !== '') {
            let t = terms.map(item => {
                let pattern = new RegExp(item['term'], 'i');
                let occurence = content.match(pattern) || 0;
                if (occurence !== 0) occurence = occurence.length
                return {
                    ...item,
                    current: occurence
                }
            });
            console.log(t);
            setTerms(t);
            let pattern = new RegExp(searchInput.current.value, 'i');
            if (termType === 'heading'){
                let d = t.filter(item => pattern.test(item.term) && item.type.includes(termType));
                setFilteredTerms(d);
            } else {
                let d = t.filter(item => pattern.test(item.term));
                setFilteredTerms(d);
            };
            // paragraph count
            let para_count = content.split(/\n+/).length;
            let htmlContent = editorRef.current.getContent({format: 'html'})
            // image count
            let image_count = (htmlContent.match(/<img/g) || []).length
            // heading count
            let heading_count = (htmlContent.match(/<h\d/g) || []).length
            // word count
            let words = content.split(/\s+/);
            words = words.filter(word => word.trim().length > 0);
            let word_count = words.length;
            let d = structure.map((item, idx) => {
                if (item.type === 'words'){
                    return {
                        ...item,
                        current: word_count
                    }
                } else if (item.type === 'headings'){
                    return {
                        ...item,
                        current: heading_count
                    }
                }else if (item.type === 'paragraphs'){
                    return {
                        ...item,
                        current: para_count
                    }
                }else if (item.type === 'images'){
                    return {
                        ...item,
                        current: image_count
                    }
                }   
            });
            setStructure(d);
        }
    }



    const gradeContentHandler = () => {
        let content = editorRef.current.getContent({format:"text"});
        if (content === '') return
        setIsLoading (c => !c);
        const input = {
            content: content
        };
        console.log(input);
        dispatch(showLoading());
        fetch(`${process.env.REACT_APP_HOST_IP}/api/content-grader`,{
          method: 'POST',
          body: JSON.stringify(input),
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + token,
              'X-User': userName
            }
        }).then(
            response => {
                dispatch(hideLoading());
              return response.json();
            }
        ).then (
            data =>{
                console.log(data);
                setData(JSON.parse(data));
                setIsLoading (c => !c);
        })
    };

    const searchTermsHandler = (term) => {
        let pattern = new RegExp(term, 'i');
        if (termType === 'heading'){
            let d = terms.filter(item => pattern.test(item.term) && item.type.includes(termType));
            setFilteredTerms(d);
        } else {
            let d = terms.filter(item => pattern.test(item.term));
            setFilteredTerms(d);
        };
    };

    const changeTypeHandler = (type) => {
        let pattern = new RegExp(searchInput.current.value, 'i');
        if (type === 'heading'){
            let d = terms.filter(item => pattern.test(item.term) && item.type.includes(type));
            setFilteredTerms(d);
        } else {
            let d = terms.filter(item => pattern.test(item.term));
            setFilteredTerms(d);
        }
        setTermType(type);
    };

    const countTermHandler = (type) => {
        let count = 0;
        let pattern = new RegExp(searchInput.current.value, 'i');
        if (type === 'heading'){
            terms.map(item => {
                if (item.type.includes(type) && pattern.test(item.term)){
                    count += 1;
                }
            })
            return count
        } else {
            terms.map(item => {
                if (pattern.test(item.term)){
                    count += 1;
                }
            })
            return count
        }
    };

    // Function to decode HTML entities
    function decodeHTMLEntities(text) {
        var textArea = document.createElement('textarea');
        textArea.innerHTML = text;
        return textArea.value;
      }

    const highlightTextHandler = (item) => {
        let htmlStr = decodeHTMLEntities(editorRef.current.getContent({format:'html'}));
        let c = 'rgb(254, 235, 200)'; // orange
        let b = '#fbd38d';
        if (item.current >= item.lower && item.current <= item.upper){
            c = 'rgb(198,246,213)' // green
            b = '#9ae6b4';
        } else if (item.current === 0){
            c = 'rgb(255, 245, 245)' // red
            b = '#feb2b2';
        };
        let replaceStr = `<span style="background-color: ${c}; border-bottom: 2px solid ${b};">${item.term}</span>`;
        // console.log('replaceStr: ', replaceStr);
        if (selectedTerms.includes(item.term)){
            htmlStr = htmlStr.replace(replaceStr, item.term);
            setSelectedTerms(c => c.filter(t => t !== item.term))
        }else{
            htmlStr = htmlStr.replace(item.term, replaceStr);
            setSelectedTerms(c => [...c, item.term])
        };
        // console.log(htmlStr);
        editorRef.current.setContent('');
        editorRef.current.execCommand('insertHTML', false, htmlStr);
    };

    const analyzeHandler = () => {
        if (editorRef.current.getContent({format:'text'}) === '' || mainKeyword.current.value === '' || location === 'Location') return
        setIsLoading (c => !c);
        const input = {
            keyword: mainKeyword.current.value,
            location: location
        }
        console.log(input);
        dispatch(showLoading());
        fetch(`${process.env.REACT_APP_HOST_IP}/api/content-guide`,{
          method: 'POST',
          body: JSON.stringify(input),
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + token,
              'X-User': userName
            }
        }).then(
            response => {
                dispatch(hideLoading());
              return response.json();
            }
        ).then (
            data =>{
                console.log(data);
                let content = editorRef.current.getContent({format: 'text'});
                let t = data['term'].map(item => {
                    let pattern = new RegExp(item['term'], 'i');
                    let occurence = content.match(pattern) || 0;
                    if (occurence !== 0) occurence = occurence.length
                    return {
                        ...item,
                        current: occurence
                    }
                });
                console.log(t);
                setTerms(t);
                setFilteredTerms(t);
                // paragraph count
                let para_count = content.split(/\n+/).length;
                let htmlContent = editorRef.current.getContent({format: 'html'})
                // image count
                let image_count = (htmlContent.match(/<img/g) || []).length
                // heading count
                let heading_count = (htmlContent.match(/<h\d/g) || []).length
                // word count
                let words = content.split(/\s+/);
                words = words.filter(word => word.trim().length > 0);
                let word_count = words.length;
                let d = data['structure'].map((item, idx) => {
                    if (item.type === 'words'){
                        return {
                            ...item,
                            current: word_count
                        }
                    } else if (item.type === 'headings'){
                        return {
                            ...item,
                            current: heading_count
                        }
                    }else if (item.type === 'paragraphs'){
                        return {
                            ...item,
                            current: para_count
                        }
                    }else if (item.type === 'images'){
                        return {
                            ...item,
                            current: image_count
                        }
                    }   
                });
                setStructure(d);
                setIsLoading (c => !c);
            })
    };

    const loadContentHandler = () => {
        if (url.current.value === '') return
        dispatch(showLoading());
        fetch(`${process.env.REACT_APP_HOST_IP}/api/render-page`,{
          method: 'POST',
          body: JSON.stringify({
            url: url.current.value
          }),
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + token,
              'X-User': userName
            }
        }).then(
            response => {
                dispatch(hideLoading());
              return response.json();
            }
        ).then (
            data =>{
                console.log(data);
                let content = '';
                data['content'].map(item => {
                    content += `<${item.content_type}>${item.content_body}</${item.content_type}>`
                });
                editorRef.current.execCommand('insertHTML', false, content);
        })
    };
    const handleEditorInit = (editor) => {
        // Add the new menu item to the toolbar
        editor.ui.registry.addToggleButton('custommenu', {
              text: 'Pixabay',
              icon: 'gallery',
              onAction: function() {
                const pixa = new bootstrap.Modal(document.getElementById('ModalPixabay'));
                pixa.show();
                // setPixabayModal(true);
              }
        });
    
        editor.ui.registry.addContextMenu('customContextMenu', {
          update: function () {
            return [
              {
                type: 'menuitem',
                text: 'Custom Item 1',
                icon: 'permanent-pen',
                onAction: function () {
                  
                  // Get the selected content
                  const selectedContent = editor.selection.getContent({format:"text"});
            
                  // Log the highlighted content to the console
                  console.log("Highlighted content:", selectedContent);
    
                  editor.execCommand('mceInsertContent', false, 'Menu item 1 content');
                }
              }
            ];
          }
        });
    };
    const chunkArray = (array, size) => {
        return array.reduce((chunks, item, index) => {
          if (index % size === 0) {
            chunks.push([item]);
          } else {
            chunks[chunks.length - 1].push(item);
          }
          return chunks;
        }, []);
    };
    const insertImgHandler = (url) => {
        // Convert the image URL to a Blob
        let file_name = url.split('/').pop();
        console.log(url);
        fetch(url)
          .then(response => response.blob())
          .then(blob => {
            // Create a File object from the Blob
            const file = new File([blob], file_name, { type: blob.type });
            // console.log(file)
            const formData = new FormData();
            formData.append('file', file);
            // console.log(formData)
    
          fetch(`${process.env.REACT_APP_HOST_IP}/api/upload/image`, {
            method: 'POST',
            headers: {
              'Authorization': 'Bearer ' + token,
              'X-User': userName
            },
            body: formData
          })
          .then(response => response.json())
          .then(data => {
            console.log('Image uploaded successfully:', data.imageUrl);
            window.tinymce.activeEditor.execCommand('InsertHTML', false, `<img src="${process.env.REACT_APP_HOST_IP}/api${data.imageUrl}" class="img-fluid" style="width:100%; height: 100%" alt="..." />`);
            // You can do something with the image URL received from the server
          })
          .catch(error => {
            console.error('Error uploading image:', error);
          });
        })
    };
    let timeoutId;
    const handleDelayedChange = (event) => {
    const value = event.target.value;
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
        if (value !== ''){
        fetch(`${process.env.REACT_APP_HOST_IP}/api/pixabay-image`,{
            method: 'POST',
            body: JSON.stringify({
            query: value,
            page: 1
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
                'X-User': userName
            }
        }).then(
            response => {
                if (response.status === 404){
                return null
                }
                return response.json();
            }
        ).then (
            data =>{
                console.log(data);
                setImages(data);
        })
        }
    }, 1000); // Set a new timeout for 1000ms
    };
    const handleDrop = (event) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        const formData = new FormData();
        formData.append('file', file);
    
        fetch(`${process.env.REACT_APP_HOST_IP}/api/upload/image`, {
            method: 'POST',
            headers: {
              'Authorization': 'Bearer ' + token,
              'X-User': userName
            },
            body: formData
          })
          .then(response => response.json())
          .then(data => {
            console.log('Image uploaded successfully:', data.imageUrl);
            window.tinymce.activeEditor.execCommand('InsertHTML', false, `<img src="${process.env.REACT_APP_HOST_IP}/api${data.imageUrl}" class="img-fluid" style="width:100%; height: 100%" alt="..." />`);
            pixaBayClose.current.click();
          })
          .catch(error => {
            console.error('Error uploading image:', error);
          });
      };
      const handleDragOver = (event) => {
        event.preventDefault();
    };
    const fileUploadHandler = () => {
        const input_file = document.getElementById('formFile').files
        console.log(input_file);
        if (input_file.length === 0){
            return
        };
        const file_size = input_file[0].size;
        const file = input_file[0];
        if (file_size < 10*1024*1024){
          const formData = new FormData();
          formData.append('file', file);
          fetch(`${process.env.REACT_APP_HOST_IP}/api/upload/image`, {
            method: 'POST',
            headers: {
              'Authorization': 'Bearer ' + token,
              'X-User': userName
            },
            body: formData
          })
          .then(response => response.json())
          .then(data => {
            console.log('Image uploaded successfully:', data.imageUrl);
            window.tinymce.activeEditor.execCommand('InsertHTML', false, `<img src="${process.env.REACT_APP_HOST_IP}/api${data.imageUrl}" class="img-fluid" style="width:100%; height: 100%" alt="..." />`);
            pixaBayClose.current.click();
          })
          .catch(error => {
            console.error('Error uploading image:', error);
          });
        } else {
          console.log('greater than 10MB');
        }
    };
    const importWordpress = () => {
        let s = wpSite;
        if (wpSite === '') s = 'hk'
        const input = {
          title: wTitle.current.value,
          category: totalWPCategory,
          site: s,
          content: editorRef.current.getContent(),
        };
        // console.log(input);
        dispatch(showLoading());
        fetch(`${process.env.REACT_APP_HOST_IP}/api/save-wordpress`,{
            method: 'POST',
            body: JSON.stringify(input),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
                'X-User': userName
              }
          }).then(
              response => {
                dispatch(hideLoading());
                return response.json();
              }
          ).then (
              data =>{
                if (data.status_code === 500){
                    console.log(data);
                    setImportStat(false);
                    setBrokenMessage(data.detail);
                    return
                } else if (data.status_code === 401) {
                    console.log(data);
                    setImportStat(false);
                    setBrokenMessage(data.detail);
                    return
                } else {
                  console.log(data);
                  setImportStat(true);
                }
                
              }) 
    };
    const openWordpressModal = () => {
        setImportStat('');
        setBrokenMessage('');
        // const m = new bootstrap.Modal(document.getElementById('ModalWordpress'));
        const m = new bootstrap.Modal(wordpressModal.current);
        m.toggle();
    };
    const getSiteCategoryHandler = (s) => {
        if (s === '') return
        setTotalWPCategory([]);
        setWPSite(s);
        const d = document.getElementsByClassName('row-checkbox');
        for ( let i = 0; i < d.length; i++) d[i].checked = false;
        fetch(`${process.env.REACT_APP_HOST_IP}/api/wordpress-category`,{
            method: 'POST',
            body: JSON.stringify({
              site: s
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
                'X-User': userName
              }
          }).then(
              response => {
                if (response.status === 500){
                  return response.message
                }
                return response.json();
              }
          ).then (
              data =>{
                // console.log(data);
                setWCategory(data);
              }) 
    };
    const handleSaveOutline = () => {
        if (topic.current.value === ''){
          saveOutlineHandler();
        }else{
          importOutline('save outside');
        }
    };
    const saveOutlineHandler = () => {
        setImportStat('');
        const m = new bootstrap.Modal(document.getElementById('ModalImport'));
        m.toggle();
    };
    const importOutline = (check) => {
        const input = {
          topic: topic.current.value,
          category: category.current.value,
          domain: domain.current.value,
          description: description.current.value,
          outline: editorRef.current.getContent(),
          date: new Date().getTime(),
          task: 'draft',
          username: localStorage.getItem('username')
        }
        if (searchParams.get('id')){
          input['id'] = searchParams.get('id');
        } else if (taskId !== ''){
          input['id'] = taskId;
        };
        // console.log(input);
        fetch(`${process.env.REACT_APP_HOST_IP}/api/import-outline`,{
            method: 'POST',
            body: JSON.stringify(input),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
                'X-User': userName
              }
        }).then(
            response => {
                return response.json()
            }
        ).then (
            data =>{
              if(data.acknowledged === true){
                setImportStat(true);
                if(check === "save outside") setAutoSave(true);
                if (taskId === ''){
                  setTaskId(data.inserted_id);
                }
              }else{
                setImportStat(false);
              }
              console.log(data);
            }
        );
    };

    return (
        <div className="d-flex flex-column vh-100">
            <h1 className="text-center mt-4 mb-5">Content Audit</h1>
            {/* Modal Pixabay*/}
            <div class={`modal fade`} id="ModalPixabay" tabindex="-1" role="dialog" aria-labelledby="ModalCenterTitle" >
                <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div class="modal-content">
                    <div className="modal-header">
                        <h5 class="modal-title me-auto" id="ModalLongTitle">Insert Image</h5>
                    </div>
                    <div class="modal-body">
                        <ul class="nav nav-tabs">
                        <li class="nav-item">
                            <a class={`nav-link ${imageMode === 'pixabay' && 'active'}`} aria-current="page" href="#" onClick={e => setImageMode('pixabay')}>Pixabay</a>
                        </li>
                        <li class="nav-item">
                            <a class={`nav-link ${imageMode === 'upload' && 'active'}`} href="#" onClick={e => setImageMode('upload')}>Upload</a>
                        </li>
                        </ul>
                        <div class={`input-group my-3 ${imageMode !== 'pixabay' && 'd-none'}`}>
                        <span class="input-group-text bg-white"><FontAwesomeIcon icon={faSearch} /></span>
                        <input type="text" class="form-control" placeholder="Search photos by keyword" 
                            onChange={handleDelayedChange}
                        />
                        </div>
                        <div className={`d-flex flex-column overflow-auto ${imageMode !== 'pixabay' && 'd-none'}`} style={{height: '450px'}}>
                        { Object.keys(images).length > 0 && images['hits'].length > 0 &&
                            chunkArray(images['hits'],4).map((item, idx) => 
                            <div className='row'>
                            {
                                item.map(it => (
                                <div className='col d-flex flex-column' onClick={e => insertImgHandler(it['largeImageURL'])}>
                                    <img src={`${it['previewURL']}`} class="img-thumbnail" alt="..." />
                                    <span>{it['tags']}</span>
                                </div>
                            ))
                            }
                            </div>
                            )
                        }
                        </div>
                        <div className={`d-flex flex-column ${imageMode !== 'upload' && 'd-none'} m-4`} style={{height: '480px'}}
                        onDrop={handleDrop}
                        onDragOver={handleDragOver}
                        >
                            <div className='d-flex flex-column align-items-center justify-content-center bg-light h-100 w-100' style={{border: '2px dashed #dee2e6'}}>
                                <h5>Drag and drop an image here</h5>
                                <h5>--- or ---</h5>
                                <input class="form-control d-none" id="formFile" type="file" placeholder="" ref={fileUpload} onChange={e => {
                                if (e.target.files.length > 0) {
                                    fileUploadHandler();
                                }
                                }}/>
                                <button type="button" class="btn btn-dark" onClick={e => {fileUpload.current.value = null; fileUpload.current.click()}}>Select from your files</button>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" onClick={e => setImportStat('')} ref={pixaBayClose}>Close</button>
                    </div>
                    </div>
                </div>
            </div>
            {/* Modal Pixabay*/}
            {/* Modal Wordpress*/}
            <div class="modal fade" id="ModalWordpress" ref={wordpressModal} tabindex="-1" role="dialog" aria-labelledby="ModalCenterTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                    <div className="modal-header">
                        <h5 class="modal-title me-auto">Save Wordpress</h5>
                        <div className={`${[false,'',null].includes(importStat) ? 'd-none' : ''}`} id="success-noti">
                            <FontAwesomeIcon icon={faCheckCircle} style={{'color':'green'}}/>
                            <span className='ms-1'>Success</span>
                        </div>
                        <div className={`${[true,'',null].includes(importStat) ? 'd-none' : ''}`} id="failed-noti">
                            <FontAwesomeIcon icon={faTimesCircle} style={{'color':'red'}}/>
                            <span className='ms-1' style={{color: 'red', fontSize: '12px'}}>{brokenMessage != '' ? brokenMessage : 'Failed. Pls Retry!'}</span>
                        </div>
                    </div>
                    <div class="modal-body">
                        <div class="d-flex flex-column mb-4">
                        <div class="input-group mb-3">
                            <span class="input-group-text">Title</span>
                            <input type="text" class="form-control" placeholder="Title" aria-label="Title" aria-describedby="basic-addon1" ref={wTitle}/>
                        </div>
                        <div class="input-group mb-3">
                            <span class="input-group-text">Site</span>
                            <select class="form-select" id="site" onChange={e => getSiteCategoryHandler(e.target.value)}>
                                <option selected value=''>Selected a site</option>
                                <option value='gleads'>Gleads</option>
                                <option value='hk'>BBCIncorp HK</option>
                                <option value="sg">BBCIncorp SG</option>
                                <option value="offshore">BBCIncorp Offshore</option>
                                <option value="vn">BBCIncorp VN</option>
                            </select>
                        </div>
                        <div class="dropdown">
                            <button class="btn btn-light dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
                            {`Category (${totalWPCategory.length})`}
                            </button>
                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1" style={{'max-height': '300px', 'overflow': 'auto'}}>
                            {
                                wCategory.map((item, index) => {
                                return (
                                    <li className='ms-2'>
                                    <div className="form-check">
                                        <input className="form-check-input row-checkbox" type="checkbox" name={`exampleRadios${index}`} id={`exampleRadios${index}`} value={`Rank ${item.id}`}
                                        {...(totalWPCategory.includes(item.rank) && {checked:true})}
                                        onChange={e => e.target.checked ? setTotalWPCategory( c => [...c, item.id]) : setTotalWPCategory( c => c.filter(it => it !== item.id))}
                                        />
                                        <label className="form-check-label" for={`exampleRadios${index}`}>
                                        <span>{item.name}</span>
                                        </label>
                                    </div>
                                    </li>
                                )
                                })
                            }
                            </ul>
                        </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" onClick={e => setImportStat('')}>Close</button>
                        <button type="button" className={`btn btn-primary`} onClick={importWordpress}>Save</button>
                    </div>
                    </div>
                </div>
            </div>
            {/* Modal Wordpress*/}
            {/* Modal Import*/}
            <div class="modal fade" id="ModalImport" tabindex="-1" role="dialog" aria-labelledby="ModalCenterTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                      <div className="modal-header">
                          <h5 class="modal-title me-auto" id="ModalLongTitle">Save Outline</h5>
                          <div className={`${[false,'',null].includes(importStat) ? 'd-none' : ''}`} id="success-noti">
                            <FontAwesomeIcon icon={faCheckCircle} style={{'color':'green'}}/>
                            <span className='ms-1'>Success</span>
                          </div>
                          <div className={`${[true,'',null].includes(importStat) ? 'd-none' : ''}`} id="failed-noti">
                            <FontAwesomeIcon icon={faTimesCircle} style={{'color':'red'}}/>
                            <span className='ms-1'>Failed. Pls Retry!</span>
                          </div>
                      </div>
                      <div class="modal-body" style={{'max-height':'500px', 'overflow':'auto'}}>
                        <div class="d-flex flex-column mb-4">
                          <div class="input-group mb-3">
                              <span class="input-group-text">Topic</span>
                              <input type="text" class="form-control" placeholder="Topic" aria-label="Topic" aria-describedby="basic-addon1" ref={topic}/>
                          </div>
                          <div class="input-group mb-3">
                              <span class="input-group-text">Domain</span>
                              <input type="text" class="form-control" placeholder="Domain" aria-label="Domain" aria-describedby="basic-addon1" ref={domain}/>
                          </div>
                          <div class="input-group mb-3">
                              <span class="input-group-text">Category</span>
                              <input type="text" class="form-control" placeholder="Category" aria-label="Category" aria-describedby="basic-addon1" ref={category}/>
                          </div>
                          <div class="form-floating">
                            <textarea class="form-control" placeholder="Leave a description" id="floatingTextarea2" style={{height: '100px'}} ref={description}/>
                            <label for="floatingTextarea2">Description</label>
                          </div>
                        </div>
                      </div>
                      <div class="modal-footer">
                          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" onClick={e => setImportStat('')}>Close</button>
                          <button type="button" className={`btn btn-primary`} id='import-button' onClick={importOutline}>Save</button>
                      </div>
                    </div>
                </div>
              </div>
            {/* Modal Import*/}
            <div class="input-group mx-4 my-4">
                <div className='input-group w-50 me-auto'>
                    <input type="text" class="form-control" placeholder="Insert exact url to load content from" ref={url}/>
                    <button class="btn btn-primary" type="button" onClick={loadContentHandler}>Load Content</button>
                </div>
                <div class="d-flex me-2">
                    <button class="btn btn-secondary" type="button" id="language" aria-expanded="false"
                      onClick={openWordpressModal}
                    >
                        <FontAwesomeIcon icon={faWordpress} className='me-2' />
                        Save
                    </button>
                </div>
                <div className='d-flex'>
                    <div className="input-group">
                    <button type="button" className="btn btn-primary" onClick={handleSaveOutline}>
                        {autoSave === true &&
                            <FontAwesomeIcon icon={faCheckCircle} className='me-2' style={{'color':'white'}}/>
                        }
                        <span>Save</span>
                    </button>
                    <button type="button" className="btn btn-primary" onClick={saveOutlineHandler}>
                    <FontAwesomeIcon icon={faUpRightFromSquare}/>
                    </button>
                    </div>
                </div>
            </div>
            <div className='d-flex h-75'>
                <div className='col-md-8 mx-4'>
                <Editor
                    apiKey='7kbo05huwibwa05biyzkb856nnpxv1tvflf8o3a85ideeaz7'
                    onInit={(evt, editor) => (editorRef.current = editor)}
                    initialValue={initialValue}
                    onEditorChange={(newValue, editor) => {
                        updateMetricHandler();
                        setAutoSave(false)
                    }}
                    init={{
                        height: "100%",
                        menubar: false,
                        plugins: [
                            "advlist",
                            "autolink",
                            "lists",
                            "link",
                            "image",
                            "charmap",
                            "anchor",
                            "searchreplace",
                            "visualblocks",
                            "code",
                            "fullscreen",
                            "insertdatetime",
                            "media",
                            "table",
                            "preview",
                            "wordcount",
                            'contextmenu'
                        ],
                        setup: handleEditorInit,
                        toolbar:
                            "undo redo | blocks | " +
                            "bold italic forecolor custommenu| alignleft aligncenter " +
                            "alignright alignjustify | bullist numlist outdent indent | " +
                            "removeformat",
                        content_style:
                            "body { font-family:Roboto,Arial,sans-serif; font-size:14px }",
                    }}
                    
                />
                </div>
                <div className='d-flex flex-column col-md-4'>
                    <ul class="nav nav-tabs">
                        <li class="nav-item" onClick={e => setTab('guideline')}>
                            <a class={`nav-link ${tab === 'guideline' && 'active'}`} href="#">
                                Guideline
                            </a>
                        </li>
                        <li class="nav-item" onClick={e => setTab('grader')}>
                            <a class={`nav-link ${tab === 'grader' && 'active'}`} href="#">Grader</a>
                        </li>
                    </ul>
                    <div className={`d-flex flex-column ${tab !== 'guideline' && 'd-none'}`}>
                        <div class="input-group my-4">
                            <button class="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">{location}</button>
                            <ul class="dropdown-menu">
                            <li onClick={event => setLocation('US-en')}>
                                <a class=" d-flex dropdown-item m-auto" href="#">
                                    <img src='us-flag.png' className="pe-2" style={{"height":"20px"}}/>
                                    <span>US-en</span>
                                </a>
                            </li>
                            <li onClick={event => setLocation('SG-en')}>
                                <a class=" d-flex dropdown-item m-auto" href="#">
                                    <img src='sg-flag.png' className="pe-2" style={{"height":"20px"}}/>
                                    <span>SG-en</span>
                                </a>
                            </li>
                            <li onClick={event => setLocation('VN-vi')}>
                                <a class=" d-flex dropdown-item m-auto" href="#">
                                    <img src='vn-flag.png' className="pe-2" style={{"height":"20px"}}/>
                                    <span>VN-vi</span>
                                </a>
                            </li>
                            </ul>
                            <input type="text" class="form-control" placeholder="Insert topic's main keyword" ref={mainKeyword}/>
                            <button class="btn btn-outline-primary" type="button" onClick={analyzeHandler} disabled={isLoading ? true : false}>Analyze</button>
                        </div>
                        {
                            terms.length > 0 &&
                            <>
                                <div className='d-flex flex-column my-4'>
                                    <h5>Content Structure</h5>
                                    <div className='d-flex mt-3'>
                                        {
                                            structure.length > 0 &&
                                            structure.map((item, idx) => (
                                                <div className={`d-flex col-md-3 flex-column align-items-center ${idx !== (structure.length -1) && 'border-end'}`}>
                                                    <h6 className='text-muted'>
                                                        {item.type.toUpperCase()}
                                                    </h6>
                                                    <h5 className='d-flex'>
                                                        {item.current ? item.current.toLocaleString('en-us') : '-'}
                                                        {
                                                            (item.current && item.current >= item.min && item.current <= item.max) ?
                                                            <FontAwesomeIcon icon={faCheck} className='ms-2 align-self-center' style={{color:'green', fontSize: '12px'}} /> :
                                                            (item.current && item.current === 0) || !item.current ?
                                                            <FontAwesomeIcon icon={faArrowUp} className='ms-2 align-self-center' style={{color:'red', fontSize: '12px'}} /> :
                                                            item.current ?
                                                            <FontAwesomeIcon icon={faArrowDown} className='ms-2 align-self-center' style={{color:'red', fontSize: '12px'}} /> :
                                                            null
                                                        }
                                                    </h5>
                                                    <span>{`${item.min.toLocaleString('en-us')} - ${item.max.toLocaleString('en-us')}`}</span>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                                <hr />
                                <div className='d-flex flex-column my-4'>
                                    <h5 className='mb-3'>Terms</h5>
                                    <div className='d-flex border border-2 rounded ps-2 align-items-center mb-3'>
                                        <FontAwesomeIcon icon={faSearch} className='p-2'/>
                                        <input type="text" class="form-control border-0" placeholder="Search"  ref={searchInput} onChange={e => searchTermsHandler(e.target.value)}/>
                                    </div>
                                    <ul class="nav nav-tabs my-4">
                                        <li class="nav-item" onClick={e => changeTypeHandler('all')}>
                                            <a class={`nav-link ${termType === 'all' && 'active'}`} href="#">
                                                All
                                                <span className='ms-2 px-2 bg-secondary rounded-3' style={{color: 'white'}}>{countTermHandler('all')}</span>
                                            </a>
                                        </li>
                                        <li class="nav-item" onClick={e => changeTypeHandler('heading')}>
                                            <a class={`nav-link ${termType === 'heading' && 'active'}`} href="#">
                                                Headings
                                                <span className='ms-2 px-2 bg-secondary rounded-3' style={{color: 'white'}}>{countTermHandler('heading')}</span>
                                            </a>
                                        </li>
                                    </ul>
                                    <div className='d-flex flex-wrap' style={{fontSize: '13px'}}>
                                        {
                                            filteredTerms.map((item, idx) => {
                                                return (
                                                    <div role='button' className='d-flex me-2 my-1'
                                                        onClick={e => highlightTextHandler(item)}
                                                        style={{color: (item.current >= item.lower && item.current <= item.upper) ? 'rgb(34, 42, 58)' : item.current === 0 ? 'rgb(123, 52, 30)' : 'rgb(123, 52, 30)',
                                                        border: (selectedTerms.includes(item.term) && item.current >= item.lower && item.current <= item.upper) ? '1px solid rgb(34, 42, 58)' : selectedTerms.includes(item.term) && item.current === 0 ? '1px solid rgb(123, 52, 30)' : selectedTerms.includes(item.term) ? '1px solid rgb(123, 52, 30)' : null,
                                                        borderRadius: '50rem'
                                                        }}
                                                    >
                                                        <span className='px-3 py-1 border border-1 border-end-0 rounded-end rounded-pill' style={{backgroundColor: (item.current >= item.lower && item.current <= item.upper) ? 'rgb(198,246,213)' : item.current === 0 ? 'rgb(255, 245, 245)' : 'rgb(254, 235, 200)'}}>{item.term}</span>
                                                        <span className='px-3 py-1 border border-1 rounded-start rounded-pill' style={{backgroundColor: (item.current >= item.lower && item.current <= item.upper) ? 'rgb(240,255,244)' : item.current === 0 ? 'rgb(255, 255, 255)' : 'rgb(255, 250, 240)'}}>{`${item.current || 0}/${item.lower}-${item.upper}`}</span>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                    <div className={`d-flex flex-column align-items-center mt-4 ${tab !== 'grader' && 'd-none'}`}>
                        {
                            data.length === 0 ?
                            <button type="button" class="btn btn-primary" disabled={isLoading ? true : false}
                                onClick={gradeContentHandler}
                            >
                                Grade Content
                            </button> :
                            <>
                                <div className='d-flex mb-4'>
                                    <RadarChart 
                                        data={data}
                                    />
                                </div>
                                <div className='d-flex flex-column overflow-auto px-2' style={{maxHeight: '300px'}}>
                                    {
                                        data.map((item, idx) => (
                                            <div class="card mb-3" >
                                                <div class="row g-0">
                                                    <div class="card-body">
                                                        <div className='d-flex'>
                                                            <h5 class="card-title">{item.criteria}</h5>
                                                            <small class="text-muted ms-auto">{`${item.grade}/5`}</small>
                                                        </div>
                                                        <p class="card-text">{item.explanation}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
};

export default ContentAudit;