import LoadingBar from 'react-redux-loading-bar';

const Head = () => {
    return (
        <div className="fixed-top">
          <LoadingBar />
        </div>
    );
};

export default Head;