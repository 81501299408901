import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap/dist/js/bootstrap.min.js";
// import * as bootstrap from 'bootstrap/dist/js/bootstrap'

import { useState, useRef, Fragment, useEffect, useMemo } from 'react';
// import { OverlayTrigger, Popover } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import Collapse from 'react-bootstrap/Collapse';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash, faDownload, faRefresh, faFilter, faMinus, faCalendar, faAngleUp, faAngleDown, faTimesCircle, faLineChart, faUpload, faGlobe, faPencil, faCheckCircle, faSearch, faCircleCheck, faArrowDown, faCaretDown
} from "@fortawesome/free-solid-svg-icons";

import {showLoading, hideLoading } from 'react-redux-loading-bar';
import { useDispatch } from "react-redux";

import { getAuthToken } from '../util/token';
import { useNavigate, useParams } from 'react-router-dom';
import { PieChart, Pie, ResponsiveContainer, Tooltip, Cell } from 'recharts';


const OnpageDetail = () => {
    const token = getAuthToken();
    const userName = localStorage.getItem('username');

    const [issueURL, setIssueURL] = useState([]);
    const [stat, setStat] = useState([]);
    const [open, setOpen] = useState([]);
    const [sidebarDisplayMode, setSidebarDisplayMode] = useState('category');
    const [byPriority, setByPriority] = useState([]);
    const [filteredList, setFilteredList] = useState([]);
    const searchIssue = useRef('');

    //table pagination controller
    const [showResult, setShowResult] = useState(20);
    const [pagination, setPagination] = useState([]);
    const [selectedPage, setSelectedPage] = useState(1);
    const [currentPageRange, setCurrentPageRange] = useState(1);
    const [wdth, setWdth] = useState('300px');
    
    useEffect(() => {
        fetch(`${process.env.REACT_APP_HOST_IP}/api/link-validate`,{
            method: 'POST',
            body: JSON.stringify({
                category: '',
                domain: 'gleads.vn',
                issue: 'all'
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
                'X-User': userName
            }
            }).then(
                    response => {
                        return response.json()
                    }
            ).then (
                (data) =>{
                    // console.log(data);
                    setIssueURL(data);
                    let newPagination = [];
                    for (let i = 1; i <= Math.ceil(data.length/showResult); i++){
                        // console.log(i);
                        newPagination.push(
                        <a class="page-link" href="#" onClick={(e) => {e.preventDefault();
                            setSelectedPage(i);
                            const el = document.querySelectorAll('li[class*=active]')[0];
                            el.className = 'page-item';
                            document.getElementById(`page-${i}`).className = 'page-item active';
                            }}>{i}
                        </a>
                        )
                    };
                    setPagination(newPagination);
                });
        fetch(`${process.env.REACT_APP_HOST_IP}/api/audit/technical`,{
            method: 'POST',
            body: JSON.stringify({}),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
                'X-User': userName
                }
        }).then(
                response => {
                    return response.json()
                }
            ).then (
                (data) =>{
                    // console.log(data);
                    setStat(data);
                }
            )
    },[]);

    useEffect(() => {
        setWdth(`${document.getElementsByClassName('second-col')[0].clientWidth + 30}px`);
        console.log(wdth);
    },[issueURL, selectedPage]);

    useEffect(() => {
        setCurrentPageRange(1);
        setSelectedPage(1);
        let newPagination = [];
        for (let i = 1; i <= Math.ceil(issueURL.length/showResult); i++){
          newPagination.push(
            <a class="page-link" href="#" onClick={(e) => {e.preventDefault();
              setSelectedPage(i);
              const el = document.querySelectorAll('li[class*=active]')[0];
              el.className = 'page-item';
              document.getElementById(`page-${i}`).className = 'page-item active';
              }}>{i}
            </a>
          )
        };
        setPagination(newPagination);
      },[showResult]);

    const changeShowResultHandler= (result) => {
        setCurrentPageRange(1);
        setSelectedPage(1);
        setShowResult(result);
      };
    
    const countIssue = (obj, category, type) => {
        let count = 0;
        if (type === 'pass'){
            Object.keys(obj[category]).map((issue, idx) => {
                if (parseInt(obj[category][issue]['value']) === 0 && obj[category][issue]['title'] !== 'All'){
                    count += 1;
                }
            });
        } else if (!['Critical', 'Warning', 'Info'].includes(category)) {
            Object.keys(obj[category]).map((issue, idx) => {
                if (parseInt(obj[category][issue]['value']) > 0 && obj[category][issue]['title'] !== 'All'){
                    count += 1;
                }
            });
        } else if (['Critical', 'Warning', 'Info'].includes(category)){
            Object.keys(obj[category]).map((issue, idx) => {
                if (parseInt(obj[category][issue]['value']) > 0 && issue !== 'All'){
                    count += 1;
                }
            });
        }
        return count
    };

    const switchModeHandler = (mode) => {
        setSidebarDisplayMode(mode);
        const prior = {
            'Critical':{},
            'Warning': {},
            'Info': {}
        }
        // console.log(stat)
        if (mode = 'priority'){
            Object.keys(stat).map(category => {
                Object.keys(stat[category]).map(issue => {
                    if(stat[category][issue]['value'] > 0){
                        if (!(issue in prior[stat[category][issue]['importance']])){

                            prior[stat[category][issue]['importance']][issue] = {};
                        }
                        prior[stat[category][issue]['importance']][issue] = {
                            'title': stat[category][issue]['title'],
                            'value': stat[category][issue]['value'],
                        }
                    }
                })
            });
            console.log(prior);
            setByPriority(prior);
        }
    };
    const filterHandler = (val) => {
        let filter = {}
        if (sidebarDisplayMode === 'category'){
            Object.keys(stat).map(category => {
                filter[category] = {};
                Object.keys(stat[category]).map(issue => {
                    if (stat[category][issue]['title'].search(new RegExp(`^${val}`, 'i')) !== -1){
                        if ( !(issue in filter[category])){
                            filter[category][issue] = {}
                        };
                        filter[category][issue] = stat[category][issue];
                    }
                })
            })
        } else if (sidebarDisplayMode === 'priority'){
            Object.keys(byPriority).map(category => {
                filter[category] = {};
                Object.keys(byPriority[category]).map(issue => {
                    if (byPriority[category][issue]['title'].search(new RegExp(`^${val}`, 'i')) !== -1){
                        if ( !(issue in filter[category])){
                            filter[category][issue] = {}
                        };
                        filter[category][issue] = byPriority[category][issue];
                    }
                })
            })
        }
        setFilteredList(filter);

    }
    
    
    return (
        <div className="d-flex flex-column">
            <h1 className="text-center mt-4 mb-5">Technical Audit Detail</h1>
            <div className='d-flex my-5'>
                <div className='d-flex flex-column col-md-9'>
                    <div>
                        <div className='d-flex my-2'>
                            <span className={`me-3 rounded bg-warning`} style={{width: '15px', height: '30px'}}> </span>
                            <h5>Page has an anchored image with no alt text</h5>
                        </div>
                        <p>Category: Links</p>
                        <p>Pages that contain anchor links to image URLs with missed or empty alt tag.</p>
                    </div>
                    <div className="table-responsive text-nowrap mt-4 overflow-auto mb-3" style={{"height":"800px", width: '100%'}}>
                        <table className="table w-auto table-fixed" style={{'word-wrap': 'break-word', width: ''}}>
                            <thead className="table-primary sticky-top" style={{'z-index':'9'}}>
                                <tr>
                                    <th scope="col" style={{position: 'sticky', left: 0, backgroundColor: '#cfe2ff',}}>#</th>
                                    <th scope="col" style={{position: 'sticky', left: '30px', backgroundColor: '#cfe2ff', width: '150px'}} className='second-col'>URL</th>
                                    <th style={{position: 'sticky', left: `${wdth}` , padding: 0, backgroundColor: '#cfe2ff',  width: '1%'}}>&nbsp;</th>
                                    <th scope="col" className='w-auto'>Content type</th>
                                    <th scope="col" className='w-auto'>Status code</th>
                                    <th scope="col" className='w-auto'>Indexability</th>
                                    <th scope="col" className='w-auto' >Title</th>
                                    <th scope="col" className='w-auto'>Title Length</th>
                                    <th scope="col" className='w-auto'>Description</th>
                                    <th scope="col" className='w-auto'>Description Length</th>
                                    <th scope="col" className='w-auto'>H1</th>
                                    <th scope="col" className='w-auto'>H1 Length</th>
                                    <th scope="col" className='w-auto'>H2</th>
                                    <th scope="col" className='w-auto'>H2 Length</th>
                                    <th scope="col" className='w-auto'>Meta robots</th>
                                    <th scope="col" className='w-auto'>Canonical</th>
                                    <th scope="col" className='w-auto'>Word count</th>
                                    <th scope="col" className='w-auto'>Sentence count</th>
                                    <th scope="col" className='w-auto'>Avg. Word per sentence</th>
                                    <th scope="col" className='w-auto'>Flesch score</th>
                                    <th scope="col" className='w-auto'>Crawl depth</th>
                                    <th scope="col" className='w-auto'>Response time</th>
                                    <th scope="col" className='w-auto'>Hreflang code</th>
                                    <th scope="col" className='w-auto'>Hreflang URL</th>
                                </tr>
                            </thead>
                            <tbody>
                                {issueURL.length > 0 && issueURL.slice(showResult*(selectedPage-1),showResult*selectedPage).map((item, index) => {
                                        return (
                                            <tr key={index} >
                                                <th scope="row" style={{position: 'sticky', left: 0}} className='bg-white w-auto'>{(index+1)+(selectedPage-1)*showResult}</th>
                                                <td style={{position: 'sticky', left: '30px',  width: '150px'}} className='bg-white second-col'>{item['URL']}</td>
                                                <td style={{position: 'sticky', left: `${wdth}`, backgroundColor: '#dee2e6', padding: 0}} className='w-auto'> </td>
                                                <td className='w-auto'>{item['content type']}</td>
                                                <td className='w-auto'>{item['status code']}</td>
                                                <td className='w-auto'></td>
                                                <td className='w-auto'>{item['title'][0] || ''}</td>
                                                <td className='w-auto'>{item['title'][0].length || ''}</td>
                                                <td className='w-auto'>{item['meta description'][0] || ''}</td>
                                                <td className='w-auto'>{item['meta description'][0].length || ''}</td>
                                                <td className='w-auto'>{item['content'].map(it => {
                                                    if(it['content_type'] === 'H1'){
                                                        return it['content_body']
                                                    }
                                                }) || ''}</td>
                                                <td className='w-auto'>{item['content'].map(it => {
                                                    if(it['content_type'] === 'H1'){
                                                        return it['content_body']
                                                    }
                                                }).length || ''}</td>
                                                <td className='w-auto'>{item['content'].map(it => {
                                                    if(it['content_type'] === 'H2'){
                                                        return it['content_body']
                                                    }
                                                }) || ''}</td>
                                                <td className='w-auto'>{item['content'].map(it => {
                                                    if(it['content_type'] === 'H2'){
                                                        return it['content_body']
                                                    }
                                                }).length || ''}</td>
                                                <td className='w-auto'>{item['meta robots']}</td>
                                                <td className='w-auto'>{item['canonical']}</td>
                                                <td className='w-auto'>{item['word_count']}</td>
                                                <td className='w-auto'>{item['sent_count']}</td>
                                                <td className='w-auto'>{item['sent_avg_length']}</td>
                                                <td className='w-auto'>{item['flesch_score']}</td>
                                                <td className='w-auto'>{item['crawl depth']}</td>
                                                <td className='w-auto'>{item['response time']}</td>
                                                <td className='w-auto'>{item['href lang code'][0]}</td>
                                                <td className='w-auto'>{item['href URL'][0]}</td>
                                            </tr>
                                        )
                                    })}
                            </tbody>
                        </table>
                    </div>
                    <div className="d-flex">
                        <div class="input-group mb-3 me-3 ms-auto w-25">
                        <label class="input-group-text" for="inputGroupSelect01">Results per page</label>
                        <select class="form-select" id="inputGroupSelect01" onChange={e => changeShowResultHandler(e.target.value)}>
                            <option selected value="10">10</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="500">500</option>
                        </select>
                        </div>
                        <nav aria-label="Page navigation example">
                        <ul class="pagination">
                            <li class={`page-item ${currentPageRange === 1 && 'disabled'}`}
                            onClick = {e => {
                            e.preventDefault();
                            setCurrentPageRange(current => {
                                if(current > 1){
                                return current -1
                                }
                                return current;
                                })}}
                            ><a class="page-link" href="#">Previous</a></li>
                            {pagination.map((item, index) => {return(
                            <li  key={index+1} id={`page-${index+1}`} className={`page-item ${index+1 === selectedPage && 'active'} ${(index+1 > 10*currentPageRange || index + 1 <= 10*(currentPageRange-1)) && 'd-none'}`}>
                                {item}
                            </li>
                            )})
                            }
                            <li
                            class={`page-item ${(pagination.length <= 10 || currentPageRange + 1 > Math.ceil(pagination.length/10)) && 'disabled'}`}
                            onClick = {e => {
                            e.preventDefault();
                            setCurrentPageRange(current => {
                                if(current + 1 <= Math.ceil(pagination.length/10)) {
                                return (current +1);
                                }
                                return current;
                            })}
                            }
                            ><a class="page-link" href="#">Next</a></li>
                        </ul>
                        </nav>
                        
                    </div>
                </div>
                <div className='d-flex flex-column col-md-3 ms-3'>
                    <div class="btn-group mb-3" role="group" aria-label="Basic radio toggle button group" >
                        <input type="radio" class="btn-check" name="btnradio" id="btnradio1" autocomplete="off" checked onClick={e => switchModeHandler('category')}/>
                        <label class="btn btn-outline-primary" for="btnradio1">by Category</label>

                        <input type="radio" class="btn-check" name="btnradio" id="btnradio2" autocomplete="off" onClick={e => switchModeHandler('priority')}/>
                        <label class="btn btn-outline-primary" for="btnradio2">by Priority</label>
                    </div>
                    <div class="input-group mb-3" >
                        <div className='d-flex align-content-start flex-fill border border-light border-2 p-2' >
                            <FontAwesomeIcon icon={faSearch} className='fa-3 align-self-center me-2'/>
                            <input type="text" class="form-control" placeholder="Search..." aria-label="Search..." aria-describedby="button-addon2"
                            style={{border: 'none'}}
                            ref={searchIssue}
                            onChange={e => filterHandler(e.target.value)}
                            />                        
                        </div>
                    </div>
                    <ul className='list-group'>
                        { sidebarDisplayMode === 'category' && searchIssue.current.value === '' ?
                        Object.keys(stat).map((category, index) => {
                            return (
                                !['Summary', 'Internal', 'External'].includes(category) &&
                                <>
                                    <a className='list-group-item list-group-item-action d-flex flex-column mb-2 border-0' index={index} role='button'
                                    href={`#${category}-${index}`}
                                    onClick={(e) => {e.preventDefault();
                                        if (category===open){
                                            setOpen('');
                                        }else{
                                            setOpen(category);
                                        }   
                                    }}
                                    >
                                        <div className='d-flex mb-2 w-100'>
                                            {category}
                                            <span className='ms-auto'>
                                                {Math.round(countIssue(stat, category))}
                                            </span>
                                        </div>
                                    </a>
                                    <Collapse in={open ===  category ? true : false}>
                                        <ul className="" id={category}>
                                            {Object.keys(stat[category]).map((issue, idx) => 
                                                // console.log(data[category][issue])
                                                parseInt(stat[category][issue]['value']) > 0 && stat[category][issue]['title'] !== 'All' &&
                                                <a class="list-group-item list-group-item-action d-flex align-items-center mb-2" index={idx} role='button'>
                                                    <span className={`me-3 rounded ${stat[category][issue]['importance'] === 'Critical' ? 'bg-danger' : stat[category][issue]['importance'] === 'Warning' ? 'bg-warning' : 'bg-light' }`} style={{width: '15px', height: '30px'}}> </span>
                                                    <span className='align-self-center me-auto'>{stat[category][issue]['title']}: {stat[category][issue]['value']} pages</span>
                                                </a>
                                            )}
                                        </ul>
                                    </Collapse>
                                </>
                            )
                        }) : searchIssue.current.value === '' ?
                        Object.keys(byPriority).map((priority, index) => {
                            return (
                                <>
                                    <a className='list-group-item list-group-item-action d-flex flex-column mb-2 border-0' index={index} role='button'
                                    href={`#${priority}-${index}`}
                                    onClick={(e) => {e.preventDefault(); 
                                        if (priority===open){
                                            setOpen('');
                                        }else{
                                            setOpen(priority);
                                        }   
                                    }}
                                    >
                                        <div className='d-flex mb-2 w-100'>
                                            <span className={`me-3 rounded ${priority === 'Critical' ? 'bg-danger' : priority === 'Warning' ? 'bg-warning' : 'bg-light' }`} style={{width: '15px', height: '30px'}}> </span>
                                            {priority}
                                            <span className='ms-auto'>
                                                {Math.round(countIssue(byPriority, priority))}
                                            </span>
                                        </div>
                                    </a>
                                    <Collapse in={open ===  priority ? true : false}>
                                        <ul className="" id={priority}>
                                            {Object.keys(byPriority[priority]).map((issue, idx) => 
                                                // console.log(data[category][issue])
                                                parseInt(byPriority[priority][issue]['value']) > 0 && issue !== 'All' &&
                                                <a class="list-group-item list-group-item-action d-flex align-items-center mb-2" index={idx} role='button'>
                                                    <span className='align-self-center me-auto'>{byPriority[priority][issue]['title']}: {byPriority[priority][issue]['value']} pages</span>
                                                </a>
                                            )}
                                        </ul>
                                    </Collapse>
                                </>
                            )
                        }) :
                        Object.keys(filteredList).map((category, index) => {
                            return (
                                !['Summary', 'Internal', 'External'].includes(category) &&
                                <>
                                    <a className='list-group-item list-group-item-action d-flex flex-column mb-2 border-0' index={index} role='button'
                                    href={`#${category}-${index}`}
                                    onClick={(e) => {e.preventDefault();
                                        if (category===open){
                                            setOpen('');
                                        }else{
                                            setOpen(category);
                                        }   
                                    }}
                                    >
                                        <div className='d-flex mb-2 w-100'>
                                            {sidebarDisplayMode === 'priority' && <span className={`me-3 rounded ${category === 'Critical' ? 'bg-danger' : category === 'Warning' ? 'bg-warning' : 'bg-light' }`} style={{width: '15px', height: '30px'}}> </span>}
                                            {category}
                                            <span className='ms-auto'>
                                                {Math.round(countIssue(filteredList, category))}
                                            </span>
                                        </div>
                                    </a>
                                    <Collapse in={open ===  category ? true : false}>
                                        <ul className="" id={category}>
                                            {Object.keys(filteredList[category]).map((issue, idx) => 
                                                // console.log(data[category][issue])
                                                parseInt(filteredList[category][issue]['value']) > 0 && filteredList[category][issue]['title'] !== 'All' &&
                                                <a class="list-group-item list-group-item-action d-flex align-items-center mb-2" index={idx} role='button'>
                                                    {sidebarDisplayMode === 'category' && <span className={`me-3 rounded ${filteredList[category][issue]['importance'] === 'Critical' ? 'bg-danger' : filteredList[category][issue]['importance'] === 'Warning' ? 'bg-warning' : 'bg-light' }`} style={{width: '15px', height: '30px'}}> </span>}
                                                    <span className='align-self-center me-auto'>{filteredList[category][issue]['title']}: {filteredList[category][issue]['value']} pages</span>
                                                </a>
                                            )}
                                        </ul>
                                    </Collapse>
                                </>
                            )
                        })
                        }
                    </ul>
                </div>
            </div>
        </div>
    )
};

export default OnpageDetail;