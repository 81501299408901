import { useState, useRef, Fragment, useEffect } from 'react';
import Menu from "../components/menu";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash, faDownload, faArrowLeft, faArrowRight, faFilter
} from "@fortawesome/free-solid-svg-icons";



function DatePicker(props) {

    const [selectedMonth, setSelectedMonth] = useState((new Date()).getMonth() + 1);
    const [ activeDate, setActiveDate] = useState("start");
    const [ activePeriod, setActivePeriod] = useState("");
    const [ showComparison, setShowComparison ] = useState(props.comparisonMode || false);
    const [ trackingDomains, setTrackingDomains ] = useState(props.trackingDomains || []);
    const [domain, setDomain] = useState('Domain');
    
    // const current_weekday = date.getDay(); // From 0 - 6
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [startDateComp, setStartDateComp] = useState();
    const [endDateComp, setEndDateComp] = useState();

    useEffect(() => {
        setActivePeriod(props.activePeriod);
        setDomain(props.domain);
        setShowComparison(props.comparisonMode);
        document.getElementById('flexSwitchCheckDefault').checked = props.comparisonMode;

        //set startDate
        let c_date = new Date(props.start);

        let c_day = c_date.getDate();
        let c_month = c_date.toLocaleString('en-US', {month: 'short'});
        let c_year = c_date.getFullYear();

        setStartDate(`${c_month} ${c_day}, ${c_year}`);
        // document.getElementById('startField').value = `${c_month} ${c_day}, ${c_year}`;


        // set startDateComp
        let c_dateComp = new Date(props.startComp);

        let c_dayComp = c_dateComp.getDate();
        let c_monthComp = c_dateComp.toLocaleString('en-US', {month: 'short'});
        let c_yearComp = c_dateComp.getFullYear();

        setStartDateComp(`${c_monthComp} ${c_dayComp}, ${c_yearComp}`);
        // document.getElementById('startFieldComp').value = `${c_monthComp} ${c_dayComp}, ${c_yearComp}`;

        // set endDate
        let d_date = new Date(props.end);

        let d_day = d_date.getDate();
        let d_month = d_date.toLocaleString('en-US', {month: 'short'});
        let d_year = d_date.getFullYear();
        setEndDate(`${d_month} ${d_day}, ${d_year}`);
        // document.getElementById('endField').value = `${d_month} ${d_day}, ${d_year}`;

        // set endDateComp
        let d_dateComp = new Date(props.endComp);

        let d_dayComp = d_dateComp.getDate();
        let d_monthComp = d_dateComp.toLocaleString('en-US', {month: 'short'});
        let d_yearComp = d_dateComp.getFullYear();
        setEndDateComp(`${d_monthComp} ${d_dayComp}, ${d_yearComp}`);
        // document.getElementById('endFieldComp').value = `${d_monthComp} ${d_dayComp}, ${d_yearComp}`;
        if (Math.abs((new Date()).getTime() - d_date)/(86400*1000) > 30 && props.end !== ''){
            setSelectedMonth(d_date.getMonth() + 1);
        };
    },[])

    const final_month_idx = (year, month) => {
        let start = new Date(year, month-1,1).getDay();
        let total = new Date(year, month, 0).getDate();

        let max_week = 5;
        if ((total + start) > 35) {max_week = 6};

        let start_month_idx = new Array(start).fill('');
        let end_month_idx = new Array(max_week*7 - total - start).fill('');
        let month_idx = []
        for (let i = 1; i <= total; i++) {
            month_idx.push(i);
        };
        
        return [max_week, start_month_idx.concat(month_idx, end_month_idx)];
    };

    const updateDateHandler = (e) => {
        // console.log(e.target.innerHTML);
        setActivePeriod('');
        if (activeDate === "start"){
            setStartDate(e.target.getAttribute("data-date"));
            setActiveDate( current => "end");
        } else if (activeDate === "end") {
            setEndDate(e.target.getAttribute("data-date"));
            setActiveDate( current => "start");
        } else if (activeDate === "startcomp") {
            setStartDateComp(e.target.getAttribute("data-date"));
            setActiveDate( current => "endcomp");
        } else {
            setEndDateComp(e.target.getAttribute("data-date"));
            setActiveDate( current => "startcomp"); 
        };
    }

    

    const date_table = (activeMonth) => {
        let date = new Date(2024, activeMonth-1,1);

        let current_year = date.getFullYear();
        let current_month = date.toLocaleString('en-US', {month: 'short'});
        let [ total_week, detail_date ] = final_month_idx(2024, activeMonth);
        return (
            <table class="table">
                            <thead>
                                <tr>
                                    <th colspan="7" className="text-center">{current_month} {current_year}</th>
                                </tr>
                                <tr>
                                    <th scope="col">S</th>
                                    <th scope="col">M</th>
                                    <th scope="col">T</th>
                                    <th scope="col">W</th>
                                    <th scope="col">T</th>
                                    <th scope="col">F</th>
                                    <th scope="col">S</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                (new Array(total_week).fill("")).map((item, idx) => {
                                    return (
                                        <tr>
                                            {
                                            detail_date.slice(idx*7,(idx+1)*7).map((it, index) => {
                                                return (
                                                    <td 
                                                        style = {
                                                            (Date.parse(startDate) <= Date.parse(`${current_month} ${it}, ${current_year}`)) && it !== "" && (Date.parse(endDate) >= Date.parse(`${current_month} ${it}, ${current_year}`)) ? {backgroundColor: "#D2E3FC"} :
                                                            (Date.parse(startDateComp) <= Date.parse(`${current_month} ${it}, ${current_year}`)) && it !== "" && showComparison && (Date.parse(endDateComp) >= Date.parse(`${current_month} ${it}, ${current_year}`)) ? {backgroundColor: "rgb(255, 236, 179)"}: {}
                                                            
                                                        }
                                                        onClick = {event => updateDateHandler(event)}
                                                        data-date = {`${current_month} ${it}, ${current_year}`}
                                                        className="p-0"
                                                    >
                                                        <div className = "p-2"
                                                            data-date = {`${current_month} ${it}, ${current_year}`}
                                                            onMouseEnter={event => {event.currentTarget.style.background = '#dadce0'}}
                                                            onMouseLeave={event => {if (event.currentTarget.style.background === 'rgb(218, 220, 224)') {event.currentTarget.style.background = ''}}}
                                                        >
                                                            {it}
                                                        </div>
                                                    </td>
                                                )
                                            })
                                            }
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
        )
    }

    const dateBack = (delta) => {
        const c_date = new Date();

        const c_day = c_date.getDate();
        const c_month = c_date.getMonth();
        const c_year = c_date.getFullYear();

        const n_date = new Date(c_year, c_month, c_day - delta);
        const n_day = n_date.getDate();
        const n_month = n_date.toLocaleString('en-US', {month: 'short'});
        const n_year = n_date.getFullYear();
        return [n_day, n_month, n_year];
    };

    const updatePeriodHandler = (input) => {
        // "today"
        // "yesterday"
        // "last7"
        // "last30"
        // "last90"
        // "last12m"
        const c_date = new Date();

        const c_day = c_date.getDate();
        const c_year = c_date.getFullYear();
        const c_month = c_date.toLocaleString('en-US', {month: 'short'});
        if (input === "today") {
            setActivePeriod(current => "today");
            setStartDate(`${c_month} ${c_day}, ${c_year}`);
            setEndDate(`${c_month} ${c_day}, ${c_year}`);
            if (showComparison){
                const [n_date, n_month, n_year] = dateBack(1);
                setStartDateComp(`${n_month} ${n_date}, ${n_year}`);
                setEndDateComp(`${n_month} ${n_date}, ${n_year}`); 
            }
        } else if (input === "yesterday"){
            setActivePeriod(current => "yesterday");
            let [n_date, n_month, n_year] = dateBack(1);
            setStartDate(`${n_month} ${n_date}, ${n_year}`);
            setEndDate(`${n_month} ${n_date}, ${n_year}`);
            if (showComparison){
                let [n_date, n_month, n_year] = dateBack(2);
                setStartDateComp(`${n_month} ${n_date}, ${n_year}`);
                setEndDateComp(`${n_month} ${n_date}, ${n_year}`); 
            }
        } else if (input === "last7"){
            setActivePeriod(current => "last7");
            let [s_date, s_month, s_year] = dateBack(7);
            setStartDate(`${s_month} ${s_date}, ${s_year}`);
            let [e_date, e_month, e_year] = dateBack(1);
            setEndDate(`${e_month} ${e_date}, ${e_year}`);
            if (showComparison){
                let [s_date, s_month, s_year] = dateBack(15);
                setStartDateComp(`${s_month} ${s_date}, ${s_year}`);
                let [e_date, e_month, e_year] = dateBack(8);
                setEndDateComp(`${e_month} ${e_date}, ${e_year}`); 
            }
        } else if (input === "last30"){
            setActivePeriod(current => "last30");
            let [s_date, s_month, s_year] = dateBack(30);
            setStartDate(`${s_month} ${s_date}, ${s_year}`);
            let [e_date, e_month, e_year] = dateBack(1);
            setEndDate(`${e_month} ${e_date}, ${e_year}`);
            if (showComparison){
                let [s_date, s_month, s_year] = dateBack(61);
                setStartDateComp(`${s_month} ${s_date}, ${s_year}`);
                let [e_date, e_month, e_year] = dateBack(31);
                setEndDateComp(`${e_month} ${e_date}, ${e_year}`); 
            }
        } else if (input === "last90"){
            setActivePeriod(current => "last90");
            let [s_date, s_month, s_year] = dateBack(90);
            setStartDate(`${s_month} ${s_date}, ${s_year}`);
            let [e_date, e_month, e_year] = dateBack(1);
            setEndDate(`${e_month} ${e_date}, ${e_year}`);
            if (showComparison){
                let [s_date, s_month, s_year] = dateBack(181);
                setStartDateComp(`${s_month} ${s_date}, ${s_year}`);
                let [e_date, e_month, e_year] = dateBack(91);
                setEndDateComp(`${e_month} ${e_date}, ${e_year}`); 
            }
        } else if (input === "last12m"){
            setActivePeriod(current => "last12m");
            let [s_date, s_month, s_year] = dateBack(365);
            setStartDate(`${s_month} ${s_date}, ${s_year}`);
            let [e_date, e_month, e_year] = dateBack(1);
            setEndDate(`${e_month} ${e_date}, ${e_year}`);
            if (showComparison){
                let [s_date, s_month, s_year] = dateBack(731);
                setStartDateComp(`${s_month} ${s_date}, ${s_year}`);
                let [e_date, e_month, e_year] = dateBack(366);
                setEndDateComp(`${e_month} ${e_date}, ${e_year}`); 
            }
        }
    }

    const onApplyHandler = (start, end, startcomp, endcomp, domain, showComparison, activePeriod) => {
        props.applyDateRangeHandler(start,end, startcomp, endcomp, domain, showComparison, activePeriod);
        // console.log( startcomp, endcomp);
        document.body.click();
    }

    return (
        <div className="d-flex my-2 shadow rounded">
                <div className="p-2 align-self-center">
                    <button className="btn btn-light" onClick = {event => setSelectedMonth(current => current -1)}><FontAwesomeIcon icon={faArrowLeft} /></button>
                </div>
                <div className="p-2">
                    {date_table(selectedMonth-2)}
                </div>
                <div className="p-2">
                    {date_table(selectedMonth-1)}
                </div>
                <div className="p-2">
                    {date_table(selectedMonth)}
                </div>
                <div className="p-2 align-self-center">
                <button className="btn btn-light" onClick = {event => setSelectedMonth(current => current +1)}><FontAwesomeIcon icon={faArrowRight} /></button>
                </div>
                <div className="p-3">
                    <ul class="list-group list-group-flush">
                        <a href="#" class={`list-group-item ps-0 ${activePeriod === "today" ? "list-group-item-primary" : ""}`} onClick={event => updatePeriodHandler("today")}>Today</a>
                        <a href="#" class={`list-group-item ps-0 ${activePeriod === "yesterday" ? "list-group-item-primary" : ""}`} onClick={event => updatePeriodHandler("yesterday")}>Yesterday</a>
                        <a href="#" class={`list-group-item ps-0 ${activePeriod === "last7" ? "list-group-item-primary" : ""}`} onClick={event => updatePeriodHandler("last7")}>Last 7 days</a>
                        <a href="#" class={`list-group-item ps-0 ${activePeriod === "last30" ? "list-group-item-primary" : ""}`} onClick={event => updatePeriodHandler("last30")}>Last 30 days</a>
                        <a href="#" class={`list-group-item ps-0 ${activePeriod === "last90" ? "list-group-item-primary" : ""}`} onClick={event => updatePeriodHandler("last90")}>Last 90 days</a>
                        <a href="#" class={`list-group-item ps-0 ${activePeriod === "last12m" ? "list-group-item-primary" : ""}`}onClick={event => updatePeriodHandler("last12m")}>Last 12 months</a>
                    </ul>
                    <div className={`my-2 ${(props.hideControl === '' || props.hideControl === 'filter') && 'd-none'}`}>
                        <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                            <FontAwesomeIcon icon={faFilter} />
                            <span className='ms-2'>{domain}</span>
                        </button>
                        <ul class="dropdown-menu" style={{'z-index':'9999'}} aria-labelledby="dropdownMenuButton1">
                        {
                            trackingDomains.map((item, index) => {
                            return (
                                <li key={index} onClick={e => {e.preventDefault(); setDomain(item)}}><a class="dropdown-item" href="#">{item}</a></li>
                            )
                            })
                        }
                        </ul>
                    </div>
                    <h6 className="py-2">CUSTOM</h6>
                    <div className="d-flex pb-2">
                        <input type="text" class="form-control" style={activeDate === "start" ? {backgroundColor: "#D2E3FC"} : {}} defaultValue={startDate} id="startField" aria-label="Start date" onClick={event => setActiveDate(current => "start")} />
                        <span className="px-2">-</span>
                        <input type="text" class="form-control" style={activeDate === "end" ? {backgroundColor: "#D2E3FC"} : {}} defaultValue={endDate} id="endField" aria-label="End date" onClick={event => setActiveDate(current => "end")}/>
                    </div>
                    <div className={`d-flex py-2 ${props.hideControl === '' && 'd-none'}`}>
                        <h6 className="pe-3">Compare</h6>
                        <div class="form-check form-switch">
                            <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" defaultChecked={showComparison} onChange = {event => setShowComparison(current => !current)}/>
                        </div>
                    </div>
                    <div className={`${!showComparison ? "d-none" : ""}`}>
                        <h6 className="py-2">COMPARED TO</h6>
                        <div className="d-flex pb-2">
                            <input type="text" class="form-control" style={activeDate === "startcomp" ? {backgroundColor: "#D2E3FC"} : {}} defaultValue={startDateComp} id="startFieldComp" aria-label="Start date" onClick={event => setActiveDate(current => "startcomp")} />
                            <span className="px-2">-</span>
                            <input type="text" class="form-control" style={activeDate === "endcomp" ? {backgroundColor: "#D2E3FC"} : {}} defaultValue={endDateComp} id="endFieldComp" aria-label="End date" onClick={event => setActiveDate(current => "endcomp")}/>
                        </div>
                    </div>
                    <div className="d-flex my-3">
                        <button
                            type="button"
                            className={`btn me-2 ms-auto`}
                            onClick={(event) => document.body.click()}
                            >
                                {/* <FontAwesomeIcon icon={faDownload} /> */}
                                Cancel
                        </button>
                        <button
                        type="button"
                        className={`btn btn-primary`}
                        onClick={event => onApplyHandler(startDate,endDate,startDateComp,endDateComp,domain, showComparison, activePeriod)}
                        >
                            {/* <FontAwesomeIcon icon={faDownload} /> */}
                            Apply
                        </button>
                    </div>
                </div>
            
        </div>
    )
};

export default DatePicker;