import { useState, useMemo, useRef, useEffect } from "react";
import {showLoading, hideLoading } from 'react-redux-loading-bar';
import { useDispatch, useSelector } from "react-redux";

import "bootstrap/dist/css/bootstrap.min.css";
// import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import "bootstrap/dist/js/bootstrap.min.js";
import * as bootstrap from 'bootstrap/dist/js/bootstrap';

import { OverlayTrigger, Popover } from 'react-bootstrap';
import ProgressBar from 'react-bootstrap/ProgressBar';

import WordCloudComponent from "../util/word-cloud";
import Papa from 'papaparse';
import { v4 as uuid4 } from 'uuid';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faDownload, faGlobe, faLanguage, faSearch, faFilter, faAngleUp, faAngleDown, faPencil,
    faCheckCircle, faTimesCircle, faTrash, faRefresh
} from "@fortawesome/free-solid-svg-icons";

import { Form, redirect, useActionData, useNavigation } from "react-router-dom";
import {
    CartesianGrid,
    Legend,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
    BarChart,
    Bar,
    PieChart,
    Pie,
    Cell
  } from "recharts";
  import moment from "moment";
  import { scaleTime } from "d3-scale";
import { getAuthToken } from "../util/token";

// const data = {
//   "ref_domains": [
//       "all4webs.com",
//       "chillspot1.com",
//       "taiungdung.vn",
//       "blogtranphu.com",
//       "reviewphim.net",
//       "vemaybays.vn",
//       "blogsofts.net",
//       "xaongon.net",
//       "aiti-aptech.edu.vn",
//       "f-store.com.vn",
//       "topgoogleaz.com",
//       "topdanhgiaaz.com",
//       "entheomedia.org",
//       "internetmarketing.vn",
//       "elsalvadorhistorico.org",
//       "sapavietnam.vn",
//       "coolpadmobile.vn",
//       "blogthuatngu.com",
//       "hosodoanhnhan.vn",
//       "vd-art.vn",
//       "apologize.vn",
//       "bizday.net",
//       "batteryinyourleg.com",
//       "emsayroi.com",
//       "canhchua.vn",
//       "social.vn",
//       "choihoadep.net",
//       "chogiasi.com.vn",
//       "clubsincrisis.com",
//       "blogbatdongsan.net",
//       "idiadiem.net",
//       "remove.vn",
//       "gitlab.com.vn",
//       "all4webs.com",
//       "all4webs.com",
//       "all4webs.com",
//       "all4webs.com",
//       "all4webs.com",
//       "all4webs.com",
//       "all4webs.com",
//       "all4webs.com",
//       "all4webs.com",
//       "all4webs.com",
//       "all4webs.com",
//       "all4webs.com",
//       "all4webs.com",
//       "all4webs.com",
//       "all4webs.com",
//       "all4webs.com",
//       "all4webs.com",
//       "all4webs.com",
//       "all4webs.com",
//       "all4webs.com",
//       "all4webs.com",
//       "all4webs.com",
//       "all4webs.com",
//       "memoryarchive.org",
//       "zozoship.vn",
//       "songgio.vn",
//       "baodanang.vn",
//       "tcn.vn",
//       "xaydunghtd.com",
//       "all4webs.com",
//       "paxland.vn",
//       "reddit.com.vn",
//       "thiepcuoidep.vn",
//       "chinaphilharmonic.org",
//       "workfromhome.vn",
//       "vietbao.vn",
//       "realchampionshipwrestling.com",
//       "duongdamat.com.vn",
//       "topdanhgia.vn",
//       "www.bienphong.com.vn",
//       "srebrenica-opstina.org",
//       "baoquangngai.vn",
//       "www.chillspot1.com",
//       "ello.co",
//       "ello.co",
//       "ello.co",
//       "ello.co",
//       "tuoitrexahoi.vn",
//       "hashnode.com",
//       "gleadsltd.wixsite.com",
//       "gleadsltd.wixsite.com",
//       "gleadsltd.wixsite.com",
//       "gleadsltd.wixsite.com",
//       "gleadsltd.wixsite.com",
//       "blogphuot.info",
//       "nuongthom.net",
//       "xehay.com.vn",
//       "xevespa.vn",
//       "gleadsvn.blogspot.com",
//       "gleadsvn.blogspot.com",
//       "gleadsvn.blogspot.com",
//       "gleadsvn.blogspot.com",
//       "gleadsvn.blogspot.com",
//       "blog.donghoviet.vn",
//       "minhview.com",
//       "feel.vn",
//       "vietkieu.com.vn"
//   ],
//   "ref_ips": [
//       "104.21.235.187",
//       "69.4.80.171",
//       "103.110.84.51",
//       "103.77.162.11",
//       "103.27.237.162",
//       "103.27.237.160",
//       "188.114.96.3",
//       "103.9.156.112",
//       "103.57.223.17",
//       "172.67.197.10",
//       "103.77.162.21",
//       "103.77.162.23",
//       "188.114.96.3",
//       "103.27.237.156",
//       "45.119.213.82",
//       "103.27.237.158",
//       "103.9.157.225",
//       "112.213.89.130",
//       "103.27.237.156",
//       "103.9.157.218",
//       "103.75.185.20",
//       "103.221.222.96",
//       "104.21.78.6",
//       "188.114.96.3",
//       "103.57.220.161",
//       "103.27.237.154",
//       "103.9.156.105",
//       "103.27.237.145",
//       "188.114.96.3",
//       "103.77.162.23",
//       "103.77.162.23",
//       "103.27.237.153",
//       "172.67.132.12",
//       "104.21.235.187",
//       "104.21.235.187",
//       "104.21.235.187",
//       "104.21.235.187",
//       "104.21.235.187",
//       "104.21.235.187",
//       "104.21.235.187",
//       "104.21.235.187",
//       "104.21.235.187",
//       "104.21.235.187",
//       "104.21.235.187",
//       "104.21.235.187",
//       "104.21.235.187",
//       "104.21.235.187",
//       "104.21.235.187",
//       "104.21.235.187",
//       "104.21.235.187",
//       "104.21.235.187",
//       "104.21.235.187",
//       "104.21.235.187",
//       "104.21.235.187",
//       "104.21.235.187",
//       "104.21.235.187",
//       "172.67.169.53",
//       "188.114.96.3",
//       "188.114.96.3",
//       "123.31.36.90",
//       "172.67.137.55",
//       "103.130.216.96",
//       "104.21.235.187",
//       "188.114.96.3",
//       "188.114.96.3",
//       "103.27.237.166",
//       "104.21.95.133",
//       "172.67.223.12",
//       "116.118.88.93",
//       "104.21.74.62",
//       "202.92.4.49",
//       "112.213.89.186",
//       "125.212.192.52",
//       "104.21.52.50",
//       "123.31.45.45",
//       "69.4.80.171",
//       "104.22.26.152",
//       "104.22.26.152",
//       "104.22.26.152",
//       "104.22.26.152",
//       "210.2.90.34",
//       "76.76.21.21",
//       "34.117.60.144",
//       "34.117.60.144",
//       "34.117.60.144",
//       "34.117.60.144",
//       "34.117.60.144",
//       "103.9.156.104",
//       "103.9.157.232",
//       "103.27.237.176",
//       "103.27.237.162",
//       "142.250.186.33",
//       "142.250.186.33",
//       "142.250.186.33",
//       "142.250.186.33",
//       "142.250.186.33",
//       "103.27.237.134",
//       "172.67.141.219",
//       "103.27.237.146",
//       "103.27.237.136"
//   ],
//   "spam_score": {
//       "0-30": 90,
//       "31-60": 10,
//       "61-100": 0
//   },
//   "domain_rank": {
//       "0-50": 12,
//       "51-200": 36,
//       "201-500": 47,
//       "500+": 5
//   },
//   "tld": {
//       "com": 38,
//       "vn": 25,
//       "net": 8,
//       "edu.vn": 1,
//       "com.vn": 8,
//       "org": 5,
//       "co": 4,
//       "wixsite.com": 5,
//       "info": 1,
//       "blogspot.com": 5
//   },
//   "country": {
//       "nil": 33,
//       "United States": 19,
//       "Vietnam": 40,
//       "The Netherlands": 8
//   },
//   "backlink_types": {
//       "anchor": 91,
//       "image": 9
//   },
//   "link_attr": {
//       "follow": 100,
//       "no-follow": 0,
//       "sponsored": 0,
//       "ugc": 0
//   },
//   "anchor": {
//       "https://gleads.vn/vi": 17,
//       "Gleads": 56,
//       "Công ty TNHH Gleads": 13,
//       "Công ty Gleads": 1,
//       "công ty Gleads": 1,
//       "Đội ngũ Gleads": 1,
//       "https://gleads.vn": 1,
//       "gleads.vn/vi": 4,
//       "null": 6
//   },
//   "domain": {
//       "all4webs.com": {
//           "backlinks": 25,
//           "country": "nil",
//           "ip": "104.21.235.187"
//       },
//       "chillspot1.com": {
//           "backlinks": 1,
//           "country": "United States",
//           "ip": "69.4.80.171"
//       },
//       "taiungdung.vn": {
//           "backlinks": 1,
//           "country": "Vietnam",
//           "ip": "103.110.84.51"
//       },
//       "blogtranphu.com": {
//           "backlinks": 1,
//           "country": "Vietnam",
//           "ip": "103.77.162.11"
//       },
//       "reviewphim.net": {
//           "backlinks": 1,
//           "country": "Vietnam",
//           "ip": "103.27.237.162"
//       },
//       "vemaybays.vn": {
//           "backlinks": 1,
//           "country": "Vietnam",
//           "ip": "103.27.237.160"
//       },
//       "blogsofts.net": {
//           "backlinks": 1,
//           "country": "The Netherlands",
//           "ip": "188.114.96.3"
//       },
//       "xaongon.net": {
//           "backlinks": 1,
//           "country": "Vietnam",
//           "ip": "103.9.156.112"
//       },
//       "aiti-aptech.edu.vn": {
//           "backlinks": 1,
//           "country": "Vietnam",
//           "ip": "103.57.223.17"
//       },
//       "f-store.com.vn": {
//           "backlinks": 1,
//           "country": "United States",
//           "ip": "172.67.197.10"
//       },
//       "topgoogleaz.com": {
//           "backlinks": 1,
//           "country": "Vietnam",
//           "ip": "103.77.162.21"
//       },
//       "topdanhgiaaz.com": {
//           "backlinks": 1,
//           "country": "Vietnam",
//           "ip": "103.77.162.23"
//       },
//       "entheomedia.org": {
//           "backlinks": 1,
//           "country": "The Netherlands",
//           "ip": "188.114.96.3"
//       },
//       "internetmarketing.vn": {
//           "backlinks": 1,
//           "country": "Vietnam",
//           "ip": "103.27.237.156"
//       },
//       "elsalvadorhistorico.org": {
//           "backlinks": 1,
//           "country": "Vietnam",
//           "ip": "45.119.213.82"
//       },
//       "sapavietnam.vn": {
//           "backlinks": 1,
//           "country": "Vietnam",
//           "ip": "103.27.237.158"
//       },
//       "coolpadmobile.vn": {
//           "backlinks": 1,
//           "country": "Vietnam",
//           "ip": "103.9.157.225"
//       },
//       "blogthuatngu.com": {
//           "backlinks": 1,
//           "country": "Vietnam",
//           "ip": "112.213.89.130"
//       },
//       "hosodoanhnhan.vn": {
//           "backlinks": 1,
//           "country": "Vietnam",
//           "ip": "103.27.237.156"
//       },
//       "vd-art.vn": {
//           "backlinks": 1,
//           "country": "Vietnam",
//           "ip": "103.9.157.218"
//       },
//       "apologize.vn": {
//           "backlinks": 1,
//           "country": "Vietnam",
//           "ip": "103.75.185.20"
//       },
//       "bizday.net": {
//           "backlinks": 1,
//           "country": "Vietnam",
//           "ip": "103.221.222.96"
//       },
//       "batteryinyourleg.com": {
//           "backlinks": 1,
//           "country": "nil",
//           "ip": "104.21.78.6"
//       },
//       "emsayroi.com": {
//           "backlinks": 1,
//           "country": "The Netherlands",
//           "ip": "188.114.96.3"
//       },
//       "canhchua.vn": {
//           "backlinks": 1,
//           "country": "Vietnam",
//           "ip": "103.57.220.161"
//       },
//       "social.vn": {
//           "backlinks": 1,
//           "country": "Vietnam",
//           "ip": "103.27.237.154"
//       },
//       "choihoadep.net": {
//           "backlinks": 1,
//           "country": "Vietnam",
//           "ip": "103.9.156.105"
//       },
//       "chogiasi.com.vn": {
//           "backlinks": 1,
//           "country": "Vietnam",
//           "ip": "103.27.237.145"
//       },
//       "clubsincrisis.com": {
//           "backlinks": 1,
//           "country": "The Netherlands",
//           "ip": "188.114.96.3"
//       },
//       "blogbatdongsan.net": {
//           "backlinks": 1,
//           "country": "Vietnam",
//           "ip": "103.77.162.23"
//       },
//       "idiadiem.net": {
//           "backlinks": 1,
//           "country": "Vietnam",
//           "ip": "103.77.162.23"
//       },
//       "remove.vn": {
//           "backlinks": 1,
//           "country": "Vietnam",
//           "ip": "103.27.237.153"
//       },
//       "gitlab.com.vn": {
//           "backlinks": 1,
//           "country": "United States",
//           "ip": "172.67.132.12"
//       },
//       "memoryarchive.org": {
//           "backlinks": 1,
//           "country": "United States",
//           "ip": "172.67.169.53"
//       },
//       "zozoship.vn": {
//           "backlinks": 1,
//           "country": "The Netherlands",
//           "ip": "188.114.96.3"
//       },
//       "songgio.vn": {
//           "backlinks": 1,
//           "country": "The Netherlands",
//           "ip": "188.114.96.3"
//       },
//       "baodanang.vn": {
//           "backlinks": 1,
//           "country": "Vietnam",
//           "ip": "123.31.36.90"
//       },
//       "tcn.vn": {
//           "backlinks": 1,
//           "country": "United States",
//           "ip": "172.67.137.55"
//       },
//       "xaydunghtd.com": {
//           "backlinks": 1,
//           "country": "Vietnam",
//           "ip": "103.130.216.96"
//       },
//       "paxland.vn": {
//           "backlinks": 1,
//           "country": "The Netherlands",
//           "ip": "188.114.96.3"
//       },
//       "reddit.com.vn": {
//           "backlinks": 1,
//           "country": "The Netherlands",
//           "ip": "188.114.96.3"
//       },
//       "thiepcuoidep.vn": {
//           "backlinks": 1,
//           "country": "Vietnam",
//           "ip": "103.27.237.166"
//       },
//       "chinaphilharmonic.org": {
//           "backlinks": 1,
//           "country": "nil",
//           "ip": "104.21.95.133"
//       },
//       "workfromhome.vn": {
//           "backlinks": 1,
//           "country": "United States",
//           "ip": "172.67.223.12"
//       },
//       "vietbao.vn": {
//           "backlinks": 1,
//           "country": "Vietnam",
//           "ip": "116.118.88.93"
//       },
//       "realchampionshipwrestling.com": {
//           "backlinks": 1,
//           "country": "nil",
//           "ip": "104.21.74.62"
//       },
//       "duongdamat.com.vn": {
//           "backlinks": 1,
//           "country": "Vietnam",
//           "ip": "202.92.4.49"
//       },
//       "topdanhgia.vn": {
//           "backlinks": 1,
//           "country": "Vietnam",
//           "ip": "112.213.89.186"
//       },
//       "www.bienphong.com.vn": {
//           "backlinks": 1,
//           "country": "Vietnam",
//           "ip": "125.212.192.52"
//       },
//       "srebrenica-opstina.org": {
//           "backlinks": 1,
//           "country": "nil",
//           "ip": "104.21.52.50"
//       },
//       "baoquangngai.vn": {
//           "backlinks": 1,
//           "country": "Vietnam",
//           "ip": "123.31.45.45"
//       },
//       "www.chillspot1.com": {
//           "backlinks": 1,
//           "country": "United States",
//           "ip": "69.4.80.171"
//       },
//       "ello.co": {
//           "backlinks": 4,
//           "country": "nil",
//           "ip": "104.22.26.152"
//       },
//       "tuoitrexahoi.vn": {
//           "backlinks": 1,
//           "country": "Vietnam",
//           "ip": "210.2.90.34"
//       },
//       "hashnode.com": {
//           "backlinks": 1,
//           "country": "United States",
//           "ip": "76.76.21.21"
//       },
//       "gleadsltd.wixsite.com": {
//           "backlinks": 5,
//           "country": "United States",
//           "ip": "34.117.60.144"
//       },
//       "blogphuot.info": {
//           "backlinks": 1,
//           "country": "Vietnam",
//           "ip": "103.9.156.104"
//       },
//       "nuongthom.net": {
//           "backlinks": 1,
//           "country": "Vietnam",
//           "ip": "103.9.157.232"
//       },
//       "xehay.com.vn": {
//           "backlinks": 1,
//           "country": "Vietnam",
//           "ip": "103.27.237.176"
//       },
//       "xevespa.vn": {
//           "backlinks": 1,
//           "country": "Vietnam",
//           "ip": "103.27.237.162"
//       },
//       "gleadsvn.blogspot.com": {
//           "backlinks": 5,
//           "country": "United States",
//           "ip": "142.250.186.33"
//       },
//       "blog.donghoviet.vn": {
//           "backlinks": 1,
//           "country": "Vietnam",
//           "ip": "103.27.237.134"
//       },
//       "minhview.com": {
//           "backlinks": 1,
//           "country": "United States",
//           "ip": "172.67.141.219"
//       },
//       "feel.vn": {
//           "backlinks": 1,
//           "country": "Vietnam",
//           "ip": "103.27.237.146"
//       },
//       "vietkieu.com.vn": {
//           "backlinks": 1,
//           "country": "Vietnam",
//           "ip": "103.27.237.136"
//       }
//   },
//   "semantic_loc": {
//       "null": 46,
//       "article": 37,
//       "section": 7,
//       "header": 4,
//       "main": 1,
//       "aside": 5
//   },
//   "items": [
//       {
//           "type": "backlink",
//           "domain_from": "all4webs.com",
//           "url_from": "https://all4webs.com/gleadsvn/home.htm",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "com",
//           "is_new": false,
//           "is_lost": false,
//           "backlink_spam_score": 35,
//           "rank": 113,
//           "page_from_rank": 0,
//           "domain_from_rank": 456,
//           "domain_from_platform_type": [
//               "unknown"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "104.21.235.187",
//           "domain_from_country": null,
//           "page_from_external_links": 1,
//           "page_from_internal_links": 35,
//           "page_from_size": 9272,
//           "page_from_encoding": "utf-8",
//           "page_from_language": null,
//           "page_from_title": "Blog Chia Sẽ Gleads",
//           "page_from_status_code": 200,
//           "first_seen": "2023-10-25 05:13:34 +00:00",
//           "prev_seen": "2023-10-25 05:13:34 +00:00",
//           "last_seen": "2023-12-21 21:57:23 +00:00",
//           "item_type": "anchor",
//           "attributes": null,
//           "dofollow": true,
//           "original": false,
//           "alt": null,
//           "image_url": null,
//           "anchor": "https://gleads.vn/vi",
//           "text_pre": null,
//           "text_post": null,
//           "semantic_location": null,
//           "links_count": 1,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "chillspot1.com",
//           "url_from": "https://chillspot1.com/user/Gleadsvn",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "com",
//           "is_new": false,
//           "is_lost": false,
//           "backlink_spam_score": 35,
//           "rank": 113,
//           "page_from_rank": 0,
//           "domain_from_rank": 268,
//           "domain_from_platform_type": [
//               "unknown"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "69.4.80.171",
//           "domain_from_country": null,
//           "page_from_external_links": 1,
//           "page_from_internal_links": 15,
//           "page_from_size": 24387,
//           "page_from_encoding": "utf-8",
//           "page_from_language": "en",
//           "page_from_title": "ChillSpot1 - Gleadsvn's Channel",
//           "page_from_status_code": 200,
//           "first_seen": "2023-01-16 00:11:59 +00:00",
//           "prev_seen": "2023-02-23 20:36:20 +00:00",
//           "last_seen": "2023-05-07 00:22:45 +00:00",
//           "item_type": "anchor",
//           "attributes": null,
//           "dofollow": true,
//           "original": false,
//           "alt": null,
//           "image_url": null,
//           "anchor": "https://gleads.vn/vi",
//           "text_pre": null,
//           "text_post": null,
//           "semantic_location": null,
//           "links_count": 2,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "taiungdung.vn",
//           "url_from": "https://taiungdung.vn/thiet-ke-website-bat-dong-san-chuyen-nghiep/",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "vn",
//           "is_new": false,
//           "is_lost": false,
//           "backlink_spam_score": 5,
//           "rank": 78,
//           "page_from_rank": 0,
//           "domain_from_rank": 99,
//           "domain_from_platform_type": [
//               "cms",
//               "blogs"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "103.110.84.51",
//           "domain_from_country": "VN",
//           "page_from_external_links": 2,
//           "page_from_internal_links": 22,
//           "page_from_size": 90578,
//           "page_from_encoding": "utf-8",
//           "page_from_language": "vi",
//           "page_from_title": "Tải Thiết kế website bất động sản chuyên nghiệp - Trong giới thiết kế web cho bất động sản, Gleads tự hào luôn là điểm lựa chọn tin tưởng của doanh nghiệp. Dịch vụ tại Gleads luôn đem đến cho khách hàng những giải pháp chuyên nghiệp và hiện đại nhất. Với đội ngũ thiết kế chuyên nghiệp và kinh nghiệm lâu năm, chúng tôi sẽ giúp doanh nghiệp tạo ra một trang web chuyên nghiệp và thu hút khách hàng một cách hiệu quả nhất.",
//           "page_from_status_code": 200,
//           "first_seen": "2023-04-02 23:19:34 +00:00",
//           "prev_seen": "2023-12-17 01:36:57 +00:00",
//           "last_seen": "2024-02-18 12:57:41 +00:00",
//           "item_type": "anchor",
//           "attributes": [
//               "noopener"
//           ],
//           "dofollow": true,
//           "original": false,
//           "alt": null,
//           "image_url": null,
//           "anchor": "Gleads",
//           "text_pre": null,
//           "text_post": null,
//           "semantic_location": null,
//           "links_count": 1,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "blogtranphu.com",
//           "url_from": "https://blogtranphu.com/thiet-ke-trang-web-thuong-mai-dien-tu/",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "com",
//           "is_new": false,
//           "is_lost": false,
//           "backlink_spam_score": 0,
//           "rank": 78,
//           "page_from_rank": 0,
//           "domain_from_rank": 181,
//           "domain_from_platform_type": [
//               "cms",
//               "blogs",
//               "organization"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "103.77.162.11",
//           "domain_from_country": null,
//           "page_from_external_links": 7,
//           "page_from_internal_links": 30,
//           "page_from_size": 93186,
//           "page_from_encoding": "utf-8",
//           "page_from_language": "vi",
//           "page_from_title": "Thiết kế trang web thương mại điện tử",
//           "page_from_status_code": 200,
//           "first_seen": "2023-03-30 20:59:20 +00:00",
//           "prev_seen": "2024-02-09 09:28:43 +00:00",
//           "last_seen": "2024-02-17 01:09:33 +00:00",
//           "item_type": "anchor",
//           "attributes": null,
//           "dofollow": true,
//           "original": false,
//           "alt": null,
//           "image_url": null,
//           "anchor": "Gleads",
//           "text_pre": null,
//           "text_post": null,
//           "semantic_location": "article",
//           "links_count": 1,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "reviewphim.net",
//           "url_from": "https://reviewphim.net/thiet-ke-website-wordpress-gia-re-cho-cac-trang-phim/",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "net",
//           "is_new": false,
//           "is_lost": false,
//           "backlink_spam_score": 5,
//           "rank": 78,
//           "page_from_rank": 0,
//           "domain_from_rank": 197,
//           "domain_from_platform_type": [
//               "cms",
//               "blogs"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "103.27.237.162",
//           "domain_from_country": null,
//           "page_from_external_links": 5,
//           "page_from_internal_links": 39,
//           "page_from_size": 150548,
//           "page_from_encoding": "utf-8",
//           "page_from_language": "vi",
//           "page_from_title": "Thiết kế website wordpress giá rẻ cho các trang phim | Review Phim",
//           "page_from_status_code": 200,
//           "first_seen": "2023-04-05 16:23:42 +00:00",
//           "prev_seen": "2023-12-15 11:38:58 +00:00",
//           "last_seen": "2024-02-15 07:32:33 +00:00",
//           "item_type": "anchor",
//           "attributes": [
//               "noopener"
//           ],
//           "dofollow": true,
//           "original": false,
//           "alt": null,
//           "image_url": null,
//           "anchor": "https://gleads.vn/vi",
//           "text_pre": null,
//           "text_post": null,
//           "semantic_location": null,
//           "links_count": 1,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "vemaybays.vn",
//           "url_from": "https://vemaybays.vn/thiet-ke-web-ban-ve-may-bay/",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "vn",
//           "is_new": false,
//           "is_lost": false,
//           "backlink_spam_score": 0,
//           "rank": 78,
//           "page_from_rank": 0,
//           "domain_from_rank": 20,
//           "domain_from_platform_type": [
//               "cms",
//               "blogs",
//               "organization"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "103.27.237.160",
//           "domain_from_country": "VN",
//           "page_from_external_links": 2,
//           "page_from_internal_links": 26,
//           "page_from_size": 172774,
//           "page_from_encoding": "utf-8",
//           "page_from_language": "vi",
//           "page_from_title": "Thiết kế web bán vé máy bay - Vemaybays.vn",
//           "page_from_status_code": 200,
//           "first_seen": "2023-04-08 23:27:18 +00:00",
//           "prev_seen": "2023-12-17 01:06:00 +00:00",
//           "last_seen": "2024-02-15 07:08:14 +00:00",
//           "item_type": "anchor",
//           "attributes": [
//               "noopener"
//           ],
//           "dofollow": true,
//           "original": false,
//           "alt": null,
//           "image_url": null,
//           "anchor": "Gleads",
//           "text_pre": null,
//           "text_post": null,
//           "semantic_location": null,
//           "links_count": 1,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "blogsofts.net",
//           "url_from": "https://blogsofts.net/thiet-ke-website-chi-phi-thiet-ke-canh-tranh-nhat/",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "net",
//           "is_new": false,
//           "is_lost": false,
//           "backlink_spam_score": 0,
//           "rank": 78,
//           "page_from_rank": 0,
//           "domain_from_rank": 316,
//           "domain_from_platform_type": [
//               "cms",
//               "blogs"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "188.114.96.3",
//           "domain_from_country": "VN",
//           "page_from_external_links": 6,
//           "page_from_internal_links": 30,
//           "page_from_size": 90499,
//           "page_from_encoding": "utf-8",
//           "page_from_language": "vi",
//           "page_from_title": "Thiết kế website - chi phí thiết kế cạnh tranh nhất - Blog Soft",
//           "page_from_status_code": 200,
//           "first_seen": "2023-11-02 11:47:07 +00:00",
//           "prev_seen": "2023-12-12 01:44:28 +00:00",
//           "last_seen": "2024-02-14 02:01:40 +00:00",
//           "item_type": "anchor",
//           "attributes": [
//               "noopener"
//           ],
//           "dofollow": true,
//           "original": false,
//           "alt": null,
//           "image_url": null,
//           "anchor": "Gleads",
//           "text_pre": null,
//           "text_post": null,
//           "semantic_location": "article",
//           "links_count": 1,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "xaongon.net",
//           "url_from": "https://xaongon.net/qc/nhan-thiet-ke-website-wordpress-cho-nganh-suc-khoe/",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "net",
//           "is_new": false,
//           "is_lost": false,
//           "backlink_spam_score": 0,
//           "rank": 78,
//           "page_from_rank": 0,
//           "domain_from_rank": 262,
//           "domain_from_platform_type": [
//               "organization"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "103.9.156.112",
//           "domain_from_country": null,
//           "page_from_external_links": 13,
//           "page_from_internal_links": 13,
//           "page_from_size": 78053,
//           "page_from_encoding": "utf-8",
//           "page_from_language": "vi",
//           "page_from_title": "Nhận thiết kế website wordpress cho ngành sức khỏe",
//           "page_from_status_code": 200,
//           "first_seen": "2023-03-30 03:38:48 +00:00",
//           "prev_seen": "2023-10-04 22:25:08 +00:00",
//           "last_seen": "2024-02-11 03:16:46 +00:00",
//           "item_type": "anchor",
//           "attributes": [
//               "noopener"
//           ],
//           "dofollow": true,
//           "original": false,
//           "alt": null,
//           "image_url": null,
//           "anchor": "Gleads",
//           "text_pre": "Thiết kế website chuyên nghiệp:",
//           "text_post": "sẽ tạo ra một thiết kế website đẹp và chuyên nghiệp, phù hợp với nhu cầu và yêu cầu của quý doanh nghiệp.",
//           "semantic_location": "article",
//           "links_count": 1,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "aiti-aptech.edu.vn",
//           "url_from": "https://aiti-aptech.edu.vn/chay-quang-cao-google-adwords-gia-re-cho-da-nganh-nghe/",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "edu.vn",
//           "is_new": false,
//           "is_lost": false,
//           "backlink_spam_score": 0,
//           "rank": 78,
//           "page_from_rank": 0,
//           "domain_from_rank": 138,
//           "domain_from_platform_type": [
//               "cms",
//               "blogs"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "103.57.223.17",
//           "domain_from_country": "VN",
//           "page_from_external_links": 2,
//           "page_from_internal_links": 11,
//           "page_from_size": 50702,
//           "page_from_encoding": "utf-8",
//           "page_from_language": "en",
//           "page_from_title": "Chạy quảng cáo Google Adwords giá rẻ cho đa ngành nghề - AiTi - Aptech",
//           "page_from_status_code": 200,
//           "first_seen": "2023-05-03 19:46:50 +00:00",
//           "prev_seen": "2023-10-04 14:01:31 +00:00",
//           "last_seen": "2024-02-09 23:52:16 +00:00",
//           "item_type": "anchor",
//           "attributes": null,
//           "dofollow": true,
//           "original": false,
//           "alt": null,
//           "image_url": null,
//           "anchor": "Gleads",
//           "text_pre": null,
//           "text_post": "sẽ dựa trên những yêu cầu của doanh nghiệp về dịch vụ quảng cáo Google Adword cũng như loại hình kinh doanh, đối thủ cạnh tranh và các yếu tố khác để đưa ra giải pháp thích hợp nhất cho bạn. Nếu bạn cần",
//           "semantic_location": "article",
//           "links_count": 1,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "f-store.com.vn",
//           "url_from": "https://f-store.com.vn/thiet-ke-website-bao-ve-dat-chuan-digital-marketing/",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "com.vn",
//           "is_new": false,
//           "is_lost": false,
//           "backlink_spam_score": 0,
//           "rank": 78,
//           "page_from_rank": 0,
//           "domain_from_rank": 83,
//           "domain_from_platform_type": [
//               "cms",
//               "blogs"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "172.67.197.10",
//           "domain_from_country": "VN",
//           "page_from_external_links": 6,
//           "page_from_internal_links": 19,
//           "page_from_size": 83378,
//           "page_from_encoding": "utf-8",
//           "page_from_language": "vi",
//           "page_from_title": "Thiết kế website bảo vệ đạt chuẩn Digital Marketing",
//           "page_from_status_code": 200,
//           "first_seen": "2023-05-20 16:04:41 +00:00",
//           "prev_seen": "2023-12-01 02:40:55 +00:00",
//           "last_seen": "2024-02-03 18:45:14 +00:00",
//           "item_type": "anchor",
//           "attributes": [
//               "noopener"
//           ],
//           "dofollow": true,
//           "original": false,
//           "alt": null,
//           "image_url": null,
//           "anchor": "Công ty TNHH Gleads",
//           "text_pre": null,
//           "text_post": null,
//           "semantic_location": "article",
//           "links_count": 1,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "topgoogleaz.com",
//           "url_from": "https://topgoogleaz.com/thiet-ke-web-theo-yeu-cau-gia-re/",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "com",
//           "is_new": false,
//           "is_lost": false,
//           "backlink_spam_score": 0,
//           "rank": 78,
//           "page_from_rank": 0,
//           "domain_from_rank": 143,
//           "domain_from_platform_type": [
//               "cms",
//               "blogs",
//               "organization"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "103.77.162.21",
//           "domain_from_country": null,
//           "page_from_external_links": 7,
//           "page_from_internal_links": 38,
//           "page_from_size": 96626,
//           "page_from_encoding": "utf-8",
//           "page_from_language": "vi",
//           "page_from_title": "Thiết kế web theo yêu cầu giá rẻ cho đa lĩnh vực",
//           "page_from_status_code": 200,
//           "first_seen": "2023-09-25 08:50:07 +00:00",
//           "prev_seen": "2023-12-10 09:29:44 +00:00",
//           "last_seen": "2024-02-02 19:42:17 +00:00",
//           "item_type": "anchor",
//           "attributes": null,
//           "dofollow": true,
//           "original": false,
//           "alt": null,
//           "image_url": null,
//           "anchor": "Gleads",
//           "text_pre": null,
//           "text_post": null,
//           "semantic_location": "article",
//           "links_count": 1,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "topdanhgiaaz.com",
//           "url_from": "https://topdanhgiaaz.com/thiet-ke-web-noi-that/",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "com",
//           "is_new": false,
//           "is_lost": false,
//           "backlink_spam_score": 0,
//           "rank": 78,
//           "page_from_rank": 0,
//           "domain_from_rank": 146,
//           "domain_from_platform_type": [
//               "cms",
//               "blogs",
//               "organization"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "103.77.162.23",
//           "domain_from_country": null,
//           "page_from_external_links": 7,
//           "page_from_internal_links": 23,
//           "page_from_size": 80859,
//           "page_from_encoding": "utf-8",
//           "page_from_language": "vi",
//           "page_from_title": "Thiết kế web nội thất tích hợp quản lý sản phẩm và kho",
//           "page_from_status_code": 200,
//           "first_seen": "2023-10-14 16:18:22 +00:00",
//           "prev_seen": "2023-11-22 14:09:28 +00:00",
//           "last_seen": "2024-01-31 15:24:15 +00:00",
//           "item_type": "anchor",
//           "attributes": null,
//           "dofollow": true,
//           "original": false,
//           "alt": null,
//           "image_url": null,
//           "anchor": "Gleads",
//           "text_pre": "Công ty TNHH",
//           "text_post": null,
//           "semantic_location": "article",
//           "links_count": 1,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "entheomedia.org",
//           "url_from": "https://entheomedia.org/website-theo-yeu-cau-de-day-hoc-online-chuyen-sau/",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "org",
//           "is_new": false,
//           "is_lost": false,
//           "backlink_spam_score": 0,
//           "rank": 78,
//           "page_from_rank": 0,
//           "domain_from_rank": 139,
//           "domain_from_platform_type": [
//               "cms",
//               "blogs",
//               "organization"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "188.114.96.3",
//           "domain_from_country": null,
//           "page_from_external_links": 5,
//           "page_from_internal_links": 24,
//           "page_from_size": 126367,
//           "page_from_encoding": "utf-8",
//           "page_from_language": "vi",
//           "page_from_title": "Website theo yêu cầu để dạy học online chuyên sâu - Entheomedia",
//           "page_from_status_code": 200,
//           "first_seen": "2023-11-30 16:27:04 +00:00",
//           "prev_seen": "2023-11-30 16:27:04 +00:00",
//           "last_seen": "2024-01-31 14:02:16 +00:00",
//           "item_type": "anchor",
//           "attributes": [
//               "noopener"
//           ],
//           "dofollow": true,
//           "original": false,
//           "alt": null,
//           "image_url": null,
//           "anchor": "https://gleads.vn/vi",
//           "text_pre": null,
//           "text_post": null,
//           "semantic_location": null,
//           "links_count": 1,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "internetmarketing.vn",
//           "url_from": "https://internetmarketing.vn/dich-vu-chay-google-ads-toi-uu-tren-tung-click-chuot/",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "vn",
//           "is_new": false,
//           "is_lost": false,
//           "backlink_spam_score": 0,
//           "rank": 78,
//           "page_from_rank": 0,
//           "domain_from_rank": 187,
//           "domain_from_platform_type": [
//               "cms",
//               "blogs",
//               "organization"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "103.27.237.156",
//           "domain_from_country": "VN",
//           "page_from_external_links": 4,
//           "page_from_internal_links": 30,
//           "page_from_size": 112766,
//           "page_from_encoding": "utf-8",
//           "page_from_language": "vi",
//           "page_from_title": "Dịch vụ chạy Google Ads tối ưu trên từng click chuột - Internet Marketing",
//           "page_from_status_code": 200,
//           "first_seen": "2023-04-08 16:16:15 +00:00",
//           "prev_seen": "2023-11-21 16:07:38 +00:00",
//           "last_seen": "2024-01-28 16:38:09 +00:00",
//           "item_type": "anchor",
//           "attributes": [
//               "noopener"
//           ],
//           "dofollow": true,
//           "original": false,
//           "alt": null,
//           "image_url": null,
//           "anchor": "Công ty TNHH Gleads",
//           "text_pre": null,
//           "text_post": null,
//           "semantic_location": null,
//           "links_count": 1,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "elsalvadorhistorico.org",
//           "url_from": "https://elsalvadorhistorico.org/bang-gia-thiet-ke-website-thuong-mai-dien-tu-tu-gleads/",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "org",
//           "is_new": false,
//           "is_lost": false,
//           "backlink_spam_score": 5,
//           "rank": 78,
//           "page_from_rank": 0,
//           "domain_from_rank": 128,
//           "domain_from_platform_type": [
//               "cms",
//               "blogs",
//               "organization"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "45.119.213.82",
//           "domain_from_country": null,
//           "page_from_external_links": 2,
//           "page_from_internal_links": 24,
//           "page_from_size": 149433,
//           "page_from_encoding": "utf-8",
//           "page_from_language": "vi",
//           "page_from_title": "Bảng giá thiết kế website thương mại điện tử từ Gleads",
//           "page_from_status_code": 200,
//           "first_seen": "2023-04-08 04:39:45 +00:00",
//           "prev_seen": "2023-11-15 04:13:04 +00:00",
//           "last_seen": "2024-01-27 00:00:25 +00:00",
//           "item_type": "anchor",
//           "attributes": [
//               "noopener"
//           ],
//           "dofollow": true,
//           "original": false,
//           "alt": null,
//           "image_url": null,
//           "anchor": "Gleads",
//           "text_pre": null,
//           "text_post": null,
//           "semantic_location": null,
//           "links_count": 1,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "sapavietnam.vn",
//           "url_from": "https://sapavietnam.vn/bang-gia-dich-vu-thiet-ke-web-moi-nhat-cua-gleads/",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "vn",
//           "is_new": false,
//           "is_lost": false,
//           "backlink_spam_score": 5,
//           "rank": 78,
//           "page_from_rank": 0,
//           "domain_from_rank": 145,
//           "domain_from_platform_type": [
//               "cms",
//               "blogs",
//               "organization"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "103.27.237.158",
//           "domain_from_country": "VN",
//           "page_from_external_links": 4,
//           "page_from_internal_links": 34,
//           "page_from_size": 150647,
//           "page_from_encoding": "utf-8",
//           "page_from_language": "vi",
//           "page_from_title": "Bảng giá dịch vụ thiết kế web mới nhất của Gleads - Sapavietnam.vn",
//           "page_from_status_code": 200,
//           "first_seen": "2023-04-06 07:55:07 +00:00",
//           "prev_seen": "2023-11-27 13:43:52 +00:00",
//           "last_seen": "2024-01-26 17:17:32 +00:00",
//           "item_type": "anchor",
//           "attributes": [
//               "noopener"
//           ],
//           "dofollow": true,
//           "original": false,
//           "alt": null,
//           "image_url": null,
//           "anchor": "Công ty Gleads",
//           "text_pre": null,
//           "text_post": null,
//           "semantic_location": null,
//           "links_count": 1,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 1
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "coolpadmobile.vn",
//           "url_from": "https://coolpadmobile.vn/hoi-dap/thiet-ke-website-voi-wordpress-co-loi-ich-gi/",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "vn",
//           "is_new": false,
//           "is_lost": false,
//           "backlink_spam_score": 0,
//           "rank": 78,
//           "page_from_rank": 0,
//           "domain_from_rank": 302,
//           "domain_from_platform_type": [
//               "organization"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "103.9.157.225",
//           "domain_from_country": "VN",
//           "page_from_external_links": 8,
//           "page_from_internal_links": 17,
//           "page_from_size": 84948,
//           "page_from_encoding": "utf-8",
//           "page_from_language": "vi",
//           "page_from_title": "Thiết kế website với wordpress có lợi ích gì?",
//           "page_from_status_code": 200,
//           "first_seen": "2023-04-09 12:03:44 +00:00",
//           "prev_seen": "2023-11-17 00:31:22 +00:00",
//           "last_seen": "2024-01-21 13:36:02 +00:00",
//           "item_type": "anchor",
//           "attributes": [
//               "noopener"
//           ],
//           "dofollow": true,
//           "original": false,
//           "alt": null,
//           "image_url": null,
//           "anchor": "https://gleads.vn/vi",
//           "text_pre": "Phone: +8428 6272 2262 (Sales) Website:",
//           "text_post": null,
//           "semantic_location": "article",
//           "links_count": 1,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "blogthuatngu.com",
//           "url_from": "https://blogthuatngu.com/cong-ty-thiet-ke-website-ban-hang/",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "com",
//           "is_new": false,
//           "is_lost": false,
//           "backlink_spam_score": 0,
//           "rank": 78,
//           "page_from_rank": 0,
//           "domain_from_rank": 171,
//           "domain_from_platform_type": [
//               "cms",
//               "blogs",
//               "organization"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "112.213.89.130",
//           "domain_from_country": null,
//           "page_from_external_links": 7,
//           "page_from_internal_links": 29,
//           "page_from_size": 87912,
//           "page_from_encoding": "utf-8",
//           "page_from_language": "vi",
//           "page_from_title": "Công ty thiết kế Website bán hàng",
//           "page_from_status_code": 200,
//           "first_seen": "2023-05-16 15:22:22 +00:00",
//           "prev_seen": "2023-11-19 04:10:56 +00:00",
//           "last_seen": "2024-01-15 21:41:40 +00:00",
//           "item_type": "anchor",
//           "attributes": null,
//           "dofollow": true,
//           "original": false,
//           "alt": null,
//           "image_url": null,
//           "anchor": "Gleads",
//           "text_pre": "Công ty TNHH",
//           "text_post": null,
//           "semantic_location": "article",
//           "links_count": 1,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "hosodoanhnhan.vn",
//           "url_from": "https://hosodoanhnhan.vn/quang-cao-tim-kiem-tren-google-la-gi/",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "vn",
//           "is_new": false,
//           "is_lost": false,
//           "backlink_spam_score": 0,
//           "rank": 78,
//           "page_from_rank": 0,
//           "domain_from_rank": 207,
//           "domain_from_platform_type": [
//               "cms",
//               "blogs"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "103.27.237.156",
//           "domain_from_country": "VN",
//           "page_from_external_links": 7,
//           "page_from_internal_links": 27,
//           "page_from_size": 135517,
//           "page_from_encoding": "utf-8",
//           "page_from_language": "en",
//           "page_from_title": "Quảng cáo tìm kiếm trên Google là gì? - Hosodoanhnhan.vn",
//           "page_from_status_code": 200,
//           "first_seen": "2023-03-29 14:33:37 +00:00",
//           "prev_seen": "2023-10-29 15:45:23 +00:00",
//           "last_seen": "2024-01-13 18:20:58 +00:00",
//           "item_type": "anchor",
//           "attributes": null,
//           "dofollow": true,
//           "original": false,
//           "alt": null,
//           "image_url": null,
//           "anchor": "Gleads",
//           "text_pre": null,
//           "text_post": null,
//           "semantic_location": null,
//           "links_count": 1,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "vd-art.vn",
//           "url_from": "https://vd-art.vn/qc/thiet-ke-website-kien-truc.html",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "vn",
//           "is_new": false,
//           "is_lost": false,
//           "backlink_spam_score": 0,
//           "rank": 78,
//           "page_from_rank": 0,
//           "domain_from_rank": 172,
//           "domain_from_platform_type": [
//               "organization"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "103.9.157.218",
//           "domain_from_country": "VN",
//           "page_from_external_links": 12,
//           "page_from_internal_links": 12,
//           "page_from_size": 73900,
//           "page_from_encoding": "utf-8",
//           "page_from_language": "vi",
//           "page_from_title": "Thiết kế website kiến trúc",
//           "page_from_status_code": 200,
//           "first_seen": "2023-04-05 03:08:56 +00:00",
//           "prev_seen": "2023-10-29 15:57:05 +00:00",
//           "last_seen": "2024-01-13 06:20:01 +00:00",
//           "item_type": "anchor",
//           "attributes": [
//               "noopener"
//           ],
//           "dofollow": true,
//           "original": false,
//           "alt": null,
//           "image_url": null,
//           "anchor": "Công ty TNHH Gleads",
//           "text_pre": null,
//           "text_post": null,
//           "semantic_location": "article",
//           "links_count": 1,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "apologize.vn",
//           "url_from": "https://apologize.vn/thiet-ke-web-wordpress-chuyen-sau-tu-gleads/",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "vn",
//           "is_new": false,
//           "is_lost": false,
//           "backlink_spam_score": 0,
//           "rank": 78,
//           "page_from_rank": 0,
//           "domain_from_rank": 27,
//           "domain_from_platform_type": [
//               "cms",
//               "blogs"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "103.75.185.20",
//           "domain_from_country": "VN",
//           "page_from_external_links": 2,
//           "page_from_internal_links": 24,
//           "page_from_size": 130344,
//           "page_from_encoding": "utf-8",
//           "page_from_language": "vi",
//           "page_from_title": "Thiết kế web Wordpress chuyên sâu từ Gleads - Apologiza",
//           "page_from_status_code": 200,
//           "first_seen": "2023-06-18 18:01:38 +00:00",
//           "prev_seen": "2023-11-02 19:19:11 +00:00",
//           "last_seen": "2024-01-09 20:24:45 +00:00",
//           "item_type": "anchor",
//           "attributes": [
//               "noopener"
//           ],
//           "dofollow": true,
//           "original": false,
//           "alt": null,
//           "image_url": null,
//           "anchor": "Gleads",
//           "text_pre": null,
//           "text_post": null,
//           "semantic_location": "article",
//           "links_count": 1,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 1
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "bizday.net",
//           "url_from": "https://bizday.net/quy-trinh-thiet-ke-website-thuong-mai-dien-tu.html",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "net",
//           "is_new": false,
//           "is_lost": false,
//           "backlink_spam_score": 0,
//           "rank": 78,
//           "page_from_rank": 0,
//           "domain_from_rank": 159,
//           "domain_from_platform_type": [
//               "cms"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "103.221.222.96",
//           "domain_from_country": null,
//           "page_from_external_links": 4,
//           "page_from_internal_links": 101,
//           "page_from_size": 113625,
//           "page_from_encoding": "utf-8",
//           "page_from_language": "vi",
//           "page_from_title": "Quy trình thiết kế website thương mại điện tử - Bizday",
//           "page_from_status_code": 200,
//           "first_seen": "2023-04-04 13:46:46 +00:00",
//           "prev_seen": "2023-10-04 18:26:34 +00:00",
//           "last_seen": "2024-01-08 16:59:23 +00:00",
//           "item_type": "anchor",
//           "attributes": [
//               "noopener"
//           ],
//           "dofollow": true,
//           "original": false,
//           "alt": null,
//           "image_url": null,
//           "anchor": "Công ty TNHH Gleads",
//           "text_pre": null,
//           "text_post": null,
//           "semantic_location": "article",
//           "links_count": 1,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "batteryinyourleg.com",
//           "url_from": "https://batteryinyourleg.com/tao-website-tin-tuc-bang-wordpress-nhanh-chong-de-dang-nang-cap/",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "com",
//           "is_new": false,
//           "is_lost": false,
//           "backlink_spam_score": 5,
//           "rank": 78,
//           "page_from_rank": 0,
//           "domain_from_rank": 265,
//           "domain_from_platform_type": [
//               "cms",
//               "blogs",
//               "organization"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "104.21.78.6",
//           "domain_from_country": null,
//           "page_from_external_links": 2,
//           "page_from_internal_links": 21,
//           "page_from_size": 154407,
//           "page_from_encoding": "utf-8",
//           "page_from_language": "vi",
//           "page_from_title": "Tạo website tin tức bằng wordpress nhanh chóng dễ dàng nâng cấp",
//           "page_from_status_code": 200,
//           "first_seen": "2023-04-06 02:57:39 +00:00",
//           "prev_seen": "2023-11-04 08:34:56 +00:00",
//           "last_seen": "2024-01-03 09:42:36 +00:00",
//           "item_type": "anchor",
//           "attributes": [
//               "noopener"
//           ],
//           "dofollow": true,
//           "original": false,
//           "alt": null,
//           "image_url": null,
//           "anchor": "Gleads",
//           "text_pre": null,
//           "text_post": null,
//           "semantic_location": null,
//           "links_count": 1,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "emsayroi.com",
//           "url_from": "https://emsayroi.com/quang-cao-google-shopping-la-gi-ma-doanh-nghiep-ban-le-nao-cung-ung-dung/",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "com",
//           "is_new": false,
//           "is_lost": false,
//           "backlink_spam_score": 0,
//           "rank": 78,
//           "page_from_rank": 0,
//           "domain_from_rank": 65,
//           "domain_from_platform_type": [
//               "cms",
//               "organization"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "188.114.96.3",
//           "domain_from_country": null,
//           "page_from_external_links": 7,
//           "page_from_internal_links": 25,
//           "page_from_size": 95173,
//           "page_from_encoding": "utf-8",
//           "page_from_language": "vi",
//           "page_from_title": "Quảng cáo Google Shopping là gì? Mà doanh nghiệp bán lẻ nào cũng ứng dụng",
//           "page_from_status_code": 200,
//           "first_seen": "2023-10-07 20:16:45 +00:00",
//           "prev_seen": "2023-11-16 18:10:17 +00:00",
//           "last_seen": "2024-01-01 06:52:21 +00:00",
//           "item_type": "anchor",
//           "attributes": [
//               "noopener"
//           ],
//           "dofollow": true,
//           "original": false,
//           "alt": null,
//           "image_url": null,
//           "anchor": "Gleads",
//           "text_pre": null,
//           "text_post": null,
//           "semantic_location": "article",
//           "links_count": 1,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "canhchua.vn",
//           "url_from": "https://canhchua.vn/thiet-ke-website-ban-hang-gia-re/",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "vn",
//           "is_new": false,
//           "is_lost": false,
//           "backlink_spam_score": 0,
//           "rank": 78,
//           "page_from_rank": 0,
//           "domain_from_rank": 134,
//           "domain_from_platform_type": [
//               "cms",
//               "blogs"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "103.57.220.161",
//           "domain_from_country": "VN",
//           "page_from_external_links": 2,
//           "page_from_internal_links": 19,
//           "page_from_size": 98931,
//           "page_from_encoding": "utf-8",
//           "page_from_language": "vi",
//           "page_from_title": "Thiết kế website bán hàng giá rẻ",
//           "page_from_status_code": 200,
//           "first_seen": "2023-05-16 09:44:55 +00:00",
//           "prev_seen": "2023-10-22 09:04:04 +00:00",
//           "last_seen": "2023-12-30 10:37:39 +00:00",
//           "item_type": "anchor",
//           "attributes": null,
//           "dofollow": true,
//           "original": false,
//           "alt": null,
//           "image_url": null,
//           "anchor": "https://gleads.vn/vi",
//           "text_pre": "Công ty TNHH Gleads\nMã số thuế: 0314736707\nPhone: +8428 7305 4898 (HR)\nPhone: +8428 6272 2262 (Sales)\nWebsite:",
//           "text_post": "Email: service@gleads.vn",
//           "semantic_location": "article",
//           "links_count": 1,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "social.vn",
//           "url_from": "https://social.vn/thiet-ke-website-mang-xa-hoi/",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "vn",
//           "is_new": false,
//           "is_lost": false,
//           "backlink_spam_score": 0,
//           "rank": 78,
//           "page_from_rank": 0,
//           "domain_from_rank": 57,
//           "domain_from_platform_type": [
//               "cms",
//               "blogs"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "103.27.237.154",
//           "domain_from_country": "VN",
//           "page_from_external_links": 2,
//           "page_from_internal_links": 20,
//           "page_from_size": 107994,
//           "page_from_encoding": "utf-8",
//           "page_from_language": "vi",
//           "page_from_title": "Thiết kế website mạng xã hội - Social.vn",
//           "page_from_status_code": 200,
//           "first_seen": "2023-04-02 00:07:58 +00:00",
//           "prev_seen": "2023-10-22 09:59:12 +00:00",
//           "last_seen": "2023-12-30 09:56:55 +00:00",
//           "item_type": "anchor",
//           "attributes": [
//               "noopener"
//           ],
//           "dofollow": true,
//           "original": false,
//           "alt": null,
//           "image_url": null,
//           "anchor": "Gleads",
//           "text_pre": "Vì thế, hãy để",
//           "text_post": "là đơn vị tiên phong hỗ trợ doanh nghiệp trong việc tạo lập và vận hành trang web mạng xã hội. Với kinh nghiệm phong phú cùng khả năng tối ưu đỉnh cao, hãy liên hệ với chúng tôi để được tư vấn dịch vụ",
//           "semantic_location": null,
//           "links_count": 1,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "choihoadep.net",
//           "url_from": "https://choihoadep.net/qc/thiet-ke-web-php-la-gi/",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "net",
//           "is_new": false,
//           "is_lost": false,
//           "backlink_spam_score": 0,
//           "rank": 78,
//           "page_from_rank": 0,
//           "domain_from_rank": 228,
//           "domain_from_platform_type": [
//               "organization"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "103.9.156.105",
//           "domain_from_country": null,
//           "page_from_external_links": 10,
//           "page_from_internal_links": 14,
//           "page_from_size": 84580,
//           "page_from_encoding": "utf-8",
//           "page_from_language": "vi",
//           "page_from_title": "Thiết kế web php là gì? Vì sao nên sử dụng platform này?",
//           "page_from_status_code": 200,
//           "first_seen": "2023-04-02 22:05:50 +00:00",
//           "prev_seen": "2023-10-30 02:47:12 +00:00",
//           "last_seen": "2023-12-29 11:22:23 +00:00",
//           "item_type": "anchor",
//           "attributes": [
//               "noopener"
//           ],
//           "dofollow": true,
//           "original": false,
//           "alt": null,
//           "image_url": null,
//           "anchor": "Gleads",
//           "text_pre": "Với nhiều năm kinh nghiệm được tích lũy từ các dự án thiết kế website PHP thành công,",
//           "text_post": "đảm bảo thực hiện các yêu cầu của quý doanh nghiệp một cách tốt nhất. Cam kết hoàn thành dự án đúng hạn với mức giá thiết kế web PHP hợp lý nhất cho doanh nghiệp.",
//           "semantic_location": "article",
//           "links_count": 1,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "chogiasi.com.vn",
//           "url_from": "https://chogiasi.com.vn/thiet-ke-website-wordpress-chuyen-nghiep-tuy-bien-module-nang-cao/",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "com.vn",
//           "is_new": false,
//           "is_lost": false,
//           "backlink_spam_score": 5,
//           "rank": 78,
//           "page_from_rank": 0,
//           "domain_from_rank": 120,
//           "domain_from_platform_type": [
//               "cms",
//               "blogs",
//               "organization"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "103.27.237.145",
//           "domain_from_country": "VN",
//           "page_from_external_links": 4,
//           "page_from_internal_links": 36,
//           "page_from_size": 138074,
//           "page_from_encoding": "utf-8",
//           "page_from_language": "vi",
//           "page_from_title": "Thiết kế website WordPress chuyên nghiệp tùy biến module nâng cao - Chợ giá sỉ",
//           "page_from_status_code": 200,
//           "first_seen": "2023-04-09 23:05:51 +00:00",
//           "prev_seen": "2023-10-29 16:54:07 +00:00",
//           "last_seen": "2023-12-28 20:11:04 +00:00",
//           "item_type": "anchor",
//           "attributes": null,
//           "dofollow": true,
//           "original": false,
//           "alt": null,
//           "image_url": null,
//           "anchor": "Gleads",
//           "text_pre": "Công ty TNHH",
//           "text_post": null,
//           "semantic_location": null,
//           "links_count": 1,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "clubsincrisis.com",
//           "url_from": "https://clubsincrisis.com/thiet-ke-website-trung-tam-anh-van/",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "com",
//           "is_new": false,
//           "is_lost": false,
//           "backlink_spam_score": 0,
//           "rank": 78,
//           "page_from_rank": 0,
//           "domain_from_rank": 161,
//           "domain_from_platform_type": [
//               "cms",
//               "blogs",
//               "organization"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "188.114.96.3",
//           "domain_from_country": null,
//           "page_from_external_links": 8,
//           "page_from_internal_links": 34,
//           "page_from_size": 124030,
//           "page_from_encoding": "utf-8",
//           "page_from_language": "vi",
//           "page_from_title": "Thiết kế website trung tâm anh văn",
//           "page_from_status_code": 200,
//           "first_seen": "2023-11-05 05:03:13 +00:00",
//           "prev_seen": "2023-11-05 05:03:13 +00:00",
//           "last_seen": "2023-12-27 04:48:38 +00:00",
//           "item_type": "anchor",
//           "attributes": [
//               "noopener"
//           ],
//           "dofollow": true,
//           "original": false,
//           "alt": null,
//           "image_url": null,
//           "anchor": "Gleads",
//           "text_pre": null,
//           "text_post": null,
//           "semantic_location": "article",
//           "links_count": 1,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "blogbatdongsan.net",
//           "url_from": "https://blogbatdongsan.net/cong-ty-thiet-ke-website-bat-dong-san-chuyen-sau/",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "net",
//           "is_new": false,
//           "is_lost": false,
//           "backlink_spam_score": 0,
//           "rank": 78,
//           "page_from_rank": 0,
//           "domain_from_rank": 185,
//           "domain_from_platform_type": [
//               "cms",
//               "blogs",
//               "organization"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "103.77.162.23",
//           "domain_from_country": null,
//           "page_from_external_links": 7,
//           "page_from_internal_links": 30,
//           "page_from_size": 95200,
//           "page_from_encoding": "utf-8",
//           "page_from_language": "vi",
//           "page_from_title": "Công ty thiết kế website bất động sản chuyên sâu",
//           "page_from_status_code": 200,
//           "first_seen": "2023-09-25 04:09:38 +00:00",
//           "prev_seen": "2023-12-03 17:14:19 +00:00",
//           "last_seen": "2023-12-24 15:50:23 +00:00",
//           "item_type": "anchor",
//           "attributes": null,
//           "dofollow": true,
//           "original": false,
//           "alt": null,
//           "image_url": null,
//           "anchor": "Công ty TNHH Gleads",
//           "text_pre": null,
//           "text_post": null,
//           "semantic_location": "article",
//           "links_count": 1,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "idiadiem.net",
//           "url_from": "https://idiadiem.net/thiet-ke-website-logistics/",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "net",
//           "is_new": false,
//           "is_lost": false,
//           "backlink_spam_score": 0,
//           "rank": 78,
//           "page_from_rank": 0,
//           "domain_from_rank": 55,
//           "domain_from_platform_type": [
//               "cms",
//               "blogs",
//               "organization"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "103.77.162.23",
//           "domain_from_country": null,
//           "page_from_external_links": 3,
//           "page_from_internal_links": 27,
//           "page_from_size": 93846,
//           "page_from_encoding": "utf-8",
//           "page_from_language": "vi",
//           "page_from_title": "Thiết kế website logistics hỗ trợ điều phối và tracking xe",
//           "page_from_status_code": 200,
//           "first_seen": "2023-10-17 04:38:03 +00:00",
//           "prev_seen": "2023-10-17 04:38:03 +00:00",
//           "last_seen": "2023-12-23 18:30:33 +00:00",
//           "item_type": "anchor",
//           "attributes": null,
//           "dofollow": true,
//           "original": false,
//           "alt": null,
//           "image_url": null,
//           "anchor": "https://gleads.vn/vi",
//           "text_pre": "Website:",
//           "text_post": null,
//           "semantic_location": "article",
//           "links_count": 1,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "remove.vn",
//           "url_from": "https://remove.vn/thiet-ke-web-chuan-seo-gia-re/",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "vn",
//           "is_new": false,
//           "is_lost": false,
//           "backlink_spam_score": 0,
//           "rank": 78,
//           "page_from_rank": 0,
//           "domain_from_rank": 181,
//           "domain_from_platform_type": [
//               "cms",
//               "blogs"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "103.27.237.153",
//           "domain_from_country": "VN",
//           "page_from_external_links": 2,
//           "page_from_internal_links": 18,
//           "page_from_size": 95347,
//           "page_from_encoding": "utf-8",
//           "page_from_language": "vi",
//           "page_from_title": "Thiết kế web chuẩn SEO giá rẻ - Remove.vn",
//           "page_from_status_code": 200,
//           "first_seen": "2023-04-06 09:28:08 +00:00",
//           "prev_seen": "2023-10-08 21:13:05 +00:00",
//           "last_seen": "2023-12-23 03:17:29 +00:00",
//           "item_type": "anchor",
//           "attributes": [
//               "noopener"
//           ],
//           "dofollow": true,
//           "original": false,
//           "alt": null,
//           "image_url": null,
//           "anchor": "https://gleads.vn/vi",
//           "text_pre": null,
//           "text_post": null,
//           "semantic_location": null,
//           "links_count": 1,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "gitlab.com.vn",
//           "url_from": "https://gitlab.com.vn/thiet-ke-web-ban-hang-chuan-seo/",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "com.vn",
//           "is_new": false,
//           "is_lost": false,
//           "backlink_spam_score": 0,
//           "rank": 78,
//           "page_from_rank": 0,
//           "domain_from_rank": 67,
//           "domain_from_platform_type": [
//               "cms",
//               "organization"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "172.67.132.12",
//           "domain_from_country": "VN",
//           "page_from_external_links": 8,
//           "page_from_internal_links": 22,
//           "page_from_size": 86505,
//           "page_from_encoding": "utf-8",
//           "page_from_language": "vi",
//           "page_from_title": "Thiết kế web bán hàng chuẩn SEO",
//           "page_from_status_code": 200,
//           "first_seen": "2023-06-20 11:22:09 +00:00",
//           "prev_seen": "2023-10-18 18:41:30 +00:00",
//           "last_seen": "2023-12-23 00:39:37 +00:00",
//           "item_type": "anchor",
//           "attributes": [
//               "noopener"
//           ],
//           "dofollow": true,
//           "original": false,
//           "alt": null,
//           "image_url": null,
//           "anchor": "Gleads",
//           "text_pre": null,
//           "text_post": null,
//           "semantic_location": "article",
//           "links_count": 1,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "all4webs.com",
//           "url_from": "https://all4webs.com/gleadsvn/dich-vu-seo-tphcm.htm",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "com",
//           "is_new": false,
//           "is_lost": false,
//           "backlink_spam_score": 25,
//           "rank": 78,
//           "page_from_rank": 0,
//           "domain_from_rank": 456,
//           "domain_from_platform_type": [
//               "unknown"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "104.21.235.187",
//           "domain_from_country": null,
//           "page_from_external_links": 2,
//           "page_from_internal_links": 35,
//           "page_from_size": 18486,
//           "page_from_encoding": "utf-8",
//           "page_from_language": null,
//           "page_from_title": "Tối ưu h�a từ kh�a v� nội dung trong dịch vụ SEO TP HCM",
//           "page_from_status_code": 200,
//           "first_seen": "2023-10-25 05:14:34 +00:00",
//           "prev_seen": "2023-10-25 05:14:34 +00:00",
//           "last_seen": "2023-12-21 21:58:29 +00:00",
//           "item_type": "anchor",
//           "attributes": null,
//           "dofollow": true,
//           "original": false,
//           "alt": null,
//           "image_url": null,
//           "anchor": "Gleads",
//           "text_pre": " ",
//           "text_post": null,
//           "semantic_location": null,
//           "links_count": 1,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "all4webs.com",
//           "url_from": "https://all4webs.com/gleadsvn/seo-mu-trang.htm",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "com",
//           "is_new": false,
//           "is_lost": false,
//           "backlink_spam_score": 35,
//           "rank": 78,
//           "page_from_rank": 0,
//           "domain_from_rank": 456,
//           "domain_from_platform_type": [
//               "unknown"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "104.21.235.187",
//           "domain_from_country": null,
//           "page_from_external_links": 2,
//           "page_from_internal_links": 35,
//           "page_from_size": 18296,
//           "page_from_encoding": "windows-1258",
//           "page_from_language": null,
//           "page_from_title": "Làm thế nào để tối ưu hóa cho t́m kiếm địa phương trong SEO Mũ Trắng",
//           "page_from_status_code": 200,
//           "first_seen": "2023-10-25 05:14:32 +00:00",
//           "prev_seen": "2023-10-25 05:14:32 +00:00",
//           "last_seen": "2023-12-21 21:58:27 +00:00",
//           "item_type": "anchor",
//           "attributes": null,
//           "dofollow": true,
//           "original": false,
//           "alt": null,
//           "image_url": null,
//           "anchor": "Gleads",
//           "text_pre": " ",
//           "text_post": null,
//           "semantic_location": null,
//           "links_count": 1,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "all4webs.com",
//           "url_from": "https://all4webs.com/gleadsvn/ccht7841oqu7843ngcos.htm",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "com",
//           "is_new": false,
//           "is_lost": false,
//           "backlink_spam_score": 35,
//           "rank": 78,
//           "page_from_rank": 0,
//           "domain_from_rank": 456,
//           "domain_from_platform_type": [
//               "unknown"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "104.21.235.187",
//           "domain_from_country": null,
//           "page_from_external_links": 2,
//           "page_from_internal_links": 35,
//           "page_from_size": 19069,
//           "page_from_encoding": "utf-8",
//           "page_from_language": null,
//           "page_from_title": "Quảng c�o shoppable tr�n YouTube l� một c�ng cụ mạnh mẽ để kết hợp giữa video quảng c",
//           "page_from_status_code": 200,
//           "first_seen": "2023-10-25 05:14:29 +00:00",
//           "prev_seen": "2023-10-25 05:14:29 +00:00",
//           "last_seen": "2023-12-21 21:58:25 +00:00",
//           "item_type": "anchor",
//           "attributes": null,
//           "dofollow": true,
//           "original": false,
//           "alt": null,
//           "image_url": null,
//           "anchor": "Gleads",
//           "text_pre": " ",
//           "text_post": null,
//           "semantic_location": null,
//           "links_count": 1,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "all4webs.com",
//           "url_from": "https://all4webs.com/gleadsvn/quang-cao-facebook.htm",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "com",
//           "is_new": false,
//           "is_lost": false,
//           "backlink_spam_score": 35,
//           "rank": 78,
//           "page_from_rank": 0,
//           "domain_from_rank": 456,
//           "domain_from_platform_type": [
//               "unknown"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "104.21.235.187",
//           "domain_from_country": null,
//           "page_from_external_links": 2,
//           "page_from_internal_links": 35,
//           "page_from_size": 19501,
//           "page_from_encoding": "utf-8",
//           "page_from_language": null,
//           "page_from_title": "Sử dụng quảng c�o b�i viết Facebook để x�y dựng danh s�ch email kh�ch h�ng tiềm năng",
//           "page_from_status_code": 200,
//           "first_seen": "2023-10-25 05:14:27 +00:00",
//           "prev_seen": "2023-10-25 05:14:27 +00:00",
//           "last_seen": "2023-12-21 21:58:22 +00:00",
//           "item_type": "anchor",
//           "attributes": null,
//           "dofollow": true,
//           "original": false,
//           "alt": null,
//           "image_url": null,
//           "anchor": "Gleads",
//           "text_pre": " ",
//           "text_post": null,
//           "semantic_location": null,
//           "links_count": 1,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "all4webs.com",
//           "url_from": "https://all4webs.com/gleadsvn/qu7843ngcaovideofb.htm",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "com",
//           "is_new": false,
//           "is_lost": false,
//           "backlink_spam_score": 25,
//           "rank": 78,
//           "page_from_rank": 0,
//           "domain_from_rank": 456,
//           "domain_from_platform_type": [
//               "unknown"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "104.21.235.187",
//           "domain_from_country": null,
//           "page_from_external_links": 2,
//           "page_from_internal_links": 35,
//           "page_from_size": 15829,
//           "page_from_encoding": "utf-8",
//           "page_from_language": null,
//           "page_from_title": "Tối ưu thời lượng video quảng c�o Facebook",
//           "page_from_status_code": 200,
//           "first_seen": "2023-10-25 05:14:25 +00:00",
//           "prev_seen": "2023-10-25 05:14:25 +00:00",
//           "last_seen": "2023-12-21 21:58:20 +00:00",
//           "item_type": "anchor",
//           "attributes": null,
//           "dofollow": true,
//           "original": false,
//           "alt": null,
//           "image_url": null,
//           "anchor": "Gleads",
//           "text_pre": " ",
//           "text_post": null,
//           "semantic_location": null,
//           "links_count": 1,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "all4webs.com",
//           "url_from": "https://all4webs.com/gleadsvn/thi7871tk7871-website4.htm",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "com",
//           "is_new": false,
//           "is_lost": false,
//           "backlink_spam_score": 25,
//           "rank": 78,
//           "page_from_rank": 0,
//           "domain_from_rank": 456,
//           "domain_from_platform_type": [
//               "unknown"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "104.21.235.187",
//           "domain_from_country": null,
//           "page_from_external_links": 2,
//           "page_from_internal_links": 35,
//           "page_from_size": 19227,
//           "page_from_encoding": "utf-8",
//           "page_from_language": null,
//           "page_from_title": "Tối ưu h�a Cấu tr�c v� Trải nghiệm Người d�ng của Website",
//           "page_from_status_code": 200,
//           "first_seen": "2023-10-25 05:14:21 +00:00",
//           "prev_seen": "2023-10-25 05:14:21 +00:00",
//           "last_seen": "2023-12-21 21:58:15 +00:00",
//           "item_type": "anchor",
//           "attributes": null,
//           "dofollow": true,
//           "original": false,
//           "alt": null,
//           "image_url": null,
//           "anchor": "Gleads",
//           "text_pre": " ",
//           "text_post": null,
//           "semantic_location": null,
//           "links_count": 1,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "all4webs.com",
//           "url_from": "https://all4webs.com/gleadsvn/thiet-ke-logo.htm",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "com",
//           "is_new": false,
//           "is_lost": false,
//           "backlink_spam_score": 25,
//           "rank": 78,
//           "page_from_rank": 0,
//           "domain_from_rank": 456,
//           "domain_from_platform_type": [
//               "unknown"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "104.21.235.187",
//           "domain_from_country": null,
//           "page_from_external_links": 2,
//           "page_from_internal_links": 35,
//           "page_from_size": 16766,
//           "page_from_encoding": "utf-8",
//           "page_from_language": null,
//           "page_from_title": "C�ch Thiết Kế Logo với Sự Phản �nh Văn H�a v� Địa Điểm",
//           "page_from_status_code": 200,
//           "first_seen": "2023-10-25 05:14:19 +00:00",
//           "prev_seen": "2023-10-25 05:14:19 +00:00",
//           "last_seen": "2023-12-21 21:58:13 +00:00",
//           "item_type": "anchor",
//           "attributes": null,
//           "dofollow": true,
//           "original": false,
//           "alt": null,
//           "image_url": null,
//           "anchor": "Gleads",
//           "text_pre": " ",
//           "text_post": null,
//           "semantic_location": null,
//           "links_count": 1,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "all4webs.com",
//           "url_from": "https://all4webs.com/gleadsvn/thi7871tk7871-website.htm",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "com",
//           "is_new": false,
//           "is_lost": false,
//           "backlink_spam_score": 25,
//           "rank": 78,
//           "page_from_rank": 0,
//           "domain_from_rank": 456,
//           "domain_from_platform_type": [
//               "unknown"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "104.21.235.187",
//           "domain_from_country": null,
//           "page_from_external_links": 2,
//           "page_from_internal_links": 34,
//           "page_from_size": 15446,
//           "page_from_encoding": "utf-8",
//           "page_from_language": null,
//           "page_from_title": "Cấu H�nh WooCommerce: Hướng Dẫn Sử Dụng v� C�i Đặt Cơ Bản",
//           "page_from_status_code": 200,
//           "first_seen": "2023-10-25 05:14:17 +00:00",
//           "prev_seen": "2023-10-25 05:14:17 +00:00",
//           "last_seen": "2023-12-21 21:58:10 +00:00",
//           "item_type": "anchor",
//           "attributes": null,
//           "dofollow": true,
//           "original": false,
//           "alt": null,
//           "image_url": null,
//           "anchor": "Gleads",
//           "text_pre": " ",
//           "text_post": null,
//           "semantic_location": null,
//           "links_count": 1,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "all4webs.com",
//           "url_from": "https://all4webs.com/gleadsvn/seo-tu-khoa.htm",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "com",
//           "is_new": false,
//           "is_lost": false,
//           "backlink_spam_score": 35,
//           "rank": 78,
//           "page_from_rank": 0,
//           "domain_from_rank": 456,
//           "domain_from_platform_type": [
//               "unknown"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "104.21.235.187",
//           "domain_from_country": null,
//           "page_from_external_links": 2,
//           "page_from_internal_links": 35,
//           "page_from_size": 17045,
//           "page_from_encoding": "windows-1258",
//           "page_from_language": null,
//           "page_from_title": "Tầm quan trọng của theo dơi và đánh giá kết quả Dịch vụ SEO từ khóa",
//           "page_from_status_code": 200,
//           "first_seen": "2023-10-25 05:14:15 +00:00",
//           "prev_seen": "2023-10-25 05:14:15 +00:00",
//           "last_seen": "2023-12-21 21:58:08 +00:00",
//           "item_type": "anchor",
//           "attributes": null,
//           "dofollow": true,
//           "original": false,
//           "alt": null,
//           "image_url": null,
//           "anchor": "Gleads",
//           "text_pre": " ",
//           "text_post": null,
//           "semantic_location": null,
//           "links_count": 1,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "all4webs.com",
//           "url_from": "https://all4webs.com/gleadsvn/quang-cao-youtube.htm",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "com",
//           "is_new": false,
//           "is_lost": false,
//           "backlink_spam_score": 25,
//           "rank": 78,
//           "page_from_rank": 0,
//           "domain_from_rank": 456,
//           "domain_from_platform_type": [
//               "unknown"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "104.21.235.187",
//           "domain_from_country": null,
//           "page_from_external_links": 2,
//           "page_from_internal_links": 35,
//           "page_from_size": 18982,
//           "page_from_encoding": "utf-8",
//           "page_from_language": null,
//           "page_from_title": "L�m thế n�o để Sử Dụng Storytelling trong Quảng C�o YouTube",
//           "page_from_status_code": 200,
//           "first_seen": "2023-10-25 05:14:11 +00:00",
//           "prev_seen": "2023-10-25 05:14:11 +00:00",
//           "last_seen": "2023-12-21 21:58:04 +00:00",
//           "item_type": "anchor",
//           "attributes": null,
//           "dofollow": true,
//           "original": false,
//           "alt": null,
//           "image_url": null,
//           "anchor": "Gleads",
//           "text_pre": " ",
//           "text_post": null,
//           "semantic_location": null,
//           "links_count": 1,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "all4webs.com",
//           "url_from": "https://all4webs.com/gleadsvn/seo-onpage-la-gi.htm",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "com",
//           "is_new": false,
//           "is_lost": false,
//           "backlink_spam_score": 25,
//           "rank": 78,
//           "page_from_rank": 0,
//           "domain_from_rank": 456,
//           "domain_from_platform_type": [
//               "unknown"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "104.21.235.187",
//           "domain_from_country": null,
//           "page_from_external_links": 2,
//           "page_from_internal_links": 35,
//           "page_from_size": 18316,
//           "page_from_encoding": "windows-1258",
//           "page_from_language": null,
//           "page_from_title": "Cách đánh giá và theo dơi hiệu quả SEO Onpage",
//           "page_from_status_code": 200,
//           "first_seen": "2023-10-25 05:14:07 +00:00",
//           "prev_seen": "2023-10-25 05:14:07 +00:00",
//           "last_seen": "2023-12-21 21:57:59 +00:00",
//           "item_type": "anchor",
//           "attributes": null,
//           "dofollow": true,
//           "original": false,
//           "alt": null,
//           "image_url": null,
//           "anchor": "Gleads",
//           "text_pre": " ",
//           "text_post": null,
//           "semantic_location": null,
//           "links_count": 1,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "all4webs.com",
//           "url_from": "https://all4webs.com/gleadsvn/d7883chv7909-youtubeads.htm",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "com",
//           "is_new": false,
//           "is_lost": false,
//           "backlink_spam_score": 25,
//           "rank": 78,
//           "page_from_rank": 0,
//           "domain_from_rank": 456,
//           "domain_from_platform_type": [
//               "unknown"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "104.21.235.187",
//           "domain_from_country": null,
//           "page_from_external_links": 2,
//           "page_from_internal_links": 35,
//           "page_from_size": 18789,
//           "page_from_encoding": "utf-8",
//           "page_from_language": null,
//           "page_from_title": "Tối ưu h�a chiến dịch quảng c�o game tr�n YouTube",
//           "page_from_status_code": 200,
//           "first_seen": "2023-10-25 05:13:57 +00:00",
//           "prev_seen": "2023-10-25 05:13:57 +00:00",
//           "last_seen": "2023-12-21 21:57:50 +00:00",
//           "item_type": "anchor",
//           "attributes": null,
//           "dofollow": true,
//           "original": false,
//           "alt": null,
//           "image_url": null,
//           "anchor": "Gleads",
//           "text_pre": " ",
//           "text_post": null,
//           "semantic_location": null,
//           "links_count": 1,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "all4webs.com",
//           "url_from": "https://all4webs.com/gleadsvn/thi7871tk7871-website2.htm",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "com",
//           "is_new": false,
//           "is_lost": false,
//           "backlink_spam_score": 25,
//           "rank": 78,
//           "page_from_rank": 0,
//           "domain_from_rank": 456,
//           "domain_from_platform_type": [
//               "unknown"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "104.21.235.187",
//           "domain_from_country": null,
//           "page_from_external_links": 2,
//           "page_from_internal_links": 35,
//           "page_from_size": 17423,
//           "page_from_encoding": "utf-8",
//           "page_from_language": null,
//           "page_from_title": "Cung cấp Nội dung Phụ đề v� Th�ng tin Đa ng�n ngữ",
//           "page_from_status_code": 200,
//           "first_seen": "2023-10-25 05:13:55 +00:00",
//           "prev_seen": "2023-10-25 05:13:55 +00:00",
//           "last_seen": "2023-12-21 21:57:48 +00:00",
//           "item_type": "anchor",
//           "attributes": null,
//           "dofollow": true,
//           "original": false,
//           "alt": null,
//           "image_url": null,
//           "anchor": "Gleads",
//           "text_pre": " ",
//           "text_post": null,
//           "semantic_location": null,
//           "links_count": 1,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "all4webs.com",
//           "url_from": "https://all4webs.com/gleadsvn/thiet-ke-profile.htm",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "com",
//           "is_new": false,
//           "is_lost": false,
//           "backlink_spam_score": 25,
//           "rank": 78,
//           "page_from_rank": 0,
//           "domain_from_rank": 456,
//           "domain_from_platform_type": [
//               "unknown"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "104.21.235.187",
//           "domain_from_country": null,
//           "page_from_external_links": 2,
//           "page_from_internal_links": 35,
//           "page_from_size": 16194,
//           "page_from_encoding": "utf-8",
//           "page_from_language": null,
//           "page_from_title": "Hồ sơ năng lực v� việc tạo cơ hội nghề nghiệp",
//           "page_from_status_code": 200,
//           "first_seen": "2023-10-25 05:13:53 +00:00",
//           "prev_seen": "2023-10-25 05:13:53 +00:00",
//           "last_seen": "2023-12-21 21:57:46 +00:00",
//           "item_type": "anchor",
//           "attributes": null,
//           "dofollow": true,
//           "original": false,
//           "alt": null,
//           "image_url": null,
//           "anchor": "Gleads",
//           "text_pre": " ",
//           "text_post": null,
//           "semantic_location": null,
//           "links_count": 1,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "all4webs.com",
//           "url_from": "https://all4webs.com/gleadsvn/seo-offpage-la-gi.htm",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "com",
//           "is_new": false,
//           "is_lost": false,
//           "backlink_spam_score": 25,
//           "rank": 78,
//           "page_from_rank": 0,
//           "domain_from_rank": 456,
//           "domain_from_platform_type": [
//               "unknown"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "104.21.235.187",
//           "domain_from_country": null,
//           "page_from_external_links": 2,
//           "page_from_internal_links": 35,
//           "page_from_size": 19495,
//           "page_from_encoding": "utf-8",
//           "page_from_language": null,
//           "page_from_title": "C�c kỹ thuật x�y dựng li�n kết ph� hợp với thương hiệu",
//           "page_from_status_code": 200,
//           "first_seen": "2023-10-25 05:13:47 +00:00",
//           "prev_seen": "2023-10-25 05:13:47 +00:00",
//           "last_seen": "2023-12-21 21:57:38 +00:00",
//           "item_type": "anchor",
//           "attributes": null,
//           "dofollow": true,
//           "original": false,
//           "alt": null,
//           "image_url": null,
//           "anchor": "Gleads",
//           "text_pre": " ",
//           "text_post": null,
//           "semantic_location": null,
//           "links_count": 1,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "all4webs.com",
//           "url_from": "https://all4webs.com/gleadsvn/thi7871tk7871-website3.htm",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "com",
//           "is_new": false,
//           "is_lost": false,
//           "backlink_spam_score": 25,
//           "rank": 78,
//           "page_from_rank": 0,
//           "domain_from_rank": 456,
//           "domain_from_platform_type": [
//               "unknown"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "104.21.235.187",
//           "domain_from_country": null,
//           "page_from_external_links": 2,
//           "page_from_internal_links": 35,
//           "page_from_size": 18126,
//           "page_from_encoding": "utf-8",
//           "page_from_language": null,
//           "page_from_title": "Tạo Trang Hỗ trợ Kỹ thuật v� Li�n hệ Cho Website B�n M�y T�nh",
//           "page_from_status_code": 200,
//           "first_seen": "2023-10-25 05:13:45 +00:00",
//           "prev_seen": "2023-10-25 05:13:45 +00:00",
//           "last_seen": "2023-12-21 21:57:36 +00:00",
//           "item_type": "anchor",
//           "attributes": null,
//           "dofollow": true,
//           "original": false,
//           "alt": null,
//           "image_url": null,
//           "anchor": "Gleads",
//           "text_pre": " ",
//           "text_post": null,
//           "semantic_location": null,
//           "links_count": 1,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "all4webs.com",
//           "url_from": "https://all4webs.com/gleadsvn/dich-vu-seo2.htm",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "com",
//           "is_new": false,
//           "is_lost": false,
//           "backlink_spam_score": 25,
//           "rank": 78,
//           "page_from_rank": 0,
//           "domain_from_rank": 456,
//           "domain_from_platform_type": [
//               "unknown"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "104.21.235.187",
//           "domain_from_country": null,
//           "page_from_external_links": 2,
//           "page_from_internal_links": 35,
//           "page_from_size": 18315,
//           "page_from_encoding": "utf-8",
//           "page_from_language": null,
//           "page_from_title": "Tạo li�n kết th�ng minh trong dịch vụ SEO tổng thể",
//           "page_from_status_code": 200,
//           "first_seen": "2023-10-25 05:13:42 +00:00",
//           "prev_seen": "2023-10-25 05:13:42 +00:00",
//           "last_seen": "2023-12-21 21:57:34 +00:00",
//           "item_type": "anchor",
//           "attributes": null,
//           "dofollow": true,
//           "original": false,
//           "alt": null,
//           "image_url": null,
//           "anchor": "Gleads",
//           "text_pre": " ",
//           "text_post": null,
//           "semantic_location": null,
//           "links_count": 1,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "all4webs.com",
//           "url_from": "https://all4webs.com/gleadsvn/seo-youtube.htm",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "com",
//           "is_new": false,
//           "is_lost": false,
//           "backlink_spam_score": 25,
//           "rank": 78,
//           "page_from_rank": 0,
//           "domain_from_rank": 456,
//           "domain_from_platform_type": [
//               "unknown"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "104.21.235.187",
//           "domain_from_country": null,
//           "page_from_external_links": 2,
//           "page_from_internal_links": 35,
//           "page_from_size": 16863,
//           "page_from_encoding": "utf-8",
//           "page_from_language": null,
//           "page_from_title": "C�ch tối ưu h�a ti�u đề v� m� tả video tr�n YouTube",
//           "page_from_status_code": 200,
//           "first_seen": "2023-10-25 05:13:40 +00:00",
//           "prev_seen": "2023-10-25 05:13:40 +00:00",
//           "last_seen": "2023-12-21 21:57:31 +00:00",
//           "item_type": "anchor",
//           "attributes": null,
//           "dofollow": true,
//           "original": false,
//           "alt": null,
//           "image_url": null,
//           "anchor": "Gleads",
//           "text_pre": " ",
//           "text_post": null,
//           "semantic_location": null,
//           "links_count": 1,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "all4webs.com",
//           "url_from": "https://all4webs.com/gleadsvn/dich-vu-seo.htm",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "com",
//           "is_new": false,
//           "is_lost": false,
//           "backlink_spam_score": 25,
//           "rank": 78,
//           "page_from_rank": 0,
//           "domain_from_rank": 456,
//           "domain_from_platform_type": [
//               "unknown"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "104.21.235.187",
//           "domain_from_country": null,
//           "page_from_external_links": 2,
//           "page_from_internal_links": 35,
//           "page_from_size": 18344,
//           "page_from_encoding": "utf-8",
//           "page_from_language": null,
//           "page_from_title": "Dịch vụ SEO chuy�n nghiệp tại Gleads",
//           "page_from_status_code": 200,
//           "first_seen": "2023-10-25 05:13:38 +00:00",
//           "prev_seen": "2023-10-25 05:13:38 +00:00",
//           "last_seen": "2023-12-21 21:57:29 +00:00",
//           "item_type": "anchor",
//           "attributes": null,
//           "dofollow": true,
//           "original": false,
//           "alt": null,
//           "image_url": null,
//           "anchor": "Gleads",
//           "text_pre": " ",
//           "text_post": null,
//           "semantic_location": null,
//           "links_count": 1,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "all4webs.com",
//           "url_from": "https://all4webs.com/gleadsvn/quangcaoyoutube.htm",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "com",
//           "is_new": false,
//           "is_lost": false,
//           "backlink_spam_score": 25,
//           "rank": 78,
//           "page_from_rank": 0,
//           "domain_from_rank": 456,
//           "domain_from_platform_type": [
//               "unknown"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "104.21.235.187",
//           "domain_from_country": null,
//           "page_from_external_links": 2,
//           "page_from_internal_links": 35,
//           "page_from_size": 16757,
//           "page_from_encoding": "utf-8",
//           "page_from_language": null,
//           "page_from_title": "Sử dụng remarketing trong quảng c�o youtube TrueView",
//           "page_from_status_code": 200,
//           "first_seen": "2023-10-25 05:13:36 +00:00",
//           "prev_seen": "2023-10-25 05:13:36 +00:00",
//           "last_seen": "2023-12-21 21:57:27 +00:00",
//           "item_type": "anchor",
//           "attributes": null,
//           "dofollow": true,
//           "original": false,
//           "alt": null,
//           "image_url": null,
//           "anchor": "Gleads",
//           "text_pre": " ",
//           "text_post": null,
//           "semantic_location": null,
//           "links_count": 1,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "all4webs.com",
//           "url_from": "https://all4webs.com/gleadsvn/thiet-ke-bndth.htm",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "com",
//           "is_new": false,
//           "is_lost": false,
//           "backlink_spam_score": 35,
//           "rank": 78,
//           "page_from_rank": 0,
//           "domain_from_rank": 456,
//           "domain_from_platform_type": [
//               "unknown"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "104.21.235.187",
//           "domain_from_country": null,
//           "page_from_external_links": 2,
//           "page_from_internal_links": 35,
//           "page_from_size": 18835,
//           "page_from_encoding": "utf-8",
//           "page_from_language": null,
//           "page_from_title": "C�ch thể hiện gi� trị v� t�m hồn của thương hiệu qua bộ nhận diện thương hi&",
//           "page_from_status_code": 200,
//           "first_seen": "2023-10-25 05:13:32 +00:00",
//           "prev_seen": "2023-10-25 05:13:32 +00:00",
//           "last_seen": "2023-12-21 21:57:21 +00:00",
//           "item_type": "anchor",
//           "attributes": null,
//           "dofollow": true,
//           "original": false,
//           "alt": null,
//           "image_url": null,
//           "anchor": "Gleads",
//           "text_pre": " ",
//           "text_post": null,
//           "semantic_location": null,
//           "links_count": 1,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "all4webs.com",
//           "url_from": "https://all4webs.com/gleadsvn/quang-cao-facebook2.htm",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "com",
//           "is_new": false,
//           "is_lost": false,
//           "backlink_spam_score": 35,
//           "rank": 78,
//           "page_from_rank": 0,
//           "domain_from_rank": 456,
//           "domain_from_platform_type": [
//               "unknown"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "104.21.235.187",
//           "domain_from_country": null,
//           "page_from_external_links": 2,
//           "page_from_internal_links": 35,
//           "page_from_size": 17906,
//           "page_from_encoding": "utf-8",
//           "page_from_language": null,
//           "page_from_title": "C�ch Thực Hiện Quảng C�o Tin Nhắn Facebook tr�n Nền Tảng Messenger",
//           "page_from_status_code": 200,
//           "first_seen": "2023-10-25 05:13:29 +00:00",
//           "prev_seen": "2023-10-25 05:13:29 +00:00",
//           "last_seen": "2023-12-21 21:57:19 +00:00",
//           "item_type": "anchor",
//           "attributes": null,
//           "dofollow": true,
//           "original": false,
//           "alt": null,
//           "image_url": null,
//           "anchor": "Gleads",
//           "text_pre": " ",
//           "text_post": null,
//           "semantic_location": null,
//           "links_count": 1,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "all4webs.com",
//           "url_from": "https://all4webs.com/gleadsvn/thi7871tk7871-web.htm",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "com",
//           "is_new": false,
//           "is_lost": false,
//           "backlink_spam_score": 35,
//           "rank": 78,
//           "page_from_rank": 0,
//           "domain_from_rank": 456,
//           "domain_from_platform_type": [
//               "unknown"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "104.21.235.187",
//           "domain_from_country": null,
//           "page_from_external_links": 2,
//           "page_from_internal_links": 35,
//           "page_from_size": 15773,
//           "page_from_encoding": "utf-8",
//           "page_from_language": null,
//           "page_from_title": "Tạo Diễn Đ�n v� Cộng Đồng Học Tập: Lợi �ch v� Sự Kết Nối Cho Học Vi�n",
//           "page_from_status_code": 200,
//           "first_seen": "2023-10-25 05:13:27 +00:00",
//           "prev_seen": "2023-10-25 05:13:27 +00:00",
//           "last_seen": "2023-12-21 21:57:17 +00:00",
//           "item_type": "anchor",
//           "attributes": null,
//           "dofollow": true,
//           "original": false,
//           "alt": null,
//           "image_url": null,
//           "anchor": "Gleads",
//           "text_pre": " ",
//           "text_post": null,
//           "semantic_location": null,
//           "links_count": 1,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "memoryarchive.org",
//           "url_from": "https://memoryarchive.org/thiet-ke-web-dep-giup-giu-chan-khach-hang-lau-hon/",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "org",
//           "is_new": false,
//           "is_lost": false,
//           "backlink_spam_score": 0,
//           "rank": 78,
//           "page_from_rank": 0,
//           "domain_from_rank": 187,
//           "domain_from_platform_type": [
//               "cms",
//               "blogs",
//               "organization"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "172.67.169.53",
//           "domain_from_country": null,
//           "page_from_external_links": 10,
//           "page_from_internal_links": 37,
//           "page_from_size": 108977,
//           "page_from_encoding": "utf-8",
//           "page_from_language": "vi",
//           "page_from_title": "Thiết kế web đẹp giúp giữ chân khách hàng lâu hơn",
//           "page_from_status_code": 200,
//           "first_seen": "2023-04-06 15:46:59 +00:00",
//           "prev_seen": "2023-10-20 13:12:38 +00:00",
//           "last_seen": "2023-12-20 10:47:07 +00:00",
//           "item_type": "anchor",
//           "attributes": [
//               "noopener"
//           ],
//           "dofollow": true,
//           "original": false,
//           "alt": null,
//           "image_url": null,
//           "anchor": "công ty Gleads",
//           "text_pre": null,
//           "text_post": null,
//           "semantic_location": "article",
//           "links_count": 1,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "zozoship.vn",
//           "url_from": "https://zozoship.vn/bang-bao-gia-thiet-ke-website-tu-a-den-z/",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "vn",
//           "is_new": false,
//           "is_lost": false,
//           "backlink_spam_score": 0,
//           "rank": 78,
//           "page_from_rank": 0,
//           "domain_from_rank": 187,
//           "domain_from_platform_type": [
//               "cms",
//               "blogs"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "188.114.96.3",
//           "domain_from_country": "VN",
//           "page_from_external_links": 2,
//           "page_from_internal_links": 27,
//           "page_from_size": 94705,
//           "page_from_encoding": "utf-8",
//           "page_from_language": "vi",
//           "page_from_title": "Bảng báo giá thiết kế website từ A đến Z",
//           "page_from_status_code": 200,
//           "first_seen": "2023-10-30 09:10:51 +00:00",
//           "prev_seen": "2023-10-30 09:10:51 +00:00",
//           "last_seen": "2023-12-19 18:44:54 +00:00",
//           "item_type": "anchor",
//           "attributes": [
//               "noopener"
//           ],
//           "dofollow": true,
//           "original": false,
//           "alt": null,
//           "image_url": null,
//           "anchor": "Đội ngũ Gleads",
//           "text_pre": null,
//           "text_post": null,
//           "semantic_location": "article",
//           "links_count": 1,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "songgio.vn",
//           "url_from": "https://songgio.vn/cong-ty-thiet-ke-web-noi-tieng/",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "vn",
//           "is_new": false,
//           "is_lost": false,
//           "backlink_spam_score": 0,
//           "rank": 78,
//           "page_from_rank": 0,
//           "domain_from_rank": 82,
//           "domain_from_platform_type": [
//               "cms",
//               "organization"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "188.114.96.3",
//           "domain_from_country": "VN",
//           "page_from_external_links": 8,
//           "page_from_internal_links": 23,
//           "page_from_size": 110466,
//           "page_from_encoding": "utf-8",
//           "page_from_language": "vi",
//           "page_from_title": "Công ty thiết kế web nổi tiếng",
//           "page_from_status_code": 200,
//           "first_seen": "2023-10-09 14:01:15 +00:00",
//           "prev_seen": "2023-11-13 17:51:48 +00:00",
//           "last_seen": "2023-12-18 14:25:36 +00:00",
//           "item_type": "anchor",
//           "attributes": [
//               "noopener"
//           ],
//           "dofollow": true,
//           "original": false,
//           "alt": null,
//           "image_url": null,
//           "anchor": "Gleads",
//           "text_pre": null,
//           "text_post": null,
//           "semantic_location": "article",
//           "links_count": 1,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "baodanang.vn",
//           "url_from": "https://baodanang.vn/can-biet/202301/quang-cao-google-gia-re-chuyen-sau-su-dung-tot-ngan-sach-3935595/index.htm",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "vn",
//           "is_new": false,
//           "is_lost": false,
//           "backlink_spam_score": 5,
//           "rank": 78,
//           "page_from_rank": 0,
//           "domain_from_rank": 445,
//           "domain_from_platform_type": [
//               "unknown"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "123.31.36.90",
//           "domain_from_country": "VN",
//           "page_from_external_links": 2,
//           "page_from_internal_links": 24,
//           "page_from_size": 14189,
//           "page_from_encoding": "utf-8",
//           "page_from_language": "vi",
//           "page_from_title": "Báo Đà Nẵng điện tử",
//           "page_from_status_code": 200,
//           "first_seen": "2023-02-04 04:49:32 +00:00",
//           "prev_seen": "2023-10-13 21:11:19 +00:00",
//           "last_seen": "2023-12-16 04:06:31 +00:00",
//           "item_type": "anchor",
//           "attributes": null,
//           "dofollow": true,
//           "original": false,
//           "alt": null,
//           "image_url": null,
//           "anchor": "https://gleads.vn/vi",
//           "text_pre": "Website:",
//           "text_post": null,
//           "semantic_location": null,
//           "links_count": 1,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "tcn.vn",
//           "url_from": "https://tcn.vn/kinh-doanh/37930-quang-cao-hien-thi-google-giup-kiem-tien-online-hieu-qua.html",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "vn",
//           "is_new": false,
//           "is_lost": false,
//           "backlink_spam_score": 0,
//           "rank": 78,
//           "page_from_rank": 0,
//           "domain_from_rank": 160,
//           "domain_from_platform_type": [
//               "cms",
//               "news"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "172.67.137.55",
//           "domain_from_country": "VN",
//           "page_from_external_links": 2,
//           "page_from_internal_links": 30,
//           "page_from_size": 81589,
//           "page_from_encoding": "utf-8",
//           "page_from_language": "vi",
//           "page_from_title": "Để kiếm tiền online trong thời đại công nghệ phát triển, việc sử dụng quảng cáo hiển thị của google là một điều tất yếu.",
//           "page_from_status_code": 200,
//           "first_seen": "2023-06-29 00:22:05 +00:00",
//           "prev_seen": "2023-09-21 07:21:00 +00:00",
//           "last_seen": "2023-12-12 00:07:59 +00:00",
//           "item_type": "anchor",
//           "attributes": [
//               "external",
//               "noopener",
//               "noreferrer"
//           ],
//           "dofollow": true,
//           "original": false,
//           "alt": null,
//           "image_url": null,
//           "anchor": "Công ty TNHH Gleads",
//           "text_pre": "năng mà bạn có thể tiếp cận không chỉ dừng lại ở hàng trăm, hàng ngàn, mà lên đến con số hàng triệu. Vậy nên, hãy tìm hiểu thật kỹ về quảng cáo hiển thị để phát huy tối đa công dụng của loại hình này.Thông tin liên hệ:",
//           "text_post": "Mã số thuế: 0314736707Phone: +8428 7305 4898 (HR)Phone: +8428 6272 2262 (Sales)Email: service@gleads.vnAddress: 39-41 đường D4, Khu Him Lam, Phường Tân Hưng, Quận 7, Thành Phố Hồ Chí Minh.",
//           "semantic_location": "article",
//           "links_count": 1,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "xaydunghtd.com",
//           "url_from": "https://xaydunghtd.com/blogs/thiet-ke-website-xay-dung-giup-tiep-can-nhieu-khach-hang-tiem-nang-hon/",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "com",
//           "is_new": false,
//           "is_lost": false,
//           "backlink_spam_score": 5,
//           "rank": 78,
//           "page_from_rank": 0,
//           "domain_from_rank": 277,
//           "domain_from_platform_type": [
//               "cms",
//               "blogs"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "103.130.216.96",
//           "domain_from_country": null,
//           "page_from_external_links": 2,
//           "page_from_internal_links": 15,
//           "page_from_size": 285525,
//           "page_from_encoding": "utf-8",
//           "page_from_language": "vi",
//           "page_from_title": "Thiết kế website xây dựng giúp tiếp cận nhiều khách hàng tiềm năng hơn - Blogs Xây Dựng & Nội Thất",
//           "page_from_status_code": 200,
//           "first_seen": "2023-04-10 10:42:37 +00:00",
//           "prev_seen": "2023-08-20 00:25:47 +00:00",
//           "last_seen": "2023-12-07 13:03:47 +00:00",
//           "item_type": "anchor",
//           "attributes": [
//               "noopener"
//           ],
//           "dofollow": true,
//           "original": false,
//           "alt": null,
//           "image_url": null,
//           "anchor": "Gleads",
//           "text_pre": "xây dựng đầy cạnh tranh thì một website được thiết kế chuyên nghiệp sẽ giúp doanh nghiệp làm tốt điều đó. Vì thế mà doanh nghiệp hãy tìm hiểu và lựa chọn đơn vị thiết kế web xây dựng đẹp chuyên nghiệp. Hãy đến với",
//           "text_post": ", chúng tôi đảm bảo cung cấp dịch vụ thiết kế làm hài lòng quý doanh nghiệp với chi phí hợp lý nhất. Website xây dựng được thiết kế tại Gleads sẽ giúp doanh nghiệp tăng độ phủ thương hiệu, thể hiện sự",
//           "semantic_location": "article",
//           "links_count": 1,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "all4webs.com",
//           "url_from": "https://all4webs.com/gleadsvn/seo-onpage-la-gi.htm?28099=18112",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "com",
//           "is_new": true,
//           "is_lost": false,
//           "backlink_spam_score": 25,
//           "rank": 78,
//           "page_from_rank": 0,
//           "domain_from_rank": 457,
//           "domain_from_platform_type": [
//               "unknown"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "104.21.235.187",
//           "domain_from_country": null,
//           "page_from_external_links": 2,
//           "page_from_internal_links": 35,
//           "page_from_size": 18316,
//           "page_from_encoding": "windows-1258",
//           "page_from_language": null,
//           "page_from_title": "Cách đánh giá và theo dơi hiệu quả SEO Onpage",
//           "page_from_status_code": 200,
//           "first_seen": "2023-11-29 00:18:22 +00:00",
//           "prev_seen": null,
//           "last_seen": "2023-11-29 00:18:22 +00:00",
//           "item_type": "anchor",
//           "attributes": null,
//           "dofollow": true,
//           "original": true,
//           "alt": null,
//           "image_url": null,
//           "anchor": "Gleads",
//           "text_pre": " ",
//           "text_post": null,
//           "semantic_location": null,
//           "links_count": 1,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "paxland.vn",
//           "url_from": "https://paxland.vn/lam-web-tin-tuc-chat-luong-tai-gleads/",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "vn",
//           "is_new": false,
//           "is_lost": false,
//           "backlink_spam_score": 0,
//           "rank": 78,
//           "page_from_rank": 0,
//           "domain_from_rank": 233,
//           "domain_from_platform_type": [
//               "cms",
//               "blogs"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "188.114.96.3",
//           "domain_from_country": "VN",
//           "page_from_external_links": 2,
//           "page_from_internal_links": 27,
//           "page_from_size": 69329,
//           "page_from_encoding": "utf-8",
//           "page_from_language": "vi",
//           "page_from_title": "Làm web tin tức chất lượng tại Gleads",
//           "page_from_status_code": 200,
//           "first_seen": "2023-10-05 20:59:53 +00:00",
//           "prev_seen": "2023-10-05 20:59:53 +00:00",
//           "last_seen": "2023-11-18 06:40:36 +00:00",
//           "item_type": "anchor",
//           "attributes": [
//               "noopener"
//           ],
//           "dofollow": true,
//           "original": false,
//           "alt": null,
//           "image_url": null,
//           "anchor": "Gleads",
//           "text_pre": null,
//           "text_post": null,
//           "semantic_location": "article",
//           "links_count": 1,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "reddit.com.vn",
//           "url_from": "https://reddit.com.vn/cong-ty-thiet-ke-website-chuyen-nghiep/",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "com.vn",
//           "is_new": true,
//           "is_lost": false,
//           "backlink_spam_score": 0,
//           "rank": 78,
//           "page_from_rank": 0,
//           "domain_from_rank": 100,
//           "domain_from_platform_type": [
//               "cms",
//               "organization"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "188.114.96.3",
//           "domain_from_country": "VN",
//           "page_from_external_links": 7,
//           "page_from_internal_links": 29,
//           "page_from_size": 78536,
//           "page_from_encoding": "utf-8",
//           "page_from_language": "vi",
//           "page_from_title": "Công ty thiết kế website chuyên nghiệp - Reddit - Blog chia sẻ công nghệ, thủ thuật, giải trí, học tập,...",
//           "page_from_status_code": 200,
//           "first_seen": "2023-11-15 18:56:11 +00:00",
//           "prev_seen": null,
//           "last_seen": "2023-11-15 18:56:11 +00:00",
//           "item_type": "anchor",
//           "attributes": [
//               "noopener"
//           ],
//           "dofollow": true,
//           "original": true,
//           "alt": null,
//           "image_url": null,
//           "anchor": "Gleads",
//           "text_pre": null,
//           "text_post": null,
//           "semantic_location": "article",
//           "links_count": 1,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "thiepcuoidep.vn",
//           "url_from": "https://thiepcuoidep.vn/dich-vu-thiet-ke-website-gia-re.html",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "vn",
//           "is_new": false,
//           "is_lost": false,
//           "backlink_spam_score": 0,
//           "rank": 78,
//           "page_from_rank": 0,
//           "domain_from_rank": 280,
//           "domain_from_platform_type": [
//               "cms",
//               "blogs"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "103.27.237.166",
//           "domain_from_country": "VN",
//           "page_from_external_links": 6,
//           "page_from_internal_links": 24,
//           "page_from_size": 87579,
//           "page_from_encoding": "utf-8",
//           "page_from_language": "vi",
//           "page_from_title": "Dịch vụ thiết kế website giá rẻ - Thiepcuoidep.vn",
//           "page_from_status_code": 200,
//           "first_seen": "2023-04-02 22:17:14 +00:00",
//           "prev_seen": "2023-08-21 05:11:23 +00:00",
//           "last_seen": "2023-11-12 16:42:28 +00:00",
//           "item_type": "anchor",
//           "attributes": null,
//           "dofollow": true,
//           "original": false,
//           "alt": null,
//           "image_url": null,
//           "anchor": "Công ty TNHH Gleads",
//           "text_pre": null,
//           "text_post": null,
//           "semantic_location": "article",
//           "links_count": 1,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "chinaphilharmonic.org",
//           "url_from": "https://chinaphilharmonic.org/thiet-ke-website-cho-doanh-nghiep-tron-goi/",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "org",
//           "is_new": false,
//           "is_lost": false,
//           "backlink_spam_score": 15,
//           "rank": 78,
//           "page_from_rank": 0,
//           "domain_from_rank": 232,
//           "domain_from_platform_type": [
//               "cms",
//               "blogs"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "104.21.95.133",
//           "domain_from_country": null,
//           "page_from_external_links": 2,
//           "page_from_internal_links": 24,
//           "page_from_size": 112114,
//           "page_from_encoding": "utf-8",
//           "page_from_language": "vi",
//           "page_from_title": "Thiết kế website cho doanh nghiệp trọn gói - chinaphilharmonic.org",
//           "page_from_status_code": 200,
//           "first_seen": "2023-05-16 15:19:42 +00:00",
//           "prev_seen": "2023-10-12 21:57:57 +00:00",
//           "last_seen": "2023-11-12 04:09:39 +00:00",
//           "item_type": "anchor",
//           "attributes": [
//               "noopener"
//           ],
//           "dofollow": true,
//           "original": false,
//           "alt": null,
//           "image_url": null,
//           "anchor": "Công ty TNHH Gleads",
//           "text_pre": null,
//           "text_post": null,
//           "semantic_location": null,
//           "links_count": 1,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "workfromhome.vn",
//           "url_from": "https://workfromhome.vn/thiet-ke-giao-dien-wordpress-cho-website/",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "vn",
//           "is_new": false,
//           "is_lost": false,
//           "backlink_spam_score": 0,
//           "rank": 78,
//           "page_from_rank": 0,
//           "domain_from_rank": 134,
//           "domain_from_platform_type": [
//               "cms",
//               "organization"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "172.67.223.12",
//           "domain_from_country": "VN",
//           "page_from_external_links": 7,
//           "page_from_internal_links": 26,
//           "page_from_size": 95617,
//           "page_from_encoding": "utf-8",
//           "page_from_language": "vi",
//           "page_from_title": "Thiết kế giao diện wordpress cho website",
//           "page_from_status_code": 200,
//           "first_seen": "2023-04-01 01:34:50 +00:00",
//           "prev_seen": "2023-07-29 10:45:09 +00:00",
//           "last_seen": "2023-11-02 21:21:58 +00:00",
//           "item_type": "anchor",
//           "attributes": [
//               "noopener"
//           ],
//           "dofollow": true,
//           "original": false,
//           "alt": null,
//           "image_url": null,
//           "anchor": "Công ty TNHH Gleads",
//           "text_pre": null,
//           "text_post": null,
//           "semantic_location": "article",
//           "links_count": 1,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "vietbao.vn",
//           "url_from": "https://vietbao.vn/print/408603.html",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "vn",
//           "is_new": false,
//           "is_lost": false,
//           "backlink_spam_score": 20,
//           "rank": 78,
//           "page_from_rank": 0,
//           "domain_from_rank": 460,
//           "domain_from_platform_type": [
//               "unknown"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "116.118.88.93",
//           "domain_from_country": "VN",
//           "page_from_external_links": 2,
//           "page_from_internal_links": 1,
//           "page_from_size": 9525,
//           "page_from_encoding": "utf-8",
//           "page_from_language": null,
//           "page_from_title": "Công ty quảng cáo Google Adwords chuyên sâu - Gleads",
//           "page_from_status_code": 200,
//           "first_seen": "2023-02-09 12:46:43 +00:00",
//           "prev_seen": "2023-07-12 04:57:05 +00:00",
//           "last_seen": "2023-10-14 20:54:08 +00:00",
//           "item_type": "anchor",
//           "attributes": null,
//           "dofollow": true,
//           "original": false,
//           "alt": null,
//           "image_url": null,
//           "anchor": "https://gleads.vn/vi",
//           "text_pre": "Công ty TNHH GleadsMã số thuế: 0314736707Phone: +8428 7305 4898 (HR)Phone: +8428 6272 2262 (Sales)Website:",
//           "text_post": "Email: service@gleads.vnAddress: 39-41 đường D4, Khu Him Lam, Phường Tân Hưng, Quận 7, TP.HCM",
//           "semantic_location": "article",
//           "links_count": 1,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "realchampionshipwrestling.com",
//           "url_from": "https://realchampionshipwrestling.com/lam-web-bang-wordpress-co-nhung-uu-diem-gi/",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "com",
//           "is_new": false,
//           "is_lost": false,
//           "backlink_spam_score": 0,
//           "rank": 78,
//           "page_from_rank": 0,
//           "domain_from_rank": 197,
//           "domain_from_platform_type": [
//               "cms",
//               "blogs"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "104.21.74.62",
//           "domain_from_country": null,
//           "page_from_external_links": 2,
//           "page_from_internal_links": 27,
//           "page_from_size": 35002,
//           "page_from_encoding": "utf-8",
//           "page_from_language": "vi",
//           "page_from_title": "Làm web bằng WordPress có những ưu điểm gì?",
//           "page_from_status_code": 200,
//           "first_seen": "2023-04-07 06:35:47 +00:00",
//           "prev_seen": "2023-08-02 07:22:21 +00:00",
//           "last_seen": "2023-10-09 16:38:16 +00:00",
//           "item_type": "anchor",
//           "attributes": null,
//           "dofollow": true,
//           "original": false,
//           "alt": null,
//           "image_url": null,
//           "anchor": "Gleads",
//           "text_pre": "cùng về tối ưu website, chúng tôi còn là một đơn vị chức năng tất cả trình độ cao vào nghành nghề dịch vụ digital marketing, nhất là trình độ chuyên môn sâu trong số chiến dịch SEO. Do đó, khi thực hiện website cùng",
//           "text_post": ", công ty chúng tôi đã dựa theo các yếu tố và nguyên lý SEO nhằm mục tiêu cung cấp khách hàng trong Việc cạnh tranh cùng với địch thủ vào dụng cụ search tìm. Dường như còn tư vấn SEO sơ cỗ mang lại quý",
//           "semantic_location": "article",
//           "links_count": 1,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "duongdamat.com.vn",
//           "url_from": "https://duongdamat.com.vn/thiet-ke-website-bao-dien-tu-tren-da-nen-tang/",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "com.vn",
//           "is_new": false,
//           "is_lost": false,
//           "backlink_spam_score": 0,
//           "rank": 78,
//           "page_from_rank": 0,
//           "domain_from_rank": 235,
//           "domain_from_platform_type": [
//               "cms",
//               "blogs"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "202.92.4.49",
//           "domain_from_country": "VN",
//           "page_from_external_links": 2,
//           "page_from_internal_links": 9,
//           "page_from_size": 42848,
//           "page_from_encoding": "utf-8",
//           "page_from_language": "en",
//           "page_from_title": "Thiết kế website báo điện tử trên đa nền tảng - Dưỡng da mặt",
//           "page_from_status_code": 200,
//           "first_seen": "2023-05-02 17:13:03 +00:00",
//           "prev_seen": "2023-05-02 17:13:03 +00:00",
//           "last_seen": "2023-07-24 17:34:49 +00:00",
//           "item_type": "anchor",
//           "attributes": null,
//           "dofollow": true,
//           "original": false,
//           "alt": null,
//           "image_url": null,
//           "anchor": "Công ty TNHH Gleads",
//           "text_pre": null,
//           "text_post": null,
//           "semantic_location": "article",
//           "links_count": 1,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "topdanhgia.vn",
//           "url_from": "https://topdanhgia.vn/thiet-ke-web-noi-that/",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "vn",
//           "is_new": false,
//           "is_lost": false,
//           "backlink_spam_score": 0,
//           "rank": 78,
//           "page_from_rank": 0,
//           "domain_from_rank": 227,
//           "domain_from_platform_type": [
//               "cms",
//               "blogs",
//               "organization"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "112.213.89.186",
//           "domain_from_country": "VN",
//           "page_from_external_links": 7,
//           "page_from_internal_links": 53,
//           "page_from_size": 91374,
//           "page_from_encoding": "utf-8",
//           "page_from_language": "vi",
//           "page_from_title": "Thiết kế web nội thất tích hợp quản lý sản phẩm và kho",
//           "page_from_status_code": 200,
//           "first_seen": "2023-05-05 12:27:44 +00:00",
//           "prev_seen": "2023-05-05 12:27:44 +00:00",
//           "last_seen": "2023-06-22 07:52:45 +00:00",
//           "item_type": "anchor",
//           "attributes": null,
//           "dofollow": true,
//           "original": false,
//           "alt": null,
//           "image_url": null,
//           "anchor": "Gleads",
//           "text_pre": "Công ty TNHH",
//           "text_post": null,
//           "semantic_location": "article",
//           "links_count": 1,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "www.bienphong.com.vn",
//           "url_from": "https://www.bienphong.com.vn/dich-vu-quang-cao-google-adwords-target-chinh-xac-doi-tuong-khach-hang-post458631.html",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "com.vn",
//           "is_new": false,
//           "is_lost": false,
//           "backlink_spam_score": 0,
//           "rank": 78,
//           "page_from_rank": 0,
//           "domain_from_rank": 392,
//           "domain_from_platform_type": [
//               "news"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "125.212.192.52",
//           "domain_from_country": "VN",
//           "page_from_external_links": 3,
//           "page_from_internal_links": 59,
//           "page_from_size": 55407,
//           "page_from_encoding": "utf-8",
//           "page_from_language": "vi",
//           "page_from_title": "Dịch vụ quảng cáo Google Adwords target chính xác đối tượng khách hàng",
//           "page_from_status_code": 200,
//           "first_seen": "2023-02-09 09:52:25 +00:00",
//           "prev_seen": "2023-02-19 01:16:11 +00:00",
//           "last_seen": "2023-06-15 14:09:05 +00:00",
//           "item_type": "anchor",
//           "attributes": null,
//           "dofollow": true,
//           "original": false,
//           "alt": null,
//           "image_url": null,
//           "anchor": "https://gleads.vn",
//           "text_pre": "Website:",
//           "text_post": null,
//           "semantic_location": "article",
//           "links_count": 1,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "srebrenica-opstina.org",
//           "url_from": "https://srebrenica-opstina.org/gia-thiet-ke-1-trang-web-co-nhung-khoan-phi-nao/",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "org",
//           "is_new": false,
//           "is_lost": false,
//           "backlink_spam_score": 5,
//           "rank": 78,
//           "page_from_rank": 0,
//           "domain_from_rank": 161,
//           "domain_from_platform_type": [
//               "cms",
//               "blogs"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "104.21.52.50",
//           "domain_from_country": null,
//           "page_from_external_links": 2,
//           "page_from_internal_links": 21,
//           "page_from_size": 73492,
//           "page_from_encoding": "utf-8",
//           "page_from_language": "vi",
//           "page_from_title": "Giá thiết kế 1 trang web có những khoản phí nào?",
//           "page_from_status_code": 200,
//           "first_seen": "2023-04-02 08:49:06 +00:00",
//           "prev_seen": "2023-04-02 08:49:06 +00:00",
//           "last_seen": "2023-05-09 18:43:57 +00:00",
//           "item_type": "anchor",
//           "attributes": [
//               "noopener"
//           ],
//           "dofollow": true,
//           "original": false,
//           "alt": null,
//           "image_url": null,
//           "anchor": "Gleads",
//           "text_pre": null,
//           "text_post": null,
//           "semantic_location": "article",
//           "links_count": 1,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "baoquangngai.vn",
//           "url_from": "https://baoquangngai.vn/channel/8181/202302/thiet-ke-website-bat-dong-san-voi-nhieu-module-chuyen-biet-3155360/index.htm",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "vn",
//           "is_new": false,
//           "is_lost": false,
//           "backlink_spam_score": 5,
//           "rank": 78,
//           "page_from_rank": 0,
//           "domain_from_rank": 381,
//           "domain_from_platform_type": [
//               "unknown"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "123.31.45.45",
//           "domain_from_country": "VN",
//           "page_from_external_links": 2,
//           "page_from_internal_links": 15,
//           "page_from_size": 17671,
//           "page_from_encoding": "utf-8",
//           "page_from_language": "vi",
//           "page_from_title": "Thiết kế website bất động sản với nhiều module chuyên biệt - Báo Quảng Ngãi điện tử",
//           "page_from_status_code": 200,
//           "first_seen": "2023-02-04 04:09:20 +00:00",
//           "prev_seen": "2023-02-04 04:09:20 +00:00",
//           "last_seen": "2023-03-23 12:24:25 +00:00",
//           "item_type": "anchor",
//           "attributes": null,
//           "dofollow": true,
//           "original": false,
//           "alt": null,
//           "image_url": null,
//           "anchor": "Công ty TNHH Gleads",
//           "text_pre": null,
//           "text_post": null,
//           "semantic_location": "section",
//           "links_count": 1,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "www.chillspot1.com",
//           "url_from": "https://www.chillspot1.com/user/Gleadsvn",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "com",
//           "is_new": true,
//           "is_lost": false,
//           "backlink_spam_score": 35,
//           "rank": 78,
//           "page_from_rank": 0,
//           "domain_from_rank": 270,
//           "domain_from_platform_type": [
//               "unknown"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "69.4.80.171",
//           "domain_from_country": null,
//           "page_from_external_links": 2,
//           "page_from_internal_links": 14,
//           "page_from_size": 24387,
//           "page_from_encoding": "utf-8",
//           "page_from_language": "en",
//           "page_from_title": "ChillSpot1 - Gleadsvn's Channel",
//           "page_from_status_code": 200,
//           "first_seen": "2023-03-14 15:16:41 +00:00",
//           "prev_seen": null,
//           "last_seen": "2023-03-14 15:16:41 +00:00",
//           "item_type": "anchor",
//           "attributes": null,
//           "dofollow": true,
//           "original": true,
//           "alt": null,
//           "image_url": null,
//           "anchor": "https://gleads.vn/vi",
//           "text_pre": null,
//           "text_post": null,
//           "semantic_location": null,
//           "links_count": 2,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "ello.co",
//           "url_from": "https://ello.co/gleadsvn/loves",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "co",
//           "is_new": true,
//           "is_lost": false,
//           "backlink_spam_score": 0,
//           "rank": 78,
//           "page_from_rank": 0,
//           "domain_from_rank": 661,
//           "domain_from_platform_type": [
//               "unknown"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "104.22.26.152",
//           "domain_from_country": "CO",
//           "page_from_external_links": 2,
//           "page_from_internal_links": 16,
//           "page_from_size": 6947,
//           "page_from_encoding": "utf-8",
//           "page_from_language": "en",
//           "page_from_title": "Posts loved by Gleadsvn (@gleadsvn) | Ello",
//           "page_from_status_code": 200,
//           "first_seen": "2023-02-14 04:00:34 +00:00",
//           "prev_seen": null,
//           "last_seen": "2023-02-14 04:00:34 +00:00",
//           "item_type": "image",
//           "attributes": null,
//           "dofollow": true,
//           "original": true,
//           "alt": null,
//           "image_url": null,
//           "anchor": "gleads.vn/vi",
//           "text_pre": null,
//           "text_post": null,
//           "semantic_location": "header",
//           "links_count": 1,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "ello.co",
//           "url_from": "https://ello.co/gleadsvn/following",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "co",
//           "is_new": true,
//           "is_lost": false,
//           "backlink_spam_score": 0,
//           "rank": 78,
//           "page_from_rank": 0,
//           "domain_from_rank": 661,
//           "domain_from_platform_type": [
//               "unknown"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "104.22.26.152",
//           "domain_from_country": "CO",
//           "page_from_external_links": 2,
//           "page_from_internal_links": 91,
//           "page_from_size": 22223,
//           "page_from_encoding": "utf-8",
//           "page_from_language": "en",
//           "page_from_title": "Following | Gleadsvn (@gleadsvn) | Ello",
//           "page_from_status_code": 200,
//           "first_seen": "2023-02-14 04:00:22 +00:00",
//           "prev_seen": null,
//           "last_seen": "2023-02-14 04:00:22 +00:00",
//           "item_type": "image",
//           "attributes": null,
//           "dofollow": true,
//           "original": true,
//           "alt": null,
//           "image_url": null,
//           "anchor": "gleads.vn/vi",
//           "text_pre": null,
//           "text_post": null,
//           "semantic_location": "header",
//           "links_count": 1,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "ello.co",
//           "url_from": "https://ello.co/gleadsvn",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "co",
//           "is_new": true,
//           "is_lost": false,
//           "backlink_spam_score": 0,
//           "rank": 78,
//           "page_from_rank": 0,
//           "domain_from_rank": 661,
//           "domain_from_platform_type": [
//               "unknown"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "104.22.26.152",
//           "domain_from_country": "CO",
//           "page_from_external_links": 2,
//           "page_from_internal_links": 16,
//           "page_from_size": 7205,
//           "page_from_encoding": "utf-8",
//           "page_from_language": "en",
//           "page_from_title": "Gleadsvn (@gleadsvn) | Ello",
//           "page_from_status_code": 200,
//           "first_seen": "2023-02-14 03:58:42 +00:00",
//           "prev_seen": null,
//           "last_seen": "2023-02-14 03:58:42 +00:00",
//           "item_type": "image",
//           "attributes": null,
//           "dofollow": true,
//           "original": true,
//           "alt": null,
//           "image_url": null,
//           "anchor": "gleads.vn/vi",
//           "text_pre": null,
//           "text_post": null,
//           "semantic_location": "header",
//           "links_count": 1,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "ello.co",
//           "url_from": "https://ello.co/gleadsvn/followers",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "co",
//           "is_new": true,
//           "is_lost": false,
//           "backlink_spam_score": 0,
//           "rank": 78,
//           "page_from_rank": 0,
//           "domain_from_rank": 661,
//           "domain_from_platform_type": [
//               "unknown"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "104.22.26.152",
//           "domain_from_country": "CO",
//           "page_from_external_links": 2,
//           "page_from_internal_links": 16,
//           "page_from_size": 6978,
//           "page_from_encoding": "utf-8",
//           "page_from_language": "en",
//           "page_from_title": "Followers | Gleadsvn (@gleadsvn) | Ello",
//           "page_from_status_code": 200,
//           "first_seen": "2023-02-14 03:57:56 +00:00",
//           "prev_seen": null,
//           "last_seen": "2023-02-14 03:57:56 +00:00",
//           "item_type": "image",
//           "attributes": null,
//           "dofollow": true,
//           "original": true,
//           "alt": null,
//           "image_url": null,
//           "anchor": "gleads.vn/vi",
//           "text_pre": null,
//           "text_post": null,
//           "semantic_location": "header",
//           "links_count": 1,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "tuoitrexahoi.vn",
//           "url_from": "https://tuoitrexahoi.vn/dich-vu-thiet-ke-website-cua-gleads-a203717.html",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "vn",
//           "is_new": false,
//           "is_lost": false,
//           "backlink_spam_score": 0,
//           "rank": 77,
//           "page_from_rank": 202,
//           "domain_from_rank": 356,
//           "domain_from_platform_type": [
//               "news"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "210.2.90.34",
//           "domain_from_country": "VN",
//           "page_from_external_links": 8,
//           "page_from_internal_links": 81,
//           "page_from_size": 128946,
//           "page_from_encoding": "utf-8",
//           "page_from_language": "vi",
//           "page_from_title": "Dịch vụ thiết kế website của Gleads",
//           "page_from_status_code": 200,
//           "first_seen": "2023-03-11 15:46:59 +00:00",
//           "prev_seen": "2024-01-25 17:22:59 +00:00",
//           "last_seen": "2024-02-11 22:22:08 +00:00",
//           "item_type": "anchor",
//           "attributes": null,
//           "dofollow": true,
//           "original": false,
//           "alt": null,
//           "image_url": null,
//           "anchor": "Gleads",
//           "text_pre": "Bên cạnh dịch vụ thiết kế website chất lượng, ",
//           "text_post": " còn cung cấp dịch vụ PPC, dịch vụ SEO, dịch vụ content, dịch vụ thiết kế bộ nhận diện thương hiệu. Liên hệ ngay với chúng tôi để được tư vấn hoặc tham khảo thêm tại website Gleads.",
//           "semantic_location": "section",
//           "links_count": 1,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "hashnode.com",
//           "url_from": "https://hashnode.com/@Gleadsvn",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "com",
//           "is_new": true,
//           "is_lost": false,
//           "backlink_spam_score": 0,
//           "rank": 65,
//           "page_from_rank": 238,
//           "domain_from_rank": 581,
//           "domain_from_platform_type": [
//               "unknown"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "76.76.21.21",
//           "domain_from_country": "WW",
//           "page_from_external_links": 18,
//           "page_from_internal_links": 37,
//           "page_from_size": 176155,
//           "page_from_encoding": "utf-8",
//           "page_from_language": "en",
//           "page_from_title": "Công ty TNHH Gleads — Hashnode",
//           "page_from_status_code": 200,
//           "first_seen": "2023-11-13 01:47:19 +00:00",
//           "prev_seen": null,
//           "last_seen": "2023-11-13 01:47:19 +00:00",
//           "item_type": "anchor",
//           "attributes": [
//               "noopener"
//           ],
//           "dofollow": true,
//           "original": true,
//           "alt": null,
//           "image_url": null,
//           "anchor": null,
//           "text_pre": "245 249 / var(--tw-bg-opacity));}.dark .css-uahd06:hover{--tw-bg-opacity:1;background-color:rgb(51 65 85 / var(--tw-bg-opacity));}@media (min-width: 1536px){.css-uahd06{margin-left:0.25rem;margin-right:0.25rem;}}",
//           "text_post": null,
//           "semantic_location": "main",
//           "links_count": 1,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "gleadsltd.wixsite.com",
//           "url_from": "https://gleadsltd.wixsite.com/c-ng-ty-tnhh-gleads/blog/page/2",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "wixsite.com",
//           "is_new": false,
//           "is_lost": false,
//           "backlink_spam_score": 5,
//           "rank": 58,
//           "page_from_rank": 0,
//           "domain_from_rank": 0,
//           "domain_from_platform_type": [
//               "cms",
//               "blogs"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "34.117.60.144",
//           "domain_from_country": null,
//           "page_from_external_links": 4,
//           "page_from_internal_links": 24,
//           "page_from_size": 825409,
//           "page_from_encoding": "utf-8",
//           "page_from_language": "en",
//           "page_from_title": "Blog | C Ng Ty Tnhh Gleads",
//           "page_from_status_code": 200,
//           "first_seen": "2023-10-21 18:45:04 +00:00",
//           "prev_seen": "2023-10-21 18:45:04 +00:00",
//           "last_seen": "2024-02-19 19:04:37 +00:00",
//           "item_type": "image",
//           "attributes": [
//               "noreferrer",
//               "noopener"
//           ],
//           "dofollow": true,
//           "original": false,
//           "alt": null,
//           "image_url": null,
//           "anchor": null,
//           "text_pre": null,
//           "text_post": null,
//           "semantic_location": "section",
//           "links_count": 2,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "gleadsltd.wixsite.com",
//           "url_from": "https://gleadsltd.wixsite.com/c-ng-ty-tnhh-gleads/blog/page/3",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "wixsite.com",
//           "is_new": false,
//           "is_lost": false,
//           "backlink_spam_score": 5,
//           "rank": 58,
//           "page_from_rank": 0,
//           "domain_from_rank": 0,
//           "domain_from_platform_type": [
//               "cms",
//               "blogs"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "34.117.60.144",
//           "domain_from_country": null,
//           "page_from_external_links": 4,
//           "page_from_internal_links": 6,
//           "page_from_size": 651580,
//           "page_from_encoding": "utf-8",
//           "page_from_language": "en",
//           "page_from_title": "Blog | C Ng Ty Tnhh Gleads",
//           "page_from_status_code": 200,
//           "first_seen": "2023-10-21 18:44:40 +00:00",
//           "prev_seen": "2023-10-21 18:44:40 +00:00",
//           "last_seen": "2024-02-19 19:03:33 +00:00",
//           "item_type": "image",
//           "attributes": [
//               "noreferrer",
//               "noopener"
//           ],
//           "dofollow": true,
//           "original": false,
//           "alt": null,
//           "image_url": null,
//           "anchor": null,
//           "text_pre": null,
//           "text_post": null,
//           "semantic_location": "section",
//           "links_count": 2,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "gleadsltd.wixsite.com",
//           "url_from": "https://gleadsltd.wixsite.com/c-ng-ty-tnhh-gleads/blog",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "wixsite.com",
//           "is_new": false,
//           "is_lost": false,
//           "backlink_spam_score": 5,
//           "rank": 58,
//           "page_from_rank": 0,
//           "domain_from_rank": 0,
//           "domain_from_platform_type": [
//               "cms",
//               "blogs"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "34.117.60.144",
//           "domain_from_country": null,
//           "page_from_external_links": 4,
//           "page_from_internal_links": 25,
//           "page_from_size": 828511,
//           "page_from_encoding": "utf-8",
//           "page_from_language": "en",
//           "page_from_title": "Blog | C Ng Ty Tnhh Gleads",
//           "page_from_status_code": 200,
//           "first_seen": "2023-10-21 18:43:26 +00:00",
//           "prev_seen": "2023-10-21 18:43:26 +00:00",
//           "last_seen": "2024-02-19 19:01:16 +00:00",
//           "item_type": "image",
//           "attributes": [
//               "noreferrer",
//               "noopener"
//           ],
//           "dofollow": true,
//           "original": false,
//           "alt": null,
//           "image_url": null,
//           "anchor": null,
//           "text_pre": null,
//           "text_post": null,
//           "semantic_location": "section",
//           "links_count": 2,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "gleadsltd.wixsite.com",
//           "url_from": "https://gleadsltd.wixsite.com/c-ng-ty-tnhh-gleads/blank-1",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "wixsite.com",
//           "is_new": false,
//           "is_lost": false,
//           "backlink_spam_score": 5,
//           "rank": 58,
//           "page_from_rank": 0,
//           "domain_from_rank": 0,
//           "domain_from_platform_type": [
//               "cms",
//               "blogs"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "34.117.60.144",
//           "domain_from_country": null,
//           "page_from_external_links": 4,
//           "page_from_internal_links": 3,
//           "page_from_size": 404930,
//           "page_from_encoding": "utf-8",
//           "page_from_language": "en",
//           "page_from_title": "About | C Ng Ty Tnhh Gleads",
//           "page_from_status_code": 200,
//           "first_seen": "2023-10-21 18:43:22 +00:00",
//           "prev_seen": "2023-10-21 18:43:22 +00:00",
//           "last_seen": "2024-02-19 19:01:07 +00:00",
//           "item_type": "image",
//           "attributes": [
//               "noreferrer",
//               "noopener"
//           ],
//           "dofollow": true,
//           "original": false,
//           "alt": null,
//           "image_url": null,
//           "anchor": null,
//           "text_pre": null,
//           "text_post": null,
//           "semantic_location": "section",
//           "links_count": 2,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "gleadsltd.wixsite.com",
//           "url_from": "https://gleadsltd.wixsite.com/c-ng-ty-tnhh-gleads",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "wixsite.com",
//           "is_new": false,
//           "is_lost": false,
//           "backlink_spam_score": 0,
//           "rank": 58,
//           "page_from_rank": 0,
//           "domain_from_rank": 0,
//           "domain_from_platform_type": [
//               "cms",
//               "blogs"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "34.117.60.144",
//           "domain_from_country": null,
//           "page_from_external_links": 4,
//           "page_from_internal_links": 3,
//           "page_from_size": 421967,
//           "page_from_encoding": "utf-8",
//           "page_from_language": "en",
//           "page_from_title": "Gleads | Thiết kế website",
//           "page_from_status_code": 200,
//           "first_seen": "2023-10-21 18:43:18 +00:00",
//           "prev_seen": "2023-10-21 18:43:18 +00:00",
//           "last_seen": "2024-02-19 19:00:58 +00:00",
//           "item_type": "image",
//           "attributes": [
//               "noreferrer",
//               "noopener"
//           ],
//           "dofollow": true,
//           "original": false,
//           "alt": null,
//           "image_url": null,
//           "anchor": null,
//           "text_pre": null,
//           "text_post": null,
//           "semantic_location": "section",
//           "links_count": 3,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "blogphuot.info",
//           "url_from": "https://blogphuot.info/tong-hop/thiet-ke-website-wordpress-tron-goi-cho-cac-tua-game.html",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "info",
//           "is_new": false,
//           "is_lost": false,
//           "backlink_spam_score": 0,
//           "rank": 58,
//           "page_from_rank": 0,
//           "domain_from_rank": 294,
//           "domain_from_platform_type": [
//               "blogs",
//               "organization"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "103.9.156.104",
//           "domain_from_country": null,
//           "page_from_external_links": 13,
//           "page_from_internal_links": 14,
//           "page_from_size": 82836,
//           "page_from_encoding": "utf-8",
//           "page_from_language": "vi",
//           "page_from_title": "Thiết kế website wordpress trọn gói cho các tựa game",
//           "page_from_status_code": 200,
//           "first_seen": "2023-03-30 14:27:40 +00:00",
//           "prev_seen": "2023-10-04 04:17:09 +00:00",
//           "last_seen": "2024-02-15 20:13:19 +00:00",
//           "item_type": "anchor",
//           "attributes": [
//               "noopener"
//           ],
//           "dofollow": true,
//           "original": false,
//           "alt": null,
//           "image_url": null,
//           "anchor": "Công ty TNHH Gleads",
//           "text_pre": null,
//           "text_post": null,
//           "semantic_location": "article",
//           "links_count": 1,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "nuongthom.net",
//           "url_from": "https://nuongthom.net/qc/quang-cao-google-tim-kiem-la-gi/",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "net",
//           "is_new": false,
//           "is_lost": false,
//           "backlink_spam_score": 0,
//           "rank": 58,
//           "page_from_rank": 0,
//           "domain_from_rank": 192,
//           "domain_from_platform_type": [
//               "organization"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "103.9.157.232",
//           "domain_from_country": null,
//           "page_from_external_links": 14,
//           "page_from_internal_links": 13,
//           "page_from_size": 82325,
//           "page_from_encoding": "utf-8",
//           "page_from_language": "vi",
//           "page_from_title": "Quảng cáo Google tìm kiếm là gì? Sao được nhiều doanh nghiệp sử dụng",
//           "page_from_status_code": 200,
//           "first_seen": "2023-05-03 03:53:23 +00:00",
//           "prev_seen": "2023-12-11 08:13:07 +00:00",
//           "last_seen": "2024-02-15 20:11:24 +00:00",
//           "item_type": "anchor",
//           "attributes": [
//               "noopener"
//           ],
//           "dofollow": true,
//           "original": false,
//           "alt": null,
//           "image_url": null,
//           "anchor": "Gleads",
//           "text_pre": "Dựa trên các yêu cầu và thông tin cụ thể doanh nghiệp cung cấp về sản phẩm/dịch vụ, ngành nghề kinh doanh mà",
//           "text_post": "báo mức giá chi tiết nhất.",
//           "semantic_location": "article",
//           "links_count": 1,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "xehay.com.vn",
//           "url_from": "https://xehay.com.vn/nhan-chay-quang-cao-google-gia-re-cho-nhieu-dong-xe-khac-nhau/",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "com.vn",
//           "is_new": false,
//           "is_lost": false,
//           "backlink_spam_score": 5,
//           "rank": 58,
//           "page_from_rank": 0,
//           "domain_from_rank": 117,
//           "domain_from_platform_type": [
//               "cms",
//               "blogs",
//               "organization"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "103.27.237.176",
//           "domain_from_country": "VN",
//           "page_from_external_links": 5,
//           "page_from_internal_links": 22,
//           "page_from_size": 138120,
//           "page_from_encoding": "utf-8",
//           "page_from_language": "vi",
//           "page_from_title": "Nhận chạy quảng cáo Google giá rẻ cho nhiều dòng xe khác nhau - Xehay.com.vn",
//           "page_from_status_code": 200,
//           "first_seen": "2023-04-09 23:51:15 +00:00",
//           "prev_seen": "2023-12-17 01:17:42 +00:00",
//           "last_seen": "2024-02-15 10:20:27 +00:00",
//           "item_type": "anchor",
//           "attributes": [
//               "noopener"
//           ],
//           "dofollow": true,
//           "original": false,
//           "alt": null,
//           "image_url": null,
//           "anchor": "Gleads",
//           "text_pre": null,
//           "text_post": null,
//           "semantic_location": null,
//           "links_count": 1,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "xevespa.vn",
//           "url_from": "https://xevespa.vn/thiet-ke-web-wordpress-gia-re-cho-web-ban-phu-kien-xe/",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "vn",
//           "is_new": false,
//           "is_lost": false,
//           "backlink_spam_score": 5,
//           "rank": 58,
//           "page_from_rank": 0,
//           "domain_from_rank": 121,
//           "domain_from_platform_type": [
//               "cms",
//               "blogs"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "103.27.237.162",
//           "domain_from_country": "VN",
//           "page_from_external_links": 5,
//           "page_from_internal_links": 17,
//           "page_from_size": 113276,
//           "page_from_encoding": "utf-8",
//           "page_from_language": "vi",
//           "page_from_title": "Thiết kế web wordpress giá rẻ cho web bán phụ kiện xe - Xevespa.vn",
//           "page_from_status_code": 200,
//           "first_seen": "2023-04-02 21:42:12 +00:00",
//           "prev_seen": "2023-12-17 02:06:20 +00:00",
//           "last_seen": "2024-02-15 07:04:38 +00:00",
//           "item_type": "anchor",
//           "attributes": [
//               "noopener"
//           ],
//           "dofollow": true,
//           "original": false,
//           "alt": null,
//           "image_url": null,
//           "anchor": "https://gleads.vn/vi",
//           "text_pre": null,
//           "text_post": null,
//           "semantic_location": null,
//           "links_count": 1,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "gleadsvn.blogspot.com",
//           "url_from": "https://gleadsvn.blogspot.com/2023/08/",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "blogspot.com",
//           "is_new": false,
//           "is_lost": false,
//           "backlink_spam_score": 30,
//           "rank": 58,
//           "page_from_rank": 0,
//           "domain_from_rank": 0,
//           "domain_from_platform_type": [
//               "blogs",
//               "organization"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "142.250.186.33",
//           "domain_from_country": null,
//           "page_from_external_links": 6,
//           "page_from_internal_links": 25,
//           "page_from_size": 204551,
//           "page_from_encoding": "utf-8",
//           "page_from_language": "en",
//           "page_from_title": "GleadsVN",
//           "page_from_status_code": 200,
//           "first_seen": "2023-10-21 18:45:54 +00:00",
//           "prev_seen": "2023-12-11 09:46:49 +00:00",
//           "last_seen": "2024-02-12 21:25:22 +00:00",
//           "item_type": "anchor",
//           "attributes": null,
//           "dofollow": true,
//           "original": false,
//           "alt": null,
//           "image_url": null,
//           "anchor": "https://gleads.vn/vi",
//           "text_pre": null,
//           "text_post": null,
//           "semantic_location": "aside",
//           "links_count": 1,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "gleadsvn.blogspot.com",
//           "url_from": "https://gleadsvn.blogspot.com/2023/10/",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "blogspot.com",
//           "is_new": false,
//           "is_lost": false,
//           "backlink_spam_score": 30,
//           "rank": 58,
//           "page_from_rank": 0,
//           "domain_from_rank": 0,
//           "domain_from_platform_type": [
//               "blogs",
//               "organization"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "142.250.186.33",
//           "domain_from_country": null,
//           "page_from_external_links": 6,
//           "page_from_internal_links": 21,
//           "page_from_size": 153296,
//           "page_from_encoding": "utf-8",
//           "page_from_language": "en",
//           "page_from_title": "GleadsVN",
//           "page_from_status_code": 200,
//           "first_seen": "2023-10-21 18:45:50 +00:00",
//           "prev_seen": "2023-12-11 09:46:42 +00:00",
//           "last_seen": "2024-02-12 21:25:18 +00:00",
//           "item_type": "anchor",
//           "attributes": null,
//           "dofollow": true,
//           "original": false,
//           "alt": null,
//           "image_url": null,
//           "anchor": "https://gleads.vn/vi",
//           "text_pre": null,
//           "text_post": null,
//           "semantic_location": "aside",
//           "links_count": 1,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "gleadsvn.blogspot.com",
//           "url_from": "https://gleadsvn.blogspot.com/2023/05/",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "blogspot.com",
//           "is_new": false,
//           "is_lost": false,
//           "backlink_spam_score": 30,
//           "rank": 58,
//           "page_from_rank": 0,
//           "domain_from_rank": 0,
//           "domain_from_platform_type": [
//               "blogs",
//               "organization"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "142.250.186.33",
//           "domain_from_country": null,
//           "page_from_external_links": 6,
//           "page_from_internal_links": 15,
//           "page_from_size": 1372971,
//           "page_from_encoding": "utf-8",
//           "page_from_language": "en",
//           "page_from_title": "GleadsVN",
//           "page_from_status_code": 200,
//           "first_seen": "2023-10-21 18:45:46 +00:00",
//           "prev_seen": "2023-12-11 09:46:37 +00:00",
//           "last_seen": "2024-02-12 21:25:14 +00:00",
//           "item_type": "anchor",
//           "attributes": null,
//           "dofollow": true,
//           "original": false,
//           "alt": null,
//           "image_url": null,
//           "anchor": "https://gleads.vn/vi",
//           "text_pre": null,
//           "text_post": null,
//           "semantic_location": "aside",
//           "links_count": 1,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "gleadsvn.blogspot.com",
//           "url_from": "https://gleadsvn.blogspot.com/2023/09/",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "blogspot.com",
//           "is_new": false,
//           "is_lost": false,
//           "backlink_spam_score": 30,
//           "rank": 58,
//           "page_from_rank": 0,
//           "domain_from_rank": 0,
//           "domain_from_platform_type": [
//               "blogs",
//               "organization"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "142.250.186.33",
//           "domain_from_country": null,
//           "page_from_external_links": 6,
//           "page_from_internal_links": 24,
//           "page_from_size": 187526,
//           "page_from_encoding": "utf-8",
//           "page_from_language": "en",
//           "page_from_title": "GleadsVN",
//           "page_from_status_code": 200,
//           "first_seen": "2023-10-21 18:45:38 +00:00",
//           "prev_seen": "2023-12-11 09:46:29 +00:00",
//           "last_seen": "2024-02-12 21:25:06 +00:00",
//           "item_type": "anchor",
//           "attributes": null,
//           "dofollow": true,
//           "original": false,
//           "alt": null,
//           "image_url": null,
//           "anchor": "https://gleads.vn/vi",
//           "text_pre": null,
//           "text_post": null,
//           "semantic_location": "aside",
//           "links_count": 1,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "gleadsvn.blogspot.com",
//           "url_from": "https://gleadsvn.blogspot.com/",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "blogspot.com",
//           "is_new": false,
//           "is_lost": false,
//           "backlink_spam_score": 30,
//           "rank": 58,
//           "page_from_rank": 0,
//           "domain_from_rank": 0,
//           "domain_from_platform_type": [
//               "blogs",
//               "organization"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "142.250.186.33",
//           "domain_from_country": null,
//           "page_from_external_links": 6,
//           "page_from_internal_links": 20,
//           "page_from_size": 151569,
//           "page_from_encoding": "utf-8",
//           "page_from_language": "en",
//           "page_from_title": "GleadsVN",
//           "page_from_status_code": 200,
//           "first_seen": "2023-10-21 18:45:58 +00:00",
//           "prev_seen": "2023-12-11 09:46:55 +00:00",
//           "last_seen": "2024-02-12 21:25:02 +00:00",
//           "item_type": "anchor",
//           "attributes": null,
//           "dofollow": true,
//           "original": false,
//           "alt": null,
//           "image_url": null,
//           "anchor": "https://gleads.vn/vi",
//           "text_pre": null,
//           "text_post": null,
//           "semantic_location": "aside",
//           "links_count": 1,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "blog.donghoviet.vn",
//           "url_from": "https://blog.donghoviet.vn/thiet-ke-website-trang-suc-sang-trong-va-quy-phai/",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "vn",
//           "is_new": true,
//           "is_lost": false,
//           "backlink_spam_score": 0,
//           "rank": 58,
//           "page_from_rank": 0,
//           "domain_from_rank": 330,
//           "domain_from_platform_type": [
//               "cms",
//               "blogs",
//               "organization"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "103.27.237.134",
//           "domain_from_country": "VN",
//           "page_from_external_links": 9,
//           "page_from_internal_links": 20,
//           "page_from_size": 133457,
//           "page_from_encoding": "utf-8",
//           "page_from_language": "vi",
//           "page_from_title": "Thiết kế website trang sức sang trọng và quý phái - Đồng Hồ Việt",
//           "page_from_status_code": 200,
//           "first_seen": "2024-02-11 01:59:47 +00:00",
//           "prev_seen": null,
//           "last_seen": "2024-02-11 01:59:47 +00:00",
//           "item_type": "anchor",
//           "attributes": [
//               "noopener"
//           ],
//           "dofollow": true,
//           "original": true,
//           "alt": null,
//           "image_url": null,
//           "anchor": "Công ty TNHH Gleads",
//           "text_pre": null,
//           "text_post": null,
//           "semantic_location": null,
//           "links_count": 1,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "minhview.com",
//           "url_from": "https://minhview.com/gia-thiet-ke-1-trang-web-co-nhung-khoan-phi-nao/",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "com",
//           "is_new": false,
//           "is_lost": false,
//           "backlink_spam_score": 0,
//           "rank": 58,
//           "page_from_rank": 0,
//           "domain_from_rank": 174,
//           "domain_from_platform_type": [
//               "unknown"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "172.67.141.219",
//           "domain_from_country": null,
//           "page_from_external_links": 7,
//           "page_from_internal_links": 34,
//           "page_from_size": 270852,
//           "page_from_encoding": "utf-8",
//           "page_from_language": "vi",
//           "page_from_title": "Giá thiết kế 1 trang web có những khoản phí nào? - MinhView",
//           "page_from_status_code": 200,
//           "first_seen": "2023-08-12 15:49:39 +00:00",
//           "prev_seen": "2023-12-09 18:27:14 +00:00",
//           "last_seen": "2024-01-31 08:32:10 +00:00",
//           "item_type": "anchor",
//           "attributes": [
//               "noopener"
//           ],
//           "dofollow": true,
//           "original": false,
//           "alt": null,
//           "image_url": null,
//           "anchor": "Gleads",
//           "text_pre": "Đến với",
//           "text_post": ", quy trình thiết kế trang sẽ bắt đầu tuần tự như sau:",
//           "semantic_location": "article",
//           "links_count": 1,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "feel.vn",
//           "url_from": "https://feel.vn/thiet-ke-trang-web-gia-re/",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "vn",
//           "is_new": false,
//           "is_lost": false,
//           "backlink_spam_score": 0,
//           "rank": 58,
//           "page_from_rank": 0,
//           "domain_from_rank": 284,
//           "domain_from_platform_type": [
//               "cms",
//               "blogs",
//               "organization"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "103.27.237.146",
//           "domain_from_country": "VN",
//           "page_from_external_links": 5,
//           "page_from_internal_links": 34,
//           "page_from_size": 163415,
//           "page_from_encoding": "utf-8",
//           "page_from_language": "vi",
//           "page_from_title": "Thiết kế trang web giá rẻ - Feel.vn",
//           "page_from_status_code": 200,
//           "first_seen": "2023-05-02 02:06:25 +00:00",
//           "prev_seen": "2023-11-12 00:28:50 +00:00",
//           "last_seen": "2024-01-28 05:04:07 +00:00",
//           "item_type": "anchor",
//           "attributes": [
//               "noopener"
//           ],
//           "dofollow": true,
//           "original": false,
//           "alt": null,
//           "image_url": null,
//           "anchor": "Gleads",
//           "text_pre": null,
//           "text_post": null,
//           "semantic_location": null,
//           "links_count": 1,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       },
//       {
//           "type": "backlink",
//           "domain_from": "vietkieu.com.vn",
//           "url_from": "https://vietkieu.com.vn/cong-ty-thiet-ke-web-chuyen-nghiep-gleads/",
//           "url_from_https": true,
//           "domain_to": "gleads.vn",
//           "url_to": "https://gleads.vn/vi",
//           "url_to_https": true,
//           "tld_from": "com.vn",
//           "is_new": false,
//           "is_lost": false,
//           "backlink_spam_score": 5,
//           "rank": 58,
//           "page_from_rank": 0,
//           "domain_from_rank": 85,
//           "domain_from_platform_type": [
//               "cms",
//               "blogs",
//               "organization"
//           ],
//           "domain_from_is_ip": false,
//           "domain_from_ip": "103.27.237.136",
//           "domain_from_country": "VN",
//           "page_from_external_links": 5,
//           "page_from_internal_links": 26,
//           "page_from_size": 152995,
//           "page_from_encoding": "utf-8",
//           "page_from_language": "vi",
//           "page_from_title": "Công ty thiết kế web chuyên nghiệp Gleads - Vietkieu.com.vn",
//           "page_from_status_code": 200,
//           "first_seen": "2023-04-02 01:29:45 +00:00",
//           "prev_seen": "2023-12-17 02:09:21 +00:00",
//           "last_seen": "2024-01-27 07:17:48 +00:00",
//           "item_type": "anchor",
//           "attributes": [
//               "noopener"
//           ],
//           "dofollow": true,
//           "original": false,
//           "alt": null,
//           "image_url": null,
//           "anchor": "Gleads",
//           "text_pre": null,
//           "text_post": null,
//           "semantic_location": null,
//           "links_count": 1,
//           "group_count": 0,
//           "is_broken": false,
//           "url_to_status_code": 200,
//           "url_to_spam_score": 0,
//           "url_to_redirect_target": null,
//           "ranked_keywords_info": {
//               "page_from_keywords_count_top_3": 0,
//               "page_from_keywords_count_top_10": 0,
//               "page_from_keywords_count_top_100": 0
//           },
//           "is_indirect_link": false,
//           "indirect_link_path": null
//       }
//   ]
// }

const Backlink = () => {

    const dispatch = useDispatch();
    //get auth token
    const token = getAuthToken();
    const userName = localStorage.getItem('username');
    
    const [data, setData] = useState({});
    const inputURL = useRef('');

    //table pagination
    const [sort,setSort] = useState('');
    const [showResult, setShowResult] = useState(10);
    const [pagination, setPagination] = useState([]);
    const [selectedPage, setSelectedPage] = useState(1);
    const [currentPageRange, setCurrentPageRange] = useState(1);
    
    const [rankDetail, setRankDetail] = useState([]);
    const [filteredRank, setFilteredRank] = useState([]);

    //metrics
    const [spamScore, setSpamScore] = useState([]);
    const [domainRank, setDomainRank] = useState([]);
    const [tld, setTld] = useState([]);
    const [country, setCountry] = useState([]);
    const [backlinkTypes, setBacklinkTypes] = useState([]);
    const [linkAttrs, setLinkAttrs] = useState([]);
    const [anchors, setAnchors] = useState([]);
    const [domain, setDomain] = useState([]);
    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

    const [backlinkMode, setBacklinkMode] = useState('one_per_domain');

    const [filterArr, setFilterArr] = useState([{
      id: uuid4(),heading: 'url_from', operation: 'contains', value: ''
    }]);

    const f = {
      url_from: 'URL from',
      domain_from: 'Domain from',
      url_to: 'URL to',
      backlink_spam_score: 'Backlink spam score',
      rank: 'Rank',
      domain_from_ip: 'Domain from IP',
      page_from_external_links: 'Page from external links',
      first_seen: 'First seen',
      last_seen: 'Last seen',
      item_type: 'Backlink type',
      dofollow: 'DoFollow',
      alt: 'Alt',
      image_url: 'Image URL',
      anchor: 'Anchor'
    }


    const keySearch = useRef();

    const checkBacklinkHandler = (refresh) => {
      if (inputURL.current.value === '') return;
      // console.log(backlinkMode);
        dispatch(showLoading());
        fetch(`${process.env.REACT_APP_HOST_IP}/api/backlink-audit`,{
          method: 'POST',
          body: JSON.stringify({
            target: inputURL.current.value,
            refresh: refresh,
            mode: backlinkMode
          }),
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + token,
              'X-User': userName
              }
      }).then(
          response => {
            dispatch(hideLoading());
            return response.json()
          }
      ).then (
        data =>{
        console.log(data);
        if (typeof data === 'string'){
            const m = new bootstrap.Modal(document.getElementById('ModalAlert'));
            m.toggle();
            return;
        }
        setData(data);
        setDomain(data['domain']);
        setRankDetail(data['items']);
        setFilteredRank(data['items']);
        let d = Object.entries(data['spam_score'])
                    .reduce((r, [k, v])  => ([
                    ...r,
                    {'name': k,
                    'value': v}
                    ]),
                    [])
        setSpamScore(d);
        d = Object.entries(data['domain_rank'])
                        .reduce((r, [k, v])  => ([
                        ...r,
                        {'name': k,
                        'value': v}
                        ]),
                        [])
        setDomainRank(d);
        let totalTLD = Object.entries(data['tld']).reduce((r, [k,v]) => r + parseInt(v), 0);
        d = Object.entries(data['tld'])
                        .reduce((r, [k, v])  => ([
                        ...r,
                        {'name': k,
                        'value': v,
                        'pct': `${Math.round(parseInt(v)/totalTLD*100)} %`
                    }
                        ]),
                        []);
        // console.log(d);
        setTld(d);
        let totalCountries = Object.entries(data['country']).reduce((r, [k,v]) => r + parseInt(v), 0);
        // console.log('total countries: ', totalCountries)
        d = Object.entries(data['country'])
                        .reduce((r, [k, v])  => ([
                        ...r,
                        {'name': k,
                        'value': v,
                        'pct': `${Math.round(parseInt(v)/totalCountries*100)} %`
                    }
                        ]),
                        [])
        // console.log(d)
        setCountry(d);
        let backlinkType = Object.entries(data['backlink_types']).reduce((r, [k,v]) => r + parseInt(v), 0);
        d = Object.entries(data['backlink_types'])
                        .reduce((r, [k, v])  => ([
                        ...r,
                        {'name': k,
                        'value': v,
                        'pct': `${Math.round(parseInt(v)/backlinkType*100)}`
                    }
                        ]),
                        [])
        setBacklinkTypes(d);
        let totalAttrs = Object.entries(data['link_attr']).reduce((r, [k,v]) => r + parseInt(v), 0);
        d = Object.entries(data['link_attr'])
                        .reduce((r, [k, v])  => ([
                        ...r,
                        {'name': k,
                        'value': v,
                        'pct': `${Math.round(parseInt(v)/totalAttrs*100)}`
                    }
                        ]),
                        [])
        setLinkAttrs(d);
        d = Object.entries(data['anchor'])
                        .reduce((r, [k, v])  => ([
                        ...r,
                        [k, v]
                        ]),
                        []);
        // console.log(d);
        setAnchors(d);
        })
    //   console.log(data);
    //   setDomain(data['domain']);
    //   setRankDetail(data['items']);
    //   setFilteredRank(data['items']);
      
    };

    useEffect(() => {
        setCurrentPageRange(1);
        setSelectedPage(1);
        let newPagination = [];
        for (let i = 1; i <= Math.ceil(filteredRank.length/showResult); i++){
          newPagination.push(
            <a class="page-link" href="#" onClick={(e) => {e.preventDefault();
              setSelectedPage(i);
              const el = document.querySelectorAll('li[class*=active]')[0];
              el.className = 'page-item';
              document.getElementById(`page-${i}`).className = 'page-item active';
              }}>{i}
            </a>
          )
        };
        setPagination(newPagination);
      },[filteredRank, showResult]);

    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };
    
    const sortHandler = (title, heading, data) => {
        if(Array.isArray(data)){
          if(title==='detail'){
            if (['first_seen', 'last_seen'].includes(heading)) {
                if (sort !== `${heading}_inc` && sort !== `${heading}_dec`){
                    setSort(`${heading}_inc`);
                    data.sort((a,b) => new Date(b[heading]).getTime() - new Date(a[heading]).getTime());
                } else if(sort === `${heading}_dec`){
                    setSort(`${heading}_inc`);
                    data.sort((a,b) => new Date(b[heading]).getTime() - new Date(a[heading]).getTime());
                } else if(sort === `${heading}_inc`){
                    setSort(`${heading}_dec`);
                    data.sort((a,b) => new Date(a[heading]).getTime() - new Date(b[heading]).getTime());
                };
            } else {
                if (sort !== `${heading}_inc` && sort !== `${heading}_dec`){
                    setSort(`${heading}_inc`);
                    data.sort((a,b) => (b[heading] > a[heading]) ? -1 : (b[heading] < a[heading]) ? 1 : 0);
                    } else if(sort === `${heading}_dec`){
                    setSort(`${heading}_inc`);
                    data.sort((a,b) => (b[heading] > a[heading]) ? -1 : (b[heading] < a[heading]) ? 1 : 0);
                    } else if(sort === `${heading}_inc`){
                    setSort(`${heading}_dec`);
                    data.sort((a,b) => (b[heading] > a[heading]) ? 1 : (b[heading] < a[heading]) ? -1 : 0);
                    };
            }
          } else if (title==='country'){
            if (sort !== `${heading}_inc` && sort !== `${heading}_dec`){
              setSort(`${heading}_inc`);
              let d = country.sort((a,b) => (b[heading] > a[heading]) ? -1 : (b[heading] < a[heading]) ? 1 : 0);
              setCountry(d);
              } else if(sort === `${heading}_dec`){
              setSort(`${heading}_inc`);
              let d = country.sort((a,b) => (b[heading] > a[heading]) ? -1 : (b[heading] < a[heading]) ? 1 : 0);
              setCountry(d);
              } else if(sort === `${heading}_inc`){
              setSort(`${heading}_dec`);
              let d = country.sort((a,b) => (b[heading] > a[heading]) ? 1 : (b[heading] < a[heading]) ? -1 : 0);
              setCountry(d);
              };
            }
        } else {
          if (heading ==='domain'){
            if (sort !== `${heading}_inc` && sort !== `${heading}_dec`){
              setSort(`${heading}_inc`);
              setDomain(Object.fromEntries(Object.entries(data).sort(([k1,v1], [k2, v2]) => k1.localeCompare(k2))));
              } else if(sort === `${heading}_dec`){
              setSort(`${heading}_inc`);
             setDomain(Object.fromEntries(Object.entries(data).sort(([k1,v1], [k2, v2]) => k1.localeCompare(k2))));
              } else if(sort === `${heading}_inc`){
              setSort(`${heading}_dec`);
              setDomain(Object.fromEntries(Object.entries(data).sort(([k1,v1], [k2, v2]) => k2.localeCompare(k1))));
              };
          } else {
            if (sort !== `${heading}_inc` && sort !== `${heading}_dec`){
              setSort(`${heading}_inc`);
              setDomain(Object.fromEntries(Object.entries(data).sort(([k1,v1], [k2, v2]) => (v2[heading] > v1[heading]) ? -1 : (v2[heading] < v1[heading]) ? 1 : 0)));
              } else if(sort === `${heading}_dec`){
              setSort(`${heading}_inc`);
              setDomain(Object.fromEntries(Object.entries(data).sort(([k1,v1], [k2, v2]) => (v2[heading] > v1[heading]) ? -1 : (v2[heading] < v1[heading]) ? 1 : 0)));
              } else if(sort === `${heading}_inc`){
              setSort(`${heading}_dec`);
              setDomain(Object.fromEntries(Object.entries(data).sort(([k1,v1], [k2, v2]) => (v1[heading] > v2[heading]) ? -1 : (v1[heading] < v2[heading]) ? 1 : 0)));
              };
          }
        };
        };


    const changeShowResultHandler= (result) => {
        setCurrentPageRange(1);
        setSelectedPage(1);
        setShowResult(result);
    };
    
    const downloadReportHandler = () => {

        if(filteredRank.length === 0){
          return;
        } else{
          let d = filteredRank.map(item => 
              [
                item.url_from,
                item.domain_from,
                item.url_to,
                item.backlink_spam_score,
                item.rank,
                item.domain_from_ip,
                item.page_from_external_links,
                item.first_seen,
                item.last_seen,
                item.item_type,
                item.dofollow,
                item.alt,
                item.image_url,
                item.anchor
              ]
            )
          const csv = Papa.unparse({
            "fields": ['URL from', "Domain from", 'URL to', 'Backlink spam score', 'Rank', 'Domain from IP', 'Page from external links', 'First seen', 'Last seen', 'Backlink type', 'DoFollow', 'Alt', 'Image URL', 'Anchor'],
            "data": d
        });
          // console.log(csv);
          const blob = new Blob([csv]);

          const url = window.URL.createObjectURL(blob, {type: 'text/plain' });
          // console.log(blob);
          const link = document.createElement("a");
          link.download = 'backlinks.csv';
          link.href = url;
          link.click();
        };
      };

      const incFilterHandler = (filterID) => {
        if(filterArr.length === 3 ) return;
        setFilterArr( c => {
            const d = [...c];
            d.push({id: filterID ,heading: 'url_from', operation: 'contains', value: ''});
            return d
        });
        console.log(filterArr);
    };
    const updateFilterHandler = (filterID, type, value) => {
        const newArr = filterArr.filter(item => {
            if(item.id === filterID){
                const d = item;
                item[type] = value;
                return d
            }else {
                return item
            }
        });
        setFilterArr(newArr);
        // console.log(filterArr);
    };

    const applyFilterHandler = () => {
        //filterArr[{id, heading, operation, value}]
        console.log('re render');
        const arr = rankDetail.filter(item => {
            let count = 0;
            filterArr.map(condition => {
                if (condition.operation === 'contains'){
                  console.log(condition.heading)
                  // console.log()
                //   console.log(`"${item[condition.heading]}".includes("${condition.value}")`)
                    count += eval(`"${item[condition.heading]}".includes("${condition.value}")`)
                }else if (condition.operation === 'does not contain'){
                    count += eval(`!"${item[condition.heading]}".includes("${condition.value}")`)
                }else if (condition.operation === 'is'){
                    // console.log(`"${item[condition.heading]}" === "${condition.value}"`);
                    count += eval(`"${item[condition.heading]}" === "${condition.value}"`)
                }else if (condition.operation === 'is not'){
                    // console.log(`"${item[condition.heading]}" !== "${condition.value}"`);
                    count += eval(`"${item[condition.heading]}" !== "${condition.value}"`)
                }else if(condition.operation === 'greater than') {
                    count += eval(`"${item[condition.heading]}" > "${condition.value}"`)
                }else if (condition.operation === 'greater than or equal'){
                    count += eval(`"${item[condition.heading]}" >= "${condition.value}"`)
                }else if (condition.operation === 'less than'){
                    count += eval(`"${item[condition.heading]}" < "${condition.value}"`)
                }else if (condition.operation === 'less than or equal'){
                    count += eval(`"${item[condition.heading]}" <= "${condition.value}"`)
                } 
            })
            return count === filterArr.length
        });
        setFilteredRank(arr);
        console.log(arr);
        console.log(filterArr)
      };
    const deleteFilterHandler = (filterID) => {
        const arr = filterArr.filter(item => item.id !== filterID);
        document.getElementById(filterID).value = '';
        if (arr.length === 0){
          setFilterArr([{id: uuid4() ,heading: 'url_from', operation: 'contains', value: ''}]);
        } else {
            setFilterArr(arr);
        }
        console.log(filterArr);
    }
    
    const urlFilter = (
            <Popover id="popover-positioned-top" title="Popover bottom" style={{"max-width":"600px", 'border': 'none'}}>
                <div className='d-flex flex-column p-4 bg-white shadow'>
                    <p>Filter URLs</p>
                    {
                        filterArr.map(item => {
                            // console.log(item);
                            return (
                                <div className='d-flex align-items-center' key={item}>
                                    where
                                    <select class="form-select form-select-sm" aria-label="Default select example" className='ms-3 me-2'
                                        onChange={e => updateFilterHandler(item.id, 'heading', e.target.value)}
                                    >
                                        <option value='url_from' selected={item.heading === '' ? true : item.heading === 'url_from' ? true : false}>URL from</option>
                                        { 
                                            Object.entries(f).map(([heading, title]) =>{
                                                if (heading !== 'url_from') {
                                                    return (<option value={heading} selected={item['heading'] === heading}>{title}</option>)
                                                } 
                                            })
                                        }
                                    </select>
                                    <select class="form-select form-select-sm" aria-label="Default select example" className='me-2'
                                        onChange={e => updateFilterHandler(item.id, 'operation', e.target.value)}
                                    >
                                        <option value='contains' selected={item.operation === '' ? true : item.operation === 'contains' ? true : false}>contains</option>
                                        <option value="does not contain" selected={item.operation === 'does not contain'}>does not contain</option>
                                        <option value="is" selected={item.operation === 'is'}>is</option>
                                        <option value="is not" selected={item.operation === 'is not'}>is not</option>
                                        <option value="greater than" selected={item.operation === 'greater than'}>greater than</option>
                                        <option value="greater than or equal" selected={item.operation === 'greater than or equal'}>greater than or equal</option>
                                        <option value="less than" selected={item.operation === 'less than'}>less than</option>
                                        <option value="less than or equal" selected={item.operation === 'less than or equal'}>less than or equal</option>
                                    </select>

                                    <input type="text" id={item.id} className="form-control me-2" style={{width: '120px'}} placeholder="Enter a value" aria-label="Enter a value" aria-describedby="basic-addon2" 
                                        defaultValue={item.value}
                                        onChange={e => updateFilterHandler(item.id, 'value', e.target.value)}
                                    />
                                    <button className='btn'
                                        onClick={e => {return}}
                                    ><FontAwesomeIcon icon={faTrash} onClick={e => deleteFilterHandler(item.id)}/></button>
                                </div>
                            )
                        })
                    }
                    <div className='d-flex mt-4'>
                        <button type="button" class={`btn btn-light me-auto ${filterArr.length === 3 && 'disabled'}`} onClick={e => incFilterHandler(uuid4())}>+ Add condition</button>
                        <button type="button" class="btn btn-primary" onClick={applyFilterHandler}>Apply</button>
                    </div>
                    
                </div>
            </Popover>
    );

    const changeBacklinkModeHandler = (showAll) => {
      if (showAll){
        // console.log('as_is');
        setBacklinkMode('as_is')
      } else {
        // console.log('one_per_domain');
        setBacklinkMode('one_per_domain');
      };
    };

    return (
        <div className="d-flex flex-column">
            <h1 className="text-center mt-4 mb-5">Backlink Audit</h1>
            <div class="input-group flex-nowrap mt-4">
              <span class="input-group-text" id="addon-wrapping">Input URL</span>
              <input type="text" class="form-control" placeholder="URL" aria-label="Username" aria-describedby="addon-wrapping" ref={inputURL}/>
              <button type="button" class="btn btn-primary w-25" onClick={e => checkBacklinkHandler('')}>Check</button>
            </div>
            <div class="form-check form-switch mt-2">
              <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked" onChange={e => changeBacklinkModeHandler(e.target.checked)}/>
              <label class="form-check-label" for="flexSwitchCheckChecked">Show all backlinks</label>
            </div>
            <span>Note: a domain or a subdomain should be specified without https:// and www.</span>
            <span>a page should be specified with absolute URL (including https:// or https://)</span>
            {
                Object.keys(data).length > 0 && data['date_checked'] !== '' &&
                <div className='d-flex align-self-end align-items-center'>
                    <a href='#' className='me-2' onClick={e => checkBacklinkHandler('refresh')}>
                        <FontAwesomeIcon icon={faRefresh} className='me-2'/>
                        Refresh
                    </a>
                    <span className='d-flex'>{`Last checked: ${new Date(data['date_checked']).toLocaleDateString()}`}</span>
                </div>
            }
            {/* Modal Import*/}
            <div class="modal fade" id="ModalAlert" tabindex="-1" role="dialog" aria-labelledby="ModalCenterTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                    <div className="modal-header">
                        <h5 class="modal-title me-auto" id="ModalLongTitle">Resources Exhausted</h5>
                    </div>
                    <div class="modal-body" style={{'max-height':'500px', 'overflow':'auto'}}>
                        <p>Backlink usage exceeded 100 reports/day</p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" >Close</button>
                    </div>
                    </div>
                </div>
            </div>
        {/* Modal Import*/}
            <div className='my-5'>
            <div className='d-flex'>
              <div className='d-flex col-md-6'>
                <div className='d-flex flex-column w-100'>
                  <div className='d-flex mb-3 h-50'>
                    <div class="d-flex flex-grow flex-column col border shadow-sm rounded mx-3 py-2 px-3">
                      <b>Backlinks</b>
                      <h2 className='align-self-center my-3'>
                        {(Object.keys(data).length > 0 && backlinkMode === 'one_per_domain') ? Object.keys(data['domain']).length : (Object.keys(data).length > 0 && backlinkMode === 'as_is') ? numberWithCommas(filteredRank.length) : ''}
                      </h2>
                    </div>
                    <div class="d-flex flex-column col border shadow-sm rounded mx-3 py-2 px-3">
                      <b>Ref. Domains</b>
                      <h2 className='align-self-center my-3'>
                      {Object.keys(data).length > 0 &&  data['ref_domains'].length}
                    </h2>
                    </div>
                  </div>
                  <div class="d-flex flex-column col border shadow-sm rounded mx-3 py-2 px-3 w-50 h-50">
                    <b>Ref. IPs</b>
                    <h2 className='align-self-center my-3'>
                    { Object.keys(data).length > 0 > 0 && data['ref_ips'].length}
                    </h2>
                  </div>
                </div>
              </div>
              <div className='d-flex col-md-6'>
              <div class="d-flex flex-column col border shadow-sm rounded mx-3 py-2 px-3">
                  <b>Top Anchors</b>
                  <hr />
                  <div style={{height: '200px'}}>
                    <WordCloudComponent words={anchors} />
                  </div>
                </div>
              </div>
            </div>
            
            <hr />
            {/* spam score and domain rank */}
            <div className='d-flex mb-4 justify-content-center'>
              <div className='d-flex col-md-6'>
                <div class="d-flex flex-column col border shadow-sm rounded mx-3 py-2 px-3">
                  <b>Spam score</b>
                  <hr />
                  <div style={{height: '300px'}}>
                    <ResponsiveContainer width="100%" height="80%">
                      <BarChart
                      width={500}
                      height={300}
                      data={spamScore}
                      layout="vertical"
                      margin={{
                          top: 30,
                          right: 30,
                          left: 20,
                          bottom:-30,
                      }}
                      >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis
                        type="number" 
                        domain={[0, 'auto']}
                      />
                      <YAxis 
                        dataKey="name"
                        type="category"
                      />
                      <Tooltip 
                          wrapperStyle={{ backgroundColor: '#f8f9fa', padding: '2px' }}
                      />
                      <Legend />
                      <Bar dataKey="value" fill="#8884d8" />
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              </div>
              <div className='d-flex col-md-6'>
                <div class="d-flex flex-column col border shadow-sm rounded mx-3 py-2 px-3">
                  <b>Domain rank</b>
                  <hr />
                  <div style={{height: '300px'}}>
                    <ResponsiveContainer width="100%" height="80%">
                      <BarChart
                      width={500}
                      height={300}
                      data={domainRank}
                      layout="vertical"
                      margin={{
                          top: 30,
                          right: 30,
                          left: 20,
                          bottom:-30,
                      }}
                      >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis
                        type="number" 
                        domain={[0, 'auto']}
                      />
                      <YAxis 
                        dataKey="name"
                        type="category"
                      />
                      <Tooltip 
                          wrapperStyle={{ backgroundColor: '#f8f9fa', padding: '2px' }}
                      />
                      <Legend />
                      <Bar dataKey="value" fill="#8884d8" />
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              </div>
            </div>
            <hr className='my-5'/>
            {/* country and tld */}
            <div className='d-flex mb-4 justify-content-center'>
              <div className='d-flex col-md-6'>
                <div class="d-flex flex-column col border shadow-sm rounded mx-3 py-2 px-3 w-100">
                  <b>Country</b>
                  <hr />
                  <div className='d-flex overflow-auto' style={{height: '300px'}}>
                    <table class="table">
                      <thead className='sticky-top table-light'>
                        <tr>
                          <th scope="col">
                            <button class="btn" type="button" aria-expanded="false" onClick={event => sortHandler('country','name', country)}>
                              <span className='me-2 fw-bold'>Country</span>
                              <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'name_inc' ? '#000' : '#6c757d'}`}}/>
                              <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'name_dec' ? '#000' : '#6c757d'}`}}/>
                            </button>
                          </th>
                          <th scope="col">
                            <button class="btn" type="button" aria-expanded="false" onClick={event => sortHandler('country', 'value', country)}>
                              <span className='me-2 fw-bold'>Ref. Domains</span>
                              <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'value_inc' ? '#000' : '#6c757d'}`}}/>
                              <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'value_dec' ? '#000' : '#6c757d'}`}}/>
                            </button>
                          </th>
                          <th scope="col">
                            <button class="btn" type="button" aria-expanded="false" onClick={event => sortHandler('country', 'pct', country)}>
                              <span className='me-2 fw-bold'>Pct</span>
                              <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'pct_inc' ? '#000' : '#6c757d'}`}}/>
                              <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'pct_dec' ? '#000' : '#6c757d'}`}}/>
                            </button>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          country.map((item) => 
                            (
                              <tr>
                                <td>{item.name !== 'nil' ? item.name : 'Other'}</td>
                                <td>{item.value}</td>
                                <td>{item.pct}</td>
                              </tr>
                            )
                          )
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className='d-flex col-md-6'>
                <div class="d-flex flex-column col border shadow-sm rounded mx-3 py-2 px-3">
                  <b>TLD</b>
                  <hr />
                  <div className='d-flex' style={{height: '300px'}}>
                    <div className='d-flex col-md-6 h-100'>
                      <ResponsiveContainer width="100%" height="80%">
                        <PieChart
                        width={500}
                        height={300}
                        margin={{
                            top: 30,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <Tooltip 
                              wrapperStyle={{ backgroundColor: '#f8f9fa', padding: '2px' }}
                          />
                            <Pie
                              dataKey="value"
                            //   startAngle={180}
                              // endAngle={0}
                              data={tld.sort((a,b) => b.value - a.value).slice(0,10)}
                              cx="50%"
                              cy="50%"
                              innerRadius={40}
                              outerRadius={80}
                              paddingAngle={5}
                              fill="#8884d8"
                          >
                              {tld.sort((a,b) => b.value - a.value).slice(0,10).map((entry, index) => (
                                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                              ))}
                          </Pie>
                        </PieChart>
                      </ResponsiveContainer>
                    </div>
                    <div className='d-flex col-md-6 overflow-auto' style={{height: '250px'}}>
                    <table class="table">
                        <thead className='sticky-top table-light'>
                          <tr>
                            <th scope="col">TLD</th>
                            <th scope="col">Ref. Domains</th>
                            <th scope="col">Pct</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            tld.map((item) => 
                              (
                                <tr>
                                  <td>{item.name}</td>
                                  <td>{item.value}</td>
                                  <td>{item.pct}</td>
                                </tr>
                              )
                            )
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* backlink types & link attributes */}
            <div className='d-flex mb-4 justify-content-center'>
              <div className='d-flex col-md-6'>
                <div class="d-flex flex-column col border shadow-sm rounded mx-3 py-2 px-3 w-100">
                  <b>Ref. Domains</b>
                  <hr />
                  <div className='d-flex overflow-auto' style={{height: '300px'}}>
                    <table class="table">
                      <thead className='sticky-top table-light'>
                        <tr>
                          <th scope="col">
                            <button class="btn" type="button" aria-expanded="false" onClick={event => sortHandler('', 'domain', data['domain'])}>
                                <span className='me-2 fw-bold'>Domain</span>
                                <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'domain_inc' ? '#000' : '#6c757d'}`}}/>
                                <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'domain_dec' ? '#000' : '#6c757d'}`}}/>
                            </button>
                          </th>
                          <th scope="col">
                            <button class="btn" type="button" aria-expanded="false" onClick={event => sortHandler('', 'backlinks', data['domain'])}>
                              <span className='me-2 fw-bold'>Backlinks</span>
                              <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'backlink_inc' ? '#000' : '#6c757d'}`}}/>
                              <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'backlink_dec' ? '#000' : '#6c757d'}`}}/>
                            </button>
                          </th>
                          <th scope="col">
                            <button class="btn" type="button" aria-expanded="false" onClick={event => sortHandler('', 'country', data['domain'])}>
                              <span className='me-2 fw-bold'>Country</span>
                              <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'country_inc' ? '#000' : '#6c757d'}`}}/>
                              <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'country_dec' ? '#000' : '#6c757d'}`}}/>
                            </button>
                          </th>
                          <th scope="col">
                            <button class="btn" type="button" aria-expanded="false" onClick={event => sortHandler('', 'ip', data['domain'])}>
                              <span className='me-2 fw-bold'>IP</span>
                              <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'ip_inc' ? '#000' : '#6c757d'}`}}/>
                              <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'ip_dec' ? '#000' : '#6c757d'}`}}/>
                            </button>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          Object.entries(domain).map(([domain, value]) => 
                              (
                                <tr>
                                  <td>{domain}</td>
                                  <td>{value.backlinks}</td>
                                  <td>{value.country }</td>
                                  <td>{value.ip}</td>
                                </tr>
                              )
                          )
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className='d-flex flex-column col-md-6'>
                <div class="d-flex flex-column col border shadow-sm rounded mx-3 py-2 px-3">
                  <b>Backlink types</b>
                  <hr />
                  <div style={{height: '150px'}}>
                  {
                      backlinkTypes.map(item => 
                        <div className='d-flex align-items-center mb-3'>
                          <div className='m-0 me-2' style={{width: '70px'}}>{item.name}</div>
                          <div className='' style={{width: '400px'}}>
                            <ProgressBar now={item.pct} />
                          </div>
                          <div className='mx-2 text-end' style={{width: '40px'}}>{item.value}</div>
                          <div className='text-end' style={{width: '40px'}}>{`${item.pct} %`}</div>
                        </div>
                        )
                    }
                  </div>
                </div>
                <div class="d-flex flex-column col border shadow-sm rounded mx-3 py-2 px-3">
                  <b>Link Attributes</b>
                  <hr />
                  <div className='d-flex flex-column' style={{height: '150px'}}>
                    {
                      linkAttrs.map(item => 
                        <div className='d-flex align-items-center mb-3'>
                          <div className='m-0 me-2' style={{width: '70px'}}>{item.name}</div>
                          <div className='' style={{width: '400px'}}>
                            <ProgressBar now={item.pct} />
                          </div>
                          <div className='mx-2 text-end' style={{width: '40px'}}>{item.value}</div>
                          <div className='text-end' style={{width: '40px'}}>{`${item.pct} %`}</div>
                        </div>
                        )
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className='d-flex mb-2'>
                <div className='d-flex'>
                  <OverlayTrigger trigger="click" placement="bottom" rootClose overlay={urlFilter} container={this} containerPadding={20}>
                      <button type="button" class="btn btn-light">
                          <FontAwesomeIcon className='me-2' icon={faFilter} />
                          Filter URLs
                      </button>
                  </OverlayTrigger>
                </div>
                <button class="btn btn-primary ms-auto" type="button" aria-expanded="false" onClick={downloadReportHandler}>
                    <FontAwesomeIcon icon={faDownload} className="pe-2"/>
                    Download
                </button>
            </div>
            <div className="overflow-auto mb-3" style={{"height":"500px"}}>
                <table class="table table-striped" style={{'table-layout':'fixed', 'word-wrap': 'break-word'}}>
                    <thead className="table-primary sticky-top" style={{'z-index':'9'}}>
                        <tr>
                        <th scope="col" style={{'width':'5%'}}>#</th>
                        <th scope="col" style={{'width':'25%'}}>
                            <button class="btn" type="button" aria-expanded="false" onClick={event => sortHandler('detail', 'url_from', filteredRank)}>
                                <span className='me-2 fw-bold'>URL from </span>
                                <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'url_from_inc' ? '#000' : '#6c757d'}`}}/>
                                <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'url_from_dec' ? '#000' : '#6c757d'}`}}/>
                            </button>
                        </th>
                        <th scope="col" style={{'width':'10%'}}>
                            <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler('detail', 'domain_from', filteredRank)}>
                                <span className='me-2 fw-bold'>Domain from</span>
                                <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'domain_from_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'domain_from_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                            </button>
                        </th>
                        <th scope="col" style={{'width':'12%'}}>
                            <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler('detail', 'url_to', filteredRank)}>
                                <span className='me-2 fw-bold'>URL to</span>
                                <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'url_to_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'url_to_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                            </button>
                        </th>
                        <th scope="col" style={{'width':'8%'}}>
                            <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler('detail', 'backlink_spam_score', filteredRank)}>
                                <span className='me-2 fw-bold'>Backlink spam score</span>
                                <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'backlink_spam_score_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'backlink_spam_score_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                            </button>
                        </th>
                        <th scope="col" style={{'width':'10%'}}>
                            <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler('detail', 'rank', filteredRank)}>
                                <span className='me-2 fw-bold'>Rank</span>
                                <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'rank_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'rank_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                            </button>
                        </th>
                        <th scope="col" style={{'width':'10%'}}>
                            <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler('detail', 'domain_from_ip', filteredRank)}>
                                <span className='me-2 fw-bold'>Domain from IP</span>
                                <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'domain_from_ip_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'domain_from_ip_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                            </button>
                        </th>
                        <th scope="col" style={{'width':'10%'}}>
                            <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler('detail', 'page_from_external_links', filteredRank)}>
                                <span className='me-2 fw-bold'>Page from external link</span>
                                <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'page_from_external_links_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'page_from_external_links_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                            </button>
                        </th>
                        <th scope="col" style={{'width':'10%'}}>
                            <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler('detail', 'first_seen', filteredRank)}>
                                <span className='me-2 fw-bold'>First seen</span>
                                <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'first_seen_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'first_seen_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                            </button>
                        </th>
                        <th scope="col" style={{'width':'10%'}}>
                            <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler('detail', 'last_seen', filteredRank)}>
                                <span className='me-2 fw-bold'>Last seen</span>
                                <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'last_seen_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'last_seen_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                            </button>
                        </th>
                        <th scope="col" style={{'width':'10%'}}>
                            <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler('detail', 'item_type', filteredRank)}>
                                <span className='me-2 fw-bold'>Link type</span>
                                <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'item_type_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'item_type_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                            </button>
                        </th>
                        <th scope="col" style={{'width':'10%'}}>
                            <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler('detail', 'dofollow', filteredRank)}>
                                <span className='me-2 fw-bold'>Dofollow</span>
                                <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'dofollow_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'dofollow_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                            </button>
                        </th>
                        <th scope="col" style={{'width':'10%'}}>
                            <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler('detail', 'alt', filteredRank)}>
                                <span className='me-2 fw-bold'>Alt</span>
                                <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'alt_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'alt_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                            </button>
                        </th>
                        <th scope="col" style={{'width':'10%'}}>
                            <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler('detail', 'image_url', filteredRank)}>
                                <span className='me-2 fw-bold'>Image url</span>
                                <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'image_url_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'image_url_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                            </button>
                        </th>
                        <th scope="col" style={{'width':'10%'}}>
                            <button class="btn px-0" type="button" aria-expanded="false" onClick={event => sortHandler('detail', 'anchor', filteredRank)}>
                                <span className='me-2 fw-bold'>Anchor</span>
                                <FontAwesomeIcon icon={faAngleUp} style={{'color':`${sort === 'anchor_inc' ? '#000' : '#6c757d'}`,'font-size':'0.8rem'}}/>
                                <FontAwesomeIcon icon={faAngleDown} style={{'color':`${sort === 'anchor_dec' ? '#000' : '#6c757d'}`, 'font-size':'0.8rem'}}/>
                            </button>
                        </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            filteredRank.slice(showResult*(selectedPage-1),showResult*selectedPage).map((item, index) => 
                                    <tr key={index + 1}>
                                        <td>{(index+1)+(selectedPage-1)*showResult}</td>
                                        <td>{item.url_from}</td>
                                        <td>{item.domain_from}</td>
                                        <td>{item.url_to}</td>
                                        <td>{item.backlink_spam_score}</td>
                                        <td>{item.rank}</td>
                                        <td>{item.domain_from_ip}</td>
                                        <td>{item.page_from_external_links}</td>
                                        <td>{new Date(item.first_seen).toLocaleDateString()}</td>
                                        <td>{new Date(item.last_seen).toLocaleDateString()}</td>
                                        <td>{item.item_type}</td>
                                        <td>{item.dofollow ? 'True' : ''}</td>
                                        <td>{item.alt}</td>
                                        <td>{item.image_url}</td>
                                        <td>{item.anchor}</td>
                                    </tr>
                                )
                        }
                    </tbody>
                </table>
                <h4 className={`text-muted text-center ${rankDetail.length > 0 && 'd-none'}`}>No Records founded in selected period!</h4>
            </div>
            <div className="d-flex">
                <div>
                    <span className='me-4'>Total backlinks: <b>{numberWithCommas(filteredRank.length)}</b></span>
                </div>
                <div class="input-group mb-3 me-3 ms-auto w-25">
                <label class="input-group-text" for="inputGroupSelect01">Results per page</label>
                <select class="form-select" id="inputGroupSelect01" onChange={e => changeShowResultHandler(e.target.value)}>
                    <option selected value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="500">500</option>
                </select>
                </div>
                <nav aria-label="Page navigation example">
                <ul class="pagination">
                    <li class={`page-item ${currentPageRange === 1 && 'disabled'}`}
                    onClick = {e => {
                    e.preventDefault();
                    setCurrentPageRange(current => {
                        if(current > 1){
                        return current -1
                        }
                        return current;
                        })}}
                    ><a class="page-link" href="#">Previous</a></li>
                    {pagination.map((item, index) => {return(
                    <li  key={index+1} id={`page-${index+1}`} className={`page-item ${index+1 === selectedPage && 'active'} ${(index+1 > 10*currentPageRange || index + 1 <= 10*(currentPageRange-1)) && 'd-none'}`}>
                        {item}
                    </li>
                    )})
                    }
                    <li
                    class={`page-item ${(pagination.length <= 10 || currentPageRange + 1 > Math.ceil(pagination.length/10)) && 'disabled'}`}
                    onClick = {e => {
                    e.preventDefault();
                    setCurrentPageRange(current => {
                        if(current + 1 <= Math.ceil(pagination.length/10)) {
                        return (current +1);
                        }
                        return current;
                    })}
                    }
                    ><a class="page-link" href="#">Next</a></li>
                </ul>
                </nav>
            
            </div>
            </div>
        </div>
    )
}

export default Backlink