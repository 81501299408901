import { RowItem } from "../components/cart-item";


function NotiModal(props) {

    const groupKwHandler = (mode) => {
        props.onGroupKw(mode);
    };

    let counter =0;
        return(
            <>
                <div class="modal fade" id="ModalCenter" tabindex="-1" role="dialog" aria-labelledby="ModalCenterTitle" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="ModalLongTitle">Uploaded Keywords</h5>
                        </div>
                        <div class="modal-body" style={{'max-height':'500px', 'overflow':'auto'}}>
                            {props.kw_list.length > 0 ?
                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Keyword</th>
                                        <th scope="col">Volume</th>
                                        <th scope="col">KD</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {props.kw_list.map((kw, index) => {
                                            counter += 1;
                                            return (
                                                <RowItem
                                                key = {index} 
                                                item = {kw}
                                                counter = {counter}
                                                />
                                            )
                                        })}
                                </tbody>
                            </table>
                            : <h6 className="my-3">{props.msg}</h6>}
                        </div>
                        <div class="modal-footer">
                            {
                                props.kw_list.length > 0 &&
                                <button type="button" class="btn btn-secondary me-auto" data-bs-dismiss="modal" onClick={e => groupKwHandler('new')}>Create New</button>
                            }
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Re-Up</button>
                            {
                                props.kw_list.length > 0 &&
                                <button type="button" class="btn btn-primary" data-bs-dismiss="modal" onClick={e => groupKwHandler('existing')}>Add</button>
                            }
                            
                        </div>
                        </div>
                    </div>
                </div>
            </>)
}

export default NotiModal;